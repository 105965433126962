import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export class AccountMenu extends Component {
  render() {
    return (
      <ul className="account-menu" onClick={this.props.toggleSideMenu}>
        <li className="account-menu_item">
          <Link
            to="/account/profile"
            activeClassName="account-menu_link--active"
            className="account-menu_link"
          >
            My Profile
          </Link>

          {!__FHIR__ && (
            <Link
              to="/account/memberships"
              activeClassName="account-menu_link--active"
              className="account-menu_link"
            >
              Memberships
            </Link>
          )}
          {!__FHIR__ && (
            <Link
              to="/account/subscriptions"
              activeClassName="account-menu_link--active"
              className="account-menu_link"
            >
              Subscriptions
            </Link>
          )}
          {!__FHIR__ && (
            <Link
              to="/account/cme"
              activeClassName="account-menu_link--active"
              className="account-menu_link"
            >
              CME
            </Link>
          )}
        </li>
      </ul>
    );
  }
}

AccountMenu.propTypes = {
  toggleSideMenu: PropTypes.func
};

export default AccountMenu;
