import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DiagnosisItem from "./DiagnosisItem";
import OutputItemSelectable from "./OutputItemSelectable";

const PatientEdDiagnoses = ({ diagnoses, ehrView = false }) => {
  return (
    <div className="patient-ed-diagnoses_container">
      {ehrView ? (
        <Fragment>
          <p className="patient-ed-diagnoses_text">
            Patient has been evaluated and diagnosed with the following
            condition(s):
          </p>
          {diagnoses.map(diagnosis => (
            <OutputItemSelectable
              key={diagnosis.id}
              item={diagnosis}
              itemText={
                <div className="patient-ed-diagnoses_ehr-checkbox-label">
                  {diagnosis.title}
                </div>
              }
            />
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <p className="patient-ed-diagnoses_text">
            You are being treated today for
          </p>
          {diagnoses.map(diagnosis => (
            <DiagnosisItem
              key={diagnosis.id}
              diagnosis={diagnosis}
              render={() => (
                <div className="patient-ed_patient-description">
                  <div className="patient-ed_patient-description-text">
                    <p>{diagnosis.patientDescription}</p>
                  </div>
                </div>
              )}
            />
          ))}
        </Fragment>
      )}
    </div>
  );
};

PatientEdDiagnoses.propTypes = {
  diagnoses: PropTypes.array.isRequired,
  ehrView: PropTypes.bool
};

export default PatientEdDiagnoses;
