import React from "react";
import PropTypes from "prop-types";

import "./Tooltip.scss";
import MarkdownRender from "utility/markdownRender";

export class Tooltip extends React.Component {
  componentDidMount() {
    const {
      step: { transitionType, target },
      primaryProps: { onClick }
    } = this.props;

    // no need to clean this up due to "once"
    if (transitionType === "CLICK_TARGET") {
      document
        .querySelector(target)
        .addEventListener("click", onClick, { once: true });
    }
  }

  render() {
    const {
      index,
      size,
      step: {
        content,
        disableBackButton = true,
        color,
        transitionType,
        tourName
      },
      backProps,
      primaryProps,
      tooltipProps,
      closeProps
    } = this.props;

    return (
      <div
        {...tooltipProps}
        style={{ backgroundColor: color }}
        className="tour-tooltip_container"
      >
        <div className="tour-tooltip_header-container">
          <b className="tour-tooltip_header-title">{tourName}</b>
          <em className="tour-tooltip_header-step">
            Step {index + 1} of {size}
          </em>
          <div className="tour-tooltip_spacer" />
          <button {...closeProps} className="tour-tooltip_close-button">
            X
          </button>
        </div>
        <hr className="tour-tooltip_divider" />
        <MarkdownRender source={content} />
        <div className="tour-tooltip_footer">
          {index > 0 && !disableBackButton && (
            <button {...backProps} className="tour-tooltip_button">
              Back
            </button>
          )}
          {transitionType === "NEXT_BUTTON" && (
            <button {...primaryProps} className="tour-tooltip_next-button">
              {index === size - 1 ? "Done" : "Next"}
            </button>
          )}
        </div>
      </div>
    );
  }
}
Tooltip.propTypes = {
  index: PropTypes.number,
  size: PropTypes.number,
  step: PropTypes.object,
  backProps: PropTypes.object,
  primaryProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  closeProps: PropTypes.object
};
