import { createSelector } from "reselect";

export function selectAuth(state) {
  return state.auth;
}

/********************** General Auth ***********************/

export const selectIsAuthenticated = createSelector(
  selectIsAuth0TokenValid,
  selectISFHIRTokenValid,
  selectIsECTokenValid,
  (auth0TokenValid, fhirTokenValid, ecTokenValid) => {
    return ecTokenValid && (__FHIR__ ? fhirTokenValid : auth0TokenValid);
  }
);

export function selectAuthToken(state) {
  return __FHIR__ ? selectFHIRToken(state) : selectAuth0Token(state);
}

export function selectIsAuthTokenValid(state) {
  return __FHIR__
    ? selectISFHIRTokenValid(state)
    : selectIsAuth0TokenValid(state);
}

/********************** Redirection ***********************/

export function selectRedirect(state) {
  return selectAuth(state).redirect;
}

const NO_REDIRECT_ROUTES = [
  "/login",
  "/logout",
  "/signup",
  "/partner",
  "/authcb"
];
export function selectRedirectLocation(state) {
  const { location, expirationTime } = selectRedirect(state);
  if (!location) {
    return "/";
  } else if (!expirationTime || Date.now() < expirationTime) {
    return NO_REDIRECT_ROUTES.some(route => location.startsWith(route))
      ? "/"
      : location;
  } else {
    return "/";
  }
}

/********************** Auth0 Token ***********************/

export function selectAuth0(state) {
  return selectAuth(state).auth0;
}

export function selectAuth0Token(state) {
  return selectAuth0(state).idToken;
}

export function selectAuth0TokenExpirationTimestamp(state) {
  return selectAuth0(state).data.exp;
}

export const selectAuth0TokenExpirationDate = createSelector(
  selectAuth0TokenExpirationTimestamp,
  expirationSecondsFromEpoch => {
    if (!expirationSecondsFromEpoch) return new Date();
    else return new Date(expirationSecondsFromEpoch * 1000);
  }
);

export function selectIsAuth0TokenExpired(state) {
  return selectAuth0TokenExpirationDate(state).valueOf() <= Date.now();
}

export function selectIsAuth0TokenValid(state) {
  return Boolean(selectAuth0Token(state)) && !selectIsAuth0TokenExpired(state);
}

// returns the # of ms until the EC token needs to be renewed
export function selectAuth0TokenTimeToRenewal(state) {
  return Math.max(
    0,
    selectAuth0TokenExpirationDate(state) - Date.now() - 60 * 1000
  );
}

/************************* EC Token ***********************/

export function selectECTokenState(state) {
  return selectAuth(state).ecToken;
}

export function selectECToken(state) {
  return selectECTokenState(state).token;
}

export function selectECTokenExpirationTimestamp(state) {
  return selectECTokenState(state).data.exp;
}

export const selectECTokenExpirationDate = createSelector(
  selectECTokenExpirationTimestamp,
  expirationSecondsFromEpoch => {
    if (!expirationSecondsFromEpoch) return new Date();
    else return new Date(expirationSecondsFromEpoch * 1000);
  }
);

// returns true iff the EC token is expired
export function selectIsEcTokenExpired(state) {
  return selectECTokenExpirationDate(state).valueOf() <= Date.now();
}

// returns the # of ms until the EC token needs to be renewed
export function selectECTokenTimeToRenewal(state) {
  return Math.max(
    0,
    selectECTokenExpirationDate(state) - Date.now() - 60 * 1000
  );
}

export function selectIsECTokenValid(state) {
  return Boolean(selectECToken(state)) && !selectIsEcTokenExpired(state);
}

/************************* FHIR Token ***********************/

export function selectFHIRState(state) {
  return selectAuth(state).fhir;
}

export function selectFHIRToken(state) {
  return FHIR_ENVIRONMENT === "cerner" || FHIR_ENVIRONMENT === "tsystem"
    ? selectFHIRState(state).idToken
    : selectFHIRState(state).accessToken;
}

export function selectFHIRTokenURI(state) {
  return selectFHIRState(state).tokenURI;
}

export function selectFHIRRefreshToken(state) {
  return selectFHIRState(state).refreshToken;
}

export function selectFHIRoTokenExpirationTimestamp(state) {
  return FHIR_ENVIRONMENT === "cerner" || FHIR_ENVIRONMENT === "tsystem"
    ? selectFHIRState(state).idTokenData.exp
    : selectFHIRState(state).accessTokenData.exp;
}

export const selectFHIRTokenExpirationDate = createSelector(
  selectFHIRoTokenExpirationTimestamp,
  expirationSecondsFromEpoch => {
    if (!expirationSecondsFromEpoch) return new Date();
    else return new Date(expirationSecondsFromEpoch * 1000);
  }
);

// returns true iff the FHIR token is expired
export function selectIsFHIRTokenExpired(state) {
  return selectFHIRTokenExpirationDate(state).valueOf() <= Date.now();
}

// returns the # of ms until the FHIR token needs to be renewed
export function selectFHIRTokenTimeToRenewal(state) {
  return Math.max(
    0,
    selectFHIRTokenExpirationDate(state) - Date.now() - 60 * 1000
  );
}

export function selectISFHIRTokenValid(state) {
  return Boolean(selectFHIRToken(state)) && !selectIsFHIRTokenExpired(state);
}

export function selectFHIRScope(state) {
  return selectFHIRState(state).scope;
}
