// ------------------------------------
// Selectors
// ------------------------------------

export function selectModals(state) {
  return state.modal;
}

export function selectSherpaModalProps(state, { name }) {
  return selectModals(state).sherpaModalPropsGallery[name];
}
