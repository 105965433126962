import R from "ramda";

export const createDosingString = (prescriptionObj, patientWeight = null) => {
  const {
    dosage,
    duration,
    frequency,
    info,
    maxDosage,
    route,
    units = {}
  } = prescriptionObj;
  // // only parse as float if it's a number
  let dosageFormated =
    !isNaN(parseFloat(dosage)) && isFinite(dosage)
      ? parseFloat(dosage)
      : dosage || "";
  let showMaxDosage = true;
  let dosageUnit = units && units.name;
  if (
    patientWeight > 0 &&
    dosageUnit &&
    units.per_kg &&
    dosageUnit !== undefined &&
    dosageFormated !== ""
  ) {
    let dosageAmount = Math.round(dosage * patientWeight);
    if (maxDosage) {
      dosageFormated = dosageAmount < maxDosage ? dosageAmount : maxDosage;
    } else {
      dosageFormated = dosageAmount;
    }
    // I'm not sure why this is here, will need to check when adding patient weights (Kelsey)
    dosageUnit = dosageUnit.slice(0, -3);
    showMaxDosage = false;
  }
  let dosingString = `${dosageFormated}`;
  // if the unit is `mg/kg`, replacing everything after the inital unit of measurement for the max dosage with `/dose`
  // (i.e. 'mg/kg' becomes 'mg/dose')

  const newMaxDosageUnit =
    units && units.name && units.perKg
      ? `${units.name.split("/")[0]}/dose`
      : "";
  const maxDosageString = ` (max: ${maxDosage} ${newMaxDosageUnit}) `;

  dosingString += !units ? "" : ` ${units.name}`;
  dosingString += !maxDosage || !showMaxDosage ? "" : maxDosageString;
  dosingString += !route ? "" : ` ${route.name}`;
  dosingString += !frequency ? "" : ` ${frequency.name}`;
  dosingString += !duration ? "" : ` ${duration.name}`;
  dosingString += R.isNil(info) ? "" : ` ${info}`;
  return dosingString;
};
