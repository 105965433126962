import { createSelector } from "reselect";
import R from "ramda";
const getInputs = (state, props) =>
  state.session.entities.usages[props.calcUsage.id] &&
  state.session.entities.usages[props.calcUsage.id].inputs;

export const makeCheckIfAllSelectedInputs = () => {
  return createSelector(
    [getInputs],
    calcInputs =>
      R.values(calcInputs).every(calcInput => calcInput.selected !== null)
  );
};

export const makeGetTotal = () => {
  return createSelector(
    [getInputs],
    calcInputs => {
      return (
        R.values(calcInputs).reduce((acc, input) => {
          if (input.selected) {
            acc += Number(input.value);
          }
          return acc;
        }, 0) || 0
      );
    }
  );
};
