import React from "react";

import GlobalSnackBar from "./SnackBar";
import GlobalModal from "./Modal";
import Loader from "components/ui/loader";
import Overlay from "components/ui/overlay";
import Notifications from "./Notifications";
import TourWrap from "./TourWrap";

export const GlobalComponents = () => (
  <>
    <GlobalSnackBar />
    <GlobalModal />
    <Overlay />
    <Loader />
    <Notifications />
    <TourWrap />
  </>
);

export default GlobalComponents;
