import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";

export class Answer extends Component {
  constructor(props) {
    super();
    this.state = {
      isSelected: props.isSelected
    };
  }
  handleSelectorClick = () => {
    const { answer, questionId, shouldShowExplanation } = this.props;
    this.props.setTestAnswer({
      questionId,
      answerId: answer.id,
      correct: answer.is_correct
    });
    shouldShowExplanation(answer);
  };
  renderAnswer = (answer, selectClasses) => {
    return (
      <div className="cme-question_answer" onClick={this.handleSelectorClick}>
        <div className="answer_selector">
          <div className={selectClasses} />
        </div>
        <div className="cme-question_answer-value">{answer.value}</div>
      </div>
    );
  };
  render() {
    const { answer, isSelected } = this.props;
    const selectClasses = classNames("answer_selector--radio", {
      "icon-radio": !isSelected,
      "answer_selector--selected icon-radio-selected": isSelected
    });
    return this.renderAnswer(answer, selectClasses);
  }
}

Answer.propTypes = {
  answer: PropTypes.object,
  setTestAnswer: PropTypes.func,
  questionId: PropTypes.string,
  isSelected: PropTypes.bool,
  shouldShowExplanation: PropTypes.func
};

export default Answer;
