import { SHOW_MODAL, HIDE_MODAL, SET_SHERPA_MODALS } from "./constants";

// ------------------------------------
// Reducer
// ------------------------------------

const ACTION_HANDLERS = {
  [SHOW_MODAL]: (state, action) => ({ ...state, ...action.payload }),
  [HIDE_MODAL]: state => ({ ...state, modalType: null, modalProps: {} }),
  [SET_SHERPA_MODALS]: (state, action) => ({
    ...state,
    sherpaModalPropsGallery: action.payload
  })
};

const initialState = {
  // the 'type' of the modal to display (see handlers in the modal component)
  modalType: null,

  // current props for the active modal
  modalProps: {},

  // indexed list of sherpa modal props by the name of the modal
  sherpaModalPropsGallery: {}
};
export default function modalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
