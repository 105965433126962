import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { fetchAdmissionContext } from "../mcgReducer";
import { getMcgGuidelines, getMcgContext } from "../mcgSelectors";
import { mcgImagePlaceholder } from "../constants";

class McgHeader extends React.Component {
  state = {
    levelOfCareIsOpen: false
  };
  dropdownRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleHsimBlur, false);
    this.props.fetchAdmissionContext();
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleHsimBlur, false);
  }
  setLevelOfCare = () => {
    this.setState({ levelOfCareIsOpen: !this.state.levelOfCareIsOpen });
  };
  handleHsimBlur = e => {
    const clickedClassName = e.target.className;
    const { levelOfCareIsOpen } = this.state;
    const shouldCloseHsimDropDown =
      levelOfCareIsOpen === true &&
      (clickedClassName !== "mcg-level-of-care-option" &&
        clickedClassName !== "mcg-level-of-care_options" &&
        clickedClassName !== "mcg-level-of-care_header" &&
        !clickedClassName.includes("icon-expand") &&
        clickedClassName !== "mcg-level-of-care_title");
    if (shouldCloseHsimDropDown) {
      this.setState({ levelOfCareIsOpen: false });
    }
  };
  handleDropdownSelect = ({ guideline }) => {
    this.setState({ levelOfCareIsOpen: false });
    if (guideline.hsim !== this.props.selectedGuideline.hsim) {
      this.props.handleGuidelineSelect({ guideline });
    }
  };
  render() {
    const { levelOfCareIsOpen } = this.state;
    const { guidelines, context, selectedGuideline = {} } = this.props;
    const levelOfCareDropdownClasses = classNames(
      "mcg-level-of-care_expand-icon",
      {
        "icon-expand": !levelOfCareIsOpen,
        "icon-expanded": levelOfCareIsOpen
      }
    );
    return (
      <div className="mcg-header-container">
        {guidelines.length > 0 && context[selectedGuideline.hsim] ? (
          <div className="mcg-level-of-care-container">
            <h3 className="mcg-level-of-care_header-text">
              Select a Clinical Admission Guideline
            </h3>
            <div className="mcg-level-of-care" onClick={this.setLevelOfCare}>
              <div className="mcg-level-of-care_header">
                <div className="mcg-level-of-care_title">
                  {context[selectedGuideline.hsim].title}
                </div>
                <div className={levelOfCareDropdownClasses} />
              </div>
            </div>
            {levelOfCareIsOpen && (
              <div ref={this.dropdownRef} className="mcg-level-of-care_options">
                {guidelines.length > 0 &&
                  guidelines.map(guideline => {
                    return (
                      <div
                        className="mcg-level-of-care-option"
                        key={guideline.hsim}
                        onClick={() => this.handleDropdownSelect({ guideline })}
                      >
                        {context[guideline.hsim].title}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        ) : (
          "No guidelines to show..."
        )}
        <img src={mcgImagePlaceholder} />
      </div>
    );
  }
}

McgHeader.propTypes = {
  guidelines: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
  selectedGuideline: PropTypes.object,
  handleGuidelineSelect: PropTypes.func.isRequired,
  fetchAdmissionContext: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  guidelines: getMcgGuidelines(state),
  context: getMcgContext(state)
});

const mapDispatchToProps = {
  fetchAdmissionContext
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McgHeader);
