import { SET_SHERPA_DRAFT_MODE } from "./constants";

const ACTION_HANDLERS = {
  [SET_SHERPA_DRAFT_MODE]: (state, { payload }) => ({
    ...state,
    sherpaDraftMode: payload
  })
};

const initialState = {
  // whether or not sherpa content should be loaded in draft mode
  sherpaDraftMode: false
};

export default function adminReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
