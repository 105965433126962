import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCustomOutputItemSelectedState } from "store/session";
import dotProp from "dot-prop";
import classNames from "classnames";
import GraphSetItem from "components/protocol/protocolRightCol/moreInfo/moreInfoItems/GraphSetItem";
import Checkbox from "components/ui/Checkbox";
import CommentBubble from "components/protocol/comments/CommentBubble";

class MediaOutputItemSelectable extends Component {
  handleSelect = () => {
    const {
      item,
      setCustomOutputItemSelectedState,
      customItemState
    } = this.props;
    const itemIsSelected = customItemState
      ? customItemState["isSelected"]
      : true;
    setCustomOutputItemSelectedState({
      outputId: "",
      outputItemId: item.id,
      isSelected: !itemIsSelected
    });
  };

  render() {
    const { item, customItemState } = this.props;
    const isSelected = customItemState ? customItemState["isSelected"] : true;
    const indicatorClasses = classNames("media-select-output-item", "columns", {
      "media-select-output-item--selected": isSelected,
      "": !isSelected
    });

    return (
      <div className="output-item_container row align-middle collapse">
        <div className="columns shrink">
          <Checkbox
            checkboxClassName={"media-select-output-item_text-checkbox"}
            isSelected={isSelected}
            onChange={this.handleSelect}
          />
          <CommentBubble
            locationDescription="Media Output Item"
            internalName={item.internalName}
            style={{
              margin: "0px -4px 0px 4px",
              position: "relative",
              top: "-3px"
            }}
            itemId={item.id}
            type="patientid"
            condensed
          />
        </div>

        <div className={indicatorClasses}>
          <GraphSetItem item={item} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, { item }) => ({
  customItemState: dotProp.get(state, `session.customOutputItems.${item.id}`)
});
export default connect(
  mapStateToProps,
  { setCustomOutputItemSelectedState }
)(MediaOutputItemSelectable);

MediaOutputItemSelectable.propTypes = {
  item: PropTypes.object.isRequired,
  setCustomOutputItemSelectedState: PropTypes.func.isRequired,
  customItemState: PropTypes.object
};
