import React from "react";
import PropTypes from "prop-types";
import InlineCitation from "./InlineCitation";
import reactStringReplace from "react-string-replace";

const TextWithCitation = ({ item }) => {
  const text = reactStringReplace(item.Text, /\[\[(.*?)\]\]/g, (match, i) => (
    <InlineCitation key={match + i} citationText={match} item={item} />
  ));
  return <>{text}</>;
};

TextWithCitation.propTypes = {
  item: PropTypes.object
};

export default TextWithCitation;
