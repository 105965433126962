import React, { Component } from "react";
import PropTypes from "prop-types";

class Generic extends Component {
  render() {
    let { content, title } = this.props;
    return (
      <div>
        <h5 className="modal_title">{title}</h5>
        {content}
      </div>
    );
  }
}
Generic.propTypes = {
  content: PropTypes.object,
  title: PropTypes.string
};

export default Generic;
