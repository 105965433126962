import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
export const Tab = ({
  className,
  label,
  active,
  icon,
  onClick,
  isMiddleTab,
  disabled
}) => {
  const tabClasses = classNames(`main-nav-tabs_tab columns`, {
    "main-nav-tabs_tab--active": active,
    "main-nav-tabs_tab--middle-tab": isMiddleTab,
    [`main-nav-tabs_${className}-tab--disabled`]: disabled
  });

  return (
    <li className={tabClasses} onClick={onClick} data-flow-tabname={label}>
      <div className={`main-nav-tabs_icon ${icon}`} />
      <div className="main-nav-tabs_text show-for-medium">{label}</div>
    </li>
  );
};
Tab.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isMiddleTab: PropTypes.bool,
  disabled: PropTypes.bool
};
export default Tab;
