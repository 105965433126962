import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { success, error } from "react-notification-system-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { passwordReset, generateEmailVerification } from "store/account";
import { FieldTextComponent, FieldSelectComponent } from "components/ui/form";
import { showLoader, hideLoader } from "store/ui";
import { ZIP_CODE_FIVE_DIGITS, NPI_CODE } from "constants/regex";
import SelectFromListByZipCodeField from "./SelectByZipCodeField";
import {
  fetchSchoolsApi,
  fetchWorkplacesApi,
  fetchSpecialtiesApi
} from "lib/ec/profile";
import { updateUserProfile } from "store/user/actions";
import {
  selectIsUserAuthEmailVerified,
  selectIsUserEmailVerified,
  selectUserAuthEmail,
  selectUserDegree,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
  selectUserNPI,
  selectUserProviderType,
  selectUserSchool,
  selectUserSpecialty,
  selectUserWorkplaces,
  selectUserZipcode
} from "store/user/selectors";
import { EVENT_EMAIL_VERIFICATION_REQUEST } from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";

const PROVIDER_TYPES = [
  { value: "physician", text: "Physician" },
  { value: "resident", text: "Resident" },
  { value: "medstudent", text: "Medical Student" },
  { value: "midlevel", text: "Midlevel", deprecated: true },
  { value: "apn", text: "Advanced Practice Nurse" },
  { value: "pa", text: "Physician Assistant" },
  { value: "nurse", text: "Nurse" },
  { value: "ems", text: "EMS" },
  { value: "pharmacist", text: "Pharmacist" },
  { value: "administrator", text: "Administrator" }
];

export class AccountDetailsForm extends React.PureComponent {
  state = {
    isSendingVerificationEmail: false,
    isPhysician: providerTypeIsPhysician(this.props.providerType),
    specialties: []
  };

  componentDidMount() {
    fetchSpecialtiesApi().then(({ data: { results } }) => {
      this.setState({ specialties: results });
    });
  }

  sendVerificationEmail = email => {
    if (!this.state.isSendingVerificationEmail) {
      const {
        pipelineRequest,
        generateEmailVerification,
        success,
        error
      } = this.props;
      pipelineRequest({
        action: EVENT_EMAIL_VERIFICATION_REQUEST
      });
      this.setState({ isSendingVerificationEmail: true });
      generateEmailVerification(email)
        .then(() => {
          this.setState({ isSendingVerificationEmail: false });
          success({
            message: `A verification email has been sent to ${email}`,
            autoDismiss: 3
          });
        })
        .catch(() =>
          error({ message: "There was a problem trying to verify your email" })
        );
    }
  };

  onSubmit = values => {
    const {
      showLoader,
      hideLoader,
      success,
      error,
      updateUserProfile
    } = this.props;
    const {
      specialty,
      providerType,
      zipcode,
      degree,
      npi,
      firstName,
      lastName,
      email
    } = values;

    showLoader();
    updateUserProfile({
      specialty,
      provider_type: providerType,
      zipcode,
      degree,
      npi,
      first_name: firstName,
      last_name: lastName,
      email
    })
      .then(() => {
        success({
          message: "Profile save success",
          autoDismiss: 3
        });
      })
      .catch(() => {
        error({
          message: "Failed to update profile"
        });
      })
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    const {
      email,
      firstName,
      lastName,
      zipcode,
      providerType,
      specialty,
      school,
      workplaces,
      degree,
      npi,
      isAuthEmailVerified,
      authEmail,

      updateUserProfile,
      passwordReset
    } = this.props;

    const { isSendingVerificationEmail, specialties, isPhysician } = this.state;

    const specialtyId = specialty ? specialty.id : "";

    return (
      <Formik
        initialValues={{
          authEmail,
          email,
          firstName,
          lastName,
          zipcode,
          providerType,
          specialty: specialtyId,
          degree,
          npi
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required("Contact email is required"),
          firstName: Yup.string().required("First Name is required"),
          lastName: Yup.string().required("Last Name is required"),
          zipcode: Yup.string().matches(
            ZIP_CODE_FIVE_DIGITS,
            "Please enter a valid zip code"
          ),
          npi: Yup.string().matches(NPI_CODE, "Please enter a valid NPI code"),
          providerType: Yup.string()
        })}
        onSubmit={this.onSubmit}
        render={({ values, handleSubmit, isValid }) => (
          <form
            onSubmit={handleSubmit}
            onKeyPress={e => e.key === "Enter" && e.preventDefault()}
            className="account-form"
          >
            {/* Email*/}
            {!__FHIR__ && (
              <div className="account-form_section_email">
                <label>
                  Login Email
                  <Field
                    className="account-form_email-input"
                    disabled
                    component={FieldTextComponent}
                    name="authEmail"
                  />
                  {!isAuthEmailVerified && (
                    <p className="account-form_verification-message">
                      Your email has not been verified.
                      <span
                        className="account-form_verification-message_link"
                        onClick={() => {
                          this.sendVerificationEmail(email);
                        }}
                      >
                        {(isSendingVerificationEmail && " Sending email...") ||
                          " Request a new verification email."}
                      </span>
                    </p>
                  )}
                </label>
                <button
                  type="button"
                  className="account-form_password-reset button button-gray"
                  onClick={passwordReset}
                >
                  Request Password Reset
                </button>
              </div>
            )}

            {/* User Name*/}
            <div className="account-form_section_user">
              <div className="row collapse-form-row">
                <div className="columns-left">
                  <Field
                    label="Contact Email"
                    name="email"
                    required
                    component={FieldTextComponent}
                  />
                </div>
                <div className="columns-left">
                  <Field
                    label="First Name"
                    name="firstName"
                    required
                    component={FieldTextComponent}
                  />
                </div>
                <div className="columns-right">
                  <Field
                    label="Last Name"
                    name="lastName"
                    required
                    component={FieldTextComponent}
                  />
                </div>
              </div>

              {/* Zip Code Input */}
              <Field
                label="Zip Code"
                name="zipcode"
                maxLength="5"
                component={FieldTextComponent}
              />

              <div>
                {/* Provider Type Input */}
                <Field
                  label="Provider Type"
                  name="providerType"
                  inputWrapProps={{
                    onChange: e => {
                      this.setState({
                        isPhysician: providerTypeIsPhysician(e.target.value)
                      });
                    }
                  }}
                  component={FieldSelectComponent}
                >
                  <option value="">No provider type chosen</option>
                  {PROVIDER_TYPES.map(p => (
                    <option
                      key={p.value}
                      value={p.value}
                      hidden={!!p.deprecated}
                      disabled={!!p.deprecated}
                    >
                      {p.text}
                    </option>
                  ))}
                </Field>

                {/* Degree Input */}
                {isPhysician && (
                  <Field
                    label="Degree"
                    name="degree"
                    component={FieldSelectComponent}
                  >
                    <option value="MD">MD</option>
                    <option value="DO">DO</option>
                    <option value="PA">PA</option>
                  </Field>
                )}

                {/* Specialty Input */}
                {specialties.length > 0 ? (
                  <Field
                    label="Specialty"
                    name="specialty"
                    component={FieldSelectComponent}
                  >
                    <option value="">No specialty chosen</option>
                    {specialties.map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Field>
                ) : (
                  <p>Loading values</p>
                )}

                {/* NPI Input */}
                <Field
                  label="NPI"
                  name="npi"
                  maxLength="10"
                  component={FieldTextComponent}
                />
              </div>
            </div>

            <div className="account-form_section_medical">
              {/* School Input */}
              <SelectFromListByZipCodeField
                selection={school}
                name="school"
                label="Medical School"
                save={(value = "") => {
                  return updateUserProfile({ school: value });
                }}
                fetchOptions={(zipcode, radius) => {
                  return fetchSchoolsApi({ zipcode, radius }).then(data => {
                    return data.data.results;
                  });
                }}
                initialZip={values.zipcode}
                selectButtonText="Select A Med School"
              />

              {/* Workplaces Input */}
              <SelectFromListByZipCodeField
                selection={workplaces[0]}
                name="workplaces"
                label="Workplace"
                save={value => {
                  const workplaces = value ? [value] : [];
                  return updateUserProfile({ workplaces });
                }}
                fetchOptions={(zipcode, radius) => {
                  return fetchWorkplacesApi({ zipcode, radius }).then(data => {
                    return data.data.results;
                  });
                }}
                initialZip={values.zipcode}
                selectButtonText="Select A Hospital"
              />
            </div>

            <div className="account-form_save-wrapper">
              <button
                className="account-form_save button button-secondary"
                type="submit"
                disabled={!isValid}
              >
                Save Changes
              </button>
            </div>
          </form>
        )}
      />
    );
  }
}

AccountDetailsForm.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  zipcode: PropTypes.string,
  providerType: PropTypes.string,
  specialty: PropTypes.object,
  school: PropTypes.object,
  workplaces: PropTypes.array,
  degree: PropTypes.string,
  npi: PropTypes.string,
  isEmailVerified: PropTypes.bool,
  authEmail: PropTypes.string,
  isAuthEmailVerified: PropTypes.bool,

  passwordReset: PropTypes.func,
  updateUserProfile: PropTypes.func,
  generateEmailVerification: PropTypes.func,
  pipelineRequest: PropTypes.func,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  success: PropTypes.func,
  error: PropTypes.func
};

function mapStateToProps(state) {
  return {
    email: selectUserEmail(state),
    firstName: selectUserFirstName(state),
    lastName: selectUserLastName(state),
    zipcode: selectUserZipcode(state),
    providerType: selectUserProviderType(state),
    npi: selectUserNPI(state),
    school: selectUserSchool(state),
    degree: selectUserDegree(state),
    workplaces: selectUserWorkplaces(state),
    specialty: selectUserSpecialty(state),
    isEmailVerified: selectIsUserEmailVerified(state),
    authEmail: selectUserAuthEmail(state),
    isAuthEmailVerified: selectIsUserAuthEmailVerified(state)
  };
}

const mapDispatchToProps = {
  updateUserProfile,
  passwordReset,
  generateEmailVerification,
  showLoader,
  hideLoader,
  pipelineRequest,
  success,
  error
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsForm);

// ---------------------------------
// Utilities
// ---------------------------------

function providerTypeIsPhysician(providerType) {
  return providerType === "physician";
}
