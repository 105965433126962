import createHash from "create-hash";

export const b64EncodeUnicode = str => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
      match,
      p1
    ) {
      return String.fromCharCode("0x" + p1);
    })
  );
};

export const buildTooltipMessage = (isMapped, isDraft, isDraftReviewer) => {
  let orderMessage = null;

  if (!isMapped) {
    // medication is NOT mapped
    orderMessage =
      "This order cannot be selected because of missing order details.";
  } else if (isMapped && isDraft && isDraftReviewer) {
    // move this string to a contants array of feature flags
    // medication is mapped, in draft mode and the user DOES have the feature flag
    orderMessage =
      "This order is currently in DRAFT mode. Please confirm the order details are correct after submission.";
  } else if (isMapped && isDraft && !isDraftReviewer) {
    // medication is mapped, in draft mode and the user DOES NOT have the feature flag
    orderMessage = "This order cannot be selected while in DRAFT mode.";
  } else if (isMapped && !isDraft) {
    // medication is mapped, not in draft mode
    // do nothing, just leaving this here for clarity
  }
  return orderMessage;
};

export const hashEncounterID = encounterResponse => {
  const encounterID = encounterResponse.id ? encounterResponse.id : null;

  const customHandle = createHash("sha256")
    .update(encounterID, "utf-8")
    .digest("hex");
  return customHandle;
};

export const hasExistingSession = (sessionList, fhirCDS) => {
  return (
    sessionList &&
    sessionList.length > 0 &&
    sessionList[0].current_protocol_id &&
    sessionList[0].id &&
    !window.location.search.includes("doNotAutoLoadEncounterSession") &&
    !fhirCDS
  );
};
