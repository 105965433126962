import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import { selectAssociations } from "store/associations/selectors";

// ------------------------------------
// Public Selectors
// ------------------------------------

/***************** Simple Selectors **********************/

export function selectUser(state) {
  return state.user;
}

export function selectOrganization(state) {
  return selectUser(state).organization;
}

export function selectContent(state) {
  return selectUser(state).content;
}

export function selectUserProviderType(state) {
  return selectUser(state).provider_type;
}

export function selectFeatureFlags(state) {
  return selectUser(state).features;
}

export function selectUserFirstName(state) {
  return selectUser(state).first_name;
}

export function selectUserLastName(state) {
  return selectUser(state).last_name;
}

export function selectAvatarCloudinaryID(state) {
  return selectUser(state).cloudinary_id;
}

export function selectUserZipcode(state) {
  return selectUser(state).zipcode;
}

export function selectUserEmail(state) {
  return selectUser(state).email;
}

export function selectIsUserEmailVerified(state) {
  return selectUser(state).email_verified;
}

export function selectUserAuthEmail(state) {
  return selectUser(state).auth_email;
}

export function selectIsUserAuthEmailVerified(state) {
  return selectUser(state).auth_email_verified;
}

export function selectUserSchool(state) {
  return selectUser(state).school;
}

export function selectUserDegree(state) {
  return selectUser(state).degree;
}

export function selectUserNPI(state) {
  return selectUser(state).npi;
}

export function selectUserWorkplaces(state) {
  return selectUser(state).workplaces;
}

export function selectUserSpecialty(state) {
  return selectUser(state).specialty;
}

export function selectUserAlias(state) {
  return selectUser(state).alias;
}

export function selectOrganizationSlug(state) {
  return selectOrganization(state).slug;
}

export function selectHasViewedNewUserModal(state) {
  return selectUser(state).hasViewedNewUserModal;
}

/***************** Derived Selectors **********************/

export const selectUserCreatedAtDate = createSelector(
  state => selectUser(state).created_at,
  createdAtString => {
    return createdAtString ? new Date(createdAtString) : null;
  }
);

export const selectUserAssociations = createSelector(
  state => selectUser(state).associations,
  selectAssociations,
  (associationSlugs, visibleAssociations) => {
    return associationSlugs.map(slug => {
      return visibleAssociations[slug] || { slug };
    });
  }
);

export const selectIsUserInAssociation = createCachedSelector(
  selectUserAssociations,
  (state, { slug }) => slug,
  (associations, slug) => {
    return (
      associations.findIndex(association => association.slug === slug) !== -1
    );
  }
);

// returns true iff the user is a student
const STUDENT_TYPES = new Set(["medstudent", "nurse", "resident", "ems"]);
export const selectUserIsStudent = createSelector(
  selectUserProviderType,
  type => STUDENT_TYPES.has(type)
);

// returns true iff the user is a doctor
const DOCTOR_TYPES = new Set([["physician", "resident"]]);
export const selectUserIsDoctor = createSelector(
  selectUserProviderType,
  type => DOCTOR_TYPES.has(type)
);

// returns true iff the user is a physician
export const selectUserIsPhysician = createSelector(
  selectUserProviderType,
  type => type === "physician"
);

// returns true if the given feature flag is enabled
export const selectUserHasFeature = createCachedSelector(
  selectFeatureFlags,
  (state, { flag }) => flag,
  (featureFlags = [], flag) => {
    return featureFlags.includes(flag);
  }
)((state, { flag }) => flag);

// returns the formatted full name of the user (with title)
export const selectFormattedUserName = createSelector(
  selectUserIsDoctor,
  selectUserFirstName,
  selectUserLastName,
  (isDoctor, firstName, lastName) => {
    if (!firstName || !lastName) return "";
    return `${isDoctor ? "Dr. " : ""}${firstName} ${lastName}`;
  }
);

// returns the cloudinary URL for the user's avatar
export const selectUserAvatarURL = createSelector(
  selectAvatarCloudinaryID,
  id =>
    id
      ? `${CLOUDINARY_URL}/evidencecare/image/upload/h_400,w_400,r_max,c_fill/${id}`
      : `${CLOUDINARY_URL}/evidencecare/image/upload/v1473439347/users/user-profile.png`
);

// // gets the permissions for filtering Sherpa content objects
export const selectSherpaPermissions = createSelector(
  selectOrganization,
  selectContent,
  ({ slug }, content) => {
    return {
      userOrganizationName: slug,
      accessibleContentBuckets: content
    };
  }
);

// grab the association with the best discout. first filter out non memberships,
// then reduce them to a single object with the highest discount number
export const selectBestMembership = createSelector(
  selectUserAssociations,
  associations => {
    return associations.reduce(
      (acc, curr) =>
        (acc.discount_amount || 0) > (curr.discount_amount || 0) ? acc : curr,
      {}
    );
  }
);

// returns a chargify URL for the user to use for various subscriptions
export const selectChargifySignupURL = createSelector(
  selectUserAlias,
  selectUserFirstName,
  selectUserLastName,
  selectUserEmail,
  selectBestMembership,
  (state, { url }) => url,
  (alias, firstName, lastName, email, { coupon } = {}, baseURL) => {
    return `${baseURL}?reference=${alias}${
      firstName ? `&first_name=${firstName}` : ""
    }${lastName ? `&last_name=${lastName}` : ""}&email=${email}${
      coupon ? `&coupon_code=${coupon}` : ""
    }`;
  }
);
