import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setTourStep,
  startTour,
  STOP_MESSAGE_TYPES,
  tourEnd,
  tourManualStop
} from "store/tour/actions";
import { selectActiveTour } from "store/tour/selectors";
import { Tooltip } from "./Tooltip";
import { StepEmphasizer } from "./StepEmphasizer";

export class Tour extends React.Component {
  onCallback = callbackProps => {
    const { stop, end } = this.props;
    const { action, index, status } = callbackProps;

    // order is important here
    if (status === STATUS.FINISHED) {
      end();
    } else if (action === ACTIONS.CLOSE) {
      stop(STOP_MESSAGE_TYPES.CLOSE);
    } else if (
      callbackProps.type === EVENTS.STEP_AFTER ||
      callbackProps.type === EVENTS.TARGET_NOT_FOUND
    ) {
      this.doStep(index, action);
    }
  };

  doStep = (index, action) => {
    this.props.setStep(index + (action === ACTIONS.PREV ? -1 : 1));
  };

  render() {
    const { steps, run, stepIndex, activeFlow, title } = this.props;

    if (!run) return null;

    const step = steps[stepIndex];
    const { transitionType, color, disableEmphasizer, beaconOffset = 0 } = step;

    return (
      <>
        <Joyride
          // force a remount if the flow changes
          key={activeFlow}
          steps={
            steps.map(step => ({
              ...step,
              tourName: title,
              disableOverlay: true,
              disableBeacon: true
            })) || []
          }
          continuous={transitionType !== "WAIT"}
          callback={data => this.onCallback(data)}
          run={run}
          stepIndex={stepIndex}
          styles={{
            options: {
              arrowColor: color
            }
          }}
          floaterProps={{
            disableAnimation: true
          }}
          tooltipComponent={Tooltip}
        />
        {!disableEmphasizer && (
          <StepEmphasizer
            target={steps[stepIndex].target}
            borderColor={color}
            offset={beaconOffset}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { stepIndex, isPaused } = state.tour;
  const { title, name, steps = [] } = selectActiveTour(state) || {};
  return {
    title,
    activeFlow: name,
    steps,
    run: !isPaused && stepIndex < steps.length,
    stepIndex
  };
}

const mapDispatchToProps = {
  setStep: setTourStep,
  stop: tourManualStop,
  start: startTour,
  end: tourEnd
};

Tour.propTypes = {
  title: PropTypes.string,
  setStep: PropTypes.func,
  stop: PropTypes.func,
  end: PropTypes.func,
  steps: PropTypes.array,
  run: PropTypes.bool,
  stepIndex: PropTypes.number,
  activeFlow: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tour);
