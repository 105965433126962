import { makeEcRequest } from "./base";

export const createSessionApi = ({
  protocolId,
  customHandle,
  intialSession
}) => {
  const body = { current_protocol_id: protocolId, state: intialSession };
  if (customHandle) body.custom_handle = customHandle;
  return makeEcRequest({
    method: "POST",
    location: `protocols/session`,
    body
  });
};

export const updateSessionApi = ({ state, state_text }) => {
  return makeEcRequest({
    method: "PATCH",
    location: `protocols/session/${state.id}`,
    body: {
      state,
      state_text,
      current_protocol_id: state.activeProtocolId
    }
  });
};

export const fetchSessionApi = ({ sessionId }) => {
  return makeEcRequest({
    method: "GET",
    location: `protocols/session/${sessionId}`
  });
};

export const fetchSessionListApi = ({ customHandle }) => {
  const customHandleString = customHandle
    ? "&custom_handle=" + customHandle
    : "";
  return makeEcRequest({
    method: "GET",
    location: `protocols/session?page_size=100${customHandleString}&ordering=-created_at`
  });
};
