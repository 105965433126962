import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

export const Tooltip = props => {
  const {
    message,
    id,
    children,
    effect,
    type,
    place,
    className,
    hideOnFhir,
    disable
  } = props;
  const shouldShowTooltip = (__FHIR__ && !hideOnFhir) || !__FHIR__;

  return (
    <Fragment>
      <div data-tip data-for={id}>
        {children}
      </div>
      {shouldShowTooltip && (
        <ReactTooltip
          className={`tooltip ${className || ""}`}
          id={id}
          place={place}
          type={type}
          effect={effect}
          delayShow={300}
          border
          resizeHide
          disable={disable}
        >
          <span>{message}</span>
        </ReactTooltip>
      )}
    </Fragment>
  );
};

Tooltip.defaultProps = {
  type: "light",
  place: "top",
  effect: "solid",
  className: "tooltip",
  disable: false
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.object,
  type: PropTypes.string,
  effect: PropTypes.string,
  place: PropTypes.string,
  className: PropTypes.string,
  hideOnFhir: PropTypes.bool,
  disable: PropTypes.bool
};
