import { createLogic } from "redux-logic";
import axios from "axios";

import {
  SESSION_SET_OUTPUT_SELECTED,
  SESSION_SET_OUTPUT_UNSELECTED,
  SESSION_ADD_SECTION,
  SESSION_ADD_ENTITIES,
  SESSION_LAUNCH_NEXT_SECTION,
  initializeSessionSection,
  updateEntityProp,
  removeSessionEntitiesBySectionId,
  launchNextSection,
  addProtocolToSession
} from "store/session";

import { showLoader, hideLoader } from "store/ui";

import {
  PROTOCOL_LAUNCH_PROTOCOL,
  PROTOCOL_CLEAR_LAUNCH_BUTTON,
  addProtocolSection,
  setProtocolContent,
  clearActiveMoreInfo
} from "store/protocol";

import { setSectionVisited } from "store/review";
import { fetchProtocolApi, fetchSectionVersionApi } from "lib/ec/protocol";
import R from "ramda";
import { getSelectedSelectionIds } from "selectors/outputSelectors";
import moment from "moment";
import localForage from "localforage";
import dotProp from "dot-prop";
import { fetchProtocolConfigs } from "store/plugin";
import { extendPrototype } from "localforage-startswith";
extendPrototype(localForage);
import { scrollIntoView, simulateScroll } from "helpers/dom/scroll";
import { pipelineRequest } from "store/pipeline";
import {
  EVENT_PROTOCOL_LAUNCHED,
  EVENT_SECTION_LAUNCHED
} from "constants/broadcastEventTypes";

const scrollToActiveSection = () => {
  const activeSection = document.getElementsByClassName("section--active")[0];
  activeSection && scrollIntoView(activeSection, { duration: 500 });
};

/**
 * Launch next section is always trigger by a user clicking on something
 * like a next button, question answer, care usage, or a calc submit
 * This will always happen from the active section in the active protocol tab
 */
const processLaunchNextSection = createLogic({
  type: SESSION_LAUNCH_NEXT_SECTION,
  process({ getState, action }, dispatch, done) {
    const { currentSectionId, nextSectionId } = action.payload;
    const state = getState();

    /**
     * Added a new event to identify when a new section has been launched into
     */
    dispatch(
      pipelineRequest({
        action: EVENT_SECTION_LAUNCHED,
        message: {
          protocol_id: action.payload.protocolId,
          next_section_id: nextSectionId
        }
      })
    );

    /**
     * if review is enabled, set this section as visible
     */
    const reviewEnabled = getState().review.enabled;
    if (reviewEnabled) {
      dispatch(setSectionVisited({ sectionId: nextSectionId }));
    }
    dispatch(clearActiveMoreInfo());
    const activeProtocolId = state.session.activeProtocolId;
    const sectionsOrder = dotProp.get(
      state,
      `session.entities.protocols.${activeProtocolId}.sectionsOrder`
    );
    const useProtocolVersion = dotProp.get(
      state,
      `session.entities.protocols.${activeProtocolId}.useProtocolVersion`
    );
    const currentSectionIndex = Array.isArray(sectionsOrder)
      ? sectionsOrder.indexOf(currentSectionId)
      : -1;
    const indexOfNextSection = sectionsOrder.indexOf(nextSectionId);
    const nextSectionIsLoaded =
      indexOfNextSection && indexOfNextSection - currentSectionIndex === 1;
    const needToRemoveSections =
      !nextSectionIsLoaded && currentSectionIndex !== sectionsOrder.length - 1;
    if (needToRemoveSections) {
      sectionsOrder.slice(currentSectionIndex + 1).forEach(sectionId => {
        dispatch(removeSessionEntitiesBySectionId({ sectionId }));
      });
    }
    // if we are launching the next section, then we shouldn't need to persist the launch button
    if (state.protocol.launchButton) {
      dispatch({
        type: PROTOCOL_CLEAR_LAUNCH_BUTTON
      });
    }
    if (nextSectionIsLoaded) {
      dispatch(
        updateEntityProp({
          id: activeProtocolId,
          entityName: "protocols",
          prop: "activeSectionId",
          value: nextSectionId
        })
      );
      const activeGroupId = dotProp.get(
        state,
        `session.entities.sections.${nextSectionId}.groupId`
      );
      if (activeGroupId) {
        dispatch(
          updateEntityProp({
            id: activeProtocolId,
            entityName: "protocols",
            prop: "activeGroupId",
            value: activeGroupId
          })
        );
      }
      done();
    } else {
      /**
       * this will fetch it from the store if it can
       */
      const section = dotProp.get(
        getState(),
        `protocol.${activeProtocolId}.sections.${nextSectionId}`
      );
      if (section) {
        dispatch(
          initializeSessionSection({
            activeProtocolId,
            sectionVersion: section,
            currentSectionId
          })
        );
        done();
      } else {
        const approvalPoint = useProtocolVersion
          ? dotProp.get(
              getState(),
              `session.entities.protocols.${activeProtocolId}.approvalPoint`
            )
          : moment().format();
        fetchSectionVersionApi({ sectionId: nextSectionId, approvalPoint })
          .then(resp => resp.data)
          .then(sectionVersion => {
            dispatch(
              addProtocolSection({
                activeProtocolId,
                currentSectionId,
                sectionVersion
              })
            );
            dispatch(
              initializeSessionSection({
                activeProtocolId: action.payload.protocolId,
                sectionVersion,
                currentSectionId
              })
            );
          })
          .catch(err => {
            console.error(err); // log since could be render err
          })
          .then(() => done());
      }
    }
  }
});

const launchProtocolLogic = createLogic({
  type: PROTOCOL_LAUNCH_PROTOCOL,
  async process({ getState, action }, dispatch, next) {
    /**
     * we may not have the protocol when we are ready to launch
     * if we don't, we check the redux store, then cache, then api
     */
    const { protocolId, sectionId } = action.payload;
    dispatch(clearActiveMoreInfo());

    const state = getState();
    const protocols = state.session.entities.protocols;
    const activeProtocolId = state.session.activeProtocolId;
    // const protocolId = protocol.id

    // fetch the new protocol config whenever a protocol is launched
    fetchProtocolConfigs(dispatch, getState(), protocolId);

    // if there is an activeProtocol Id that means we are launch this while inside a session

    if (activeProtocolId) {
      const currentProtocol = dotProp.get(
        state,
        `session.entities.protocols.${activeProtocolId}`
      );
      const activeSectionId = currentProtocol.activeSectionId;
      const sectionsOrder = currentProtocol.sectionsOrder;
      const activeSectionIndex = sectionsOrder.indexOf(activeSectionId);

      // if it's not the last section, need to remove some sections from the session
      const needToRemoveSections =
        activeSectionIndex !== sectionsOrder.length - 1;
      if (needToRemoveSections) {
        sectionsOrder.slice(activeSectionIndex + 1).forEach(sectionId => {
          dispatch(removeSessionEntitiesBySectionId({ sectionId }));
        });
      }
      const protocolsOrder = state.session.protocolsOrder;
      const activeProtocolIndex = protocolsOrder.indexOf(activeProtocolId);
      const needToRemoveProtocols =
        activeProtocolIndex !== protocolsOrder.length - 1;
      if (needToRemoveProtocols) {
        protocolsOrder.slice(activeProtocolIndex + 1).forEach(protocolId => {
          /**
           * this has not been written yet
           */
          // dispatch(removeSessionEntitiesByProtocolId({ protocolId }))
        });
      }
      if (state.protocol.launchButton) {
        dispatch({
          type: PROTOCOL_CLEAR_LAUNCH_BUTTON
        });
      }
    }

    /**
     * the protocol data is not loaded. Need to find the latest version,
     * pull it, add it to the store, and add it to the session, setting
     * it as the active protocol
     */
    if (!state.protocol[protocolId]) {
      dispatch(showLoader());
      fetchProtocolApi({ protocolId }).then(({ data: { results } }) => {
        if (results.length) {
          const [
            {
              data: { latestVersion }
            }
          ] = results;
          axios({
            method: "GET",
            url: latestVersion.renderedDocument
          }).then(({ data }) => {
            const protocolContent = data.protocol;
            protocolContent.sections = R.indexBy(
              R.prop("sectionId"),
              data.sectionVersions
            );
            protocolContent.id = data.protocol.id;
            protocolContent.latestVersion = latestVersion;
            dispatch(setProtocolContent({ protocolContent }));

            if (!protocols[protocolId]) {
              dispatch(
                addProtocolToSession({
                  protocolId,
                  latestVersion
                })
              );
            }
            dispatch(hideLoader());

            if (sectionId) {
              const sectionVersion = protocolContent.sections[sectionId];
              dispatch(
                initializeSessionSection({
                  activeProtocolId: protocolContent.id,
                  sectionVersion,
                  currentSectionId: null
                })
              );
              const reviewEnabled = getState().review.enabled;
              if (reviewEnabled) {
                dispatch(setSectionVisited({ sectionId }));
              }
            }
            dispatch(
              pipelineRequest({
                action: EVENT_PROTOCOL_LAUNCHED,
                message: {
                  protocol_id: protocolContent.id,
                  initial: 0
                }
              })
            );
            next(action);
          });
        } else {
          throw new Error("Could not find protocol in Omni");
        }
      });
    } else {
      /**
       * we already have the protocol data, need to add it to the session
       */
      const protocolContent = state.protocol[protocolId];
      if (!protocols[protocolId]) {
        dispatch(
          addProtocolToSession({
            protocolId,
            latestVersion: protocolContent.latestVersion
          })
        );
        if (sectionId) {
          const sectionVersion = protocolContent.sections[sectionId];
          dispatch(
            initializeSessionSection({
              activeProtocolId: protocolContent.id,
              sectionVersion,
              currentSectionId: null
            })
          );
          const reviewEnabled = getState().review.enabled;
          if (reviewEnabled) {
            dispatch(setSectionVisited({ sectionId }));
          }
        }
      }
      dispatch(
        pipelineRequest({
          action: EVENT_PROTOCOL_LAUNCHED,
          message: {
            protocol_id: protocolContent.id,
            initial: 1
          }
        })
      );
      next(action);
    }
  }
});

export const lfProtocolStore = localForage.createInstance({
  name: "EvidenceCare",
  version: 1.0, // schema version
  storeName: "ec_storage"
});

/**
 * looks at current selections and when new outputs are added to the session, select them if they
 * contain that selection
 */
const sessionAddEntitiesLogic = createLogic({
  type: SESSION_ADD_ENTITIES,
  transform({ getState, action }, next) {
    const { entities, entityName, currentSectionId } = action.payload;
    const state = getState();
    /**
     * I believe we can remove currentSectionId from this payload
     * While entities are being added, the current section id is always
     * the active one, which can be found in the session
     */
    if (__FHIR__ && entityName === "selections") {
      const { gender, birthDate } = getState().patient;
      const updatedEntities = R.values(entities).reduce((acc, sel) => {
        const selectedByObservation = sel.clinicalObservations.some(co => {
          if (co.loincCode === "21112-8") {
            const ageMonths = moment(moment()).diff(birthDate, "months");
            return ageMonths >= co.valueMin && ageMonths <= co.valueMax;
          } else if (co.loincCode === "46098-0") {
            return co.stringChoices.includes(gender);
          }

          return false;
        });
        acc[sel.id] = { ...sel, selectedByObservation };
        return acc;
      }, {});
      next({
        payload: {
          entities: updatedEntities,
          entityName,
          currentSectionId
        },
        type: SESSION_ADD_ENTITIES
      });
    } else if (__FHIR__ && entityName === "usages") {
      const updatedEntities = R.values(entities).reduce((acc, usage) => {
        if (usage.type === "calculatorusage") {
          const selections = getState().session.entities.selections;
          const inputSelectedByObservation = R.values(usage.inputs).find(
            input => {
              return (
                selections[input.selectionId] &&
                selections[input.selectionId].selectedByObservation
              );
            }
          );
          if (!inputSelectedByObservation) {
            acc[usage.id] = usage;
          } else {
            const updatedInputs = R.values(usage.inputs).reduce(
              (acc2, input) => {
                if (inputSelectedByObservation.id === input.id) {
                  acc2[input.id] = {
                    ...input,
                    selected: true || null,
                    selectedByObservation: true
                  };
                } else if (
                  inputSelectedByObservation.inputGroupId === input.inputGroupId
                ) {
                  acc2[input.id] = {
                    ...input,
                    selected: false,
                    selectedByObservation: false
                  };
                } else {
                  acc2[input.id] = input;
                }
                return acc2;
              },
              {}
            );
            acc[usage.id] = { ...usage, inputs: updatedInputs };
          }
        } else {
          acc[usage.id] = usage;
        }
        return acc;
      }, {});

      next({
        payload: {
          entities: updatedEntities,
          entityName,
          currentSectionId
        },
        type: SESSION_ADD_ENTITIES
      });
    } else if (entityName === "outputs") {
      const selectionIds = getSelectedSelectionIds(state);
      const updatedEntities = R.values(entities).reduce((acc, op) => {
        const selectedByObservation =
          __FHIR__ &&
          dotProp.get(
            state,
            `session.entities.selections.${op.selectionId}.selectedByObservation`
          );
        /**
         * When we are adding outputs to the session we are checking to see if any of the outputs
         * have a selection that has already been selected. If so, we set the output as selected.
         *
         * If we are in a FHIR env we can also set an output as selected if it's selection has `selectedByObservation`
         * set as true
         */
        acc[op.id] = {
          ...op,
          selected: !!(
            (op.selectionId && selectionIds.includes(op.selectionId)) ||
            selectedByObservation
          )
        };
        return acc;
      }, {});

      next({
        payload: {
          entities: updatedEntities,
          entityName,
          currentSectionId
        },
        type: SESSION_ADD_ENTITIES
      });
    } else {
      next(action);
    }
  }
});
const processSessionAddEntitiesLogic = createLogic({
  type: SESSION_ADD_ENTITIES,
  process({ getState, action }, dispatch, done) {
    const { entities, entityName } = action.payload;
    if (entityName === "outputs") {
      // outputs with a next section id and that are preselected (currently only way to preselect an output is for it to be triggered )
      /**
       * Outputs with a next section id and that are preselected.
       * Currently only way to preselect an output is for it to be triggered by an observation
       */
      const state = getState();
      const sectionIdsInSession = Object.keys(state.session.entities.sections);
      const selectedOutputWithNextSection = R.values(entities).find(
        op =>
          op.nextId &&
          dotProp.get(state, `session.entities.outputs.${op.id}.selected`)
      );
      const hasNextAndIsInCurrentProtocol =
        selectedOutputWithNextSection &&
        dotProp.get(
          state,
          `protocol${state.session.activeProtocolId}.sections${selectedOutputWithNextSection.nextId}`
        );
      if (
        hasNextAndIsInCurrentProtocol &&
        !sectionIdsInSession.includes(selectedOutputWithNextSection.nextId)
      ) {
        const activeProtocolId = state.session.activeProtocolId;
        const currentSectionId = dotProp.get(
          getState(),
          `session.entities.protocols.${activeProtocolId}.activeSectionId`
        );
        dispatch(
          launchNextSection({
            currentSectionId,
            nextSectionId: selectedOutputWithNextSection.nextId
          })
        );
      }
    }
    done();
  }
});

/**
 * Handle side effects from setting a selected output
 */
const setOutputSelectedLogic = createLogic({
  type: SESSION_SET_OUTPUT_SELECTED,
  process({ getState, action }, dispatch, done) {
    const { outputId, canSelectMultiple } = action.payload;
    const outputs = getState().session.entities.outputs;
    const usages = getState().session.entities.usages;

    const outputToSet = outputs[outputId];
    const sectionId = outputToSet.sectionId;
    const outputsToClear = Object.keys(outputs).reduce((acc, opId) => {
      if (
        !canSelectMultiple &&
        outputToSet.nextId &&
        outputs[opId].sectionId === sectionId &&
        opId !== outputId
      ) {
        /**
         * only want to clear outputs that are selected AND point to a different section
         * AND only if the output we are setting has a nextId
         */
        if (
          outputs[opId].usageId &&
          outputs[opId].selected &&
          outputs[opId].nextId
        ) {
          const usageType = usages[outputs[opId].usageId].type;
          if (
            ["calculatorusage", "questionusage", "careusage"].includes(
              usageType
            )
          ) {
            acc.push(outputs[opId]);
          }
        }
      }
      return acc;
    }, []);
    outputsToClear.forEach(output => {
      dispatch({
        type: SESSION_SET_OUTPUT_UNSELECTED,
        payload: { outputId: output.id }
      });
    });
    done();
  }
});
/**
 * Handle side effects from setting a un-selected output
 */
const setOutputUnSelectedLogic = createLogic({
  type: SESSION_SET_OUTPUT_UNSELECTED,
  process({ getState, action }, dispatch, done) {
    const { outputId } = action.payload;
    const outputsObj = getState().session.entities.outputs;
    const outputs = R.values(outputsObj);
    const outputToSet = outputsObj[outputId];
    const outputsInSection = outputs.filter(
      op => op.sectionId === outputToSet.sectionId
    );
    // const usagesObj = getState().session.entities.usages

    /**
     * This looks for any selected care usages outputs in the same section
     * that have dependent selections and unselects them if unselecting this output
     * was the last parent dependency
     */
    const outputsToClear = outputsInSection.filter(op => {
      if (op.usageType !== "careusage") return false;
      if (!op.selected) return false;
      if (!op.dependentSelections || op.dependentSelections.length === 0)
        return false;
      if (op.dependentSelections.includes(outputToSet.selectionId)) {
        if (
          !op.dependentSelections.some(selId =>
            outputsInSection.some(
              op =>
                op.selected &&
                op.selectionId === selId &&
                op.id !== outputToSet.id
            )
          )
        ) {
          return true;
        }
      }
      return false;
    });
    outputsToClear.forEach(output => {
      dispatch({
        type: SESSION_SET_OUTPUT_UNSELECTED,
        payload: { outputId: output.id }
      });
    });
    done();
  }
});
/**
 * Transform happens before the action takes place
 */
const setSessionLogic = createLogic({
  type: SESSION_ADD_SECTION,
  transform({ getState, action }, next) {
    const { sectionState } = action.payload;
    const protocolState = getState().session.entities.protocols[
      sectionState.protocolId
    ];
    if (protocolState && sectionState.groupId === protocolState.activeGroupId) {
      /**
       * Not super ideal...should prob switch to refs
       * This will add up the height of all the active sections and
       * if the new section is in the same group, it will scroll to
       * the new section
       */
      const element = document.getElementById("protocol-main-col");
      const currentSections = document.querySelectorAll(".section--active");
      var offsetHeight = 0;
      for (var i = 0; i < currentSections.length; ++i) {
        offsetHeight += currentSections[i].offsetHeight;
      }
      const adjustedOffsetHeight =
        offsetHeight - currentSections[currentSections.length - 1].offsetHeight;
      setTimeout(
        () =>
          simulateScroll(element, {
            targetScrollPosition: adjustedOffsetHeight,
            duration: 500
          }),
        100
      );
    } else if (
      protocolState &&
      sectionState.groupId !== protocolState.activeGroupId
    ) {
      scrollToActiveSection();
    }
    next(action);
  }
});

export default [
  launchProtocolLogic,
  processSessionAddEntitiesLogic,
  setOutputSelectedLogic,
  setOutputUnSelectedLogic,
  setSessionLogic,
  sessionAddEntitiesLogic,
  processLaunchNextSection
];
