export default {
  core: {
    name: "CORE",
    price: "Free",
    features:
      "- All Core Protocols\n- Antibiotic Advisor\n- GoodRx Medication Discounts\n- Patient Care Summary",
    excludedFeatures: "- EHR Documentation\n- Hide Ads\n- Earn CME Credits"
  },
  "ind-sub-monthly": {
    features:
      "- All Core Protocols\n- Antibiotic Advisor\n- GoodRx Medication Discounts\n- Patient Care Summary\n- EHR Documentation\n- Opt-out of Ads",
    excludedFeatures: "- Earn CME Credits"
  },
  "ind-sub-annual": {
    features:
      "- All Core Protocols\n- Antibiotic Advisor\n- GoodRx Medication Discounts\n- Patient Care Summary\n- EHR Documentation\n- No Ads\n- Earn CME Credits"
  }
};
