import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OutputItemEditable from "./OutputItemEditable";
import MediaOutputItemSelectable from "./MediaOutputItemSelectable";
import McgPatientEd from "integrations/mcg/McgPatientEd";

const sortAndGroupByCategory = ({ patientEds, patientEdOrdering }) => {
  return patientEdOrdering.length > -1
    ? patientEdOrdering.reduce((acc, key) => {
        const sortedPatientEd = patientEds.filter(pe => pe.categoryId === key);
        if (sortedPatientEd.length > 0) {
          acc[key] = sortedPatientEd;
        }
        return acc;
      }, {})
    : {};
};

const patientEdItemComponent = {
  text: OutputItemEditable,
  graph: MediaOutputItemSelectable
};

const PatientEducationItems = ({
  patientEds,
  patientEdOrdering,
  shouldShowMcg,
  fetchMcg,
  mcgIndications,
  mcgText
}) => {
  const patientEdItems = sortAndGroupByCategory({
    patientEds,
    patientEdOrdering
  });
  return (
    <>
      {patientEdItems &&
        Object.keys(patientEdItems).map(id => (
          <div
            key={
              patientEdItems[id][0].category &&
              patientEdItems[id][0].category.title
            }
            className="patient-ed_treatment-course-container"
          >
            <div className="patient-ed_category-title">
              {patientEdItems[id][0].category &&
                patientEdItems[id][0].category.title}
              <div className="patient-ed_category-subtitle">
                {`${patientEdItems[id][0].category &&
                  patientEdItems[id][0].category.subtitle}`}
              </div>
              {patientEdItems[id].map(patientEd => (
                <Fragment key={patientEd.id}>
                  {patientEd.items.length > 0 &&
                    patientEd.items.map(item => {
                      const ItemComponent =
                        patientEdItemComponent[item.itemType];
                      return (
                        (ItemComponent && (
                          <ItemComponent
                            key={item.id}
                            item={item}
                            internalName={patientEd.title}
                            outputItemType="PE_ITEM"
                          />
                        )) ||
                        null
                      );
                    })}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      {shouldShowMcg ? (
        <McgPatientEd
          fetchMcg={fetchMcg}
          mcgIndications={mcgIndications}
          mcgText={mcgText}
        />
      ) : null}
    </>
  );
};

PatientEducationItems.propTypes = {
  patientEds: PropTypes.array.isRequired,
  patientEdOrdering: PropTypes.array,
  shouldShowMcg: PropTypes.bool,
  fetchMcg: PropTypes.func,
  mcgIndications: PropTypes.object,
  mcgText: PropTypes.object
};

const mapStateToProps = state => ({
  patientEdOrdering:
    state.bootstrapContent.patientEdOrdering[
      "patientEducationCategoriesOrdering"
    ]
});

export default connect(mapStateToProps)(PatientEducationItems);
