import React from "react";
import classNames from "classnames";

// this is used both in admin and user facing
export const pagination = ({ count, pageSize, currentPage, setPage }) => {
  return (
    (count && (
      <div style={{ textAlign: "center" }}>
        <strong>{count}</strong> results
        <ul className="pagination">
          {[...Array(Math.ceil(count / pageSize))].map((blank, i) => {
            const pageNum = i + 1;
            const paginationItemClasses = classNames("pagination_item", {
              "pagination_item--selected": pageNum === currentPage
            });
            return (
              <li
                key={i}
                className={paginationItemClasses}
                onClick={() => setPage(pageNum)}
              >
                {pageNum}
              </li>
            );
          })}
        </ul>
      </div>
    )) ||
    null
  );
};
