export const largeImageUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/w_1200,c_limit/${cloudinaryId}`;
};
export const xsmallImageUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/w_150,c_limit/${cloudinaryId}`;
};

export const smallImageUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/w_400,c_limit/${cloudinaryId}`;
};

export const pdfSmallThumbUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/w_150,c_limit/${cloudinaryId}.jpg`;
};

export const pdfFullUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/${cloudinaryId}.pdf`;
};

export const profileImageUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/h_400,w_400,r_max,c_fill/${cloudinaryId}`;
};
export const profileImageNoRadiusUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/h_400,w_400,c_fill/${cloudinaryId}`;
};

export const headerAdUrl = cloudinaryId => {
  return `${CLOUDINARY_URL}/evidencecare/image/upload/h_150/${cloudinaryId}`;
};

/**********************************************
 * Edits a cloudinary image URI in the following ways:
 * - replaces the cloudinary domain with the cloudinaryHost param (if provided)
 * - inserts the cloudinaryTransformationString in the appropriate place (if provided)
 * - changes the protocol to https
 * - changes the image format (if format is provided)
 *
 * Note that this function can be run on a string that contains multiple cloudinary URIs.
 * The transformations will be inserted at the *end* of the transformation chain.
 *
 * See https://cloudinary.com/documentation/image_transformations for reference
 *
 * Finally, this function will print descriptive errors if the provided string contains
 * no cloudinary URIs or if the URIs point to non image resources
 *
 * ** Note that use of this function should be limited and is really only ideal
 * for parsing user-entered full cloudinary URIs; normally, you should defer to more
 * specific functions (like above)
 **********************************************/

const CLOUDINARY_REGEX = /(https?:\/\/)?res\.cloudinary\.com(\/[^ ,]*|$|(?=([ ,])))/g;
const PROTOCOL_REGEX = /https?:\/\//;
const RESOURCE_TYPES = new Set(["image", "raw", "video"]);
export function editCloudinarySrc(
  src,
  cloudinaryTransformationString,
  format,
  cloudinaryHost = CLOUDINARY_HOST
) {
  // get all of the matches
  const matches = [];
  let curMatch;
  // eslint-disable-next-line no-cond-assign
  while ((curMatch = CLOUDINARY_REGEX.exec(src))) matches.push(curMatch[0]);

  if (matches.length === 0) {
    console.error(new Error(`Given non-cloudinary image: ${src}`));
    return src;
  }
  let newSrc = src;
  matches.forEach(match => {
    const protocolRemoved = match.replace(PROTOCOL_REGEX, "");
    const URIParts = protocolRemoved.split("/");

    if (cloudinaryTransformationString) {
      // keep incrementing indexToInsertBefore until we get to the version segment
      // or the end of the array, and then we want to insert transformations immediately before
      let indexToInsertBefore = 2;
      let currentPart;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        currentPart = URIParts[indexToInsertBefore];

        // check to see if the resource type is correct
        if (RESOURCE_TYPES.has(currentPart) && currentPart !== "image") {
          console.error(new Error(`Given non-image cloudinary URI: ${match}`));
          return;
        } else if (
          currentPart.startsWith("v") ||
          indexToInsertBefore === URIParts.length - 1
        ) {
          break;
        }
        indexToInsertBefore++;
      }

      URIParts.splice(indexToInsertBefore, 0, cloudinaryTransformationString);
    }

    // replace the format
    if (format) {
      const image = URIParts[URIParts.length - 1];
      if (image.includes(".")) {
        const newParts = image.split(".");
        newParts[1] = format;
        URIParts[URIParts.length - 1] = newParts.join(".");
      } else {
        URIParts[URIParts.length - 1] = `${image}.${format}`;
      }
    }

    newSrc = newSrc.replace(
      match,
      cloudinaryHost
        ? `https://${cloudinaryHost}/${URIParts.slice(1).join("/")}`
        : `https://${URIParts.join("/")}`
    );
  });
  return newSrc;
}
