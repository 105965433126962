import { selectSherpaPermissions } from "store/user/selectors";
import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { hasPermissions } from "lib/ec/sherpa";

// ------------------------------------
// Public Selectors
// ------------------------------------

export function selectHelpCenter({ helpCenter }) {
  return helpCenter;
}

export function selectHelpCenterArticles(state) {
  return selectHelpCenter(state).articles;
}

export function selectHelpCenterSections(state) {
  return selectHelpCenter(state).sections;
}

export function selectHelpCenterSubsections(state) {
  return selectHelpCenter(state).subsections;
}

export function selectHelpCenterActiveSectionId(state) {
  return selectHelpCenter(state).activeSectionId;
}

export const selectLandingPageData = createSelector(
  state => selectHelpCenter(state).landingPage,
  selectHelpCenterArticles,
  selectHelpCenterSections,
  selectHelpCenterSubsections,
  selectSherpaPermissions,
  (landingPage, articles, sections, subsections, filters) => {
    if (!landingPage) return null;

    return {
      featuredArticles: (landingPage.featuredArticles || [])
        .map(id => _selectArticle(articles, id))
        .filter(article => hasPermissions(article, filters)),
      sections: (landingPage.sections || [])
        .map(id => _selectSection(sections, id, true))
        .map(section => {
          section.subsections = section.subsections.map(id =>
            _selectSubsection(subsections, id)
          );
          return section;
        }),
      tips: (landingPage.tips || []).filter(tip => hasPermissions(tip, filters))
    };
  }
);

export const selectHelpCenterTabs = createSelector(
  selectHelpCenter,
  helpCenter => {
    const { landingPage, sections } = helpCenter;
    if (!landingPage) return [];

    return landingPage.sections
      .map(id => {
        const section = sections[id];
        if (!section) return null;
        const { title } = section;
        return { title, id };
      })
      .filter(section => Boolean(section));
  }
);

export const selectSectionData = createCachedSelector(
  selectHelpCenterSections,
  selectHelpCenterSubsections,
  selectHelpCenterArticles,
  selectSherpaPermissions,
  (state, { id }) => id,
  (sections, subsections, articles, filters, id) => {
    const section = _selectSection(sections, id, true);

    if (!section) return {};

    section.subsections = section.subsections
      .map(id => _selectSubsection(subsections, id, true))
      .filter(subsection => subsection)
      .map(subsection => {
        subsection.articles = (subsection.articles || [])
          .map(id => _selectArticle(articles, id))
          .filter(article => hasPermissions(article, filters));
        return subsection;
      });
    return section;
  }
)((state, { id }) => id);

export const selectArticleData = createCachedSelector(
  selectHelpCenterArticles,
  selectSherpaPermissions,
  (state, { id }) => id,
  (articles, filters, id) => {
    const article = _selectArticle(articles, id, true);

    if (!article) return {};

    article.hasPermissions = hasPermissions(article, filters);
    return article;
  }
)((state, { id }) => id);

export function selectIsLandingDataLoaded(state) {
  return selectLandingPageData(state) !== null;
}

export const selectIsSectionLoaded = createCachedSelector(
  selectSectionData,
  sectionData => {
    return Boolean(sectionData.isFullylLoaded);
  }
)((state, { id }) => id);

export const selectIsArticleLoaded = createCachedSelector(
  selectArticleData,
  articleData => {
    return Boolean(articleData.isFullylLoaded);
  }
)((state, { id }) => id);

export const selectActiveSection = createSelector(
  selectHelpCenterSections,
  selectHelpCenterActiveSectionId,
  _selectSection
);

// ------------------------------------
// Internal Selectors
// ------------------------------------

function _selectArticle(articles, id, copy = false) {
  const article = articles[id];
  return copy && article ? deepCopyJSON(article) : article;
}

function _selectSection(sections, id, copy = false) {
  const section = sections[id];
  return copy && section ? deepCopyJSON(section) : section;
}

function _selectSubsection(subsections, id, copy = false) {
  const subsection = subsections[id];
  return copy && subsection ? deepCopyJSON(subsection) : subsection;
}

// Utilities
function deepCopyJSON(obj) {
  return Array.isArray(obj) || (typeof obj === "object" && obj !== null)
    ? JSON.parse(JSON.stringify(obj))
    : obj;
}
