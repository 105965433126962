import React from "react";
import PropTypes from "prop-types";
import lines from "svg-patterns/p/lines";
import stringify from "virtual-dom-stringify";

const COLORS_THAT_RENDER_SVG_PATTERN = ["yellow", "red", "orange"];

const PatternFill = ({
  strokeColor = "#000",
  backgroundColor = "#fff",
  itemColor = "green",
  size = 6
}) => {
  const patternSettings = {
    yellow: lines({
      size, // size of the pattern
      strokeWidth: 1.25,
      stroke: strokeColor, // any SVG-compatible color
      background: backgroundColor, // any SVG-compatible color
      orientations: [-45] // can be any combination of 0, 45, -45, 90
    }),
    red: lines({
      stroke: strokeColor,
      background: "#343434"
    }),
    green: {
      width: "100%",
      height: "10px",
      background: "#fff",
      border: "1px solid #000"
    },
    blue: {
      width: "100%",
      height: "10px",
      background: "#A9A9A9"
    }
  };
  const renderSvgPattern = () => {
    const shouldRenderCheckerPattern = itemColor === "orange";
    const pattern = patternSettings[itemColor];
    return shouldRenderCheckerPattern ? (
      <svg
        style={{ display: "block" }}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <pattern
          id="pattern-checkers"
          x="0"
          y="0"
          width="5"
          height="5"
          patternUnits="userSpaceOnUse"
        >
          <rect className="checker" x="0" width="4" height="4" y="0" />
          <rect className="checker" x="100" width="4" height="4" y="100" />
        </pattern>
        <rect
          x="0"
          y="0"
          width="100%"
          height={"100%"}
          fill="url(#pattern-checkers)"
        />
      </svg>
    ) : (
      <svg
        style={{ display: "block" }}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs dangerouslySetInnerHTML={{ __html: stringify(pattern) }} />
        <rect width="100%" height="100%" style={{ fill: pattern.url() }} />
      </svg>
    );
  };
  const pattern = patternSettings[itemColor];

  return COLORS_THAT_RENDER_SVG_PATTERN.includes(itemColor) ? (
    renderSvgPattern()
  ) : (
    <div style={pattern} width="100%" />
  );
};

PatternFill.propTypes = {
  strokeColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  itemColor: PropTypes.string,
  size: PropTypes.number
};

export default PatternFill;
