import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { editCloudinarySrc } from "helpers/urls/cloudinary";
import {
  loadHelpCenterLanding,
  goToHelpCenterSection
} from "store/helpCenter/actions";
import { selectLandingPageData } from "store/helpCenter/selectors";
import HelpCenterArticleGroup from "./HelpCenterArticleGroup";
import "./HelpCenterLanding.scss";

/**********************************************************
 * Help Center Landing
 **********************************************************/

export class HelpCenterLanding extends React.Component {
  componentDidMount() {
    if (!this.isDataLoaded()) {
      this.props.loadData();
    }
  }

  isDataLoaded = () => {
    const { sections, featuredArticles } = this.props;
    return Boolean(sections && featuredArticles);
  };

  render() {
    const {
      tips = [],
      sections = [],
      featuredArticles = [],
      goToSection
    } = this.props;

    // Commenting this out pending discussion about header.
    // if (!this.isDataLoaded())
    //   return <Header backgroundClass="help-center-landing_header-background" />;

    return (
      <>
        <div className="help-center-landing_header-background">
          <div className="help-center-landing_content-container help-center-landing_heading-container">
            <h1 className="help-center-landing_heading">
              {" "}
              Hi! How can we help?
            </h1>
          </div>
        </div>
        <div className="help-center-landing_section-background">
          <div className="help-center-landing_content-container">
            {sections.map(({ id, title, cardImageURL, cardMessage }) => (
              <div
                className="help-center-landing_section-card-wrapper"
                key={id}
              >
                <SectionCard
                  imageURL={cardImageURL}
                  message={cardMessage}
                  id={id}
                  title={title}
                  onClick={goToSection}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="help-center-landing_featured-background">
          <div className="help-center-landing_featured-container">
            <HelpCenterArticleGroup
              articles={featuredArticles}
              title="Featured Articles"
            />
            <hr className="help-center-landing_featured-divider" />
          </div>
          <div className="help-center-landing_featured-container">
            <div className="help-center-article-group_header-container">
              <h4>
                <b>Tips</b>
              </h4>
            </div>
            <div className="help-center-landing_tip-container">
              {tips.map(({ message, imageURL, id }) => (
                <div key={id} className="help-center-landing_tip-wrapper">
                  <TipCard message={message} imageURL={imageURL} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

HelpCenterLanding.propTypes = {
  featuredArticles: PropTypes.array,
  sections: PropTypes.array,
  tips: PropTypes.array,
  loadData: PropTypes.func,
  goToSection: PropTypes.func
};

const mapStateToProps = state => {
  return selectLandingPageData(state) || {};
};

const mapDispatchToProps = {
  loadData: loadHelpCenterLanding,
  goToSection: goToHelpCenterSection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpCenterLanding);

/**********************************************************
 * Section Cards
 **********************************************************/

function SectionCard({ imageURL, title, message, id, onClick }) {
  return (
    <div
      className="help-center-landing_section-card"
      onClick={() => onClick(id)}
    >
      <img
        className="help-center-landing_section-image"
        src={editCloudinarySrc(imageURL, "q_60,w_125,h_125,c_fill", "png")}
        alt={`${title} header image`}
      />
      <h3>
        <b>{title}</b>
      </h3>
      <div>{message}</div>
    </div>
  );
}

SectionCard.propTypes = {
  imageURL: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func
};

/**********************************************************
 * Tip Cards
 **********************************************************/

function TipCard({ message, imageURL }) {
  return (
    <div className="help-center-landing_tip-card">
      <img
        className="help-center-landing_tip-image"
        src={editCloudinarySrc(imageURL, "q_60,w_60,h_60,c_fill", "png")}
        alt={"tip icon"}
      />
      <div className="help-center-landing_tip-message">{message}</div>
    </div>
  );
}

TipCard.propTypes = {
  message: PropTypes.string,
  imageURL: PropTypes.string
};
