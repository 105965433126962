import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Calculator from "./Calculator";
import classNames from "classnames";
import Question from "../question/Question";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { Tooltip } from "components/ui/tooltip";
import { connect } from "react-redux";
import CommentBubble from "components/protocol/comments/CommentBubble";
import dotProp from "dot-prop";

export class Calculators extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedCalc: props.calculatorUsages[0].id,
      showTabs: false
    };
    this.totalUsageCount = props.calculatorUsages.concat(
      props.questions
    ).length;
  }
  static propTypes = {
    calculatorUsages: PropTypes.array,
    selections: PropTypes.array,
    outputs: PropTypes.array,
    questions: PropTypes.array,
    shouldShowCalcTabs: PropTypes.bool,
    sectionIndex: PropTypes.number,
    section: PropTypes.object,
    sectionVersionId: PropTypes.string,
    activeSelectedOutputs: PropTypes.array,
    relatedSections: PropTypes.object,
    setActiveGroup: PropTypes.func
  };
  selectCalcTab = selectedCalc => {
    this.setState({ selectedCalc });
    this.setState({ showTabs: false });
  };
  showTabs = () => {
    this.setState({ showTabs: !this.state.showTabs });
  };
  render() {
    const {
      calculatorUsages,
      selections,
      outputs,
      questions,
      shouldShowCalcTabs,
      activeSelectedOutputs,
      relatedSections,
      setActiveGroup
    } = this.props;
    const calcClasses = classNames("calculators", {
      "calculators--single": !shouldShowCalcTabs
    });
    const calcTabWidthClass =
      this.totalUsageCount < 5
        ? `react-tabs__tab--tabs-${this.totalUsageCount}`
        : "react-tabs__tab--tabs-overflow";
    const activeQuestions = questions.reduce((acc, questionUsage) => {
      const shouldShow =
        questionUsage.criteria.length === 0
          ? true
          : questionUsage.criteria.some(criteria => {
              return Object.keys(activeSelectedOutputs).some(
                outputId =>
                  activeSelectedOutputs[outputId].selectionId ===
                  criteria.selectionId
              );
            });
      if (shouldShow) acc.push(questionUsage.id);
      return acc;
    }, []);
    const inactiveTabClasses = classNames({
      "react-tabs__tab--shown": this.state.showTabs
    });
    const tabsToggleClasses = classNames({
      "icon-expand": !this.state.showTabs,
      "icon-expanded": this.state.showTabs
    });
    return (
      <div className={calcClasses}>
        {!shouldShowCalcTabs &&
          calculatorUsages.map(calcUsage => (
            <Calculator
              key={calcUsage.id}
              calculatorUsage={calcUsage}
              selections={selections}
              outputs={outputs}
              sectionIndex={this.props.sectionIndex}
              currentSectionId={this.props.section.id}
              relatedSections={relatedSections}
              setActiveGroup={setActiveGroup}
              withoutTab
            />
          ))}
        {shouldShowCalcTabs && (
          <Tabs>
            <TabList>
              {calculatorUsages.map(calcUsage => {
                const calcTabClasses = classNames(
                  "calculator-tab",
                  "row",
                  "align-middle",
                  "collapse",
                  {
                    "calculator-tab--active":
                      this.state.selectedCalc === calcUsage.id,
                    "calculator-tab_redflag":
                      calcUsage.calculator.variation === "redflag"
                  }
                );
                return (
                  <Tab
                    className={`react-tabs__tab ${calcTabWidthClass} ${inactiveTabClasses}`}
                    key={calcUsage.id}
                    onClick={() => this.selectCalcTab(calcUsage.id)}
                  >
                    <Tooltip
                      id="calculator-tab-tooltip"
                      className="calculator-tab_tooltip"
                      message="Select and complete one question or calculator."
                      place="top"
                    >
                      <div className={calcTabClasses}>
                        <div className="calculator-tab_text columns">
                          <span
                            className={`calculator-tab_icon ${
                              calcUsage.calculator.variation === "redflag"
                                ? "icon-flag"
                                : "icon-calculator"
                            }`}
                          />
                          <p>{calcUsage.calculator.title}</p>
                          <CommentBubble
                            style={{ marginLeft: ".5rem" }}
                            itemId={calcUsage.id}
                            usageId={calcUsage.id}
                            internalName={dotProp.get(
                              calcUsage,
                              "calculator.title",
                              ""
                            )}
                            locationDescription="Calculator"
                            condensed
                          />
                        </div>
                      </div>
                    </Tooltip>
                  </Tab>
                );
              })}
              {questions.reduce((acc, questionUsage) => {
                const shouldShow = activeQuestions.includes(questionUsage.id);
                if (shouldShow) {
                  const calcTabClasses = classNames(
                    "calculator-tab",
                    "row",
                    "align-middle",
                    "collapse",
                    "calculator-tab--question",
                    {
                      "calculator-tab--active":
                        this.state.selectedCalc === questionUsage.id
                    }
                  );
                  acc.push(
                    <Tab
                      className={`react-tabs__tab ${calcTabWidthClass} ${inactiveTabClasses}`}
                      key={questionUsage.id}
                      onClick={() => this.selectCalcTab(questionUsage.id)}
                    >
                      <Tooltip
                        id="calculator-tab-tooltip"
                        className="calculator-tab_tooltip"
                        message="Select and complete one question or calculator."
                        place="top"
                      >
                        <div className={calcTabClasses}>
                          <div className="columns shrink" />
                          <div className="calculator-tab_text columns">
                            <p>{questionUsage.question.title}</p>
                            <CommentBubble
                              style={{ marginLeft: ".5rem" }}
                              itemId={questionUsage.id}
                              usageId={questionUsage.id}
                              internalName={dotProp.get(
                                questionUsage,
                                "question.title",
                                ""
                              )}
                              locationDescription="Question"
                              condensed
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </Tab>
                  );
                }
                return acc;
              }, [])}
            </TabList>
            {shouldShowCalcTabs && (
              <div
                className="react-tabs__toggle hide-for-medium"
                onClick={this.showTabs}
              >
                <i className={tabsToggleClasses} />
              </div>
            )}
            {calculatorUsages.map(calcUsage => (
              <TabPanel key={calcUsage.id}>
                <Calculator
                  calculatorUsage={calcUsage}
                  selections={selections}
                  outputs={outputs}
                  sectionIndex={this.props.sectionIndex}
                  currentSectionId={this.props.section.id}
                  relatedSections={relatedSections}
                  setActiveGroup={setActiveGroup}
                />
              </TabPanel>
            ))}
            {questions.reduce((acc, questionUsage) => {
              const shouldShow = activeQuestions.includes(questionUsage.id);
              shouldShow &&
                acc.push(
                  <TabPanel key={questionUsage.id}>
                    <Question
                      key={questionUsage.id}
                      questionUsage={questionUsage}
                      outputs={outputs}
                      usageId={questionUsage.id}
                      section={this.props.section}
                      sectionVersionId={this.props.sectionVersionId}
                      question={questionUsage.question}
                      relatedSections={relatedSections}
                      setActiveGroup={setActiveGroup}
                    />
                  </TabPanel>
                );
              return acc;
            }, [])}
          </Tabs>
        )}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = state => {
    const getSelectedOutputs = makeSelectedOutputs();
    return {
      activeSelectedOutputs: getSelectedOutputs(state)
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(Calculators);
