import PropTypes from "prop-types";
import React, { Component } from "react";
import RxGroup from "./RxGroup";
import { connect } from "react-redux";
import dotProp from "dot-prop";
export class PrescriptionSet extends Component {
  render() {
    const {
      prescriptionSet,
      prescriptionSetLength,
      medProfileMetaTimestamp
    } = this.props;
    return (
      medProfileMetaTimestamp && (
        <div className="prescription-set">
          {prescriptionSet.structure.groups.map((group, groupIndex) => {
            return (
              <RxGroup
                key={group.id}
                group={group}
                internalName={prescriptionSet.internalName}
                prescriptionsWithProfiles={prescriptionSet.prescriptions}
                rxSetId={prescriptionSet.id}
                rxSetLength={prescriptionSetLength}
                groupLength={prescriptionSet.structure.groups.length}
              />
            );
          })}
        </div>
      )
    );
  }
}

PrescriptionSet.propTypes = {
  prescriptionSet: PropTypes.object,
  prescriptionSetLength: PropTypes.number,
  medProfileMetaTimestamp: PropTypes.number
};

const mapStateToProps = state => {
  return {
    medProfileMetaTimestamp: dotProp.get(
      state,
      "smallLists.medProfileMeta.timestamp"
    )
  };
};

export default connect(mapStateToProps)(PrescriptionSet);
