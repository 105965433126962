import React from "react";
import PropTypes from "prop-types";
import McgContentItems from "./McgContentItems";
import TextWithCitation from "./TextWithCitation";

const McgIndicationsComponent = ({
  guidelineWithStatus = [],
  selectedIndicationIds,
  indicationIdsBeingFetched,
  isCheckingStatus,
  selectedGuideline,
  handleCheckMcg
}) => {
  // the nested guideline content comes as the first item in the array
  const [content = {}] = guidelineWithStatus;
  return (
    <div key={content.Id} className={"indication-wrapper"}>
      <div className="indication_first-indication">
        <TextWithCitation item={content} />
      </div>
      {/* pass in initial level to know how much to indent */}
      {content.ContentItems && content.ContentItems.length > 0 && (
        <McgContentItems
          contentItems={content.ContentItems}
          level={1}
          isCheckingStatus={isCheckingStatus}
          selectedGuideline={selectedGuideline}
          handleCheckMcg={handleCheckMcg}
          selectedIndicationIds={selectedIndicationIds}
          indicationIdsBeingFetched={indicationIdsBeingFetched}
        />
      )}
    </div>
  );
};

McgIndicationsComponent.propTypes = {
  guidelineWithStatus: PropTypes.array,
  selectedIndicationIds: PropTypes.array,
  indicationIdsBeingFetched: PropTypes.array,
  isCheckingStatus: PropTypes.bool,
  selectedGuideline: PropTypes.object,
  handleCheckMcg: PropTypes.func
};

export default McgIndicationsComponent;
