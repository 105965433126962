import React, { Component } from "react";
import PropTypes from "prop-types";
import { createDosingString } from "helpers/parsing/meds";

export class MedicationItem extends Component {
  render() {
    const { prescriptions, prescriptionSetMode, singleMedMode } = this.props;

    return (
      <div className="patient-view-element">
        {prescriptions.map((prescription, prescriptionIndex) => (
          <div key={prescription.id} className="prescription_med">
            {prescriptionIndex > 0 && (
              <div className="prescription_mode">
                {prescriptionSetMode || "PLUS"}
              </div>
            )}
            {(!singleMedMode || prescriptionIndex === 0) && (
              <div className="patient-view-element_header">
                <strong>{prescription.medicationProfile.genericName}</strong>
              </div>
            )}
            <div className="patient-view-element_body">
              {createDosingString(prescription)}
            </div>
          </div>
        ))}
      </div>
      // {prescriptions[0].medicationProfile.outpatient_price && <div className='columns shrink patient-view-element_med-price'>${parseInt(prescriptions[0].medicationProfile.outpatient_price)}</div>}
    );
  }
}

MedicationItem.propTypes = {
  prescriptions: PropTypes.array,
  prescriptionSetMode: PropTypes.string,
  singleMedMode: PropTypes.bool
};

export default MedicationItem;
