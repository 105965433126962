import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withBottomDrawer from "./withBottomDrawer";
import classNames from "classnames";
import OutputItemSelectable from "components/patientEd/OutputItemSelectable";
import PatientEdAfterCare from "components/patientEd/PatientEdAfterCare";
import PatientEdFaq from "components/patientEd/PatientEdFaq";

class PatientResources extends Component {
  state = { activeTab: 1 };
  setTab = tab => () => this.setState({ activeTab: tab });

  render() {
    const { resources, faqs, patientEds } = this.props;
    return (
      <Fragment>
        <PatientEdDrawerTabs
          setTab={this.setTab}
          activeTab={this.state.activeTab}
        />
        <PatientResourcesContent
          activeTab={this.state.activeTab}
          resources={resources}
          patientEds={patientEds}
          faqs={faqs}
        />
      </Fragment>
    );
  }
}

PatientResources.propTypes = {
  resources: PropTypes.array.isRequired,
  faqs: PropTypes.array.isRequired,
  patientEds: PropTypes.array.isRequired
};
const PatientResourcesContent = ({
  activeTab,
  faqs,
  resources,
  patientEds
}) => {
  return (
    <Fragment>
      {activeTab === 1 && (
        <div className="patient-ed-resources_container">
          <div className="patient-ed-resources_subtitle">
            Here are answers to questions that patients with your condition
            commonly ask.
          </div>
          {faqs && faqs.map(faq => <PatientEdFaq key={faq.id} faq={faq} />)}
        </div>
      )}
      {activeTab === 2 && (
        <div className="patient-ed-resources_container">
          <div className="patient-ed-resources_subtitle">
            These resources may help you learn more about your condition.
          </div>
          <div className="patient-ed-resources_link-container">
            {resources &&
              resources.map(resource => (
                <div
                  key={resource.id}
                  className="patient-ed-resources_link-wrapper"
                >
                  <OutputItemSelectable
                    item={resource}
                    itemText={
                      <a
                        rel="noopener noreferrer"
                        className="patient-ed-resources_link"
                        href={resource.url}
                        target="_blank"
                      >
                        {resource.title}
                      </a>
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      {activeTab === 3 && (
        <div className="patient-ed-resources_container">
          <PatientEdAfterCare patientEds={patientEds} />
        </div>
      )}
    </Fragment>
  );
};

PatientResourcesContent.propTypes = {
  activeTab: PropTypes.number.isRequired,
  faqs: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
  patientEds: PropTypes.array.isRequired
};

const PatientEdDrawerTabs = ({ activeTab, setTab }) => {
  return (
    <div>
      <ul className="patient-ed-drawer-tabs">
        <Tab label="FAQs" active={activeTab === 1} onClick={setTab(1)} />
        <Tab label="Links" active={activeTab === 2} onClick={setTab(2)} />
        <Tab label="After Care" active={activeTab === 3} onClick={setTab(3)} />
      </ul>
    </div>
  );
};

PatientEdDrawerTabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired
};

const Tab = ({ label, active, onClick }) => {
  const tabClasses = classNames("patient-ed-drawer-tabs_tab", {
    "patient-ed-drawer-tabs_tab--active": active
  });

  return (
    <li className={tabClasses} onClick={onClick}>
      <div className="patient-ed-drawer-tabs_text">{label}</div>
    </li>
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default withBottomDrawer(PatientResources);
