import React, { Component } from "react";
import PropTypes from "prop-types";
import CareDecisionByCategory from "./CareDecisionByCategory";
import { CareDecisionIconKey } from "./CareDecisionIconKey";

export const APPROPRIATENESS_SETTINGS = {
  "2": {
    displayTitle: "Strongly Appropriate",
    icon: "icon-thumbs-up care-folder_thumbs-up-icon"
  },
  "1": {
    displayTitle: "Usually Appropriate",
    icon: "icon-thumbs-up care-folder_thumbs-up-icon"
  },
  "0": {
    displayTitle: "Sometimes Appropriate",
    icon: "icon-neutral-circle care-folder_neutral-circle-icon"
  },
  "-1": {
    displayTitle: "Rarely Appropriate",
    icon: "icon-thumbs-down care-folder_thumbs-down-icon"
  },
  "-2": {
    displayTitle: "Never Appropriate",
    icon: "icon-thumbs-down care-folder_thumbs-down-icon"
  }
};

export class CareDecision extends Component {
  constructor(props) {
    super();
    this.state = {
      sortBy: "appropriateness"
    };
  }
  handleSortBy = (e, sortBy) => {
    e.stopPropagation();
    this.setState({ sortBy });
  };

  render() {
    return (
      <div className="care-decision">
        <div className="care-decision_header row align-middle">
          <h5 className="care-decision_title columns">Care Decisions</h5>
        </div>
        <div className="care-decision_body">
          <CareDecisionByCategory
            careFolders={this.props.careFolders}
            openedFromClick={this.props.openedFromClick}
          />
        </div>
        <CareDecisionIconKey />
        {this.props.careDecisionCanLeadToNextSection && (
          <p className="care-decision_asterisk">* Answer Required</p>
        )}
      </div>
    );
  }
}
CareDecision.propTypes = {
  careFolders: PropTypes.array,
  openedFromClick: PropTypes.bool,
  careDecisionCanLeadToNextSection: PropTypes.bool
};
export default CareDecision;
