import { indexBy, prop } from "ramda";
import { fetchOrderMapApi } from "lib/ec/ehr";
import { getSelectedOrderData } from "selectors/orderstringSelectors";
import { getSelectedMedData } from "selectors/medSelectors";
import Moment from "moment";
import uuid from "uuid";

export const ORDERMAP_INITIAL_STATE = {};
export const INITIAL_STATE = {
  crosswalk: {},
  sentOrders: {},
  sentMeds: {}
};

export const FHIR_ORDERS_CLEAR_ORDERS = "FHIR_ORDERS_CLEAR_ORDERS";
export const FHIR_ORDERS_SET_ORDERMAP = "FHIR_ORDERS_SET_ORDERMAP";
export const FHIR_ORDERS_SUBMIT_ORDERS = "FHIR_ORDERS_SUBMIT_ORDERS";
export const FHIR_ORDERS_SET_HANDSHAKE_RESPONSE =
  "FHIR_ORDERS_SET_HANDSHAKE_RESPONSE";

export function clearOrders() {
  return {
    type: FHIR_ORDERS_CLEAR_ORDERS
  };
}

export function setOrderMap(orderMap) {
  return {
    type: FHIR_ORDERS_SET_ORDERMAP,
    payload: orderMap
  };
}

export function fetchOrdersMap({ orderStringsIds }) {
  return (dispatch, getState) => {
    const token = getState().user.token;
    return fetchOrderMapApi({ token, orderStringsIds }).then(({ data }) => {
      if (data.results.length) {
        const newResults = indexBy(prop("object_id"), data.results);
        dispatch(setOrderMap(newResults));
      }
    });
  };
}

export const setSentOrders = (selectedOrders, selectedMeds) => (
  dispatch,
  getState
) => {
  const state = getState().FHIR_orders;
  const timeStamp = Moment().format("LLL");

  // add time stamps to selected orders
  const ordersWithTimeStamps = selectedOrders.map(order => ({
    ...order,
    sent_at: timeStamp
  }));
  const medsWithTimeStamps = selectedMeds.map(med => ({
    ...med,
    sent_at: timeStamp,
    sentId: uuid.v4()
  }));
  // convert orders/meds arrays into objects so they can be merged into state
  const selectedOrdersObj = indexBy(prop("sentId"), ordersWithTimeStamps);
  const selectedMedsObj = indexBy(prop("sentId"), medsWithTimeStamps);

  // merge with existing orders/meds
  const sentOrders = { ...state.sentOrders, ...selectedOrdersObj };
  const sentMeds = { ...state.sentMeds, ...selectedMedsObj };

  dispatch({
    type: FHIR_ORDERS_SUBMIT_ORDERS,
    payload: { sentOrders, sentMeds }
  });
};

export function getOrderData() {
  return (dispatch, getState) => {
    const selectedOrders = getSelectedOrderData(getState());
    const selectedMeds = getSelectedMedData(getState());
    const token = getState().user.token;

    if (selectedMeds.length > 0 || selectedOrders.length > 0) {
      return {
        token: token,
        patientId: getState().patient.id,
        encounterId: getState().patient.encounter.id,
        selectedOrders,
        selectedMeds
      };
    } else {
      return null;
    }
  };
}

export function getHandshakeResponse() {
  return (dispatch, getState) => {
    return getState().FHIR_orders.handshakeResponse;
  };
}

export function setHandshakeResponse(response) {
  return {
    type: FHIR_ORDERS_SET_HANDSHAKE_RESPONSE,
    payload: response
  };
}

const ACTION_HANDLERS = {
  [FHIR_ORDERS_CLEAR_ORDERS]: (state, action) => INITIAL_STATE,
  [FHIR_ORDERS_SET_ORDERMAP]: (state, action) =>
    Object.assign({}, state, { crosswalk: action.payload }),
  [FHIR_ORDERS_SET_HANDSHAKE_RESPONSE]: (state, action) =>
    Object.assign({}, state, { handshakeResponse: action.payload }),
  [FHIR_ORDERS_SUBMIT_ORDERS]: (state, action) =>
    Object.assign({}, state, {
      sentOrders: action.payload.sentOrders,
      sentMeds: action.payload.sentMeds
    })
};
export default function protocolReducer(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
