import {
  PROTOCOLS,
  resultsCriteriaMap,
  MEDICATIONS,
  omniResultsCriteriaType
} from "constants/protocolCriteriaTypes";
import R from "ramda";
import {
  fetchProtocolsOmni,
  fetchProtocolOmniOptions,
  fetchProtocolsApi,
  fetchProtocolApi,
  makeOmniSearchQueryString
} from "lib/ec/protocol";
import orderBy from "lodash/orderBy";
import * as immutable from "object-path-immutable";
import keyBy from "lodash/keyBy";
import dotProp from "dot-prop";
import { pipelineRequest } from "store/pipeline";
import { EVENT_PROTOCOL_SEARCHED } from "constants/broadcastEventTypes";

// ------------------------------------
// Constants
// ------------------------------------
export const STARTPAGE_SET_CURRENT_TAB_TYPE = "STARTPAGE_SET_CURRENT_TAB_TYPE";
export const STARTPAGE_SEARCH_TEXT = "STARTPAGE_SEARCH_TEXT";
const STARTPAGE_CLEAR_SEARCH = "STARTPAGE_CLEAR_SEARCH";
const STARTPAGE_FETCH_RESULTS_FAILURE = "STARTPAGE_FETCH_RESULTS_FAILURE";
const STARTPAGE_RESULTS_FETCH_REQUEST = "STARTPAGE_RESULTS_FETCH_REQUEST";
const STARTPAGE_FETCH_RESULTS_SUCCESS = "STARTPAGE_FETCH_RESULTS_SUCCESS";
const STARTPAGE_SET_RESULTS = "STARTPAGE_SET_RESULTS";
export const STARTPAGE_SET_MORE_INFO = "STARTPAGE_SET_MORE_INFO";
export const PROTOCOLS_CLEAR_MORE_INFO = "PROTOCOLS_CLEAR_MORE_INFO";
export const STARTPAGE_SHOW_CLEAR_SEARCH_ICON =
  "STARTPAGE_SHOW_CLEAR_SEARCH_ICON";
export const STARTPAGE_CLEAR_SELECTED_CRITERIA =
  "STARTPAGE_CLEAR_SELECTED_CRITERIA";
export const STARTPAGE_SET_PROTOCOLS_CRITERIA_OPTIONS =
  "STARTPAGE_SET_PROTOCOLS_CRITERIA_OPTIONS";
export const STARTPAGE_SELECT_PROTOCOL_CRITERIA =
  "STARTPAGE_SELECT_PROTOCOL_CRITERIA";
export const STARTPAGE_SET_PROTOCOLS_LATEST = "STARTPAGE_SET_PROTOCOLS_LATEST";
const SET_MORE_INFO_PROTOCOL = "SET_MORE_INFO_PROTOCOL";
const STARTPAGE_RECEIVE_NEXT_RESULTS = "STARTPAGE_RECEIVE_NEXT_RESULTS";
const STARTPAGE_GETTING_NEXT_RESULTS = "STARTPAGE_GETTING_NEXT_RESULTS";
const STARTPAGE_IS_FETCHING_PROTOCOLS_LATEST =
  "STARTPAGE_IS_FETCHING_PROTOCOLS_LATEST";
const STARTPAGE_SET_LAST_SEARCH_PHRASE = "STARTPAGE_SET_LAST_SEARCH_PHRASE";
const STARTPAGE_PROTOCOLS_LATEST_FETCH_REQUEST =
  "STARTPAGE_PROTOCOLS_LATEST_FETCH_REQUEST";
const STARTPAGE_CLEAR_OMNI_TYPE = "STARTPAGE_CLEAR_OMNI_TYPE";
const STARTPAGE_SET_SEARCH_IS_POPULATED = "STARTPAGE_SET_SEARCH_IS_POPULATED";

// ------------------------------------
// Actions
// ------------------------------------

export const setProtocolCriteriaOptions = options => {
  const criteriaFilters = options.reduce((acc, curr) => {
    const group = dotProp.get(curr, "group");
    // removing groups such as "cpt_codes" that do not have filters associated with them
    if (curr.filters.length > 0) {
      const criteriaFilters = curr.filters.map(filter => ({
        ...filter,
        filterValue: filter.value,
        criteriaKey: group
      }));

      const categoryName = group.split(".") ? group.split(".")[1] : null;
      const formattedFilter = {
        categoryName,
        criteriaKey: group,
        type: curr.type,
        criteria: keyBy(criteriaFilters, "hash")
      };
      // moving 'Care Settings' filter to the top
      if (formattedFilter.categoryName === "Care Setting") {
        acc.unshift(formattedFilter);
      } else {
        acc = [...acc, formattedFilter];
      }
    }
    return acc;
  }, []);

  const criteriaFilterIds = options.reduce((acc, curr) => {
    return acc.concat(curr.filters.map(filter => filter.hash));
  }, []);
  return {
    type: STARTPAGE_SET_PROTOCOLS_CRITERIA_OPTIONS,
    payload: { criteriaFilters, criteriaFilterIds }
  };
};

export const resultsFetchRequest = ({
  searchText,
  type,
  page,
  filter: selectedProtocolCriteriaQuery,
  resultsKey
}) => ({
  type: STARTPAGE_RESULTS_FETCH_REQUEST,
  payload: {
    searchText,
    type,
    page,
    filter: selectedProtocolCriteriaQuery,
    resultsKey
  }
});

export const selectProtocolCriteria = ({ id }) => (dispatch, getState) => {
  const { selectedCriteriaFilterIds, currentTabType } = getState().startPage;
  const isSelected = selectedCriteriaFilterIds.some(criteriaId =>
    R.equals(criteriaId, id)
  );
  let updatedSelectedMedicationFilters = [];

  // user can only select one medication filter at a time
  if (currentTabType === MEDICATIONS) {
    updatedSelectedMedicationFilters = isSelected ? [] : [id];
  } else {
    updatedSelectedMedicationFilters = isSelected
      ? selectedCriteriaFilterIds.filter(crit => !R.equals(crit, id))
      : [...selectedCriteriaFilterIds, id];
  }
  dispatch({
    type: STARTPAGE_SELECT_PROTOCOL_CRITERIA,
    payload: updatedSelectedMedicationFilters
  });
  dispatch(getOmniResults());
};

const getSearchQuery = ({ selectedCriteriaFilterIds, criteriaFilters }) => {
  const criteriaByKey =
    criteriaFilters &&
    criteriaFilters.reduce(
      (acc, criteria) => ({ ...acc, ...criteria.criteria }),
      {}
    );
  const query =
    selectedCriteriaFilterIds &&
    selectedCriteriaFilterIds.reduce((acc, curr) => {
      const selectedCriteria = criteriaByKey[curr];
      if (!acc[selectedCriteria.criteriaKey]) {
        acc[selectedCriteria.criteriaKey] = [];
      }
      acc[selectedCriteria.criteriaKey].push(selectedCriteria.filterValue);
      return acc;
    }, {});
  return JSON.stringify(query);
};

export const getProtocolOmniOptions = ({
  type,
  isOnTabChange,
  isOnStartPageMount
} = {}) => (dispatch, getState) => {
  const { selectedCriteriaFilterIds, criteriaFilters } = getState().startPage;
  const selectedProtocolCriteriaQuery = getSearchQuery({
    selectedCriteriaFilterIds,
    criteriaFilters
  });
  const filter = isOnStartPageMount ? "{}" : selectedProtocolCriteriaQuery;

  if (!criteriaFilters.find(x => x.type === type)) {
    fetchProtocolOmniOptions({ filter, type, isOnTabChange }).then(
      ({ data }) => {
        dispatch(setProtocolCriteriaOptions(data));
      }
    );
  }
};

export const clearSearch = () => (dispatch, getState) => {
  dispatch({ type: STARTPAGE_CLEAR_SEARCH });
  dispatch(showClearSearchIcon({ showIcon: false }));
  dispatch(getOmniResults());
};

export const clearMoreInfo = () => ({
  type: PROTOCOLS_CLEAR_MORE_INFO
});

export const showClearSearchIcon = ({ showIcon }) => ({
  type: STARTPAGE_SHOW_CLEAR_SEARCH_ICON,
  payload: { showIcon }
});

export const setSearchText = ({ searchText }) => dispatch => {
  searchText === "" && dispatch(showClearSearchIcon({ showIcon: false }));
  dispatch({ type: STARTPAGE_SEARCH_TEXT, payload: searchText });
};

export const updateIsPopulated = () => ({
  type: STARTPAGE_SET_SEARCH_IS_POPULATED
});

const setOmniResults = ({ results, resultsType, queryString }) => ({
  type: STARTPAGE_SET_RESULTS,
  payload: { results, resultsType, queryString }
});

export const setCurrentTabType = ({ currentTabType }) => (
  dispatch,
  getState
) => {
  const resultsKey = resultsCriteriaMap[currentTabType];
  const {
    omniResults,
    selectedCriteriaFilterIds,
    criteriaFilters
  } = getState().startPage;
  const selectedProtocolCriteriaQuery = getSearchQuery({
    selectedCriteriaFilterIds,
    criteriaFilters
  });

  // here we clear selected filters on left-column of startpage
  // and fetch new omni options according to the selected tab type
  // there is currently no omni option for admission advisor
  if (resultsKey !== "admission") {
    dispatch(clearSelectedCriteria());
    dispatch(getProtocolOmniOptions({ type: resultsKey, isOnTabChange: true }));
  }
  dispatch(clearMoreInfo());
  dispatch({ type: STARTPAGE_SET_CURRENT_TAB_TYPE, payload: currentTabType });
  if (
    selectedProtocolCriteriaQuery !==
    omniResults[resultsKey].selectedProtocolCriteriaQuery
  ) {
    dispatch(getOmniResults());
  }
};

export const getOmniResults = ({ currentTabType, page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const {
    searchText,
    omniResults,
    selectedCriteriaFilterIds,
    criteriaFilters,
    moreInfo
  } = getState().startPage;
  const currentTabTypeLatest =
    currentTabType || getState().startPage.currentTabType;
  const resultsKey = resultsCriteriaMap[currentTabTypeLatest];
  const selectedProtocolCriteriaQuery = getSearchQuery({
    selectedCriteriaFilterIds,
    criteriaFilters
  });
  const type =
    resultsKey === resultsCriteriaMap[MEDICATIONS]
      ? omniResultsCriteriaType[MEDICATIONS]
      : resultsCriteriaMap[currentTabTypeLatest];
  const currentQueryString = makeOmniSearchQueryString({
    searchText,
    type,
    page,
    filter: selectedProtocolCriteriaQuery
  });

  moreInfo && dispatch(clearMoreInfo());
  if (currentQueryString !== omniResults[resultsKey].queryString) {
    dispatch(
      resultsFetchRequest({
        searchText,
        type,
        page,
        filter: selectedProtocolCriteriaQuery,
        resultsKey
      })
    );
    fetchProtocolsOmni({
      searchText,
      filter: selectedProtocolCriteriaQuery,
      type,
      page
    })
      .then(({ data }) => {
        const results =
          searchText === ""
            ? orderBy(
                data.results,
                [
                  protocol =>
                    protocol.data.title
                      ? protocol.data.title.toLowerCase()
                      : protocol.data.generic_name.toLowerCase()
                ],
                ["asc"]
              )
            : data.results;
        const orderedData = { ...data, results };
        if (searchText !== "") {
          dispatch({
            type: STARTPAGE_SET_LAST_SEARCH_PHRASE,
            payload: { searchText, resultsKey }
          });
          dispatch(showClearSearchIcon({ showIcon: true }));
        }
        dispatch({ type: STARTPAGE_FETCH_RESULTS_SUCCESS });
        page !== 1 && dispatch({ type: STARTPAGE_RECEIVE_NEXT_RESULTS });

        dispatch(
          pipelineRequest({
            action: EVENT_PROTOCOL_SEARCHED,
            message: {
              searchText: searchText,
              resultCount: data.results.length
            }
          })
        );

        dispatch(
          setOmniResults({
            results: orderedData,
            resultsType: currentTabTypeLatest,
            queryString: currentQueryString
          })
        );
      })
      .catch(error => {
        dispatch({ type: STARTPAGE_FETCH_RESULTS_FAILURE, error });
      });
  }
};

export const clearOmniResults = ({ omniType }) => ({
  type: STARTPAGE_CLEAR_OMNI_TYPE,
  payload: omniType
});

export const clearSelectedCriteria = () => ({
  type: STARTPAGE_CLEAR_SELECTED_CRITERIA
});

export const getProtocolsLatest = () => dispatch => {
  dispatch({ type: STARTPAGE_PROTOCOLS_LATEST_FETCH_REQUEST });
  return fetchProtocolsApi().then(({ data }) => {
    dispatch({
      type: STARTPAGE_SET_PROTOCOLS_LATEST,
      payload: { protocols: data }
    });
    return data.results;
  });
};

export const getMoreInfoProtocol = ({ protocolId }) => (dispatch, getState) => {
  fetchProtocolApi({ protocolId }).then(({ data: { results } }) => {
    if (results.length) {
      const [{ data, id }] = results;
      dispatch({ type: SET_MORE_INFO_PROTOCOL, payload: { id, data } });
      dispatch(startPageSetMoreInfo({ protocolId }));
    } else {
      throw new Error("Could not find protocol in Omni");
    }
  });
};

export const startPageSetMoreInfo = ({ protocolId }) => ({
  type: STARTPAGE_SET_MORE_INFO,
  payload: { protocolId }
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STARTPAGE_SET_RESULTS]: (state, action) => {
    const { results, resultsType, queryString } = action.payload;
    const resultsKey = resultsCriteriaMap[resultsType];
    return {
      ...state,
      omniResults: {
        ...state.omniResults,
        [resultsKey]: {
          ...state.omniResults[resultsKey],
          count: results.count,
          next: results.next,
          page: results.page,
          numPages: results.num_pages,
          previous: results.previous,
          results:
            results.page === 1
              ? results.results
              : [...state.omniResults[resultsKey].results, ...results.results],
          queryString
        }
      }
    };
  },
  [STARTPAGE_SELECT_PROTOCOL_CRITERIA]: (state, { payload }) => ({
    ...state,
    selectedCriteriaFilterIds: payload
  }),
  [STARTPAGE_SET_PROTOCOLS_CRITERIA_OPTIONS]: (state, { payload }) => ({
    ...state,
    isFetchingCriteriaFilters: false,
    criteriaFilterIds: [
      ...payload.criteriaFilterIds,
      ...state.criteriaFilterIds
    ],
    criteriaFilters: [...payload.criteriaFilters, ...state.criteriaFilters]
  }),
  [STARTPAGE_SET_LAST_SEARCH_PHRASE]: (state, { payload }) =>
    immutable.set(
      state,
      `omniResults.${payload.resultsKey}.lastSearchedPhrase`,
      payload.searchText
    ),
  [SET_MORE_INFO_PROTOCOL]: (state, { payload }) =>
    immutable.set(state, `protocols.${payload.id}`, {
      ...payload.data,
      id: payload.id
    }),
  [STARTPAGE_RESULTS_FETCH_REQUEST]: (state, { payload }) => {
    return {
      ...state,
      isFetchingOmniResults: true,
      omniResults: immutable.set(
        state.omniResults,
        `${payload.resultsKey}.inProgressSearch`,
        payload
      )
    };
  },
  [STARTPAGE_FETCH_RESULTS_SUCCESS]: state => ({
    ...state,
    isFetchingOmniResults: false
  }),
  [STARTPAGE_SET_MORE_INFO]: (state, { payload }) => ({
    ...state,
    moreInfo: state.protocols[payload.protocolId]
  }),
  [PROTOCOLS_CLEAR_MORE_INFO]: state => ({ ...state, moreInfo: null }),
  [STARTPAGE_CLEAR_SELECTED_CRITERIA]: state => ({
    ...state,
    selectedCriteriaFilterIds: []
  }),
  [STARTPAGE_IS_FETCHING_PROTOCOLS_LATEST]: state => ({
    ...state,
    isFetchingProtocolsLatest: true
  }),
  [STARTPAGE_SET_PROTOCOLS_LATEST]: (state, { payload }) => ({
    ...state,
    protocolsLatest: payload.protocols,
    isFetchingProtocolsLatest: false
  }),
  [STARTPAGE_PROTOCOLS_LATEST_FETCH_REQUEST]: state => ({
    ...state,
    isFetchingProtocolsLatest: true
  }),
  [STARTPAGE_SET_CURRENT_TAB_TYPE]: (state, { payload }) => ({
    ...state,
    currentTabType: payload
  }),
  [STARTPAGE_SEARCH_TEXT]: (state, { payload }) => ({
    ...state,
    searchText: payload
  }),
  [STARTPAGE_CLEAR_SEARCH]: state => ({
    ...state,
    searchText: "",
    searchIsPopulated: "cleared"
  }),
  [STARTPAGE_SET_SEARCH_IS_POPULATED]: state => ({
    ...state,
    searchIsPopulated: "set"
  }),
  [STARTPAGE_SHOW_CLEAR_SEARCH_ICON]: (state, action) => ({
    ...state,
    showClearSearchIcon: action.payload.showIcon
  }),
  [STARTPAGE_CLEAR_OMNI_TYPE]: (state, { payload }) =>
    immutable.set(state, `omniResults.${payload}`, defaultResults),
  [STARTPAGE_GETTING_NEXT_RESULTS]: state => ({
    ...state,
    isFetchingNextResults: true
  }),
  [STARTPAGE_RECEIVE_NEXT_RESULTS]: state => ({
    ...state,
    isFetchingNextResults: false
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const defaultResults = {
  lastSearchedPhrase: "",
  count: null,
  next: null,
  numPages: 0,
  page: null,
  previous: null,
  results: [],
  queryString: null
};
const initialState = {
  omniResults: {
    all: defaultResults,
    protocol: defaultResults,
    antibiotic: defaultResults,
    calculator: defaultResults,
    medicationprofile: defaultResults,
    admission: defaultResults,
    imaging: defaultResults
  },
  moreInfo: null,
  showClearSearchIcon: false,
  searchText: "",
  criteriaFilters: [],
  criteriaFilterIds: [],
  selectedCriteriaFilterIds: [],
  isFetchingOmniResults: false,
  isFetchingCriteriaFilters: false,
  protocolsLatest: [],
  currentPage: null,
  currentTabType: PROTOCOLS,
  isFetchingProtocolsLatest: false,
  protocols: {},
  searchIsPopulated: ""
};
export default function startPageReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
