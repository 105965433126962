import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { openCloudinary } from "store/account";
import { selectUserAvatarURL } from "store/user/selectors";

export function AccountEditAvatar({
  avatar,
  openCloudinary,
  handleUserSubmit
}) {
  return (
    <form onSubmit={handleUserSubmit} className="account-form">
      <div className="account-form_avatar">
        <img className="account-form_avatar-image" src={avatar} />
        <button className="button button-gray" onClick={openCloudinary}>
          Edit Photo
        </button>
      </div>
    </form>
  );
}

AccountEditAvatar.propTypes = {
  openCloudinary: PropTypes.func,
  avatar: PropTypes.string,
  handleUserSubmit: PropTypes.func
};

function mapStateToProps(state) {
  return {
    avatar: selectUserAvatarURL(state)
  };
}

const mapDispatchToProps = {
  openCloudinary
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountEditAvatar);
