import createCachedSelector from "re-reselect";
import R from "ramda";
import { getIndication } from "./helpers";
import {
  getSelectedOutputs,
  getSelectionDisplayNameById
} from "selectors/outputSelectors";
import dotProp from "dot-prop";

const getActiveProtocolPlugins = state =>
  state.plugins.protocolConfigs[state.session.activeProtocolId];

export const getMcgPlugin = state => {
  const activePlugins = getActiveProtocolPlugins(state);
  return dotProp.get(activePlugins, "MCG", {});
};

export const getActiveProtocol = state =>
  state.protocol[state.session.activeProtocolId] || {};

export const getIsAdmissionProtocol = state =>
  getActiveProtocol(state).type === "admission";

export const getMcgSessionData = state => state.session.mcgGuideline;

export const getAllMetaData = state => state.mcg.activeMetaData;

export const getMcgGuidelines = createCachedSelector(
  getActiveProtocol,
  getIsAdmissionProtocol,
  getMcgPlugin,
  (activeProtocol, isAdmissionProtocol, mcgPlugin) => {
    return isAdmissionProtocol
      ? [{ hsim: activeProtocol.context.hsim }]
      : dotProp.get(mcgPlugin, "config.hsims", []).map(guideline => {
          return {
            hsim: guideline,
            selectionId:
              mcgPlugin.plugin.hsim[guideline].selectedSelectionIdOnMet
          };
        });
  }
)(state => {
  // if it's an admission protocol we could use the context hsim, if not we could use the plugin hsim
  return `hsim:${
    getIsAdmissionProtocol(state)
      ? state.protocol[state.session.activeProtocolId].context.hsim
      : dotProp.get(
          state,
          `plugins.protocolConfigs[${state.session.activeProtocolId}][MCG]`,
          "null"
        )
  }`;
});

export const getShouldShowMcgLaunchButton = createCachedSelector(
  getSelectedOutputs,
  getMcgPlugin,
  (selectedOutputs, mcgPlugin) => {
    return (
      mcgPlugin &&
      R.values(selectedOutputs).some(
        output =>
          output.selectionId ===
          dotProp.get(mcgPlugin, "plugin.selectionIdTrigger", "")
      )
    );
  }
)(state => {
  return `plugin:${state.plugins.protocolConfigs[state.session.activeProtocolId]["MCG"].id}`;
});

export const getShouldShowAdmissionAdvisor = createCachedSelector(
  state => state,
  getIsAdmissionProtocol,
  (state, isAdmissionProtocol) => {
    return isAdmissionProtocol || state.mcg.showMcgGuidelines;
  }
)(state => `protocol:${state.session.activeProtocolId}`);

export const getSelectedGuideline = createCachedSelector(
  getIsAdmissionProtocol,
  getMcgSessionData,
  getActiveProtocol,
  getMcgGuidelines,
  (isAdmissionProtocol, sessionGuideline, activeProtocol, mcgGuidelines) => {
    // if there is a saved session, use the hsim from session
    const guideline = sessionGuideline.hsim
      ? sessionGuideline
      : isAdmissionProtocol
      ? {
          // if there isn't a saved session and it's an admission protocol get it from the activeProtocol context state.protocol[activeProtocolId].context.hsim -- there will only ever be one
          hsim: dotProp.get(activeProtocol, "context.hsim", ""),
          selections: []
          // if there isn't a saved session, and it's not an admission
          // protocol, then we need to get it from the plugin, and it
          // will be the first hsim in the plugins list since they are
          // ordered by which guideline is most appropriate for the protocol
        }
      : mcgGuidelines[0];

    return guideline;
  }
)(
  // there will only ever be one selected guideline per protocol
  state => `protocol:${state.session.activeProtocolId}`
);

export const getMcgContext = createCachedSelector(
  state => state,
  getActiveProtocol,
  getIsAdmissionProtocol,
  (state, activeProtocol, isAdmissionProtocol) => {
    return isAdmissionProtocol
      ? {
          [activeProtocol.context.hsim]: {
            ...activeProtocol.context,
            title: activeProtocol.title
          }
        }
      : state.mcg.context;
  }
)(state => `activeProtocol:${state.session.activeProtocolId}`);

export const getSelectedIndicationsEhr = createCachedSelector(
  state => state,
  state => {
    const formattedIndications =
      state.mcg.guidelineWithStatus.length > 0
        ? getIndication(state.mcg.guidelineWithStatus[0])
        : { text: "", indications: [] };
    return formattedIndications;
  }
)(state => {
  return `guideline:${state.session.mcgGuideline.hsim}`;
});

export const getMcgEhrText = createCachedSelector(
  state => state,
  getSelectedGuideline,
  getMcgSessionData,
  getMcgContext,
  (state, selectedGuideline, mcgSessionData, mcgContext) => {
    const selectionName = getSelectionDisplayNameById(
      state,
      mcgSessionData.selectedSelection
    );
    const title = dotProp.get(
      mcgContext,
      `${selectedGuideline.hsim}.title`,
      ""
    );
    const edition = dotProp.get(
      mcgContext,
      `${selectedGuideline.hsim}.edition`,
      ""
    );
    return {
      title,
      mcgSessionData,
      selectionName,
      edition
    };
  }
)(state => {
  return `guideline:${state.session.mcgGuideline.hsim}`;
});
