import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  handleImagingAdvisorCriteriaMet,
  handleCdsWebMessageResponse
} from "store/protocol";
import { pipelineRequest } from "store/pipeline";
import { EVENT_IA_ERROR } from "constants/broadcastEventTypes";

function withWebMessage(WrappedComponent) {
  class WebMessage extends Component {
    receiveWebMessage = event => {
      const { handleCdsWebMessageResponse } = this.props;
      console.log(`EC window received message from ${event.origin} `, event);
      if (event.origin !== window.origin) {
        console.log("EC window received message from UNKNOWN ORIGIN");
        pipelineRequest({ action: EVENT_IA_ERROR, message: event.data });
        return;
      } else if (event.data && event.data.responseToMessageId) {
        console.log("bout to handle webmessage");
        handleCdsWebMessageResponse(event);
        //removing the listener since we're only going to add it when a message is being posted
        window.removeEventListener("message", this.receiveWebMessage);
      } else {
        console.log("EC window received message and was unable to process");
        pipelineRequest({
          action: EVENT_IA_ERROR,
          message: event.data
        });
        return;
      }
    };

    sendWebMessage = () => {
      window.addEventListener("message", this.receiveWebMessage);
      this.props.handleImagingAdvisorCriteriaMet();
    };

    render() {
      return (
        <WrappedComponent
          sendWebMessage={this.sendWebMessage}
          {...this.props}
        />
      );
    }
  }

  const mapDispatchToprops = dispatch => ({
    handleImagingAdvisorCriteriaMet: () =>
      dispatch(handleImagingAdvisorCriteriaMet()),
    handleCdsWebMessageResponse: event =>
      dispatch(handleCdsWebMessageResponse({ payload: event }))
  });

  WebMessage.propTypes = {
    handleImagingAdvisorCriteriaMet: PropTypes.func,
    handleCdsWebMessageResponse: PropTypes.func
  };

  return connect(
    null,
    mapDispatchToprops
  )(WebMessage);
}

export default withWebMessage;
