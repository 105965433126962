import { SET_TERMS, SET_TERMS_AGREED } from "./constants";

// ------------------------------------
// Reducer
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_TERMS]: (state, { payload }) => ({ ...state, terms: payload }),
  [SET_TERMS_AGREED]: (state, { payload }) => {
    return { ...state, agreed: payload };
  }
};

const initialState = {
  // the terms content
  terms: [],

  // only FHIR envionments should start out with terms not agreed
  agreed: !__FHIR__
};
export default function termsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
