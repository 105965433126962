import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { setIndicationToOpen, setIndicationToClosed } from "../mcgReducer";
import Checkbox from "components/ui/Checkbox";
import TextWithCitation from "./TextWithCitation";

export class IndicationAccordian extends Component {
  constructor(props) {
    const { item, openIndications, text } = props;
    super();
    this.state = {
      isOpen: item
        ? openIndications.includes(item.Id)
        : openIndications.includes(text)
    };
  }

  handleCheck = e => {
    e.stopPropagation();
    const { level, parentId, item } = this.props;
    this.props.handleCheckMcg({
      indicationId: item.Id,
      level,
      parentIndicationId: parentId
    });
  };

  renderTitle = () => {
    const {
      definitionTitle,
      item,
      withCheckbox,
      isCheckingStatus
    } = this.props;
    const { isOpen } = this.state;
    const checkboxClasses = classNames("indication-check-box", {
      "indication-check-box--fetching": isCheckingStatus
    });
    return (
      <div className="row align-middle collapse">
        {withCheckbox && !item.Selected && (
          <div className="columns shrink" style={{ marginRight: ".5rem" }}>
            <Checkbox
              checkboxClassName={checkboxClasses}
              isSelected={item.Selected || false}
              name={item.Id}
              onChange={this.handleCheck}
            />
          </div>
        )}
        <span className="columns" onClick={e => this.handleClick(e)}>
          {definitionTitle && !isOpen ? (
            `${definitionTitle} `
          ) : (
            <TextWithCitation item={item} />
          )}
        </span>
      </div>
    );
  };

  handleClick = e => {
    const { isOpen } = this.state;
    const { item, setIndicationToOpen, setIndicationToClosed } = this.props;

    this.setState({ isOpen: !isOpen });
    if (isOpen) {
      setIndicationToClosed({ indicationId: item.Id });
    } else {
      setIndicationToOpen({ indicationId: item.Id });
    }
  };

  render() {
    const { item, isCheckingStatus, children, withCheckbox } = this.props;
    const { isOpen } = this.state;
    const iconClasses = classNames(
      "mcg-indication-accordian-expand-icon icon-expand",
      {
        "mcg-indication-accordian-expand-icon--open": isOpen
      }
    );
    return (
      <>
        <div
          onClick={e => this.handleClick(e)}
          className={"mcg-indication-accordian"}
        >
          <div className="mcg-indication-accordian-left-column">
            {!isCheckingStatus &&
              (item.Selected || item.Status === "Met" ? (
                <div className="mcg-indication-accordian-icon-wrapper">
                  <Checkbox
                    checkboxClassName="status-checker-check-box"
                    isSelected
                    onChange={withCheckbox && this.handleCheck}
                  />
                </div>
              ) : (
                <div className="mcg-indication-accordian-icon-wrapper">
                  <span className="mcg-expand-icon-outline" />
                  <div className={iconClasses} />
                </div>
              ))}
            {isCheckingStatus && (
              <span className="mcg-definition-status-loader" />
            )}
          </div>
          <div className="mcg-indication-accordian-title">
            {this.renderTitle()}
          </div>
        </div>
        {isOpen && <div>{children}</div>}
      </>
    );
  }
}

IndicationAccordian.propTypes = {
  children: PropTypes.object,
  text: PropTypes.string,
  item: PropTypes.object,
  openIndications: PropTypes.array,
  setIndicationToOpen: PropTypes.func,
  isCheckingStatus: PropTypes.bool,
  setIndicationToClosed: PropTypes.func,
  definitionTitle: PropTypes.string,
  withCheckbox: PropTypes.bool,
  level: PropTypes.number,
  parentId: PropTypes.string,
  handleCheckMcg: PropTypes.func
};

const mapStateToProps = state => ({
  openIndications: state.mcg.openIndications
});

const mapDispatchToProps = {
  setIndicationToOpen,
  setIndicationToClosed
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndicationAccordian);
