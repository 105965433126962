import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import dotProp from "dot-prop";
import R from "ramda";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { launchNextSection, setSelected } from "store/session";
import { protocolLaunchProtocol } from "store/protocol";
import classNames from "classnames";

import { resetAll, hideReview } from "store/review";
export class Review extends Component {
  static propTypes = {
    protocol: PropTypes.object,
    protocolId: PropTypes.string,
    resetAll: PropTypes.func,
    hideReview: PropTypes.func,
    protocolEntities: PropTypes.object,
    launchNextSection: PropTypes.func,
    protocolLaunchProtocol: PropTypes.func,
    sectionReview: PropTypes.object,
    selectedOutputs: PropTypes.array,
    setSelected: PropTypes.func
  };
  state = {
    showHelp: false
  };
  // componentWillMount = () => {
  //   this.props.resetAll()
  // }
  clearAll = () => {
    this.props.resetAll();
  };
  toggleViewHelp = () => {
    this.setState({ showHelp: !this.state.showHelp });
  };
  launchSection = ({
    isActive,
    outboundProtocol,
    currentSectionId,
    nextSectionId,
    outputId
  }) => {
    const {
      protocolLaunchProtocol,
      launchNextSection,
      setSelected
    } = this.props;
    return () => {
      if (isActive) {
        setSelected({ outputId });
        outboundProtocol
          ? protocolLaunchProtocol({
              protocolId: outboundProtocol.id,
              sectionId: nextSectionId
            })
          : launchNextSection({ currentSectionId, nextSectionId });
      }
    };
  };
  group = ({ group, sectionReview, rootId }) => (
    <div className="review-group">
      <h4 className="review-group_title">
        <span>{group.title}</span>
      </h4>
      <div className="review-group_sections">
        {group.sections.map(section => {
          const isActive = dotProp
            .get(
              this.props.protocolEntities,
              `${this.props.protocolId}.sectionsOrder`,
              []
            )
            .includes(section.id);
          const hasNoNextSections =
            R.values(section.nextSelectionsByNextSectionId).length === 0 &&
            section.defaultNextSections.length === 0;
          return (
            <div
              key={section.id}
              className={classNames("review-section", {
                "review-section--active": isActive
              })}
            >
              <h6 className="review-section_title">{section.internalName}</h6>
              <div className="review-section_inner-wrap">
                <div
                  className={classNames("review-section_selections", {
                    "review-section_selection--active": isActive
                  })}
                >
                  {R.values(section.nextSelectionsByNextSectionId).map(ns => {
                    const uniqueSelectionsByDisplayName = R.uniqBy(
                      sel => sel.displayName,
                      ns.selections
                    );
                    const selectionDisplayName = uniqueSelectionsByDisplayName.map(
                      (sel, i) =>
                        `${sel.displayName} ${
                          i !== uniqueSelectionsByDisplayName.length - 1
                            ? " | "
                            : ""
                        }`
                    );
                    return (
                      <div
                        className={classNames(
                          "row collapse align-middle review-section_selection",
                          {
                            "review-section_selection--new-protocol":
                              ns.outboundProtocol
                          }
                        )}
                        key={ns.nextId}
                        onClick={this.launchSection({
                          isActive,
                          outboundProtocol: ns.outboundProtocol,
                          currentSectionId: section.id,
                          nextSectionId: dotProp.get(ns, "nextSection.id"),
                          outputId: ns.output.id
                        })}
                      >
                        {isActive && (
                          <div className="columns shrink">
                            <div
                              className={classNames("review-section_radio", {
                                "review-section_radio-selected icon-radio-selected":
                                  ns.isSelected,
                                "icon-radio": !ns.isSelected
                              })}
                            />
                          </div>
                        )}
                        <div className="columns review-section_label-col">
                          {ns.outboundProtocol && (
                            <div className="review-section_sub-header">
                              Launches new protocol
                            </div>
                          )}
                          <div>
                            Selection:{" "}
                            <strong>
                              {selectionDisplayName}{" "}
                              {ns.selections.length > 1
                                ? `(${ns.selections.length})`
                                : ""}
                            </strong>
                          </div>
                          <div className="review-section_next-wrap">
                            <h5 className="review-section_next-header">
                              Navigates to:
                            </h5>
                            <div>
                              Group:{" "}
                              <strong>
                                {dotProp.get(
                                  ns,
                                  "nextGroup.title",
                                  "Error Not found"
                                )}
                              </strong>
                            </div>
                            <div>
                              Section:{" "}
                              <strong>
                                {dotProp.get(
                                  ns,
                                  "nextSection.internalName",
                                  "Error Not found"
                                )}
                              </strong>
                            </div>
                          </div>

                          {ns.outboundProtocol && (
                            <div>
                              Protocol:
                              <br />
                              <strong>
                                {dotProp.get(
                                  ns,
                                  "outboundProtocol.title",
                                  "Error Not found"
                                )}
                              </strong>
                              `
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {section.defaultNextSections.length > 0 && (
                  <div>
                    <h6 className="review-section_sub-header">
                      Default Next Section
                    </h6>
                    <div className="review-section_selections">
                      {section.defaultNextSections.map(dns => {
                        const isSelected = Object.keys(
                          this.props.selectedOutputs
                        ).includes(dns.outputId);
                        const radioClasses = classNames(
                          "review-section_radio",
                          {
                            "review-section_radio-selected icon-radio-selected": isSelected,
                            "icon-radio": !isSelected
                          }
                        );
                        return (
                          <div
                            key={dns.id}
                            className="row collapse align-middle review-section_selection"
                            onClick={this.launchSection({
                              outputId: dns.outputId,
                              isActive,
                              currentSectionId: section.id,
                              nextSectionId: dns.id
                            })}
                          >
                            {isActive && (
                              <div className="columns shrink">
                                <div className={radioClasses} />
                              </div>
                            )}
                            <div className="columns review-section_label-col">
                              {dns.internalName}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {isActive && hasNoNextSections && (
                  <p>
                    <strong>End Of Protocol</strong>
                  </p>
                )}
              </div>

              <div className="review-section_status-bar">
                <div className="row collapse">
                  <div className="columns">
                    {dotProp.get(
                      sectionReview,
                      `${section.id}.visited`,
                      false
                    ) && (
                      <div className="review-section_status review-section_status--visited">
                        Visted
                      </div>
                    )}
                    {!dotProp.get(
                      sectionReview,
                      `${section.id}.visited`,
                      false
                    ) && (
                      <div className="review-section_status review-section_status--not-visited">
                        Not Visited
                      </div>
                    )}
                  </div>
                  <div className="columns">
                    {isActive && (
                      <div className="review-section_status review-section_status--active">
                        Active
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  render() {
    const {
      protocol,
      protocolId,
      sectionReview,
      protocolEntities,
      selectedOutputs
    } = this.props;
    const selectedOutputIds = Object.keys(selectedOutputs);
    const sectionsByGroup =
      protocol &&
      protocol.sections &&
      R.values(protocol.sections).reduce(
        (acc1, sectionVersion, currentIndex) => {
          const section = sectionVersion.render;
          const defaultNextOutput = section.outputs.filter(
            op => op.nextId && !op.usageId && !op.selectionId
          );
          const defaultNextSections = defaultNextOutput.map(op => {
            return {
              ...section.sections.find(sec => sec.id === op.nextId),
              outputId: op.id
            };
          });
          const nextSelectionsByNextSectionId = section.outputs.reduce(
            (acc2, output) => {
              if (output.nextId) {
                const selection = section.selections.find(
                  sel => sel.id === output.selectionId
                );
                const isSelected = selectedOutputIds.includes(output.id);

                if (acc2[output.nextId]) {
                  selection && acc2[output.nextId].selections.push(selection);
                  acc2[output.nextId].outputIds.push(output.id);
                  if (isSelected) acc2.isSelected = true;
                  return acc2;
                }
                if (selection) {
                  const nextSection = section.sections.find(
                    sec => sec.id === output.nextId
                  );
                  const nextGroup =
                    nextSection &&
                    section.sectionGroups.find(
                      sg => sg.id === nextSection.groupId
                    );
                  const outboundProtocol =
                    nextSection && section.protocolId !== nextSection.protocolId
                      ? section.protocols.find(
                          p => nextSection && p.id === nextSection.protocolId
                        )
                      : null;
                  acc2[output.nextId] = {
                    selections: [selection],
                    nextSection,
                    nextGroup,
                    outboundProtocol,
                    isSelected,
                    output,
                    outputIds: [output.id]
                  };
                }
              }
              return acc2;
            },
            {}
          );
          const sectionWithInfo = {
            ...section,
            nextSelectionsByNextSectionId,
            defaultNextSections
          };
          acc1[section.groupId] = acc1[section.groupId]
            ? {
                ...acc1[section.groupId],
                sections: [...acc1[section.groupId].sections, sectionWithInfo]
              }
            : {
                sections: [sectionWithInfo],
                title: section.group.title,
                id: section.group.id
              };
          if (currentIndex === R.values(protocol.sections).length - 1) {
            const activeSections = dotProp.get(
              protocolEntities,
              `${protocolId}.sectionsOrder`,
              []
            );
            const updatedAcc = Object.keys(acc1).reduce((acc3, groupId) => {
              const sections = acc1[groupId].sections;
              const activeSection = sections.find(sec =>
                activeSections.includes(sec.id)
              );
              const sortedSections = activeSection
                ? [
                    activeSection,
                    ...sections.filter(sec => sec.id !== activeSection.id)
                  ]
                : sections;

              acc3[groupId] = { ...acc1[groupId], sections: sortedSections };
              return acc3;
            }, {});
            return updatedAcc;
          }
          return acc1;
        },
        {}
      );

    const groupOrder =
      sectionsByGroup &&
      dotProp
        .get(protocolEntities, `${protocolId}.sectionsOrder`, [])
        .map(sectionId => {
          const section = protocol.sections[sectionId];
          return section.render.groupId;
        });
    const innactiveGroups =
      groupOrder && R.difference(Object.keys(sectionsByGroup), groupOrder);
    return (
      <React.Fragment>
        <div className="review">
          {this.state.showHelp && (
            <div>
              <ul className="review_help">
                <li>
                  Quick select: You may make selections in review mode. This
                  will mark that selection as set and launch the next section in
                  the background. The review modal will show that selection as
                  selected and show the new section as active.{" "}
                </li>
                <li>
                  You may only make selections in active sections (emulates the
                  app and prevents you from entering a state not possible when
                  using the app)
                </li>
                <li>
                  A number wrapped with a parenthesis after the selection name
                  is denoting that there is a group of similar selections (all
                  point to the same next section).
                </li>
                <li>
                  If any of the display names in a section group differ, those
                  will be shown pipe delimited. Eg. &quot;Name One | Name
                  Two&quot;
                </li>
                <li>
                  If you choose to select a group of selections (a selection
                  with a number after it) in this review modal (opposed to
                  selecting one in the actual app), it will select a random
                  selection (eg. With 3 calculators and question answer that
                  lead to the same section, it will randomly select one of
                  them). As each of these selections may have different items
                  populate in the right column, this quick select feature should
                  not be used to verify right column items.
                  <br />
                </li>
                <li>
                  If a selection will launch a new protocol, it has a blue
                  background
                </li>
                <li>
                  Visit history is only recorded when review mode is on (toggle
                  is in the main hamburger menu).
                </li>
                <li>
                  Visit history only persists locally on the browser you are
                  currently using
                </li>
                <li>
                  The red &quot;Clear all protocols&apos; visit history&quot;
                  will clear all protocol view history, not just the one you are
                  viewing
                </li>
                <li>
                  At the bottom of every section, the status bar shows if you
                  have visited that section, and whether or not it is active (a
                  section that is currently visible in the app)
                </li>
              </ul>
            </div>
          )}
          <h3 className="review_header">Currently Visible</h3>
          <div className="review_button-bar">
            <button
              className="button button_standard button review_help-toggle-button"
              onClick={this.toggleViewHelp}
            >
              Toggle View Help
            </button>

            <button
              className="button button_standard button button_standard--warning"
              onClick={this.clearAll}
            >
              Clear all protocols&apos; visit history
            </button>
            <button
              className="icon-close review_close-button"
              onClick={this.props.hideReview}
            />
          </div>
          <div className="review-group_wrap">
            {groupOrder &&
              groupOrder.map(groupId => {
                return this.group({
                  group: sectionsByGroup[groupId],
                  sectionReview,
                  rootId: protocol.rootId
                });
              })}
          </div>
          <h3 className="review_header">Not currently visable</h3>
          <div className="review-group_wrap">
            {innactiveGroups &&
              innactiveGroups.map(groupId => {
                return this.group({
                  group: sectionsByGroup[groupId],
                  sectionReview,
                  rootId: protocol.rootId
                });
              })}
          </div>
        </div>
        <div className="review_click-under" onClick={this.props.hideReview} />
      </React.Fragment>
    );
  }
}
const makeMapStateToProps = () => {
  const getSelectedOutputs = makeSelectedOutputs();
  const mapStateToProps = state => {
    return {
      selectedOutputs: getSelectedOutputs(state),
      protocol: dotProp.get(
        state,
        `protocol.${state.session.activeProtocolId}`
      ),
      protocolId: state.session.activeProtocolId,
      sectionReview: state.review.section,
      protocolEntities: state.session.entities.protocols
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = {
  resetAll,
  hideReview,
  launchNextSection,
  protocolLaunchProtocol,
  setSelected
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Review);
