import createCachedSelector from "re-reselect";
import R from "ramda";
import dotProp from "dot-prop";
import { getSelectedOutputs } from "selectors/outputSelectors";

export const selectSection = (state, protocolId, sectionId) => {
  return dotProp.get(
    state,
    `protocol.${protocolId}.sections.${sectionId}.render`
  );
};

export const getSectionInfo = createCachedSelector(selectSection, section => {
  const sectionOutput =
    section &&
    section.outputs.find(
      op =>
        op.sectionId === section.id &&
        op.selectionId === null &&
        op.usageId === null
    );

  const relatedSections =
    section &&
    section.sections &&
    section.sections.reduce((acc, relSec) => {
      const protocol = section.protocols.find(
        protocol => relSec.protocolId === protocol.id
      );
      const group = section.sectionGroups.find(
        group => relSec.groupId === group.id
      );
      acc[relSec.id] = { ...relSec, protocol, group };
      return acc;
    }, {});

  const usages =
    section &&
    section.usages.reduce(
      (acc, usage) => {
        // finding seletion and adding to usage, this probably can be changed to only look add for careusages
        const selection =
          (usage.selectionId &&
            section.selections.find(
              selection => selection.id === usage.selectionId
            )) ||
          null;
        acc[usage.usageType] = [
          ...acc[usage.usageType],
          { ...usage, selection }
        ];
        return acc;
      },
      {
        questionusage: [],
        calculatorusage: [],
        careusage: [],
        evidenceusage: []
      }
    );

  const careUsagesWithOutputs =
    section &&
    usages.careusage.map(cu => {
      const output = section.outputs.find(
        op => op.usageId === cu.id && op.selectionId === cu.selectionId
      );
      return { ...cu, output };
    });
  const usagesByCarefolderId =
    careUsagesWithOutputs &&
    careUsagesWithOutputs.reduce((acc, cu) => {
      const parentSelectionIds =
        (cu.criteria && cu.criteria.map(crit => crit.selectionId)) || [];
      acc[cu.folderId] = {
        usages:
          acc[cu.folderId] && acc[cu.folderId].usages
            ? [...acc[cu.folderId].usages, cu]
            : [cu],
        parentSelectionIds:
          acc[cu.folderId] && acc[cu.folderId].parentSelectionIds
            ? [...acc[cu.folderId].parentSelectionIds, ...parentSelectionIds]
            : parentSelectionIds,
        id: cu.folderId,
        hasNextSection: Boolean(
          acc[cu.folderId]
            ? acc[cu.folderId].hasNextSection || cu.output.nextId
            : cu.output.nextId
        )
      };
      return acc;
    }, {});

  const careFolders =
    section &&
    section.careFolders.length > 0 &&
    section.careFolders.reduce((accFolder, cf) => {
      const childCareFolders = R.values(usagesByCarefolderId).reduce(
        (acc, cfPs) => {
          if (cf.id !== cfPs.id) {
            const isParent = cfPs.parentSelectionIds.some(selId =>
              usagesByCarefolderId[cf.id].usages.some(
                cu => cu.output && cu.output.selectionId === selId
              )
            );
            if (isParent)
              acc[cfPs.id] = {
                id: cfPs.id,
                hasNextSection: usagesByCarefolderId[cfPs.id].hasNextSection
              };
          }
          return acc;
        },
        {}
      );
      if (usagesByCarefolderId[cf.id]) {
        const usagesByFolderId = usagesByCarefolderId[cf.id].usages.map(u => {
          const hasChildQuestion = usages.questionusage.some(qu =>
            qu.criteria.some(crit => crit.selectionId === u.selectionId)
          );
          return { ...u, hasChildQuestion };
        });
        const selectionIds = usagesByFolderId.reduce(
          (acc, usage) => [
            ...acc,
            ...((usage.output && [usage.output.selectionId]) || [])
          ],
          []
        );
        const hasQuestionChild = usagesByFolderId.some(u => u.hasChildQuestion);
        const childFoldersHaveNextSection = R.values(childCareFolders).some(
          cf => cf.hasNextSection
        );
        const childUsageHasNextSection =
          childFoldersHaveNextSection || hasQuestionChild;
        accFolder[cf.id] = {
          ...cf,
          ...usagesByCarefolderId[cf.id],
          usages: cf.careUsagesOrdering
            ? cf.careUsagesOrdering.map(cuId =>
                usagesByFolderId.find(u => u.id === cuId)
              )
            : usagesByFolderId,
          selectionIds,
          hiddenByDefault:
            usagesByFolderId.length ===
            usagesByCarefolderId[cf.id].parentSelectionIds.length,
          allUsagesHaveCriteria: usagesByFolderId.every(
            cu => cu.criteria.length > 0
          ),
          hasNextSection: usagesByCarefolderId[cf.id].hasNextSection,
          childCareFolders,
          childUsageHasNextSection
        };
      }
      return accFolder;
    }, {});

  const careDecisionCanLeadToNextSection = R.values(careFolders).some(
    cf => cf.hasNextSection || cf.childUsageHasNextSection
  );

  const orderedCareFolders =
    section &&
    section.careFoldersOrdering.reduce((acc, cfId) => {
      if (careFolders[cfId]) {
        acc.push(careFolders[cfId]);
      }
      return acc;
    }, []);

  // array of all question usage ID's in specific order
  const evaluationOrdering = section && section.evaluationOrdering;
  // array of all question usage ID's in no specific order
  const questionUsageIds = usages ? usages.questionusage.map(qu => qu.id) : [];
  // checking to see if all the question usage ID's are in the evaluationOrdering array
  const shouldUseEvaluationOrdering = questionUsageIds.every(questionUsageId =>
    evaluationOrdering.includes(questionUsageId)
  );
  // if usages is falsey, return the original value
  // if we have question usages and evaluation, order them, otherwise return the original value
  const sortedUsages = !usages
    ? usages
    : {
        ...usages,
        // if all the question usage ID's exist in the evaluationOrdering array, use the ordered array
        // otherwise, use the original, non ordered question usage array
        questionusage: shouldUseEvaluationOrdering
          ? evaluationOrdering.reduce((acc, id) => {
              const questionUsage = usages.questionusage.find(
                usage => usage.id === id
              );
              return questionUsage ? [...acc, questionUsage] : acc;
            }, [])
          : usages.questionusage
      };

  const info = {
    sectionOutput,
    relatedSections,
    careUsagesWithOutputs,
    careDecisionCanLeadToNextSection,
    orderedCareFolders,
    usages: sortedUsages
  };
  return info;
})((state, protocolId, sectionId) => sectionId);

/**
 * Args: state, protocolId, sectionId
 */
export const getIsEndOfBranch = createCachedSelector(
  getSelectedOutputs,
  selectSection,
  getSectionInfo,
  (activeSelectedOutputs, section, sectionInfo) => {
    const {
      orderedCareFolders,
      usages,
      careDecisionCanLeadToNextSection,
      sectionOutput
    } = sectionInfo;

    /**
     * Currently need the following to know whether or not the state has the info (the section data is loaded) to make a decision
     * If there are no usages, we can't calculate anything
     */
    if (!usages) {
      return false;
    }
    // return false
    const firstCheck =
      usages.calculatorusage.length === 0 &&
      (usages.questionusage.length === 0 || careDecisionCanLeadToNextSection) &&
      ((sectionOutput && !sectionOutput.nextId) || !sectionOutput);
    if (!firstCheck) return false;

    const selectedSelectionIds = R.uniq(
      Object.keys(activeSelectedOutputs).map(
        opId => activeSelectedOutputs[opId].selectionId
      )
    );
    const careFoldersStates = R.values(orderedCareFolders).reduce((acc, cf) => {
      acc[cf.id] = {
        ...cf,
        shouldShow: cf.allUsagesHaveCriteria
          ? cf.parentSelectionIds.some(psId =>
              selectedSelectionIds.includes(psId)
            )
          : true
      };
      return acc;
    }, {});
    const shouldNotShowEnd = R.values(careFoldersStates).some(cf => {
      if (cf.shouldShow) {
        // can you get to a next section from this care folder
        const selectedUsage = cf.usages.find(
          cu =>
            cu.folderId === cf.id &&
            selectedSelectionIds.includes(cu.output.selectionId)
        );
        if (selectedUsage && selectedUsage.hasChildQuestion) return true;
        if (selectedUsage && selectedUsage.output.nextId) return true;
        // if cf does not have selection and we have a next section, not end of branch
        if (
          !cf.selectMultiple &&
          !selectedUsage &&
          (cf.hasNextSection || cf.childUsageHasNextSection)
        )
          return true;
        if (selectedUsage) {
          // if we are selected but children have a branch
          const childrenHaveNextSection = R.values(cf.childCareFolders).some(
            cf => cf.hasNextSection && careFoldersStates[cf.id].shouldShow
          );
          const grandChildrenHaveNextSection =
            !childrenHaveNextSection &&
            R.values(cf.childCareFolders).some(
              cf => careFoldersStates[cf.id].childUsageHasNextSection
            );
          return childrenHaveNextSection || grandChildrenHaveNextSection;
        }
      }
    });
    return !shouldNotShowEnd;
  }
)((state, protocolId, sectionId, section) => sectionId);

export const getFirstEndOfBranchSectionId = createCachedSelector(
  (state, protocolId) => state,
  (state, protocolId) => protocolId,
  (state, protocolId) => {
    const openSections = dotProp.get(
      state,
      `session.entities.protocols.${protocolId}.sectionsOrder`
    );
    const sectionId =
      protocolId &&
      openSections &&
      openSections.find(sectionId => {
        return getIsEndOfBranch(state, protocolId, sectionId);
      });
    return sectionId;
  }
)((state, protocolId) => protocolId);
