import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { launchLogin } from "store/auth/actions";

/**
 * For now, hitting the logout route will just redirect users
 * to the login page automatically.
 *
 * Perhaps in the future we will add some distinct post-logout UI elements
 */
class Logout extends Component {
  componentDidMount() {
    this.props.launchLogin(true);
  }
  render() {
    return null;
  }
}

Logout.propTypes = {
  launchLogin: PropTypes.func
};

const mapDispatchToProps = {
  launchLogin
};

export default connect(
  null,
  mapDispatchToProps
)(Logout);
