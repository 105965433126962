import {} from "../../../public/static/fhir/fhir-client";
import { logSentryError } from "helpers/logging/error";
import uuid from "uuid";
import isEmpty from "lodash/isEmpty";
import R from "ramda";
import {
  ehrStringObject,
  ehrStringMarkup,
  inputGroupStringsMarkup,
  diagnosesTitleArray,
  diagnosesString
} from "helpers/ehr/ehr";

export class TSystem {
  // ToDo: Move this into the factory? It's redundant in each of the classes
  stu3 = "stu3";
  dstu2 = "dstu2" || "v/r2";
  specs = [this.stu3, this.dstu2];
  currentSpec;

  //*********************************************************
  //EMR Specific Routines
  //********************************************************
  getCurrentSpec(url) {
    return this.specs.filter(spec => url.includes(spec))[0];
  }

  clearForLaunch = (requestedScopes, returnedScopes) => {
    return requestedScopes
      .split(" ")
      .every(elem => returnedScopes.split(" ").indexOf(elem) > -1);
  };

  //*********************************************************
  // Patient data access methods
  //*********************************************************
  getPatient = patient => {
    return {
      id: patient.id,
      name: `${this.getPatientFirstName(
        this.currentSpec,
        patient.name
      )} ${this.getPatientLastName(this.currentSpec, patient.name)}`,
      mrn: this.getPatientMRN(this.currentSpec, patient.identifier),
      gender: this.getPatientGender(this.currentSpec, patient),
      birthDate: this.getPatientBirthDate(this.currentSpec, patient)
    };
  };

  getPatientFirstName = (spec, name = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2: {
        const firstName = name.find(name => name.use === "official");
        return firstName ? firstName.given.join(" ") : "anonymous";
      }
      default:
        return null;
    }
  };

  getPatientLastName = (spec, name = []) => {
    switch (spec) {
      case this.stu3: {
        const lastName = name.find(name => name.use === "official");
        return lastName ? lastName.family : null;
      }
      case this.dstu2: {
        const lastName = name.find(name => name.use === "official");
        return lastName ? lastName.family.join("") : null;
      }
      default:
        return null;
    }
  };

  getPatientMRN = (spec, identifier = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2: {
        const hasMRN = identifier[0];
        const value = hasMRN && hasMRN.value;
        return value;
      }
      default:
        return null;
    }
  };

  getPatientGender = (spec, patient) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2: {
        return patient.gender;
      }
      default:
        return null;
    }
  };

  getPatientBirthDate = (spec, patient) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2:
        return patient.birthDate;
      default:
        return null;
    }
  };

  //*********************************************************
  // Practitioner data access methods
  //*********************************************************
  getPractitioner = practitioner => {
    return {
      id: practitioner.id,
      name: `${this.getPractitionerFirstName(
        this.currentSpec,
        practitioner.name
      )} ${this.getPractitionerLastName(this.currentSpec, practitioner.name)}`,
      npi: this.getPractitionerNPI(this.currentSpec, practitioner.identifier)
    };
  };

  getPractitionerFirstName = (spec, name = []) => {
    switch (spec) {
      case this.stu3:
        return null;
      case this.dstu2:
        return null;
      default:
        return name[0] ? name[0].given.join(" ") : "anonymous";
    }
  };

  getPractitionerLastName = (spec, name = []) => {
    switch (spec) {
      case this.stu3:
        return null;
      case this.dstu2: {
        return null;
      }
      default:
        return name[0] ? name[0].family : "anonymous";
    }
  };

  getPractitionerNPI = (spec, identifier = []) => {
    let hasNPI = null;
    let value = null;
    switch (spec) {
      case this.stu3:
        return null;
      case this.dstu2:
        return null;
      default:
        hasNPI = identifier[0];
        value = hasNPI && hasNPI.value;
        return value;
    }
  };

  //*********************************************************
  // Observation data access
  //*********************************************************
  getObservations = ({ category, results }) => ({
    category,
    observations: results.map(result => {
      switch (category) {
        case "social-history":
          return {
            field: result.code.text,
            value:
              result.valueCodeableConcept && result.valueCodeableConcept.text
          };
        default:
          // vital-signs, laboratory, exam
          return {
            field: result.code.text,
            value: result.valueQuantity && result.valueQuantity.value,
            unit: result.valueQuantity && result.valueQuantity.unit,
            date: result.effectiveDateTime
          };
      }
    })
  });

  //*********************************************************
  // Fetch methods
  //*********************************************************
  fetchEncounterData(patientIdentifiers, ecToken) {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          smart.api
            .read({ type: "Encounter", id: smart.tokenResponse.encounter })
            .then(
              ({ data }) => {
                resolve({ encounter: data });
              },
              err => {
                reject(err);
                logSentryError(err);
              }
            );
        })
        .catch(err => logSentryError(err));
    });
  }

  fetchPatientData() {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          smart.patient.read().then(
            patient => {
              resolve(patient);
            },
            err => {
              reject(err);
              logSentryError(err);
            }
          );
        })
        .catch(err => logSentryError(err));
    });
  }

  fetchUserFhirData(ecToken) {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData().then(
        smart => {
          smart.user.read().then(
            user => {
              resolve(user);
            },
            err => {
              reject(err);
              logSentryError(err);
            }
          );
        },
        err => {
          reject(err);
          logSentryError(err);
        }
      );
    });
  }

  fetchPatientObservations(categoryType) {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          smart.patient.api
            .fetchAll({ type: `Observation?category=${categoryType}` })
            .then(
              results => {
                resolve(results);
              },
              err => {
                reject(err);
                logSentryError(err);
              }
            );
        })
        .catch(err => logSentryError(err));
    });
  }

  //*********************************************************
  // Document posting methods
  //*********************************************************
  makeEhrHtmlString = ({ ehrs, diagnoses, calcs, providerNote, reasons }) => {
    if (
      ehrs.length > 0 ||
      diagnoses.length > 0 ||
      calcs.length > 0 ||
      !isEmpty(reasons)
    ) {
      const finalEhrMarkup = Object.keys(ehrStringObject(ehrs)).map(
        ehrCategory => {
          return `<div><h3>${ehrCategory}</h3>
          ${ehrStringMarkup(ehrs)[ehrCategory].join("")}
          </div>`;
        }
      );
      const calcInputStrings = calcs =>
        calcs.length > 0 &&
        calcs.map(selectedCalc =>
          inputGroupStringsMarkup(selectedCalc).join("")
        );
      const calcStringMarkup =
        calcInputStrings(calcs).length > 0
          ? calcInputStrings(calcs).join("<br />")
          : "";
      const providerNoteMarkup = `${
        providerNote
          ? `<div><h2>Provider Comment: </h2><p>${providerNote}</p></div>`
          : ""
      } `;
      const diagnosisMarkup =
        diagnosesTitleArray(diagnoses).length > 0
          ? `<div><h2>${diagnosesString}</h2> <ul>${diagnosesTitleArray(
              diagnoses
            )
              .map(title => `<li>${title}</li>`)
              .join("")}</ul></div>`
          : "";

      const reasonsMarkup = !isEmpty(reasons)
        ? `<div><h3>Justification</h3><ul>
      ${R.values(reasons)
        .map(reason => `<li>${reason.text}</li>`)
        .join("")}</ul></div>`
        : "";

      return `${providerNoteMarkup} ${diagnosisMarkup} ${finalEhrMarkup.join(
        ""
      )} ${calcStringMarkup} ${reasonsMarkup}`;
    } else {
      return " ";
    }
  };

  wrapEhrHtmlString = ehrString => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"
        "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
     <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
     <head>
       <title>CDS Summary</title>
       <meta http-equiv="Content-Type" content="application/xhtml+xml; charset=UTF-8" />
       <meta http-equiv="Content-Style-Type" content="text/css" />
     </head>
     <body>
      <div>
        <h1>Evidence Care -- CDS Summary</h1>
        ${ehrString}
      </div>
     </body>
    </html>`;
  };

  makeEhrString = ({
    ehrs,
    diagnoses,
    calcs,
    reasons,
    providerNote,
    mcgItems
  }) => {
    if (
      ehrs.length > 0 ||
      diagnoses.length > 0 ||
      calcs.length > 0 ||
      !isEmpty(reasons)
    ) {
      // generate text from ehr items
      const finalEhrString = Object.keys(ehrStringObject(ehrs)).map(
        ehrCategory =>
          `${decode(ehrCategory)}\n${decode(
            ehrStringObject(ehrs)[ehrCategory].join("")
          )}`
      );

      // generate text calc strings
      const calcInputStrings = (calcs = []) =>
        calcs.map(selectedCalc => {
          return inputGroupStrings(selectedCalc);
        });
      const formattedCalcInputStrings = R.flatten(calcInputStrings(calcs)).join(
        ""
      );

      // generate reasons for deviation to ehr string
      const reasonsForDeviation = !isEmpty(reasons)
        ? `Justification\n\nThe following justification was used to make a decision in the care of this patient:\n${R.values(
            reasons
          )
            .map(reason => reason.text)
            .join("")}`
        : "";

      const providerNoteString = `${
        providerNote ? `Provider Comment: ${providerNote}\n\n ` : ""
      }`;

      const diagnosisString =
        diagnosesTitleArray(diagnoses).length > 0
          ? `${diagnosesString} \n -${diagnosesTitleArray(diagnoses).join(
              "\n-"
            )}\n\n `
          : "";

      const mcgItemString = mcgItems.items
        ? `Admission Criteria \n\n${mcgItems.summaryText} \n\n${
            mcgItems.subtitle
          } \n\n${mcgItems.indication} \n\n${mcgItems.items
            .map(item =>
              item.text.startsWith("-") ? item.text : `- ${item.text}`
            )
            .join("\n")} \n\n${mcgItems.copyright}`
        : "";

      return `${decode(providerNoteString)}${decode(diagnosisString)}${decode(
        summaryString
      )}\n\n${decode(finalEhrString.join("\n"))}${
        formattedCalcInputStrings ? decode(formattedCalcInputStrings) : ""
      }\n\n${decode(reasonsForDeviation)}\n\n${decode(mcgItemString)}`;
    } else {
      return "";
    }
  };

  wrapPatientEdHtmlString = (patientEdHtmlString, pe_description) => {
    const markup = `<?xml version="1.0" encoding="utf-8"?>
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"
          "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
        <head>
          <title>${pe_description}</title>
          <meta http-equiv="Content-Type" content="application/xhtml+xml; charset=UTF-8" />
          <meta http-equiv="Content-Style-Type" content="text/css" />
        </head>
        <body>
          ${patientEdHtmlString}
        </body>
      </html>`;

    return markup;
  };

  postEHR = (entry, callback, errback, patientIdentifiers, ecToken) => {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          smart.patient.api
            .create(entry)
            .then(
              // success or error never fires this first param.
              data => {
                if (data.status === "success") {
                  callback();
                } else {
                  errback(
                    {
                      status: data.status,
                      statusText: data.statusText,
                      responseText: data.responseText,
                      headers: data.getAllResponseHeaders(),
                      body: data
                    },
                    entry
                  );
                }
              }
            )
            .catch(err => logSentryError(err));
        })
        .catch(err => console.error(err));
    });
  };

  buildDocumentReference = ({
    user,
    patient,
    loinc,
    description,
    encodedHTML,
    plainText,
    patientIdentifiers
  }) => {
    const now = new Date().toISOString();
    return {
      resourceType: "DocumentReference",
      author: [
        {
          reference: `Practitioner/${user.FHIR_Practitioner.id}`
        }
      ],
      subject: {
        reference: `Patient/${patient.id}`
      },
      type: {
        coding: [
          {
            system: "http://loinc.org",
            code: `${loinc}`,
            display: "Summary of Episode Note"
          }
        ]
      },
      indexed: now,
      created: now,
      status: "current",
      docStatus: "preliminary",
      description: `${description}`,
      content: [
        {
          attachment: {
            contentType: "application/xhtml+xml;charset=utf-8",
            data: `${encodedHTML}`
          }
        }
      ],
      context: {
        encounter: {
          reference: `Encounter/${patient.encounter.id}`
        },
        period: {
          end: now
        }
      }
    };
  };

  //*********************************************************
  // CDS methods
  //*********************************************************
  getCDSAppContext = () => {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData().then(smart => {
        const appContext =
          smart.tokenResponse &&
          smart.tokenResponse.appContext &&
          JSON.parse(smart.tokenResponse.appContext);

        resolve(appContext);
      });
    });
  };

  sendWebMessage = ({ messageData }) => {
    try {
      console.log("Sending webMessage:", messageData);
      window.postMessage(messageData, "*");
    } catch (error) {
      return null;
    }
  };

  buildWebMessages = ({
    appContext,
    ordersFromResponse,
    messageType,
    consultationCode,
    AUCCriteria
  }) => {
    console.log("buildWebMessages appContext", appContext);
    console.log("buildWebMessages ordersFromResponse", ordersFromResponse);
    const webMessages = appContext.orders.map(order => {
      const webMessage = {
        messageId: uuid.v4(),
        messageType: messageType,
        //set the initial payload to be the procedureRequest resource from appContext
        payload: {
          resource: ordersFromResponse.find(o => o.key === order.id).resource //order.resource
        }
      };
      //set some extensions on the existing resource that contain the AUC data
      webMessage.payload.resource.extension = [
        {
          url: "http://fhir.org/argonaut/StructureDefinition/pama-rating",
          valueCodeableConcept: {
            coding: [
              {
                system: "http://fhir.org/argonaut/CodeSystem/pama-rating",
                code: this.translateAUCCriteria(AUCCriteria)
              }
            ]
          }
        },
        {
          url:
            "http://fhir.org/argonaut/StructureDefinition/pama-rating-qcdsm-consulted",
          valueUri: "https://api.evidence.care/cds-services"
        },
        {
          url:
            "http://fhir.org/argonaut/StructureDefinition/pama-rating-auc-applied",
          valueUri: "https://acsearch.acr.org/70910548971"
        },
        {
          url:
            "http://fhir.org/argonaut/StructureDefinition/pama-rating-consult-id",
          valueUri: "urn:uuid:" + consultationCode
        }
      ];
      return webMessage;
    });
    return webMessages;
  };

  translateAUCCriteria = AUCCriteria => {
    if (AUCCriteria === "appropriate") return "appropriate";
    else if (AUCCriteria === "not-appropriate") {
      return "inappropriate";
    } else if (AUCCriteria === "no-criteria-apply") {
      return "not-applicable";
    } else {
      return "unknown";
    }
  };

  //*********************************************************
  // Orders methods
  //*********************************************************
  ordersFromOrderMap = (orders, meds) => {
    return null;
  };

  buildTabList = (orders, meds) => {
    return null;
  };

  buildOrder = ({
    token,
    patientId,
    encounterId,
    selectedOrders,
    selectedMeds
  }) => {
    return null;
  };

  webMessageListener = event => {
    console.log(event);
  };

  initiateHandshake = () => {
    return null;
  };

  cleanupHandshake = handshakeListener => {
    return null;
  };

  processHandshake = () => {
    return null;
  };

  initiateOrder = (orderObject, orderListener) => {
    return null;
  };

  processOrderResponse = () => {
    return null;
  };

  cleanupOrderResponse = orderListener => {
    return null;
  };
}
