import React, { Fragment } from "react";
import PropTypes from "prop-types";
import OutputItemEditable from "components/patientEd/OutputItemEditable";
import R from "ramda";

const EhrSupportingStatement = ({ supportingStatementEhrs }) => {
  return (
    <Fragment>
      <div className="ehr_treatment-summary-text">
        Here is the evidence that was delivered to the patient
      </div>
      {R.values(supportingStatementEhrs).map(ehr => (
        <Fragment key={ehr.id}>
          <div className="ehr_treatment-course-container">
            <div className="ehr_category-title">{ehr.title}</div>
            <div className="ehr_category-subtitle">{ehr.subtitle}</div>
            {ehr.ehrItems.map(ehrItem => (
              <OutputItemEditable
                key={ehrItem.id}
                item={ehrItem}
                outputItemType="EHR_SUPPORTING_STATEMENT"
              />
            ))}
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default EhrSupportingStatement;

EhrSupportingStatement.propTypes = {
  supportingStatementEhrs: PropTypes.object.isRequired
};
