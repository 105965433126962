import React, { Component } from "react";
import PropTypes from "prop-types";
import ShowMultipleInput from "./ShowMultipleInput";
import { connect } from "react-redux";
import { setAllCalcInputsToUnselected } from "store/session";
import MoreInfoIcon from "../moreInfo/MoreInfoIcon";
class ShowInputGroupMultiple extends Component {
  render() {
    const {
      inputGroup,
      calcUsageId,
      index,
      inputGroupsLength,
      calculatorType
    } = this.props;
    return (
      <div className="calculator-input-group">
        <div key={inputGroup.id} className="calculator-input-group--multiple">
          <div className="row align-middle collapse">
            <div className="columns">
              <h5 className="calculator-multiple-input-group_title">
                {inputGroupsLength > 1 ? `${index + 1}.` : ""}{" "}
                {inputGroup.title}{" "}
                <span className="calculator-input-group_asterisk">*</span>
              </h5>
            </div>
            {inputGroup.evidence && (
              <div className="columns shrink">
                <MoreInfoIcon evidence={inputGroup.evidence} />
              </div>
            )}
          </div>
          {inputGroup.subtitle && (
            <h6 className="calculator-input-group_subtitle">
              {inputGroup.subtitle}
            </h6>
          )}
          {inputGroup.inputs &&
            inputGroup.inputs.map(input => (
              <ShowMultipleInput
                key={input.id}
                input={input}
                calcUsageId={calcUsageId}
                calculatorType={calculatorType}
              />
            ))}
        </div>
      </div>
    );
  }
}

ShowInputGroupMultiple.propTypes = {
  inputGroup: PropTypes.object.isRequired,
  calcUsageId: PropTypes.string,
  setAllCalcInputsToUnselected: PropTypes.func,
  index: PropTypes.number,
  inputGroupsLength: PropTypes.number,
  calculatorType: PropTypes.string
};

const mapDispatchToProps = {
  setAllCalcInputsToUnselected
};
export default connect(
  null,
  mapDispatchToProps
)(ShowInputGroupMultiple);
