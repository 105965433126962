import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./TourSelectorButton.scss";
import { showModal } from "store/modal/actions";

/**
 * Button attached to the edge of the screen that allows users to open the selection
 * modal for picking which tour to activate. Also allows users to reposition the button
 * by dragging it around the edge of the screen.
 */
const BORDER_RADIUS = 5;
class TourSelectorButton extends React.Component {
  constructor() {
    super();
    this.state = {
      calculatedStyles: {
        visibility: "hidden"
      },
      dragging: false,
      originalDimensions: null
    };

    this.buttonRef = React.createRef();

    // need synchronous access to this data and it
    // is never used in the React render logic
    this.dragStartTimeout = null;
  }

  // Note that this MUST be mounted to the window rather than the React element in order
  // to get the most accurate positioning. Moreover, do NOT set the button's draggable attribute
  // to true
  componentDidMount() {
    window.addEventListener("mousemove", this.dragHandler);
    window.addEventListener("mousedown", this.mouseDownHandler);
    window.addEventListener("mouseup", this.mouseUpHandler);

    const dimensions = this.buttonRef.current.getBoundingClientRect();
    this.setState({
      // we save and ONLY use the original dimensions of the button because calls to these APIs
      // are going differ depending on our rotation criteria
      originalDimensions: dimensions,

      // needs to be done after the initial mount so that the above calculations are accurate
      calculatedStyles: {
        bottom: "15vh",
        right: 0,
        transform: `rotate(-90deg) translateY(${dimensions.width}px)`,
        transformOrigin: "bottom left",
        borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.dragHandler);
    window.removeEventListener("mousedown", this.mouseDownHandler);
    window.removeEventListener("mouseup", this.mouseUpHandler);
  }

  // track when we click inside the button
  // wait X milliseconds before enabling drag logic
  // if the user releases the click (anywhere on the page)
  // before dragging is enabled, then activate the normal onClick behavior
  mouseDownHandler = e => {
    if (e.target === this.buttonRef.current) {
      this.dragStartTimeout = setTimeout(() => {
        this.setState({ dragging: true });
        this.dragStartTimeout = null;
      }, 250);
    }
  };

  mouseUpHandler = () => {
    if (this.dragStartTimeout) {
      clearTimeout(this.dragStartTimeout);
      this.dragStartTimeout = null;
      this.onClick();
    }

    //remove the animation override (if applicable)
    const newStyles = { ...this.state.calculatedStyles };
    delete newStyles.animation;

    this.setState({ dragging: false, calculatedStyles: newStyles });
  };

  // based on where the user drags their mouse after clicking on the button,
  // determine the absolute positioning of the button on the screen as well
  // as the rotation parameters
  dragHandler = e => {
    // only do calculations if we are in dragging mode
    if (this.state.dragging) {
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
      const { clientX: mouseX, clientY: mouseY } = e;
      const {
        width: elWidth,
        height: elHeight
      } = this.state.originalDimensions;

      // the new state of calculated styles (filled in below)
      const calculatedStyles = { animation: "none" };

      // determine what part of the screen the mouse is in
      const inTopHalf = mouseY < windowHeight / 2;
      const inLeftHalf = mouseX < windowWidth / 2;

      // calculate lowest number of pixels to the edges of the screen on each axis
      const distanceToEdgeVertical = inTopHalf ? mouseY : windowHeight - mouseY;
      const distanceToEdgeHorizontal = inLeftHalf
        ? mouseX
        : windowWidth - mouseX;

      // button should be bound on an X axis
      if (distanceToEdgeVertical < distanceToEdgeHorizontal) {
        //bind to either the top or bottom
        if (inTopHalf) {
          calculatedStyles.top = 0;
          calculatedStyles.borderRadius = `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`;
        } else {
          calculatedStyles.bottom = 0;
          calculatedStyles.borderRadius = `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`;
        }
        calculatedStyles[inTopHalf ? "top" : "bottom"] = 0;

        // calculated bottom assuming mouse is in the middle of the desired element position
        const calculatedLeft = mouseX - elWidth / 2;

        // make sure the button is not out of bounds
        if (calculatedLeft < 0) {
          calculatedStyles.left = 0;
        } else if (calculatedLeft + elWidth > windowWidth) {
          calculatedStyles.left = `${windowWidth - elWidth}px`;
        } else {
          calculatedStyles.left = `${calculatedLeft}px`;
        }

        // button should be bound on a Y axis
      } else {
        // attach to left / right and rotate counter-clockwise 90 deg
        // Note: these rotation settings preserve the "left" and "bottom" positioning
        // don't change unless you know what you are doing as this will impact the rest
        // of the positioning logic
        if (inLeftHalf) {
          calculatedStyles.left = 0;
          calculatedStyles.transform = `rotate(-90deg) translateY(${elHeight}px)`;
          calculatedStyles.transformOrigin = "bottom left";
          calculatedStyles.borderRadius = `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`;
        } else {
          calculatedStyles.right = 0;
          calculatedStyles.transform = `rotate(-90deg) translateY(${elWidth}px)`;
          calculatedStyles.transformOrigin = "bottom left";
          calculatedStyles.borderRadius = `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`;
        }

        // calculated bottom assuming mouse is in the middle of the desired element position
        const calculatedBottom = windowHeight - mouseY - elWidth / 2;

        // ensure that the bottom is not out of bounds
        if (calculatedBottom < 0) {
          calculatedStyles.bottom = 0;
        } else if (calculatedBottom + elWidth >= windowHeight) {
          calculatedStyles.bottom = `${windowHeight - elWidth}px`;
        } else {
          calculatedStyles.bottom = `${calculatedBottom}px`;
        }
      }

      this.setState({ calculatedStyles });
    }
  };

  // this is broken into a separate function so you can easily
  // see where you should add functionality that should occur on
  // a non-drag click
  onClick = () => {
    this.props.showModal("SELF_HELP");
  };

  render() {
    const { calculatedStyles } = this.state;
    return (
      <div className="tour-selector-button_wrap" style={calculatedStyles}>
        <button className={"tour-selector-button"} ref={this.buttonRef}>
          Self Help
        </button>
      </div>
    );
  }
}

TourSelectorButton.propTypes = {
  showModal: PropTypes.func
};

export default connect(
  null,
  { showModal }
)(TourSelectorButton);
