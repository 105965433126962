//
// Helper routines related to Chargify product handles
//

import productFeatures from "constants/plans";

export const isCore = subscription => {
  return subscription && subscription.product === "core";
};
export const isMonthlySubscription = subscription => {
  return subscription && subscription.product.handle === "ind-sub-monthly";
};

export const isCanceledSubscription = subscription => {
  return subscription && subscription.state === "canceled";
};

export const calculatePrice = (price, interval, intervalUnit) => {
  const displayAsYear = intervalUnit === "month" && interval === "12";
  return `${parseInt(price)}/${displayAsYear ? "year" : "month"}`;
};

export const calculateMonthlyPrice = (price, interval, intervalUnit) => {
  let monthlyPrice;
  switch (intervalUnit) {
    case "month":
      monthlyPrice = parseFloat(price) / interval;
      break;
    case "day":
      monthlyPrice = parseFloat(price) * (interval / 30);
      break;
    case "year":
      monthlyPrice = parseFloat(price) / interval / 12;
      break;
    default:
      monthlyPrice = 0;
  }
  return monthlyPrice % 1 !== 0 ? monthlyPrice.toFixed(2) : monthlyPrice;
};

export const calculateDiscountPrice = (price, percent) => {
  return ((Number(price) * (100 - percent)) / 100).toFixed(2);
};

export const priceToDollars = priceInCents => {
  return (priceInCents / 100).toFixed(2);
};

export const extendProducts = products => {
  return products.map(product => {
    return (
      productFeatures[product.handle] && {
        product,
        features: productFeatures[product.handle].features,
        excludedFeatures: productFeatures[product.handle].excludedFeatures
      }
    );
  });
};

// creates signup url fom the public signup page on the product and adds a reference to the user, first name, last name and adds coupon if there is one
export const chargifySignUpUrl = (product, user, account, coupon) => {
  return `${product.product.public_signup_pages[0].url}?reference=${
    user.alias
  }${account.firstName ? `&first_name=${account.firstName}` : ""}${
    account.lastName ? `&last_name=${account.lastName}` : ""
  }&email=${user.email}${coupon ? `&coupon_code=${coupon}` : ""}`;
};
