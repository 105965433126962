import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchCMESummary,
  fetchCMENewDisclosure,
  createCMETest,
  fetchCMEDisclosure
} from "store/account";
import { CME } from "constants/features";
import { hasFeature } from "helpers/user";
import { showModal } from "store/modal/actions";
import { xsmallImageUrl } from "helpers/urls/cloudinary";

import { EVENT_FEATURE_CME_VIEW } from "constants/broadcastEventTypes";

import CMEHistory from "./CMEHistory";
import FeatureBlock from "components/modals/FeatureBlock";
import { pipelineRequest } from "store/pipeline";

export class CMEComponent extends Component {
  componentDidMount() {
    const { user } = this.props;
    this.props.fetchCMESummary();
    hasFeature(user.features, CME) && this.props.fetchCMENewDisclosure();
    this.props.pipelineRequest({
      action: EVENT_FEATURE_CME_VIEW
    });
  }

  postTest = () => {
    this.props.createCMETest();
    this.showTest();
  };

  showDisclosures = e => {
    e.preventDefault();
    this.props.fetchCMEDisclosure();
    this.props.showModal("DISCLOSURE", {
      hideAgree: true
    });
  };

  showAgreement = e => {
    e.preventDefault();
    this.props.showModal("DISCLOSURE");
  };
  showTest = () => {
    this.props.showModal("CME_TEST");
  };

  tableRows = summary => {
    return summary.map(sum => (
      <tr key={sum.id}>
        <td>
          <span className="cme-summary_title">{sum.title}</span>
          {sum.subtitle && (
            <span className="cme-summary_subtitle">
              <br />
              {sum.subtitle && sum.subtitle}
            </span>
          )}
        </td>
        <td className="cme-summary_image">
          <img
            src={xsmallImageUrl(sum.accreditor.cloudinary_id)}
            alt={sum.accreditor.title}
          />
        </td>
        <td>{sum.credits}</td>
      </tr>
    ));
  };

  renderPromptAgreement() {
    return (
      <div className="account-col">
        <p>
          It looks like you haven&apos;t agreed to the CME Disclosures yet.
          Please
          <a href="#" onClick={this.showAgreement}>
            review and agree
          </a>{" "}
          in order to access CME credits that you&apos;ve accumulated.
        </p>
      </div>
    );
  }

  renderNoCMEActivity() {
    return (
      <div className="account-col">
        <p>
          It looks like you haven&apos;t accrued any CME activity yet. Check
          back here once you&apos;ve used some of our protocols.
        </p>
      </div>
    );
  }

  renderCME = hasAccess => {
    const { summary } = this.props;
    const showSummary = summary.length > 0;
    const totalCredits = summary.reduce(
      (acc, curr) => acc + parseFloat(curr.credits),
      0
    );
    return (
      <div className="account-col">
        {hasAccess && !showSummary && this.renderPromptAgreement()}
        <div className="account_header">
          <h2 className="account_title">CME</h2>
          <button className="button button-secondary" onClick={this.postTest}>
            Print Certificate
          </button>
        </div>
        <p>
          <a href="#" onClick={this.showDisclosures}>
            Review CME Disclosures
          </a>
        </p>
        <table className="cme-summary_table">
          <thead>
            <tr>
              <th>Protocols</th>
              <th width="150px">Accredited By</th>
              <th>Hours</th>
            </tr>
          </thead>
          <tbody>{this.tableRows(summary)}</tbody>
          <tfoot className="cme-summary_table_total_hours--active">
            <tr>
              <td>Total</td>
              <td />
              <td>{totalCredits}</td>
            </tr>
          </tfoot>
        </table>
        <CMEHistory />
      </div>
    );
  };

  renderMessage() {
    return (
      <div className="account-col">
        Explore, learn and take good care of your patients. Check back to watch
        your CME credits accumulate.
      </div>
    );
  }

  render() {
    return (
      <div className="cme-container">
        <FeatureBlock
          cta="SUBSCRIBE NOW"
          url="/account/subscriptions"
          title="CME"
          text="Your plan doesn't include this feature. Upgrade now and access your CME credits."
          feature={CME}
          overlay
          render={hasAccess =>
            this.props.total === 0 && hasAccess ? (
              this.renderNoCMEActivity()
            ) : this.props.total === 0 && !hasAccess ? (
              <div className="account-col" />
            ) : (
              this.renderCME(hasAccess)
            )
          }
        >
          <a href="#" onClick={this.showDisclosures}>
            Review CME Disclosures
          </a>
        </FeatureBlock>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  summary: state.account.cme.summary || [],
  total: state.account.cme.total,
  subscription: state.account.subscription
});
const mapDispatchToProps = {
  fetchCMESummary,
  fetchCMENewDisclosure,
  showModal,
  createCMETest,
  fetchCMEDisclosure,
  pipelineRequest
};

CMEComponent.propTypes = {
  summary: PropTypes.array,
  user: PropTypes.object,
  fetchCMENewDisclosure: PropTypes.func,
  showModal: PropTypes.func,
  createCMETest: PropTypes.func,
  fetchCMEDisclosure: PropTypes.func,
  total: PropTypes.number,
  fetchCMESummary: PropTypes.func,
  pipelineRequest: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMEComponent);
