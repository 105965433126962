import PropTypes from "prop-types";
import React from "react";
import ModalContainer from "components/protocol/containers/ModalContainer";
import MediaQuery from "react-responsive";
import CitationList from "./CitationList";

export const TableItem = ({ item }) => {
  const renderTable = () => (
    <div
      className="evidence-item-table"
      dangerouslySetInnerHTML={{ __html: item && item.data }}
    />
  );
  return (
    <div className=" evidence-item-table">
      <h6 className="evidence-item-table_title">{item.title}</h6>
      <MediaQuery minDeviceWidth={600}>
        {matches => {
          if (matches) {
            return (
              <ModalContainer title={item.title || ""}>
                {renderTable()}
              </ModalContainer>
            );
          } else {
            return renderTable();
          }
        }}
      </MediaQuery>
      {item && item.citations && <CitationList citations={item.citations} />}
    </div>
  );
};
TableItem.propTypes = {
  item: PropTypes.object
};
export default TableItem;
