import React from "react";
import PropTypes from "prop-types";
import R from "ramda";
import OrderStringCategory from "./OrderStringCategory";

export class OrderSetView extends React.Component {
  render() {
    const { orderStrings, groupedOrderStrings, duplicateTitles } = this.props;
    return (
      <div className="orderset">
        {orderStrings &&
          Object.keys(groupedOrderStrings).map((category, index) => {
            // grouping orderStrings by order ID
            const groupedOrderStringsById = R.groupBy(
              orderstring => orderstring.orderId
            )(groupedOrderStrings[category]);
            return (
              <OrderStringCategory
                category={category}
                orderStringsByOrderId={groupedOrderStringsById}
                orderStrings={orderStrings}
                groupedOrderStrings={groupedOrderStrings}
                duplicateTitles={duplicateTitles}
                key={index}
              />
            );
          })}
      </div>
    );
  }
}

OrderSetView.propTypes = {
  orderStrings: PropTypes.array,
  groupedOrderStrings: PropTypes.object,
  duplicateTitles: PropTypes.array
};

export default OrderSetView;
