export function selectTermsState(state) {
  return state.terms;
}

export function selectTerms(state) {
  return selectTermsState(state).terms;
}

export function selectHasAgreedToTerms(state) {
  return selectTermsState(state).agreed;
}
