import React from "react";
import PropTypes from "prop-types";
import TextItem from "./TextItem";
import TableItem from "./TableItem";
import MediaItem from "./MediaItem";
import GraphSetItem from "./GraphSetItem";

const items = {
  text: TextItem,
  table: TableItem,
  media: MediaItem,
  graph: GraphSetItem
};
const DisplayItem = ({ item, isInBlackAndWhiteMode }) => {
  const Component =
    items[item.itemType] ||
    function NotFound() {
      return <span>Item type not found</span>;
    };
  return (
    <Component isInBlackAndWhiteMode={isInBlackAndWhiteMode} item={item} />
  );
};

DisplayItem.propTypes = {
  item: PropTypes.object,
  isInBlackAndWhiteMode: PropTypes.bool
};

export default DisplayItem;
