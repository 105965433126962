import * as Sentry from "@sentry/browser";
import Notifications from "react-notification-system-redux";

import {
  TOUR_SET_STEP,
  TOUR_STOP,
  TOUR_RESUME,
  TOUR_PAUSE,
  TOUR_ACTIVATE_FEATURE,
  TOUR_START,
  TOUR_SET_FLOWS
} from "./constants";
import { fetchTours } from "lib/ec/sherpa";
import { history } from "myHistory";
import {
  waitForTarget,
  watchTarget,
  isTargetVisible
} from "helpers/dom/target";
import { scrollIntoView } from "helpers/dom/scroll";
import { showSherpaModal } from "store/modal/actions";
import { selectSherpaPermissions } from "store/user/selectors";
import { selectSherpaDraftMode } from "store/admin/selectors";
import { selectActiveTour, selectActiveTourSteps } from "store/tour/selectors";

// ------------------------------------
// Config
// ------------------------------------

// time a target can be hidden (but on the DOM) before the tour exists
const HIDDEN_TIMEOUT = 10000;

// the time we initially wait for a target to appear when starting a step
const INITIAL_WAIT_TIMEOUT = 2500;

// the time we wait for the user to return after removing the target
// from the DOM (potentially on accident)
const REMOVED_FROM_DOM_TIMEOUT = 5000;

// ------------------------------------
// Thunks
// ------------------------------------

/************  Initialization Logic ***************/

// load tour data from an external source
export function loadTours() {
  return (dispatch, getState) => {
    const state = getState();
    return fetchTours({
      ...selectSherpaPermissions(state),
      loadDrafts: selectSherpaDraftMode(state)
    })
      .then(flows => {
        dispatch(setFlows(flows));
        dispatch(activateFeature());
      })
      .catch(err => {
        Sentry.captureException(err);
        console.log(err);
        dispatch(setFlows([]));
        dispatch(activateFeature());
      });
  };
}

// initialization logic for starting a new tour from the beginning
export function startTour(flowName) {
  return (dispatch, getState) => {
    const { flows } = getState().tour;

    if (!Array.isArray(flows)) {
      console.error("Cannot start flow. SelfHelp not initialized!");
      return;
    }

    const flow = flows.find(flow => flow.name === flowName);

    Sentry.addBreadcrumb({
      category: "tour",
      message: "Staring tour " + flow.name,
      data: printForSentry(flow),
      level: Sentry.Severity.Info
    });

    if (!flow) {
      console.error(`Flow named ${flowName} does not exist!`);
      return;
    } else if (!Array.isArray(flow.steps) || flow.steps.length === 0) {
      console.error(`No steps on flow ${flowName}!`);
      return;
    }

    // start the tour
    dispatch({
      type: TOUR_START,
      payload: flowName
    });

    // move to step 0
    dispatch(setTourStep(0));

    // Checking to see if changing the page route is required
    const { start_route = "" } = flow;

    if (start_route !== "" && history.location.pathname !== start_route) {
      history.push(`${start_route}`);
    }
  };
}

/************  Runtime Logic ***************/

// progresses the tour to a different step
let stopMonitoringPreviousTarget = () => {};
export function setTourStep(stepIndex = 0) {
  return (dispatch, getState) => {
    // if was monitoring previous target, unsubscribe that monitor
    stopMonitoringPreviousTarget();

    const state = getState();
    const { tour } = state;
    const { activeFlow } = tour;
    const steps = selectActiveTourSteps(state);

    // stop the tour if the step index becomes out of bounds
    if (!steps || stepIndex < 0) {
      dispatch(tourCrash());
      return;
    } else if (stepIndex >= steps.length) {
      dispatch(tourEnd());
      return;
    }

    // wait for the indicated step target to appear before doing the transition
    const { target } = steps[stepIndex];
    const unsubscribe = waitForTarget(target, (targetEl, alreadyPresent) => {
      unsubscribe();

      // this timeout is a fix for two issues with using Joyride:
      // (1) need to wait for React to finish rendering before launching joyride
      // (2) if using the same target for subsequent steps this forces Joyride to unmount
      // and remount (otherwise won't point to the correct location)
      // todo investigate and fix (but make sure you test the above)
      setTimeout(
        async () => {
          Sentry.addBreadcrumb({
            category: "tour",
            message: "Moving to step " + stepIndex,
            data: printForSentry(steps[stepIndex]),
            level: Sentry.Severity.Info
          });
          dispatch({
            type: TOUR_SET_STEP,
            payload: stepIndex
          });

          // if the target is still not visible, extend the pause
          if (!isTargetVisible(target)) {
            dispatch(
              pauseTour(() => {
                stopMonitoringPreviousTarget();
                dispatch(tourManualStop(STOP_MESSAGE_TYPES.NAVIGATE));
              }, HIDDEN_TIMEOUT)
            );

            // try to resume by scrolling into view
            await scrollIntoView(targetEl);
            if (isTargetVisible(target)) {
              dispatch(resumeTour());
            }
          } else {
            dispatch(resumeTour());
          }
          stopMonitoringPreviousTarget = dispatch(
            monitorTarget(target, activeFlow)
          );

          // if the target was already on the page, no need to wait a full half second for the
          // rendering to complete (beware: this is an indeterministic gut call -- to be better
          // this should really only execute when the page has "settled" [e.g., finished the main
          // render])
        },
        alreadyPresent ? 250 : 500
      );
    });

    // pause the tour (waiting for the next target to appear)
    dispatch(
      pauseTour(() => {
        // don't wait forever, instead exit after being paused
        // for a period
        unsubscribe();
        dispatch(targetNotFound(target));
      }, INITIAL_WAIT_TIMEOUT)
    );
  };
}

// monitors the target, stopping and resuming the tour step
// when then element enters and leaves the DOM & visible screen
export function monitorTarget(target, activeTourName) {
  return dispatch => {
    // to keep things DRY, these functions are used
    // in the watching configuration
    // Note: some internal variables are not defined until
    // later in the scope, but that's fine because
    // these functions will never be executed on the current event loop
    const resume = () => {
      dispatch(resumeTour());
    };
    const exit = () => {
      unsubscribe();
      dispatch(tourManualStop(STOP_MESSAGE_TYPES.NAVIGATE));
    };

    // create an observer to handle visibility
    // changes for the target
    let waitingForScroll = false;
    const unsubscribe = watchTarget(target, {
      onRemoveFromDOM: () => {
        dispatch(pauseTour(exit, REMOVED_FROM_DOM_TIMEOUT));
      },
      onHidden: () => {
        dispatch(pauseTour(exit, HIDDEN_TIMEOUT));
      },
      onAddToDOM: async targetEl => {
        if (!isTargetVisible(target)) {
          waitingForScroll = true;
          await scrollIntoView(targetEl);
          waitingForScroll = false;
          if (isTargetVisible(target)) resume();
        } else {
          resume();
        }
      },
      onVisible: () => !waitingForScroll && resume()
    });

    return unsubscribe;
  };
}

/************  Pause Logic ***************/

// direct access to this timeout is ONLY allowed in this block
let _pauseTimer = null;

// pauses the tour and optionally takes a callback to
// be executed if the tour has been paused for `timeout` ms
export function pauseTour(callback, timeout = 5000) {
  return dispatch => {
    // clear any existing pause timers to enable
    // the ability to extend the pause
    clearPauseTimer();

    dispatch({
      type: TOUR_PAUSE
    });

    if (callback) {
      _pauseTimer = setTimeout(callback, timeout);
    }
  };
}

export function resumeTour() {
  return dispatch => {
    clearPauseTimer();
    dispatch({
      type: TOUR_RESUME
    });
  };
}

function clearPauseTimer() {
  if (_pauseTimer) {
    clearTimeout(_pauseTimer);
    _pauseTimer = null;
  }
}

/************  Stop Logic ***************/

export function tourEnd() {
  return (dispatch, getState) => {
    const tour = selectActiveTour(getState());
    if (tour) {
      const { endAction = "CLOSE", endActionInput = "" } = tour;
      switch (endAction) {
        case "OPEN_MODAL": {
          dispatch(stopTour());
          dispatch(showSherpaModal(endActionInput));
          return;
        }
        case "NEXT_TOUR": {
          dispatch(stopTour());
          dispatch(startTour(endActionInput));
          return;
        }
        case "CLOSE":
        default: {
          dispatch(tourManualStop(STOP_MESSAGE_TYPES.FINISH));
          return;
        }
      }
    }
  };
}

// user explicitly performs an action that stops a tour in the middle
export const STOP_MESSAGE_TYPES = {
  CLOSE: "CLOSE",
  NAVIGATE: "NAVIGATE",
  FINISH: "FINISH"
};
const STOP_MESSAGES = {
  [STOP_MESSAGE_TYPES.CLOSE]:
    "You closed the tour! If this was a mistake, please restart!",
  [STOP_MESSAGE_TYPES.NAVIGATE]:
    "You navigated away! If you wish to complete the tour, please restart.",
  [STOP_MESSAGE_TYPES.FINISH]: "You've completed this tour."
};
export function tourManualStop(messageType = STOP_MESSAGE_TYPES.CLOSE) {
  return (dispatch, getState) => {
    const tour = selectActiveTour(getState());
    if (tour) {
      dispatch(
        Notifications.info({
          title: tour.title,
          message: STOP_MESSAGES[messageType],
          position: "tr",
          autoDismiss: 10,
          action: {
            label: "Click to restart!",
            callback: () => {
              dispatch(startTour(tour.name));
            }
          }
        })
      );
      dispatch(stopTour());
    }
  };
}

// handler for tour crashing due to bad logic
export function tourCrash() {
  return (dispatch, getState) => {
    const tour = selectActiveTour(getState());
    if (tour) {
      dispatch(
        Notifications.info({
          title: tour.title,
          message: "Your tour ended unexpectedly. We're working on a fix!",
          position: "tr",
          autoDismiss: 10
        })
      );
      dispatch(stopTour());
    }
  };
}

// handler for the case where a tour crashes because a target is not found
export function targetNotFound(target) {
  return dispatch => {
    const message = `Did not find target: ${target}!`;
    console.error(message);
    Sentry.captureException(new Error(message));
    dispatch(tourCrash());
  };
}

// performs any necessary clean-up for ending the tour
// MUST always be called when a tour ends
export function stopTour() {
  return dispatch => {
    clearPauseTimer();
    dispatch({
      type: TOUR_STOP
    });
  };
}

// ------------------------------------
// Actions
// ------------------------------------

export function setFlows(flows = []) {
  return {
    type: TOUR_SET_FLOWS,
    payload: flows
  };
}

export function activateFeature() {
  return {
    type: TOUR_ACTIVATE_FEATURE
  };
}

// ------------------------------------
// Utilities
// ------------------------------------

// formats an object for display in sentry logs
// todo move to a util file
function printForSentry(obj) {
  if (Array.isArray(obj)) {
    return JSON.stringify(obj);
  } else if (typeof obj === "object" && obj !== null) {
    const retObj = {};
    Object.keys(obj).forEach(key => {
      retObj[key] = JSON.stringify(obj[key]);
    });
    return retObj;
  } else {
    return JSON.stringify(obj);
  }
}
