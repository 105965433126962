import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import serialize from "form-serialize";
import classNames from "classnames";
import { sendGoodRxEmail, fetchGoodRx } from "lib/ec/meds";
import { Formik, Field } from "formik";
import { FieldTextComponent } from "components/ui/form";
import { object, string } from "yup";
import { pipelineRequest } from "store/pipeline";
import {
  EVENT_FEATURE_GOODRX_VIEW,
  EVENT_FEATURE_GOODRX_PRINT
} from "constants/broadcastEventTypes";

class GoodRx extends Component {
  state = {
    drugName: null,
    showSuccess: false,
    data: null,
    errorMessage: "",
    cheapestCouponUrl: null
  };
  componentDidMount() {
    const { token, goodRxUrl, pipelineRequest } = this.props;
    pipelineRequest({
      action: EVENT_FEATURE_GOODRX_VIEW
    });
    fetchGoodRx(token, goodRxUrl)
      .then(({ data }) => {
        this.setState({ data: data.data });
        this.findCheapestCoupon();
      })
      .catch(error => {
        const errorMessage = error.response.data.error.detail || "Error";
        this.setState({ errorMessage });
      });
  }
  handleSubmit = e => {
    e.preventDefault();
    const { medProfileId } = this.props;
    const formObj = serialize(e.target, { hash: true });
    if (formObj.email) {
      sendGoodRxEmail(this.props.token, formObj.email, medProfileId).then(
        () => {
          this.setState({ showSuccess: true });
          setTimeout(() => {
            this.setState({ showSuccess: false });
          }, 2000);
        }
      );
    }
  };
  findCheapestCoupon = () => {
    const { data } = this.state;
    const cheapestCoupon = data.prices
      .filter(drug => {
        return !isNaN(drug.price);
      })
      .reduce((prev, curr) => {
        return prev.price < curr.price ? prev : curr;
      });
    cheapestCoupon.coupon_url &&
      this.setState({ cheapestCouponUrl: cheapestCoupon.coupon_url });
  };

  sendPrintEvent = (e, drug) => {
    this.props.pipelineRequest({
      action: EVENT_FEATURE_GOODRX_PRINT,
      message: {
        drug_name: this.state.data.display,
        coupon_url: drug.coupon_url
      }
    });
  };

  goodRxEl() {
    const { data } = this.state;
    const successClasses = classNames("goodrx_success", {
      "goodrx_success--active": this.state.showSuccess
    });
    return (
      <div className="goodrx_modal-container">
        {data && <h4 className="goodrx_med-name">{data.display}</h4>}
        <div className="goodrx_section">
          <h5 className="goodrx_title">Price Comparison</h5>
          {data && (
            <table className="goodrx-table">
              <tbody>
                <tr>
                  <th>Pharmacy</th>
                  <th>Price</th>
                  <th className="goodrx_coupon-label">Coupon</th>
                </tr>
                {data.prices &&
                  data.prices.map((drug, i) => {
                    return (
                      <tr key={`row-${drug.pharmacy}`}>
                        <td>{drug.pharmacy}</td>
                        <td>${Math.round(drug.price)}</td>
                        <td className="goodrx_print-icon-container">
                          {drug.coupon_url && (
                            <a
                              className="goodrx_print"
                              onClick={e => this.sendPrintEvent(e, drug)}
                              rel="noopener noreferrer"
                              href={drug.coupon_url}
                              target="_blank"
                            >
                              <span className="goodrx_print-label">Print</span>{" "}
                              <i className="icon-print goodrx_print-icon" />
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        <div className="goodrx_section">
          <div>
            <h5 className="goodrx_title">Send Patient Coupon</h5>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={object().shape({
                email: string()
                  .email("Your email must be a valid email")
                  .required("Email is required")
              })}
              render={({ isValid, isSubmitting }) => (
                <form onSubmit={this.handleSubmit}>
                  <label className="goodrx-coupon-form_email-label">
                    Email Address<span className="required_asterisk">*</span>
                    <Field
                      type="text"
                      name="email"
                      placeholder="email"
                      component={FieldTextComponent}
                    />
                  </label>
                  <button
                    className="button button_standard"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Submit
                  </button>
                </form>
              )}
            />
          </div>
        </div>
        {this.state.showSuccess && (
          <div className={successClasses}>Email Sent</div>
        )}
        {data && data.url && (
          <a
            className="button goodrx_button goodrx_view-full-pharmacy"
            href={data.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            View Full Pharmacy Listing
          </a>
        )}
      </div>
    );
  }

  errorEl() {
    return (
      <h4 className="goodrx_error">
        We&apos;re sorry, but GoodRx does not have pricing information on this
        drug
      </h4>
    );
  }

  render() {
    return (
      <div>
        <div className="goodrx_logo" />
        <div>{this.state.errorMessage ? this.errorEl() : this.goodRxEl()}</div>
      </div>
    );
  }
}
GoodRx.propTypes = {
  token: PropTypes.string,
  goodRxUrl: PropTypes.string,
  medProfileId: PropTypes.number,
  pipelineRequest: PropTypes.func
};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = {
  pipelineRequest
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodRx);
