import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Disclosure from "./Disclosure";
import { createCMEAgreement } from "store/account";
import MarkdownRender from "utility/markdownRender";
import { EVENT_FEATURE_CME_DISCLOSURE_VIEW } from "constants/broadcastEventTypes";
import moment from "moment";
import { pipelineRequest } from "store/pipeline";

class CMEDisclosure extends Component {
  componentDidMount() {
    this.props.pipelineRequest({
      action: EVENT_FEATURE_CME_DISCLOSURE_VIEW
    });
  }
  agree = e => {
    this.props.createCMEAgreement();
  };
  prepDisclosure = disclosure =>
    disclosure.map(disc => {
      return disc.accreditor ? (
        <Disclosure disclosure={disc} key={disc.id} />
      ) : null;
    });

  render() {
    const { disclosure = [], hideAgree = false } = this.props;
    const genericDisclosure =
      disclosure.filter(disc => !disc.medical_society)[0] || {};
    const disclosureComponent =
      disclosure.length > 0 ? this.prepDisclosure(disclosure) : null;
    return (
      <div className="cme-disclosure">
        {!hideAgree && (
          <p className="cme-disclosure_header-message">
            Users must agree to CME Pre-Activity Disclosures before being able
            to access their CME credits
          </p>
        )}
        <Tabs className="cme-disclosure_tabs">
          <TabList>
            <Tab>Accrediting Organizations</Tab>
            <Tab>General Disclosures</Tab>
          </TabList>
          <TabPanel>{disclosureComponent}</TabPanel>
          <TabPanel>
            <MarkdownRender source={genericDisclosure.text} />
            <p>
              Copyright {moment().year()} Medical Decisions, LLC. All rights
              reserved.
            </p>
          </TabPanel>
        </Tabs>
        {!hideAgree && (
          <div className="cme-disclosure_agree" onClick={this.agree}>
            I agree
          </div>
        )}
      </div>
    );
  }
}
CMEDisclosure.propTypes = {
  disclosure: PropTypes.array,
  hideAgree: PropTypes.bool,
  createCMEAgreement: PropTypes.func,
  pipelineRequest: PropTypes.func
};

const mapStateToProps = state => ({
  disclosure: state.account.cme.disclosure
});

const mapDispatchToProps = {
  createCMEAgreement,
  pipelineRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMEDisclosure);
