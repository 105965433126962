import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { clearSnackbar } from "store/ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Snackbar from "material-ui/Snackbar";

export class GlobalSnackBar extends Component {
  render() {
    return (
      <MuiThemeProvider>
        <Snackbar
          open={!this.props.snackbar.isHidden}
          message={this.props.snackbar.message}
          autoHideDuration={2000}
          onRequestClose={this.props.clearSnackbar}
        />
      </MuiThemeProvider>
    );
  }
}

GlobalSnackBar.propTypes = {
  clearSnackbar: PropTypes.func.isRequired,
  snackbar: PropTypes.object
};

const mapStateToProps = state => ({
  snackbar: state.ui.snackbar
});

const mapDispatchToProps = {
  clearSnackbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSnackBar);
