// returns true iff the child is a descendant in the DOM
// of the parent element (or is the parent element)
export function isDescendantInclusive(parent, child) {
  if (parent === child) return true;
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
