import { SET_TERMS, SET_TERMS_AGREED } from "store/terms/constants";
import { acceptAgreementApi, termsApi } from "lib/ec/agreements";

// ------------------------------------
// Thunks
// ------------------------------------

export const loadUserTerms = () => {
  return dispatch => {
    return termsApi()
      .then(({ data: { results } }) => {
        dispatch(setTermsAgreed(!results.some(term => !term.accepted)));
        dispatch(setTerms(results));
      })
      .catch(err => {
        console.log("Error fetching user terms: ", err);
      });
  };
};

export const acceptUserTerms = id => {
  return dispatch => {
    return acceptAgreementApi(id).then(() => {
      dispatch(setTermsAgreed(true));
    });
  };
};

// ------------------------------------
// Actions
// ------------------------------------

export function setTermsAgreed(agreed = false) {
  return {
    type: SET_TERMS_AGREED,
    payload: agreed
  };
}

export function setTerms(terms = []) {
  return {
    type: SET_TERMS,
    payload: terms
  };
}
