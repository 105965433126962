import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import uuid from "uuid";
import Reply from "./Reply";
import { Comment, Form, TextArea } from "semantic-ui-react";
import { showLoader, hideLoader } from "store/ui";
import Avatar from "./Avatar";
import axios from "axios";
import CommentSocket from "lib/commentSocket";
import dotProp from "dot-prop";
import R from "ramda";
import Moment from "moment";
import { addCommentReplyToProtocol } from "store/comments";
class EcComment extends Component {
  state = {
    showSuccess: false,
    data: null,
    newComment: "",
    replyTo: false,
    actionsOpen: true,
    hidden: false
  };

  submitReply = e => {
    const {
      firstName,
      lastName,
      email,
      protocolId,
      sectionId,
      outputs,
      userId,
      alias,
      ecToken,
      cloudinaryId,
      parentId
    } = this.props;
    e.preventDefault();
    const body = {
      markdown: this.state.newComment,
      firstName,
      lastName,
      ecToken,
      email,
      protocolId,
      sectionId,
      userId,
      alias,
      cloudinaryId,
      selectectedOutputs: R.values(outputs)
        .filter(op => op.selected)
        .map(op => op.id),
      id: uuid.v4()
    };
    const localAuthor = {
      firstName,
      lastName,
      email,
      userId,
      alias,
      cloudinaryId
    };

    if (this.props.socketIsConnected) {
      const commentBody = {
        ...body,
        author: localAuthor,
        ecToken,
        createdAt: Date.now()
      };
      CommentSocket.sendMessage({
        action: "createReply",
        data: { comment: commentBody, parentId }
      });
      this.setState({ newComment: "", replyTo: false });
      this.props.addCommentReplyToProtocol({
        protocolId,
        sectionId,
        comment: commentBody,
        parentId
      });
    }
  };
  replyTo = () => {
    console.log("setting reply to", this.props.parentId);
    this.setState({ replyTo: true });
  };
  archive = () => {
    const { ecToken, _id } = this.props;
    // this.props.removeCommentFromProtocol({ protocolId, _id, id })
    this.props.showLoader();
    axios({
      method: "PUT",
      url: `${COMMENT_SERVICE}/comments/${_id}`,
      headers: {
        Authorization: "Bearer " + ecToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({ archived: true })
    })
      .then(resp => {
        this.setState({ hidden: true });
        console.log(resp);
        // const newCom = resp.data
      })
      .catch(err => console.log(err))
      .finally(resp => {
        this.props.hideLoader();
      });
  };
  delete = () => {
    const { ecToken, _id } = this.props;
    // this.props.removeCommentFromProtocol({ protocolId, _id, id })
    this.props.showLoader();

    axios({
      method: "PUT",
      url: `${COMMENT_SERVICE}/comments/${_id}`,
      headers: {
        Authorization: "Bearer " + ecToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({ deleted: true })
    })
      .then(resp => {
        this.setState({ hidden: true });
        console.log(resp);
        // const newCom = resp.data
      })
      .catch(err => console.log(err))
      .finally(resp => {
        this.props.hideLoader();
      });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  render() {
    const { createdAt, markdown, replies, author, _id } = this.props;
    const displayName = !author
      ? "User"
      : author.firstName || author.lastName
      ? `${author.firstName} ${author.lastName}`
      : author.email;

    const scope = dotProp.get(this.props, "decodedEcToken.scope", []);
    const canResolve = scope.includes("group:Comment");
    const canDelete =
      scope.includes("group:Comment") ||
      dotProp.get(this.props, "author.userId") === this.props.userId;
    return (
      !this.state.hidden && (
        <React.Fragment>
          <Comment className="comment">
            <Avatar author={author} />

            <Comment.Content>
              {author && <Comment.Author as="a">{displayName}</Comment.Author>}
              <Comment.Metadata>
                <div>{Moment(createdAt).fromNow()}</div>
              </Comment.Metadata>
              <Comment.Text>{markdown}</Comment.Text>
              <Comment.Actions>
                {_id && (
                  <Comment.Action onClick={this.replyTo}>Reply</Comment.Action>
                )}

                {_id && canResolve && (
                  <Comment.Action
                    className="comment_hidden-action"
                    onClick={this.archive}
                  >
                    Resolve
                  </Comment.Action>
                )}
                {_id && canDelete && (
                  <Comment.Action
                    className="comment_hidden-action"
                    onClick={this.delete}
                  >
                    Delete
                  </Comment.Action>
                )}
              </Comment.Actions>
            </Comment.Content>
            {this.state.replyTo && (
              <Form className="comments_reply-form">
                <TextArea
                  placeholder="Reply to a comment"
                  onChange={this.handleChange}
                  rows={2}
                  name="newComment"
                  value={this.state.newComment}
                />

                <div className="text-right">
                  <button
                    disabled={this.state.newComment.length === 0}
                    className="button button_standard comments_reply-button"
                    onClick={this.submitReply}
                    labelPosition="left"
                    icon="edit"
                    primary
                  >
                    Reply
                  </button>
                </div>
              </Form>
            )}
            {replies && replies.length > 0 && (
              <Comment.Group>
                {replies.map(reply => {
                  return <Reply key={reply.id} {...reply} />;
                })}
              </Comment.Group>
            )}
          </Comment>
        </React.Fragment>
      )
    );
  }
}
const mapStateToProps = state => {
  return {
    protocolId: state.session.activeProtocolId,
    sectionId:
      state.session.entities.protocols[state.session.activeProtocolId]
        .activeSectionId,
    outputs: state.session.entities.outputs,
    email: state.user.email,
    firstName: state.user.first_name,
    lastName: state.user.last_name,
    alias: state.user.alias,
    userId: state.user.user_uid,
    ecToken: state.account.ecToken.token,
    cloudinaryId: state.user.cloudinary_id,
    socketIsConnected: state.comments.socketIsConnected,
    decodedEcToken: state.account.ecToken
  };
};
const mapDispatchToProps = {
  addCommentReplyToProtocol,
  showLoader,
  hideLoader
};
EcComment.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  createdAt: PropTypes.number,
  markdown: PropTypes.string,
  id: PropTypes.string,
  addCommentReplyToProtocol: PropTypes.func,
  decodedEcToken: PropTypes.object,
  socketIsConnected: PropTypes.func,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  email: PropTypes.string,
  protocolId: PropTypes.string,
  sectionId: PropTypes.string,
  outputs: PropTypes.array,
  userId: PropTypes.string,
  alias: PropTypes.string,
  ecToken: PropTypes.string,
  threadId: PropTypes.string,
  parentIndex: PropTypes.number,
  author: PropTypes.object,
  cloudinaryId: PropTypes.string,
  parentId: PropTypes.string,
  replies: PropTypes.array,
  _id: PropTypes.string
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EcComment);
