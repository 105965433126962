import { Auth0Lock } from "auth0-lock";

import { AUTH_SCOPE } from "constants/auth";

// Makes an Auth0 Lock object. Assigns it to this context. The Lock object is
// created with the passed-in Lock configuration object.
export function makeAuth0Lock(
  clientId,
  domain,
  {
    prefills = {},
    callbacks: { onAuthenticated, onAuthenticationError, onSignup }
  }
) {
  const config = makeLockConfiguration(prefills);
  const lock = new Auth0Lock(clientId, domain, config);
  lock.on("authenticated", onAuthenticated);
  lock.on("authorization_error", onAuthenticationError);
  lock.on("signup submit", onSignup);
  return lock;
}

// Factory for the Auth0 Lock configuration
const makeLockConfiguration = ({ code, email }) => {
  return {
    container: "login-container",
    closable: false,
    autofocus: true,
    mustAcceptTerms: true,
    socialButtonStyle: "big",
    allowShowPassword: true,
    defaultDatabaseConnection: "Email-Password-Authentication-With-Migration",
    configurationBaseUrl: "https://cdn.auth0.com",
    auth: {
      redirectUrl: `${APP_DOMAIN}/authcb`,
      responseType: "id_token",
      params: { scope: AUTH_SCOPE }
    },
    theme: {
      primaryColor: "#2A7DC3",
      logo: "https://s3.amazonaws.com/ecmediafiles/ec-logo.png",
      authButtons: {
        HealthStream: {
          icon:
            "https://res.cloudinary.com/evidencecare/image/upload/v1549678364/HealthStream.5a82124175e51_cte8wh.jpg"
        }
      }
    },
    additionalSignUpFields: [
      {
        name: "signupCode",
        placeholder: "Code (optional)",
        validator: address => {
          return { valid: true };
        },
        prefill: code
      }
    ],
    languageDictionary: {
      title: "",
      signUpTerms:
        'I am a healthcare professional and I agree to the <a style="color:#0000EE;" href="https://evidence.care/terms" target="_new">Terms of Service</a>.',
      forgotPasswordAction: "Reset Your Password",
      error: {
        signUp: {
          "lock.fallback":
            "Something went wrong signing up. Please ensure you're using a valid email account."
        }
      }
    },
    prefill: {
      email
    }
  };
};
