import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import McgComponent from "./McgComponent";
import classNames from "classnames";
import { getShouldShowAdmissionAdvisor } from "./mcgSelectors";
import {
  fetchMcgGuideline,
  clearMcgGuidelines,
  hideMcgGuidelines
} from "./mcgReducer";

class McgContainer extends React.Component {
  state = {
    isOpen: true
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.activeProtocolId &&
      prevProps.activeProtocolId !== this.props.activeProtocolId
    ) {
      this.props.hideMcgGuidelines();
    }
  }
  componentWillUnmount() {
    this.props.clearMcgGuidelines();
  }
  closeSection = () => {
    this.setState({ isOpen: false });
  };
  render() {
    const expandClasses = classNames("section_expand-icon", {
      "section_expand-icon--active icon-expanded": this.state.isOpen,
      "icon-expand": !this.state.isOpen
    });
    const sectionClasses = classNames("section", {
      "section--active": this.state.isOpen
    });
    const { shouldShowMcg } = this.props;
    return shouldShowMcg ? (
      <div className={sectionClasses}>
        <div
          className="section_header-wrap"
          onClick={() => this.setState(({ isOpen }) => ({ isOpen: !isOpen }))}
        >
          <h5 className="section_header">Admission Advisor</h5>
          <div className={expandClasses} />
        </div>
        {this.state.isOpen && (
          <div className="section-folder_container">
            <McgComponent closeComponent={this.closeSection} />
          </div>
        )}
      </div>
    ) : null;
  }
}

McgContainer.propTypes = {
  shouldShowMcg: PropTypes.bool,
  clearMcgGuidelines: PropTypes.func,
  hideMcgGuidelines: PropTypes.func,
  activeProtocolId: PropTypes.string
};

const mapStateToProps = state => {
  return {
    shouldShowMcg: getShouldShowAdmissionAdvisor(state),
    activeProtocolId: state.session.activeProtocolId
  };
};
const mapDispatchToProps = {
  fetchMcgGuideline,
  clearMcgGuidelines,
  hideMcgGuidelines
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McgContainer);
