import React, { Component } from "react";
import PropTypes from "prop-types";
import { createDosingString } from "helpers/parsing/meds";
import classNames from "classnames";

export const Prescription = ({
  prescription,
  prescriptionSetMode,
  singleMedMode,
  prescriptionIndex,
  prescriptionSetMessage
}) => {
  return (
    (
      <div className="prescription_info-wrapper">
        {prescriptionIndex > 0 && (
          <div className="prescription_mode">
            {prescriptionSetMode || "PLUS"}
          </div>
        )}
        {(!singleMedMode || prescriptionIndex === 0) && (
          <div className="row collapse" title={prescriptionSetMessage}>
            <div className="large-12 columns">
              <h6 className="prescription_med-name">
                {prescription.medicationProfile.genericName}
              </h6>
            </div>
          </div>
        )}
        <div className="prescription_dosage">
          {createDosingString(prescription)}
        </div>
        {prescriptionSetMessage && (
          <div className="rx-set_message-col align-self-middle">
            <div className="rx-set_message">{prescriptionSetMessage}</div>
          </div>
        )}
      </div>
    ) || null
  );
};

Prescription.propTypes = {
  prescription: PropTypes.object,
  prescriptionSetMode: PropTypes.string,
  singleMedMode: PropTypes.bool,
  prescriptionIndex: PropTypes.number,
  isMapped: PropTypes.bool,
  isSent: PropTypes.bool,
  isDraft: PropTypes.bool,
  isDraftReviewer: PropTypes.bool,
  prescriptionSetMessage: PropTypes.string,
  sentAt: PropTypes.string,
  orderMessage: PropTypes.string
};

export class FHIR_Prescription extends Component {
  state = {
    selected: false,
    prescriptions: []
  };
  render() {
    const {
      prescription,
      prescriptionSetMode,
      singleMedMode,
      prescriptionIndex,
      isMapped,
      isSent,
      isDraft,
      isDraftReviewer,
      sentAt,
      orderMessage,
      prescriptionSetMessage
    } = this.props;
    const rxSetMessageClasses = classNames("rx-set_message", {
      "rx-set_message--disabled":
        !isMapped || (isMapped && isDraft && !isDraftReviewer)
    });
    const prescriptionMedNameClasses = classNames("prescription_med-name", {
      "prescription_med-name--disabled":
        !isMapped || (isMapped && isDraft && !isDraftReviewer),
      "prescription_med-name--draft": isDraft && isDraftReviewer,
      "prescription_med-name":
        ((isMapped && !isDraft) || (isMapped && isDraft && isDraftReviewer)) &&
        !isSent,
      "prescription_med-name--sent": isSent
    });
    const prescriptionInfoClasses = classNames("prescription_info-wrapper", {
      "prescription_info--disabled":
        !isMapped || (isMapped && isDraft && !isDraftReviewer),
      "prescription_info--draft": isDraft
    });
    return (
      (
        <div className={prescriptionInfoClasses}>
          {prescriptionIndex > 0 && (
            <div className="prescription_mode">
              {prescriptionSetMode || "PLUS"}
            </div>
          )}
          {(!singleMedMode || prescriptionIndex === 0) && (
            <div className="row collapse" title={orderMessage}>
              <div className="large-12 columns">
                <h6 className={prescriptionMedNameClasses}>
                  {prescription.medicationProfile.genericName}
                </h6>
              </div>
            </div>
          )}
          <div className="prescription_dosage">
            {createDosingString(prescription)}
          </div>
          {prescriptionSetMessage && (
            <div className="rx-set_message-col align-self-middle">
              <div className={rxSetMessageClasses}>
                {prescriptionSetMessage}
              </div>
            </div>
          )}
          {
            <div className="prescription_med-name-sent-time">
              {isSent && "sent " + sentAt}
            </div>
          }
        </div>
      ) || null
    );
  }
}

FHIR_Prescription.propTypes = {
  prescription: PropTypes.object,
  prescriptionSetMode: PropTypes.string,
  singleMedMode: PropTypes.bool,
  prescriptionIndex: PropTypes.number,
  isMapped: PropTypes.bool,
  isSent: PropTypes.bool,
  isDraft: PropTypes.bool,
  isDraftReviewer: PropTypes.bool,
  prescriptionSetMessage: PropTypes.string,
  sentAt: PropTypes.string,
  orderMessage: PropTypes.string
};
