import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import DisplayItem from "./moreInfoItems/DisplayItem";
import BlockEvidence from "./BlockEvidence";
import R from "ramda";
import { connect } from "react-redux";

import CommentBubble from "components/protocol/comments/CommentBubble";

export class Block extends Component {
  constructor(props) {
    super();
    this.state = {
      open: !!props.startOpen
    };
  }
  render() {
    const { block, isCollapsible, isTitleHidden } = this.props;
    const expandClasses = classNames("block_expand-icon", {
      "icon-expanded": this.state.open,
      "icon-expand": !this.state.open
    });

    const moreInfoBlockClasses = classNames("block", {
      "block--open": this.state.open
    });
    const showBlockEvidence =
      this.state.open &&
      (block.medicalSocietyId ||
        block.nnh ||
        block.nnt ||
        block.ratings.length > 0);
    return (
      <div className={moreInfoBlockClasses}>
        {!isTitleHidden && (
          <div
            className="block_header"
            onClick={() =>
              isCollapsible && this.setState({ open: !this.state.open })
            }
          >
            {isCollapsible && <div className={expandClasses} />}
            <h6 className="block_title">{block.title || ""}</h6>
          </div>
        )}
        {showBlockEvidence && <BlockEvidence data={block} />}
        {this.state.open &&
          R.uniq(block.items).map(item => {
            return (
              <div className="block_body" key={item.id}>
                <DisplayItem key={item.id} item={item} />
                {this.props.showComments && (
                  <div className="comment-bubble_wrap">
                    <CommentBubble
                      itemId={item.id}
                      locationDescription={`Block Item from evidence ${this.props.evidenceInternalName}`}
                      internalName={block.title}
                      usageId={this.props.usageId}
                      condensed
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

Block.propTypes = {
  block: PropTypes.object,
  isCollapsible: PropTypes.bool,
  isTitleHidden: PropTypes.bool,
  startOpen: PropTypes.bool,
  usageId: PropTypes.string,
  showComments: PropTypes.bool,
  evidenceInternalName: PropTypes.string
};

const mapStateToProps = state => {
  return {
    showCommentSidebar: state.comments.showSidebar,
    showComments: state.comments.showComments
  };
};
export default connect(mapStateToProps)(Block);
