import PropTypes from "prop-types";
import React from "react";

/***********************************
 * Provides a basic layout for the authentication
 * page as well as the `login-container`
 * element which is where the auth0 lock is rendered
 ***********************************/

export function Login(props) {
  const { message, bannerImage } = props;

  return (
    <div className="login-wrapper">
      <div className="login">
        {message &&
          (bannerImage ? (
            <div className="login_message">
              <img className="login_message-banner-img" src={bannerImage} />
              <div className="login_message-banner-text">{message}</div>
            </div>
          ) : (
            <div className="login_message"> {message} </div>
          ))}
        <div id="login-container" />
        <img
          className="login_logo show-for-medium"
          src={`${CLOUDINARY_URL}/evidencecare/image/upload/v1496332970/tagalong-site-assets/evidencecare-logo.svg`}
        />
      </div>
    </div>
  );
}

Login.propTypes = {
  message: PropTypes.object,
  bannerImage: PropTypes.string
};

export default Login;
