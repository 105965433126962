import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";

export function NotificationsEl(props) {
  return <Notifications notifications={props.notifications} />;
}

NotificationsEl.propTypes = {
  notifications: PropTypes.array
};

const mapStateToProps = state => ({
  notifications: state.notifications
});

export default connect(mapStateToProps)(NotificationsEl);
