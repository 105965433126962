import { createSelector } from "reselect";
import { getSessionCustomOutputs } from "selectors/outputSelectors";
import R from "ramda";

// the chain function maps a function over a list and concatenates the results
export const getOrderstrings = orders =>
  R.chain(order => order.orderStrings)(orders);
export const getOrderData = state => state.FHIR_orders.crosswalk;

// grouping orderstrings by category
export const makeGetOrderstringsGroupedByCategory = () => {
  return createSelector(
    [getOrderstrings],
    orderStrings => R.groupBy(orderstring => orderstring.category)(orderStrings)
  );
};

// checking if there are any orderstrings with the same title
// for now, this is how we have to check for multiple orderstring on an order
export const makeGetOrderstringsWithDuplicateTitles = () => {
  return createSelector(
    [getOrderstrings],
    orderStrings => {
      const groupedOrderStringsByCategory = R.groupBy(
        orderstring => orderstring.category
      )(orderStrings);
      const groupedOrderStringsByTitle = R.values(
        groupedOrderStringsByCategory
      ).reduce((acc, groupedOrderstring) => {
        return acc.concat(groupedOrderstring.map(order => order.title));
      }, []);
      return groupedOrderStringsByTitle.filter(
        (element, index) =>
          groupedOrderStringsByTitle.indexOf(element, index + 1) > -1
      );
    }
  );
};

export const getSelectedOrders = createSelector(
  [getSessionCustomOutputs],
  customOutputs => {
    const isSelectedOrder = R.where({
      isSelected: R.equals(true),
      itemType: R.equals("order")
    });
    const filteredOutputs = R.filter(isSelectedOrder, customOutputs);
    return filteredOutputs;
  }
);

export const getSelectedOrderData = createSelector(
  // this takes the ids from the selectedOrders and grabs the corresponding order data from state -- necessary for submitting orders
  [getSelectedOrders, getOrderData],
  (selectedOrders, orderData) => {
    const orders = Object.keys(selectedOrders).reduce((acc, orderStringId) => {
      if (orderData[orderStringId]) {
        acc.push(orderData[orderStringId]);
      }
      return acc;
    }, []);
    return orders;
  }
);
