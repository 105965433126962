import React from "react";
import PropTypes from "prop-types";

export const ProviderNoteForm = ({ note, onChange, onSubmit }) => {
  return (
    <div className="patient-ed_provider-note-container">
      <form>
        <label>
          Provider Comment
          <textarea
            type="text"
            className="patient-ed_provider-note-input"
            name="providerNote"
            value={note}
            onChange={onChange}
          />
        </label>
        <input
          className="patient-ed_provider-note-save"
          onClick={onSubmit}
          type="submit"
          value="Save"
        />
      </form>
    </div>
  );
};

ProviderNoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ProviderNoteForm;
