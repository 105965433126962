import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";

import LoginComponent from "components/auth/login/Login";
import { selectPartnerTemplate } from "constants/partners";
import { onLoginPage } from "store/auth/actions";
import AuthLayout from "layouts/AuthLayout/AuthLayout";

/******************************************
 * Reaching this component implies that we
 * need to try to begin an auth flow.
 *
 * This component launches the auth flow
 * as well as providing a basic DOM canvas
 * for the adding additional UI elements
 * like the Auth0 lock
 ******************************************/

class LoginContainer extends React.Component {
  componentDidMount() {
    this.props.onLoginPage();
  }

  render() {
    const { welcomeText, cloudinaryUrl } =
      selectPartnerTemplate(this.props.partner) || {};
    return (
      <AuthLayout>
        <LoginComponent message={welcomeText} bannerImage={cloudinaryUrl} />
      </AuthLayout>
    );
  }
}

LoginContainer.propTypes = {
  partner: PropTypes.string,
  onLoginPage: PropTypes.func
};

const mapDispatchToProps = {
  onLoginPage
};

export default connect(
  null,
  mapDispatchToProps
)(LoginContainer);
