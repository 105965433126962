import PropTypes from "prop-types";
import React, { Component } from "react";
import { showModal } from "store/modal/actions";
import { connect } from "react-redux";

export class ModalContainer extends Component {
  state = {
    modalOpen: false
  };
  openModal = () => {
    // passing in children like this via redux isn't ideal, but should be fine for now
    this.props.showModal("GENERIC", {
      title: this.props.title,
      content: this.props.children
    });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  render() {
    return (
      <div>
        <div className="block_enlarge" onClick={this.openModal}>
          {this.props.children}
          <div className="icon-enlarge" />
        </div>
      </div>
    );
  }
}
ModalContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  showModal: PropTypes.func
};
const mapDispatchToProps = {
  showModal
};
export default connect(
  null,
  mapDispatchToProps
)(ModalContainer);
