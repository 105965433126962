import { fetchSessionListApi } from "lib/ec/session";
import { getFacilityList } from "lib/ec/account";
import dotProp from "dot-prop";

// ------------------------------------
// Constants
// ------------------------------------
export const PATIENT_SET = "PATIENT_SET";
export const PATIENT_SET_OBSERVATIONS = "PATIENT_SET_OBSERVATIONS";
export const PATIENT_SET_ENCOUNTER = "PATIENT_SET_ENCOUNTER";
export const PATIENT_DRAWER_SHOW = "PATIENT_DRAWER_SHOW";
export const PATIENT_DRAWER_HIDE = "PATIENT_DRAWER_HIDE";
export const PATIENT_SET_CUSTOM_HANDLE = "PATIENT_SET_CUSTOM_HANDLE";
export const PATIENT_SET_SESSION_LIST = "PATIENT_SET_SESSION_LIST";
export const PATIENT_SET_FHIR_ENCOUNTER = "PATIENT_SET_FHIR_ENCOUNTER";
export const PATIENT_SET_IDENTIFIERS = "PATIENT_SET_IDENTIFIERS";
export const PATIENT_SET_FACILITY_LOCATION = "PATIENT_SET_FACILITY_LOCATION";
export const PATIENT_SET_APP_CONTEXT = "PATIENT_SET_APP_CONTEXT";
export const PATIENT_SET_CDS_AUC = "PATIENT_SET_CDS_AUC";
export const PATIENT_SET_CDS_ORDERS_RESPONSE =
  "PATIENT_SET_CDS_ORDERS_RESPONSE";
export const PATIENT_SET_AUC_PROP = "PATIENT_SET_AUC_PROP";

// ------------------------------------
// Actions
// ------------------------------------
export function setPatient(patient) {
  return {
    type: PATIENT_SET,
    payload: patient
  };
}

export function setObservations({ category, observations }) {
  return {
    type: PATIENT_SET_OBSERVATIONS,
    payload: { category, observations }
  };
}

export function setEncounter({ encounter }) {
  return {
    type: PATIENT_SET_ENCOUNTER,
    payload: { encounter }
  };
}

export function setIdentifiers(identifiers) {
  return {
    type: PATIENT_SET_IDENTIFIERS,
    payload: identifiers
  };
}

export function setAppContext(appContext) {
  return {
    type: PATIENT_SET_APP_CONTEXT,
    payload: appContext
  };
}

export function setCDSOrdersResponse(appContext) {
  return {
    type: PATIENT_SET_CDS_ORDERS_RESPONSE,
    payload: appContext
  };
}

export function setCdsAuc(aucData) {
  return {
    type: PATIENT_SET_CDS_AUC,
    payload: aucData
  };
}
export function setAucProp({ payload }) {
  return {
    type: PATIENT_SET_AUC_PROP,
    payload
  };
}

export function showPatientDrawer() {
  return {
    type: PATIENT_DRAWER_SHOW,
    payload: {
      visible: true
    }
  };
}

export function hidePatientDrawer() {
  return {
    type: PATIENT_DRAWER_HIDE,
    payload: {
      visible: false
    }
  };
}

export function setCustomHandle({ customHandle }) {
  return {
    type: PATIENT_SET_CUSTOM_HANDLE,
    payload: { customHandle }
  };
}

export const setSessionList = ({ sessionList }) => ({
  type: PATIENT_SET_SESSION_LIST,
  payload: { sessionList }
});

export const getFacilityLocation = () => (dispatch, getState) => {
  const token = getState().user.token;
  const locations = dotProp.get(getState(), "patient.encounter.location");
  const location =
    (locations &&
      locations[0] &&
      locations[0].location &&
      locations[0].location.reference) ||
    "";
  const locationCode = location.split("/")[1];
  getFacilityList({ token }).then(({ data }) => {
    const facilityLocation = data.results.find(
      facility => facility.client_id === locationCode
    );
    dispatch(setFacilityLocation({ facilityLocation }));
  });
};

const setFacilityLocation = ({ facilityLocation }) => ({
  type: PATIENT_SET_FACILITY_LOCATION,
  payload: facilityLocation
});

export const fetchAndSetSessionList = () => (dispatch, getState) => {
  return new Promise(resolve => {
    const customHandle = __FHIR__ && getState().patient.customHandle;
    const fetchParams = {
      ...((customHandle && { customHandle }) || {})
    };
    fetchSessionListApi(fetchParams).then(({ data }) => {
      data.results && dispatch(setSessionList({ sessionList: data.results }));
      resolve(data);
    });
  });
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PATIENT_SET]: (state, action) => Object.assign({}, state, action.payload),
  [PATIENT_SET_OBSERVATIONS]: (state, action) =>
    Object.assign({}, state, {
      observations: {
        ...state.observations,
        [action.payload.category]: action.payload.observations
      }
    }),
  [PATIENT_SET_ENCOUNTER]: (state, action) =>
    Object.assign({}, state, { encounter: action.payload.encounter }),
  [PATIENT_SET_IDENTIFIERS]: (state, action) =>
    Object.assign({}, state, { identifiers: action.payload }),
  [PATIENT_DRAWER_SHOW]: (state, action) =>
    Object.assign({}, state, { patientDrawer: action.payload }),
  [PATIENT_DRAWER_HIDE]: (state, action) =>
    Object.assign({}, state, { patientDrawer: action.payload }),
  [PATIENT_SET_CUSTOM_HANDLE]: (state, action) => ({
    ...state,
    customHandle: action.payload.customHandle
  }),
  [PATIENT_SET_ENCOUNTER]: (state, action) =>
    Object.assign({}, state, { encounter: action.payload.encounter }),
  [PATIENT_SET_IDENTIFIERS]: (state, action) =>
    Object.assign({}, state, { identifiers: action.payload }),
  [PATIENT_DRAWER_SHOW]: (state, action) =>
    Object.assign({}, state, { patientDrawer: action.payload }),
  [PATIENT_DRAWER_HIDE]: (state, action) =>
    Object.assign({}, state, { patientDrawer: action.payload }),
  [PATIENT_SET_CUSTOM_HANDLE]: (state, action) => ({
    ...state,
    customHandle: action.payload.customHandle
  }),
  [PATIENT_SET_SESSION_LIST]: (state, action) => ({
    ...state,
    sessionList: action.payload.sessionList
  }),
  [PATIENT_SET_FACILITY_LOCATION]: (state, action) => ({
    ...state,
    encounter: { ...state.encounter, facilityLocation: action.payload }
  }),
  [PATIENT_SET_APP_CONTEXT]: (state, action) => ({
    ...state,
    appContext: action.payload ? action.payload : null
  }),
  [PATIENT_SET_CDS_ORDERS_RESPONSE]: (state, action) => ({
    ...state,
    cdsOrdersResponse: action.payload ? action.payload : null
  }),
  [PATIENT_SET_CDS_AUC]: (state, action) => ({
    ...state,
    aucData: {
      ...state.aucData,
      ...action.payload
    }
  }),
  [PATIENT_SET_AUC_PROP]: (state, { payload }) => ({
    ...state,
    aucData: { ...state.aucData, [payload.prop]: payload.value }
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  observations: {},
  encounter: { facilityLocation: null },
  patientDrawer: {
    visible: false
  },
  identifiers: {}
};
export default function patientReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
