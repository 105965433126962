import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import R from "ramda";
import {
  makeGetFullActiveOutputs,
  makeGetFullActiveSelectedOutputs,
  makeGetFullActiveSelectedCalcInputs
} from "selectors/outputSelectors";
import MainNavTabs from "./mainNavTabs/MainNavTabs";
import { OUTPUT_TAB } from "store/session";
import PatientEdView from "components/patientEd/PatientEdView";
import PatientEdResources from "components/patientEd/PatientEdResources";
import EhrView from "components/ehr/EhrView";
import OrdersView from "components/orders/OrdersView";
import PrescriptionSetsView from "components/rxSets/PrescriptionSetsView";
import OrdersContainer from "components/orders/OrdersContainer";
import { DEFAULT_RIGHT_COLUMN_TEXT } from "constants/protocol";
import isEmpty from "lodash/isEmpty";
import { selectCDSMode } from "store/ui/selectors.js";
import {
  DISABLE_ORDERS_COLUMN,
  DISABLE_PATIENT_ED_COLUMN,
  DISABLE_EHR_COLUMN
} from "constants/fhir";
import classNames from "classnames";
import { selectFeatureFlags } from "store/user/selectors";

export class RightCol extends Component {
  static propTypes = {
    activeOutputObjs: PropTypes.object,
    activeTab: PropTypes.string,
    activeSelectedOutputObjs: PropTypes.object,
    patientEducationGroupedByCategory: PropTypes.array,
    userFeatures: PropTypes.array,
    calcs: PropTypes.array,
    mcgData: PropTypes.object,
    fhirCDS: PropTypes.bool
  };
  mapOutputs = type => {
    const { activeOutputObjs } = this.props;
    return (
      <div>
        <h6>{type}</h6>
        <ul>
          {R.uniq(activeOutputObjs[type]).map(output => (
            <li key={output.id}>
              {output.internalName && output.internalName}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  makeOutputArray = type => R.uniq(this.props.activeOutputObjs[type]);

  renderDefaultText = itemType => {
    return (
      <div style={{ padding: "1rem 2rem" }}>
        {DEFAULT_RIGHT_COLUMN_TEXT[itemType]}
      </div>
    );
  };

  showOutputs = () => {
    const { activeTab, calcs, mcgData, userFeatures } = this.props;
    const ehrs = this.makeOutputArray("ehrs");
    const pes = this.makeOutputArray("patientEducation");
    const orders = this.makeOutputArray("orderSets");
    const rxSets = this.makeOutputArray("prescriptionSets");
    const diagnoses = this.makeOutputArray("diagnoses");
    const showMcg = !isEmpty(mcgData) && mcgData.isSubmitted;

    const ordersContainerClasses = classNames({
      "protocol-right-column_content-disabled": userFeatures.includes(
        DISABLE_ORDERS_COLUMN
      )
    });
    const patientEdContainerClasses = classNames({
      "protocol-right-column_content-disabled": userFeatures.includes(
        DISABLE_PATIENT_ED_COLUMN
      )
    });
    const EHRContainerClasses = classNames({
      "protocol-right-column_content-disabled": userFeatures.includes(
        DISABLE_EHR_COLUMN
      )
    });

    switch (activeTab) {
      case OUTPUT_TAB.EHR:
        return (
          <>
            {ehrs.length > 0 || calcs.length > 0 || showMcg ? (
              <div className={EHRContainerClasses}>
                {" "}
                <EhrView
                  calcs={calcs}
                  ehrs={ehrs}
                  diagnoses={diagnoses}
                  mcgData={mcgData}
                />
              </div>
            ) : (
              this.renderDefaultText("ehr")
            )}
          </>
        );
      case OUTPUT_TAB.PE:
        return (
          <div
            id="patientEdViewId"
            className={patientEdContainerClasses}
            style={{ height: "100%" }}
          >
            {pes.length > 0 || diagnoses.length > 0 || showMcg ? (
              <PatientEdView
                patientEd={pes}
                diagnoses={diagnoses}
                activeSelectedOutputObjs={this.props.activeSelectedOutputObjs}
                patientEducationGroupedByCategory={
                  this.props.patientEducationGroupedByCategory
                }
                mcgData={mcgData}
              />
            ) : (
              this.renderDefaultText("pe")
            )}
          </div>
        );
      case OUTPUT_TAB.ORDERS:
        return (
          <div className={ordersContainerClasses} style={{ height: "100%" }}>
            {orders.length > 0 || rxSets.length > 0 ? (
              <OrdersContainer prescriptionSets={rxSets} orders={orders}>
                <OrdersView orders={orders} />
                <PrescriptionSetsView prescriptionSets={rxSets} />
              </OrdersContainer>
            ) : (
              this.renderDefaultText("orders")
            )}
          </div>
        );
      default:
        return <div style={{ height: "100%" }}>no tab selected</div>;
    }
  };
  render() {
    return (
      <>
        <div style={{ height: "100%" }}>
          <MainNavTabs />
          {this.showOutputs()}
        </div>
        {this.props.activeTab === OUTPUT_TAB.PE && (
          <PatientEdResources
            faqs={this.makeOutputArray("faqs")}
            resources={this.makeOutputArray("resources")}
            patientEd={this.makeOutputArray("patientEducation")}
          />
        )}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getFullActiveSelectedCalcInputs = makeGetFullActiveSelectedCalcInputs();
  const getFullActiveOutputs = makeGetFullActiveOutputs();
  const getFullActiveSelectedOutputs = makeGetFullActiveSelectedOutputs();
  const mapStateToProps = state => {
    return {
      calcs: getFullActiveSelectedCalcInputs(state),
      activeOutputObjs: getFullActiveOutputs(state),
      activeSelectedOutputObjs: getFullActiveSelectedOutputs(state),
      mcgData: state.session.mcgGuideline,
      activeTab: state.session.activeOutputTab,
      fhirCDS: selectCDSMode(state),
      userFeatures: selectFeatureFlags(state)
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(RightCol);
