import createCachedSelector from "re-reselect";

// ------------------------------------
// Selectors
// ------------------------------------

export function selectTourState(state) {
  return state.tour;
}

export function selectTours(state) {
  return selectTourState(state).flows;
}

export function selectActiveTourSteps(state) {
  const activeTour = selectActiveTour(state);
  return activeTour ? activeTour.steps : null;
}

// returns a list of tours based on the path matching rules
// and that are ordered properly
export const selectSelfHelpTours = createCachedSelector(
  selectTours,
  () => window.location.pathname,
  (tours, path) => {
    return tours.reduce(
      (
        acc,
        {
          possibleRoutes,
          order,
          name,
          title,
          subtitle,
          hideIfNotOnPage,
          hideFromSelfHelp
        }
      ) => {
        if (!hideFromSelfHelp) {
          const onPage = pathMatchesPossibleRoutes(path, possibleRoutes);
          if (onPage || !hideIfNotOnPage) {
            acc.push({
              name,
              title,
              order,
              subtitle,
              onPage
            });
          }
        }
        return acc;
      },
      []
    );
  }
)(() => window.location.pathname);

export function selectActiveTour(state) {
  const name = selectTourState(state).activeFlow;
  if (!name) return null;
  return selectTour(state, { name });
}

export const selectTour = createCachedSelector(
  selectTours,
  (state, { name }) => name,
  (tours, name) => {
    return tours.find(tour => tour.name === name);
  }
)((state, { name }) => name);

// ------------------------------------
// Utilities
// ------------------------------------

function pathMatchesPossibleRoutes(path, possibleRoutes) {
  if (!possibleRoutes) return false;
  return possibleRoutes.find(({ operation, input }) => {
    switch (operation) {
      case "exact":
        return input === path;
      case "contains":
        return path.includes(input);
      case "startsWith":
        return path.startsWith(input);
      case "regex":
        return new RegExp(input).exec(path) !== null;
      default:
        return false;
    }
  });
}
