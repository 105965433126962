export function loadCloudinaryWidgetScript() {
  return new Promise((resolve, reject) => {
    if (window.cloudinary) {
      return resolve();
    }
    const elem = document.getElementsByTagName("script")[0];
    const script = document.createElement("script");
    script.src = "//widget.cloudinary.com/global/all.js";
    script.async = true;
    elem.parentNode.insertBefore(script, elem);
    let i = 0;
    let waitingForCloudinary = setInterval(() => {
      if (window.cloudinary) {
        clearInterval(waitingForCloudinary);
        return resolve(true);
      } else if (i === 6) {
        return reject("timeout");
      }
      i++;
    }, 1000);
  });
}

export function loadTinyMceScript() {
  return new Promise((resolve, reject) => {
    const elem = document.getElementsByTagName("script")[0];
    const script = document.createElement("script");
    script.src = "//cloud.tinymce.com/stable/tinymce.min.js";
    script.async = true;
    elem.parentNode.insertBefore(script, elem);
    console.log("Adding TinyMCE");
    let i = 0;
    let waitingForTinyMce = setInterval(() => {
      if (window.tinymce) {
        clearInterval(waitingForTinyMce);
        return resolve(true);
      } else if (i === 6) {
        return reject("timeout");
      }
      i++;
    }, 1000);
  });
}
