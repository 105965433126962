import React from "react";
import PropTypes from "prop-types";
import dotProp from "dot-prop";
import { connect } from "react-redux";

import { makeSelectedOutputs } from "selectors/outputSelectors";
import { updateReason } from "store/session";
import { pipelineRequest } from "store/pipeline";
import { EVENT_FEATURE_DEVIATION_SUBMIT } from "constants/broadcastEventTypes";

class ReasonForDecision extends React.Component {
  state = {
    isEditing: true
  };
  submitReason = (usage, reason) => {
    this.props.pipelineRequest({
      action: EVENT_FEATURE_DEVIATION_SUBMIT,
      message: {
        reason_text: reason.text,
        reason_type: reason.type,
        careusage_id: usage.id
      }
    });
    this.setState({ isEditing: false });
    this.props.updateReason({
      id: usage.id,
      type: reason.type,
      submitted: true,
      text: reason.text,
      isSelected: reason.isSelected
    });
  };
  componentDidUpdate(prevProps) {
    // when we submit a reason for deviation, also need to set submitted to false if they choose an appropriate care usage, thereby effectively revert their deviation
    const { careUsages, activeSelectedOutputs, reasons } = prevProps;
    // get previous selected inappropriate care usage
    const prevSelectedInappropriateCareUsage = careUsages.find(
      cu => cu.appropriateness === -1 && activeSelectedOutputs[cu.output.id]
    );
    // check to see if current selected careusage is not inappropriate
    const thisCareUsageIsNotInappropriate = this.props.careUsages.some(
      cu =>
        cu.appropriateness !== -1 &&
        this.props.activeSelectedOutputs[cu.output.id]
    );
    // if both ^^ are true and there is a reason for the previous care usage, update it to not be submitted
    if (
      prevSelectedInappropriateCareUsage &&
      thisCareUsageIsNotInappropriate &&
      reasons[prevSelectedInappropriateCareUsage.id]
    ) {
      this.props.updateReason({
        ...reasons[prevSelectedInappropriateCareUsage.id],
        submitted: false
      });
    }

    const prevSelectedAppropriateCareUsage = careUsages.find(
      cu => cu.appropriateness !== -1 && activeSelectedOutputs[cu.output.id]
    );
    const selectedInappropriateCareUsages = this.props.careUsages.filter(
      cu =>
        cu.appropriateness === -1 &&
        this.props.activeSelectedOutputs[cu.output.id]
    );
    // handle toggling back to innapropriate from appropriate care usage.
    if (
      prevSelectedAppropriateCareUsage &&
      selectedInappropriateCareUsages.length &&
      !this.state.isEditing
    ) {
      this.setState({ isEditing: true });
    }
  }
  render() {
    const {
      careUsages,
      activeSelectedOutputs,
      reasons,
      updateReason
    } = this.props;
    const selectedInappropriateCareUsages = careUsages.filter(
      cu => cu.appropriateness === -1 && activeSelectedOutputs[cu.output.id]
    );
    // const reason
    return (
      (selectedInappropriateCareUsages.length > 0 &&
        selectedInappropriateCareUsages.map(usage => {
          const reason = dotProp.get(reasons, `${usage.id}`, {});

          return (
            <div key={usage.id} className="reason-for-decision">
              {this.state.isEditing ? (
                <label className="reason-for-decision_label">
                  Indicate reasoning for rarely appropriate care decision
                  <textarea
                    type="text"
                    className="reason-for-decision_text-area"
                    name="reasonForDecision"
                    value={(reason && reason.text) || ""}
                    onChange={e =>
                      updateReason({
                        text: e.target.value,
                        id: usage.id,
                        type: "CARE_USAGE",
                        submitted: false,
                        isSelected: reason.isSelected || true
                      })
                    }
                  />
                </label>
              ) : (
                <div className="reason-for-decision_text">
                  <div className="reason-for-decision_label">
                    Reasoning for rarely appropriate care decision
                  </div>
                  <div className="row collapse">
                    <div className="columns reason-for-decision_body">
                      {reason.text}
                    </div>
                    <div className="columns shrink">
                      <button
                        onClick={() => this.setState({ isEditing: true })}
                      >
                        <span className="output-icon icon-pencil reason-for-decision_edit" />
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {this.state.isEditing ? (
                <button
                  className="button button_standard"
                  onClick={() => this.submitReason(usage, reason)}
                >
                  Submit
                </button>
              ) : null}
            </div>
          );
        })) ||
      null
    );
  }
}

ReasonForDecision.propTypes = {
  careUsages: PropTypes.array,
  activeSelectedOutputs: PropTypes.array,
  updateReason: PropTypes.func,
  reasons: PropTypes.object,
  pipelineRequest: PropTypes.func
};
const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const getSelectedOutputs = makeSelectedOutputs();
    return {
      activeSelectedOutputs: getSelectedOutputs(state),
      reasons: state.session.userText.reasons
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = {
  updateReason,
  pipelineRequest
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ReasonForDecision);
