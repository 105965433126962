import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { updateEntityProp, setOutputTab } from "store/session";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { clearActiveMoreInfo } from "store/protocol";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionLaunchNextSection from "./SectionLaunchNextSection";
import dotProp from "dot-prop";

import { getSectionInfo } from "selectors/sectionSelectors";
export class SectionContainer extends Component {
  constructor(props) {
    super();

    this.state = {
      openedFromClick: false
    };
  }
  setActiveGroup = () => {
    const thisGroupId = this.props.sectionVersion.render.groupId;
    const groupIdToSet =
      thisGroupId === this.props.activeGroupId ? null : thisGroupId;
    this.setState({ openedFromClick: true });
    this.props.updateEntityProp({
      id: this.props.sectionVersion.protocolId,
      entityName: "protocols",
      prop: "activeGroupId",
      value: groupIdToSet
    });
    this.props.updateEntityProp({
      id: this.props.sectionVersion.protocolId,
      entityName: "protocols",
      prop: "activeSectionId",
      value: this.props.sectionVersion.sectionId
    });
  };

  openOutputTab = outputTab => {
    return () => {
      this.props.clearActiveMoreInfo();
      this.props.setOutputTab({ outputTabName: outputTab });
    };
  };

  render() {
    const {
      activeGroupId,
      activeSelectedOutputs,
      section,
      sectionVersion
    } = this.props;
    const sectionActive = activeGroupId === this.props.section.groupId;
    const sectionClasses = classNames("section", {
      "section--active": sectionActive
    });
    const showSection = activeGroupId === sectionVersion.render.groupId;
    return (
      <div className={sectionClasses}>
        <SectionHeader
          sectionVersion={sectionVersion}
          setActiveGroup={this.setActiveGroup}
        />
        {showSection && (
          <Section
            sectionVersionId={sectionVersion.id}
            activeGroupId={activeGroupId}
            activeSelectedOutputs={activeSelectedOutputs}
            relatedSections={this.props.relatedSections}
            careFolders={this.props.orderedCareFolders}
            careUsagesWithOutputs={this.props.careUsagesWithOutputs}
            usages={this.props.usages}
            section={section}
            openedFromClick={this.state.openedFromClick}
            careDecisionCanLeadToNextSection={
              this.props.careDecisionCanLeadToNextSection
            }
            sectionOutput={this.props.sectionOutput}
            setActiveGroup={this.setActiveGroup}
          />
        )}
        {showSection && (
          <SectionLaunchNextSection
            careUsagesWithOutputs={this.props.careUsagesWithOutputs}
            section={section}
            sectionOutput={this.props.sectionOutput}
          />
        )}
      </div>
    );
  }
}

SectionContainer.propTypes = {
  updateEntityProp: PropTypes.func,
  activeGroupId: PropTypes.string,
  activeSelectedOutputs: PropTypes.array,
  setOutputTab: PropTypes.func.isRequired,
  clearActiveMoreInfo: PropTypes.func,
  section: PropTypes.object,
  sectionVersion: PropTypes.shape({
    id: PropTypes.string,
    protocolId: PropTypes.string,
    sectionId: PropTypes.string,
    render: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      keywords: PropTypes.array,
      ancestry: PropTypes.array,
      groupId: PropTypes.string,
      group: PropTypes.object,
      outputs: PropTypes.array
    }).isRequired
  }),
  sectionOutput: PropTypes.object,
  relatedSections: PropTypes.object,
  careUsagesWithOutputs: PropTypes.array,
  careDecisionCanLeadToNextSection: PropTypes.bool,
  orderedCareFolders: PropTypes.array,
  usages: PropTypes.object
};
const makeMapStateToProps = (initialState, initialProps) => {
  const getSelectedOutputs = makeSelectedOutputs();
  const mapStateToProps = (state, props) => {
    const sectionInfo =
      (initialProps.sectionId &&
        getSectionInfo(
          state,
          initialProps.protocolId,
          initialProps.sectionId
        )) ||
      {};
    return {
      activeSelectedOutputs: getSelectedOutputs(state),
      getSelectedOutputs: getSelectedOutputs(state),
      ...sectionInfo,
      /**
       * section info includes:
       * sectionOutput,
       * relatedSections,
       * careUsagesWithOutputs,
       * getCareDecisionCanLeadToNextSection,
       * orderedCareFolders,
       * usages
       */
      activeGroupId:
        state.session.entities.protocols[props.protocolId].activeGroupId,
      selections: state.session.entities.selections,
      section: dotProp.get(
        state,
        `protocol.${initialProps.protocolId}.sections.${initialProps.sectionId}.render`
      ),
      sectionVersion: dotProp.get(
        state,
        `protocol.${initialProps.protocolId}.sections.${initialProps.sectionId}`
      )
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateEntityProp,
  setOutputTab,
  clearActiveMoreInfo
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SectionContainer);
