import React from "react";
import { saveAs } from "file-saver";
import R from "ramda";
import { success } from "react-notification-system-redux";

import { makePdfRequest } from "lib/ec/base";
import { b64toBlob } from "helpers/parsing/b64ToBlob";
import { logSentryError } from "helpers/logging/error";
import { showLoader, hideLoader } from "store/ui";
import { showModal } from "store/modal/actions";
import {
  selectUserFirstName,
  selectUserIsDoctor,
  selectUserLastName
} from "store/user/selectors";

export const requestPdf = ({
  data,
  openInNewWindow,
  pdfWindow,
  year,
  dispatch
}) => {
  makePdfRequest("cme", data)
    .then(({ data }) => {
      const blob = b64toBlob(data.pdf, "application/pdf");
      if (openInNewWindow) {
        pdfWindow.location.href = URL.createObjectURL(blob);
      } else {
        const fileName = `cme-${year || ""}.pdf`;
        saveAs(blob, fileName);
        dispatch(hideLoader());
        const notificationOpts = {
          uid: "pdf-download-success",
          title: "Success",
          message: "A PDF of your certificate has been downloaded.",
          position: "bl"
        };
        dispatch(success(notificationOpts));
      }
    })
    .catch(() => {
      dispatch(
        showModal("ERROR", {
          message: (
            <p>
              An error occured while generating the PDF of your CME certificate.
              We&apos;ve been notified and are looking into this issue.
            </p>
          ),
          dispatch
        })
      );
      dispatch(hideLoader());
    });
};

export const printSummary = ({ medSocieties, testId, total, year, degree }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const data = {
    testId,
    firstName: selectUserFirstName(state),
    lastName: selectUserLastName(state),
    degree,
    isDoctor: selectUserIsDoctor(state),
    medSocieties,
    total,
    year
  };
  try {
    var isFileSaverSupported = !!new Blob();
  } catch (err) {
    logSentryError(err);
  }
  if (isFileSaverSupported) {
    let isSafari =
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1;
    let openInNewWindow = isSafari;
    if (openInNewWindow) {
      var pdfWindow = window.open("", "_blank");
      const html =
        '<style>@keyframes pulse{0%,100%{opacity:.6}50%{opacity:1}}.pulse{animation-iteration-count: infinite;animation-name:pulse;animation-duration:2.5s}</style><div style="position:absolute;width:100%;height:100%;top:0;left:0;background:#00aeef"><div class="pulse" style="font-family: sans-serif;color:#fff;text-align: center;transform: translateY(-50%);top: 50%;position:relative;font-size: 4vw;">Please wait, generating PDF...</div></div>';
      pdfWindow.document.write(html);
    } else {
      dispatch(showLoader());
    }
    requestPdf({ data, openInNewWindow, pdfWindow, year, dispatch });
  } else {
    alert(
      "Your browser does not support this feature, please update browser or contact support."
    );
  }
};

export const transformAccreditations = accreditations => {
  return accreditations.reduce(
    (acc, cred) => {
      const medSocietyIndex = R.findIndex(
        R.propEq("id", cred.accreditor.id),
        acc.medSocieties
      );
      acc.total =
        (cred.credits && parseFloat(cred.credits) + acc.total) || acc.total;
      const accreditation = {
        id: cred.id,
        name: cred.title,
        credits: cred.credits,
        startDate: cred.start_date,
        endDate: cred.end_date
      };
      if (acc.medSocieties.length === 0 || medSocietyIndex < 0) {
        acc.medSocieties.push({
          id: cred.accreditor.id,
          logoCloudinaryId: cred.accreditor.cloudinary_id,
          disclaimer: cred.accreditor.certificate_disclaimer,
          name: cred.accreditor.title,
          accreditations: [accreditation]
        });
      } else {
        acc.medSocieties[medSocietyIndex].accreditations.push(accreditation);
      }
      return acc;
    },
    { medSocieties: [], total: 0 }
  );
};
