import React from "react";
import classNames from "classnames";
import ProtocolLaunchButton from "./ProtocolLaunchButton";
import Sections from "./sections/Sections";
import BottomNav from "./BottomNav";
import CommentThread from "./comments/CommentThread";
import EndOfProtocol from "./EndOfProtocol";
import ProtocolRightCol from "./protocolRightCol/ProtocolRightCol";

export const ProtocolContent = ({
  hideCommentSidebar,
  showSidebar,
  activeProtocolId,
  endOfBranchSectionId,
  fhirCDS,
  hideRightColumns,
  openOutputTab
}) => {
  const protocolMainColumnClasses = classNames("protocol_main-column", {
    "protocol_main-column--full-screen": hideRightColumns
  });
  return (
    <div className="protocol_content-row">
      {showSidebar && (
        <div className="comments-sidebar-wrap">
          <div className="comments-sidebar">
            <CommentThread />
            <div
              className="comments-sidebar_click-off"
              onClick={hideCommentSidebar}
            />
          </div>
        </div>
      )}
      <div id="protocol-main-col" className={protocolMainColumnClasses}>
        {activeProtocolId && <Sections activeProtocolId={activeProtocolId} />}
        <ProtocolLaunchButton />
        <BottomNav />
        {!fhirCDS && (
          <EndOfProtocol
            openOutputTab={openOutputTab}
            endOfBranchSectionId={endOfBranchSectionId}
          />
        )}
      </div>

      {activeProtocolId && !hideRightColumns && <ProtocolRightCol />}
    </div>
  );
};

export default ProtocolContent;
