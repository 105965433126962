import groupBy from "lodash/groupBy";
import dotProp from "dot-prop";
import { escape, decode } from "he";

export const groupedEhrs = ehrs => groupBy(ehrs, "category.title");

export const diagnosesTitleArray = diagnoses =>
  diagnoses.map(diagnosis => diagnosis.title);

export const diagnosesString =
  "The patient was evaluated and treated for the following condition(s):";

export const selectedInputs = selectedCalc => {
  const inputs = dotProp.get(selectedCalc, "usage.inputs", {});
  return Object.keys(inputs).reduce((acc, inputId) => {
    if (inputs[inputId].selected) {
      acc.push(inputs[inputId]);
    }
    return acc;
  }, []);
};

export const inputGroupStrings = selectedCalc =>
  selectedCalc.usage.calculator.inputGroups.map((inputGroup, index) => {
    const inputStrings = inputGroup.inputs.map(input => {
      const isInputSelected = selectedInputs(selectedCalc).find(
        selectedInput => input.id === selectedInput.id
      );
      return `- (${isInputSelected ? "Yes" : "No"}) ${input.title} \n`;
    });
    return inputStrings;
  });

export const subtitle = ehr => `${ehr.subtitle ? escape(ehr.subtitle) : ""}`;
export const text = ehr => `${ehr.text ? escape(ehr.text) : ""}`;
export const citations = ({ citations = [] }) =>
  citations.map(citation => `(${escape(citation.displayName)})`);

export const ehrStringObject = ehrs =>
  Object.keys(groupedEhrs(ehrs)).reduce((acc, categoryTitle) => {
    groupedEhrs(ehrs)[categoryTitle].forEach(ehr => {
      const ehrStringBody = `${subtitle(ehr) && `${subtitle(ehr)}\n`}- ${text(
        ehr
      )}\n${`${
        ehr.citations && ehr.citations.length > 0
          ? citations({ citations: ehr.citations }).join(" ")
          : ""
      }\n `}`;
      if (acc[categoryTitle]) {
        acc[categoryTitle] = [...acc[categoryTitle], ehrStringBody];
      } else {
        acc[categoryTitle] = [ehrStringBody];
      }
    });
    return acc;
  }, {});

export const summaryString =
  "Here is a summary of the assessment and care decisions that were made on this patient:";

export const ehrStringMarkup = ehrs =>
  Object.keys(groupedEhrs(ehrs)).reduce((acc, categoryTitle) => {
    groupedEhrs(ehrs)[categoryTitle].forEach(ehr => {
      const ehrStringBody = `
    ${ehr.subtitle ? `${escape(ehr.subtitle)}<br />` : ""}
    ${ehr.text ? `<p>-${escape(ehr.text)}</p>` : ""}
    ${
      ehr.citations && ehr.citations.length > 0
        ? `${citations({ citations: ehr.citations })}<br />`
        : ""
    }
    `;
      if (acc[categoryTitle]) {
        acc[categoryTitle] = [...acc[categoryTitle], ehrStringBody];
      } else {
        acc[categoryTitle] = [ehrStringBody];
      }
    });
    return acc;
  }, {});

export const inputGroupStringsMarkup = selectedCalc =>
  selectedCalc.usage.calculator.inputGroups.map(inputGroup => {
    const inputStrings = inputGroup.inputs.map(input => {
      const isInputSelected = selectedInputs(selectedCalc).find(
        selectedInput => input.id === selectedInput.id
      );
      if (isInputSelected) {
        if (inputGroup.style === "multiple") {
          return `<p> - (Yes) ${escape(input.title)} </p> `;
        }
        if (inputGroup.style === "single") {
          return `<h4>${escape(inputGroup.title)}</h4><p> - ${escape(
            input.title
          )} </p> `;
        }
      } else if (!isInputSelected && inputGroup.style === "multiple") {
        return `<p> - (No) ${escape(input.title)} </p> `;
      } else {
        return "";
      }
    });
    return inputStrings.join("");
  });
