import React from "react";
import validator from "validator";
import moment from "moment";

// validation engine: https://www.npmjs.com/package/react-validation
// validators, https://www.npmjs.com/package/validator

export const isRequired = value => {
  if (!value.toString().trim().length) {
    return <span className="form-error is-visible">Required</span>;
  }
};
export const required = value => {
  if (!value.toString().trim().length) {
    return <span className="form-error is-visible">Required</span>;
  }
};
export const hasValidCode = value => {
  if (value.indexOf("2016") <= 0) {
    return "Must enter a valid code";
  }
};
export const isEmail = value => {
  if (!validator.isEmail(value)) {
    return (
      <span className="form-error is-visible">
        {value} is not a valid email.
      </span>
    );
  }
};
export const isPhone = value => {
  if (value.replace(/\D/g, "").trim().length !== 10) {
    return "Must be valid 10 digit phone number";
  }
};
export const isAlpha = () => {
  return <span className="form-error is-visible">only letters are valid</span>;
};
export const isEqualToNamedInput = (value, comparedValue) => {
  if (value !== comparedValue) {
    return <span className="form-error is-visible">should be equal</span>;
  }
};
export const passwordLength = value => {
  if (!value || value.length <= 7) {
    return (
      <span className="form-error is-visible">
        Needs to be at least 8 characters.
      </span>
    );
  }
};
export const passwordMatch = (value, components) => {
  const password = components.new_password.state;
  const passwordConfirm = components.new_password_confirm.state;
  const isBothUsed =
    password && passwordConfirm && password.isUsed && passwordConfirm.isUsed;
  const isBothChanged =
    isBothUsed && password.isChanged && passwordConfirm.isChanged;
  if (isBothUsed && isBothChanged && password.value !== passwordConfirm.value) {
    return (
      <span className="form-error is-visible">Passwords should be equal.</span>
    );
  }
};
export const hasNumber = value => {
  if (value && !value.match(/\d+/g)) {
    return (
      <span className="form-error is-visible">
        Needs to have at least one number.
      </span>
    );
  }
};
export const isNumeric = value => {
  if (value && !validator.isNumeric(value)) {
    return <span className="form-error is-visible">Can only be numbers</span>;
  }
};
export const isNumericOrEmpty = value => {
  if (!validator.isNumeric(value) && !validator.isEmpty(value)) {
    return <span className="form-error is-visible">Can only be numbers</span>;
  }
};
export const isNumericFloatOrEmpty = value => {
  const isNumeric = validator.isNumeric(value);
  const isFloat = validator.isFloat(value);
  const isEmpty = validator.isEmpty(value);
  // if it not numeric or float show message
  if (!(isNumeric || isFloat) && !isEmpty) {
    return <span className="form-error is-visible">Can only be a number</span>;
  }
};
export const isNumericFloatRangeSlashOrEmpty = value => {
  const isEmpty = validator.isEmpty(value);
  const isNumeric = validator.isNumeric(value);
  const isFloat = validator.isFloat(value);
  const isRangeWithDash = /\d+-\d+/.test(value);
  const isRangeWithSlash = /\d+\/\d+/.test(value);
  // since it comes back when false, if the negative of any of these were
  // false at any one time, then it would always come back false. So I'm checking if any are true,
  // if any are, then I won't return the error message
  const anyPass =
    isNumeric || isFloat || isRangeWithDash || isRangeWithSlash || isEmpty;
  if (!anyPass) {
    return (
      <span className="form-error is-visible">
        Can only be a number or range eg. 4-10
      </span>
    );
  }
};
export const isNumericFloatRangeOrEmpty = value => {
  if (
    (value &&
      (!validator.isNumeric(value) ||
        !validator.isFloat(value) ||
        !/\d+-\d+/.test(value))) ||
    !value
  ) {
    return (
      <span className="form-error is-visible">
        Can only be a number or range eg. 4-10
      </span>
    );
  }
};
export const isCreditCard = value => {
  if (!value || !validator.isCreditCard(value)) {
    return (
      <span className="form-error is-visible">Must be valid credit card</span>
    );
  }
};
export const hasCapLetter = value => {
  if (!value || value.toLowerCase() === value) {
    return (
      <span className="form-error is-visible">
        Needs to have at least one capital letter.
      </span>
    );
  }
};
export const isDateOrBlank = value => {
  if (!value || !moment(value, "MM/DD/YYYY", true).isValid()) {
    return (
      <span className="form-error is-visible">
        Must be valid date MM/DD/YYYY
      </span>
    );
  }
};
export const hasLetter = value => {
  if (!value || !/[a-z]/i.test(value)) {
    return (
      <span className="form-error is-visible">
        Needs to have at least one letter.
      </span>
    );
  }
};
export const hasLowerCaseLetter = value => {
  if (!value || !/[a-z]/.test(value)) {
    return (
      <span className="form-error is-visible">
        Needs to have at least one lowercase letter.
      </span>
    );
  }
};

export const passwordValidation = [
  passwordLength,
  hasCapLetter,
  hasNumber,
  hasLowerCaseLetter
];
