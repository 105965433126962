import { createSelector } from "reselect";
import {
  getSessionRxSetItems,
  getPatientLocation
} from "selectors/patientSelectors";
import R from "ramda";

// the chain function maps a function over a list and concatenates the results
export const getOrderMap = state => state.FHIR_orders.crosswalk;

export const getSelectedPrescriptionSets = createSelector(
  [getSessionRxSetItems],
  rxSetItems => {
    return R.values(rxSetItems).reduce((acc, sets) => {
      const selectedSets = sets.filter(set => set.isSelected);
      return [...acc, ...selectedSets];
    }, []);
  }
);

export const getSelectedMedData = createSelector(
  // this takes the ids from the selectedMeds in the UI and grabs the corresponding order data from state
  [getSelectedPrescriptionSets, getOrderMap, getPatientLocation],
  (prescriptionSets, orderMap, patientLocation) => {
    const matchedCrosswalkMeds = R.values(prescriptionSets).reduce(
      (acc, val) => {
        val.prescriptions.map(prescription => {
          if (prescription && orderMap[prescription]) {
            // check if the patient location matches the order location or if the order location is null
            if (
              patientLocation === orderMap[prescription].location ||
              !orderMap[prescription].location
            ) {
              acc.push(orderMap[prescription]);
            }
          }
        });

        return acc;
      },
      []
    );
    return matchedCrosswalkMeds;
  }
);
