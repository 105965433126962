import PropTypes from "prop-types";
import React, { Component } from "react";
import { Router } from "react-router";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader";

import GlobalComponents from "components/global/GlobalComponents";
import { logSentryError } from "helpers/logging/error";
import { history } from "myHistory";
import Routes from "routes";
import { Loader } from "components/ui/loader";
import GlobalModal from "components/global/Modal";

/**************************************
 * Provides a platform for:
 *
 * - A top level error boundary
 * - Displaying a loader until application
 * bootstrapping has completed
 * - HMR
 *
 * Takes the `store` as the redux store
 * and `bootstrappingFinishedPromise`
 * which resolves when bootstrapping is completed
 **************************************/

class AppContainer extends Component {
  state = {
    hasError: false,
    bootstrappingFinished: false
  };

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // also log the error to an error reporting service
    logSentryError(error, info);
  }

  componentDidMount() {
    this.props.bootstrappingFinishedPromise.then(() => {
      this.setState({ bootstrappingFinished: true });
    });
  }

  render() {
    const { store } = this.props;
    const { hasError, bootstrappingFinished } = this.state;

    if (hasError) {
      return (
        <div className="app-error">
          <h1 className="app-error_title">Oops, something has gone wrong!</h1>
          <p className="app-error_text">
            Our team has been notified, and we&apos;re working to address the
            error.
          </p>
        </div>
      );
    }

    return (
      <Provider store={store}>
        <div style={{ height: "100%" }}>
          {bootstrappingFinished ? (
            <>
              <Router history={history}>
                <Routes />
              </Router>
              <GlobalComponents />
            </>
          ) : (
            <>
              <Loader />
              <GlobalModal />
            </>
          )}
        </div>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  bootstrappingFinishedPromise: PropTypes.object.isRequired
};

export default hot(module)(AppContainer);
