import {
  SET_USER_FHIR,
  SET_USER_TERMS,
  SET_BOOTSTRAP,
  PATCH_USER,
  CLEAR_USER,
  SET_HAS_VIEWED_NEW_USER_MODAL
} from "./constants";

// ---------------------------------------------------------
// Action Handlers
// ---------------------------------------------------------

const ACTION_HANDLERS = {
  [SET_BOOTSTRAP]: (state, action) => ({ ...state, ...action.payload }),
  [SET_USER_TERMS]: (state, action) => ({ ...state, ...action.payload }),
  [SET_USER_FHIR]: (state, action) => ({
    ...state,
    FHIR_Practitioner: action.payload
  }),
  [CLEAR_USER]: () => initialState,
  [SET_HAS_VIEWED_NEW_USER_MODAL]: (state, { payload }) => ({
    ...state,
    hasViewedNewUserModal: payload
  }),
  [PATCH_USER]: (state, action) => {
    const newState = {
      ...state,
      ...action.payload
    };

    // `/account/me` and `/bootstrap` endpoints return different structs for their organization fields
    const { organization } = action.payload;
    if (typeof organization === "string") {
      newState.organization = {
        slug: organization,
        name: organization,
        cloudinary_id:
          state.organization.name === organization
            ? state.organization.cloudinary_id
            : undefined
      };
    }

    // different endpoints return different formats for the association array
    // we just want a list of slugs; (more information can be found in the `associations`
    // store
    const { associations } = action.payload;
    if (associations) {
      newState.associations = associations.map(association => {
        if (typeof association === "string") {
          return association;
        } else {
          return association.slug;
        }
      });
    }

    return newState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  // an array of strings specifying the content buckets the
  // user has access to
  content: [],

  // an array of feature flags (strings) specifying what features
  // this user has access to
  features: [],

  // basic bio info
  first_name: "",
  last_name: "",
  zipcode: "",

  // the contact email for the user
  // (separate from the login email)
  email: "",
  email_verified: false,

  // the login email for the user (web only)
  auth_email: "",
  auth_email_verified: false,

  // the number of CME hours the user has accumulated
  // on the platform
  cme_hours: null,

  // the organization the user belongs to; when set, contains the following fields:
  // - slug: unique identifier for the org
  // - name: the display name for the org
  // - cloudinary_id (optional): the cloudinary id for an image for the org
  organization: {},

  // an array of slugs (strings) specifying the associations
  // this user is a member of
  associations: [],

  // the cloudinary id for the user's avatar image
  cloudinary_id: "",

  // a field specifying the user's primary job (e.g., physician, student, etc)
  provider_type: "",

  // null if not selected; otherwise, has the following structure:
  // - id: int
  // - name: string (combination of university and college)
  // - college: string
  // - university: string
  // - city: string,
  // - state string (two letter abbrev)
  school: null,

  // null if not selected; otherwise, has the following structure:
  // - id: int
  // - name: string
  specialty: null,

  // a two letter string specifying the user's degree if a physician
  degree: null,

  // an array of workplaces (currently only the first one in this list
  // is what is represented in the UI)
  // each workplace has the following structure:
  // - id: uuid (string)
  // - name: string
  // - street: string
  // - city: string
  // - state: string (two letter abbrev)
  workplaces: [],

  // false iff the user should view
  // the new user modal on their FIRST login
  hasViewedNewUserModal: false,

  // when the user was created on our backend
  created_at: null,

  // todo what are these?
  FHIR_Practitioner: {},
  terms: [],
  permissions: [],
  preferences: {},
  referral_token: null,
  suggestedArrtibutes: [],
  npi: ""
};

export default function userReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
