import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "components/ui/Checkbox";
import TextWithCitation from "./TextWithCitation";

class McgContentItem extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    isNested: PropTypes.bool,
    isCheckingStatus: PropTypes.bool,
    level: PropTypes.number,
    parentId: PropTypes.string,
    handleCheckMcg: PropTypes.func.isRequired,
    indicationContainerClasses: PropTypes.string,
    indicationItemClasses: PropTypes.string,
    indicationTitleClasses: PropTypes.string,
    shouldBeSelected: PropTypes.bool
  };
  handleClick = ({ item, level, parentId }) => {
    this.props.handleCheckMcg({
      indicationId: item.Id,
      level,
      parentIndicationId: parentId
    });
  };
  render() {
    const {
      item,
      isNested,
      isCheckingStatus,
      level,
      indicationContainerClasses,
      indicationItemClasses,
      indicationTitleClasses,
      shouldBeSelected,
      parentId
    } = this.props;
    const checkboxClasses = classNames("indication-check-box", {
      "indication-check-box--nested": isNested,
      "indication-check-box--fetching": isCheckingStatus
    });
    return (
      <div
        className={`${indicationContainerClasses} ${indicationContainerClasses}--${level}`}
        onClick={() => this.handleClick({ item, level, parentId })}
      >
        <div className={indicationItemClasses}>
          {
            <Checkbox
              checkboxClassName={checkboxClasses}
              isSelected={shouldBeSelected}
              name={item.Id}
              onChange={() => {}}
            />
          }
          <div className={indicationTitleClasses + " clickable"}>
            <TextWithCitation item={item} />
          </div>
        </div>
      </div>
    );
  }
}

export default McgContentItem;
