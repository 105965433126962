import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PatientEducationItems from "./PatientEducationItems";

const PatientEdAfterCare = ({ patientEds }) => {
  return (
    <Fragment>
      <div className="patient-ed-resources_subtitle">
        Here is a list of symptoms which could indicate you need to return or
        contact your provider.
      </div>
      <PatientEducationItems patientEds={patientEds} />
    </Fragment>
  );
};

export default PatientEdAfterCare;

PatientEdAfterCare.propTypes = {
  patientEds: PropTypes.array
};
