import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import classNames from "classnames";

import { hideModal } from "store/modal/actions";
import Tarascon from "components/modals/Tarascon";
import GoodRx from "components/modals/GoodRx";
import Generic from "components/modals/Generic";
import Calculator from "components/modals/Calculator";
import ErrorModal from "components/modals/Error";
import Video from "components/modals/Video";
import Eliquis from "components/modals/Eliquis";
import CMETest from "components/modals/CMETest/CMETest";
import CMEDisclosure from "components/modals/CMEDisclosure/CMEDisclosure";
import SelfHelp from "components/modals/SelfHelp";
import Sherpa from "components/modals/Sherpa";
import ImagingAdvisorWelcome from "components/modals/ImagingAdvisorWelcome";

import "./Modal.scss";

export class GlobalModal extends Component {
  componentDidMount() {
    Modal.setAppElement("#root");
  }
  render() {
    const { ContentEl, hideModal, additionalClasses, modalProps } = this.props;

    if (!ContentEl) return null;

    return (
      <Modal
        className={classNames("global-modal_content", additionalClasses)}
        style={{ content: { width: modalProps.modalWidth } }}
        isOpen
        onRequestClose={hideModal}
      >
        <div className="global-modal_content-container">
          <ContentEl {...modalProps} />
        </div>
        <button
          className="global-modal_close-button icon-close"
          onClick={hideModal}
        />
      </Modal>
    );
  }
}
GlobalModal.propTypes = {
  additionalClasses: PropTypes.string,
  ContentEl: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  hideModal: PropTypes.func,
  modalProps: PropTypes.object
};

/*********************************************************
 * Redux Logic
 *********************************************************/

const MODAL_COMPONENTS = {
  TARASCON: {
    ContentEl: Tarascon,
    additionalClasses: "global-modal_tarascon"
  },
  GOODRX: {
    ContentEl: GoodRx,
    additionalClasses: "global-modal_goodrx"
  },
  GENERIC: {
    ContentEl: Generic
  },
  CALCULATOR: {
    ContentEl: Calculator,
    additionalClasses: "global-modal_calculator"
  },
  ERROR: {
    ContentEl: ErrorModal,
    additionalClasses: "global-modal_error-modal"
  },
  VIDEO: {
    ContentEl: Video,
    additionalClasses: "global-modal_video"
  },
  ELIQUIS: {
    ContentEl: Eliquis,
    additionalClasses: "global-modal_eliquis"
  },
  CME_TEST: {
    ContentEl: CMETest,
    additionalClasses: "global-modal_cme-test"
  },
  DISCLOSURE: {
    ContentEl: CMEDisclosure,
    additionalClasses: "global-modal_disclosure"
  },
  SELF_HELP: {
    ContentEl: SelfHelp,
    additionalClasses: "global-modal_self-help"
  },
  SHERPA: {
    ContentEl: Sherpa
  },
  IMAGING_ADVISOR_WELCOME: {
    ContentEl: ImagingAdvisorWelcome,
    additionalClasses: "global-modal_imaging-advisor-welcome"
  }
};

const mapStateToProps = state => {
  const {
    modal: { modalType, modalProps }
  } = state;
  const { additionalClasses, ContentEl } = MODAL_COMPONENTS[modalType] || {};
  return {
    additionalClasses,
    ContentEl,
    modalProps
  };
};

const mapDispatchToProps = {
  hideModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalModal);
