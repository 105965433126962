import { makeEcContentRequest as makeEcRequest } from "lib/ec/base";
import { REHYDRATE } from "redux-persist";
import dotProp from "dot-prop";
import * as immutable from "object-path-immutable";
export const createListItemApi = ({ token, apiPath, propName, value }) => {
  return makeEcRequest({
    method: "POST",
    location: `${apiPath}`,
    token,
    body: { [propName]: value }
  });
};
export const fetchListItemApi = ({ token, apiPath, pageSize = 500 }) => {
  return makeEcRequest({
    method: "GET",
    location: `${apiPath}?page_size=${pageSize}`,
    token
  });
};

/*
  Purpose of this is to fetch, update, and cache smaller reusable lists
  Current criteria are lists that are not > 200 items
*/

// ------------------------------------
// Constants
// ------------------------------------
const initialMedProfileMetaState = { data: [], timestamp: null };
export const COMMON_LISTS_FETCH = "COMMON_LISTS_FETCH";
export const COMMON_LISTS_SET_LIST = "COMMON_LISTS_SET_LIST";
export const COMMON_LISTS_SET_LOADING = "COMMON_LISTS_SET_LOADING";
export const COMMON_LISTS_SET_ADD_ITEM = "COMMON_LISTS_SET_ADD_ITEM";
export const MEDICATIONS_SET_PROFILE_META = "MEDICATIONS_SET_PROFILE_META";
// const listApi = {
//   frequency: { fetch: fetchFrequenciesApi, create: null },
//   duration: { fetch: fetchDurationsApi, create: null },
//   contrastDetail: { fetch: fetchContrastDetailsApi, create: createContrastDetailApi, serverFieldName: 'contrast_detail' },
//   priority: { fetch: fetchPrioritiesApi, create: createPriorityApi },
//   source: { fetch: fetchSourcesApi, create: createSourceApi }
// }

// ------------------------------------
// Actions
// ------------------------------------
export function fetchList({
  reduxListsSmallStateName,
  apiPath,
  pageSize = 500,
  refresh = false
}) {
  return (dispatch, getState) => {
    dispatch(setList({ reduxListsSmallStateName, isFetching: true }));
    const token = getState().user.token;
    return fetchListItemApi({ token, apiPath, pageSize }).then(({ data }) => {
      dispatch(
        setList({ reduxListsSmallStateName, results: data, isFetching: false })
      );
    });
  };
}

export function setList({ reduxListsSmallStateName, results, isFetching }) {
  return {
    type: COMMON_LISTS_SET_LIST,
    payload: { stateName: reduxListsSmallStateName, results, isFetching }
  };
}

export function addToList({
  apiPath,
  name,
  propName,
  value,
  reduxListsSmallStateName
}) {
  return (dispatch, getState) => {
    const token = getState().user.token;
    return createListItemApi({ token, apiPath, propName, value }).then(
      ({ data }) => {
        console.log("should have id in here, which we should return", data);
        dispatch(addNewItemToList({ reduxListsSmallStateName, item: data }));
        return data;
      }
    );
  };
}

export function addNewItemToList({ reduxListsSmallStateName, item }) {
  return {
    type: COMMON_LISTS_SET_ADD_ITEM,
    payload: { stateName: reduxListsSmallStateName, item }
  };
}

export const setMedicationsProfileMeta = ({ medProfileMeta }) => ({
  type: MEDICATIONS_SET_PROFILE_META,
  payload: { medProfileMeta }
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [REHYDRATE]: (state, action) => {
    var incoming = action.payload && action.payload.smallLists;
    const medProfileMetaTimestamp = dotProp.get(
      incoming,
      "medProfileMeta.timestamp"
    );
    if (
      medProfileMetaTimestamp &&
      Date.now() - medProfileMetaTimestamp > 600000
    ) {
      const updatedIncoming = immutable.set(
        incoming,
        "medProfileMeta",
        initialMedProfileMetaState
      );
      return { ...state, ...updatedIncoming };
    }
    if (incoming) return { ...state, ...incoming };
    return state;
  },
  [COMMON_LISTS_SET_LIST]: (state, action) => {
    const { stateName, results, isFetching = false } = action.payload;
    return Object.assign({}, state, {
      [stateName]: Object.assign(
        {},
        state[stateName],
        results && { list: results }, // only update list if results are passed in
        { isFetching }
      )
    });
  },
  [COMMON_LISTS_SET_ADD_ITEM]: (state, action) => {
    const { stateName, item } = action.payload;
    return Object.assign({}, state, {
      [stateName]: Object.assign({}, state[stateName], {
        list: [...state[stateName].list, item]
      })
    });
  },
  [MEDICATIONS_SET_PROFILE_META]: (state, action) =>
    Object.assign({}, state, {
      medProfileMeta: {
        data: action.payload.medProfileMeta,
        timestamp: Date.now()
      }
    })
};

// ------------------------------------
// Reducer
// ------------------------------------
const defaultState = {
  count: null,
  list: [],
  isFetching: false,
  page: null,
  cacheKey: REDUX_PERSIST_CACHE_KEY
};

const initialState = {
  frequency: {
    ...defaultState
  },
  duration: {
    ...defaultState
  },
  contrastDetail: {
    ...defaultState
  },
  priority: {
    ...defaultState
  },
  source: {
    ...defaultState
  },
  medProfileMeta: initialMedProfileMetaState
};
export default function smallListsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
