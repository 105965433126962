import { makeEcRequest, makeOmniRequest } from "./base";

export const fetchPluginProtocolConfigs = ({ token, protocolId }) => {
  return makeEcRequest({
    method: "GET",
    location: `account/plugins/protocol/${protocolId}`,
    token
  });
};

export const fetchAdmissionContextApi = ({ token, hsims }) => {
  return makeOmniRequest({
    method: "GET",
    location: `search?types=admission&filters={"context.hsim":${hsims}}`,
    token
  });
};
