import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { error } from "react-notification-system-redux";

import { unsubscribeApi } from "lib/ec/account";
import { showLoader, hideLoader } from "store/ui";
import {
  EVENT_UNSUBSCRIBE_ERROR,
  EVENT_EMAIL_UNSUBSCRIBE_SUCCESS
} from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";
import AuthLayout from "layouts/AuthLayout/AuthLayout";

export class Unsubscribe extends React.PureComponent {
  state = {
    text: ""
  };

  componentDidMount() {
    const { uid, showLoader, pipelineRequest, error, hideLoader } = this.props;
    showLoader();
    unsubscribeApi({
      uid
    })
      .then(({ data }) => {
        // display message if already unsubscribed
        const message = data && data.non_field_errors;
        if (message && message.toLowerCase() === "already unsubscribed") {
          this.setState({ text: "You have previously been unsubscribed." });
        } else {
          this.setState({ text: "You have been unsubscribed." });
          pipelineRequest({
            action: EVENT_EMAIL_UNSUBSCRIBE_SUCCESS
          });
        }
      })
      .catch(() => {
        pipelineRequest({
          action: EVENT_UNSUBSCRIBE_ERROR
        });
        // alert the user that there was an error
        error({ message: "There was an error when attempting to unsubscribe" });
      })
      .finally(() => {
        hideLoader();
      });
  }

  render() {
    const { text } = this.state;
    return (
      <AuthLayout>
        <div className="accept-invite_wrap">
          <div className="accept-invite">
            <h4 className="accept-invite_header">Unsubscribed</h4>
            <p>{text}</p>
            <a href="/" className="accept-invite_submit-button text-center">
              <span className="accept-invite_submit-button-label">
                Continue to EvidenceCare
              </span>
            </a>
          </div>
          <img
            className="login_logo show-for-medium"
            src={`${CLOUDINARY_URL}/evidencecare/image/upload/v1496332970/tagalong-site-assets/evidencecare-logo.svg`}
          />
        </div>
      </AuthLayout>
    );
  }
}

Unsubscribe.propTypes = {
  uid: PropTypes.string.isRequired,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  error: PropTypes.func,
  pipelineRequest: PropTypes.func
};

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  error,
  pipelineRequest
};

export default connect(
  null,
  mapDispatchToProps
)(Unsubscribe);
