import React from "react";
import PropTypes from "prop-types";
// import { setActiveMoreInfo } from 'store/protocol'
import { setCalcInput, setSelectedCalcInputsToUnselected } from "store/session";
import classNames from "classnames";
import { connect } from "react-redux";
import { Tooltip } from "components/ui/tooltip";

const ShowSingleInput = ({
  input,
  index,
  calcUsageId,
  inputState = {},
  setCalcInput,
  inputGroupId,
  setSelectedCalcInputsToUnselected
}) => {
  const toggleInput = ({ calcUsageId, inputId, selected, setCalcInput }) => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedCalcInputsToUnselected({ calcUsageId, inputId, inputGroupId });

      setCalcInput({ calcUsageId, inputId, selected });
    };
  };
  const inputClasses = classNames(
    "calculator-input",
    "row",
    "align-middle",
    "calculator-input_single",
    {
      "calculator-input--active": inputState.selected
    }
  );
  const subtitleClasses = classNames("calculator-input_subtitle", {
    "calculator-input_subtitle--active": inputState.selected
  });
  return (
    <div
      key={input.id}
      className={inputClasses}
      onClick={toggleInput({
        calcUsageId,
        inputId: input.id,
        setCalcInput,
        selected: inputState.selected ? null : true
      })}
    >
      <div className="columns">
        <div className="calculator-input_name">
          {input.title}
          {__FHIR__ && inputState.selectedByObservation && inputState.selected && (
            <div className="calculator-input_observation-icon-wrap">
              <Tooltip
                id="ehr-doc-tooltip"
                message="This selection was populated from the EHR"
                place="top"
              >
                <i className="calculator-input_observation-icon icon-ehr-information" />
              </Tooltip>
            </div>
          )}
        </div>
        {input.subtitle && (
          <div className={subtitleClasses}>{input.subtitle}</div>
        )}
      </div>
      <div className="calculator-input_value columns shrink">
        {input.value > 0 ? `+ ${input.value}` : input.value}
      </div>
    </div>
  );
};

ShowSingleInput.propTypes = {
  input: PropTypes.object.isRequired,
  index: PropTypes.number,
  // setActiveMoreInfo: PropTypes.func,
  setCalcInput: PropTypes.func,
  calcUsageId: PropTypes.string,
  inputState: PropTypes.object,
  setSelectedCalcInputsToUnselected: PropTypes.func,
  inputGroupId: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  // activeMoreInfoId: state.protocol.activeMoreInfoId,
  inputState:
    state.session.entities.usages[props.calcUsageId] &&
    state.session.entities.usages[props.calcUsageId].inputs[props.input.id]
});
const mapDispatchToProps = {
  // setActiveMoreInfo,
  setCalcInput,
  setSelectedCalcInputsToUnselected
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSingleInput);
