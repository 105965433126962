import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Tour from "components/Tour/Tour";
import TourSelectorButton from "components/Tour/TourSelectorButton";

export class TourWrap extends Component {
  render() {
    const { toursActive } = this.props;

    return (
      toursActive && (
        <>
          <Tour />
          <TourSelectorButton />
        </>
      )
    );
  }
}
TourWrap.propTypes = {
  toursActive: PropTypes.bool
};

const mapStateToProps = state => ({
  toursActive: state.tour.featureActivated
});

export default connect(mapStateToProps)(TourWrap);
