import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import { error, info, hide } from "react-notification-system-redux";
import { fetchContentBootstrap } from "lib/ec/protocol";
import { createFhirProfileApi, fetchBootStrapInfoApi } from "lib/ec/profile";
import { loadUserProfile, patchUser, setUserFHIR } from "store/user/actions";
import { setCME } from "store/account";
import { loadTours } from "store/tour/actions";
import { batch } from "react-redux";

import { showModal, loadSherpaModals } from "store/modal/actions";
import { loadHelpCenterLanding } from "store/helpCenter/actions";
import { fhirFactory } from "lib/fhir/factory";
import { logSentryError } from "helpers/logging/error";
import { hashEncounterID, hasExistingSession } from "helpers/ehr/fhir";
import {
  FHIR_FLAGS_HAS_ENCOUNTER_DATA,
  FHIR_FLAGS_HAS_PATIENT_DATA,
  FHIR_DEVELOPER_CONSOLE,
  FHIR_FLAGS_DISABLE_IA_WELCOME
} from "constants/fhir";
import {
  selectOrganizationSlug,
  selectUserHasFeature
} from "store/user/selectors";
import {
  fetchAndSetSessionList,
  setCustomHandle,
  setEncounter,
  setIdentifiers,
  setPatient,
  setAppContext,
  setCDSOrdersResponse
} from "store/patient";
import { selectAuthToken, selectFHIRScope } from "store/auth/selectors";
import { setRedirectLocation } from "store/auth/actions";
import { setCDSMode } from "store/ui";
import { pipelineRequest } from "store/pipeline";
import {
  EVENT_IA_LAUNCH_PROTOCOL,
  EVENT_HASHED_ENCOUNTER
} from "constants/broadcastEventTypes";
import { hasFeature } from "helpers/user";
import { fetchAppContext } from "lib/ec/imaging-advisor";

/**********************************************************************
 * The intent of this file is to provide a consolidated location
 * for all of the logic required to fetch data that the application needs
 * on bootstrap. However, while all of the logic has been consolidated here,
 * there is still a lot of legacy cruft that should be addressed in the
 * near future:
 *
 * - todo: separate this file into separate files for reducer, action, selectors, and constants
 *
 * - todo: optimize the bootstrapping logic; after a brief initial review,
 *   it appears that we can optimize the bootstrap logic so that to reduce time
 *   to first render by ~50%, especially in FHIR environments
 *
 * - todo: separate the subscription polling logic into its own system;
 *   this really has no business being conflated with the bootstrap
 *
 * - todo: there really is no reason to have a separate store for "bootstrap content";
 *   this content should really be integrated into the application at a separate location
 *
 * - todo: better documentation; it is unclear (a) why particular data is required on
 *   boostrap and (b) why some data is required before the initial render or
 *   post session launch logic
 *
 * - todo: paradigm for how bootstrapping should work in tandem with store persistence
 **********************************************************************/

// ------------------------------------
// Constants
// ------------------------------------
export const SET_BOOTSTRAP_CONTENT = "SET_BOOTSTRAP_CONTENT";
export const FIREBUG_SCRIPT = document.createElement("script");
FIREBUG_SCRIPT.language = "javscript";
FIREBUG_SCRIPT.async = true;
FIREBUG_SCRIPT.type = "text/javascript";
FIREBUG_SCRIPT.src = `https://devtools.evidence.care/firebug-lite/firebug-lite-debug.js#overrideConsole=true,startInNewWindow=false,startOpened=false,enableTrace=false`;

// ------------------------------------
// Evidence Care Data Bootstrapping
// ------------------------------------

// Loads the initial set of EC data that the application needs to render
export const loadBootstrapData = () => {
  return (dispatch, getState) => {
    let state = getState();

    const bootstrapContentPromise = !selectIsBootstrapContentLoaded(state)
      ? fetchContentBootstrap()
          .then(({ data }) =>
            dispatch({ type: SET_BOOTSTRAP_CONTENT, payload: data })
          )
          .catch(e => {
            console.log("bootstrap content error");
          })
      : Promise.resolve();

    const boostrapInfoPromise = fetchBootStrapInfoApi().then(({ data }) =>
      dispatch(handleFetchBootsrapInfoApiResponse(data))
    );

    return Promise.all([bootstrapContentPromise, boostrapInfoPromise]);
  };
};

// Handles the response from `fetchBootStrapInfoApi`
function handleFetchBootsrapInfoApiResponse(data) {
  return (dispatch, getState) => {
    // As we now have a userId, we can start tracking
    if (!__FHIR__) {
      ReactGA.set({ userId: data.alias });
      Sentry.configureScope(scope => {
        scope.setExtra(
          "isStaff",
          typeof data.email === "string" &&
            data.email.includes("@evidence.care")
        );
        scope.setUser({ id: data.user_uid, email: data.email });
      });
    }

    dispatch(patchUser(data));

    // todo remove this legacy 'account' code
    dispatch(setCME({ total: data.cme_hours }));

    const state = getState();

    if (
      selectUserHasFeature(state, { flag: FHIR_DEVELOPER_CONSOLE }) &&
      !document.head.contains(FIREBUG_SCRIPT)
    ) {
      document.head.appendChild(FIREBUG_SCRIPT);
    }

    if (selectUserHasFeature(state, { flag: "SHERPA_TOURS" })) {
      dispatch(loadTours());
    }
    if (selectUserHasFeature(state, { flag: "SHERPA_MODALS" })) {
      dispatch(loadSherpaModals());
    }

    if (selectUserHasFeature(state, { flag: "SHERPA_HELP_CENTER" })) {
      dispatch(loadHelpCenterLanding());
    }

    dispatch(loadUserProfile());
  };
}

// ------------------------------------
// FHIR Bootstrapping
// ------------------------------------
let fhirModule = null;
if (__FHIR__) {
  fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);
}

export const loadBootstrapFHIRData = () => {
  return async (dispatch, getState) => {
    console.log("BOOTSTRAPPING: ");

    let fhirCDS = false;
    const state = getState();
    const scope = selectFHIRScope(state);

    //-----------------------------
    // Launch scopes
    //-----------------------------
    if (!fhirModule.clearForLaunch(FHIR_LAUNCH_SCOPE, scope)) {
      logSentryError("Launch scope issue detected", {
        requestedScopes: FHIR_LAUNCH_SCOPE,
        returnedScopes: scope
      });
      dispatch(
        error({
          message: `A configuration error occurred. Please contact support if the issue persists.`,
          autoDismiss: 5
        })
      );
    }

    //-----------------------------
    // CDS Hooks / Imaging Advisor
    //-----------------------------
    let appContext = null;
    let appContextId = null;
    appContext = await fhirModule.getCDSAppContext();

    appContextId = appContext && appContext.id ? appContext.id : null;

    if (appContextId) {
      try {
        // use the id from appContext to get the data from CDS service
        const appContextResponse = await fetchAppContext({ id: appContextId });

        if (appContextResponse && appContextResponse.data) {
          console.log("TCL: loadBootstrapFHIRData -> appContext", appContext);

          fhirCDS = true;
          const user = getState().user;
          !hasFeature(user.features, FHIR_FLAGS_DISABLE_IA_WELCOME) &&
            dispatch(showModal("IMAGING_ADVISOR_WELCOME"));
          batch(() => {
            dispatch(
              setCDSOrdersResponse(
                appContextResponse.data.matches[appContext.protocol.id].orders
              )
            );
            dispatch(setAppContext(appContext));
            dispatch(setCDSMode(fhirCDS));

            dispatch(
              pipelineRequest({
                action: EVENT_IA_LAUNCH_PROTOCOL,
                message: { appContext: appContextResponse.data }
              })
            );
          });
        }
      } catch (error) {
        console.log("fetchAppContext error: ", error);
      }
    }

    //-----------------------------
    // FHIR Data
    //-----------------------------
    if (FHIR_FLAGS_HAS_PATIENT_DATA && FHIR_FLAGS_HAS_ENCOUNTER_DATA) {
      const token = selectAuthToken(state);
      const orgSlug = selectOrganizationSlug(state);

      let patientResponse = null;
      patientResponse = await fhirModule.fetchPatientData();
      dispatch(setPatient(fhirModule.getPatient(patientResponse)));

      if (patientResponse) {
        const encounterResponse = await fhirModule.fetchEncounterData(
          dispatch,
          token,
          patientResponse,
          orgSlug
        );
        if (encounterResponse) {
          const encounterHash = encounterResponse.encounter
            ? hashEncounterID(encounterResponse.encounter)
            : null;
          dispatch(setIdentifiers(encounterResponse.identifiers || []));
          dispatch(setCustomHandle({ customHandle: encounterHash }));
          dispatch(setEncounter({ encounter: encounterResponse.encounter }));
          dispatch(
            pipelineRequest({
              action: EVENT_HASHED_ENCOUNTER,
              message: { hashEncounterID: encounterHash }
            })
          );
        }
      }

      const userFhirResponse = await fhirModule.fetchUserFhirData(token);

      if (userFhirResponse) {
        dispatch(setUserFHIR(userFhirResponse));
        createFhirProfileApi(userFhirResponse, orgSlug);
      } else {
        //adding some logging when the response is null
        logSentryError("Null FHIR Profile", userFhirResponse);
      }

      //-----------------------------
      // Session Rehydration
      //-----------------------------
      const res = await dispatch(fetchAndSetSessionList());
      const sessionList = res.results;

      if (hasExistingSession(sessionList, fhirCDS)) {
        dispatch(
          setRedirectLocation(
            `/protocol/s/${sessionList[0].current_protocol_id}/${sessionList[0].id}`
          )
        );
      } else if (fhirCDS) {
        //redirect to the protocol from appContext
        dispatch(setRedirectLocation(`/protocol/s/${appContext.protocol.id}`));
      }
    }
    console.log("DONE BOOTSTRAPPING");
  };
};

// ------------------------------------
// Polling Bootstrap
// ------------------------------------

export const pollForValidSubscription = () => {
  return dispatch => {
    const notificationOpts = {
      uid: "subscription-update-wait",
      title: "We are syncing your new features!",
      position: "tr",
      message: "Your new features are being added to our system.",
      autoDismiss: 0
    };
    dispatch(info(notificationOpts));
    return pollEndpoint({
      apiCallFunction: fetchBootStrapInfoApi,
      prop: "subscription_valid",
      val: true
    })
      .then(data => {
        // Polling done, now do something else!
        dispatch(handleFetchBootsrapInfoApiResponse(data));
        dispatch(hide("subscription-update-wait"));
      })
      .catch(() => {
        // Polling timed out, handle the error!
        const notificationOpts = {
          uid: "subscription-update-fail",
          title: "We were not able to validate your subscription!",
          position: "tr",
          message:
            "An error occurred while trying to validate your subscription. We have been notified and are looking in to this issue."
        };
        dispatch(error(notificationOpts));
      });
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_BOOTSTRAP_CONTENT]: (
    state,
    {
      payload: {
        careCategoriesOrdering,
        ehrCategoriesOrdering,
        patientEducationCategoriesOrdering
      }
    }
  ) => ({
    ...state,
    patientEdOrdering: {
      careCategoriesOrdering,
      ehrCategoriesOrdering,
      patientEducationCategoriesOrdering
    },
    hasLoaded: true
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const INITIAL_STATE = {
  hasLoaded: false,
  patientEdOrdering: {
    careCategoriesOrdering: [],
    ehrCategoriesOrdering: [],
    patientEducationCategoriesOrdering: []
  }
};

export default function bootstrapContent(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export function selectBootstrapContent(state) {
  return state.bootstrapContent;
}

export function selectIsBootstrapContentLoaded(state) {
  return selectBootstrapContent(state).hasLoaded;
}

// ------------------------------------
// Utilities
// -----------------------------------

// Executes `apiCallFunction` (which must return a Promise) and inspects the return value continually
//
// By default, successfully resolves when the returned Promise resolves successfully
// If  just `prop` is given, resolves when prop is present on the return data
// If `prop` and `val` are given, resolves when prop is equal to val on the returned data
//
// `timeout` specifies how long polling should continue
// `interval` specifies the ms between polls

const pollEndpoint = ({
  apiCallFunction,
  timeout = 600000 /* 10 min */,
  interval = 5000,
  prop,
  val
}) => {
  const endTime = Date.now() + timeout;

  return new Promise((res, rej) => {
    const poll = () => {
      apiCallFunction().then(({ data } = {}) => {
        let shouldRes = false;

        if (data !== undefined) {
          if (prop !== undefined) {
            if (val !== undefined) {
              shouldRes = data[prop] === val;
            } else {
              shouldRes = data.hasOwnProperty(val);
            }
          } else {
            shouldRes = true;
          }
        }

        if (shouldRes) {
          res(data);
        } else if (Date.now() < endTime) {
          // doesn't have the value, but the timeout hasn't elapsed, go again
          setTimeout(poll, interval);
        } else {
          // Didn't match and too much time, reject!
          rej(new Error("timed out for " + apiCallFunction));
        }
      });
    };

    poll();
  });
};
