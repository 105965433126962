import createCachedSelector from "re-reselect";

export const getThreadComments = createCachedSelector(
  (state, protocolId, itemId) => protocolId,
  (state, protocolId, itemId) => state.comments.protocol,
  (state, protocolId, itemId) => itemId,
  (activeProtocolId, protocolCommentsById, itemId) => {
    const protocolComments = protocolCommentsById[activeProtocolId];
    return (
      (protocolComments &&
        protocolComments.filter(com => com.itemId === itemId)) ||
      []
    );
  }
)((state, protocolId, itemId) => {
  return itemId;
});
