import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { success, error } from "react-notification-system-redux";
import classnames from "classnames";

import { acceptInviteApi, fetchInviteApi } from "lib/ec/account";
import { showLoader, hideLoader } from "store/ui";
import { EVENT_INVITE_ACCEPT_FAILURE } from "constants/broadcastEventTypes";
import { selectPartnerTemplate } from "constants/partners";
import { pipelineRequest } from "store/pipeline";
import { history } from "myHistory";
import { launchLogin, onAuth0Credentials } from "store/auth/actions";
import InviteInput from "components/auth/invite/InviteInput";
import { selectIsAuthenticated } from "store/auth/selectors";
import { selectUserEmail } from "store/user/selectors";
import AuthLayout from "layouts/AuthLayout/AuthLayout";

/************************************'
 * Reaching this component implies the user clicked on
 * an EC invite link. This component
 * contains all of the logic necessary
 * for processing that invite (though perhaps that should
 * be abstracted) and creating a new user
 * Auth session if the invite processing is successful
 *************************************/
export class InviteAccept extends React.PureComponent {
  state = {
    email: "",
    connection: ""
  };

  componentDidMount() {
    const {
      error,
      success,
      showLoader,
      hideLoader,
      isAuthenticated,
      currentEmail,
      inviteId,
      singleUseToken
    } = this.props;

    showLoader();

    fetchInviteApi(singleUseToken, inviteId)
      .then(({ data }) => {
        const { user_exists, email, connection } = data;

        if (user_exists) {
          if (!isAuthenticated) {
            error({
              message:
                "This invite is for a user that already exists! Please sign in before using the invite link."
            });
            hideLoader();
            launchLogin();
          } else if (email !== currentEmail) {
            error({ message: "This invite is not for your user!" });
            hideLoader();
            launchLogin(true);
          } else {
            acceptInviteApi({
              singleUseToken,
              inviteId
            })
              .then(() => {
                history.push("/");
                success({ message: "Your invitation has been accepted." });
              })
              .catch(this.acceptInviteFailure)
              .finally(hideLoader);
          }
        } else if (isAuthenticated) {
          const path = window.location.pathname;
          launchLogin(true);
          history.push(path);
        } else {
          this.setState({ email, connection });
          hideLoader();
        }
      })
      .catch(this.fetchInviteError)
      .finally(hideLoader);
  }
  // Cleanup to do if fetching the invite from the backend fails
  fetchInviteError = err => {
    const { error, pipelineRequest, inviteId } = this.props;

    // if the backend returns a 404 on the fetchInviteApi method, it means the invitation has already been accepted
    this.props.hideLoader();
    if (err.response.status === 404) {
      error({ message: "That invite has already been accepted." });
      pipelineRequest({
        action: EVENT_INVITE_ACCEPT_FAILURE,
        message: { inviteId }
      });
    } else {
      error({ message: "There was an error with your invite" });
      pipelineRequest({
        action: EVENT_INVITE_ACCEPT_FAILURE,
        message: { inviteId }
      });
    }

    // we just redirect to the login page here instead
    // of logging the user out and then redirecting them as there
    // is no need to redo the auth flow
    history.push("/login");
  };

  // Cleanup to do if accepting the invite fails
  acceptInviteFailure = err => {
    const { error, pipelineRequest, inviteId } = this.props;

    const data = err && err.response && err.response.data;
    if (data && data.uid && data.uid[0] === "invitation already accepted") {
      error({ message: "That invite has already been accepted" });
    } else {
      error({ message: "There was an error accepting your invite" });
      pipelineRequest({
        action: EVENT_INVITE_ACCEPT_FAILURE,
        message: { inviteId }
      });
    }

    //
    history.push("/login");
  };

  acceptInvite = password => {
    const {
      inviteId,
      singleUseToken,
      showLoader,
      hideLoader,
      loginWithCredentials
    } = this.props;
    const { email } = this.state;

    showLoader();

    acceptInviteApi({
      singleUseToken,
      inviteId,
      password
    })
      .then(({ status }) => {
        if (status === 200) {
          loginWithCredentials({
            connection: "Email-Password-Authentication-With-Migration",
            username: email,
            password
          });
        }
      })
      .catch(this.acceptInviteFailure)
      .finally(hideLoader);
  };

  render() {
    const { email } = this.state;
    const { organization } = this.props;

    const { cloudinaryUrl, welcomeText } =
      selectPartnerTemplate(organization) || {};

    const acceptInviteWrapperClasses = classnames("accept-invite", {
      "accept-invite--with-org": cloudinaryUrl || welcomeText
    });
    const acceptInviteHeaderClasses = classnames("accept-invite_header", {
      "accept-invite_header--with-org": cloudinaryUrl || welcomeText
    });

    return (
      <AuthLayout>
        <div className="accept-invite_wrap">
          <div className={acceptInviteWrapperClasses}>
            {cloudinaryUrl && <img src={cloudinaryUrl} />}
            <h4 className={acceptInviteHeaderClasses}>
              Welcome to EvidenceCare
            </h4>
            {welcomeText && (
              <div
                className="accept-invite_welcome-text"
                dangerouslySetInnerHTML={{ __html: welcomeText }}
              />
            )}
            {email && (
              <div>
                <p className="accept-invite_copy">
                  Create a password to accept your invite
                </p>
                <InviteInput onSubmit={this.acceptInvite} />
              </div>
            )}
          </div>
          <img
            className="login_logo show-for-medium"
            src={`${CLOUDINARY_URL}/evidencecare/image/upload/v1496332970/tagalong-site-assets/evidencecare-logo.svg`}
          />
        </div>
      </AuthLayout>
    );
  }
}

InviteAccept.propTypes = {
  currentEmail: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  error: PropTypes.func,
  success: PropTypes.func,
  pipelineRequest: PropTypes.func,
  loginWithCredentials: PropTypes.func,
  inviteId: PropTypes.string.isRequired,
  singleUseToken: PropTypes.string.isRequired,
  organization: PropTypes.string
};

const mapStateToProps = state => ({
  isAuthenticated: selectIsAuthenticated(state),
  currentEmail: selectUserEmail(state)
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  error,
  success,
  pipelineRequest,
  loginWithCredentials: onAuth0Credentials
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteAccept);
