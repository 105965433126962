import {
  TOUR_ACTIVATE_FEATURE,
  TOUR_START,
  TOUR_PAUSE,
  TOUR_RESUME,
  TOUR_SET_FLOWS,
  TOUR_STOP,
  TOUR_SET_STEP
} from "./constants";

// ------------------------------------
// Reducer
// ------------------------------------

const ACTION_HANDLERS = {
  [TOUR_ACTIVATE_FEATURE]: state => ({ ...state, featureActivated: true }),
  [TOUR_START]: (state, { payload }) => ({
    ...state,
    activeFlow: payload,
    isPaused: false,
    stepIndex: 0
  }),
  [TOUR_STOP]: state => ({ ...state, activeFlow: null, isPaused: false }),
  [TOUR_PAUSE]: state => ({ ...state, isPaused: true }),
  [TOUR_RESUME]: state => ({ ...state, isPaused: false }),
  [TOUR_SET_STEP]: (state, { payload }) => ({ ...state, stepIndex: payload }),
  [TOUR_SET_FLOWS]: (state, { payload }) => ({ ...state, flows: payload })
};

const initialState = {
  // whether or not the tour feature is active (todo: move to separate piece of redux store)
  featureActivated: false,

  // the index of the current step in the active tour
  stepIndex: 0,

  // a list of all the tours available to the application (already sorted by order)
  flows: [],

  // the `name` of the active flow (todo: switch to id)
  activeFlow: null,

  // whether or not the tour is paused (i.e., temporarily not visible to the user)
  isPaused: false
};

export default function tourReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
