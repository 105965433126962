import React from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import classNames from "classnames";

export const MarkdownRender = props => {
  return (
    <Markdown
      className={props.className}
      source={props.source}
      escapeHtml={props.escapeHtml}
      softBreak={props.softBreak}
      renderers={{
        link: linkProps => (
          <a href={linkProps.href} rel="noopener noreferrer" target="_blank">
            {linkProps.children}
          </a>
        ),
        code: codeProps => {
          const tagProps =
            codeProps.value &&
            codeProps.value.split(",").map(item => item.trim());
          if (tagProps && tagProps.length > 1 && tagProps[0] === "tag") {
            const tagClasses = classNames("label", "tag", {
              "tag--nnt": tagProps[1].includes("NNT"),
              "tag--nnh": tagProps[1].includes("NNH")
            });
            return <span className={tagClasses}>{tagProps[1]}</span>;
          } else {
            return <code>{codeProps.value}</code>;
          }
        }
      }}
    />
  );
};

MarkdownRender.defaultProps = {
  escapeHtml: true,
  className: "markdown-render",
  softBreak: "\n"
};

MarkdownRender.propTypes = {
  escapeHtml: PropTypes.bool,
  source: PropTypes.string,
  className: PropTypes.string,
  softBreak: PropTypes.string
};

export default MarkdownRender;
