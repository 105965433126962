import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { handleMcgSubmit } from "../mcgReducer";

export const McgSubmitBar = ({
  canSubmit,
  isCheckingStatus,
  closeComponent,
  handleMcgSubmit,
  guideline
}) => {
  const handleClick = e => {
    closeComponent();
    handleMcgSubmit({ guideline });
  };
  const submitBarClasses = classNames("mcg-submit-bar", {
    "mcg-submit-bar--can-submit": canSubmit,
    "mcg-submit-bar--can-not-submit": !canSubmit,
    "mcg-submit-bar--fetching": isCheckingStatus
  });
  const submitButtonClasses = classNames("mcg-submit-button", {
    "mcg-submit-button--can-submit": canSubmit,
    "mcg-submit-button--can-not-submit": !canSubmit
  });
  return (
    <div className={submitBarClasses}>
      <p>
        {(isCheckingStatus && "Checking if criteria has been met") ||
          (canSubmit ? "Criteria Met" : "Criteria Not Met")}
      </p>
      {!isCheckingStatus && (
        <button
          className={submitButtonClasses}
          onClick={handleClick}
          disabled={!canSubmit}
        >
          Submit
        </button>
      )}
    </div>
  );
};

McgSubmitBar.propTypes = {
  canSubmit: PropTypes.bool,
  isCheckingStatus: PropTypes.bool,
  closeComponent: PropTypes.func,
  handleMcgSubmit: PropTypes.func,
  guideline: PropTypes.object
};

const mapStateToProps = state => {
  return {
    canSubmit: state.mcg.canSubmit,
    isCheckingStatus: state.mcg.isCheckingStatus
  };
};

const mapDispatchToProps = {
  handleMcgSubmit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McgSubmitBar);
