import React, { Component } from "react";
import Protocol from "./Protocol";
import { logSentryError } from "helpers/logging/error";

export default class ProtocolErrorBoundary extends Component {
  state = { hasError: false };
  static propTypes = {};
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // also log error
    logSentryError(error, info);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="protocol-error">
          <h1 className="protocol-error_title">Something went wrong.</h1>
          <p className="protocol-error_text">
            We have hit a snag. Our team has been notified of the issue and
            working to fix. Please reload.
          </p>
        </div>
      );
    }
    return <Protocol {...this.props} />;
  }
}
