import {
  AUC_MET_SELECTION_ID,
  AUC_NOT_MET_SELECTION_ID,
  AUC_OTHER_INDICATION_SELECTION_IDS
} from "constants/appropriateUseCriteria";

export const selectCurrentProtocolId = state => state.session.activeProtocolId;

export const selectCurrentProtocol = state =>
  state.protocol[selectCurrentProtocolId(state)];

export const selectCurrentProtocolTitle = state =>
  state.protocol[selectCurrentProtocolId(state)].title;

export const selectSelection = (state, selectionId) =>
  state.session.entities.selections[selectionId];

export const selectUsages = state => state.session.entities.usages;

export const selectOutputs = state => state.session.entities.outputs;

export const selectCalculatorInputs = (state, calcId) =>
  state.session.entities.usages[calcId].inputs;

export const selectCalcInputTotal = (state, calcId) => {
  return (
    Object.values(selectCalculatorInputs(state, calcId)).reduce(
      (acc, input) => {
        if (input.selected) {
          acc += Number(input.value);
        }
        return acc;
      },
      0
    ) || 0
  );
};

export const selectAppropriateCareUsage = state => {
  const usages = selectUsages(state);
  const appropriateCareUsage =
    usages &&
    Object.values(usages).find(usage => {
      return (
        usage.calculator &&
        usage.calculator.title.includes("Appropriate Use Criteria")
      );
    });
  return appropriateCareUsage || null;
};

export const selectAUCMet = state => {
  const outputs = Object.values(selectOutputs(state));
  const otherIndicationOutput = outputs.find(x =>
    AUC_OTHER_INDICATION_SELECTION_IDS.includes(x.selectionId)
  );
  const aucNotMetOutput = outputs.find(
    x => x.selectionId === AUC_NOT_MET_SELECTION_ID
  );
  const aucMetOutput = outputs.find(
    x => x.selectionId === AUC_MET_SELECTION_ID
  );

  if (otherIndicationOutput && otherIndicationOutput.selected) {
    return null;
  } else if (aucNotMetOutput && aucNotMetOutput.selected) {
    return false;
  } else if (aucMetOutput && aucMetOutput.selected) {
    return true;
  }
  return null;
};

export const selectAUCApplies = state => {
  const outputs = selectOutputs(state);
  const output = Object.values(outputs).find(output =>
    AUC_OTHER_INDICATION_SELECTION_IDS.includes(output.selectionId)
  );

  if (output && output.selected) {
    return false;
  }
  return true;
};
