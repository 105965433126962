import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setCustomOutputItemSelectedState } from "store/session";
import dotProp from "dot-prop";
import classNames from "classnames";
import Checkbox from "components/ui/Checkbox";

/* eslint-disable max-len */
class DiagnosisItem extends Component {
  state = {
    isOpen: ""
  };
  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSelect = ({ target }) => {
    const {
      diagnosis,
      setCustomOutputItemSelectedState,
      customItemState
    } = this.props;
    const itemIsSelected = customItemState
      ? customItemState["isSelected"]
      : true;
    setCustomOutputItemSelectedState({
      outputId: "",
      outputItemId: diagnosis.id,
      isSelected: !itemIsSelected
    });
  };
  render() {
    const { render, diagnosis, customItemState } = this.props;
    const { isOpen } = this.state;
    const isSelected = customItemState ? customItemState["isSelected"] : true;
    const indicatorClasses = classNames(
      "patient-ed-diagnoses_accordion-container row align-middle collapse",
      {
        "patient-ed-diagnoses_accordion-container--selected": isSelected,
        "": !isSelected
      }
    );
    const iCD10Code =
      diagnosis.icd10_codeId !== null ? diagnosis.icd10_codeId : "";
    return (
      <div className={indicatorClasses}>
        <div className="patient-ed-diagnoses_accordion-header columns">
          <Checkbox isSelected={isSelected} onChange={this.handleSelect} />
          <span className="patient-ed-diagnoses_checkbox-label">
            {`${diagnosis.title} ${iCD10Code}`}
          </span>
        </div>
        <div className="patient-ed-diagnoses_accordion-actions columns shrink">
          <span
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.toggleAccordion();
            }}
            className="patient-ed_see-description"
          >
            {this.state.isOpen ? "Hide Description" : "See Description"}
          </span>
        </div>
        {isOpen && <Fragment>{render()}</Fragment>}
      </div>
    );
  }
}
const mapStateToProps = (state, { diagnosis }) => ({
  customItemState: dotProp.get(
    state,
    `session.customOutputItems.${diagnosis.id}`
  )
});

export default connect(
  mapStateToProps,
  { setCustomOutputItemSelectedState }
)(DiagnosisItem);

DiagnosisItem.propTypes = {
  render: PropTypes.func,
  diagnosis: PropTypes.object.isRequired,
  setCustomOutputItemSelectedState: PropTypes.func.isRequired,
  customItemState: PropTypes.object
};
