import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { createLogicMiddleware } from "redux-logic";
import localForage from "localforage";
import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";

import makeRootReducer from "store/reducers";
import arrLogic from "logic/logic";
import {
  launchAuth0Session,
  launchFHIRSession,
  onRehydrate
} from "store/auth/actions";

// ======================================================
// Middleware Configuration
// ======================================================

// array of logic items, no duplicate refs to same logic
const logicMiddleware = createLogicMiddleware(arrLogic);
const middleware = [thunk, logicMiddleware];

if (__DEV__) {
  middleware.push(
    createSentryMiddleware(Sentry, {
      stateTransformer: state => {
        const {
          tour,
          FHIR_orders,
          user,
          account,
          session,
          bootstrapContent
        } = state;

        return {
          tour,
          FHIR_orders,
          user,
          account,
          session,
          bootstrapContent
        };
      }
    })
  );
}

// ======================================================
// Store Enhancers
// ======================================================
const enhancers = [];

let composeEnhancers = compose;

if (__DEV__) {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === "function") {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const localForageReduxPersistStore = localForage.createInstance({
  name: "EvidenceCare",
  version: 1, // schema version
  storeName: "ec_storage"
});

// need to update the version everytime we change the structure of the store
const PERSIST_VERSION = 2;

// ======================================================
// Store Instantiation and HMR Setup
// ======================================================
const persistConfig = {
  version: PERSIST_VERSION,
  key: "root",
  whitelist: ["smallLists", "review", "auth", "user", "FHIR_orders"],
  debug: true,
  storage: localForageReduxPersistStore,

  // if not on the current version, delete all saved data
  migrate: async state => {
    return state && state._persist.version === PERSIST_VERSION ? state : {};
  }
};

export const store = createStore(
  persistReducer(persistConfig, makeRootReducer()),
  window.___INITIAL_STATE__,
  composeEnhancers(applyMiddleware(...middleware), ...enhancers)
);
store.asyncReducers = {};

export const bootstrappingFinishedPromise = new Promise(res => {
  // due to the way that the `persistStore` works and given that we want
  // to capture when it is done rehydrating in a promise, we have to use
  // the old export syntax
  module.exports.persistor = persistStore(store, null, () => {
    let rehydratePromise;

    // if this is set, that means we want to manually control the rehyradtion process
    // (primarily used for testing)
    if (window.ecInitializeStore) {
      const {
        // this should be the unchanged result object from authenticating via any of the Auth0 endpoints
        auth0Result,

        // this needs to be the SMART on FHIR result from the /token endpoint and the `state` key MUST be
        // denormalized (similar to how the `fhir-client` does it)
        smartOnFHIRResult
      } = window.ecInitializeStore;

      if (auth0Result) {
        rehydratePromise = store.dispatch(
          launchAuth0Session({ authResult: auth0Result })
        );
      } else if (smartOnFHIRResult) {
        rehydratePromise = store.dispatch(launchFHIRSession(smartOnFHIRResult));
      } else {
        rehydratePromise = Promise.resolve();
      }

      // otherwise, we always want to follow up the rehyrating process with
      // some authentication checks
    } else {
      rehydratePromise = store.dispatch(onRehydrate());
    }

    rehydratePromise.then(res);
  });
});

// To unsubscribe, invoke `store.unsubscribeHistory()` anytime
// !TODO! commented this out, need to investigate
// store.unsubscribeHistory = browserHistory.listen(updateLocation(store))
if (module.hot) {
  module.hot.accept(() => {
    const reducers = require("store/reducers").default;
    const nextRootReducer = reducers(store.asyncReducers);
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}
