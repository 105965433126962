import { loadTours } from "store/tour/actions";
import { loadSherpaModals, showSherpaModal } from "store/modal/actions";
import { reloadHelpCenterData } from "store/helpCenter/actions";
import { setSherpaDraftMode } from "store/admin/actions";

// All of these functions are meant to be launched using the browser console ONLY
// The return strings are the feedback messages

export const sherpa = store => {
  function setDraftStatus(draftStatus = true) {
    store.dispatch(setSherpaDraftMode(draftStatus));
    reload();

    return `Draft status changed to ${draftStatus}. Beginning reload:`;
  }

  function reload() {
    let timeout;
    let seconds = 0;
    const printLoad = () => {
      console.log(`Looading${".".repeat(seconds++)}`);
      timeout = setTimeout(printLoad, 1000);
    };
    timeout = setTimeout(printLoad, 500);

    Promise.all([
      store.dispatch(loadTours()),
      store.dispatch(loadSherpaModals()),
      store.dispatch(reloadHelpCenterData())
    ]).then(() => {
      console.log("Done!");
      clearTimeout(timeout);
    });

    return "Starting reload:";
  }

  function showModal(name) {
    store.dispatch(showSherpaModal(name));
    return `Launching ${name} modal...`;
  }

  return {
    setDraftStatus,
    reload,
    showModal
  };
};
