import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Tab from "./Tab";
import { OUTPUT_TAB, setOutputTab } from "store/session";
import { EVENT_FEATURE_ORDERS_VIEW } from "constants/broadcastEventTypes";
import {
  DISABLE_ORDERS_COLUMN,
  DISABLE_PATIENT_ED_COLUMN,
  DISABLE_EHR_COLUMN,
  DEFAULT_OPEN_TAB
} from "constants/fhir";
import { pipelineRequest } from "store/pipeline";
import { selectCDSMode } from "store/ui/selectors";
import { selectFeatureFlags } from "store/user/selectors";

export class MainNavTabs extends Component {
  componentDidMount() {
    if (DEFAULT_OPEN_TAB) {
      this.setTab(OUTPUT_TAB[DEFAULT_OPEN_TAB]);
    }
  }

  setTab = outputTabName => {
    if (outputTabName === OUTPUT_TAB.ORDERS) {
      this.props.pipelineRequest({
        action: EVENT_FEATURE_ORDERS_VIEW
      });
    }
    this.props.setOutputTab({ outputTabName });
  };

  render() {
    const { activeTab, userFeatures } = this.props;
    const disableOrdersColumn = userFeatures.includes(DISABLE_ORDERS_COLUMN);
    const disableEhrColumn = userFeatures.includes(DISABLE_EHR_COLUMN);
    const disablePatientEdColumn = userFeatures.includes(
      DISABLE_PATIENT_ED_COLUMN
    );
    return (
      <ul className="main-nav-tabs show-for-medium row collapse align-stretch">
        {
          <Tab
            label="Orders"
            className="orders"
            active={activeTab === OUTPUT_TAB.ORDERS}
            disabled={disableOrdersColumn}
            icon="icon-evidence"
            onClick={
              !disableOrdersColumn ? () => this.setTab(OUTPUT_TAB.ORDERS) : null
            }
          />
        }

        {
          <Tab
            label="Patient Education"
            className="patient-ed"
            active={activeTab === OUTPUT_TAB.PE}
            disabled={disablePatientEdColumn}
            icon="icon-patient-edu"
            onClick={
              !disablePatientEdColumn ? () => this.setTab(OUTPUT_TAB.PE) : null
            }
            isMiddleTab
          />
        }

        <Tab
          label="EHR Documentation"
          className="ehr"
          active={activeTab === OUTPUT_TAB.EHR}
          disabled={disableEhrColumn}
          icon="icon-ehr-doc"
          onClick={!disableEhrColumn ? () => this.setTab(OUTPUT_TAB.EHR) : null}
        />
      </ul>
    );
  }
}

MainNavTabs.propTypes = {
  setOutputTab: PropTypes.func,
  activeTab: PropTypes.string,
  userFeatures: PropTypes.array,
  pipelineRequest: PropTypes.func,
  fhirCDS: PropTypes.bool
};

const mapStateToProps = state => ({
  activeTab: state.session.activeOutputTab,
  fhirCDS: selectCDSMode(state),
  userFeatures: selectFeatureFlags(state)
});

const mapDispatchToProps = {
  setOutputTab,
  pipelineRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNavTabs);
