import React from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import { connect } from "react-redux";

import { pdfFullUrl } from "helpers/urls/cloudinary";
import { selectTerms } from "store/terms/selectors";
import { acceptUserTerms, loadUserTerms } from "store/terms/actions";

class Disclosure extends React.Component {
  state = {
    numPages: null
  };

  componentDidMount() {
    this.props.loadUserTerms();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { numPages } = this.state;
    const { terms, acceptUserTerms } = this.props;

    const [notAcceptedTerms = {}] = terms.filter(term => {
      return !term.accepted;
    });

    return (
      <div className="disclosure-agreement">
        <Document
          file={pdfFullUrl(notAcceptedTerms.cloudinary_id)}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
        <div className="disclosure-agreement_footer">
          <p>
            By clicking &apos;Submit&apos;, you agree that you have read and
            understand the CME disclosures listed above
          </p>
          <button
            className="disclosure-agreement_button btn"
            onClick={() => acceptUserTerms(notAcceptedTerms.id)}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

Disclosure.propTypes = {
  terms: PropTypes.array,
  acceptUserTerms: PropTypes.func,
  loadUserTerms: PropTypes.func
};

const mapStateToProps = state => ({
  terms: selectTerms(state)
});

const mapDispatchToProps = {
  acceptUserTerms,
  loadUserTerms
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Disclosure);
