import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Moment from "moment";
import R from "ramda";
import Sha1 from "jssha/src/sha1";
import queryString from "query-string";

import { pollForValidSubscription } from "store/bootstrap";
import { fetchSubscriptions, fetchSubscription } from "store/account";
import { EVENT_FEATURE_SUBSCRIPTIONS_VIEW } from "constants/broadcastEventTypes";
import ChangePlan from "./ChangePlan";
import { calculateMonthlyPrice, priceToDollars } from "helpers/subscriptions";
import {
  paypalSrc,
  visaSrc,
  mastercardSrc,
  discoverSrc,
  amexSrc
} from "constants/cloudinary";
import { pipelineRequest } from "store/pipeline";
import { selectBestMembership, selectUser } from "store/user/selectors";
import { loadAssociations } from "store/associations/actions";
import { selectAssociations } from "store/associations/selectors";

export class Subscriptions extends Component {
  state = {
    changePlan: false
  };

  componentDidMount() {
    const {
      subscription,
      associations,
      location,
      loadAssociations,
      fetchSubscription,
      pollForValidSubscription
    } = this.props;
    const queryParams = queryString.parse(location.search);

    // if no associations visible, try to load them (again)
    if (Object.keys(associations).length === 0) loadAssociations();

    // fetch subscription detail if returning from chargify
    // else only fetch on subscriptions if none exists
    if (!R.isEmpty(queryParams) && queryParams.id) {
      fetchSubscription(queryParams.id).then(() => {
        // need to poll for a successful subscription so that we know that the update has been made by the backend
        pollForValidSubscription();
      });
    } else if (!subscription) {
      this.props.fetchSubscriptions();
    }
    // can't rely on user.subscription_valid because it doesn't refresh on return from signing up on chargify
    if (
      subscription &&
      (subscription.state === "canceled" || subscription.product === "core")
    ) {
      this.changePlan();
    }
    this.props.pipelineRequest({
      action: EVENT_FEATURE_SUBSCRIPTIONS_VIEW
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.subscription !== nextProps.subscription &&
      (!nextProps.subscription ||
        (nextProps.subscription &&
          (nextProps.subscription.state === "canceled" ||
            nextProps.subscription.product === "core")))
    ) {
      this.changePlan();
    }
  }

  prepPrice = subscription => {
    const { product } = subscription;
    return calculateMonthlyPrice(
      priceToDollars(product.price_in_cents),
      product.interval,
      product.interval_unit
    );
  };

  getChargifyToken = subscription => {
    const shaObj = new Sha1("SHA-1", "TEXT");
    shaObj &&
      shaObj.update(
        `update_payment--${subscription.id}--${CHARGIFY_SITE_SHARED_KEY}`
      );
    const token = shaObj && shaObj.getHash("HEX").substring(0, 10);
    return token;
  };

  getPaypalLogo = () => {
    return <img src={paypalSrc} alt="PayPal Logo" />;
  };

  getCreditCardLogo = subscription => {
    const creditCardLogos = {
      visa: visaSrc,
      mastercard: mastercardSrc,
      discover: discoverSrc,
      amex: amexSrc
    };
    return (
      <img
        className="credit-card-logo"
        alt={subscription.credit_card.card_type}
        src={creditCardLogos[subscription.credit_card.card_type]}
      />
    );
  };

  changePlan = () => {
    this.setState({ changePlan: true });
  };

  renderCurrentSubscription = () => {
    const { subscription, membership } = this.props;
    const isFree = subscription && subscription.product === "core";
    const isCanceled = subscription && subscription.state === "canceled";
    const hasCoupon = membership && membership.discount_amount > 0;
    return (
      <div>
        <div className="subscription-row row align-middle">
          <div className="columns stretch">
            <h3 className="subscription-row_title">Coupon</h3>
            {hasCoupon
              ? `${membership.discount_amount}% from ${membership.name}`
              : "You don't have any active coupons."}
          </div>
          {!membership && (
            <div className="columns shrink">
              <Link to="/account/memberships" className="button button-gray">
                Join Membership
              </Link>
            </div>
          )}
        </div>
        {subscription && !isFree && (
          <div className="subscription-row row align-middle">
            <div className="columns stretch">
              <h3 className="subscription-row_title">Current Plan</h3>
              {isCanceled && (
                <span className="subscription-row_plan">CORE (Canceled)</span>
              )}
              {!isCanceled && (
                <span className="subscription-row_plan">
                  {subscription.product.name} (${this.prepPrice(subscription)}
                  /mo)
                </span>
              )}
            </div>
            <div className="columns shrink">
              <button className="button button-gray" onClick={this.changePlan}>
                Change Plan
              </button>
            </div>
          </div>
        )}
        {subscription && !isCanceled && !isFree && (
          <div className="subscription-row row align-middle">
            <div className="columns stretch">
              <h3 className="subscription-row_title">Terms</h3>
              Next billing date:{" "}
              {`${Moment(subscription.next_assessment_at).format(
                "MMM D, YYYY"
              )} (${Moment(subscription.next_assessment_at).fromNow(
                true
              )} left)`}
            </div>
          </div>
        )}
        {subscription && !isCanceled && !isFree && (
          <div className="subscription-row row align-middle">
            <div className="columns stretch">
              <h3 className="subscription-row_title">Payment Method</h3>
              {subscription.payment_type === "credit_card" &&
                this.getCreditCardLogo(subscription)}
              {subscription.payment_type === "paypal" && this.getPaypalLogo()}
            </div>
            <div className="columns shrink">
              <a
                href={`${CHARGIFY_DOMAIN}/update_payment/${
                  subscription.id
                }/${this.getChargifyToken(subscription)}`}
                className="button button-gray"
                rel="noopener noreferrer"
                target="_blank"
              >
                Update
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { membership, subscription } = this.props;
    return (
      <div className="account-col--expanded">
        <div className="subscription_current">
          <div className="account_header">
            <h2 className="account_title">Subscriptions</h2>
          </div>
          {this.renderCurrentSubscription()}
        </div>
        {this.state.changePlan && (
          <ChangePlan subscription={subscription} membership={membership} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: selectUser(state),
  subscription: state.account.subscription,
  membership: selectBestMembership(state),
  associations: selectAssociations(state)
});
const mapDispatchToProps = {
  fetchSubscriptions,
  fetchSubscription,
  loadAssociations,
  pollForValidSubscription,
  pipelineRequest
};

Subscriptions.propTypes = {
  subscription: PropTypes.object,
  user: PropTypes.object,
  fetchSubscriptions: PropTypes.func,
  fetchSubscription: PropTypes.func,
  membership: PropTypes.object,
  loadAssociations: PropTypes.func,
  location: PropTypes.object,
  pollForValidSubscription: PropTypes.func,
  pipelineRequest: PropTypes.func,
  associations: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptions);
