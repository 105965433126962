import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import classNames from "classnames";

/* eslint-disable max-len */
class PatientEdAccordion extends Component {
  state = {
    isOpen: true
  };
  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { title, render } = this.props;
    const { isOpen } = this.state;
    const indicatorClasses = classNames("patient-ed-accordion_indicator", {
      "triangle-right": !isOpen,
      "triangle-down": isOpen
    });

    return (
      <div className="patient-ed-accordion_container">
        <div
          onClick={this.toggleAccordion}
          className="patient-ed-accordion_header"
        >
          <div className={indicatorClasses} />
          <div className="patient-ed-accordion_title">{title}</div>
        </div>
        {isOpen && (
          <Fragment>
            {render({ toggleAccordion: this.toggleAccordion })}
          </Fragment>
        )}
      </div>
    );
  }
}

PatientEdAccordion.propTypes = {
  title: PropTypes.node,
  actions: PropTypes.node,
  render: PropTypes.func
};

export default PatientEdAccordion;
