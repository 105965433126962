import React from "react";
import PropTypes from "prop-types";

export default function CitationLink({ citation }) {
  return (
    <p className="item-table_citation">
      <a href={citation.url} rel="noopener noreferrer" target="_blank">
        {citation.displayName}
        <span className="icon-new-window" />
      </a>
    </p>
  );
}

CitationLink.propTypes = { citation: PropTypes.object };
