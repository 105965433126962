import React from "react";
import PropTypes from "prop-types";
import OutputItemSelectable from "./McgEhrOutputSelectable";
import { mcgPEText } from "./helpers";

class McgPatientEd extends React.Component {
  componentDidMount() {
    this.props.fetchMcg();
  }
  render() {
    const { mcgIndications, mcgText } = this.props;

    const { title = "", subtitle = "", copyright = "" } = mcgPEText(mcgText);
    return (
      <React.Fragment>
        <div className="patient-ed_treatment-course-container">
          <div className="patient-ed_category-title">{title}</div>
          <div className="patient-ed_category-subtitle">{subtitle}</div>
          {mcgIndications.indications.map(indication =>
            indication.text ? (
              <OutputItemSelectable
                key={indication.id}
                item={indication}
                itemText={indication.text}
              />
            ) : null
          )}
          <p className="mcg-copyright">{copyright}</p>
        </div>
      </React.Fragment>
    );
  }
}

McgPatientEd.propTypes = {
  shouldShowMcgEhr: PropTypes.bool,
  selectedGuideline: PropTypes.object,
  mcgIndications: PropTypes.object,
  mcgText: PropTypes.object,
  guidelineWithStatus: PropTypes.array,
  fetchMcg: PropTypes.func
};

export default McgPatientEd;
