import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import McgContentItems from "./McgContentItems";

export class McgDefinition extends Component {
  constructor(props) {
    super();
    this.state = {
      open: true
    };
  }
  renderNestedIndications = ({ item, definitionTitle }) => {
    const {
      handleCheckMcg,
      selectedIndicationIds,
      isCheckingStatus,
      indicationIdsBeingFetched,
      parentId,
      level
    } = this.props;
    return (
      <McgContentItems
        contentItems={item}
        level={level}
        isInDefinition
        handleCheckMcg={handleCheckMcg}
        selectedIndicationIds={selectedIndicationIds}
        isCheckingStatus={isCheckingStatus}
        indicationIdsBeingFetched={indicationIdsBeingFetched}
        isDefinitionOpen={this.state.open}
        definitionTitle={definitionTitle}
        parentId={parentId}
      />
    );
  };

  render() {
    const { item } = this.props;
    const nestedIndicationClasses = classnames("mcg-definition-content", {
      "mcg-definition-content--closed": !this.state.open
    });
    const definitionTitle = item.Definitions[0].Title;
    return (
      <div className={nestedIndicationClasses}>
        {this.renderNestedIndications({
          item: item.Definitions[0].ContentItems,
          definitionTitle
        })}
      </div>
    );
  }
}

McgDefinition.propTypes = {
  item: PropTypes.object,
  handleCheckMcg: PropTypes.func,
  selectedIndicationIds: PropTypes.array,
  isCheckingStatus: PropTypes.bool,
  indicationIdsBeingFetched: PropTypes.array,
  parentId: PropTypes.string,
  level: PropTypes.number
};

export default McgDefinition;
