import PropTypes from "prop-types";
import React, { Component } from "react";
import EvidenceFolder from "./EvidenceFolder";
import { Tooltip } from "components/ui/tooltip";

/* eslint-disable max-len */
class EvidenceSummary extends Component {
  render() {
    return (
      <div className="evidence-summary">
        <Tooltip
          className="evidence-summary_tooltip"
          place="bottom"
          id="evidence-summary-tooltip"
          message="Review the clinical recommendations based on supporting evidence."
        >
          <h5 className="evidence-summary_header">Evidence Summary</h5>
        </Tooltip>
        <div className="evidence-summary_wrapper">
          {this.props.folders.map(folder => (
            <EvidenceFolder
              key={folder.id}
              folder={folder}
              evidenceUsages={this.props.evidenceUsages}
            />
          ))}
        </div>
      </div>
    );
  }
}

EvidenceSummary.propTypes = {
  folders: PropTypes.array,
  evidenceUsages: PropTypes.array
};
export default EvidenceSummary;
