export const CUSTOM_DISCLOSURE_TEXT = {
  // ACG
  "ed63ff26-b777-4971-9d46-c363c03b5f23": {
    targetAudience:
      "Physicians, nurses, nurse practitioners, residents, medical students, physician assistants, and other health care providers engaged in the practice of gastroenterology.",
    learnerObjective:
      "Apply the latest advances in the diagnosis, treatment, clinical skills, and practice management innovation necessary for the practice of gastroenterology."
  }
};

export const DEFAULT_DISCLOSURE_TEXT = {
  targetAudience:
    "Emergency physicians, emergency nurses, nurse practitioners, residents, medical students, physician assistants, emergency department medical directors, hospital administrators, EMTs/paramedics, and other health care providers engaged in the practice of emergency medicine.",
  learnerObjective:
    "Apply the latest advances in the diagnosis, treatment, clinical skills, and practice management innovation necessary for the practice of emergency medicine."
};
