import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export class ContextMenu extends Component {
  state = {
    showMenu: false
  };
  toggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  };
  closeMenu = () => {
    // setTimeout needed, otherwise menu clicks won't count due to onBlur event
    setTimeout(() => this.setState({ showMenu: false }), 200);
  };
  render() {
    const menuClasses = classNames("context-menu", {
      [this.props.className]: this.props.className,
      "context-menu--active": this.state.showMenu
    });
    return (
      <div className={menuClasses}>
        <div
          className="context-menu_ellipsis icon-vertical-ellipsis"
          onClick={this.toggleMenu}
          onBlur={this.closeMenu}
          tabIndex="4"
        />
        {this.state.showMenu && (
          <ul className="context-menu_menu" onBlur={this.closeMenu}>
            {this.props.children}
          </ul>
        )}
      </div>
    );
  }
}

ContextMenu.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string
};

export default ContextMenu;
