import { makePipelineRequest } from "lib/ec/base";

export const sendPipelineRequest = ({
  message = {
    success: true
  },
  category = "user",
  action,
  user = {}
}) => {
  return makePipelineRequest({
    method: "POST",
    body: [
      {
        source: `tagalong-app.${category}.${action}`,
        message,
        user
      }
    ]
  });
};
