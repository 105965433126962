import { history } from "myHistory";

export const updateSessionParam = ({ sessionId, slug, protocolId }) => {
  const url = `/protocol/${slug}/${protocolId}/${sessionId}`;
  history.replace(url);
};

export const createLogoutRedirectRoute = () => {
  const returnTo = encodeURIComponent(`${APP_DOMAIN}/logout`);
  const qs = `returnTo=${returnTo}&client_id=${AUTH0_ID}`;
  return `https://${AUTH0_DOMAIN}/v2/logout?${qs}`;
};
