import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import Recommendation from "./Recommendation";
import CommentBubble from "components/protocol/comments/CommentBubble";

/* eslint-disable max-len */
class EvidenceFolder extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
      title: props.folder.title,
      subtitle: props.folder.subtitle,
      evidenceUsages: props.evidenceUsages.filter(evidenceUsage => {
        return evidenceUsage.folderId === props.folder.id;
      }),
      showEvidenceDrawer: false,
      evidenceToShow: {}
    };
    this.orderedEvidenceUsages =
      props.folder.evidenceUsagesOrdering &&
      props.folder.evidenceUsagesOrdering.map(id =>
        this.state.evidenceUsages.find(ev => ev.id === id)
      );
  }

  toggleEvidenceFolder = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  showEvidence = evidenceToShow => {
    this.setState({ showEvidenceDrawer: true, evidenceToShow });
  };

  closeEvidenceDrawer = () => {
    this.setState({ showEvidenceDrawer: false });
  };

  render() {
    const { folder } = this.props;
    const { isOpen } = this.state;
    const evidenceUsages =
      this.orderedEvidenceUsages || this.state.evidenceUsages;
    const accordionClasses = classNames("rec-group", {
      "rec-group--open": isOpen
    });
    return (
      <div className={accordionClasses}>
        <div className="row align-middle" onClick={this.toggleEvidenceFolder}>
          <div className="columns">
            <h5 className="rec-group_header">{folder.title}</h5>
          </div>
        </div>
        <CommentBubble
          style={{ top: "-10px", position: "relative", marginBottom: "4px" }}
          itemId={folder.id}
          usageId={folder.id}
          internalName={folder.title}
          locationDescription="Evidence Folder"
          condensed
        />
        {this.state.isOpen && (
          <div>
            <h6 className="rec-group_header-sub">{folder.subtitle}</h6>
            {evidenceUsages.map((usage, index) => (
              <Recommendation key={usage.id} evidenceUsage={usage} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

EvidenceFolder.propTypes = {
  folder: PropTypes.object,
  evidenceUsages: PropTypes.array
};
export default EvidenceFolder;
