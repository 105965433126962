import { SET_SHERPA_DRAFT_MODE } from "./constants";

// ------------------------------------
// Thunks
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

export function setSherpaDraftMode(draftMode = false) {
  return {
    type: SET_SHERPA_DRAFT_MODE,
    payload: draftMode
  };
}
