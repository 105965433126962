import React from "react";
import Proptypes from "prop-types";

import HelpCenterArticleLink from "./HelpCenterArticleLink";
import "./HelpCenterArticleGroup.scss";

export default function HelpCenterArticleGroup({ title, articles = [] }) {
  return (
    <>
      <div className="help-center-article-group_header-container">
        <h4>
          <b>{title}</b>
        </h4>
      </div>
      <div className="help-center-article-group_article-container">
        {articles.map(({ title, id }) => (
          <div key={id} className="help-center-article-group_article-wrapper">
            <HelpCenterArticleLink title={title} id={id} />
          </div>
        ))}
      </div>
    </>
  );
}

HelpCenterArticleGroup.propTypes = {
  articles: Proptypes.array,
  title: Proptypes.string
};
