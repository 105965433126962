import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FHIR_Prescription, Prescription } from "./Prescription";

export const ManagePrescription = ({
  prescription,
  prescriptionSetMode,
  singleMedMode,
  prescriptionIndex,
  isMapped,
  isSent,
  isDraft,
  isDraftReviewer,
  prescriptionSetMessage,
  sentAt,
  orderMessage
}) => {
  return (
    <Fragment>
      {__FHIR__ ? (
        <FHIR_Prescription
          prescription={prescription}
          prescriptionSetMode={prescriptionSetMode}
          singleMedMode={singleMedMode}
          prescriptionIndex={prescriptionIndex}
          isMapped={isMapped}
          isSent={isSent}
          isDraft={isDraft}
          isDraftReviewer={isDraftReviewer}
          prescriptionSetMessage={prescriptionSetMessage}
          sentAt={sentAt}
          orderMessage={orderMessage}
        />
      ) : (
        <Prescription
          prescription={prescription}
          prescriptionSetMode={prescriptionSetMode}
          singleMedMode={singleMedMode}
          prescriptionIndex={prescriptionIndex}
          prescriptionSetMessage={prescriptionSetMessage}
          orderMessage={orderMessage}
        />
      )}
    </Fragment>
  );
};

ManagePrescription.propTypes = {
  prescription: PropTypes.object,
  prescriptionSetMode: PropTypes.string,
  singleMedMode: PropTypes.bool,
  prescriptionIndex: PropTypes.number,
  isMapped: PropTypes.bool,
  isSent: PropTypes.bool,
  isDraft: PropTypes.bool,
  isDraftReviewer: PropTypes.bool,
  prescriptionSetMessage: PropTypes.string,
  sentAt: PropTypes.string,
  orderMessage: PropTypes.string
};

export default ManagePrescription;
