import PropTypes from "prop-types";
import React, { Component } from "react";
import dotProp from "dot-prop";
import { connect } from "react-redux";
import classNames from "classnames";
import { generateOptionSetHeaderText } from "helpers/parsing/prescription";
import RxSet from "./RxSet";
import { SortBy } from "components/orders/OrdersContainer";

export class RxGroup extends Component {
  state = {
    open: true
  };
  shouldShowSet = (criteria, attributes) => {
    if (criteria.exclusion.length) {
      const hasExclusionMatch = criteria.exclusion.some(exclusionAttributes => {
        return exclusionAttributes.every(attributeId =>
          this.props.attributes.includes(attributeId)
        );
      });
      if (hasExclusionMatch) return false;
    } else if (criteria.inclusion.length) {
      return criteria.inclusion.some(inclusionAttributes => {
        return inclusionAttributes.every(attributeId =>
          this.props.attributes.includes(attributeId)
        );
      });
    }
    return true;
  };

  render() {
    const {
      group,
      prescriptionsWithProfiles,
      rxSetId,
      rxSetItemState
    } = this.props;
    const groupClasses = classNames("prescription-set_group", {
      "prescription-set_group--open": this.state.open
    });

    return (
      <SortBy.Consumer>
        {sortBy => {
          // true if there are items, items have been selected, and selected tab is active
          // also true if items exists and 'Rcommended' tab is selected
          const showGroup =
            (rxSetItemState.some(item => item.isSelected) &&
              sortBy === "selected") ||
            sortBy !== "selected";
          const shouldNotShowTitle = sortBy === "selected" || sortBy === "sent";
          return showGroup ? (
            <div className={groupClasses}>
              {!shouldNotShowTitle && (
                <h6
                  className="prescription-set_group-header"
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  {group.name}
                </h6>
              )}
              {this.state.open && (
                <div className="prescription-set_option-sets">
                  {group.optionSets.map((optionSet, optionSetIndex) => {
                    const optionSetHeaderText = generateOptionSetHeaderText(
                      optionSetIndex,
                      optionSet
                    );
                    return (
                      <div
                        className="prescription-set_option-set"
                        key={optionSetIndex}
                      >
                        {sortBy !== "selected" &&
                          sortBy !== "sent" &&
                          optionSetHeaderText && (
                            <h6 className="prescription-set_option-set-header">
                              {optionSetHeaderText}
                            </h6>
                          )}
                        {optionSet.prescriptionSets.map(
                          (prescriptionSet, prescriptionSetIndex) => {
                            const shouldShowSet = prescriptionSet.criteria
                              ? this.shouldShowSet(
                                  prescriptionSet.criteria,
                                  this.props.attributes
                                )
                              : true;
                            return (
                              (shouldShowSet &&
                                prescriptionSet.prescriptions.length > 0 && (
                                  <RxSet
                                    prescriptionSet={prescriptionSet}
                                    prescriptionsWithProfiles={
                                      prescriptionsWithProfiles
                                    }
                                    prescriptionSetIndex={prescriptionSetIndex}
                                    groupId={group.id}
                                    internalName={this.props.internalName}
                                    optionSet={optionSet}
                                    optionSetIndex={optionSetIndex}
                                    key={prescriptionSetIndex}
                                    rxSetId={rxSetId}
                                    sortBy={sortBy}
                                  />
                                )) ||
                              null
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : null;
        }}
      </SortBy.Consumer>
    );
  }
}

RxGroup.propTypes = {
  group: PropTypes.object,
  attributes: PropTypes.array,
  prescriptionsWithProfiles: PropTypes.array,
  rxSetId: PropTypes.string,
  rxSetItemState: PropTypes.array,
  internalName: PropTypes.string
};
const mapStateToProps = (state, props) => ({
  rxSetItemState:
    dotProp.get(state, `session.rxSetItems.${props.rxSetId}`) || []
});
export default connect(mapStateToProps)(RxGroup);
