import { pick } from "ramda";

import { showModal, showSherpaModal } from "store/modal/actions";
import {
  fetchAccountProfileApi,
  fetchCanonicalProfileApi,
  updateProfileApi
} from "lib/ec/profile";
import { fetchUserAccountApi, updateAccountApi } from "lib/ec/account";
import {
  SET_USER_FHIR,
  PATCH_USER,
  CLEAR_USER,
  SET_HAS_VIEWED_NEW_USER_MODAL
} from "./constants";
import {
  selectIsUserInAssociation,
  selectOrganizationSlug,
  selectUserHasFeature
} from "store/user/selectors";

// ------------------------------------
// Thunks
// ------------------------------------

export const showNewUserModal = () => {
  return (dispatch, getState) => {
    const state = getState();
    console.log(state);
    if (selectUserHasFeature(state, { flag: "SHERPA_MODALS" })) {
      if (__FHIR__) {
        // Note: this is intentionally nested
        if (selectOrganizationSlug(state) === "medstar") {
          dispatch(showSherpaModal("newUserModalMedStar"));
        }
      } else if (selectIsUserInAssociation(state, { slug: "acepequal" })) {
        dispatch(showSherpaModal("newUserModalACEPEQUAL"));
      } else {
        dispatch(showSherpaModal("newUserModal"));
      }
    } else {
      dispatch(
        showModal("VIDEO", {
          title: "Welcome To EvidenceCare",
          content:
            "Watch the short video below to learn how to use the great EvidenceCare features.",
          video: "250107413"
        })
      );
    }
    dispatch(setHasViewedNewUserModal());
  };
};

// Patches the user profile data
export function updateUserProfile(patch) {
  return dispatch => {
    // todo investigated why these are on different endpoints and it appears that this is just for legacy reasons
    // we will need to unravel that when we have additional bandwidth
    // Note: the emails coming from the `/account` endpoints are the authentication emails while the emails
    // coming from the `/profile` endpoints are the contact emails
    return Promise.all([updateProfileApi(patch), updateAccountApi(patch)])
      .then(
        ([
          { data: d1 },
          {
            data: {
              email: auth_email,
              email_verified: auth_email_verified,
              ...d2
            }
          }
        ]) => {
          dispatch(
            patchUser({
              ...d1,
              ...d2,
              auth_email,
              auth_email_verified
            })
          );
        }
      )
      .catch(error => {
        console.log("Update user profile error: ", error);
      });
  };
}

// Loads all of the user profile data
export function loadUserProfile() {
  return (dispatch, getState) => {
    return Promise.all([
      fetchAccountProfileApi(),
      fetchUserAccountApi(),
      fetchCanonicalProfileApi()
    ])
      .then(
        ([
          { data: d1 },
          {
            data: {
              email: auth_email,
              email_verified: auth_email_verified,
              ...d2
            }
          },
          {
            data: { family_name, given_name }
          }
        ]) => {
          dispatch(
            patchUser({
              ...d1,
              ...d2,
              auth_email,
              auth_email_verified,
              ...{
                last_name: family_name,
                first_name: given_name
              }
            })
          );
        }
      )
      .catch(error => {
        console.log("Load user profile error: ", error);
      });
  };
}

// ------------------------------------
// Actions
// ------------------------------------

export function patchUser(patch = {}) {
  return {
    type: PATCH_USER,
    payload: extractPatchFields(patch)
  };
}

export const setUserFHIR = data => {
  return {
    type: SET_USER_FHIR,
    payload: data
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER
  };
};

export function setHasViewedNewUserModal(hasViewed = true) {
  return {
    type: SET_HAS_VIEWED_NEW_USER_MODAL,
    payload: hasViewed
  };
}

// ------------------------------------
// Utilities
// ------------------------------------

// Because we just throw data from the bootstrap payload (and other sources)
// directly at the redux store (this should eventually change); this at least
// provides a small decoupling between the backend APIs and the frontend data structures
const PATCH_USER_FIELDS = [
  "auth_email",
  "auth_email_verified",
  "email",
  "first_name",
  "last_name",
  "created_at",
  "associations",
  "permissions",
  "preferences",
  "cloudinary_id",
  "user_uid",
  "protocols",
  "referral_token",
  "email_verified",
  "subscription_valid",
  "chargify_customer_id",
  "provider_type",
  "cme_hours",
  "organization",
  "features",
  "alias",
  "content",
  "activity",
  "school",
  "workplaces",
  "specialty",
  "degree",
  "zipcode",
  "npi",
  "terms"
];
const extractPatchFields = data => pick(PATCH_USER_FIELDS, data);
