import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { clearActiveMoreInfo, setSideBarActive } from "store/protocol";
import { OUTPUT_TAB, setOutputTab } from "store/session";

export class BottomNav extends React.Component {
  setTab = outputTabName => {
    const {
      setOutputTab,
      setSideBarActive,
      activeMoreInfoId,
      clearActiveMoreInfo
    } = this.props;
    return () => {
      setOutputTab({ outputTabName });
      setSideBarActive({ activeState: true });
      activeMoreInfoId && clearActiveMoreInfo();
    };
  };
  closeSideBar = () => {
    this.props.setSideBarActive({ activeState: false });
  };
  createButton = ({ iconName, label, tabId, isActive }) => {
    const buttonClasses = classNames("bottom-nav_button", {
      "bottom-nav_button--active": isActive
    });
    return (
      <button className={buttonClasses} onClick={this.setTab(tabId)}>
        <i className={`icon-${iconName} bottom-nav_button-icon`} />{" "}
        <span className="bottom-nav_button-text">{label}</span>
      </button>
    );
  };
  render() {
    const { activeTab, sideBarIsActive } = this.props;
    const protocolButtonClasses = classNames("bottom-nav_button", {
      "bottom-nav_button--active": !sideBarIsActive
    });
    return (
      <div className="bottom-nav bottom-nav--protocol">
        <button className={protocolButtonClasses} onClick={this.closeSideBar}>
          <i className="icon-evidence bottom-nav_button-icon" />{" "}
          <span className="bottom-nav_button-text">Path</span>
        </button>

        {this.createButton({
          iconName: "evidence",
          label: "Orders",
          tabId: OUTPUT_TAB.ORDERS,
          isActive: sideBarIsActive && OUTPUT_TAB.ORDERS === activeTab
        })}
        {this.createButton({
          iconName: "patient-edu",
          label: "PE",
          tabId: OUTPUT_TAB.PE,
          isActive: sideBarIsActive && OUTPUT_TAB.PE === activeTab
        })}
        {this.createButton({
          iconName: "ehr-doc",
          label: "EHR",
          tabId: OUTPUT_TAB.EHR,
          isActive: sideBarIsActive && OUTPUT_TAB.EHR === activeTab
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeTab: state.session.activeOutputTab,
  sideBarIsActive: state.protocol.sideBarIsActive,
  activeMoreInfoId: state.protocol.activeMoreInfoId
});
const mapDispatchToProps = {
  setOutputTab,
  setSideBarActive,
  clearActiveMoreInfo
};
BottomNav.propTypes = {
  setOutputTab: PropTypes.func,
  activeTab: PropTypes.string,
  setSideBarActive: PropTypes.func,
  sideBarIsActive: PropTypes.bool,
  activeMoreInfoId: PropTypes.string,
  clearActiveMoreInfo: PropTypes.func
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomNav);
