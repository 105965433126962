import React from "react";

// for more info see styles/core.scss and https://connoratherton.com/loaders

export const LoaderLineSpin = () => {
  return (
    <div className="line-scale">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export const LoaderBallPulse = () => {
  return (
    <div className="loader-inner ball-pulse">
      <div />
      <div />
      <div />
    </div>
  );
};
