import React from "react";
import PropTypes from "prop-types";
import PrescriptionSetView from "./PrescriptionSetView";

class PrescriptionSetsView extends React.Component {
  render() {
    const { prescriptionSets } = this.props;
    return (
      <div>
        {prescriptionSets.map(prescriptionSet => (
          <PrescriptionSetView
            prescriptionSet={prescriptionSet}
            key={prescriptionSet.id}
          />
        ))}
      </div>
    );
  }
}

PrescriptionSetsView.propTypes = {
  prescriptionSets: PropTypes.array
};

export default PrescriptionSetsView;
