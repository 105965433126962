import {
  PROTOCOL_IMAGING_ADVISOR_CRITERIA_MET,
  PROTOCOL_HANDLE_WEB_MESSAGE_RESPONSE,
  setWebMessageOrderIds,
  storeOrderIds
} from "store/protocol";
import { createLogic } from "redux-logic";
import { batch } from "react-redux";
import { fhirFactory } from "lib/fhir/factory";
import { error, success } from "react-notification-system-redux";
import { history } from "myHistory";
import dotProp from "dot-prop";
import { pipelineRequest } from "store/pipeline";
import {
  EVENT_IA_SEND_CONSULTATION_CODE,
  EVENT_IA_ERROR,
  EVENT_IA_CONFIRMED_CONSULTATION_CODE
} from "constants/broadcastEventTypes";
import { selectAUCApplies, selectAUCMet } from "store/protocol/selectors";
import { generateConsultationCode } from "lib/ec/imaging-advisor";
import { setCdsAuc, setAucProp } from "store/patient";
import { logSentryError } from "helpers/logging/error";

const launchImagingAdvisorCriteriaMetLogic = createLogic({
  type: PROTOCOL_IMAGING_ADVISOR_CRITERIA_MET,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);
    const appContext = dotProp.get(state, "patient.appContext", {});
    const ordersFromResponse = dotProp.get(
      state,
      "patient.cdsOrdersResponse",
      {}
    );
    const AUCApplicable = selectAUCApplies(state);
    const AUCMet = selectAUCMet(state);
    const patient = dotProp.get(state, "patient", {});
    const practitioner = fhirModule.getPractitioner(
      dotProp.get(state, "user.FHIR_Practitioner", {})
    );

    const AUCCriteria = AUCApplicable
      ? AUCMet
        ? "appropriate"
        : "not-appropriate"
      : "no-criteria-apply";

    try {
      const consultationCodeRes = await generateConsultationCode({
        provider: practitioner.name,
        npi: practitioner.npi,
        dob: patient.birthDate,
        gender: patient.gender,
        AUCCriteria
      });
      const consultationCode = consultationCodeRes.data.id;
      if (consultationCode) {
        const messageType = "scratchpad.update";
        const messages = fhirModule.buildWebMessages({
          appContext,
          ordersFromResponse,
          messageType,
          consultationCode,
          AUCCriteria
        });
        batch(() => {
          dispatch(
            setAucProp({
              payload: { prop: "consultationCode", value: consultationCode }
            })
          );
          dispatch(
            setAucProp({ payload: { prop: "AUCCriteria", value: AUCCriteria } })
          );
        });
        if (messages.length > 0) {
          // if we reach this point, then we won't call history.push since receive webMessage should handle that when a response comes back
          // also, we may need some type of timeout logic here in the event that we don't receive a response
          // maybe set a timeout function to be called here, pass the timeout id (returned from setTimeout) to receiveWebMessage and cancel it on
          // the callback. so if the timeout period is reached, that means receiveWebMessage never actually received a response

          const webMessageIds = messages.map(m => m.messageId);
          dispatch(setWebMessageOrderIds({ payload: webMessageIds }));
          // send multiple messages
          messages.forEach(message => {
            fhirModule.sendWebMessage({ messageData: message });
            batch(() => {
              dispatch(setCdsAuc(message));
              dispatch(
                pipelineRequest({
                  action: EVENT_IA_SEND_CONSULTATION_CODE,
                  message: message
                })
              );
            });
          });
        } else {
          throw new Error({
            errorMessage: "Unable to build consultation code message",
            displayMessage:
              "An error occured building the imaging criteria. Please contact support if the issue persists."
          });
        }
      } else {
        throw new Error({
          errorMessage: "Unable to generate consultation code",
          displayMessage:
            "An error occured generating the imaging criteria consultation code. Please contact support if the issue persists."
        });
      }
    } catch (ex) {
      logSentryError(ex);
      const { displayMessage, errorMessage } = ex;
      console.log("exception while processing AUC criteria: ", ex);
      dispatch(
        error({
          message:
            displayMessage ||
            "An unknown error occured while assessing criteria",
          autoDismiss: 3
        })
      );
      //splash screen after failed confirmation
      history.push({
        pathname: "/imaging-advisor/web-message-sent/false"
      });
    } finally {
      done();
    }
  }
});

const launchHandleWebMessageResponseLogic = createLogic({
  type: PROTOCOL_HANDLE_WEB_MESSAGE_RESPONSE,
  async process({ getState, action }, dispatch, done) {
    try {
      const state = getState();
      const event = action.payload;

      const isMessageSuccessful = event.data.payload.success;
      console.log("webMessage isMessageSuccessful :", isMessageSuccessful);
      if (isMessageSuccessful) {
        console.log("EC window received message and processed successfully");
        pipelineRequest({
          action: EVENT_IA_CONFIRMED_CONSULTATION_CODE,
          message: event.data
        });
      } else {
        console.log(
          `EC window received AUC message and NOT processed successfully ${event.data.payload.details}`
        );
      }
      history.push({
        pathname: `/imaging-advisor/web-message-sent/${isMessageSuccessful}`
      });
    } catch (ex) {
      console.log("exception while handling web message response", ex);
      logSentryError(ex);
      const { displayMessage, errorMessage } = ex;

      dispatch(
        error({
          message:
            displayMessage ||
            "An unknown error occured while submitting criteria information",
          autoDismiss: 3
        })
      );
      pipelineRequest({ action: EVENT_IA_ERROR, message: errorMessage || ex });
      history.push({
        pathname: `/imaging-advisor/web-message-sent/${isMessageSuccessful}`
      });
    } finally {
      done();
    }
  }
});

export default [
  launchImagingAdvisorCriteriaMetLogic,
  launchHandleWebMessageResponseLogic
];
