import { makeEcCdsRequest, makeEcRequest } from "./base";
import { pipelineRequest } from "store/pipeline";
import { EVENT_IA_GENERATE_CONSULTATION_CODE } from "constants/broadcastEventTypes";

//
// Consultation Code
//

export const generateConsultationCode = ({
  provider,
  npi,
  dob,
  gender,
  AUCCriteria
}) => {
  pipelineRequest({
    action: EVENT_IA_GENERATE_CONSULTATION_CODE,
    message: { provider, npi, dob, gender, AUCCriteria }
  });

  try {
    return makeEcRequest({
      method: "POST",
      location: "auc/consultation",
      body: {
        provider_name: provider,
        provider_npi: npi,
        patient_dob: dob,
        patient_gender: gender,
        auc_criteria: AUCCriteria
      }
    });
  } catch (error) {
    console.error(error);
  }
};

//
// App Context Data
//
export const fetchAppContext = ({ id }) => {
  try {
    return makeEcCdsRequest({ method: "GET", location: "event/" + id });
  } catch (error) {
    return {};
  }
};
