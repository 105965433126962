import * as Sentry from "@sentry/browser";

/*
  logSentryServerError has already been added to every axios call
  such as makeEcRequest, makePdfRequest, makeProxyEcRequest, etc
  This should only ever be added to new axios promises
*/
export const logSentryServerError = err => {
  if (err && err.response) {
    const serverResponseMessage = JSON.stringify(err.response.data);
    if (err.response.status === "400") {
      Sentry.captureException(err.response, {
        extra: { serverResponseMessage },
        fingerprint: [serverResponseMessage]
      });
    } else {
      Sentry.captureException(err, {
        extra: { serverResponseMessage }
      });
    }
  } else {
    Sentry.captureException(err);
  }
};

/*
  use this anywhere besides catches on axios promises
  note that info can be any non falsy value
*/
export const logSentryError = (err, info) => {
  const documentMode = document.documentMode || null;
  const extra = {};
  if (info) extra.info = JSON.stringify(info);
  if (documentMode) extra.documentMode = JSON.stringify(documentMode);
  Sentry.captureException(err, { extra });
};
