import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import classNames from "classnames";

// endpoints and helpers
import { loadCloudinaryWidgetScript } from "lib/externalscripts";
import AccountInfo from "./AccountInfo";
import AccountMenu from "./AccountMenu";
import {
  selectFormattedUserName,
  selectUserAvatarURL
} from "store/user/selectors";

// nested route components
import Profile from "components/account/Profile";
import Membership from "./Membership";
import Subscriptions from "./Subscriptions";
import CME from "./CME/CMEComponent";

class Account extends Component {
  state = {
    showSideMenu: false
  };

  componentDidMount() {
    loadCloudinaryWidgetScript();
  }

  render() {
    const { avatarURL, name } = this.props;
    const { showSideMenu } = this.state;

    const accountMenuClass = classNames("columns shrink account-info-col", {
      "account-info-col--active": showSideMenu
    });

    const qsaClass = classNames("protocol-top-bar_qsa-toggle hide-for-large", {
      "icon-show-nav": !showSideMenu,
      "icon-hide-nav": showSideMenu
    });

    return (
      <>
        <div className="row collapse expanded">
          <div className={accountMenuClass}>
            <div className="account-info">
              <h6 className="account-info_header">User Profile</h6>
              <AccountInfo name={name} avatarURL={avatarURL} />
              <AccountMenu
                toggleSideMenu={() => {
                  this.setState({ showSideMenu: !showSideMenu });
                }}
              />
            </div>
          </div>
          <div className="columns account-col-wrapper">
            <div className="protocol-top-bar">
              <div
                className={qsaClass}
                onClick={() => this.setState({ showSideMenu: !showSideMenu })}
              />
              <Link
                to="/"
                className="protocol-top-bar_home protocol-top-bar_home--account icon-home"
              />
            </div>
            <div>
              <Route path="/account/profile" component={Profile} />
              <Route path="/account/memberships" component={Membership} />
              <Route path="/account/subscriptions" component={Subscriptions} />
              <Route path="/account/cme" component={CME} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

Account.propTypes = {
  avatarURL: PropTypes.string,
  name: PropTypes.string
};

const mapStateToProps = state => ({
  name: selectFormattedUserName(state),
  avatarURL: selectUserAvatarURL(state)
});

export default connect(mapStateToProps)(Account);
