import { fetchAdmissionContextApi } from "lib/ec/plugin";
import { fetchGuidelineStatusApi, fetchCitationApi } from "./helpers";
import {
  setMcgIndicationSession,
  updateMcgGuidelineSession,
  setMcgGuidelineSession,
  setMcgSubmitSession,
  setSelected
} from "store/session";
import * as immutable from "object-path-immutable";
import R from "ramda";
import { getMcgGuidelines } from "./mcgSelectors";

export const SHOW_MCG_GUIDELINES = "SHOW_MCG_GUIDELINES";
export const HIDE_MCG_GUIDELINES = "HIDE_MCG_GUIDELINES";
export const CLEAR_MCG_GUIDELINES = "CLEAR_MCG_GUIDELINES";
export const SET_GUIDELINE_INFORMATION = "SET_GUIDELINE_INFORMATION";
export const SELECT_MCG_INDICATIONS = "SELECT_MCG_INDICATIONS";
export const REMOVE_MCG_INDICATION = "REMOVE_MCG_INDICATION";
export const CLEAR_SELECTED_INDICATIONS = "CLEAR_SELECTED_INDICATIONS";
export const ADD_INDICATION_TO_LOADING_QUEUE =
  "ADD_INDICATION_TO_LOADING_QUEUE";
export const CLEAR_INDICATION_IDS_BEING_FETCHED =
  "CLEAR_INDICATION_IDS_BEING_FETCHED";
export const SET_INDICATION_TO_OPEN = "SET_INDICATION_TO_OPEN";
export const SET_INDICATION_TO_CLOSED = "SET_INDICATION_TO_CLOSED";
export const SET_CURRENT_ACTIVE_CITATION = "SET_CURRENT_ACTIVE_CITATION";
export const UPDATE_ACTIVE_CITATIONS = "UPDATE_ACTIVE_CITATIONS";
export const CLOSE_ALL_INDICATIONS = "CLOSE_ALL_INDICATIONS";
export const OPEN_META_DATA = "OPEN_META_DATA";
export const SET_FETCHING_META_STATUS = "SET_FETCHING_META_STATUS";
export const CLEAR_META_DATA = "CLEAR_META_DATA";
export const SET_MCG_CONTEXT = "SET_MCG_CONTEXT";
export const FETCH_MCG_GUIDELINE = "FETCH_MCG_GUIDELINE";
export const FETCH_MCG_GUIDELINE_STATUS = "FETCH_MCG_GUIDELINE_STATUS";
export const SET_MCG_CAN_SUBMIT = "SET_MCG_CAN_SUBMIT";
export const SET_MCG_SUBMIT = "SET_MCG_SUBMIT";

export const showMcgGuidelines = () => {
  return {
    type: SHOW_MCG_GUIDELINES
  };
};

export const hideMcgGuidelines = () => {
  return {
    type: HIDE_MCG_GUIDELINES
  };
};
export const clearMcgGuidelines = () => {
  return {
    type: CLEAR_MCG_GUIDELINES
  };
};

export const setGuidlineWithStatus = ({
  guideline = [],
  doesGuidelineExist,
  isCheckingStatus = false,
  note,
  isFetchingGuideline = false
}) => {
  return {
    type: SET_GUIDELINE_INFORMATION,
    payload: {
      guideline,
      doesGuidelineExist,
      isCheckingStatus,
      isFetchingGuideline,
      note
    }
  };
};

export const setMcgSelectionIndications = ({ indicationIds }) => {
  return {
    type: SELECT_MCG_INDICATIONS,
    payload: { indicationIds }
  };
};

export const unselectIndication = ({ indicationId }) => {
  return {
    type: REMOVE_MCG_INDICATION,
    payload: { indicationId }
  };
};

export const clearSelectedIndications = () => {
  return {
    type: CLEAR_SELECTED_INDICATIONS
  };
};

export const addToFetchingQueue = ({ parentIndicationId }) => {
  return {
    type: ADD_INDICATION_TO_LOADING_QUEUE,
    payload: { parentIndicationId }
  };
};

export const clearIdsBeingFetched = () => {
  return {
    type: CLEAR_INDICATION_IDS_BEING_FETCHED
  };
};

export const closeAllIndications = () => {
  return {
    type: CLOSE_ALL_INDICATIONS
  };
};

export const setIndicationToOpen = ({ indicationId }) => {
  return {
    type: SET_INDICATION_TO_OPEN,
    payload: { indicationId }
  };
};

export const setIndicationToClosed = ({ indicationId }) => {
  return {
    type: SET_INDICATION_TO_CLOSED,
    payload: { indicationId }
  };
};

export const setActiveCitation = ({ currentActiveCitation }) => {
  return {
    type: SET_CURRENT_ACTIVE_CITATION,
    payload: { currentActiveCitation }
  };
};

export const updateActiveCitations = ({ activeCitations }) => {
  return {
    type: UPDATE_ACTIVE_CITATIONS,
    payload: { activeCitations }
  };
};

export const openMetaData = ({ metaData }) => {
  return (dispatch, getState) => {
    const isActiveMeta = getState().mcg.activeMetaData.find(
      activeMeta => activeMeta.Id === metaData.Id
    );
    if (!isActiveMeta) {
      dispatch({
        type: OPEN_META_DATA,
        payload: { metaData }
      });
    }
  };
};

export const setFetchingMetaDataStatus = ({ isFetching }) => {
  return {
    type: SET_FETCHING_META_STATUS,
    payload: {
      isFetching
    }
  };
};

export const clearMetaData = () => {
  return {
    type: CLEAR_META_DATA
  };
};

export const fetchGuideline = () => {
  return {
    type: FETCH_MCG_GUIDELINE
  };
};

export const fetchGuidelineStatus = () => {
  return {
    type: FETCH_MCG_GUIDELINE_STATUS
  };
};

export const fetchMcgGuidelineStatus = ({ hsim, indicationIds, edition }) => {
  return async (dispatch, getState) => {
    dispatch(fetchGuidelineStatus());
    dispatch(setMcgSelectionIndications({ indicationIds }));
    dispatch(
      setMcgIndicationSession({
        hsim,
        selections: indicationIds
      })
    );
    const state = getState();
    fetchGuidelineStatusApi({
      hsim,
      edition,
      indicationIds,
      token: state.user.token
    })
      .then(response => {
        const updatedGuidelineWithStatus = response.data.Content;
        const note = updatedGuidelineWithStatus.filter(content =>
          content.Text.includes("Note:")
        )[0];
        const noteText = note && note.Text;
        dispatch(
          setGuidlineWithStatus({
            guideline: updatedGuidelineWithStatus,
            doesGuidelineExist: true,
            isCheckingStatus: false,
            note: noteText || ""
          })
        );
        const canSubmit = updatedGuidelineWithStatus[0].Status === "Met";
        dispatch(
          updateMcgGuidelineSession({
            hsim,
            isMet: canSubmit
          })
        );
        dispatch(setCanSubmit(canSubmit));
        // if indicationIds are passed in that means the guideline is loaded and indications are being clicked
        if (indicationIds) {
          dispatch(clearIdsBeingFetched());
        }
      })
      .catch(e =>
        dispatch(
          setGuidlineWithStatus({
            doesGuidelineExist: false
          })
        )
      );
  };
};

export const fetchMcgGuideline = ({ hsim }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.user.token;
    dispatch(fetchGuideline());
    const edition = await getEdition(dispatch, state, hsim);
    dispatch(setMcgGuidelineSession({ hsim, edition }));
    fetchGuidelineStatusApi({
      hsim,
      edition,
      token
    })
      .then(response => {
        const updatedGuidelineWithStatus = response.data.Content;
        const note = updatedGuidelineWithStatus.filter(guideline => {
          return guideline.Text.includes("Note:");
        })[0];
        const noteText = note && note.Text;
        dispatch(
          setGuidlineWithStatus({
            guideline: updatedGuidelineWithStatus,
            doesGuidelineExist: true,
            isFetchingGuideline: false,
            note: noteText || ""
          })
        );
        const canSubmit = updatedGuidelineWithStatus[0].Status === "Met";
        dispatch(
          updateMcgGuidelineSession({
            hsim,
            isMet: canSubmit
          })
        );
        dispatch(setCanSubmit(canSubmit));
      })
      .catch(e =>
        dispatch(
          setGuidlineWithStatus({
            doesGuidelineExist: false,
            guideline: [],
            isFetchingGuideline: false
          })
        )
      );
  };
};

const getEdition = async (dispatch, state, hsim) => {
  if (state.mcg.context[hsim] && state.mcg.context[hsim].edition) {
    return state.mcg.context[hsim].edition;
  }
  try {
    const context = await dispatch(fetchAdmissionContext());
    return context[hsim].edition;
  } catch (e) {
    console.log(e);
  }
};

export const fetchAdmissionContext = () => {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const token = state.user.token;
      const activeProtocol = state.protocol[state.session.activeProtocolId];
      const isAdmissionProtocol =
        activeProtocol && activeProtocol.type === "admission";
      const protocolConfig =
        state.plugins.protocolConfigs[state.session.activeProtocolId];

      const hsims = isAdmissionProtocol
        ? [activeProtocol.context.hsim]
        : protocolConfig
        ? protocolConfig["MCG"].config.hsims
        : [];
      if (hsims.length) {
        fetchAdmissionContextApi({ token, hsims: JSON.stringify(hsims) })
          .then(({ data }) => {
            if (data.results) {
              const context = data.results.reduce((acc, result) => {
                return {
                  ...acc,
                  [result.data.context.hsim]: {
                    title: result.data.title,
                    edition: result.data.context.edition,
                    contentOwner: result.data.context.contentOwner,
                    code: result.data.context.code,
                    product: result.data.context.product
                  }
                };
              }, {});
              dispatch({
                type: SET_MCG_CONTEXT,
                payload: { context }
              });
              resolve(context);
            }
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      } else {
        reject(new Error("No hsims from config"));
      }
    });
};

export const setCanSubmit = canSubmit => {
  return {
    type: SET_MCG_CAN_SUBMIT,
    payload: { canSubmit }
  };
};

export const handleMcgSubmit = ({ guideline }) => {
  return (dispatch, getState) => {
    // dispatch to session to store that the guideline has been submitted
    // also dispatches to session that the output selection has been made
    const guidelines = getMcgGuidelines(getState());
    const selectionToSelect = guidelines.find(g => g.hsim === guideline.hsim)
      .selectionId;
    dispatch(
      setMcgSubmitSession({
        hsim: guideline.hsim,
        selectionId: selectionToSelect
      })
    );
    const outputToSelect = R.values(getState().session.entities.outputs).find(
      output => {
        return output.selectionId === selectionToSelect;
      }
    );

    if (outputToSelect) {
      dispatch(setSelected({ outputId: outputToSelect.id }));
    }
    dispatch(clearMetaData());
    dispatch({
      type: SET_MCG_SUBMIT,
      payload: true
    });
  };
};

export const fetchCitation = ({ citation, citationType }) => {
  return (dispatch, getState) => {
    dispatch(setFetchingMetaDataStatus({ isFetching: true }));
    fetchCitationApi({ citation, token: getState().user.token }).then(
      axiosResult => {
        dispatch(openMetaData({ metaData: axiosResult.data }));
        dispatch(setFetchingMetaDataStatus({ isFetching: false }));
      }
    );
  };
};

const ACTION_HANDLERS = {
  [SHOW_MCG_GUIDELINES]: (state, action) => {
    return immutable.set(state, `showMcgGuidelines`, true);
  },
  [HIDE_MCG_GUIDELINES]: (state, action) => {
    return immutable.set(state, `showMcgGuidelines`, false);
  },
  [SET_GUIDELINE_INFORMATION]: (state, action) => {
    const {
      guideline,
      doesGuidelineExist,
      note,
      isCheckingStatus,
      isFetchingGuideline
    } = action.payload;
    return Object.assign({}, state, {
      guidelineWithStatus: guideline,
      doesGuidelineExist,
      note,
      isCheckingStatus,
      isFetchingGuideline
    });
  },
  [SELECT_MCG_INDICATIONS]: (state, action) => {
    const { indicationIds } = action.payload;
    return immutable.set(state, `selectedIndicationIds`, indicationIds);
  },
  [REMOVE_MCG_INDICATION]: (state, action) => {
    const { indicationId } = action.payload;
    return immutable.set(
      state,
      `selectedIndicationIds`,
      state.selectedIndicationIds.filter(
        selectedIndicationId => selectedIndicationId !== indicationId
      )
    );
  },
  [CLEAR_SELECTED_INDICATIONS]: (state, action) => {
    return immutable.set(state, `selectedIndicationIds`, []);
  },
  [ADD_INDICATION_TO_LOADING_QUEUE]: (state, action) => {
    const { parentIndicationId } = action.payload;
    return immutable.push(
      state,
      `indicationIdsBeingFetched`,
      parentIndicationId
    );
  },
  [CLEAR_INDICATION_IDS_BEING_FETCHED]: (state, action) => {
    return immutable.set(state, `indicationIdsBeingFetched`, []);
  },
  [SET_INDICATION_TO_OPEN]: (state, action) => {
    const { indicationId } = action.payload;
    return immutable.push(state, `openIndications`, indicationId);
  },
  [SET_INDICATION_TO_CLOSED]: (state, action) => {
    const { indicationId } = action.payload;
    return immutable.set(
      state,
      `openIndications`,
      state.openIndications.filter(
        openIndicationId => openIndicationId !== indicationId
      )
    );
  },
  [SET_CURRENT_ACTIVE_CITATION]: (state, action) => {
    const { currentActiveCitation } = action.payload;
    return immutable.set(state, `currentActiveCitation`, currentActiveCitation);
  },
  [UPDATE_ACTIVE_CITATIONS]: (state, action) => {
    const { activeCitations } = action.payload;
    return immutable.set(state, `activeCitations`, activeCitations);
  },
  [CLOSE_ALL_INDICATIONS]: (state, action) => {
    return immutable.set(state, `openIndications`, []);
  },
  [OPEN_META_DATA]: (state, action) => {
    const { metaData } = action.payload;
    return immutable.insert(state, `activeMetaData`, metaData, 0);
  },
  [SET_FETCHING_META_STATUS]: (state, action) => {
    const { isFetching } = action.payload;
    return immutable.set(state, `isFetchingMeta`, isFetching);
  },
  [CLEAR_META_DATA]: (state, action) => {
    return immutable.set(state, `activeMetaData`, []);
  },
  [SET_MCG_CONTEXT]: (state, action) => {
    return immutable.set(state, `context`, action.payload.context);
  },
  [FETCH_MCG_GUIDELINE]: (state, action) => {
    return immutable.set(state, `isFetchingGuideline`, true);
  },
  [FETCH_MCG_GUIDELINE_STATUS]: (state, action) => {
    return immutable.set(state, `isCheckingStatus`, true);
  },
  [SET_MCG_CAN_SUBMIT]: (state, action) => {
    return immutable.set(state, `canSubmit`, action.payload.canSubmit);
  },
  [SET_MCG_SUBMIT]: (state, action) => {
    return immutable.set(state, `hasSubmitted`, action.payload);
  },
  [CLEAR_MCG_GUIDELINES]: state => {
    return Object.assign({}, state, initialState);
  }
};

const initialState = {
  showMcgGuidelines: false,
  guidelineWithStatus: [],
  selectedIndicationIds: [],
  indicationIdsBeingFetched: [],
  openIndications: [],
  currentActiveCitation: {},
  activeMetaData: [],
  isFetchingGuideline: false,
  isFetchingMeta: false,
  isCheckingStatus: false,
  doesGuidelineExist: false,
  note: "",
  hasSubmitted: false,
  context: {}
};

export default function mcgReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
