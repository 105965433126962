import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import DisplayItem from "components/protocol/protocolRightCol/moreInfo/moreInfoItems/DisplayItem";
import groupBy from "lodash/groupBy";
import { propSatisfies, isEmpty, isNil, uniq } from "ramda";
import Moment from "moment";
import { ecCouponSrc } from "constants/cloudinary";
import Medication from "../patientView/Element/Medication";
import MarkdownRender from "utility/markdownRender";
import { yearsFromDate, formattedDate } from "helpers/parsing/dates";
import { formatPhoneNumber } from "helpers/util";
import { PE_CATEGORY_TITLES } from "constants/protocol";
import {
  base64PELogo,
  base64EcCoupon,
  base64MedStarLogo
} from "constants/fhir";
import classNames from "classnames";
import McgPatientEdPrint from "integrations/mcg/McgPatientEdPrint";

class PatientEdPrintMarkup extends Component {
  constructor({ activeSelectedOutputObjs, account, userName }) {
    super();
    this.state = {
      isInBlackAndWhiteMode: false
    };
    this.patientEducationGroupedByCategory = this.groupByCategoryId(
      activeSelectedOutputObjs["patientEducation"]
    );
    this.showAccount = account && userName;
  }

  groupByCategoryId = patientEds => groupBy(patientEds, "categoryId");

  categoryItemTypes = category => category.map(category => category.itemType);

  toggleBlackAndWhiteMode = () => () => {
    this.setState({ isInBlackAndWhiteMode: !this.state.isInBlackAndWhiteMode });
  };

  patientEdSections = () => {
    const {
      activeSelectedOutputObjs,
      rxSets = {},
      appointment,
      shouldShowMcg,
      mcgIndications,
      mcgText
    } = this.props;
    return {
      diagnoses: {
        // often times, there are duplicate diagnosis strings. 'uniq' from Ramda filters out any duplicates if they exist
        data: uniq(activeSelectedOutputObjs["diagnoses"]),
        markup: data => (
          <ul className="patient-ed-print_diagnoses-container">
            {data.map(diagnosis => (
              <li key={diagnosis.id}>
                <strong>{diagnosis.title}: </strong>
                <span>{diagnosis.patientDescription}</span>
              </li>
            ))}
          </ul>
        )
      },
      followup: {
        data: appointment && propSatisfies(x => x, "followUpDate", appointment),
        markup: appointment => (
          <div className="row collapse">
            <div className="columns shrink patient-ed-print_category-column">
              {appointment.followUpDate && (
                <div>
                  Appointment Date:{" "}
                  <strong>
                    {Moment(
                      `${appointment.followUpDate} ${appointment.followUpTime}`
                    ).format("dddd, MMMM D, YYYY h:mm a")}
                  </strong>
                </div>
              )}
            </div>
            <div className="columns patient-ed-print_category-column">
              <p>
                Healthcare Provider: <strong>{appointment.provider}</strong>
              </p>
              <p>
                Specialty of Medicine:{" "}
                <strong>{appointment.followUpSpecialty}</strong>
              </p>
            </div>
          </div>
        )
      },
      reasonsToReturn: {
        data:
          this.patientEducationGroupedByCategory[
            PE_CATEGORY_TITLES.reasonsToReturn.id
          ] &&
          this.patientEducationGroupedByCategory[
            PE_CATEGORY_TITLES.reasonsToReturn.id
          ].length > 0
            ? this.patientEducationGroupedByCategory[
                PE_CATEGORY_TITLES.reasonsToReturn.id
              ]
            : undefined,
        markup: data =>
          data.map(item => (
            <MarkdownRender
              key={item.id}
              className="patient-ed-print_category-markdown patient-ed-print_reasons-to-return"
              source={item.patientEdItemText || item.text}
              escapeHtml
            />
          ))
      },
      summaryOfCare: {
        data: Object.keys(this.patientEducationGroupedByCategory).reduce(
          (acc, catId) => {
            if (
              catId !== PE_CATEGORY_TITLES.atHome.id &&
              catId !== PE_CATEGORY_TITLES.reasonsToReturn.id
            ) {
              return [...acc, catId];
            } else {
              return [...acc];
            }
          },
          []
        ),
        markup: data =>
          data.map(peCategory => {
            const itemTypes = this.categoryItemTypes(
              this.patientEducationGroupedByCategory[peCategory]
            );
            const needsColumns =
              itemTypes.includes("graph") || itemTypes.includes("media");
            const peItems = this.patientEducationGroupedByCategory[peCategory];
            const categoryTitle = peItems[0].categoryTitle;
            return peItems.length === 1 ? (
              <p className="patient-ed-print_single-item">
                <strong>{categoryTitle}</strong>:
                <span>
                  {" "}
                  {peItems.map(item => item.patientEdItemText || item.text)}
                </span>
              </p>
            ) : (
              <Fragment>
                <strong>{categoryTitle}:</strong>
                <div className="row">
                  {peItems.map(item => (
                    <div
                      className={`columns ${
                        needsColumns ? "small-6" : "small-12"
                      }`}
                      key={item.id}
                    >
                      <DisplayItem
                        isInBlackAndWhiteMode={this.state.isInBlackAndWhiteMode}
                        item={{
                          ...item,
                          citations: [],
                          text: item.patientEdItemText || item.text
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Fragment>
            );
          }),
        extraData: mcgIndications,
        extra: shouldShowMcg
          ? () => (
              <McgPatientEdPrint
                mcgIndications={mcgIndications}
                mcgText={mcgText}
              />
            )
          : null
      },
      // these need their own section -- not included in Summary of Care
      atHome: {
        data:
          this.patientEducationGroupedByCategory[
            PE_CATEGORY_TITLES.atHome.id
          ] &&
          this.patientEducationGroupedByCategory[PE_CATEGORY_TITLES.atHome.id]
            .length > 0
            ? this.patientEducationGroupedByCategory[
                PE_CATEGORY_TITLES.atHome.id
              ]
            : undefined,
        markup: data =>
          data.map(item => (
            <MarkdownRender
              key={item.id}
              className="patient-ed-print_category-markdown"
              source={item.patientEdItemText || item.text}
              escapeHtml
            />
          ))
      },
      faqs: {
        // using 'uniq' from Ramda prevents any FAQ's from showing more than once
        data: uniq(activeSelectedOutputObjs["faqs"]),
        markup: data =>
          data.map(faq => (
            <div key={faq.id} className="patient-ed-print_faq-container">
              <h5 className="patient-ed-print_question">{faq.question}</h5>
              <MarkdownRender
                className="patient-ed-print_answer"
                source={faq.answer}
                escapeHtml
              />
            </div>
          ))
      },
      resources: {
        data: activeSelectedOutputObjs["resources"],
        markup: data =>
          data.map(resource => (
            <div key={resource.id} className="patient-ed-print_faq-container">
              <h5 className="patient-ed-print_resource-title">
                <strong>{resource.title}</strong>
              </h5>
              <div className="patient-ed-print_resource-url">
                {resource.url}
              </div>
            </div>
          ))
      },
      medications: {
        data: Object.keys(rxSets).reduce((acc, rxSetId) => {
          const selectedRxSet = rxSets[rxSetId].filter(
            rxSet => rxSet.isSelected
          );
          return acc.concat(selectedRxSet);
        }, []),
        markup: data =>
          data.map(med => {
            const rxSet = activeSelectedOutputObjs["prescriptionSets"].find(
              rxSet => rxSet.id === med.rxSetId
            );
            return rxSet ? (
              <div className="row collapse" key={med.id}>
                <div className="columns">
                  <Medication item={rxSet} itemState={med} />
                </div>
                <div className="columns shrink">
                  <img
                    src={!__FHIR__ ? ecCouponSrc : base64EcCoupon}
                    alt="goodrx coupon"
                  />
                </div>
              </div>
            ) : null;
          })
      }
    };
  };

  render() {
    const {
      togglePrintModal,
      providerNote,
      patient,
      facilityLocation,
      isEhrDocument,
      userName
    } = this.props;

    const { isInBlackAndWhiteMode } = this.state;
    const providerNoteClasses = classNames("patient-ed-print_header", {
      "patient-ed-print_header--no-note": !providerNote
    });

    const logoHeaderClasses = classNames(
      "patient-ed-print_organization-logo-header",
      {
        "patient-ed-print_organization-logo-header--no-facility":
          !isEhrDocument && !facilityLocation
      }
    );

    const patientInfoClasses = classNames(
      "patient-ed-print_patient-info columns",
      {
        "patient-ed-print_patient-info--black-and-white": isInBlackAndWhiteMode
      }
    );
    const patientInfoNoteClasses = classNames(
      "patient-ed-print_patient-info-note",
      {
        "patient-ed-print_patient-info-note--black-and-white": isInBlackAndWhiteMode
      }
    );
    const organizationLogoImage =
      facilityLocation && facilityLocation.cloudinary_id;

    return (
      <div className="patient-ed-print_container">
        <div className="patient-ed-print_header">
          <div className="row collapse shrink hide-for-print">
            {togglePrintModal && (
              <Fragment>
                <button
                  className="patient-ed_print-page-back-button hide-for-print"
                  onClick={togglePrintModal}
                >
                  Go back
                </button>
                <button
                  className="patient-ed_print-page-print-button hide-for-print"
                  onClick={() => window.print()}
                >
                  Print
                </button>
                <button
                  className="patient-ed_print-page-black-white-print-button hide-for-print"
                  onClick={this.toggleBlackAndWhiteMode()}
                >
                  {`${
                    this.state.isInBlackAndWhiteMode
                      ? "Go to Color"
                      : "Go to Black & White"
                  }`}
                </button>
              </Fragment>
            )}
          </div>
          <div className={logoHeaderClasses}>
            {isEhrDocument && (
              <Fragment>
                <EvidenceCareLogo
                  isInBlackAndWhiteMode={isInBlackAndWhiteMode}
                  isEhrDocument={isEhrDocument}
                />
                <br />
                {organizationLogoImage && (
                  <OrganizationLogo
                    isInBlackAndWhiteMode={isInBlackAndWhiteMode}
                    organizationLogo={organizationLogoImage}
                    isEhrDocument={isEhrDocument}
                  />
                )}
                {facilityLocation && (
                  <FacilityInfo facilityLocation={facilityLocation} />
                )}
              </Fragment>
            )}
            {!isEhrDocument && (
              <Fragment>
                <div className="patient-ed-print_facility-header-container">
                  <div className="patient-ed-print_facility-info-container">
                    {__FHIR__ && facilityLocation && (
                      <OrganizationLogo
                        isInBlackAndWhiteMode={isInBlackAndWhiteMode}
                        organizationLogo={organizationLogoImage}
                        isEhrDocument={isEhrDocument}
                      />
                    )}
                    {facilityLocation && (
                      <FacilityInfo facilityLocation={facilityLocation} />
                    )}
                  </div>
                  <div className="patient-ed-print_evidence-care-container-with-facility">
                    <EvidenceCareLogo
                      isInBlackAndWhiteMode={isInBlackAndWhiteMode}
                      isEhrDocument={isEhrDocument}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className="row patient-ed-print_patient-info-container">
          {__FHIR__ && patient && (
            <div className={patientInfoClasses}>
              <div className="patient-ed-print_header">
                <div className="patient-ed-print_provider-header">
                  Patient: {patient.gender && <span>({patient.gender})</span>}
                </div>
                <h5 className="patient-ed-print_provider-name">
                  {patient.name}
                </h5>
              </div>
              <div className="row patient-ed-print_personal-info collapse">
                <div className="columns shrink">
                  {patient.birthDate && (
                    <div>
                      Age:{" "}
                      <strong>{yearsFromDate(patient.birthDate)} old</strong>
                    </div>
                  )}
                  {patient.mrn && (
                    <div>
                      MR#: <strong>{patient.mrn}</strong>
                    </div>
                  )}
                </div>
                <div className="columns">
                  <div>
                    Date of Birth:{" "}
                    <strong>
                      {formattedDate(patient.birthDate, "MM/DD/YYYY")}
                    </strong>
                  </div>
                  <div>
                    Date of Service:{" "}
                    <strong>{Moment().format("MM/DD/YYYY")}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="patient-ed-print_patient-info-container">
            {this.showAccount && (
              <div className={patientInfoClasses}>
                <div className={providerNoteClasses}>
                  <div className="patient-ed-print_provider-header">
                    Your care team leader:
                  </div>
                  <h5 className="patient-ed-print_provider-name">{userName}</h5>
                </div>
              </div>
            )}
            {providerNote && (
              <div className={patientInfoNoteClasses}>
                <div className="patient-ed-print_provider-note">
                  Provider Comment: {providerNote}
                </div>
              </div>
            )}
          </div>
        </div>
        {Object.keys(this.patientEdSections()).map(section => {
          const thisSection = this.patientEdSections()[section];
          return (!isNil(thisSection.data) && !isEmpty(thisSection.data)) ||
            (!isNil(thisSection.extraData) &&
              !isEmpty(thisSection.extraData)) ? (
            <PatientEdSection
              isInBlackAndWhiteMode={isInBlackAndWhiteMode}
              title={PE_CATEGORY_TITLES[section].title}
              subtitle={PE_CATEGORY_TITLES[section].subtitle}
            >
              {thisSection.markup(thisSection.data)}
              {thisSection.extra && thisSection.extra()}
            </PatientEdSection>
          ) : null;
        })}
      </div>
    );
  }
}

export default PatientEdPrintMarkup;

PatientEdPrintMarkup.propTypes = {
  activeSelectedOutputObjs: PropTypes.object.isRequired,
  togglePrintModal: PropTypes.func,
  providerNote: PropTypes.string,
  appointment: PropTypes.object,
  rxSets: PropTypes.object,
  account: PropTypes.object,
  patient: PropTypes.object,
  facilityLocation: PropTypes.object,
  isEhrDocument: PropTypes.bool,
  organizationLogo: PropTypes.string,
  userName: PropTypes.string,
  shouldShowMcg: PropTypes.bool,
  mcgIndications: PropTypes.object,
  mcgText: PropTypes.object
};

const PatientEdSection = ({
  title,
  subtitle,
  children,
  className,
  isInBlackAndWhiteMode
}) => (
  <div className={`patient-ed-print_category-section ${className}`}>
    <HeaderRow
      isInBlackAndWhiteMode={isInBlackAndWhiteMode}
      title={title}
      subtitle={subtitle}
    />
    <div className="patient-ed-print_category-body">{children}</div>
  </div>
);

PatientEdSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isInBlackAndWhiteMode: PropTypes.bool
};

const HeaderRow = ({ title, subtitle, isInBlackAndWhiteMode }) => {
  const titleClasses = classNames("patient-ed-print_title columns shrink", {
    "patient-ed-print_title--black-and-white": isInBlackAndWhiteMode
  });
  const headerRowClasses = classNames(
    "patient-ed-print_header-row row collapse align-middle",
    {
      "patient-ed-print_header-row--black-and-white": isInBlackAndWhiteMode
    }
  );

  return (
    <div className={headerRowClasses}>
      <h3 className={titleClasses}>{title}</h3>
      <h4 className="patient-ed-print_subtitle columns">
        <span>{subtitle}</span>
      </h4>
    </div>
  );
};

HeaderRow.propTypes = {
  title: PropTypes.string,
  isInBlackAndWhiteMode: PropTypes.bool,
  subtitle: PropTypes.string
};

const FacilityInfo = ({ facilityLocation }) => {
  return (
    <div className="patient-ed-print_print_facility-container">
      <div className="patient-ed-print_facility-location-text">
        {facilityLocation.full_name}
      </div>
      <div className="patient-ed-print_facility-location-text">
        {facilityLocation.street}
      </div>
      {facilityLocation.city && (
        <div className="patient-ed-print_facility-location-text">{`${facilityLocation.city}, ${facilityLocation.state} ${facilityLocation.zipcode}`}</div>
      )}
      <div className="patient-ed-print_facility-location-text">
        {formatPhoneNumber({
          phoneNumberString: facilityLocation.phone_number
        })}
      </div>
    </div>
  );
};

FacilityInfo.propTypes = {
  facilityLocation: PropTypes.object
};

const EvidenceCareLogo = ({ isInBlackAndWhiteMode, isEhrDocument }) => {
  return (
    <div className="patient-ed-print_organization-logo">
      <img
        className="patient-ed-print_ec-logo"
        src={
          __FHIR__ && isEhrDocument
            ? base64PELogo
            : `//${CLOUDINARY_HOST}/evidencecare/image/upload/${
                isInBlackAndWhiteMode ? "e_grayscale/" : ""
              }v1561656194/tagalong-site-assets/ec-powered-by.png`
        }
        alt="EvidenceCare"
      />
    </div>
  );
};

EvidenceCareLogo.propTypes = {
  isInBlackAndWhiteMode: PropTypes.bool,
  isEhrDocument: PropTypes.bool
};

// TODO HIGH: need to associate base64MedStarLogo with the medstar facility/org
const OrganizationLogo = ({
  organizationLogo,
  isInBlackAndWhiteMode,
  isEhrDocument
}) => {
  const orgLogoString = `//${CLOUDINARY_HOST}/evidencecare/image/upload/${
    isInBlackAndWhiteMode ? "e_grayscale/" : ""
  }${organizationLogo}`;

  return organizationLogo ? (
    <img
      className="patient-ed-print_organization-logo"
      src={__FHIR__ && isEhrDocument ? base64MedStarLogo : orgLogoString}
    />
  ) : null;
};

OrganizationLogo.propTypes = {
  organizationLogo: PropTypes.string,
  isInBlackAndWhiteMode: PropTypes.bool,
  isEhrDocument: PropTypes.bool
};
