import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Drawer from "./Drawer";
import { setEncounter } from "store/patient";

export class PatientDrawer extends Component {
  constructor(props) {
    super();
    this.state = {
      showPatientDrawer: Boolean(props.patientDrawerIsVisible)
    };
  }

  togglePatientDrawer = () => {
    this.setState(state => ({ showPatientDrawer: !state.showPatientDrawer }));
  };
  render() {
    const patientDrawerClasses = classNames("patient-drawer_wrapper", {
      "patient-drawer_wrapper--active": this.state.showPatientDrawer
    });
    const patientDrawerToggleClasses = classNames(
      "protocol-top-bar_patient-name",
      {
        "protocol-top-bar_patient-name--open": this.state.showPatientDrawer
      }
    );
    return (
      <div className="protocol-top-bar_patient row align-middle collapse">
        <div className="columns shrink">
          <div className="icon-profile protocol-top-bar_patient-icon" />
        </div>
        <div className="columns">
          <div
            className={patientDrawerToggleClasses}
            onClick={this.togglePatientDrawer}
          >
            {this.props.patient.name}
          </div>
        </div>
        {this.state.showPatientDrawer && (
          <div
            className="patient-drawer_close icon-close columns"
            onClick={this.togglePatientDrawer}
          />
        )}
        <div className={patientDrawerClasses}>
          <Drawer
            onClose={this.togglePatientDrawer}
            patient={this.props.patient}
          />
        </div>
      </div>
    );
  }
}

PatientDrawer.propTypes = {
  patient: PropTypes.object,
  patientDrawerIsVisible: PropTypes.bool
};

const mapStateToProps = state => ({
  patient: state.patient,
  patientDrawerIsVisible: state.patient.patientDrawer.visible
});

const mapDispatchToProps = {
  setEncounter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientDrawer);
