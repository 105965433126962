import { fetchModals } from "lib/ec/sherpa";
import { selectSherpaModalProps } from "./selectors";
import { SHOW_MODAL, HIDE_MODAL, SET_SHERPA_MODALS } from "./constants";
import { selectSherpaDraftMode } from "store/admin/selectors";
import * as Sentry from "@sentry/browser";

// ------------------------------------
// Thunks
// ------------------------------------

// load modal data from an external source
export function loadSherpaModals() {
  return (dispatch, getState) => {
    return fetchModals(selectSherpaDraftMode(getState()))
      .then(modals => {
        dispatch(setSherpaModalGallery(modals));
      })
      .catch(err => {
        Sentry.captureException(err);
        console.log(err);
        return dispatch(setSherpaModalGallery([]));
      });
  };
}

// activate one of the backend controlled modals
export function showSherpaModal(name) {
  return (dispatch, getState) => {
    const modalProps = selectSherpaModalProps(getState(), { name });
    if (!modalProps) {
      console.error(new Error(`Sherpa modal ${name} does not exist!`));
      return;
    }
    dispatch(showModal("SHERPA", modalProps));
  };
}

// ------------------------------------
// Actions
// ------------------------------------
export function showModal(modalType, modalProps = {}) {
  return {
    type: SHOW_MODAL,
    payload: { modalType, modalProps }
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL
  };
}

export function setSherpaModalGallery(modals) {
  // index the modals by name
  const gallery = modals.reduce((acc, cur) => {
    acc[cur.name] = cur;
    return acc;
  }, {});

  return {
    type: SET_SHERPA_MODALS,
    payload: gallery
  };
}
