import React from "react";
import { connect } from "react-redux";
import dotProp from "dot-prop";
import PropTypes from "prop-types";
import MoreInfoIcon from "components/protocol/sections/moreInfo/MoreInfoIcon";
import {
  toggleOutputSelected,
  deselectUsageSelections,
  setSelected,
  setUnselected
} from "store/session";
import classNames from "classnames";
import { EVENT_CAREUSAGE_CLICK } from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";

const makeIcon = usage => {
  const { appropriateness } = usage;
  if (appropriateness === 1 || appropriateness === 2) {
    return "icon-thumbs-up care-folder_thumbs-up-icon";
  } else if (appropriateness === 0) {
    return "icon-neutral-circle care-folder_neutral-circle-icon";
  } else {
    return "icon-thumbs-down care-folder_thumbs-down-icon";
  }
};
export class CareUsage extends React.Component {
  constructor(props) {
    super();
    this.iconClasses = makeIcon(props.usage);
  }

  toggleOutput = () => {
    const {
      folder,
      outputIsSelected,
      deselectUsageSelections,
      usage,
      pipelineRequest
    } = this.props;

    /*
     * DataPipeline event for selecting/unselecting a care usage.  Note that both
     * actions (select and unselect) will use the same event key, however we can
     * disambiguate between the two by looking at the `selected` property.
     */
    pipelineRequest({
      action: EVENT_CAREUSAGE_CLICK,
      message: {
        selected: !outputIsSelected,
        careusage: {
          id: usage.id
        },
        selection: {
          id: usage.selection.id,
          displayName: usage.selection.displayName
        }
      }
    });

    if (!folder.selectMultiple && !outputIsSelected) {
      deselectUsageSelections({ folderId: folder.id, usageId: usage.id });
    }
    const setFunc = outputIsSelected
      ? this.props.setUnselected
      : this.props.setSelected;
    usage.output &&
      setFunc({
        outputId: usage.output.id,
        canSelectMultiple: folder.selectMultiple
      });
    // usage.output && toggleOutputSelected({ outputId: usage.output.id, folderId: folder.id })
  };

  isDisabled = () => {
    const { parentIsSelected } = this.props;
    return this.props.usage.criteria.length === 0 ? false : !parentIsSelected;
  };

  render() {
    const isDisabled = this.isDisabled();
    const { usage, outputIsSelected, folder } = this.props;
    const selectionClasses = classNames(
      "care-folder_button",
      "care-folder_category-selection",
      {
        "care-folder_button--selected": outputIsSelected,
        "care-folder_category-selection--disabled": isDisabled,
        "care-folder_button--toggle":
          !folder.selectMultiple && folder.usages.length > 1
      }
    );
    const evidenceObject =
      (usage.output && usage.output.evidence) || usage.evidence;
    return (
      <div
        className={selectionClasses}
        onClick={!isDisabled ? this.toggleOutput : () => {}}
        data-flow-selection-id={usage.selection.id}
      >
        <div className="care-folder_category-selected-content">
          <span className={`care-category_icon ${this.iconClasses}`} />
          {usage.selection.displayName}
        </div>
        {evidenceObject && (
          <div className="care-folder_more-info-container">
            <MoreInfoIcon
              evidence={evidenceObject}
              usageId={this.props.usage.id}
            />
          </div>
        )}
      </div>
    );
  }
}
CareUsage.propTypes = {
  setUnselected: PropTypes.func,
  setSelected: PropTypes.func,
  folder: PropTypes.object,
  outputIsSelected: PropTypes.bool,
  deselectUsageSelections: PropTypes.func,
  usage: PropTypes.object,
  parentIsSelected: PropTypes.bool,
  pipelineRequest: PropTypes.func
};
const mapStateToProps = (state, props) => {
  const parentIsSelected = props.usage.criteria.some(criteria =>
    props.selectedSelectionIds.includes(criteria.selectionId)
  );
  return {
    outputIsSelected:
      props.usage.output &&
      dotProp.get(
        state,
        `session.entities.outputs.${props.usage.output.id}.selected`
      ),
    selections:
      props.usage.output &&
      dotProp.get(
        state,
        `session.entities.selections.${props.usage.output.id}.selected`
      ),
    parentIsSelected
  };
};

const mapDispatchToProps = {
  toggleOutputSelected,
  deselectUsageSelections,
  setUnselected,
  setSelected,
  pipelineRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareUsage);
