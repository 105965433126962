import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import EhrAccordion from "./EhrAccordion";
import withPersonalNoteCouponAndFollowUp from "components/patientEd/withPersonalNoteCouponAndFollowUp";
import FollowUpApptForm from "../patientEd/FollowUpApptForm";
import PatientEdDiagnoses from "components/patientEd/PatientEdDiagnoses";
import isEmpty from "lodash/isEmpty";
import EhrTreatmentCourse from "./EhrTreatmentCourse";
import EhrEvidenceSummary from "./EhrEvidenceSummary";
import EhrSupportingStatement from "./EhrSupportingStatement";
// import CopyEhrToClipboard from "./CopyEhrToClipboard";
import { EHR_CATEGORY_IDS } from "constants/protocol";
import { Tooltip } from "components/ui/tooltip";
import SendToEhrButton from "./SendToEhrButton";
import { renderFollowUpIcon } from "components/patientEd/patientEdHelpers";
import ProviderNoteForm from "components/patientEd/ProviderNoteForm";
import R from "ramda";
import {
  FHIR_FLAGS_HAS_CD_POST,
  FHIR_WARN_ON_DUPLICATE_SUBMIT
} from "constants/fhir";
import {
  EVENT_FEATURE_EHR_VIEW,
  EVENT_FEATURE_EHR_SEND_TO_EHR
} from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";
import dotProp from "dot-prop";
import { success, error } from "react-notification-system-redux";
import {
  makeGetFullActiveSelectedCalcInputs,
  getSelectedEhrCustomState
} from "selectors/outputSelectors";
import { fhirFactory } from "lib/fhir/factory";
import { b64EncodeUnicode } from "helpers/ehr/fhir";
import { getConfigByOrgSlug } from "helpers/config";
import { makeEhrHtmlString } from "helpers/ehr/ehr";
import McgEhrDocumentation from "integrations/mcg/McgEhrDocumentation";
import { setLastSentClinicalDoc } from "store/session";
import { formattedDate } from "helpers/parsing/dates";
import createHash from "create-hash";

const sortFilterAndGroupByCategory = ({ ehrs, ehrOrdering, type }) => {
  return ehrOrdering.length > -1
    ? ehrOrdering.reduce((acc, key) => {
        const filteredEhrs = ehrs.filter(ehr => {
          return (
            ehr.categoryId === key &&
            (ehr.category && ehr.category.categoryType === type)
          );
        });
        const ehrItems = filteredEhrs.map(ehr => R.omit(["category"], ehr));
        if (filteredEhrs.length > 0) {
          acc[key] = {
            id: key,
            title: filteredEhrs[0].category.title,
            subtitle: filteredEhrs[0].category.subtitle,
            ehrItems,
            categoryType: filteredEhrs[0].category.categoryType
          };
        }
        return acc;
      }, {})
    : {};
};

class EhrView extends React.Component {
  componentDidMount() {
    this.props.pipelineRequest({
      action: EVENT_FEATURE_EHR_VIEW
    });
  }

  submitSuccess = data => {
    this.props.success({
      uid: "send-ehr-success",
      message: "Sent to EHR",
      position: "tr"
    });
  };

  submitError = (err, data) => {
    this.props.error({
      uid: "send-ehr-error",
      message: "Error Sending to EHR",
      position: "tr"
    });
    const log = {
      err,
      data: {
        ...data,
        resource: {
          ...data.resource,
          content: ""
        },
        data: "",
        auth: ""
      }
    };
    console.log(log);
  };

  sendToEhr = () => {
    const fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);
    const {
      selectedEhrs,
      selectedDiagnoses,
      selectedCalcs,
      selectedReasons,
      providerNote,
      slug,
      pipelineRequest,
      patient,
      user
    } = this.props;
    pipelineRequest({
      action: EVENT_FEATURE_EHR_SEND_TO_EHR
    });
    const ehrOptions = getConfigByOrgSlug(slug);
    const ehrString = fhirModule.makeEhrHtmlString({
      ehrs: selectedEhrs,
      diagnoses: selectedDiagnoses,
      calcs: selectedCalcs,
      providerNote,
      reasons: selectedReasons
    });
    const wrappedHtml = fhirModule.wrapEhrHtmlString(ehrString);
    const encodedHTML = b64EncodeUnicode(wrappedHtml);
    const hashedHTML = createHash("sha256")
      .update(encodedHTML, "utf-8")
      .digest("hex");
    const resource = fhirModule.buildDocumentReference({
      user: user,
      patient,
      encodedHTML,
      description: ehrOptions.ehr_description,
      loinc: ehrOptions.ehr_loinc,
      patientIdentifiers: this.props.patient.identifiers
    });
    const entry = {
      resource
    };

    if (
      user.features.includes(FHIR_WARN_ON_DUPLICATE_SUBMIT) &&
      this.props.lastSentDoc === hashedHTML
    ) {
      if (
        confirm(
          "EHR documentation has not changed since the last submission on " +
            formattedDate(this.props.lastSentTime, "MM/DD/YYYY h:mm:ss A") +
            ". Send again?"
        )
      ) {
        this.props.setLastSentClinicalDoc({
          doc: hashedHTML,
          lastSentTime: new Date()
        });
        fhirModule.postEHR(
          entry,
          data => this.submitSuccess(data),
          (err, data) => this.submitError(err, data)
        );
      }
    } else {
      fhirModule.postEHR(
        entry,
        data => this.submitSuccess(data),
        (err, data) => this.submitError(err, data)
      );

      if (user.features.includes(FHIR_WARN_ON_DUPLICATE_SUBMIT)) {
        this.props.setLastSentClinicalDoc({
          doc: hashedHTML,
          lastSentTime: new Date()
        });
      }
    }
  };

  render() {
    const {
      ehrs,
      diagnoses,
      togglePersonalNoteInput,
      handleAppointmentChange,
      providerNote,
      calcs,
      ehrOrdering,
      appointment,
      data,
      onChange,
      handlePersonalNoteSubmit,
      handleFollowUpSubmit,
      reasonsForDeviation,
      mcgData
    } = this.props;
    const treatmentCourseEhrs = sortFilterAndGroupByCategory({
      ehrOrdering,
      ehrs,
      type: EHR_CATEGORY_IDS["treatmentCourse"]
    });
    const evidenceSummaryEhrs = sortFilterAndGroupByCategory({
      ehrOrdering,
      ehrs,
      type: EHR_CATEGORY_IDS["evidenceSummary"]
    });
    const supportingStatementEhrs = sortFilterAndGroupByCategory({
      ehrOrdering,
      ehrs,
      type: EHR_CATEGORY_IDS["supportingStatement"]
    });
    const hasProviderNote = providerNote !== "";
    // const hasAppointment = R.values(appointment).some(x => x !== '')
    return (
      <div className="ehr_container">
        <div className="row collapse align-middle">
          <div className="ehr_header-text columns">
            Select the items to include in your documentation
          </div>
          <div className="columns shrink">
            <div className="row collapse align-middle">
              {/* <CopyEhrToClipboard
                diagnoses={diagnoses}
                ehrs={ehrs}
                providerNote={providerNote}
                sendToEhr={this.sendToEhr}
              /> */}
              {__FHIR__ && FHIR_FLAGS_HAS_CD_POST && (
                <SendToEhrButton
                  diagnoses={diagnoses}
                  ehrs={ehrs}
                  providerNote={providerNote}
                  sendToEhr={this.sendToEhr}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row align-middle collapse ehr_icon-container-row">
          <Tooltip
            id="ehr-provider-note-tooltip"
            className="ehr-icon_tooltip"
            message="Provider Comment"
          >
            {renderFollowUpIcon({ togglePersonalNoteInput, hasProviderNote })}
          </Tooltip>
          {/* {!__FHIR__ && <Tooltip
          id='ehr-follow-up-tooltip'
          className='ehr-icon_tooltip'
          message='Follow-up Appointment'>
          {renderCalIcon({ toggleFollowUpForm, hasAppointment })}
        </Tooltip>} */}
        </div>
        {data.isFollowUpFormOpen && (
          <FollowUpApptForm
            appointment={appointment}
            onChange={handleAppointmentChange}
            onSubmit={handleFollowUpSubmit}
          />
        )}
        {data.isPersonalNoteInputOpen && (
          <ProviderNoteForm
            note={providerNote}
            onChange={onChange}
            onSubmit={handlePersonalNoteSubmit}
          />
        )}
        {diagnoses.length > 0 && (
          <PatientEdDiagnoses ehrView diagnoses={diagnoses} />
        )}
        {(!isEmpty(treatmentCourseEhrs) || !isEmpty(calcs)) && (
          <div className="row align-middle collapse">
            <EhrAccordion
              defaultToOpen={
                isEmpty(evidenceSummaryEhrs) &&
                isEmpty(supportingStatementEhrs) &&
                (!isEmpty(treatmentCourseEhrs) ||
                  !isEmpty(calcs) ||
                  !isEmpty(reasonsForDeviation))
              }
              title="Treatment Course"
              render={() => (
                <EhrTreatmentCourse
                  treatmentCourseEhrs={treatmentCourseEhrs}
                  calcs={calcs}
                  reasonsForDeviation={
                    !isEmpty(reasonsForDeviation) && reasonsForDeviation
                  }
                  defaultToOpen={
                    isEmpty(evidenceSummaryEhrs) &&
                    isEmpty(supportingStatementEhrs) &&
                    (!isEmpty(treatmentCourseEhrs) ||
                      !isEmpty(calcs) ||
                      !isEmpty(reasonsForDeviation))
                  }
                />
              )}
            />
          </div>
        )}
        {!isEmpty(evidenceSummaryEhrs) && (
          <div className="row align-middle collapse">
            <EhrAccordion
              title="Evidence Summary"
              render={() => (
                <EhrEvidenceSummary evidenceSummaryEhrs={evidenceSummaryEhrs} />
              )}
            />
          </div>
        )}
        {!isEmpty(supportingStatementEhrs) && (
          <div className="row align-middle collapse">
            <EhrAccordion
              title="Supporting Statement"
              render={() => (
                <EhrSupportingStatement
                  supportingStatementEhrs={supportingStatementEhrs}
                />
              )}
            />
          </div>
        )}
        {!isEmpty(mcgData) && mcgData.isSubmitted && (
          <div>
            <div className="row align-middle collapse">
              <EhrAccordion
                title="Admission Criteria"
                render={() => <McgEhrDocumentation />}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

EhrView.propTypes = {
  ehrs: PropTypes.array,
  diagnoses: PropTypes.array,
  toggleFollowUpForm: PropTypes.func,
  togglePersonalNoteInput: PropTypes.func,
  providerNote: PropTypes.string,
  calcs: PropTypes.array,
  ehrOrdering: PropTypes.array,
  appointment: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.function,
  handlePersonalNoteSubmit: PropTypes.func,
  handleAppointmentChange: PropTypes.func,
  handleFollowUpSubmit: PropTypes.func,
  mcgData: PropTypes.object,
  pipelineRequest: PropTypes.func,
  selectedEhrs: PropTypes.array.isRequired,
  selectedDiagnoses: PropTypes.array.isRequired,
  success: PropTypes.func.isRequired,
  selectedCalcs: PropTypes.array,
  error: PropTypes.func,
  slug: PropTypes.string,
  patient: PropTypes.object,
  reasonsForDeviation: PropTypes.object,
  selectedReasons: PropTypes.object,
  user: PropTypes.object,
  lastSentDoc: PropTypes.string,
  lastSentTime: PropTypes.string,
  setLastSentClinicalDoc: PropTypes.func
};

const mapStateToProps = (state, { ehrs, diagnoses }) => {
  const getFullActiveSelectedCalcInputs = makeGetFullActiveSelectedCalcInputs();
  const selectedEhrs = getSelectedEhrCustomState(state, ehrs);

  const selectedDiagnoses = diagnoses.filter(diagnosis => {
    const customItemState = dotProp.get(
      state,
      `session.customOutputItems.${diagnosis.id}`
    );
    return customItemState ? customItemState["isSelected"] : true;
  });
  const activeCalcs = getFullActiveSelectedCalcInputs(state);
  const selectedCalcs = activeCalcs.filter(calc => {
    const customItemState = dotProp.get(
      state,
      `session.customOutputItems.${calc.usage.id}`
    );
    return customItemState ? customItemState["isSelected"] : true;
  });

  const selectedReasons = R.filter(
    reason => reason.isSelected && reason.submitted,
    state.session.userText.reasons
  );

  return {
    ehrOrdering: state.bootstrapContent.patientEdOrdering.ehrCategoriesOrdering,
    selectedEhrs,
    selectedDiagnoses,
    selectedCalcs,
    selectedReasons,
    patient: state.patient,
    slug: dotProp.get(state, `user.organization.slug`, ""),
    reasonsForDeviation: R.filter(
      reason => reason.submitted,
      dotProp.get(state, `session.userText.reasons`)
    ),
    user: state.user,
    lastSentDoc: dotProp.get(state, "session.userText.lastSentClinicalDoc.doc"),
    lastSentTime: dotProp.get(
      state,
      "session.userText.lastSentClinicalDoc.lastSentTime"
    )
  };
};
const mapDispatchToProps = {
  success,
  error,
  pipelineRequest,
  setLastSentClinicalDoc
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPersonalNoteCouponAndFollowUp(EhrView));
