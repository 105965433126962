import React, { useEffect } from "react";
import { connect } from "react-redux";
import { base64ECModalLogo } from "constants/fhir";
import { hideModal } from "store/modal/actions";
import PropTypes from "prop-types";

const ImagingAdvisorWelcomeModal = ({ hideModal }) => {
  useEffect(() => {
    setTimeout(() => {
      hideModal();
    }, 6500);
  });
  const handleCloseClick = () => {
    hideModal();
  };
  return (
    <div className="imaging-advisor-welcome_container">
      <img
        src={base64ECModalLogo}
        className="imaging-advisor-welcome_modal-logo"
        alt="EvidenceCare"
      />
      <div className="imaging-advisor-welcome_main-container">
        <p>Welcome!</p>
        <p>
          You have been directed to EvidenceCare to ensure the appropriate use
          criteria for the study you have ordered have been met.
        </p>
        <p>Please complete the short protocol.</p>
        <p>
          The determination will be automatically sent back to the EHR and you
          will be returned to your patient encounter.
        </p>
      </div>
      <div
        onClick={() => handleCloseClick()}
        className="imaging-advisor-welcome_nav-container"
      >
        <i className="imaging-advisor-welcome icon-arrow-left"></i>
        <span className="imaging-advisor-welcome_nav-text">Let's go!</span>
      </div>
    </div>
  );
};
const mapDispatchToprops = dispatch => ({
  hideModal: () => dispatch(hideModal())
});

export default connect(
  null,
  mapDispatchToprops
)(ImagingAdvisorWelcomeModal);

ImagingAdvisorWelcomeModal.propTypes = {
  hideModal: PropTypes.func
};
