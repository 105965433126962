import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { yearsFromDate, formattedDate } from "helpers/parsing/dates";
import { fhirFactory } from "lib/fhir/factory";
import { setObservations } from "store/patient";

export class Drawer extends Component {
  fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);
  state = {
    showCategory: {
      "vital-signs": false,
      imaging: false,
      "social-history": false,
      laboratory: false
    }
  };

  fetchObservations = (fhirModule, category) =>
    fhirModule
      .fetchPatientObservations(category)
      .then(results =>
        this.props.setObservations(
          fhirModule.getObservations({ category, results })
        )
      );

  getAge = birthDate => `${yearsFromDate(birthDate)} years old`;

  getDate = date => formattedDate(date, "MM/DD/YYYY");

  handleCategoryClick = category => {
    if (!this.props.observations[category]) {
      this.fetchObservations(category);
    }
    this.setState({
      showCategory: Object.assign({}, ...this.state.showCategory, {
        [category]: !this.state.showCategory[category]
      })
    });
  };

  render() {
    const { patient, observations } = this.props;
    const { birthDate } = patient;
    const formattedBirthDate = formattedDate(birthDate, "MM/DD/YYYY");
    return (
      <div className="patient-drawer">
        <div className="patient-drawer_body">
          <div className="patient-info">
            {patient.avatar && (
              <div className="patient-info_avatar">
                <img src="" alt="patient avatar" />
              </div>
            )}
            <div className="patient-info_details">
              <p className="patient-info_name">{patient.name}</p>
              <p className="patient-info_gender">
                {patient.gender} / {this.getAge(birthDate)}
              </p>
              <p className="patient-info_dob">
                Date of Birth: {formattedBirthDate}
              </p>
              <p>MRN: {patient.mrn}</p>
              <p>Account Number: {patient.encounter.id}</p>
            </div>
          </div>
          <div className="patient_vital-signs">
            {observations["vital-signs"] && (
              <h5 onClick={() => this.handleCategoryClick("vital-signs")}>
                Vital Signs{" "}
              </h5>
            )}
            {this.state.showCategory["vital-signs"] &&
              observations["vital-signs"] &&
              observations["vital-signs"].map(vs => (
                <p key={vs.field + vs.value + vs.unit}>
                  {this.getDate(vs.date)}: {vs.field} {vs.value}
                  {vs.unit}
                </p>
              ))}
            {observations.laboratory && (
              <h5 onClick={() => this.handleCategoryClick("laboratory")}>
                Labs{" "}
              </h5>
            )}
            {this.state.showCategory.laboratory &&
              observations.laboratory &&
              observations.laboratory.map((vs, i) => (
                <p key={i}>
                  {this.getDate(vs.date)}: {vs.field} {vs.value}
                  {vs.unit}
                </p>
              ))}

            {observations["social-history"] && (
              <h5 onClick={() => this.handleCategoryClick("social-history")}>
                Social History{" "}
              </h5>
            )}
            {this.state.showCategory["social-history"] &&
              observations["social-history"] &&
              observations["social-history"].map(vs => (
                <p key={vs.field + vs.value}>
                  {vs.field}: {vs.value}
                </p>
              ))}
            {observations["exam"] && (
              <h5 onClick={() => this.handleCategoryClick("exam")}>Exam </h5>
            )}
            {this.state.showCategory["exam"] &&
              observations["exam"] &&
              observations["exam"].map((vs, i) => (
                <p key={i}>
                  {this.getDate(vs.date)}: {vs.field} {vs.value} {vs.unit}
                </p>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

Drawer.propTypes = {
  patient: PropTypes.shape({
    avatar: PropTypes.string,
    encounter: PropTypes.object,
    name: PropTypes.string,
    mrn: PropTypes.string,
    birthDate: PropTypes.string,
    gender: PropTypes.string
  }).isRequired,
  observations: PropTypes.object,
  setObservations: PropTypes.func
};

const mapStateToProps = state => ({
  observations: state.patient.observations || {}
});

const mapDispatchToProps = {
  setObservations
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drawer);
