import React from "react";
import PropTypes from "prop-types";
import { eliquisSrc } from "constants/cloudinary";
import { xsmallImageUrl } from "helpers/urls/cloudinary";
import {
  EVENT_MED_COUPON_VIEW,
  EVENT_MED_COUPON_CLICK
} from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";
import { connect } from "react-redux";

class CouponComponent extends React.Component {
  componentDidMount() {
    this.props.pipelineRequest({
      action: EVENT_MED_COUPON_VIEW,
      message: {
        coupon_type: "eliquis",
        protocol_id: this.props.session.activeProtocolId,
        sessionId: this.props.session.id
      }
    });
  }
  eliquisCouponLink = url => {
    return () => {
      this.props.updateRxSet();
      this.props.pipelineRequest({
        action: EVENT_MED_COUPON_CLICK,
        message: {
          coupon_type: "eliquis",
          protocol_id: this.props.session.activeProtocolId,
          sessionId: this.props.session.id
        }
      });
      window.open(url);
    };
  };
  renderSmall = () => {
    return (
      <React.Fragment>
        <div className="columns">
          <img
            src={xsmallImageUrl(eliquisSrc)}
            className="rx-embedded-coupon_image"
          />
          <div className="rx-embedded-coupon_text">Only $10/month</div>
        </div>
        <div className="columns">
          <button
            className="rx-embedded-coupon_coupon-available button button-primary"
            onClick={this.eliquisCouponLink(
              "https://mprsetrial.mckesson.com/6822/home.html?src=EC"
            )}
          >
            View Coupon
          </button>
        </div>
      </React.Fragment>
    );
  };
  renderMedium = () => {
    return (
      <React.Fragment>
        <div className="columns small-4">
          <img
            src={xsmallImageUrl(eliquisSrc)}
            className="rx-embedded-coupon_image"
          />
        </div>
        <div className="columns rx-embedded-coupon_text small-4">
          Only $10/month
        </div>
        <div className="columns small-4 text-right">
          <button
            className="rx-embedded-coupon_coupon-available button button-primary"
            onClick={this.eliquisCouponLink(
              "https://mprsetrial.mckesson.com/6822/home.html?src=EC"
            )}
          >
            View Coupon
          </button>
        </div>
      </React.Fragment>
    );
  };
  render() {
    return (
      <div className="columns small-12 rx-embedded-coupon">
        <div className="row align-middle align-justify collapse">
          {this.props.dimensions.width >= 360
            ? this.renderMedium()
            : this.renderSmall()}
        </div>
      </div>
    );
  }
}

CouponComponent.propTypes = {
  dimensions: PropTypes.object,
  updateRxSet: PropTypes.func,
  session: PropTypes.object,
  pipelineRequest: PropTypes.func
};

const mapDispatchToProps = {
  pipelineRequest
};

export default connect(
  null,
  mapDispatchToProps
)(CouponComponent);
