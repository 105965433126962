import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import qs from "query-string";

import LoginContainer from "components/auth/login/LoginContainer";
import Logout from "components/auth/login/LogoutContainer";
import ProtocolErrorBoundary from "components/protocol/ProtocolErrorBoundary";
import CoreLayout from "layouts/CoreLayout/CoreLayout";
import Account from "components/account/Account";
import Signup from "components/auth/signup/Signup";
import Mcg from "integrations/mcg/McgComponent";
import InviteAccept from "components/auth/invite/InviteAccept";
import Unsubscribe from "components/auth/signup/Unsubscribe";
import HelpCenterLanding from "components/HelpCenter/HelpCenterLanding";
import HelpCenterSection from "components/HelpCenter/HelpCenterSection";
import HelpCenterArticle from "components/HelpCenter/HelpCenterArticle";
import EhrView from "components/ehr/EhrView";
import { selectIsAuthenticated } from "store/auth/selectors";
import { selectHasAgreedToTerms } from "store/terms/selectors";
import Disclosure from "components/disclosure/Disclosure";
import AuthCB from "components/auth/auth0/AuthCB";
import { launchLogin } from "store/auth/actions";
import { makeObjKeysLowerCase } from "helpers/util";
import ImagingAdvisorStatusScreen from "components/imagingAdvisor/ImagingAdvisorStatusScreen";
const StartPageContainer = lazy(() =>
  import(/* webpackChunkName: "StartPage" */ "containers/StartPage")
);

/********************************************
 * Route Table
 ********************************************/

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute
          exact
          path="/"
          render={props => <StartPageContainer {...props} />}
        />
        <PrivateRoute
          exact
          path="/protocol/:slug/:protocolId/:sessionId?"
          render={props => (
            <CoreLayout>
              <ProtocolErrorBoundary {...props} />
            </CoreLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/mcg/:protocolId/:mcgGroup/:mcgHsim"
          render={props => (
            <CoreLayout>
              <Mcg {...props} />
            </CoreLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/ehr/:ehrId"
          render={props => (
            <CoreLayout>
              <EhrView {...props} />
            </CoreLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/help-center"
          render={props => <HelpCenterLanding {...props} />}
        />
        <PrivateRoute
          exact
          path="/help-center/section/:id"
          render={props => <HelpCenterSection {...props} />}
        />
        <PrivateRoute
          exact
          path="/help-center/article/:id"
          render={props => <HelpCenterArticle {...props} />}
        />
        <PrivateRoute
          path="/account"
          render={props => (
            <CoreLayout>
              <Account {...props} />
            </CoreLayout>
          )}
        />
        <PrivateRoute
          path="/imaging-advisor/web-message-sent/:status"
          render={props => (
            <CoreLayout>
              <ImagingAdvisorStatusScreen {...props} />
            </CoreLayout>
          )}
        />
        <Route
          exact
          path="/unsubscribe/:uid"
          render={props => {
            return <Unsubscribe uid={props.match.params.uid} />;
          }}
        />

        {/* redirecting ACG launch URL's to the correlated protocols in our App */}
        <Route
          exact
          path="/welcome/undefined/acg"
          render={() => <Redirect to="/signup?partner=acg" />}
        />
        <Route
          exact
          path="/launch/acg_c_diff"
          render={() => (
            <Redirect to="/protocol/acgprot-cdiff/74c24cdd-8f9d-4f52-8803-553f458177e1" />
          )}
        />
        <Route
          exact
          path="/launch/acg_acute_diarrheal_infections"
          render={() => (
            <Redirect to="/protocol/acgprot-acutediarrhealinfections/40eebf57-c1e8-4886-a0f9-631cf0b52ff9" />
          )}
        />
        <Route
          exact
          path="/launch/acg_anorectal_disorders"
          render={() => (
            <Redirect to="/protocol/acgprot-benignanorectaldisorders/a00b0674-4ad8-4089-835b-2fc7d802e31a" />
          )}
        />
        <Route
          exact
          path="/launch/acg_dyspepsia"
          render={() => (
            <Redirect to="/protocol/s/8faf68fa-9034-4b84-ad3f-fdc915844a74" />
          )}
        />
        <Route
          exact
          path="/launch/acg_gerd"
          render={() => (
            <Redirect to="/protocol/s/45e26ace-e111-4d40-887b-04844ae113e1" />
          )}
        />
        <Route
          exact
          path="/launch/acg_h_pylori"
          render={() => (
            <Redirect to="/protocol/s/ebbc26a8-bbb2-404e-9545-775a9aaca567" />
          )}
        />
        <Route
          exact
          path="/launch/acg_lower_gi_bleeding"
          render={() => (
            <Redirect to="/protocol/s/2328d5a0-1644-4299-9398-32c2fe206c34" />
          )}
        />
        <Route
          exact
          path="/launch/acg_small_bowel_bleeding"
          render={() => (
            <Redirect to="/protocol/s/323476df-6055-4c30-b2d6-a1eac0e24ea1" />
          )}
        />

        {/* Authentication Routes*/}
        <Route
          exact
          path="/invite-accept/id/:inviteId/token/:singleUseToken/org/:organization?"
          render={props => {
            const {
              inviteId,
              singleUseToken,
              organization
            } = props.match.params;
            return (
              <InviteAccept
                inviteId={inviteId}
                singleUseToken={singleUseToken}
                organization={organization}
              />
            );
          }}
        />
        <Redirect from="/partner/:code" to="/signup?partner=:code" />
        <Route
          path="/signup/:code?"
          render={props => {
            const { code } = props.match.params;
            const { partner, email } = makeObjKeysLowerCase(
              qs.parse(props.location.search)
            );
            return <Signup code={partner || code} email={email} />;
          }}
        />
        <Route
          path={["/login", "/demo-login"]}
          render={props => {
            const { partner } = makeObjKeysLowerCase(
              qs.parse(props.location.search)
            );
            if (__FHIR__ || props.match.path === "/demo-login") {
              return <LoginContainer partner={partner} />;
            }
            window.location.replace("https://evidence.care/web/");
          }}
        />

        <Route exact path="/logout" component={Logout} />
        <Route path="/authcb" component={AuthCB} />
      </Switch>
    </Suspense>
  );
};

Routes.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
};

// with Router is necessary here to force a rerender if the route changes
export default withRouter(Routes);

/********************************************
 * Private Route
 *
 * Intercepts a `Routes` props and ensures
 * that the user is authenticated and has agreed
 * to EC's terms of use before displaying the route
 ********************************************/

class _PrivateRoute extends React.Component {
  componentDidUpdate() {
    this.redirectIfNeeded();
  }

  componentDidMount() {
    this.redirectIfNeeded();
  }

  redirectIfNeeded() {
    const { isAuthenticated, launchLogin } = this.props;
    if (!isAuthenticated) {
      launchLogin();
    }
  }

  render() {
    const { render, isAuthenticated, hasAgreedToTerms, ...rest } = this.props;
    if (!isAuthenticated) {
      return null;
    } else if (!hasAgreedToTerms) {
      return <Disclosure />;
    } else {
      return <Route {...rest} render={props => render(props)} />;
    }
  }
}

_PrivateRoute.propTypes = {
  render: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  hasAgreedToTerms: PropTypes.bool,
  launchLogin: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: selectIsAuthenticated(state),
  hasAgreedToTerms: selectHasAgreedToTerms(state)
});

const mapDispatchToProps = {
  launchLogin
};

const PrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(_PrivateRoute);
