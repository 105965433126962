import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCustomOutputItemSelectedState } from "store/session";
import dotProp from "dot-prop";
import classNames from "classnames";
import Checkbox from "components/ui/Checkbox";
import MarkdownRender from "utility/markdownRender";

class OutputItemSelectable extends Component {
  handleSelect = () => {
    const {
      item,
      setCustomOutputItemSelectedState,
      customItemState
    } = this.props;
    const itemIsSelected = customItemState
      ? customItemState["isSelected"]
      : true;
    setCustomOutputItemSelectedState({
      outputId: "",
      outputItemId: item.id,
      isSelected: !itemIsSelected
    });
  };

  render() {
    const { item, customItemState, itemText } = this.props;
    const text = item.text || itemText;
    const isSelected = customItemState ? customItemState["isSelected"] : true;
    const indicatorClasses = classNames("output-item_text", {
      "output-item_text--selected": isSelected,
      "": !isSelected
    });
    return (
      <div className="output-item_container row align-middle collapse">
        <div className="columns shrink">
          <Checkbox isSelected={isSelected} onChange={this.handleSelect} />
        </div>
        <div className="columns">
          <div className={indicatorClasses}>
            <MarkdownRender softBreak="br" source={text} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, { item }) => ({
  customItemState: dotProp.get(state, `session.customOutputItems.${item.id}`)
});
export default connect(
  mapStateToProps,
  { setCustomOutputItemSelectedState }
)(OutputItemSelectable);

OutputItemSelectable.propTypes = {
  item: PropTypes.object.isRequired,
  setCustomOutputItemSelectedState: PropTypes.func.isRequired,
  itemText: PropTypes.string,
  customItemState: PropTypes.object
};
