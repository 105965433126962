import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MarkdownRender from "utility/markdownRender";
import {
  CUSTOM_DISCLOSURE_TEXT,
  DEFAULT_DISCLOSURE_TEXT
} from "constants/disclosures";

export class Accreditation extends Component {
  state = {
    open: false
  };
  toggleSection = () => {
    this.setState({ open: !this.state.open });
  };
  showEditors = () => {
    const { cred } = this.props;
    return cred.editors.map(editor => (
      <li key={editor.name}>
        {`${editor.name}: Planner, Reviewer`}
        <ul>
          <li>{editor.title}</li>
          <li>{editor.institution}</li>
        </ul>
        <li>Brian Fenger, MD: Reviewer, Education Planning Representative</li>
        <li>David Sanders, MD: Reviewer, Manager</li>
      </li>
    ));
  };
  render() {
    const { cred, medSociety } = this.props;
    const maxHours = Number(cred.max_hours).toFixed(1);
    const headerClasses = classNames("cme-accreditation_header", {
      "cme-accreditation_header--opened": this.state.open
    });
    const expandClasses = classNames("section_header-expand-icon", {
      "icon-expand": !this.state.open,
      "icon-expanded": this.state.open
    });
    const disclosureText =
      CUSTOM_DISCLOSURE_TEXT[medSociety.id] || DEFAULT_DISCLOSURE_TEXT;
    return (
      <div className="cme-accreditation">
        <div className={headerClasses} onClick={this.toggleSection}>
          <h5 className="cme-accreditation_title">
            {cred.title}
            {cred.subtitle && (
              <span className="cme-accreditation_subtitle">
                <br />
                {cred.subtitle}
              </span>
            )}
          </h5>
          <div className={expandClasses} />
        </div>
        {this.state.open && (
          <div className="cme-accreditation_body">
            {cred.editors && (
              <div>
                <h5>Individuals in control of content:</h5>
                <ul>this.showEditors()</ul>
              </div>
            )}
            <h5>Disclosures:</h5>
            <MarkdownRender source={cred.editor_disclosure || "None."} />
            <h5>Target Audience</h5>
            <p>{disclosureText.targetAudience}</p>
            <h5>Learner Objective</h5>
            <p>{disclosureText.learnerObjective}</p>
            <h5>CME Details</h5>
            <h6>AMA Designation Statement</h6>
            <p>
              {medSociety.title} designates this enduring material for a maximum
              of
              {maxHours} <i>AMA PRA Category 1 Credit(s)</i>&trade;. Physicians
              should claim only the credit commensurate with the extent of their
              participation in the activity.
            </p>
            <ul>
              <li>Approved for {maxHours} CME credits</li>
              <li>Release Date: {cred.start_date}</li>
              <li>Expiration Date: {cred.end_date}</li>
              {/* <li>Date of most recent update: {cred.tbd}</li> */}
              {/* <li>Date of most recent review by CME provider: {cred.tbd}</li> */}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

Accreditation.propTypes = {
  cred: PropTypes.object,
  medSociety: PropTypes.object
};

export default Accreditation;
