import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import R from "ramda";
import classNames from "classnames";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { Tooltip } from "components/ui/tooltip";

export const SectionHeader = props => {
  const { activeGroupId, sectionVersion, activeSelectedOutputs } = props;
  const section = sectionVersion.render;
  const selectedOutputWithNextSection = R.values(activeSelectedOutputs).find(
    op => op.selected && op.nextId && op.sectionId === section.id
  );
  const sectionActive = activeGroupId === section.groupId;
  const labelSelection = selectedOutputWithNextSection
    ? sectionVersion.render.selections.find(
        sel => selectedOutputWithNextSection.selectionId === sel.id
      )
    : "";
  const selectedByObservationClass =
    labelSelection && props.selections[labelSelection.id].selectedByObservation
      ? "section_header-label--by-observation"
      : "";
  const expandClasses = classNames("section_expand-icon", {
    "section_expand-icon--active": sectionActive,
    "icon-expanded": sectionActive,
    "icon-expand": !sectionActive
  });
  return (
    <React.Fragment>
      <div
        className="section_header-wrap"
        onClick={props.setActiveGroup}
        data-flow-section={section.group.title}
      >
        <h5 className="section_header">{section.group.title}</h5>

        {props.activeGroupId !== sectionVersion.render.groupId &&
          labelSelection && (
            <div className="section_header-label-wrap">
              {props.selections[labelSelection.id].selectedByObservation && (
                <div className="section_header-icon-container">
                  <Tooltip
                    id="ehr-doc-icon-header"
                    message="This selection was populated from the EHR"
                    place="top"
                  >
                    <span className="section_header-icon icon-ehr-information" />
                  </Tooltip>
                  <div className="section_header-label-observation-icon" />
                </div>
              )}
              <div
                className={`section_header-label ${selectedByObservationClass}`}
              >
                {labelSelection.displayName || labelSelection.label}
              </div>
            </div>
          )}
        <div className={expandClasses} />
      </div>
      {props.children}
    </React.Fragment>
  );
};

SectionHeader.propTypes = {
  activeGroupId: PropTypes.string,
  selections: PropTypes.object,
  children: PropTypes.array,
  sectionVersion: PropTypes.object,
  activeSelectedOutputs: PropTypes.array,
  setActiveGroup: PropTypes.func
};
const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const getSelectedOutputs = makeSelectedOutputs();
    return {
      activeSelectedOutputs: getSelectedOutputs(state),
      activeGroupId:
        state.session.entities.protocols[props.sectionVersion.protocolId]
          .activeGroupId,
      selections: state.session.entities.selections
    };
  };
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(SectionHeader);
