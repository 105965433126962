import React, { Component } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import MedicationItem from "./MedicationItem";

export class Medication extends Component {
  setPrescriptions = (prescriptionSet, prescriptionsWithProfiles) => {
    let meds = [];
    const prescriptions = prescriptionSet.prescriptions.map(id => {
      const prescriptionObj = R.find(
        R.propEq("id", id),
        prescriptionsWithProfiles
      );
      meds.push(prescriptionObj.medicationProfile.id);
      return prescriptionObj;
    });
    const singleMedMode =
      meds.length > 1 ? R.all(R.equals(meds[0]), meds) : false;
    return { prescriptions, singleMedMode };
  };

  render() {
    const { itemState, item: rxSet } = this.props;
    const group = R.find(R.propEq("id", itemState.groupId))(
      rxSet.structure.groups
    );
    const optionSet = group.optionSets[itemState.optionSetIndex];
    const prescriptionSet = optionSet.prescriptionSets[itemState.optionIndex];
    const { prescriptions, singleMedMode } = this.setPrescriptions(
      prescriptionSet,
      rxSet.prescriptions
    );
    return (
      <MedicationItem
        group={group}
        prescriptions={prescriptions}
        singleMedMode={singleMedMode}
      />
    );
  }
}

Medication.propTypes = {
  itemState: PropTypes.object,
  item: PropTypes.object
};

export default Medication;
