import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const renderCalIcon = ({ toggleFollowUpForm, hasAppointment }) => {
  const calenderBgLayerClasses = classNames("circle-background", {
    "circle-background--active": hasAppointment
  });
  const calendarSelectorClasses = classNames("patient-ed-icon", {
    "patient-ed-icon_check": hasAppointment,
    "patient-ed-icon_plus": !hasAppointment
  });
  return (
    <div
      onClick={toggleFollowUpForm}
      className="patient-ed-icon icon-calendar-blank"
    >
      <span className={calenderBgLayerClasses} />
      <span className={calendarSelectorClasses} />
    </div>
  );
};

renderCalIcon.propTypes = {
  toggleFollowUpForm: PropTypes.func,
  hasAppointment: PropTypes.bool
};

export const renderFollowUpIcon = ({
  togglePersonalNoteInput,
  hasProviderNote
}) => {
  const providerNoteBgLayerClasses = classNames("circle-background", {
    "circle-background--active": hasProviderNote
  });
  const personalNoteSelectorClasses = classNames("patient-ed-icon", {
    "patient-ed-icon_check": hasProviderNote,
    "patient-ed-icon_plus": !hasProviderNote
  });
  return (
    <div
      onClick={togglePersonalNoteInput}
      alt="Provider Comment"
      title="Provider Comment"
      className="patient-ed-icon icon-ehr-doc"
    >
      <span className={providerNoteBgLayerClasses} />
      <span className={personalNoteSelectorClasses} />
    </div>
  );
};

renderFollowUpIcon.propTypes = {
  togglePersonalNoteInput: PropTypes.func,
  hasProviderNote: PropTypes.bool
};

export const renderEhrIcon = ({ toggleCoupon }) => {
  // coupon should always be active for the time being
  const hasCoupon = true;
  const couponBgLayerClasses = classNames("circle-background", {
    "circle-background--active": hasCoupon
  });
  const couponSelectorClasses = classNames("patient-ed-icon", {
    "patient-ed-icon_check": hasCoupon,
    "patient-ed-icon_plus": !hasCoupon
  });
  return (
    <div onClick={toggleCoupon} className="patient-ed-icon icon-coupon-cut">
      <span className={couponBgLayerClasses} />
      <span className={couponSelectorClasses} />
    </div>
  );
};

renderEhrIcon.propTypes = {
  toggleCoupon: PropTypes.func
};
