import * as immutable from "object-path-immutable";
import { fetchSectionVersionsApi } from "lib/ec/protocol";
import dotProp from "dot-prop";
import { getOrderStringIdFromProtocol } from "selectors/outputSelectors";
import { fetchOrdersMap } from "store/FHIR_orders";
import R from "ramda";

// ------------------------------------
// Constants
// ------------------------------------
export const PROTOCOL_ADD_PROTOCOL = "PROTOCOL_ADD_PROTOCOL";
export const CLEAR_PROTOCOL = "CLEAR_PROTOCOL";
export const PROTOCOL_SET_PANEL = "PROTOCOL_SET_PANEL";
export const PROTOCOL_CLEAR_PANEL = "PROTOCOL_CLEAR_PANEL";
export const SET_PROTOCOL_PROP = "SET_PROTOCOL_PROP";
export const PROTOCOL_SET_SECTIONS = "PROTOCOL_SET_SECTIONS";
export const PROTOCOL_SET_SECTION_VERSIONS = "PROTOCOL_SET_SECTION_VERSIONS";
export const PROTOCOL_ADD_SECTION = "PROTOCOL_ADD_SECTION";
export const PROTOCOL_SET_MORE_INFO = "PROTOCOL_SET_MORE_INFO";
export const PROTOCOL_CLEAR_MORE_INFO = "PROTOCOL_CLEAR_MORE_INFO";
export const PROTOCOL_REHYDRATE_SECTIONS = "PROTOCOL_REHYDRATE_SECTIONS";
export const PROTOCOL_FETCH_SECTION = "PROTOCOL_FETCH_SECTION";
export const PROTOCOL_SHOW_LAUNCH_BUTTON = "PROTOCOL_SHOW_LAUNCH_BUTTON";
export const PROTOCOL_LAUNCH_PROTOCOL = "PROTOCOL_LAUNCH_PROTOCOL";
export const PROTOCOL_CLEAR_LAUNCH_BUTTON = "PROTOCOL_CLEAR_LAUNCH_BUTTON";
export const PROTOCOL_SET_LATEST_VERSION = "PROTOCOL_SET_LATEST_VERSION";
export const PROTOCOL_SET_PROTOCOL_CONTENT = "PROTOCOL_SET_PROTOCOL_CONTENT";
export const PROTOCOL_IMAGING_ADVISOR_CRITERIA_MET =
  "PROTOCOL_IMAGING_ADVISOR_CRITERIA_MET";
export const PROTOCOL_HANDLE_WEB_MESSAGE_RESPONSE =
  "PROTOCOL_HANDLE_WEB_MESSAGE_RESPONSE";
export const STORE_ORDER_IDS = "STORE_ORDER_IDS";
export const SECTION_INITIAL_STATE = {
  schema: "",
  latestVersionId: "",
  protocolId: "",
  createdAt: "",
  id: ""
};
export const PROTOCOL_INITIAL_STATE = {
  // rightPanel: MORE_INFO_INITIAL_STATE,
  description: "",
  id: null,
  keywords: [],
  rootId: "",
  slug: "",
  subtitle: "",
  title: "",
  sections: {},
  launchButton: null
};
export const INITIAL_STATE = {
  activeMoreInfo: null,
  activeMoreInfoId: null,
  sideBarIsActive: false,
  imagingAdvisor: {}
};

// ------------------------------------
// Actions
// ------------------------------------
export function protocolAddProtocol({ protocol }) {
  return {
    type: PROTOCOL_ADD_PROTOCOL,
    payload: { protocol }
  };
}

export function setProtocolContent({ protocolContent }) {
  return (dispatch, getState) => {
    /**
     * when we set a protocol, we need to fetch and set an orders map
     * when in a fhir env
     */
    if (__FHIR__) {
      const orderStringsIds = getOrderStringIdFromProtocol(protocolContent);
      dispatch(fetchOrdersMap({ orderStringsIds })).then(() => {
        dispatch({
          type: PROTOCOL_SET_PROTOCOL_CONTENT,
          payload: { protocolContent }
        });
      });
    } else {
      dispatch({
        type: PROTOCOL_SET_PROTOCOL_CONTENT,
        payload: { protocolContent }
      });
    }
  };
}

export function protocolSetIsLatest({ isLatestVersion }) {
  return {
    type: PROTOCOL_SET_LATEST_VERSION,
    payload: { isLatestVersion }
  };
}
export function showProtocolLaunchButton({ sectionId, protocol }) {
  return {
    type: PROTOCOL_SHOW_LAUNCH_BUTTON,
    payload: { sectionId, protocol }
  };
}

export function setSections(sections) {
  return {
    type: PROTOCOL_SET_SECTIONS,
    payload: sections
  };
}
export function setSectionVersions({ sectionVersions, protocolId }) {
  return {
    type: PROTOCOL_SET_SECTION_VERSIONS,
    payload: { sectionVersions, protocolId }
  };
}
export function addProtocolSection({ sectionVersion }) {
  return {
    type: PROTOCOL_ADD_SECTION,
    payload: { sectionVersion }
  };
}
export function protocolLaunchProtocol({
  protocolId,
  sectionId,
  approvalPoint,
  protocol,
  useProtocolVersion
}) {
  return {
    type: PROTOCOL_LAUNCH_PROTOCOL,
    payload: {
      protocolId,
      sectionId,
      approvalPoint,
      protocol,
      useProtocolVersion
    }
  };
}

export function clearProtocol() {
  return {
    type: CLEAR_PROTOCOL
  };
}

export function setPanel({ id, data, header, type }) {
  return {
    type: PROTOCOL_SET_PANEL,
    payload: { id, data, header, type }
  };
}

export function clearPanel() {
  return {
    type: PROTOCOL_CLEAR_PANEL
  };
}
export function setProtocolProp(prop, val) {
  return {
    type: SET_PROTOCOL_PROP,
    payload: { prop, val }
  };
}

export function setCommentCounts(counts) {
  return {
    type: SET_PROTOCOL_PROP,
    payload: { prop: "commentsInfo", val: counts }
  };
}

export function setActiveMoreInfo({ evidence }) {
  return {
    type: PROTOCOL_SET_MORE_INFO,
    payload: { evidence }
  };
}

export function clearActiveMoreInfo() {
  return {
    type: PROTOCOL_CLEAR_MORE_INFO
  };
}

export function setSideBarActive({ activeState }) {
  return {
    type: SET_PROTOCOL_PROP,
    payload: { prop: "sideBarIsActive", val: activeState }
  };
}

export function fetchSectionsFromSession({ session }) {
  return dispatch => {
    const sectionVersionIds = Object.keys(session.entities.sections).map(
      key => session.entities.sections[key].sectionVersionId
    );
    return fetchSectionVersionsApi({ sectionVersionIds }).then(({ data }) => {
      data &&
        dispatch({
          type: PROTOCOL_REHYDRATE_SECTIONS,
          payload: { sectionVersions: data }
        });
    });
  };
}

export const handleImagingAdvisorCriteriaMet = () => ({
  type: PROTOCOL_IMAGING_ADVISOR_CRITERIA_MET
});
// ------------------------------------
export const handleCdsWebMessageResponse = ({ payload }) => ({
  type: PROTOCOL_HANDLE_WEB_MESSAGE_RESPONSE,
  payload
});

export const setWebMessageOrderIds = ({ payload }) => ({
  type: STORE_ORDER_IDS,
  payload
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PROTOCOL_ADD_PROTOCOL]: (state, action) =>
    Object.assign({}, state, {
      [action.payload.protocol.id]: {
        ...PROTOCOL_INITIAL_STATE,
        ...action.payload.protocol
      }
    }),
  [PROTOCOL_SET_SECTIONS]: (state, action) =>
    Object.assign({}, state, { sections: action.payload }),
  [PROTOCOL_SET_LATEST_VERSION]: (state, action) =>
    Object.assign({}, state, {
      isLatestVersion: action.payload.isLatestVersion
    }),
  [PROTOCOL_SET_SECTION_VERSIONS]: (state, action) => {
    const { sectionVersions, protocolId } = action.payload;
    const combinedSectionVersions = {
      ...sectionVersions,
      ...dotProp.get(state, `${protocolId}.sections`)
    };
    return immutable.set(
      state,
      `${protocolId}.sections`,
      combinedSectionVersions
    );
  },
  [PROTOCOL_ADD_SECTION]: (state, action) => {
    const { sectionVersion } = action.payload;
    return immutable.set(
      state,
      `${sectionVersion.protocolId}.sections.${sectionVersion.sectionId}`,
      sectionVersion
    );
  },
  [PROTOCOL_REHYDRATE_SECTIONS]: (state, action) => {
    const { sectionVersions } = action.payload;
    const newState = R.clone(state);
    const updatedState = sectionVersions.reduce((acc, sv) => {
      return immutable.set(
        acc,
        `${sv.protocolId}.sections.${sv.sectionId}`,
        sv
      );
    }, newState);
    return updatedState;
  },
  [PROTOCOL_SET_PANEL]: (state, action) =>
    Object.assign({}, state, { rightPanel: action.payload }),
  [PROTOCOL_CLEAR_PANEL]: (state, action) =>
    Object.assign({}, state, { rightPanel: null }),
  [CLEAR_PROTOCOL]: (state, action) => INITIAL_STATE,
  [SET_PROTOCOL_PROP]: (state, action) =>
    Object.assign({}, state, { [action.payload.prop]: action.payload.val }),
  [PROTOCOL_SET_MORE_INFO]: (state, action) => {
    const { evidence } = action.payload;
    return Object.assign({}, state, {
      activeMoreInfo: evidence,
      activeMoreInfoId: evidence.id
    });
  },
  [PROTOCOL_CLEAR_MORE_INFO]: (state, action) => {
    return Object.assign({}, state, {
      activeMoreInfo: null,
      activeMoreInfoId: null
    });
  },
  [PROTOCOL_SHOW_LAUNCH_BUTTON]: (state, action) => {
    const { protocol, sectionId } = action.payload;
    return Object.assign({}, state, {
      launchButton: {
        protocol,
        sectionId
      }
    });
  },
  [PROTOCOL_CLEAR_LAUNCH_BUTTON]: (state, action) => {
    return Object.assign({}, state, { launchButton: null });
  },
  [PROTOCOL_SET_PROTOCOL_CONTENT]: (state, action) => {
    const { protocolContent } = action.payload;
    return { ...state, [protocolContent.id]: protocolContent };
  },
  [STORE_ORDER_IDS]: (state, action) => {
    return {
      ...state,
      imagingAdvisor: { ...state.imagingAdvisor, orders: action.payload }
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function protocolReducer(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
