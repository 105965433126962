import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const FeatureBlockOverlay = props => {
  const { title, icon, text, url, cta, children } = props;
  return (
    <div className="feature-block feature-block--overlay">
      <div className="feature-block_text">
        {title && (
          <h5 className="feature-block_title">
            {icon && <span className={`feature-block_icon ${icon}`} />}
            {title}
          </h5>
        )}
        {text && <p className="feature-block_message">{text}</p>}
        {url && (
          <div className="feature-block_footer">
            <Link className="button feature-block_button" to={url}>
              {cta}
            </Link>
          </div>
        )}
        {children && <div className="feature-block_link">{children}</div>}
      </div>
    </div>
  );
};

FeatureBlockOverlay.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  children: PropTypes.object
};

export default FeatureBlockOverlay;
