import React from "react";
import { connect } from "react-redux";
import OrderString from "./OrderString";
import classNames from "classnames";
import { SortBy } from "./OrdersContainer";
import dotProp from "dot-prop";
import R from "ramda";
import PropTypes from "prop-types";
import { FHIR_ORDERS_DRAFT_REVIEWER } from "constants/fhir";

export class OrderStringCategory extends React.Component {
  state = {
    showOrderStrings: true
  };
  toggleOrderStrings = () => {
    this.setState({ showOrderStrings: !this.state.showOrderStrings });
  };
  render() {
    const {
      category,
      orderStringsByOrderId,
      customItemsState,
      duplicateTitles
    } = this.props;
    const categoryClasses = classNames("orderset_category", {
      "orderset_category--open": this.state.showOrderStrings
    });
    const isSingleOrder = orderId =>
      duplicateTitles.includes(orderStringsByOrderId[orderId][0]["title"]);
    const getOrderStringContainerClasses = orderId =>
      classNames("orderstring_container", {
        "orderstring_container--single-order": !isSingleOrder(orderId)
      });
    return (
      <SortBy.Consumer>
        {sortBy => {
          const shouldShow =
            (sortBy === "selected" && customItemsState.length > 0) ||
            sortBy !== "selected";
          return shouldShow ? (
            <div
              key={category}
              className={categoryClasses}
              onClick={this.toggleOrderStrings}
            >
              {sortBy === "recommended" && (
                <h5 className="orderset_category-title">{category}</h5>
              )}
              {this.state.showOrderStrings && (
                <div className="orderset_content-container">
                  {Object.keys(orderStringsByOrderId).map((orderId, index) => {
                    const selectedOrder =
                      R.findIndex(R.propEq("orderId", orderId))(
                        customItemsState
                      ) >= 0;
                    const shouldShowOrder =
                      (sortBy === "selected" && selectedOrder) ||
                      sortBy !== "selected";
                    return shouldShowOrder ? (
                      <div className="orderset_orderstring" key={orderId}>
                        <div
                          key={orderId}
                          className={getOrderStringContainerClasses(orderId)}
                        >
                          {isSingleOrder(orderId) && (
                            <h6 className="orderstring_title">
                              {orderStringsByOrderId[orderId][0]["title"]}
                            </h6>
                          )}
                          {orderStringsByOrderId[orderId].map(orderString => {
                            const orderMap = __FHIR__ && this.props.orderMap;
                            const sentOrders =
                              __FHIR__ && this.props.sentOrders;
                            const user = __FHIR__ && this.props.user;
                            const isMapped =
                              __FHIR__ &&
                              orderMap &&
                              !!orderMap[orderString.id];
                            const isDraftReviewer =
                              __FHIR__ &&
                              user.features.indexOf(
                                FHIR_ORDERS_DRAFT_REVIEWER
                              ) > -1;
                            const isDraft =
                              __FHIR__ &&
                              !!orderMap[orderString.id] &&
                              !!orderMap[orderString.id].data.draft_order &&
                              orderMap[orderString.id].data.draft_order ===
                                "true";
                            const isSent =
                              __FHIR__ &&
                              sentOrders &&
                              !!sentOrders[orderString.id];
                            const sent_at =
                              __FHIR__ &&
                              (isSent
                                ? sentOrders[orderString.id].sent_at
                                : "");

                            return (
                              <OrderString
                                orderString={orderString}
                                sortBy={sortBy}
                                duplicateTitles={duplicateTitles}
                                key={orderString.id}
                                isMapped={isMapped}
                                isDraft={isDraft}
                                isDraftReviewer={isDraftReviewer}
                                isSent={isSent}
                                sent_at={sent_at}
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              )}
            </div>
          ) : null;
        }}
      </SortBy.Consumer>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    customItemsState: props.orderStrings.filter(orderString => {
      const item = dotProp.get(
        state,
        `session.customOutputItems.${orderString.id}`
      );
      return item && item.isSelected && orderString.category === props.category;
    }),
    orderMap: state.FHIR_orders.crosswalk,
    sentOrders: state.FHIR_orders.sentOrders
  };
};

OrderStringCategory.propTypes = {
  category: PropTypes.string,
  orderStringsByOrderId: PropTypes.object,
  customItemsState: PropTypes.array,
  duplicateTitles: PropTypes.array,
  orderMap: PropTypes.object,
  sentOrders: PropTypes.object,
  user: PropTypes.object
};

export default connect(mapStateToProps)(OrderStringCategory);
