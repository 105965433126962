import { makeProxyEcRequest } from "lib/ec/base";

export const editionText = (edition = "") => {
  const editionInteger = edition.split(".")[0];
  const lastChar = editionInteger[editionInteger.length - 1];
  switch (lastChar) {
    case "1":
      return `${editionInteger}st`;
    case "2":
      return `${editionInteger}nd`;
    case "3":
      return `${editionInteger}rd`;
    default:
      return `${editionInteger}th`;
  }
};

export const mcgEhrText = ({
  title,
  mcgSessionData,
  selectionName,
  edition
}) => {
  return {
    summaryText: `The ${title} guideline, Informed by MCG, was utilized to verify that the patient ${
      mcgSessionData.isMet ? "met" : "did not meet"
    } indications for the ${selectionName || "selected"} level of care.`,
    title: `${title} Guideline`,
    subtitle: `Based on thorough history, physical exam, diagnostics, and treatment of the patient, the following indications were found to be present at the time of admission.`,
    copyright: `This document includes content which is the copyrighted, proprietary information of ${editionText(
      edition
    )} edition MCG Health, LLC. All Rights Reserved.`
  };
};
export const mcgPEText = ({ edition, title }) => {
  return {
    summaryText: `The ${title} guideline, Informed by MCG, was used to determine the appropriate bed status and level of care for your stay in the hospital.`,
    title: `Admission Decision`,
    subtitle: `The following criteria were consulted in determining your appropriate level of care and bed status.`,
    copyright: `This document includes content which is the copyrighted, proprietary information of ${editionText(
      edition
    )} edition MCG Health, LLC. All Rights Reserved.`
  };
};

export const fetchCitationApi = ({ citation, token }) => {
  return makeProxyEcRequest({
    method: "GET",
    location: `imcg/api/${citation.Link.split("api/")[1]}`,
    token
  });
};

export const fetchGuidelineStatusApi = ({
  hsim,
  edition = 23,
  indicationIds,
  token
}) => {
  return makeProxyEcRequest({
    method: "POST",
    location: `imcg/api/GA/${edition}/ISC/Indications/${hsim}`,
    token,
    body: {
      Indication:
        indicationIds && indicationIds.length > 0 ? [...indicationIds] : ""
    }
  });
};

export const fetchGuidelineSections = ({ token }) => {
  return makeProxyEcRequest({
    method: "GET",
    location: "imcg/api/GA/23/ISC/guidelineContent",
    token
  });
};

export const formattedIndication = content => {
  const indication = content.Text && content.Text.replace(/\[\[(.*?)\]\]/g, "");
  return {
    indicationText: indication
  };
};

// this is generator function
// instead of using a function that returns and stops execution,
// to traverse the nested object, a generator is nice because
// it can use yield to return a value and continue executing to
// yield more values as it continues traversing the object.
function* traverse(obj, level = 0) {
  for (let objKeyIndex of Object.keys(obj)) {
    yield { value: obj[objKeyIndex], level, index: objKeyIndex };
    if (Array.isArray(obj[objKeyIndex])) {
      const filteredObj = obj[objKeyIndex].filter(
        obj => obj.Status === "Met" || obj.Selected
      );
      if (filteredObj.length) {
        yield* traverse(filteredObj, level + 1);
      } else if (objKeyIndex === "Definitions") {
        yield* traverse(obj[objKeyIndex][0], level + 1);
      }
    } else if (
      obj[objKeyIndex] !== null &&
      typeof obj[objKeyIndex] === "object"
    ) {
      // going one step down in the object tree!!
      yield* traverse(obj[objKeyIndex], level + 1);
    }
  }
}

export const getIndication = guideline => {
  const generateText = (text, textToAdd, level, levels) => {
    const { indicationText = "" } = formattedIndication(textToAdd);

    if (!text.length) {
      return `${indicationText}\n`;
    } else {
      const withinList = text.startsWith("-");
      // levels comes in a set (unique) of levels
      // they need to be sorted in order to make sure we are getting an accurate level
      // it's possible that the insertion order is correct, but sorting to make sure
      let trueLevel = [...levels].sort((a, b) => a - b).indexOf(level);
      const convertSpacesFromLevel = trueLevel * 4;
      const indentLevel = ` `.repeat(convertSpacesFromLevel);
      const newText = `${
        withinList ? text : `- ${text}`
      }${indentLevel}- ${indicationText}\n `;
      return newText;
    }
  };
  const generateTextIndications = items => {
    // want the array to be each starting point for a nested ContentItem
    const filteredIndications = items.filter(
      item => item.Selected || item.Status === "Met"
    );
    const indications = filteredIndications.reduce((acc, item) => {
      if (item.Selected && !item.Definitions) {
        return [
          ...acc,
          {
            id: item.Id,
            text: generateText("", item)
          }
        ];
      } else {
        let text = "";
        let levels = new Set();
        if ((item.Status && item.Status === "Met") || item.Selected) {
          levels.add(0);
          text = generateText(text, item, 0, levels);
          if (item.Status === "Met" || item.Definitions) {
            for (const { value, level } of traverse(item)) {
              if ((value.Status && value.Status === "Met") || value.Selected) {
                levels.add(level);
                text = generateText(text, value, level, levels);
              }
            }
          }
        }
        return [
          ...acc,
          {
            id: item.Id,
            text
          }
        ];
      }
    }, []);
    return indications;
  };

  return {
    text: formattedIndication(guideline).indicationText,
    indications: generateTextIndications(guideline.ContentItems)
  };
};
