import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Checkbox from "components/ui/Checkbox";
import RichTextMdEditor from "utility/richTextMdEditor";
import MarkdownRender from "utility/markdownRender";
import {
  setCustomOutputItemProp,
  setCustomOutputItemSelectedState
} from "store/session";
import CitationList from "components/protocol/protocolRightCol/moreInfo/moreInfoItems/CitationList";
import dotProp from "dot-prop";
import classNames from "classnames";
import CommentBubble from "components/protocol/comments/CommentBubble";

class OutputItemEditable extends Component {
  state = {
    patientEdItemText: "",
    isEditing: false
  };

  handleSelect = ({ target }) => {
    const {
      item,
      setCustomOutputItemSelectedState,
      customItemState
    } = this.props;
    const itemIsSelected = customItemState
      ? customItemState["isSelected"]
      : true;
    setCustomOutputItemSelectedState({
      outputId: "",
      outputItemId: item.id,
      isSelected: !itemIsSelected
    });
  };

  handleSubmit = () => {
    const { item, setCustomOutputItemProp, customItemState } = this.props;
    const { patientEdItemText } = this.state;
    const isSelected = customItemState ? customItemState["isSelected"] : true;
    setCustomOutputItemProp({
      outputId: "",
      outputItemId: item.id,
      prop: "patientEdItemText",
      value: patientEdItemText,
      isSelected
    });
    this.setState({ isEditing: false });
  };

  handleSetMarkdown = text => {
    this.setState({ patientEdItemText: text });
  };

  render() {
    const {
      item,
      customItemState,
      updatedTextFromSession,
      itemText
    } = this.props;
    const { isEditing } = this.state;
    const text = updatedTextFromSession || item.text || itemText;
    const isSelected = customItemState ? customItemState["isSelected"] : true;
    const indicatorClasses = classNames("output-item_text", {
      "output-item_text--selected": isSelected,
      "": !isSelected
    });
    const pencilIconClasses = classNames("icon-pencil", {
      "icon-pencil--not-editable": !isSelected
    });
    const internalName = dotProp.get(
      item,
      "usage.calculator.title",
      this.props.internalName || item.internalName
    );
    return (
      <div className="output-item_container row align-middle collapse">
        <div className="columns shrink">
          <Checkbox
            checkboxClassName={"output-item_text-checkbox"}
            isSelected={isSelected}
            onChange={this.handleSelect}
          />
          <CommentBubble
            locationDescription={`Type: ${
              this.props.outputItemType
            }, Selection: ${dotProp.get(
              item,
              "selection.displayName",
              "NA"
            )} ${dotProp.get(item, "usage.calculator.title", "")}`}
            internalName={internalName}
            usageId={dotProp.get(item, "usage.id", "")}
            style={{
              margin: "0px 5px 0 12px",
              position: "relative",
              top: "-3px"
            }}
            itemId={item.id}
            type="patientid"
            condensed
          />
        </div>
        <div className="columns align-self-middle">
          <Fragment>
            {isEditing ? (
              <Fragment>
                <div className="output-item_markdown-container">
                  <RichTextMdEditor
                    markdown={text}
                    setMarkdown={this.handleSetMarkdown}
                  />
                  <button
                    className="output-item_edit-save"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Fragment>
            ) : (
              <div className={indicatorClasses}>
                <MarkdownRender softBreak="br" source={text} escapeHtml />
              </div>
            )}
          </Fragment>
        </div>
        {item.type !== "calculatorusage" && (
          <div className="columns  align-self-middle shrink">
            <div
              className={`output-icon ${pencilIconClasses}`}
              onClick={() =>
                this.setState({ isEditing: !this.state.isEditing })
              }
            />
          </div>
        )}

        {item.citations && item.citations.length > 0 && (
          <div className="output_citations-container">
            <CitationList citations={item.citations} />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const customItemState = dotProp.get(
    state,
    `session.customOutputItems.${props.item.id}`
  );
  const updatedTextFromSession =
    customItemState &&
    (customItemState.patientEdItemText || customItemState.text);
  return {
    customItemState,
    updatedTextFromSession
  };
};

const mapDispatchToProps = {
  setCustomOutputItemSelectedState,
  setCustomOutputItemProp
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutputItemEditable);

OutputItemEditable.propTypes = {
  item: PropTypes.object.isRequired,
  itemText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setCustomOutputItemSelectedState: PropTypes.func.isRequired,
  setCustomOutputItemProp: PropTypes.func.isRequired,
  customItemState: PropTypes.object,
  outputItemType: PropTypes.string,
  internalName: PropTypes.string,
  updatedTextFromSession: PropTypes.string
};
