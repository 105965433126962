import PropTypes from "prop-types";
import React, { Component } from "react";

export class ButtonLoader extends Component {
  render() {
    const { text, isLoading, isDisabled, onclick } = this.props;
    return (
      <div className="button-loader-wrapper">
        <button
          className="button button-primary button-loader-button"
          disabled={isDisabled || isLoading}
          onClick={() => onclick && onclick()}
        >
          {text && isLoading === false && (
            <p className="button-loader-button_text">{text}</p>
          )}
          {isLoading && (
            <div className="button-loader_spinner">
              <div className="button-loader_spinner_spinner-icon" />
            </div>
          )}
        </button>
      </div>
    );
  }
}

ButtonLoader.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onclick: PropTypes.func
};

export default ButtonLoader;
