export const PROTOCOL_TYPE_CATEGORY = "criteria.Protocol Type";
export const PROTOCOLS = "Protocols";
export const ANTIBIOTIC_ADVISOR = "Preferred Antibiotics";
export const CALCULATORS = "Calculators";
export const MEDICATIONS = "Medications";
export const ADMISSION = "Admission Advisor";
export const IMAGING_ADVISOR = "Imaging Advisor";

export const PROTOCOL_CRITERIA_TYPES = {
  PROTOCOLS,
  ANTIBIOTIC_ADVISOR,
  IMAGING_ADVISOR,
  ADMISSION,
  CALCULATORS,
  MEDICATIONS
};

export const criteriaCatNameMap = {
  [PROTOCOLS]: "Protocols",
  [ANTIBIOTIC_ADVISOR]: "Antibiotic Advisor",
  [IMAGING_ADVISOR]: "Imaging Advisor",
  [MEDICATIONS]: "Medications",
  [CALCULATORS]: "Calculators",
  [ADMISSION]: "Admission Advisor"
};

export const resultsCriteriaMap = {
  [PROTOCOLS]: "protocol",
  [ANTIBIOTIC_ADVISOR]: "antibiotic",
  [MEDICATIONS]: "medicationprofile",
  [CALCULATORS]: "calculator",
  [ADMISSION]: "admission",
  [IMAGING_ADVISOR]: "imaging",
  ALL: "all"
};

// types returned from the omni options API
export const omniResultsCriteriaType = {
  [PROTOCOLS]: "protocol",
  [ANTIBIOTIC_ADVISOR]: "antibiotic",
  [MEDICATIONS]: "medicationprofile",
  [CALCULATORS]: "calculator"
};
// hides certain criteria categories from left colulm criteria filters
// on start page
export const PROTOCOL_CRITERIA_CATEGORIES_TO_HIDE = [
  "Protocol Type",
  "test",
  "ZTest"
];
