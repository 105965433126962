import React, { Component } from "react";
import { OUTPUT_TAB } from "store/session";
import PropTypes from "prop-types";
import classNames from "classnames";

export class EndOfBranch extends Component {
  render() {
    const { endOfBranchSectionId, openOutputTab } = this.props;
    const statusMessage = endOfBranchSectionId
      ? "Protocol Complete"
      : "Protocol In-progress";
    const statusIconClasses = endOfBranchSectionId
      ? "icon-check-bold end-of-protocol_status-icon end-of-protocol_status-icon--complete"
      : "icon-pending end-of-protocol_status-icon end-of-protocol_status-icon--pending";
    const endofProtocolClasses = classNames(
      "row",
      "collapse",
      "end-of-protocol",
      "align-middle",
      {
        "end-of-protocol--complete": endOfBranchSectionId,
        "end-of-protocol--in-progress": !endOfBranchSectionId
      }
    );
    const messageHeader = endOfBranchSectionId ? (
      <div className="end-of-protocol_jump-header">Jump to:</div>
    ) : (
      <div className="end-of-protocol_jump-header-progress">
        Take action on{" "}
        <span
          onClick={openOutputTab(OUTPUT_TAB.ORDERS)}
          className="end-of-protocol_link"
        >
          {" "}
          orders
        </span>
        ,
        <span
          onClick={openOutputTab(OUTPUT_TAB.PE)}
          className="end-of-protocol_link"
        >
          {" "}
          patient education
        </span>
        , or{" "}
        <span
          onClick={openOutputTab(OUTPUT_TAB.EHR)}
          className="end-of-protocol_link"
        >
          {" "}
          documentation
        </span>{" "}
        in the right column anytime during the decision process.
      </div>
    );
    return (
      <div className={endofProtocolClasses}>
        <div className="columns small-12 medium-4 end-of-protocol_message">
          <div className={statusIconClasses} />
          <div className="end-of-protocol_message-text">{statusMessage}</div>
        </div>

        <div className="end-of-protocol_jump-to-section columns  small-12 medium-8 text-center">
          {messageHeader}
          {endOfBranchSectionId && (
            <div className="end-of-protocol_jump-to-button-row row align-middle align-center">
              <div
                className="columns shrink end-of-protocol_option-col column"
                onClick={openOutputTab(OUTPUT_TAB.PE)}
                data-flow-end-protocol-option="patient-edu"
              >
                <span className="icon-patient-edu end-of-protocol_icon" />
                Patient Education
              </div>
              <div
                className="columns shrink end-of-protocol_option-col column"
                onClick={openOutputTab(OUTPUT_TAB.EHR)}
                data-flow-end-protocol-option="ehr-docs"
              >
                <span className="icon-ehr-doc end-of-protocol_icon" />
                EHR Documentation
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

EndOfBranch.propTypes = {
  openOutputTab: PropTypes.func,
  endOfBranchSectionId: PropTypes.string
};

export default EndOfBranch;
