// ------------------------------------
// Public Selectors
// ------------------------------------

export function selectAdmin({ admin }) {
  return admin;
}

export function selectSherpaDraftMode(state) {
  return selectAdmin(state).sherpaDraftMode;
}
