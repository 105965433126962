import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import McgIndicationsComponent from "./McgIndicationsComponent";
import {
  unselectIndication,
  addToFetchingQueue,
  fetchMcgGuideline,
  fetchMcgGuidelineStatus
} from "../mcgReducer";

import { getMcgContext } from "../mcgSelectors";

class McgIndicationsContainer extends React.Component {
  static propTypes = {
    doesGuidelineExist: PropTypes.bool.isRequired,
    guidelineWithStatus: PropTypes.array.isRequired,
    context: PropTypes.object.isRequired,
    selectedGuideline: PropTypes.object.isRequired,
    selectedIndicationIds: PropTypes.array,
    isCheckingStatus: PropTypes.bool.isRequired,
    isFetchingGuideline: PropTypes.bool,
    fetchMcgGuideline: PropTypes.func.isRequired,
    fetchMcgGuidelineStatus: PropTypes.func.isRequired,
    indicationIdsBeingFetched: PropTypes.array,
    unselectIndication: PropTypes.func,
    addToFetchingQueue: PropTypes.func
  };
  componentDidMount() {
    const {
      selectedGuideline = {},
      fetchMcgGuideline,
      isCheckingStatus,
      fetchMcgGuidelineStatus
    } = this.props;
    const indicationIds = selectedGuideline.selections;

    if (!isCheckingStatus) {
      if (indicationIds) {
        fetchMcgGuidelineStatus({
          hsim: selectedGuideline.hsim,
          indicationIds,
          edition: selectedGuideline.edition
        });
        // if isCheckingStatus === true then the session will populate the guideline
      } else {
        fetchMcgGuideline({ hsim: selectedGuideline.hsim });
      }
    }
  }
  handleCheckMcg = ({ indicationId, parentIndicationId, level }) => {
    // make call to indication guideline check with indication ID on every check
    const {
      selectedIndicationIds,
      unselectIndication,
      addToFetchingQueue,
      fetchMcgGuidelineStatus
    } = this.props;
    const { selectedGuideline } = this.props;
    parentIndicationId && addToFetchingQueue({ parentIndicationId });
    const isIndicationAlreadySelected = selectedIndicationIds.includes(
      indicationId
    );
    const indicationIdsToFetchWith = isIndicationAlreadySelected
      ? selectedIndicationIds.filter(
          selectedIndicationId => selectedIndicationId !== indicationId
        )
      : [...selectedIndicationIds, indicationId];
    fetchMcgGuidelineStatus({
      hsim: selectedGuideline.hsim,
      indicationIds: indicationIdsToFetchWith
    });
    if (isIndicationAlreadySelected) {
      unselectIndication({ indicationId });
    }
  };
  shouldRenderMcgIndications = () => {
    const {
      guidelineWithStatus,
      isFetchingGuideline,
      selectedIndicationIds,
      indicationIdsBeingFetched,
      selectedGuideline,
      isCheckingStatus
    } = this.props;
    return isFetchingGuideline ? (
      <p className="mcg-loading-data">Loading Indications...</p>
    ) : (
      <McgIndicationsComponent
        handleCheckMcg={this.handleCheckMcg}
        isCheckingStatus={isCheckingStatus}
        guidelineWithStatus={guidelineWithStatus}
        selectedIndicationIds={selectedIndicationIds}
        indicationIdsBeingFetched={indicationIdsBeingFetched}
        selectedGuideline={selectedGuideline}
      />
    );
  };
  render() {
    const {
      doesGuidelineExist,
      context = {},
      selectedGuideline = {},
      isCheckingStatus,
      isFetchingGuideline
    } = this.props;
    return (
      <div className="mcg-guideline-wrapper">
        {doesGuidelineExist || isCheckingStatus || isFetchingGuideline ? (
          this.shouldRenderMcgIndications()
        ) : (
          <p className="">
            No Indications exist for{" "}
            {context[selectedGuideline.hsim] &&
              context[selectedGuideline.hsim].title}
            . Please select another ISC guideline.
          </p>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    guidelineWithStatus: state.mcg.guidelineWithStatus,
    context: getMcgContext(state),
    doesGuidelineExist: state.mcg.doesGuidelineExist,
    selectedIndicationIds: state.mcg.selectedIndicationIds,
    indicationIdsBeingFetched: state.mcg.indicationIdsBeingFetched,
    isCheckingStatus: state.mcg.isCheckingStatus,
    isFetchingGuideline: state.mcg.isFetchingGuideline
  };
};

const mapDispatchToProps = {
  fetchMcgGuideline,
  addToFetchingQueue,
  unselectIndication,
  fetchMcgGuidelineStatus
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McgIndicationsContainer);
