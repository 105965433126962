import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Question from "./question/Question";
import EvidenceSummary from "./evidenceFolder/EvidenceSummary";
import Calculators from "./calculator/Calculators";
import { CareDecision } from "./careDecision/CareDecision";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { clearActiveMoreInfo } from "store/protocol";
import { setOutputTab } from "store/session";

export class Section extends Component {
  openOutputTab = outputTab => {
    return () => {
      this.props.clearActiveMoreInfo();
      this.props.setOutputTab({ outputTabName: outputTab });
    };
  };
  render() {
    const {
      activeSelectedOutputs,
      relatedSections,
      usages,
      section,
      sectionVersionId,
      careFolders,
      setActiveGroup
    } = this.props;
    // add to selector
    const shouldShowCalcTabs =
      usages.calculatorusage.length > 1 ||
      (usages.calculatorusage.length > 0 && usages.questionusage.length > 0);
    return (
      <div>
        {section.subtitle && <p>{section.subtitle}</p>}

        <div className="section-folder_container">
          {section.evidenceFolders.length > 0 && (
            <div className="section-folder">
              <EvidenceSummary
                folders={section.evidenceFolders}
                evidenceUsages={usages.evidenceusage}
              />
            </div>
          )}

          {careFolders.length > 0 && (
            <div className="section-folder">
              <CareDecision
                careFolders={careFolders}
                openedFromClick={this.props.openedFromClick}
                careDecisionCanLeadToNextSection={
                  this.props.careDecisionCanLeadToNextSection
                }
              />
            </div>
          )}

          {usages.calculatorusage.length === 0 &&
            usages.questionusage.reduce((acc, questionUsage) => {
              const shouldShow =
                questionUsage.criteria.length === 0
                  ? true
                  : questionUsage.criteria.some(criteria => {
                      return Object.keys(activeSelectedOutputs).some(
                        outputId =>
                          activeSelectedOutputs[outputId].selectionId ===
                          criteria.selectionId
                      );
                    });

              shouldShow &&
                acc.push(
                  <div key={questionUsage.id} className="section-folder">
                    <Question
                      key={questionUsage.id}
                      questionUsage={questionUsage}
                      outputs={section.outputs}
                      usageId={questionUsage.id}
                      section={section}
                      sectionVersionId={sectionVersionId}
                      question={questionUsage.question}
                      relatedSections={relatedSections}
                      setActiveGroup={setActiveGroup}
                    />
                  </div>
                );
              return acc;
            }, [])}
          {usages.calculatorusage.length === 1 &&
            usages.questionusage.length === 0 && (
              <div className="section-folder">
                <Calculators
                  calculatorUsages={usages.calculatorusage}
                  selections={section.selections}
                  outputs={section.outputs}
                  questions={usages.questionusage}
                  sectionVersionId={sectionVersionId}
                  section={section}
                  shouldShowCalcTabs={shouldShowCalcTabs}
                  relatedSections={relatedSections}
                  setActiveGroup={setActiveGroup}
                />
              </div>
            )}
        </div>
        {shouldShowCalcTabs && (
          <Calculators
            calculatorUsages={usages.calculatorusage}
            selections={section.selections}
            outputs={section.outputs}
            questions={usages.questionusage}
            sectionVersionId={sectionVersionId}
            section={section}
            shouldShowCalcTabs={shouldShowCalcTabs}
            relatedSections={relatedSections}
            setActiveGroup={setActiveGroup}
          />
        )}
      </div>
    );
  }
}

Section.propTypes = {
  activeSelectedOutputs: PropTypes.array,
  setOutputTab: PropTypes.func.isRequired,
  clearActiveMoreInfo: PropTypes.func,
  relatedSections: PropTypes.object,
  sectionVersionId: PropTypes.string,
  usages: PropTypes.object,
  section: PropTypes.object,
  careFolders: PropTypes.array,
  openedFromClick: PropTypes.bool,
  careDecisionCanLeadToNextSection: PropTypes.bool,
  setActiveGroup: PropTypes.func
};
const makeMapStateToProps = () => {
  const getSelectedOutputs = makeSelectedOutputs();
  const mapStateToProps = state => {
    return {
      activeSelectedOutputs: getSelectedOutputs(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = { setOutputTab, clearActiveMoreInfo };
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Section);
