import React, { Component } from "react";
import classnames from "classnames";
import McgDefinition from "./McgDefinition";
import PropTypes from "prop-types";
import IndicationAccordian from "./IndicationAccordian";
import McgContentItem from "./McgContentItem";

export class McgContentItems extends Component {
  render() {
    const {
      contentItems,
      level,
      isInDefinition,
      handleCheckMcg,
      selectedIndicationIds,
      isNested,
      indicationIdsBeingFetched,
      isCheckingStatus,
      definitionTitle,
      parentId
    } = this.props;
    const isMostNestedItems = contentItems.every(
      contentItem => !contentItem.Status
    );
    return (
      contentItems &&
      contentItems.length &&
      contentItems.map(item => {
        const indicationContainerClasses = classnames(
          "indication-container",
          `indication-container--${level}`,
          {
            "indication-container--definition": isInDefinition
          }
        );
        const indicationItemClasses = classnames("indication-items", {
          "indication-items--nested": isNested,
          "indication-items--children": isMostNestedItems
        });
        const indicationTitleClasses = classnames("indication-title", {
          "indication-title--nested": isNested
        });
        const contentItemContainerClasses = classnames(
          "mcg-content-container",
          `mcg-content-container--${level}`,
          {
            "mcg-content-container--fetching": this.props.isCheckingStatus
          }
        );
        const shouldBeSelected =
          selectedIndicationIds &&
          selectedIndicationIds.filter(
            selectedIndicationId => selectedIndicationId === item.Id
          ).length > 0;
        if (item.ContentItems && item.ContentItems.length > 0) {
          return (
            <div
              data-key={item.Id}
              key={item.Id}
              className={indicationContainerClasses}
            >
              <IndicationAccordian
                listLogic={item.ListLogic}
                isCheckingStatus={
                  indicationIdsBeingFetched &&
                  indicationIdsBeingFetched.includes(item.Id) &&
                  isCheckingStatus
                }
                item={item}
                definitionTitle={definitionTitle}
              >
                <div className={contentItemContainerClasses}>
                  <McgContentItems
                    contentItems={item.ContentItems}
                    level={level + 1}
                    handleCheckMcg={handleCheckMcg}
                    selectedIndicationIds={selectedIndicationIds}
                    isNested={isNested}
                    isCheckingStatus={isCheckingStatus}
                    indicationIdsBeingFetched={indicationIdsBeingFetched}
                    parentId={item.Id}
                  />
                </div>
              </IndicationAccordian>
            </div>
          );
        } else if (item.Definitions) {
          const textIsSame = item.Text === item.Definitions[0].Title;
          return textIsSame ? (
            <McgDefinition
              key={item.Id}
              indicationContainerClasses={`${indicationContainerClasses} ${indicationContainerClasses}--${level}`}
              indicationItemClasses={indicationItemClasses}
              isNested={isNested}
              shouldBeSelected={shouldBeSelected}
              indicationTitleClasses={indicationTitleClasses}
              item={item}
              handleCheckMcg={handleCheckMcg}
              selectedIndicationIds={selectedIndicationIds}
              isCheckingStatus={this.props.isCheckingStatus}
              indicationIdsBeingFetched={this.props.indicationIdsBeingFetched}
              parentId={item.Id}
              level={level}
            />
          ) : (
            <div
              data-key={item.Id}
              key={item.Id}
              className={indicationContainerClasses}
            >
              <IndicationAccordian
                isCheckingStatus={
                  indicationIdsBeingFetched &&
                  indicationIdsBeingFetched.includes(item.Id) &&
                  isCheckingStatus
                }
                item={item}
                handleCheckMcg={handleCheckMcg}
                level={level}
                parentId={parentId}
                withCheckbox
              >
                <div className={contentItemContainerClasses}>
                  <McgDefinition
                    key={item.Id}
                    indicationContainerClasses={`${indicationContainerClasses} ${indicationContainerClasses}--${level +
                      1}`}
                    indicationItemClasses={indicationItemClasses}
                    isNested={isNested}
                    shouldBeSelected={shouldBeSelected}
                    indicationTitleClasses={indicationTitleClasses}
                    item={item}
                    handleCheckMcg={handleCheckMcg}
                    selectedIndicationIds={selectedIndicationIds}
                    isCheckingStatus={this.props.isCheckingStatus}
                    indicationIdsBeingFetched={
                      this.props.indicationIdsBeingFetched
                    }
                    parentId={item.Id}
                    level={level + 1}
                  />
                </div>
              </IndicationAccordian>
            </div>
          );
        } else {
          return (
            <McgContentItem
              key={item.Id}
              item={item}
              level={level}
              shouldBeSelected={shouldBeSelected}
              indicationContainerClasses={indicationContainerClasses}
              indicationItemClasses={indicationItemClasses}
              indicationTitleClasses={indicationTitleClasses}
              handleCheckMcg={handleCheckMcg}
              parentId={parentId}
              isCheckingStatus={isCheckingStatus}
            />
          );
        }
      })
    );
  }
}

McgContentItems.propTypes = {
  contentItems: PropTypes.array,
  level: PropTypes.number,
  isInDefinition: PropTypes.bool,
  handleCheckMcg: PropTypes.func,
  selectedIndicationIds: PropTypes.array,
  isNested: PropTypes.bool,
  isCheckingStatus: PropTypes.bool,
  indicationIdsBeingFetched: PropTypes.array,
  definitionTitle: PropTypes.string,
  parentId: PropTypes.string
};

export default McgContentItems;
