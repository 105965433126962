import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { error } from "react-notification-system-redux";
import classNames from "classnames";

import { EVENT_FEATURE_SUBSCRIPTIONS_CHANGE_VIEW } from "constants/broadcastEventTypes";
import { fetchProducts, updateSubscription } from "store/account";
import MarkdownRender from "utility/markdownRender";
import productFeatures from "constants/plans";
import {
  isCore,
  isCanceledSubscription,
  isMonthlySubscription,
  calculatePrice,
  calculateDiscountPrice
} from "helpers/subscriptions";
import { pipelineRequest } from "store/pipeline";
import { selectChargifySignupURL } from "store/user/selectors";

export class ChangePlan extends Component {
  componentDidMount() {
    const { products } = this.props;
    if (!products.length) {
      this.props.fetchProducts();
    }
    this.props.pipelineRequest({
      action: EVENT_FEATURE_SUBSCRIPTIONS_CHANGE_VIEW
    });
  }

  isFreePlan = plan => {
    return plan.name === "CORE";
  };

  isCurrentPlan = (plan, subscription) => {
    return (
      plan.product.handle === subscription.product.handle &&
      !isCanceledSubscription(subscription)
    );
  };

  movePlan = (plan, subscription) => {
    this.props.updateSubscription({
      handle: plan.product.handle,
      id: subscription.id,
      action: "move_delayed"
    });
  };

  cancelPlan = () => {
    const { subscription } = this.props;
    this.props.updateSubscription({
      id: subscription.id,
      action: "cancel_delayed"
    });
  };

  shouldDowngrade = (subscription, plan) => {
    // free or monthly cta should be 'downgrade' if free or if monthly and current plan is annual
    return (
      subscription &&
      !isCore(subscription) &&
      !isCanceledSubscription(subscription) &&
      (this.isFreePlan(plan) ||
        (!this.isCurrentPlan(plan, subscription) &&
          !isMonthlySubscription(subscription)))
    );
  };

  shouldUpgrade = (subscription, plan) => {
    // annual cta should be 'upgrade' if current plan is monthly
    return (
      subscription &&
      !this.isFreePlan(plan) &&
      !isCanceledSubscription(subscription) &&
      !this.isCurrentPlan(plan, subscription) &&
      isMonthlySubscription(subscription)
    );
  };

  renderCTA = plan => {
    const { subscription, chargifySignUpUrl } = this.props;
    // a lot of logic here, could potentially be simplified or handled through helpers
    const isFree = this.isFreePlan(plan);
    const isCanceled = subscription && isCanceledSubscription(subscription);
    const isCurrentFree =
      isFree && subscription && (isCanceled || isCore(subscription));
    const isCurrentNotFree =
      !isFree &&
      (subscription && this.isCurrentPlan(plan, subscription) && !isCanceled);
    const isCurrent = isCurrentFree || isCurrentNotFree;
    const shouldContactSupport = !isFree && isCanceled;

    return (
      <div className="plan-column_footer">
        {isCurrent && (
          <span className="plan-column_cta--current">Current Plan</span>
        )}
        {subscription && isCore(subscription) && !this.isFreePlan(plan) && (
          <a
            href={chargifySignUpUrl(plan.product.public_signup_pages[0].url)}
            className="button button-secondary"
          >
            Join Plan
          </a>
        )}
        {this.shouldDowngrade(subscription, plan) && (
          <button
            onClick={
              isFree ? this.cancelPlan : () => this.movePlan(plan, subscription)
            }
            className="button button-gray"
          >
            Downgrade Plan
          </button>
        )}
        {this.shouldUpgrade(subscription, plan) && (
          <button
            onClick={() => this.movePlan(plan, subscription)}
            className="button button-secondary"
          >
            Upgrade Plan
          </button>
        )}
        {shouldContactSupport && (
          <a
            href="mailto:support@evidence.care"
            className="button button-secondary"
          >
            Contact Support
          </a>
        )}
      </div>
    );
  };

  renderPrice = product => {
    const { membership } = this.props;
    const hasCoupon = membership && membership.discount_amount > 0;
    const priceClasses = classNames("plan-column_price", {
      "plan-column_price--discounted": hasCoupon
    });
    return (
      <div>
        <span className={priceClasses}>
          {this.isFreePlan(product)
            ? product.price
            : `$${calculatePrice(
                product.product.price,
                product.product.interval,
                product.product.interval_unit
              )}`}
        </span>
        {hasCoupon && (
          <span className="plan-column_price">
            <br />
            {`$${calculatePrice(
              calculateDiscountPrice(
                product.product.price,
                membership.discount_amount
              ),
              product.product.interval,
              product.product.interval_unit
            )}`}
          </span>
        )}
      </div>
    );
  };

  renderProduct = product => {
    return (
      <div className="plan-column">
        <div className="plan-column_header">
          <h3 className="plan-column_title">
            {product.product ? product.product.name : product.name}
          </h3>
          {this.renderPrice(product)}
        </div>
        <div className="plan-column_features">
          <MarkdownRender source={product.features} />
          {product.excludedFeatures && (
            <MarkdownRender
              className="plan-column_features--excluded"
              source={product.excludedFeatures}
            />
          )}
        </div>
        {this.renderCTA(product)}
      </div>
    );
  };
  renderProducts = () => {
    const { membership, products } = this.props;
    return products.map(product => {
      return (
        <div className="columns small-12 medium-4" key={product.product.name}>
          {this.renderProduct(product, membership)}
        </div>
      );
    });
  };

  render() {
    const freeProduct = productFeatures["core"];
    return (
      <div className="plans-row row">
        <div className="columns small-12 medium-4">
          {this.renderProduct(freeProduct)}
        </div>
        {this.renderProducts()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  products: state.account.products || [],
  chargifySignUpUrl: url => selectChargifySignupURL(state, { url })
});

const mapDispatchToProps = {
  fetchProducts,
  updateSubscription,
  error,
  pipelineRequest
};

ChangePlan.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  products: PropTypes.array,
  pipelineRequest: PropTypes.func,
  fetchProducts: PropTypes.func,
  membership: PropTypes.object,
  updateSubscription: PropTypes.func,
  chargifySignUpUrl: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePlan);
