import React, { Component } from "react";
import PropTypes from "prop-types";
import { history as browserHistory } from "myHistory";
import {
  EVIDENCE_CARE_PHONE_SUPPORT,
  EVIDENCE_CARE_EMAIL_SUPPORT
} from "constants/contact";
import { hideModal } from "store/modal/actions";

const contactUs = (
  <p>
    Feel free to contact us at {EVIDENCE_CARE_PHONE_SUPPORT} or{" "}
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`mailto:${EVIDENCE_CARE_EMAIL_SUPPORT}?subject=Error&body=An error occurred on the page ${window.location.href}`}
    >
      {EVIDENCE_CARE_EMAIL_SUPPORT}
    </a>
  </p>
);

class ErrorModal extends Component {
  constructor(props) {
    super();
    this.errorMessage = props.message || (
      <p>
        Something went wrong on our end, we&apos;ve been notified and are
        currently looking into this issue.
      </p>
    );
    this.navigateHome = () => {
      props.dispatch(hideModal());
      browserHistory.push("/");
    };
    this.refreshPage = () => {
      props.dispatch(hideModal());
      location.reload();
    };
  }
  render() {
    return (
      <div>
        <h4>Error</h4>
        <div>
          {this.errorMessage} {contactUs}
        </div>
        <button onClick={this.navigateHome} className="button button_standard">
          Home
        </button>
        <button
          style={{ marginLeft: "1rem" }}
          className="button button_standard"
          onClick={this.refreshPage}
        >
          Refresh Page
        </button>
      </div>
    );
  }
}
ErrorModal.propTypes = {
  message: PropTypes.string,
  dispatch: PropTypes.func
};

export default ErrorModal;
