import { createSelector } from "reselect";
import R from "ramda";
import createCachedSelector from "re-reselect";

const getSessionOutputs = state => state.session.entities.outputs;
export const getSessionCustomOutputs = state => state.session.customOutputItems;
const getProtocolSections = state => {
  const protocolIds = R.values(state.session.entities.protocols).map(
    pr => pr.id
  );
  const protocols = state.protocol;
  return (
    protocolIds.reduce((acc, prId) => {
      return protocols[prId] && protocols[prId].sections
        ? [...acc, ...R.values(protocols[prId].sections)]
        : acc;
    }, []) || []
  );
};

// const getProtocolSections = (state, props) => (state.session.activeProtocolId &&
//   state.protocol[state.session.activeProtocolId] && state.protocol[state.session.activeProtocolId].sections) || []
const getSession = state => state.session;
const getSessionUsages = state => state.session.entities.usages;
const getSessionSelections = state => state.session.entities.selections;

/**
 * getAllFullActiveOutputs
 */
const isDefaultSectionOutput = output =>
  output.usageId === null && output.selectionId === null;

export const getOutputsByTypeFromProtocol = createCachedSelector(
  protocol => protocol,
  protocol => {
    const outputTypes = {
      ehrs: [],
      faqs: [],
      diagnoses: [],
      orderSets: [],
      resources: [],
      patientEducation: [],
      prescriptionSets: []
    };
    const outputsByType = R.values(protocol.sections).reduce(
      (acc1, section) => {
        const sectionOutputs = section.render.outputs.reduce((acc2, output) => {
          for (let key in outputTypes) {
            acc2[key] = [...acc2[key], ...output[key]];
          }
          return acc2;
        }, acc1);
        return sectionOutputs;
      },
      outputTypes
    );
    return outputsByType;
  }
)(protocol => protocol.id);

export const getOrderStringIdFromProtocol = createCachedSelector(
  protocol => protocol,
  protocol => {
    const outputsByType = getOutputsByTypeFromProtocol(protocol);
    const orderSets = outputsByType["orderSets"];
    return R.uniq(R.chain(orderSet => orderSet.orderStringsIds, orderSets));
  }
)(protocol => protocol.id);

export const getAllFullActiveOutputs = createSelector(
  [getSessionOutputs, getProtocolSections],
  (sessionOutputs, protocolSections) => {
    const activeOutputs = Object.keys(sessionOutputs).reduce(
      (acc, outputId) => {
        if (
          sessionOutputs[outputId].selected ||
          isDefaultSectionOutput(sessionOutputs[outputId])
        ) {
          acc[outputId] = sessionOutputs[outputId];
        }
        return acc;
      },
      {}
    );

    return protocolSections.reduce(
      (acc1, section) => {
        const sectionOutputs = section.render.outputs.reduce(
          (acc2, output) => {
            if (activeOutputs[output.id]) {
              acc2.ehrs.push(...output.ehrs);
              acc2.faqs.push(...output.faqs);
              acc2.diagnoses.push(...output.diagnoses);
              acc2.orderSets.push(...output.orderSets);
              acc2.resources.push(...output.resources);
              acc2.patientEducation.push(...output.patientEducation);
              acc2.prescriptionSets.push(...output.prescriptionSets);
            }
            return acc2;
          },
          {
            ehrs: [],
            faqs: [],
            diagnoses: [],
            orderSets: [],
            resources: [],
            patientEducation: [],
            prescriptionSets: []
          }
        );
        acc1.ehrs.push(...sectionOutputs.ehrs);
        acc1.faqs.push(...sectionOutputs.faqs);
        acc1.diagnoses.push(...sectionOutputs.diagnoses);
        acc1.orderSets.push(...sectionOutputs.orderSets);
        acc1.resources.push(...sectionOutputs.resources);
        acc1.patientEducation.push(...sectionOutputs.patientEducation);
        acc1.prescriptionSets.push(...sectionOutputs.prescriptionSets);
        return acc1;
      },
      {
        ehrs: [],
        faqs: [],
        diagnoses: [],
        orderSets: [],
        resources: [],
        patientEducation: [],
        prescriptionSets: []
      }
    );
  }
);
export const makeGetFullActiveOutputs = () => {
  return getAllFullActiveOutputs;
};

export const getFullActiveSelectedOutputs = createSelector(
  [getSession, getAllFullActiveOutputs],
  (sessionOutputs, fullActiveOutputs) => {
    const arrayOfCustomOutputItemIds = Object.keys(
      sessionOutputs.customOutputItems
    );
    const allFullActiveSelectedOutputs = Object.keys(fullActiveOutputs).reduce(
      (acc, curr) => {
        if (!acc[curr]) {
          acc[curr] = [];
        }
        const handleAddingOutputsToSelected = item => {
          if (!arrayOfCustomOutputItemIds.includes(item.id)) {
            acc[curr].push(item);
          } else if (arrayOfCustomOutputItemIds.includes(item.id)) {
            // if text has been edited on a patient ed customOutputItem, then that needs to be passed along with the item
            const updatedText =
              sessionOutputs.customOutputItems[item.id].patientEdItemText;
            sessionOutputs.customOutputItems[item.id].isSelected &&
              acc[curr].push({ ...item, patientEdItemText: updatedText });
          }
        };

        fullActiveOutputs[curr].forEach((output, index) => {
          //! TODO!
          // sometimes output here being passed down is actually patientEdItem, with nested ouputs
          // need to handle this in cleaner way
          if (
            output.category &&
            (output.category.categoryType === 1 ||
              output.category.categoryType === 2) &&
            output.items
          ) {
            output.items.forEach(item => {
              let { category } = output;
              // have to put category on the output, so when displaying them we know whether after care, assessment, etc
              handleAddingOutputsToSelected({
                ...item,
                categoryType: category.categoryType,
                categoryTitle: category.title,
                categorySubtitle: category.subtitle,
                categoryId: category.id
              });
            });
          } else {
            handleAddingOutputsToSelected(output);
          }
        });
        return acc;
      },
      {}
    );
    return allFullActiveSelectedOutputs;
  }
);
export const makeGetFullActiveSelectedOutputs = () => {
  return getFullActiveSelectedOutputs;
};

export const getSelectedOutputs = createSelector(
  [getSessionOutputs],
  sessionOutputs => {
    const selectedOutputs = R.values(sessionOutputs).reduce((acc, op) => {
      if (op && op.selected) {
        acc[op.id] = op;
      }
      return acc;
    }, []);
    return selectedOutputs;
  }
);
export const makeSelectedOutputs = () => {
  return createSelector(
    [getSessionOutputs],
    sessionOutputs => {
      const selectedOutputs = R.values(sessionOutputs).reduce((acc, op) => {
        if (op && op.selected) {
          acc[op.id] = op;
        }
        return acc;
      }, []);
      return selectedOutputs;
    }
  );
};

export const makeGetFullActiveSelectedCalcInputs = () => {
  return createSelector(
    [getSessionOutputs, getSessionUsages, getSessionSelections],
    (sessionOutputs, sessionUsages, sessionSelections) => {
      const selectedCalcs = Object.keys(sessionOutputs).reduce(
        (acc, outputId) => {
          const output = sessionOutputs[outputId];
          if (
            output.selected &&
            (sessionUsages[output.usageId] &&
              sessionUsages[output.usageId].type === "calculatorusage")
          ) {
            acc.push({
              usage: sessionUsages[output.usageId],
              selection: sessionSelections[output.selectionId]
            });
          }
          return acc;
        },
        []
      );
      return selectedCalcs;
    }
  );
};

export const makeSelectedSelectionIds = () => {
  return createSelector(
    [getSelectedOutputs],
    selectedOutputs => {
      const selectedSections = R.values(selectedOutputs).reduce((acc, sOp) => {
        return !acc.includes(sOp.selectionId) ? [...acc, sOp.selectionId] : acc;
      }, []);
      return selectedSections;
    }
  );
};

export const getSelectedSelectionIds = createSelector(
  [getSelectedOutputs],
  selectedOutputs => {
    const selectedSections = R.values(selectedOutputs).reduce((acc, sOp) => {
      return !acc.includes(sOp.selectionId) ? [...acc, sOp.selectionId] : acc;
    }, []);
    return selectedSections;
  }
);

export const getSelectionDisplayNameById = createCachedSelector(
  (state, selectionId) => state,
  (state, selectionId) => selectionId,
  getSelectedOutputs,
  (state, selectionId, selectedOutputs) => {
    const outputFromSelectionId = R.values(selectedOutputs).find(
      output => output.selectionId === selectionId
    );
    const findSelection =
      outputFromSelectionId &&
      state.protocol[outputFromSelectionId.protocolId] &&
      state.protocol[outputFromSelectionId.protocolId].sections[
        outputFromSelectionId.sectionId
      ].render.selections.find(selection => {
        return selection.id === outputFromSelectionId.selectionId;
      });
    return findSelection ? findSelection.displayName : "";
  }
)((state, selectionId) => `selection:${selectionId}`);

export const getSelectedEhrCustomState = createCachedSelector(
  state => state,
  (state, ehrs) => ehrs,
  (state, ehrs) => {
    return ehrs.reduce((acc, curr) => {
      const customItemState = state.session.customOutputItems[curr.id];
      if (customItemState && customItemState["isSelected"]) {
        // if the user has edited the text of the ehr item, use that text; otherwise, use original text from db
        const itemText = customItemState.patientEdItemText || curr.text;
        const customItem = { ...curr, text: itemText };
        acc.push(customItem);
      } else if (!customItemState) {
        // if there is no customItemState, or if customItemState is not selected send item back as 'selected'
        acc.push(curr);
      }
      return acc;
    }, []);
  }
)((state, ehrs) => `${ehrs.map(ehr => ehr.id)}`);
