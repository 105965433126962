import React from "react";
import { Tooltip } from "components/ui/tooltip";

const CopyToClipboard = ({ handleCopy, copyButton }) => {
  return (
    <Tooltip id="copy-tooltip" message="Copy to clipboard" place="bottom">
      <div onClick={handleCopy}>{copyButton}</div>
    </Tooltip>
  );
};

export default CopyToClipboard;
