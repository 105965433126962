import React from "react";
import PropTypes from "prop-types";
import { find, propEq } from "ramda";

import ManagePrescription from "../rxSets/ManagePrescription";
import OrdersView from "./OrdersView";

const OrderSentView = ({ sentMeds, sentOrders, prescriptionSets, orders }) => {
  const sentPrescriptions = [];

  prescriptionSets.map(set => {
    set.prescriptions.map(prescription => {
      sentPrescriptions.push(prescription);
    });
  });

  return (
    <div className="order-sent-view">
      {Object.keys(sentOrders).length > 0 && <OrdersView orders={orders} />}

      {Object.keys(sentMeds).length > 0 &&
        Object.keys(sentMeds).map((med, index) => {
          const prescription = find(propEq("id", sentMeds[med].object_id))(
            sentPrescriptions
          );

          return prescription ? (
            <div className="prescription-set" key={sentMeds[med].sentId}>
              <div className="prescription-set_group prescription-set_group--open">
                <div className="prescription-set_option-sets">
                  <div className="prescription-set_option-set">
                    <div
                      className="rx-set row collapse align-middle rx-set--large"
                      data-flow-rxset-id={sentMeds[med].object_id}
                    >
                      <div className="columns small-12 rx-set-content">
                        <div className="rx-set_wrap rx-set_prescriptions-col">
                          <div className="rx-set_presciption-wrapper">
                            <ManagePrescription
                              prescription={prescription}
                              key={sentMeds[med].sentId}
                              singleMedMode={false}
                              prescriptionIndex={0}
                              prescriptionSetMode={"PLUS"}
                              isMapped={true}
                              isSent={true}
                              isDraft={false}
                              isDraftReviewer={false}
                              sentAt={sentMeds[med].sent_at}
                              orderMessage={null}
                              prescriptionSetMessage={""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rx-set_context-menu-col align-self-middle"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        })}
    </div>
  );
};

OrderSentView.propTypes = {
  sentOrders: PropTypes.object,
  sentMeds: PropTypes.object,
  prescriptionSets: PropTypes.array,
  orders: PropTypes.array
};

export default OrderSentView;
