import React, { Component } from "react";
import PropTypes from "prop-types";
import PatientEdPrintMarkup from "./PatientEdPrintMarkup";

class PatientEdPrintModal extends Component {
  componentDidMount = () => {
    document.getElementById("root").classList.add("patient-ed-print_hide-root");
  };
  componentWillUnmount = () => {
    document
      .getElementById("root")
      .classList.remove("patient-ed-print_hide-root");
  };

  render() {
    return <PatientEdPrintMarkup {...this.props} />;
  }
}

export default PatientEdPrintModal;

PatientEdPrintModal.propTypes = {
  activeSelectedOutputObjs: PropTypes.object.isRequired,
  togglePrintModal: PropTypes.func,
  facilityLocation: PropTypes.object,
  providerNote: PropTypes.string,
  organizationLogo: PropTypes.string,
  account: PropTypes.object,
  rxSets: PropTypes.array,
  appointment: PropTypes.object,
  userName: PropTypes.string,
  shouldShowMcg: PropTypes.bool,
  mcgIndications: PropTypes.array,
  mcgText: PropTypes.object
};
