import PropTypes from "prop-types";
import React from "react";
import { xsmallImageUrl } from "helpers/urls/cloudinary";

export function BlockEvidence({ data }) {
  const { medicalSociety, ratings, nnt, nnh } = data;
  const isLinked =
    !!medicalSociety && medicalSociety.url && medicalSociety.cloudinaryId;
  const isNotLinked =
    !!medicalSociety && medicalSociety.cloudinaryId && !medicalSociety.url;
  return (
    <div className="block_topItems">
      <div className="row collapse">
        {!!medicalSociety && (
          <TopItem>
            {(isLinked && (
              <a
                href={medicalSociety.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={xsmallImageUrl(medicalSociety.cloudinaryId)} />
              </a>
            )) ||
              null}
            {(isNotLinked && (
              <img src={xsmallImageUrl(medicalSociety.cloudinaryId)} />
            )) ||
              null}
          </TopItem>
        )}
        {ratings &&
          ratings.map(rating => (
            <TopItem half key={rating.id}>
              <MoreInfoRating
                rating={rating}
                subheader={
                  rating.ratingType === "evidence"
                    ? "Quality of Evidence"
                    : "Strength of Recommendation"
                }
              />
            </TopItem>
          ))}
        {!!nnt && (
          <TopItem>
            <span className="topItems-nnt">nnt {nnt}</span>
          </TopItem>
        )}
        {!!nnh && (
          <TopItem>
            <span className="topItems-nnh">nnh {nnh}</span>
          </TopItem>
        )}
      </div>
    </div>
  );
}

export function TopItem({ children, half }) {
  return (
    <div
      className={`block_topItems-item ${
        half ? "block_topItems-item--half" : ""
      }`}
    >
      {children}
    </div>
  );
}

TopItem.propTypes = {
  children: PropTypes.element,
  half: PropTypes.string
};

export function MoreInfoRating({ rating, subheader }) {
  return (
    <div className="topItems-rating">
      {rating && (
        <div title={rating.suggestedWording}>
          <div className="topItems-rating_header">{rating.ratingValue}</div>
          <div className="topItems-rating_subheader">{subheader}</div>
        </div>
      )}
    </div>
  );
}
MoreInfoRating.propTypes = {
  rating: PropTypes.object,
  subheader: PropTypes.string
};
BlockEvidence.propTypes = {
  data: PropTypes.object
};

export default BlockEvidence;
