import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Checkbox = ({
  isSelected,
  checkboxClassName = "",
  unselectedClassName = "",
  selectedClassName = "check-box--selected",
  onChange,
  name = ""
}) => {
  const selectionClasses = classNames(checkboxClassName, "check-box", {
    [`${selectedClassName} icon-checkbox-checked`]: isSelected,
    [`${unselectedClassName} icon-checkbox`]: !isSelected
  });

  return <div onClick={onChange} className={selectionClasses} name={name} />;
};

Checkbox.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  checkboxClassName: PropTypes.string,
  selectedClassName: PropTypes.string,
  unselectedClassName: PropTypes.string,
  name: PropTypes.string
};

export default Checkbox;
