export const selectAucData = state => {
  return state.patient.aucData;
};

export const selectConsultationCode = state => {
  const aucData = selectAucData(state);
  return aucData && aucData.consultationCode;
};
export const selectAUCCriteria = state => {
  const aucData = selectAucData(state);
  return aucData && aucData.AUCCriteria;
};
