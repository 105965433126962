import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ShowSingleInputGroup from "./ShowSingleInputGroup";
import ShowMultipleInputGroup from "./ShowMultipleInputGroup";
import MarkdownRender from "utility/markdownRender";
import SubmitBar from "./SubmitBar";
import MoreInfoIcon from "../moreInfo/MoreInfoIcon";
import { setAllCalcInputsToUnselected } from "store/session";
import CommentBubble from "components/protocol/comments/CommentBubble";

export class Calculator extends React.Component {
  constructor(props) {
    super();
    this.valueRangesWithData = props.calculatorUsage.calculator.valueRanges.map(
      vr => {
        const selection = props.selections.find(s => s.id === vr.selectionId);
        return {
          ...vr,
          selection,
          output: props.outputs.find(
            o =>
              o.selectionId === selection.id &&
              o.usageId === props.calculatorUsage.id
          )
        };
      }
    );
    this.multipleInputs = props.calculatorUsage.calculator.inputGroups.filter(
      inputGroup => inputGroup.style === "multiple"
    );
  }
  setAllUnansweredToNo = () => {
    const { calculatorUsage, setAllCalcInputsToUnselected } = this.props;
    return () => {
      setAllCalcInputsToUnselected({
        calcUsageId: calculatorUsage.id,
        multipleInputs: this.multipleInputs
      });
    };
  };

  render() {
    const { setActiveGroup } = this.props;
    const { calculator } = this.props.calculatorUsage;

    return (
      <div>
        {this.props.withoutTab && (
          <div>
            <div className="row align-middle collapse calculators_header">
              <div className="columns shrink">
                <span
                  className={`calculators_icon ${
                    calculator.variation === "redflag"
                      ? "icon-flag"
                      : "icon-calculator"
                  }`}
                />
              </div>
              <div className="columns calculators_title">
                {calculator.title}
              </div>
            </div>
            <CommentBubble
              style={{ marginLeft: "1.25rem" }}
              itemId={calculator.id}
              usageId={calculator.id}
              internalName={calculator.internalName}
              locationDescription="Calculator"
              condensed
            />
          </div>
        )}
        <div key={calculator.id} className="calculator collapse row">
          <div className="columns">
            <div className="calculator_body">
              <div className="row align-middle collapse">
                <div className="columns">
                  <p className="calculator_subtitle">{calculator.subtitle}</p>
                </div>
                {this.props.calculatorUsage.evidence && (
                  <div className="columns shrink">
                    <MoreInfoIcon
                      evidence={this.props.calculatorUsage.evidence}
                      usasgeId={this.props.calculatorUsage.id}
                    />
                  </div>
                )}
              </div>
              <MarkdownRender
                className="calculator-item_markdown"
                source={calculator.description}
                escapeHtml
              />
              <h3 className="calculator_prompt">{calculator.prompt}</h3>

              <div className="calculator-input-groups">
                {calculator.inputGroups.map(
                  (inputGroup, index, inputGroups) => {
                    return (
                      <Fragment key={inputGroup.id}>
                        {inputGroup.style === "multiple" ? (
                          <ShowMultipleInputGroup
                            calcUsageId={this.props.calculatorUsage.id}
                            inputGroup={inputGroup}
                            index={index}
                            inputGroupId={inputGroup.id}
                            inputGroupsLength={inputGroups.length}
                            calculatorType={calculator.variation}
                          />
                        ) : (
                          <ShowSingleInputGroup
                            inputGroup={inputGroup}
                            calcUsageId={this.props.calculatorUsage.id}
                            index={index}
                          />
                        )}
                      </Fragment>
                    );
                  }
                )}
              </div>
              {this.multipleInputs.length > 0 && (
                <div className="calculator_mark-all-container">
                  <span className="calculator_mark-all-container-label">
                    Mark all unanswered{" "}
                  </span>{" "}
                  <button
                    onClick={this.setAllUnansweredToNo()}
                    className="button button_standard calculator-input-group_no-button"
                  >
                    No
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <SubmitBar
          calcUsage={this.props.calculatorUsage}
          valueRanges={this.valueRangesWithData}
          currentSectionId={this.props.currentSectionId}
          relatedSections={this.props.relatedSections}
          setActiveGroup={setActiveGroup}
        />
      </div>
    );
  }
}

Calculator.propTypes = {
  calculatorUsage: PropTypes.object,
  outputs: PropTypes.array,
  selections: PropTypes.array,
  currentSectionId: PropTypes.string,
  withoutTab: PropTypes.bool,
  relatedSections: PropTypes.object,
  setAllCalcInputsToUnselected: PropTypes.func,
  setActiveGroup: PropTypes.func
};

const mapDispatchToProps = {
  setAllCalcInputsToUnselected
};
export default connect(
  null,
  mapDispatchToProps
)(Calculator);
