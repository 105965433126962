import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Overlay extends Component {
  render() {
    const { handleOverlayClick, className, isVisible } = this.props;
    return (
      (isVisible && (
        <div
          className={`click-off-overlay ${className}`}
          onClick={() => handleOverlayClick()}
        />
      )) ||
      null
    );
  }
}

Overlay.propTypes = {
  handleOverlayClick: PropTypes.func,
  className: PropTypes.string,
  isVisible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isVisible: state.ui.overlay.visible,
    handleOverlayClick: state.ui.overlay.handleOverlayClick,
    className: state.ui.overlay.className
  };
};

export default connect(mapStateToProps)(Overlay);
