import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { isEmpty } from "ramda";
import FHIR_SubmitOrdersButton from "./FHIR_SubmitOrdersButton";
import { FHIR_FLAGS_HAS_ORDERS_POST } from "constants/fhir";
import dotProp from "dot-prop";
import { getConfigByOrgSlug } from "helpers/config";
import { setHandshakeResponse } from "store/FHIR_orders";
import { fhirFactory } from "lib/fhir/factory";
import { pipelineRequest } from "store/pipeline";
import { EVENT_ORDER_ERROR } from "constants/broadcastEventTypes";
import OrderSentView from "./OrderSentView";

export const SortBy = React.createContext("recommended");

class OrdersContainer extends React.Component {
  static propTypes = {
    children: PropTypes.array,
    outputItems: PropTypes.object,
    rxSetItems: PropTypes.object,
    slug: PropTypes.string,
    sentMeds: PropTypes.object,
    sentOrders: PropTypes.object,
    orderData: PropTypes.object,
    setSentOrders: PropTypes.func,
    prescriptionSets: PropTypes.array,
    orders: PropTypes.array
  };
  state = {
    sortBy: "recommended"
  };
  handleSort = sortBy => e => {
    this.setState({ sortBy });
  };

  componentDidMount() {
    if (__FHIR__) {
      fhirFactory
        .getFhirModule(FHIR_ENVIRONMENT)
        .initiateHandshake(this.handshakeListener);
    }
  }

  componentWillUnmount() {
    if (__FHIR__) {
      fhirFactory
        .getFhirModule(FHIR_ENVIRONMENT)
        .cleanupHandshake(this.handshakeListener);
    }
  }

  // change the pipeline event types
  handshakeListener = event => {
    console.log(`EC window received message from ${event.origin} `, event);
    const fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);

    if (event.origin !== window.origin) {
      console.log("EC window received message from UNKNOWN ORIGIN");
      pipelineRequest({ action: EVENT_ORDER_ERROR, message: event.data });
      return;
    } else if (event.data && event.data.token) {
      //event.data.token is epic specific... move to the repo?
      console.log("EC window received handshake response");
      this.props.setHandshakeResponse(event.data);
      fhirModule.cleanupHandshake(this.handshakeListener);
    } else {
      console.log("EC window received message and was unable to process");
      pipelineRequest({
        action: EVENT_ORDER_ERROR,
        message: event.data
      });
      return;
    }
  };

  render() {
    const { sortBy } = this.state;
    const {
      outputItems,
      rxSetItems,
      slug,
      prescriptionSets,
      orders
    } = this.props;
    const config = getConfigByOrgSlug(slug);
    // since each rxItem is an array, need to flatten before filtering
    const selectedRxItems = []
      .concat(...Object.keys(rxSetItems).map(key => rxSetItems[key]))
      .filter(rxItem => rxItem.isSelected);
    const selectedOrders = Object.keys(outputItems)
      .map(key => ({
        ...outputItems[key],
        outputId: key
      }))
      .filter(item => item.itemType === "order" && item.isSelected);
    const sentMeds = __FHIR__ && Object.keys(this.props.sentMeds);
    const sentOrders = __FHIR__ && Object.keys(this.props.sentOrders);
    const sortClasses = "columns orders-button-group_item";
    const recommendedClasses = classNames(sortClasses, {
      "orders-button-group_item--active": sortBy === "recommended"
    });
    const selectedClasses = classNames(sortClasses, {
      "orders-button-group_item--has-selected":
        (selectedRxItems.length > 0 || selectedOrders.length > 0) &&
        sortBy !== "selected",
      "orders-button-group_item--none-selected":
        selectedRxItems.length === 0 && selectedOrders.length === 0,
      "orders-button-group_item--active": sortBy === "selected"
    });

    const sentClasses = classNames(sortClasses, {
      "orders-button-group_item--none-selected":
        (sentMeds.length === 0 || sentMeds.length === undefined) &&
        (sentOrders.length === undefined || sentOrders.length === 0),
      "orders-button-group_item--has-selected":
        (sentMeds.length > 0 || sentOrders.length > 0) && sortBy !== "sent",
      "orders-button-group_item--active": sortBy === "sent"
    });

    return (
      <div className="orders_container">
        <SortBy.Provider value={this.state.sortBy}>
          <div className="row align-middle collapse orders_header">
            <div className="columns orders_header-text">
              Select the orders that are indicated for your patient
            </div>
            {__FHIR__ &&
              FHIR_FLAGS_HAS_ORDERS_POST &&
              config.ord_canSubmit &&
              !isEmpty(this.props.orderData) && (
                <div className="columns shrink">
                  <FHIR_SubmitOrdersButton
                    setSentOrders={this.props.setSentOrders}
                    selectedOrders={selectedOrders}
                    selectedMeds={selectedRxItems}
                    FHIR_orders={this.props.orderData}
                  />
                </div>
              )}
          </div>
          <div className="row collapse orders-button-group text-center">
            <div
              className={recommendedClasses}
              onClick={this.handleSort("recommended")}
            >
              <span className="icon-order orders-button-group_icon" />{" "}
              Recommended
            </div>
            <div
              className={selectedClasses}
              onClick={this.handleSort("selected")}
            >
              <span className="icon-copy orders-button-group_icon" /> Selected
            </div>
            {__FHIR__ && FHIR_FLAGS_HAS_ORDERS_POST && (
              <div className={sentClasses} onClick={this.handleSort("sent")}>
                <span className="icon-submit-arrow orders-button-group_icon" />{" "}
                Sent
              </div>
            )}
          </div>
          <div className="orders_body">
            {this.state.sortBy === "sent" ? (
              <OrderSentView
                sentMeds={this.props.sentMeds}
                sentOrders={this.props.sentOrders}
                prescriptionSets={prescriptionSets}
                orders={orders}
              />
            ) : (
              <React.Fragment>{this.props.children}</React.Fragment>
            )}
          </div>
        </SortBy.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    outputItems: state.session.customOutputItems,
    rxSetItems: state.session.rxSetItems,
    orderData: state.FHIR_orders.crosswalk,
    slug: dotProp.get(state, "user.organization.slug"),
    sentMeds: state.FHIR_orders.sentMeds,
    sentOrders: state.FHIR_orders.sentOrders
  };
};

const mapDispatchToProps = {
  setHandshakeResponse
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersContainer);
