import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { isEmail } from "lib/extendedValidation";
import { xsmallImageUrl } from "helpers/urls/cloudinary";
import { eliquisSrc } from "constants/cloudinary";

class Eliquis extends Component {
  state = {
    showSuccess: false,
    data: null
  };
  componentDidMount() {
    // fetchGoodRx(this.props.token, this.props.goodRxUrl).then(({ data }) => {
    //   this.setState({ data: data.data })
    // })
    // get Eliquis asset (coupon)
  }
  handleSubmit = e => {
    e.preventDefault();
    console.log(e);
    // const { medProfileId } = this.props
    // let formObj = serialize(e.target, { hash: true })
    // if (formObj.email) {
    //   // figure out what to do about the email
    //   sendGoodRxEmail(this.props.token, formObj.email, medProfileId).then(() => {
    //     // change to notification
    //     this.setState({ showSuccess: true })
    //     setTimeout(() => {
    //       this.setState({ showSuccess: false })
    //     }, 2000)
    //   })
    // }
  };
  printCoupon = () => {
    console.log("print");
  };

  render() {
    // let { data } = this.state
    // let successClasses = classNames('goodrx_success', {
    //   'goodrx_success--active': this.state.showSuccess
    // })
    return (
      <div className="eliquis-modal_content">
        <div className="eliquis-modal_section">
          <div className="eliquis-modal_button">
            <button
              className="button button-secondary"
              onClick={this.printCoupon}
            >
              Print Coupon
            </button>
          </div>
          <div className="rx-coupon">
            <div className="rx-coupon_heading">
              <h1>
                $10 co-pay card<sup>*</sup>
              </h1>
            </div>
            <div className="rx-coupon-info">
              <h2 className="rx-coupon-info_heading">
                Activate at 1-855-ELIQUIS (354-7847) OR www.ELIQUIS.com
              </h2>
              <div className="row collapse align-middle">
                <div className="columns medium-8">
                  <div className="rx-coupon-info_disclaimers">
                    <p>
                      *Eligibility requirements and terms of use apply. <br />
                      *Patient is responsible for applicable taxes, if any.{" "}
                      <br />
                      <span className="rx-coupon-info_disclaimers--bold">
                        This offer is not health insurance.
                      </span>
                      <br />
                      Please see accompanying Full Prescribing information,
                      including Boxed <strong>WARNINGS</strong> and Medication
                      Guide
                    </p>
                  </div>
                  <div className="rx-coupon-pharmicist-info row collapse">
                    <div className="columns rx-coupon-pharmicist-info_item small-6">
                      <strong>RxBin:</strong> 610524
                    </div>
                    <div className="columns rx-coupon-pharmicist-info_item small-6">
                      <strong>GRP:</strong> 50776822
                    </div>
                    <div className="columns rx-coupon-pharmicist-info_item small-6">
                      <strong>RxPCN:</strong> LOYALTY
                    </div>
                    <div className="columns rx-coupon-pharmicist-info_item small-6">
                      <strong>ID:</strong> 000 000 000
                    </div>
                  </div>
                </div>
                <div className="columns rx-coupon-info_logo-col">
                  <div className="rx-coupon-logo">
                    <img src={xsmallImageUrl(eliquisSrc)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eliquis-modal_section">
          <h5 className="eliquis-modal_title">Send Patient Coupon</h5>
          <Form onSubmit={this.handleSubmit}>
            <label>
              Email Address
              <Input
                className="name-input"
                validations={[isEmail]}
                name="email"
                type="email"
                placeholder="username@email.com"
              />
            </label>
            <div className="eliquis-modal_button">
              <Button className="button button-secondary">
                Send savings information
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
Eliquis.propTypes = {
  medProfileId: PropTypes.number
};
export default Eliquis;
