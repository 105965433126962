import { makeEcRequest, makeEcContentRequest } from "./base";

export const fetchTarascon = (token, id) => {
  return makeEcContentRequest({
    method: "GET",
    location: `tarascon/drug/${id}`,
    token
  });
};

export const fetchDrugClasses = token => {
  return makeEcRequest({
    method: "GET",
    location: "tarascon/drugclass",
    token
  });
};

export const sendGoodRxEmail = (token, email, medProfileId) => {
  return makeEcRequest({
    method: "POST",
    location: `medication/medicationprofile/${medProfileId}/goodrx_email`,
    token,
    body: JSON.stringify({ email })
  });
};

export const fetchGoodRx = (token, goodRxUrl) => {
  const drugName = goodRxUrl.split("name=")[1];
  const location = `${EC_SERVICE_PROXY}goodrx/v2/price/compare?name=${drugName}&c=evidencecare`;
  return makeEcRequest({
    method: "GET",
    location,
    token
  });
};

export const fetchMedicationProfileMeta = token => {
  const range = encodeURIComponent("[0,500]");
  return makeEcContentRequest({
    method: "Get",
    location: `medication/medicationprofilemeta?range=${range}`,
    token
  });
};
