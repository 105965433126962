import React, { Component } from "react";
import PropTypes from "prop-types";
import Answer from "./Answer";
import classNames from "classnames";
import MarkdownRender from "utility/markdownRender";

export class Question extends Component {
  state = {
    showExplanation: false,
    explanationIsVisible: false
  };
  componentDidMount = () => {
    const { correctQuestions = [], question } = this.props;
    correctQuestions.includes(question.id) &&
      this.setState({ showExplanation: true });
  };
  isAnswerSelected = answer => {
    const {
      correctQuestions = [],
      incorrectAnswers = [],
      question
    } = this.props;
    return (
      (answer.is_correct && correctQuestions.includes(question.id)) ||
      (!answer.is_correct &&
        incorrectAnswers.map(answer => answer.answerId).includes(answer.id))
    );
  };
  shouldShowExplanation = (answer, showExplanation) => {
    if (answer.is_correct) {
      this.setState({ showExplanation: true });
    } else {
      this.setState({ showExplanation: false });
      this.setState({ explanationIsVisible: false });
    }
  };
  makeExplanationVisible = () => {
    this.setState({ explanationIsVisible: !this.state.explanationIsVisible });
  };
  renderExplanation = question => {
    const expandClasses = classNames("cme-question_explanation-expand-icon", {
      "icon-expand": !this.state.explanationIsVisible,
      "icon-expanded": this.state.explanationIsVisible
    });
    return (
      <div>
        <div
          onClick={this.makeExplanationVisible}
          className="cme-question_explanation"
        >
          <div>Explanation</div>
          <div className={expandClasses} />
        </div>
        {this.state.explanationIsVisible && (
          <MarkdownRender
            className="cme-question_explanation-content"
            source={question.explanation}
          />
        )}
      </div>
    );
  };

  renderAnswers = (answers, questionId) =>
    answers.map((answer, index) => (
      <Answer
        answer={answer}
        questionId={questionId}
        key={answer.id}
        setTestAnswer={this.props.setTestAnswer}
        isSelected={this.isAnswerSelected(answer)}
        shouldShowExplanation={this.shouldShowExplanation}
      />
    ));
  render() {
    const { question, questionIndex } = this.props;
    return (
      <div className="cme-question">
        <div className="cme-question_prompt">
          {questionIndex + 1}. {question.prompt}
        </div>
        {this.renderAnswers(question.answers, question.id)}
        {question.explanation &&
          this.state.showExplanation &&
          this.renderExplanation(question)}
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.object,
  questionIndex: PropTypes.number,
  correctQuestions: PropTypes.array,
  incorrectAnswers: PropTypes.array,
  setTestAnswer: PropTypes.func
};

export default Question;
