import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  submitCMETest,
  clearCMETest,
  fetchCMETests,
  saveAccountDetails
} from "store/account";
import { loadBootstrapData } from "store/bootstrap";
import PostActivityQuestions from "./PostActivityQuestions";
import Question from "./Question";
import { showLoader, hideLoader } from "store/ui";

import R from "ramda";
import { EVENT_FEATURE_CME_TEST_VIEW } from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";
import {
  selectUserFirstName,
  selectUserLastName,
  selectUserProviderType
} from "store/user/selectors";

class CMETest extends Component {
  state = {
    percentCorrect: 0,
    correctQuestions: [],
    incorrectAnswers: [],
    questions: [],
    hasPracticeChanged: null,
    practiceChangedDescription: "",
    postActivityQuestionsSubmitted: false,
    tabIndex: 0,
    totalQuestions: [],
    degree: "MD",
    hasSubmittedTest: false,
    answerdQuestions: []
  };
  componentDidMount() {
    this.props.pipelineRequest({
      action: EVENT_FEATURE_CME_TEST_VIEW
    });
  }

  componentWillReceiveProps(nextProps) {
    const { cmeTest } = this.props;
    if (cmeTest.score !== nextProps.cmeTest.score) {
      this.setState({ percentCorrect: nextProps.cmeTest.score });
    }
    if (
      !R.equals(cmeTest.correct_questions, nextProps.cmeTest.correct_questions)
    ) {
      this.setState({ correctQuestions: nextProps.cmeTest.correct_questions });
    }
    if (cmeTest.practice_changed !== nextProps.cmeTest.practice_changed) {
      this.setState({
        hasPracticeChanged: nextProps.cmeTest.practice_changed,
        practiceChangedDescription:
          nextProps.cmeTest.practice_changed_description
      });
    }
    if (nextProps.cmeTest.accreditations) {
      const totalQuestions = nextProps.cmeTest.accreditations.reduce(
        (acc, curr) => {
          return [...acc, ...curr.questions.map(question => question.id)];
        },
        []
      );
      this.setState({ totalQuestions });
    }
  }

  componentWillUnmount() {
    this.props.clearCMETest();
  }

  handleSubmit = e => {
    const { cmeTest } = this.props;
    const testResults = {
      correct_questions: this.state.correctQuestions,
      practice_changed: this.state.hasPracticeChanged,
      practice_changed_description: this.state.practiceChangedDescription,
      completed: true
    };
    this.setState({ hasSubmittedTest: true });
    // We do not current store a physician's degree (MD/DO) which is needed
    // on the cert. For a temp fix, they can set it on the test.
    // Defaults to MD as that's the vast majority.
    this.props
      .submitCMETest(cmeTest.id, testResults, this.state.degree)
      .then(() => {
        const { year, pageSize } = this.props.cmeTestHistory;
        this.props.fetchCMETests({ year, currentPage: 1, pageSize });
      });
  };

  handlePostActivitySubmit = ({
    hasPracticeChanged,
    practiceChangedDescription = "",
    degree
  }) => {
    this.setState({
      postActivityQuestionsSubmitted: true,
      hasPracticeChanged,
      practiceChangedDescription,
      degree
    });
  };

  tabs = cmeTest => {
    const { accreditations = [] } = cmeTest;
    return accreditations.map(cred => {
      const hasAllQuestionsCorrect = cred.questions.every(question =>
        this.state.correctQuestions.includes(question.id)
      );
      const hasSomeQuestionsCorrect = cred.questions.some(question =>
        this.state.correctQuestions.includes(question.id)
      );
      const hasSomeQuestionsIncorrect = cred.questions.some(
        question =>
          R.findIndex(
            R.propEq("questionId", question.id),
            this.state.incorrectAnswers
          ) > -1
      );
      const allQuestionsAnswered = cred.questions.every(question =>
        this.state.answerdQuestions.includes(question.id)
      );
      const showX =
        ((!hasAllQuestionsCorrect && hasSomeQuestionsCorrect) ||
          hasSomeQuestionsIncorrect) &&
        allQuestionsAnswered;
      return (
        <Tab key={`${cred.id}_tab`}>
          <div>
            {cred.title}
            {cred.subtitle && (
              <span className="cme-test_tabs-subtitle">
                <br />
                {cred.subtitle}
              </span>
            )}
          </div>
          {hasAllQuestionsCorrect && <div className="icon-circle-check" />}
          {showX && <div className="icon-circle-x" />}
        </Tab>
      );
    });
  };

  setPercentage = (a, b) => {
    return Math.round((a / b) * 100);
  };

  setTestAnswer = ({ questionId, answerId, correct }) => {
    const { correctQuestions, incorrectAnswers, totalQuestions } = this.state;
    const filteredCorrectQuestions = R.intersection(
      correctQuestions,
      totalQuestions
    );
    this.state.answerdQuestions.push(questionId);
    if (correct) {
      const incorrectQuestionIndex = R.findIndex(
        R.propEq("questionId", questionId),
        incorrectAnswers
      );
      const alreadyCorrectQuestion = filteredCorrectQuestions.includes(
        questionId
      );
      if (incorrectQuestionIndex > -1) {
        this.setState({
          correctQuestions: [...filteredCorrectQuestions, questionId],
          percentCorrect: this.setPercentage(
            filteredCorrectQuestions.length + 1,
            totalQuestions.length
          ),
          incorrectAnswers: R.remove(
            incorrectQuestionIndex,
            1,
            incorrectAnswers
          )
        });
      } else if (!alreadyCorrectQuestion) {
        this.setState({
          correctQuestions: [...filteredCorrectQuestions, questionId],
          percentCorrect: this.setPercentage(
            filteredCorrectQuestions.length + 1,
            totalQuestions.length
          )
        });
      }
    } else {
      const hasCorrectQuestion = filteredCorrectQuestions.includes(questionId);
      const answerObj = {
        questionId,
        answerId
      };
      if (hasCorrectQuestion) {
        this.setState({
          correctQuestions: filteredCorrectQuestions.filter(
            cq => cq !== questionId
          ),
          percentCorrect: this.setPercentage(
            filteredCorrectQuestions.length - 1,
            totalQuestions.length
          ),
          incorrectAnswers: R.uniqBy(R.propEq("questionId", questionId), [
            answerObj,
            ...incorrectAnswers
          ])
        });
      } else {
        this.setState({
          incorrectAnswers: R.uniqBy(R.propEq("questionId", questionId), [
            answerObj,
            ...incorrectAnswers
          ])
        });
      }
    }
  };

  panels = cmeTest => {
    const { accreditations = [] } = cmeTest;
    return accreditations.map(cred => (
      <TabPanel key={`${cred.id}_panel`}>
        <h5 className="cme-questions_title">
          {cred.title}
          {cred.subtitle && (
            <span className="cme-questions_subtitle">
              <br />
              {cred.subtitle}
            </span>
          )}
        </h5>
        {cred.questions.map((question, index) => (
          <Question
            question={question}
            questionIndex={index}
            key={question.id}
            setTestAnswer={this.setTestAnswer}
            correctQuestions={this.state.correctQuestions}
            incorrectAnswers={this.state.incorrectAnswers}
          />
        ))}
      </TabPanel>
    ));
  };

  incrementTabIndex = () => {
    this.setState({ tabIndex: this.state.tabIndex + 1 });
  };

  render() {
    const {
      cmeTest,
      providerType,
      saveAccountDetails,
      loadBootstrapData,
      firstName,
      lastName
    } = this.props;
    const { accreditations = [] } = cmeTest;
    const {
      tabIndex,
      postActivityQuestionsSubmitted,
      correctQuestions,
      totalQuestions,
      hasSubmittedTest
    } = this.state;
    const percentCorrect = this.setPercentage(
      correctQuestions.length,
      totalQuestions.length
    );
    const percentClasses = classNames("cme-test_results-percent", {
      "cme-test_results-percent--success": percentCorrect > 75
    });

    const showNext =
      percentCorrect !== 100 && accreditations.length > tabIndex + 1;
    const showSubmit = percentCorrect > 75;

    return (
      <div className="cme-test">
        {!postActivityQuestionsSubmitted ? (
          <PostActivityQuestions
            degree={this.state.degree}
            firstName={firstName}
            lastName={lastName}
            providerType={providerType}
            handlePostActivitySubmit={this.handlePostActivitySubmit}
            saveAccountDetails={saveAccountDetails}
            fetchBootstrapInfo={loadBootstrapData}
          />
        ) : (
          <Tabs
            className="cme-test_tabs row collapse"
            selectedIndex={tabIndex}
            onSelect={tabIndex => this.setState({ tabIndex })}
          >
            <div className="columns small-12 medium-4 cme-test_list-column">
              <h5 className="cme-test_title">Protocols</h5>
              <TabList>{this.tabs(cmeTest)}</TabList>
            </div>
            <div className="columns small-12 medium-8">
              {this.panels(cmeTest)}
              {showNext && (
                <button
                  className="button button-gray cme-test_next"
                  onClick={this.incrementTabIndex}
                >
                  Next
                </button>
              )}
              <div className="cme-test_results">
                <div className={percentClasses}>{`${percentCorrect}%`}</div>
                <h5 className="cme-test_results-percent-label">
                  Current Result
                </h5>
                <p className="cme-test_results-explanation">
                  To pass the test, the result must be at least <b>75%</b>
                </p>
              </div>
              {showSubmit && (
                <button
                  className="button button-secondary cme-test_submit"
                  disabled={hasSubmittedTest}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </Tabs>
        )}
      </div>
    );
  }
}
CMETest.propTypes = {
  cmeTest: PropTypes.object,
  submitCMETest: PropTypes.func,
  clearCMETest: PropTypes.func,
  cmeTestHistory: PropTypes.object,
  fetchCMETests: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  providerType: PropTypes.string,
  saveAccountDetails: PropTypes.func,
  loadBootstrapData: PropTypes.func,
  pipelineRequest: PropTypes.func
};

const mapStateToProps = state => ({
  cmeTest: state.account.cme.test || {},
  cmeTestHistory: state.account.cmeTestHistory,
  firstName: selectUserFirstName(state),
  lastName: selectUserLastName(state),
  providerType: selectUserProviderType(state)
});

const mapDispatchToProps = {
  submitCMETest,
  clearCMETest,
  fetchCMETests,
  saveAccountDetails,
  showLoader,
  hideLoader,
  loadBootstrapData,
  pipelineRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMETest);
