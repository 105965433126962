import React from "react";
import PropTypes from "prop-types";
import CitationLink from "./CitationLink";

const CitationList = ({ citations = [] }) => {
  return (
    <div className="citations-list">
      {citations.map(citation => (
        <CitationLink key={citation.id} citation={citation} />
      ))}
    </div>
  );
};

CitationList.propTypes = {
  citations: PropTypes.array
};

export default CitationList;
