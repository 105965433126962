import { isDescendantInclusive } from "helpers/dom/traversal";

// fires callback once the step target is on the page
// callback is given the target element and a boolean indicating
// whether the element was already on the page
// also return an `unsubscribe` function for cancelling the waiting
import { MOUNT_NODE } from "../../constants/react";

export function waitForTarget(target, successCallback) {
  let targetEl = document.querySelector(target);

  // element is already on the page
  if (targetEl) {
    // users will expect to be able to use the return value of `waitForTarget`
    // within their callbacks; this requires deferring the actual execution
    // of the callback until the next event loop (even if they could
    // "logically" be run immediately)
    setTimeout(() => {
      successCallback(targetEl, true);
    }, 0);

    return () => {};

    // use a mutation observer to wait for the target to appear
  } else {
    const mutationObserver = new MutationObserver(() => {
      targetEl = document.querySelector(target);
      if (targetEl) {
        mutationObserver.disconnect();
        successCallback(targetEl);
      }
    });
    mutationObserver.observe(MOUNT_NODE, {
      childList: true,
      attributes: true,
      subtree: true
    });

    return () => {
      mutationObserver.disconnect();
    };
  }
}

// an observer that watches a target css selector, firing callbacks
// when the target disappears and reappears
// onRemoveFromDOM: fired when the target leaves the DOM
// onAddToDOM: fired when the target enters the DOM
// onHidden: fired when the target is hidden from view
// onVisible: fired when the target is makes it into the view again
// Note: target MUST be unique or this function will have undefined behavior
export function watchTarget(
  target,
  { onRemoveFromDOM, onAddToDOM, onHidden, onVisible }
) {
  // make this a generator function because we want a way to stop and start polling
  // as the target enters and leave the DOM
  const startVisibilityPolling = () => {
    let targetPreviouslyVisible = isTargetVisible(target);
    return setInterval(() => {
      const targetVisible = isTargetVisible(target);
      if (targetPreviouslyVisible && !targetVisible) {
        targetPreviouslyVisible = false;
        if (onHidden) onHidden();
      } else if (!targetPreviouslyVisible && targetVisible) {
        targetPreviouslyVisible = true;
        if (onVisible) onVisible();
      }
    });
  };

  // logic for the target entering and leaving the DOM
  let targetPreviouslyOnDOM = !!document.querySelector(target);
  const mutationObserver = new MutationObserver(() => {
    const targetEl = document.querySelector(target);
    if (targetPreviouslyOnDOM && !targetEl) {
      targetPreviouslyOnDOM = false;
      clearInterval(intervalHandler);
      if (onRemoveFromDOM) onRemoveFromDOM();
    } else if (!targetPreviouslyOnDOM && targetEl) {
      intervalHandler = startVisibilityPolling();
      targetPreviouslyOnDOM = true;
      if (onAddToDOM) onAddToDOM(targetEl);
    }
  });

  // activate the watchers
  let intervalHandler = targetPreviouslyOnDOM ? startVisibilityPolling() : null;
  mutationObserver.observe(MOUNT_NODE, {
    childList: true,
    attributes: true,
    subtree: true
  });

  // returns the unsubscribe function
  return () => {
    if (intervalHandler) clearInterval(intervalHandler);
    mutationObserver.disconnect();
  };
}

// returns true iff the target element is actually visible on the page
// not just on the DOM but hidden somewhere
// eslint-disable-next-line no-unused-vars
export function isTargetVisible(target, targetMustBeFullyVisible = true) {
  // don't bother continuing if the element isn't in the DOM
  const targetEl = document.querySelector(target);
  if (!targetEl) return false;

  // Note: that this check here is necessary for full cross browser compat but
  // is a particularly slow check so I have memoized this functionality for repeated
  // calls on the same element
  if (!_cachedLiveStyles || targetEl !== _previousTargetEl) {
    _cachedLiveStyles = getComputedStyle(targetEl);
    _previousTargetEl = targetEl;
  }

  // check the computed styles
  if (
    _cachedLiveStyles.display === "none" ||
    _cachedLiveStyles.visibility === "hidden"
  )
    return false;

  const {
    top,
    left,
    bottom,
    right,
    width,
    height
  } = targetEl.getBoundingClientRect();

  // use some inset so we aren't testing the very edges of the element
  // (in case of different calculations between browsers)
  const heightInset = Math.round(height / 10);
  const widthInset = Math.round(width / 10);

  // don't select the exact corner but do an inset by a couple pixels
  // to deal with cross-browser inconsistencies
  const possibleChild1 = document.elementFromPoint(
    left + widthInset,
    top + heightInset
  );

  let check1 = true;

  // is null and element is not in viewport
  if (!possibleChild1) {
    check1 = false;

    // if the found element is outside of the DOM tree of the target element,
    // there are two scenarios:
    //  (1) the target is obscured by an ancestor (e.g., scrolled out of view)
    //  (2) there is some completely other DOM subtree on top of it
    // In both cases we will consider it not visible
  } else if (!isDescendantInclusive(targetEl, possibleChild1)) {
    check1 = true;
  }

  // if the full element must be visible and we fail the first check,
  // no need to continue
  if (targetMustBeFullyVisible && !check1) return false;

  const possibleChild2 = document.elementFromPoint(
    right - widthInset,
    bottom - heightInset
  );

  // can just do direct return because there is no need to store intermediate result
  return possibleChild2 && isDescendantInclusive(targetEl, possibleChild2);
}
let _previousTargetEl = null;
let _cachedLiveStyles = null;
