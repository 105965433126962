import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import MediaQuery from "react-responsive";

import "./HelpCenterTabbedLayout.scss";
import {
  selectActiveSection,
  selectHelpCenterTabs
} from "store/helpCenter/selectors";
import {
  goToHelpCenterLandingPage,
  goToHelpCenterSection
} from "store/helpCenter/actions";

export class HelpCenterTabbedLayout extends React.PureComponent {
  state = {
    tabsOpen: false
  };

  render() {
    const {
      children,
      activeSection: {
        id: activeSectionId,
        title = "Welcome to Evidence Care!",
        subtitle = "How can we help?"
      } = {},
      tabs = [],
      goToSection,
      goToHelpCenterLandingPage
    } = this.props;

    return (
      <>
        <div className="help-center-tabbed-layout_header-background">
          <div className="help-center-tabbed-layout_content-container help-center-tabbed-layout_header-container">
            <a
              onClick={goToHelpCenterLandingPage}
              className="help-center-tabbed-layout_return-link"
            >
              <span className="icon-arrow-left help-center-tabbed-layout_return-icon" />
              <em>Return to Help Center</em>
            </a>
            <h2 className="help-center-tabbed-layout_title">
              <b>{title}</b>
            </h2>
            <h5>{subtitle}</h5>
          </div>

          <div className="help-center-tabbed-layout_content-container help-center-tabbed-layout_tab-container">
            <MediaQuery query="(min-width: 641px)">
              {tabs.map(({ id, title }) => (
                <a
                  className={classnames("help-center-tabbed-layout_tab", {
                    "help-center-tabbed-layout_tab--selected":
                      id === activeSectionId
                  })}
                  key={id}
                  onClick={() => goToSection(id)}
                >
                  <b>{title}</b>
                </a>
              ))}
            </MediaQuery>
            <MediaQuery query="(max-width: 640px)">
              <DropDownSelector
                tabs={tabs}
                activeSectionId={activeSectionId}
                onClick={goToSection}
              />
            </MediaQuery>
          </div>
        </div>
        <div className="help-center-tabbed-layout_content-container">
          {children}
        </div>
      </>
    );
  }
}

HelpCenterTabbedLayout.propTypes = {
  activeSection: PropTypes.object,
  tabs: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  goToSection: PropTypes.func,
  goToHelpCenterLandingPage: PropTypes.func
};

const mapStateToProps = state => {
  return {
    tabs: selectHelpCenterTabs(state),
    activeSection: selectActiveSection(state)
  };
};

export default connect(
  mapStateToProps,
  { goToSection: goToHelpCenterSection, goToHelpCenterLandingPage }
)(HelpCenterTabbedLayout);

/**********************************************************
 * Dropdown Selector
 *
 * This is used to replace the tabs if the screen size is too small.
 * It will display the active section across the width of the screen
 * and clicking that section will activate the dropdown.
 *
 * When the dropdown is open, the active section will remain at the top
 * while the remaining sections will remain in order.
 **********************************************************/

class DropDownSelector extends React.PureComponent {
  state = {
    open: false
  };

  render() {
    const { tabs = [], activeSectionId, onClick } = this.props;
    const { open } = this.state;

    if (tabs.length === 0) return null;

    const { title } = tabs.find(({ id }) => id === activeSectionId) || {
      title: "Select Section"
    };

    if (open) {
      return (
        <>
          <a
            className="help-center-tabbed-layout_mobile-tab help-center-tabbed-layout_mobile-tab--selected"
            onClick={() => this.setState({ open: false })}
          >
            <b>{title}</b>
            <div className="help-center-tabbed-layout_mobile-tab-spacer" />
            <span className="icon-close" />
          </a>
          {tabs.map(({ title, id }) => {
            if (id === activeSectionId) return null;
            return (
              <a
                key={id}
                className="help-center-tabbed-layout_mobile-tab"
                onClick={() => {
                  onClick(id);
                  this.setState({ open: false });
                }}
              >
                <b>{title}</b>
              </a>
            );
          })}
        </>
      );
    } else {
      return (
        <a
          className="help-center-tabbed-layout_mobile-tab"
          onClick={() => this.setState({ open: true })}
        >
          <b>{title}</b>
          <div className="help-center-tabbed-layout_mobile-tab-spacer" />
          <span className="icon-expand" />
        </a>
      );
    }
  }
}

DropDownSelector.propTypes = {
  onClick: PropTypes.func,
  tabs: PropTypes.array,
  activeSectionId: PropTypes.string
};
