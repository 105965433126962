// import localForage from 'localforage'
import {
  createSessionApi,
  updateSessionApi,
  fetchSessionApi
} from "lib/ec/session";
import { updateSessionParam } from "helpers/urls/route";
import * as immutable from "object-path-immutable";
import dotProp from "dot-prop";
import { debounce } from "lodash";
import R from "ramda";
import { fetchAndSetSessionList } from "store/patient";
import {
  FHIR_FLAGS_HAS_ENCOUNTER_DATA,
  FHIR_FLAGS_HAS_PATIENT_DATA
} from "constants/fhir";

// var sessionStore = localForage.createInstance({
//   driver: [localForage.WEBSQL,
//     localForage.INDEXEDDB,
//     localForage.LOCALSTORAGE],
//   name: 'EvidenceCare-Sessions',
//   version: 1.0 // schema version
// })

// export function saveSession (session) {
//   // returns a promise
//   return sessionStore.setItem(session.id, session)
// }
export function createInitialSectionState(section) {
  return {
    protocolId: null,
    sectionId: section.id,
    sectionVersionId: section.sectionVersionId,
    nextSection: null,
    nextProtocol: null
  };
}
export const OUTPUT_TAB = {
  EHR: "OUTPUT_TAB_EHR",
  PE: "OUTPUT_TAB_PE",
  ORDERS: "OUTPUT_TAB_ORDERS"
};

const OUTPUT_INITIAL_STATE = {
  id: null,
  protocolId: null,
  selectionId: null,
  usageId: null,
  sectionId: null,
  nextId: null,
  selected: false,
  usageType: null
};
const SELECTION_INITIAL_STATE = {
  // selected: false,
  type: null
};
const USAGE_INITIAL_STATE = {
  id: null,
  protocolId: null,
  sectionId: null,
  type: null
};

const SECTION_INITIAL_STATE = {
  id: null,
  protocolId: null,
  sectionVersionId: null,
  defaultSectionOutput: null,
  nextSection: null,
  nextProtocol: null,
  evidenceFolders: []
};
const PROTOCOL_INITIAL_STATE = {
  id: null,
  sectionsOrder: [],
  activeMoreInfo: null,
  activeOutputTab: null,
  activeGroupId: null,
  activeSectionId: null,
  approvalPoint: null,
  latestVersion: null
};
const REASON_INITIAL_STATE = {
  id: null,
  text: "",
  type: null,
  submitted: false,
  isSelected: true
};
const INITIAL_SESSION = {
  id: null,
  protocolsOrder: [],
  customOutputItems: {},
  rxSetItems: {},
  useProtocolVersion: true,
  activeOutputTab: OUTPUT_TAB.ORDERS,
  entities: {
    protocols: {},
    sections: {},
    usages: {},
    selections: {},
    outputs: {}
  },
  mcgGuideline: {},
  userText: {
    reasons: {}
  }
};
// ------------------------------------
// Constants
// ------------------------------------
export const SESSION_CREATE_SESSION = "SESSION_CREATE_SESSION";
export const SESSION_SET_SESSION = "SESSION_SET_SESSION";
export const SESSION_INITIALIZE_SESSION = "SESSION_INITIALIZE_SESSION";

export const SESSION_UPDATE_SESSION_PROP = "SESSION_UPDATE_SESSION_PROP";
export const SESSION_CLEAR = "SESSION_CLEAR";
export const SESSION_TOGGLE_CALCULATOR_ANSWER =
  "SESSION_TOGGLE_CALCULATOR_ANSWER";
export const SESSION_SUBMIT_CALCULATOR = "SESSION_SUBMIT_CALCULATOR";
export const SESSION_UPDATE_CALCULATOR = "SESSION_UPDATE_CALCULATOR";
export const SESSION_SELECT_CALCULATOR_RADIO =
  "SESSION_SELECT_CALCULATOR_RADIO";
export const SESSION_SELECT_RXSET_RADIO = "SESSION_SELECT_RXSET_RADIO";
export const SESSION_TOGGLE_RXSET_CHECKBOX = "SESSION_TOGGLE_RXSET_CHECKBOX";
export const SESSION_INITIALIZE_SECTION = "SESSION_INITIALIZE_SECTION";
export const SESSION_UPDATE_SELECTION = "SESSION_UPDATE_SELECTION";
export const SESSION_SET_OUTPUT_TAB = "SESSION_SET_OUTPUT_TAB";

export const SESSION_ADD_SECTION = "SESSION_ADD_SECTION";
export const SESSION_ADD_ENTITIES = "SESSION_ADD_ENTITIES";
export const SESSION_REMOVE_ENTITIES_BY_SECTION_ID =
  "SESSION_REMOVE_ENTITIES_BY_SECTION_ID";
export const SESSION_CLEAR_SELECTIONS_BY_USAGE =
  "SESSION_CLEAR_SELECTIONS_BY_USAGE";
export const SESSION_SET_OUTPUT_SELECTED = "SESSION_SET_OUTPUT_SELECTED";
export const SESSION_DESELECT_OUTPUTS = "SESSION_DESELECT_OUTPUTS";
export const SESSION_TOGGLE_CALC_INPUT = "SESSION_TOGGLE_CALC_INPUT";
export const SESSION_SET_CALC_INPUT = "SESSION_SET_CALC_INPUT";
export const SESSION_SET_SELECTED_INPUTS_TO_UNSELECTED =
  "SESSION_SET_SELECTED_INPUTS_TO_UNSELECTED";
export const SESSION_SET_ALL_INPUTS_TO_UNSELECTED =
  "SESSION_SET_ALL_INPUTS_TO_UNSELECTED";
export const SESSION_SUBMIT_CALC = "SESSION_SUBMIT_CALC";
export const SESSION_UPDATE_ENTITY_PROP = "SESSION_UPDATE_ENTITY_PROP";
export const SESSION_TOGGLE_OUTPUT_SELECTED = "SESSION_TOGGLE_OUTPUT_SELECTED";
export const SESSION_SET_OUTPUT_ITEM_PROP = "SESSION_SET_OUTPUT_ITEM_PROP";
export const SESSION_SET_RX_SET_SELECTED = "SESSION_SET_RX_SET_SELECTED";
export const SESSION_SET_RX_SET_MULTIPLE_SELECTED =
  "SESSION_SET_RX_SET_MULTIPLE_SELECTED";
export const SESSION_INIT_SECTION = "SESSION_INIT_SECTION";
export const SESSION_ADD_PROTOCOL = "SESSION_ADD_PROTOCOL";
export const SESSION_REMOVE_ENTITIES_BY_PROTOCOL_ID =
  "SESSION_REMOVE_ENTITIES_BY_PROTOCOL_ID";
export const SESSION_LAUNCH_NEXT_SECTION = "SESSION_LAUNCH_NEXT_SECTION";

export const SESSION_FETCH_SECTION = "SESSION_FETCH_SECTION";
export const SESSION_ADD_PROVIDER_NOTE = "SESSION_ADD_PROVIDER_NOTE";
export const SESSION_ADD_PROVIDER_FOLLOW_UP = "SESSION_ADD_PROVIDER_FOLLOW_UP";

export const SESSION_SET_OUTPUT_UNSELECTED = "SESSION_SET_OUTPUT_UNSELECTED";
export const SET_USE_PROTOCOL_VERSION = "SET_USE_PROTOCOL_VERSION";

export const SESSION_UPDATE_MCG_GUIDELINE = "SESSION_UPDATE_MCG_GUIDELINE";
export const SESSION_DESELECT_MCG_GUIDELINE = "SESSION_DESELECT_MCG_GUIDELINE";
export const SESSION_SELECT_MCG_INDICATION = "SESSION_SELECT_MCG_INDICATION";
export const SESSION_DESELECT_MCG_INDICATION =
  "SESSION_DESELECT_MCG_INDICATION";
export const SESSION_SET_MCG_GUIDELINE = "SESSION_SET_MCG_GUIDELINE";
export const SESSION_SET_MCG_GUIDELINE_SUBMIT =
  "SESSION_SET_MCG_GUIDELINE_SUBMIT";
export const SESSION_CLEAR_RX_SET = "SESSION_CLEAR_RX_SET";
export const SESSION_CLEAR_ORDERS_SET = "SESSION_CLEAR_ORDERS_SET";

export const SESSION_UPDATE_REASON = "SESSION_UPDATE_REASON";

export const SESSION_LAST_SENT_PATIENTED_DOC =
  "SESSION_LAST_SENT_PATIENTED_DOC";
export const SESSION_LAST_SENT_CLINICAL_DOC = "SESSION_LAST_SENT_CLINICAL_DOC";

// ------------------------------------
// Actions
// ------------------------------------
const updateSession = debounce(
  ({ state, state_text }) => {
    if (state.id) {
      updateSessionApi({ state, state_text });
    }
  },
  500,
  { leading: false, trailing: true }
);
export const setupSection = ({ sectionVersion }) => {
  /**
   * Only adding adding outputs that are directly on a section, assuming anything
   * else is supposed to be a suggested default
   */
  const outputs = sectionVersion.render.outputs
    .filter(i => i.sectionId)
    .reduce((acc, output) => {
      const usage =
        output.usageId &&
        sectionVersion.render.usages.find(u => u.id === output.usageId);
      const sessionOutput = {
        id: output.id,
        protocolId: sectionVersion.protocolId,
        selectionId: output.selectionId,
        usageId: output.usageId,
        sectionId: output.sectionId,
        nextId: output.nextId,
        selected: false
      };
      if (usage && usage.usageType === "careusage") {
        sessionOutput.usageType = usage.usageType;
        sessionOutput.dependentSelections = usage.criteria.map(
          crit => crit.selectionId
        );
      }
      acc[output.id] = {
        ...OUTPUT_INITIAL_STATE,
        ...sessionOutput
      };
      return acc;
    }, {});
  /**
   * not putting evidence usages here as they hold no state
   * organize usages by type
   * Currently have selected on both selections and outputs...will need to pick one.
   */
  const usages = sectionVersion.render.usages.reduce((acc, usage) => {
    const newUsage = {
      type: usage.usageType,
      id: usage.id,
      sectionId: usage.sectionId,
      protocolId: sectionVersion.protocolId
    };
    /**
     * different usage types hold different props
     */
    if (usage.usageType === "careusage") {
      const selection =
        (usage.selectionId &&
          sectionVersion.render.selections.find(
            selection => selection.id === usage.selectionId
          )) ||
        null;
      newUsage.selectionId = selection && selection.id;
      newUsage.folderId = usage.folderId;
      newUsage.parentSelections = usage.criteria.map(crit => crit.selectionId);
    } else if (usage.usageType === "questionusage") {
      newUsage.options = usage.question.options.map(opt => ({
        id: opt.id,
        selection: opt.selectionId,
        selected: false
      }));
    } else if (usage.usageType === "calculatorusage") {
      newUsage.inputs = R.flatten(
        usage.calculator.inputGroups.map(ig =>
          ig.inputs.map(input => ({
            id: input.id,
            inputGroupId: ig.id,
            directRangeId: input.directRangeId,
            selectionId: input.selectionId,
            selectedByObservation: false,
            value: input.value,
            selected: null
          }))
        )
      ).reduce((acc, input) => {
        acc[input.id] = input;
        return acc;
      }, {});
      newUsage.valueRanges = usage.calculator.valueRanges
        .map(vr => ({
          id: vr.id,
          severity: vr.severity,
          minValue: vr.minValue,
          maxValue: vr.maxValue,
          selectionId: vr.selectionId
        }))
        .reduce((acc, vr) => {
          acc[vr.id] = vr;
          return acc;
        }, {});
      newUsage.calculator = usage.calculator;
    }
    if (usage.usageType !== "evidenceusage") {
      acc[newUsage.id] = { ...USAGE_INITIAL_STATE, ...newUsage };
    }
    return acc;
  }, {});

  const selections = sectionVersion.render.selections.reduce((acc, sel) => {
    acc[sel.id] = {
      ...SELECTION_INITIAL_STATE,
      ...{
        id: sel.id,
        protocolId: sectionVersion.protocolId,
        sectionId: sel.sectionId,
        usageId: sel.usageId,
        displayName: sel.displayName,
        selectedByObservation: false,
        clinicalObservations: sel.clinicalObservations || []
      }
    };
    return acc;
  }, {});

  // for section
  const evidenceFolders = sectionVersion.render.evidenceFolders.reduce(
    (acc, ef) => {
      acc[ef.id] = {
        id: ef.id,
        isOpen: false
      };
      return acc;
    },
    {}
  );
  const sectionOutput = sectionVersion.render.outputs.find(
    op =>
      op.sectionId === sectionVersion.sectionId &&
      op.usageId === null &&
      op.selectionId === null
  );
  const sectionState = {
    ...SECTION_INITIAL_STATE,
    ...{
      id: sectionVersion.sectionId,
      groupId: sectionVersion.render.groupId,
      protocolId: sectionVersion.protocolId,
      sectionVersionId: sectionVersion.id,
      defaultSectionOutput: sectionOutput && {
        id: sectionOutput.id,
        nextId: sectionOutput.nextId
      },
      nextProtocol: null,
      evidenceFolders
    }
  };
  return { outputs, selections, sectionState, usages };
};

export function createSession({
  protocolId,
  slug,
  latestVersion,
  intialSession
}) {
  return (dispatch, getState) => {
    const customHandle = __FHIR__ && getState().patient.customHandle;
    const args = {
      protocolId,
      intialSession
    };
    if (__FHIR__ && customHandle) args.customHandle = customHandle;
    return createSessionApi(args).then(({ data }) => {
      updateSessionParam({ sessionId: data.id, protocolId, slug });
      /**
       * the following fetchAndSetSessionList currently has specific functionality tied to launching encoutners
       * need to potientially split this off when handling the session list for non encounters
       */
      __FHIR__ &&
        (FHIR_FLAGS_HAS_PATIENT_DATA && FHIR_FLAGS_HAS_ENCOUNTER_DATA) &&
        dispatch(fetchAndSetSessionList());
      dispatch(initializeSession({ sessionId: data.id, latestVersion }));
      return data;
    });
  };
}

export const initializeSession = ({ sessionId }) => {
  return {
    type: SESSION_INITIALIZE_SESSION,
    payload: { sessionId }
  };
};

export const initializeSessionSection = ({
  activeProtocolId,
  sectionIndex = -1,
  sectionVersion,
  currentSectionId
}) => {
  return (dispatch, getState) => {
    const { outputs, selections, sectionState, usages } = setupSection({
      sectionVersion
    });
    dispatch(addSessionSection({ sectionState, currentSectionId }));
    /**
     * selections must be added first as observations may select them, which may selected
     * usage calc inputs or outputs
     */
    dispatch(
      addSessionEntities({
        entityName: "selections",
        entities: selections,
        currentSectionId
      })
    );
    dispatch(
      addSessionEntities({
        entityName: "usages",
        entities: usages,
        currentSectionId
      })
    );
    dispatch(
      addSessionEntities({
        entityName: "outputs",
        entities: outputs,
        currentSectionId
      })
    );
  };
};

// export const initializeSessionSection = ({ activeProtocolId, sectionIndex, sectionVersion }) => {
//   return {
//     type: SESSION_INIT_SECTION,
//     payload: { activeProtocolId, sectionIndex, sectionVersion }
//   }
// }
export const addProtocolToSession = ({ protocolId, latestVersion }) => {
  return {
    type: SESSION_ADD_PROTOCOL,
    payload: { protocolId, latestVersion }
  };
};
export const removeEntitiesByProtocolId = ({ protocolId }) => {
  return {
    type: SESSION_REMOVE_ENTITIES_BY_PROTOCOL_ID,
    payload: { protocolId }
  };
};

export function addSessionSection({ sectionState, currentSectionId }) {
  return {
    type: SESSION_ADD_SECTION,
    payload: { sectionState, currentSectionId }
  };
}
// currentSectionId is used in protocol logice
export function addSessionEntities({ entityName, entities, currentSectionId }) {
  return {
    type: SESSION_ADD_ENTITIES,
    payload: { entityName, entities, currentSectionId }
  };
}

export function removeSessionEntitiesBySectionId({ sectionId }) {
  return (dispatch, getState) => {
    const entities = getState().session.entities;
    const outputWithSelectNextProtocol = R.values(entities.outputs).find(op => {
      /**
       * if the output matches the section we want to remove, is selected, has a nextId,
       * and that next id points to a different protocol, we need to remove that protocol from
       * the session
       */
      // we need to check if entities.sections[op.nextId] exists...if it doesn't, that means,
      // that protocol was never loaded
      return (
        op.sectionId === sectionId &&
        op.selected &&
        op.nextId &&
        entities.sections[op.nextId] &&
        entities.sections[op.nextId].protocolId !== op.protocolId
      );
    });
    outputWithSelectNextProtocol &&
      dispatch(
        removeEntitiesByProtocolId({
          protocolId:
            entities.sections[outputWithSelectNextProtocol.nextId].protocolId
        })
      );
    dispatch({
      type: SESSION_REMOVE_ENTITIES_BY_SECTION_ID,
      payload: { sectionId }
    });
  };
}

export function rehydrateSession({ sessionId }) {
  return (dispatch, getState) => {
    const customHandle = __FHIR__ && getState().patient.customHandle;
    return new Promise((resolve, reject) => {
      const args = {
        sessionId,
        ...((__FHIR__ && customHandle && { customHandle }) || {})
      };
      fetchSessionApi(args)
        .then(({ data }) => {
          if (data && data.id) {
            dispatch({
              type: SESSION_SET_SESSION,
              payload: {
                session: { ...data.state, userText: data.state_text }
              }
            });
            resolve({ ...data.state, userText: data.state_text });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}

export function updateSelection({
  sessionId,
  protocolId,
  sectionId,
  usageId,
  selectionId,
  type,
  updateObject
}) {
  return {
    type: SESSION_UPDATE_SELECTION,
    payload: {
      sessionId,
      protocolId,
      sectionId,
      usageId,
      selectionId,
      type,
      updateObject
    }
  };
}
export function updateSessionProp(prop, val) {
  return {
    type: SESSION_UPDATE_SESSION_PROP,
    payload: { prop, val }
  };
}

export function setOutputTab({ outputTabName }) {
  return {
    type: SESSION_SET_OUTPUT_TAB,
    payload: outputTabName
  };
}

export function clearSelectedStatesByUsage({ usageId }) {
  return (dispatch, getState) => {
    /**
     * if we unselect an output that a nextId that points to a different protocol we need to
     * remove that protocol from the section
     */
    const outputs = getState().session.entities.outputs;
    const sections = getState().session.entities.sections;
    dispatch({
      type: SESSION_CLEAR_SELECTIONS_BY_USAGE,
      payload: { usageId }
    });
    /**
     * we need to check if sections[op.nextId] exists. If it doesn't, that means that protocol was never loaded
     */
    const selectedOutputWithNextId = R.values(outputs).find(
      op =>
        op.usageId === usageId &&
        op.selected &&
        op.nextId &&
        sections[op.nextId] &&
        op.protocolId !== sections[op.nextId].protocolId
    );
    selectedOutputWithNextId &&
      dispatch(
        removeEntitiesByProtocolId({
          protocolId: sections[selectedOutputWithNextId.nextId].protocolId
        })
      );
  };
}
export const setSelected = ({ outputId, canSelectMultiple }) => {
  return {
    type: SESSION_SET_OUTPUT_SELECTED,
    payload: { outputId, canSelectMultiple }
  };
};
export const setUnselected = ({ outputId, sectionId }) => {
  return {
    type: SESSION_SET_OUTPUT_UNSELECTED,
    payload: { outputId, sectionId }
  };
};
export const toggleOutputSelected = ({ outputId, ...rest }) => {
  return {
    type: SESSION_TOGGLE_OUTPUT_SELECTED,
    payload: { outputId, ...rest }
  };
};
export function deselectUsageSelections({ folderId, usageId }) {
  return (dispatch, getState) => {
    const outputs = getState().session.entities.outputs;
    const usages = getState().session.entities.usages;
    const careUsageIdsInFolder = R.values(usages).reduce(
      (acc, usage) => (usage.folderId === folderId ? [...acc, usage.id] : acc),
      []
    );
    const outputsToDeselect = R.values(outputs).reduce(
      (acc, op) =>
        careUsageIdsInFolder.includes(op.usageId) && op.selected
          ? [...acc, op.id]
          : acc,
      []
    );

    outputsToDeselect.forEach(outputId => {
      dispatch({
        type: SESSION_SET_OUTPUT_UNSELECTED,
        payload: { outputId }
      });
    });
  };
}

export function selectQuestionAnswer({
  outputId,
  usageId,
  sectionId,
  selectionId
}) {
  return (dispatch, getState) => {
    dispatch(clearSelectedStatesByUsage({ usageId }));
    dispatch(setSelected({ outputId }));
  };
}

export function clearSession() {
  return {
    type: SESSION_CLEAR
  };
}
export function launchNextSection({ currentSectionId, nextSectionId }) {
  return {
    type: SESSION_LAUNCH_NEXT_SECTION,
    payload: { currentSectionId, nextSectionId }
  };
}

export function setCalcInput({ inputId, calcUsageId, selected }) {
  return {
    type: SESSION_SET_CALC_INPUT,
    payload: { inputId, calcUsageId, selected }
  };
}
export function setSelectedCalcInputsToUnselected({
  calcUsageId,
  inputGroupId
}) {
  return {
    type: SESSION_SET_SELECTED_INPUTS_TO_UNSELECTED,
    payload: { calcUsageId, inputGroupId }
  };
}
/**
 * Can set all inputs in calc or only the ones in an input group (optional)
 */
export function setAllCalcInputsToUnselected({
  calcUsageId,
  inputGroupId,
  multipleInputs
}) {
  return {
    type: SESSION_SET_ALL_INPUTS_TO_UNSELECTED,
    payload: { calcUsageId, multipleInputs }
  };
}

export function submitCalc({ calcUsageId }) {
  return {
    type: SESSION_SUBMIT_CALC,
    payload: { calcUsageId }
  };
}
export function updateEntityProp({ id, entityName, prop, value }) {
  return {
    type: SESSION_UPDATE_ENTITY_PROP,
    payload: { id, entityName, prop, value }
  };
}

export function setProviderNoteText({ providerNoteText }) {
  return {
    type: SESSION_ADD_PROVIDER_NOTE,
    payload: { providerNoteText }
  };
}

export function setProviderFollowUpAppt({ providerFollowUp }) {
  return {
    type: SESSION_ADD_PROVIDER_FOLLOW_UP,
    payload: { providerFollowUp }
  };
}

export function setCustomOutputItemProp({
  outputId,
  outputItemId,
  prop,
  value,
  ...rest
}) {
  return {
    type: SESSION_SET_OUTPUT_ITEM_PROP,
    payload: { outputId, outputItemId, prop, value, rest }
  };
}
export function setCustomOutputItemSelectedState({
  outputId,
  outputItemId,
  isSelected,
  ...rest
}) {
  return {
    type: SESSION_SET_OUTPUT_ITEM_PROP,
    payload: {
      outputId,
      outputItemId,
      prop: "isSelected",
      value: isSelected,
      rest
    }
  };
}

export function setRxSetSelectedState({
  rxSetId,
  groupId,
  prescriptions,
  optionSetIndex,
  optionIndex,
  selected
}) {
  return {
    type: SESSION_SET_RX_SET_SELECTED,
    payload: {
      rxSetId,
      groupId,
      prescriptions,
      optionSetIndex,
      optionIndex,
      selected
    }
  };
}

export function setRxSetMultipleSelectedState({
  rxSetId,
  groupId,
  prescriptions,
  optionSetIndex,
  optionIndex
}) {
  return {
    type: SESSION_SET_RX_SET_MULTIPLE_SELECTED,
    payload: {
      rxSetId,
      groupId,
      prescriptions,
      optionSetIndex,
      optionIndex
    }
  };
}
export const setUseProtocolVersion = ({ useProtocolVersion }) => ({
  type: SET_USE_PROTOCOL_VERSION,
  payload: { useProtocolVersion }
});
export const toggleUseProtocolVersion = () => (dispatch, getState) => {
  const useProtocolVersion = !getState().session.useProtocolVersion;
  dispatch(setUseProtocolVersion({ useProtocolVersion }));
};
export const setIsSelectedRX = () => ({
  type: SESSION_CLEAR_RX_SET,
  payload: {}
});
export function clearRxSelections() {
  return (dispatch, getState) => {
    dispatch(setIsSelectedRX());
  };
}
export const setIsSelectedOrders = () => ({
  type: SESSION_CLEAR_ORDERS_SET,
  payload: {}
});
export function clearOrdersSelections() {
  return (dispatch, getState) => {
    dispatch(setIsSelectedOrders());
  };
}

export const setMcgIndicationSession = ({ selections, hsim, edition }) => {
  return {
    type: SESSION_SELECT_MCG_INDICATION,
    payload: { selections, hsim, edition }
  };
};

export const updateMcgGuidelineSession = ({ hsim, isMet }) => {
  return {
    type: SESSION_UPDATE_MCG_GUIDELINE,
    payload: { hsim, isMet }
  };
};
export const setMcgGuidelineSession = ({ hsim, edition }) => {
  return {
    type: SESSION_SET_MCG_GUIDELINE,
    payload: { hsim, edition }
  };
};
export const setMcgSubmitSession = ({ hsim, selectionId }) => {
  return {
    type: SESSION_SET_MCG_GUIDELINE_SUBMIT,
    payload: { hsim, selectionId }
  };
};
export function updateReason({ text, id, type, submitted, isSelected }) {
  return {
    type: SESSION_UPDATE_REASON,
    payload: { text, id, type, submitted, isSelected }
  };
}
export const setLastSentPatientEdDoc = ({ doc, lastSentTime }) => {
  return {
    type: SESSION_LAST_SENT_PATIENTED_DOC,
    payload: { doc, lastSentTime }
  };
};
export const setLastSentClinicalDoc = ({ doc, lastSentTime }) => {
  return {
    type: SESSION_LAST_SENT_CLINICAL_DOC,
    payload: { doc, lastSentTime }
  };
};
// ------------------------------------
// Action
// ------------------------------------
const ACTION_HANDLERS = {
  [SESSION_CLEAR]: (state, action) => ({
    ...initialState,
    useProtocolVersion: state.useProtocolVersion
  }),
  [SESSION_SET_SESSION]: (state, action) =>
    Object.assign({}, INITIAL_SESSION, action.payload.session),
  [SESSION_UPDATE_SESSION_PROP]: (state, action) => {
    const { prop, val } = action.payload;
    return Object.assign({}, state, { [prop]: val });
  },
  [SESSION_SET_OUTPUT_TAB]: (state, action) => {
    return Object.assign({}, state, { activeOutputTab: action.payload });
  },
  [SESSION_INITIALIZE_SESSION]: (state, action) => {
    const { sessionId } = action.payload;
    return immutable.set(state, `id`, sessionId);
  },
  [SESSION_ADD_PROTOCOL]: (state, action) => {
    const { protocolId, latestVersion } = action.payload;
    const newObj = immutable.push(state, "protocolsOrder", protocolId);
    const newObj2 = Object.assign({}, newObj, { activeProtocolId: protocolId });
    const newObj3 = immutable.set(newObj2, `entities.protocols.${protocolId}`, {
      ...PROTOCOL_INITIAL_STATE,
      id: protocolId,
      latestVersion
    });
    return newObj3;
  },
  [SESSION_ADD_SECTION]: (state, action) => {
    const { sectionState, currentSectionId } = action.payload;
    const newState = R.clone(state);
    const sectionsOrder = dotProp.get(
      state,
      `entities.protocols.${sectionState.protocolId}.sectionsOrder`
    );
    const currentIndex = sectionsOrder.indexOf(currentSectionId);
    /**
    |--------------------------------------------------
    | !TODO! if we are removing sections here we need to make sure we remove everything
    | related to these sections (might already be doing this somewhere)
    |--------------------------------------------------
    */
    const sliceTo = currentIndex + 1;
    const newSectionsOrder = [
      ...sectionsOrder.slice(0, sliceTo),
      sectionState.id
    ];
    // const newObj = immutable.set(state, `entities.protocols.${sectionState.protocolId}.sectionsOrder`, newSectionsOrder)
    newState.entities.protocols[
      sectionState.protocolId
    ].sectionsOrder = newSectionsOrder;
    newState.entities.sections[sectionState.id] = sectionState;
    newState.entities.protocols[sectionState.protocolId].activeGroupId =
      sectionState.groupId;
    newState.entities.protocols[sectionState.protocolId].activeSectionId =
      sectionState.id;

    return newState;
  },
  [SESSION_ADD_ENTITIES]: (state, action) => {
    const { entityName, entities } = action.payload;
    return immutable.assign(state, `entities.${entityName}`, entities);
  },

  [SESSION_REMOVE_ENTITIES_BY_PROTOCOL_ID]: (state, action) => {
    const { protocolId } = action.payload;
    /**
     * Trying to avoid cloning entire state like this. Would like to find a better way
     * in the future
     */
    const newState = R.clone(state);
    newState.entities.outputs = R.values(newState.entities.outputs).reduce(
      (acc, output) => {
        if (output.protocolId !== protocolId) acc[output.id] = output;
        return acc;
      },
      {}
    );
    newState.entities.usages = R.values(newState.entities.usages).reduce(
      (acc, usage) => {
        if (usage.protocolId !== protocolId) acc[usage.id] = usage;
        return acc;
      },
      {}
    );
    newState.entities.sections = R.values(newState.entities.sections).reduce(
      (acc, section) => {
        if (section.protocolId !== protocolId) acc[section.id] = section;
        return acc;
      },
      {}
    );
    newState.entities.protocols = R.values(newState.entities.protocols).reduce(
      (acc, protocol) => {
        if (protocol.id !== protocolId) acc[protocol.id] = protocol;
        return acc;
      },
      {}
    );
    newState.protocolsOrder = R.without([protocolId], newState.protocolsOrder);
    return newState;
  },
  [SESSION_REMOVE_ENTITIES_BY_SECTION_ID]: (state, action) => {
    const { sectionId } = action.payload;
    /**
     * Trying to avoid cloning entire state like this. Would like to find a better way
     * in the future
     */
    const newState = R.clone(state);
    newState.entities.outputs = R.values(newState.entities.outputs).reduce(
      (acc, output) => {
        if (output.sectionId !== sectionId) acc[output.id] = output;
        return acc;
      },
      {}
    );
    newState.entities.usages = R.values(newState.entities.usages).reduce(
      (acc, usage) => {
        if (usage.sectionId !== sectionId) acc[usage.id] = usage;
        return acc;
      },
      {}
    );
    newState.entities.sections = R.values(newState.entities.sections).reduce(
      (acc, section) => {
        if (section.id !== sectionId) acc[section.id] = section;
        return acc;
      },
      {}
    );
    return newState;
  },
  [SESSION_CLEAR_SELECTIONS_BY_USAGE]: (state, action) => {
    const { usageId } = action.payload;
    const outputs = state.entities.outputs;
    const updatedOutputs = Object.keys(state.entities.outputs).reduce(
      (acc, outputId) => {
        if (outputs[outputId].usageId === usageId) {
          acc[outputId] = Object.assign({}, outputs[outputId], {
            selected: false
          });
        }
        return acc;
      },
      {}
    );
    return {
      ...state,
      entities: {
        ...state.entities,
        outputs: { ...state.entities.outputs, ...updatedOutputs }
      }
    };
  },
  [SESSION_SET_OUTPUT_SELECTED]: (state, action) => {
    const { outputId } = action.payload;
    return immutable.set(state, `entities.outputs.${outputId}.selected`, true);
  },
  [SESSION_SET_OUTPUT_UNSELECTED]: (state, action) => {
    const { outputId } = action.payload;
    return immutable.set(state, `entities.outputs.${outputId}.selected`, false);
  },
  [SESSION_TOGGLE_OUTPUT_SELECTED]: (state, action) => {
    const { outputId, ...rest } = action.payload;
    const output = dotProp.get(state, `entities.outputs.${outputId}`);
    const isSelected = output.selected;
    const updatedOutput = { ...output, selected: !isSelected, ...rest };
    return immutable.set(state, `entities.outputs.${outputId}`, updatedOutput);
  },
  [SESSION_DESELECT_OUTPUTS]: (state, action) => {
    const { folderId } = action.payload;
    const outputs = dotProp.get(state, `entities.outputs`);
    const usages = dotProp.get(state, `entities.usages`);

    const careUsageIdsInFolder = R.values(usages).reduce(
      (acc, usage) => (usage.folderId === folderId ? [...acc, usage.id] : acc),
      []
    );
    const outputsToDeselect = R.values(outputs).reduce(
      (acc, op) =>
        careUsageIdsInFolder.includes(op.usageId) && op.selected
          ? [...acc, op.id]
          : acc,
      []
    );

    const updatedOutputs = R.map(output => {
      if (outputsToDeselect.includes(output.id)) {
        return { ...output, selected: false };
      }
      return output;
    }, outputs);
    return immutable.set(state, `entities.outputs`, updatedOutputs);
  },
  [SESSION_SET_SELECTED_INPUTS_TO_UNSELECTED]: (state, action) => {
    const { calcUsageId, inputGroupId } = action.payload;
    const inputState = dotProp.get(
      state,
      `entities.usages.${calcUsageId}.inputs`
    );
    const updatedInputs = Object.keys(inputState).reduce((acc, inputId) => {
      acc[inputId] = inputState[inputId];
      /**
       * If we have a inputgroupId only set that one to unselected
       */
      if (inputGroupId) {
        if (inputState[inputId].inputGroupId === inputGroupId) {
          acc[inputId] = Object.assign({}, inputState[inputId], {
            selected: false
          });
        }
      } else if (acc[inputId].selected) {
        acc[inputId] = Object.assign({}, inputState[inputId], {
          selected: false
        });
      }
      return acc;
    }, {});
    return immutable.set(
      state,
      `entities.usages.${calcUsageId}.inputs`,
      updatedInputs
    );
  },
  [SESSION_SET_ALL_INPUTS_TO_UNSELECTED]: (state, action) => {
    const { calcUsageId, multipleInputs } = action.payload;
    const inputState = dotProp.get(
      state,
      `entities.usages.${calcUsageId}.inputs`
    );
    const updatedInputs = Object.keys(inputState).reduce((acc, inputId) => {
      acc[inputId] = inputState[inputId];
      const inputGroupId = inputState[inputId].inputGroupId;
      const isInMultipleInputGroup = multipleInputs.some(
        inputGroup => inputGroup.id === inputGroupId
      );
      const isUnanswered = inputState[inputId].selected === null;
      if (isInMultipleInputGroup && isUnanswered) {
        acc[inputId] = Object.assign({}, inputState[inputId], {
          selected: false
        });
      }
      return acc;
    }, {});
    return immutable.set(
      state,
      `entities.usages.${calcUsageId}.inputs`,
      updatedInputs
    );
  },
  [SESSION_SET_CALC_INPUT]: (state, action) => {
    const { inputId, calcUsageId, selected } = action.payload;
    return immutable.set(
      state,
      `entities.usages.${calcUsageId}.inputs.${inputId}.selected`,
      selected
    );
  },
  [SESSION_UPDATE_ENTITY_PROP]: (state, action) => {
    const { id, entityName, prop, value } = action.payload;
    return immutable.set(state, `entities.${entityName}.${id}.${prop}`, value);
  },
  [SESSION_SET_OUTPUT_ITEM_PROP]: (state, action) => {
    const { outputId, outputItemId, prop, value, rest } = action.payload;
    const outputItem = dotProp.get(state, `customOutputItems.${outputItemId}`);
    const outputItemUpdated = outputItem
      ? { ...outputItem, outputId, [prop]: value, ...rest }
      : { outputId, [prop]: value, ...rest };
    return immutable.set(
      state,
      `customOutputItems.${outputItemId}`,
      outputItemUpdated
    );
  },
  [SESSION_ADD_PROVIDER_NOTE]: (state, action) => {
    const { providerNoteText } = action.payload;
    return immutable.set(state, `providerNoteText`, providerNoteText);
  },
  [SESSION_ADD_PROVIDER_FOLLOW_UP]: (state, { payload }) => {
    return immutable.set(state, `providerFollowUp`, {
      ...state.providerFollowUp,
      ...payload.providerFollowUp
    });
  },
  [SESSION_SET_RX_SET_SELECTED]: (state, action) => {
    const {
      rxSetId,
      groupId,
      prescriptions,
      optionSetIndex,
      optionIndex,
      selected
    } = action.payload;
    const optionObj = {
      rxSetId,
      optionSetIndex,
      optionIndex,
      type: "radio",
      prescriptions,
      groupId,
      orMode: false,
      isSelected: !selected,
      isActive: true
    };
    const rxSetItems = dotProp.get(state, `rxSetItems.${rxSetId}`) || [];
    // When the option set is not in OR mode, we need to remove any radio options in OR
    // mode in the same group and any in the same option set
    // const filteredOptions = state.rxSetOptions.filter(option => option.groupId !== groupId || ((option.groupId === groupId && !option.orMode) && (option.optionSetIndex !== optionSetIndex)))
    const isInOptionSet = R.propEq("optionSetIndex", optionSetIndex);
    const isInGroup = R.propEq("groupId", groupId);
    const selectedOptionSetIndex = R.findIndex(
      R.allPass([isInOptionSet, isInGroup])
    )(rxSetItems);
    const selectedOptions =
      selectedOptionSetIndex < 0
        ? [...rxSetItems, optionObj]
        : [
            ...rxSetItems.slice(0, selectedOptionSetIndex),
            optionObj,
            ...rxSetItems.slice(selectedOptionSetIndex + 1)
          ];
    return immutable.set(state, `rxSetItems.${rxSetId}`, selectedOptions);
  },
  [SESSION_SET_RX_SET_MULTIPLE_SELECTED]: (state, action) => {
    const {
      rxSetId,
      groupId,
      optionIndex,
      optionSetIndex,
      prescriptions,
      isMapped
    } = action.payload;
    const rxSetItems = dotProp.get(state, `rxSetItems.${rxSetId}`, []);
    const isInOptionSet = R.propEq("optionSetIndex", optionSetIndex);
    const hasOptionIndex = R.propEq("optionIndex", optionIndex);
    const selectedOptionIndex = R.findIndex(
      R.allPass([isInOptionSet, hasOptionIndex])
    )(rxSetItems);
    const selected =
      selectedOptionIndex >= 0
        ? !rxSetItems[selectedOptionIndex].selected
        : true;
    const isSelected = __FHIR__ ? isMapped && selected : selected;
    const optionSetObj = {
      rxSetId,
      optionIndex,
      type: "checkbox",
      optionSetIndex,
      selected: isSelected,
      prescriptions,
      groupId,
      isActive: true
    };
    const selectedOptions =
      selectedOptionIndex < 0
        ? [...rxSetItems, optionSetObj]
        : [
            ...rxSetItems.slice(0, selectedOptionIndex),
            optionSetObj,
            ...rxSetItems.slice(selectedOptionIndex + 1)
          ];
    return immutable.set(state, `rxSetItems.${rxSetId}`, selectedOptions);
  },
  [SET_USE_PROTOCOL_VERSION]: (state, action) => {
    const { useProtocolVersion } = action.payload;
    return immutable.set(state, `useProtocolVersion`, useProtocolVersion);
  },
  // initialize mcg component
  [SESSION_SET_MCG_GUIDELINE]: (state, action) => {
    const { hsim, edition } = action.payload;
    const guideline = {
      isMet: false,
      selections: [],
      isSubmitted: false,
      selectedSelection: "",
      edition,
      hsim
    };
    return immutable.set(state, `mcgGuideline`, guideline);
  },
  // select and deselect mcg indication
  [SESSION_SELECT_MCG_INDICATION]: (state, action) => {
    const { selections } = action.payload;

    return immutable.set(state, `mcgGuideline.selections`, selections);
  },
  // used to update guideline with isMet status
  [SESSION_UPDATE_MCG_GUIDELINE]: (state, action) => {
    const { isMet, hsim } = action.payload;
    return immutable.assign(state, `mcgGuideline`, { isMet, hsim });
  },

  [SESSION_SET_MCG_GUIDELINE_SUBMIT]: (state, action) => {
    const { hsim, selectionId } = action.payload;
    const guideline = {
      hsim,
      isSubmitted: true,
      selectedSelection: selectionId
    };
    return immutable.assign(state, `mcgGuideline`, guideline);
  },
  [SESSION_CLEAR_RX_SET]: (state, action) => {
    return immutable.set(state, `rxSetItems`, {});
  },
  [SESSION_CLEAR_ORDERS_SET]: (state, action) => {
    return immutable.set(state, `customOutputItems`, {});
  }
};

const STATE_TEXT_ACTION_HANDLERS = {
  [SESSION_SET_SESSION]: (state, action) =>
    Object.assign({}, state, action.payload.session.userText),
  [SESSION_CLEAR]: (state, action) => ({ ...initialState.userText }),
  [SESSION_UPDATE_REASON]: (state, action) => {
    const { id } = action.payload;

    const defaultReason = state.reasons[id] ? {} : REASON_INITIAL_STATE;

    return immutable.set(state, `reasons.${id}`, {
      ...defaultReason,
      ...action.payload
    });
  },
  [SESSION_LAST_SENT_PATIENTED_DOC]: (state, action) => {
    return Object.assign({}, state, { lastSentPatientEdDoc: action.payload });
  },
  [SESSION_LAST_SENT_CLINICAL_DOC]: (state, action) => {
    return Object.assign({}, state, { lastSentClinicalDoc: action.payload });
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = INITIAL_SESSION;
export default function sessionReducer(state = initialState, action) {
  const stateHandler = ACTION_HANDLERS[action.type];
  const stateTextHandler = STATE_TEXT_ACTION_HANDLERS[action.type];
  const newState = stateHandler ? stateHandler(state, action) : state;
  const newStateText = stateTextHandler
    ? stateTextHandler(state.userText, action)
    : state.userText;

  if (newState.id) {
    const { userText, ...noUserText } = newState;
    // sessionStore.setItem(state.id, state)
    updateSession({ state: noUserText, state_text: newStateText });
  }
  return { ...newState, userText: newStateText };
}
