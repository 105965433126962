import React from "react";
import PropTypes from "prop-types";
import { setCalcInput } from "store/session";
import { connect } from "react-redux";
import dotProp from "dot-prop";
import classNames from "classnames";
import { Tooltip } from "components/ui/tooltip";
// import { submitCalc, setSelected } from 'store/session'

// const styles = theme => ({
//   button: {
//     margin: theme.spacing.unit,
//     height: '2rem'
//   },
//   input: {
//     display: 'none'
//   },
//   buttonSelected: {
//     margin: theme.spacing.unit,
//     color: 'white',
//     backgroundColor: '#2f7ec1',
//     height: '2rem'
//   }
// })

class ShowMultipleInput extends React.Component {
  toggleInput = ({ calcUsageId, inputId, inputGroupId, selected }) => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      /**
       * remove all selections from group
       */
      // this.props.setSelectedCalcInputsToUnselected({ calcUsageId, inputId, inputGroupId })
      this.props.setCalcInput({ calcUsageId, inputId, selected });
    };
  };

  render() {
    const {
      input,
      calcUsageId,
      inputState,
      inputGroupId,
      calculatorType
    } = this.props;
    const selected = inputState ? inputState.selected : null;

    const noButtonClasses = classNames(
      "button",
      "button_multi",
      "calculator-input_multi-button",
      {
        button_inverse: selected === null || selected === true,
        button_standard: selected === false
      }
    );
    const yesButtonClasses = classNames(
      "button",
      "button_multi",
      "calculator-input_multi-button",
      {
        button_inverse: selected === null || selected === false,
        button_standard: selected === true
      }
    );
    const calculatorInputClasses = classNames(
      "calculator-input",
      "row",
      "align-middle",
      "medium-unstack",
      {
        "calculator-input--red-flag": calculatorType === "redflag"
      }
    );
    return (
      <div key={input.id} className={calculatorInputClasses}>
        <div className="columns">
          <div>{input.title || "(No Title)"}</div>

          {input.subtitle && (
            <div style={{ fontSize: "12px", color: "#757E87" }}>
              {input.subtitle}
            </div>
          )}
        </div>
        <div className="calculator-input_value columns shrink">
          <button
            onClick={this.toggleInput({
              calcUsageId,
              inputId: input.id,
              selected: true,
              inputGroupId
            })}
            className={yesButtonClasses}
          >
            {" "}
            {__FHIR__ &&
              dotProp.get(inputState, "selectedByObservation") &&
              selected && (
                <div className="calculator-input_observation-icon-mult-wrap">
                  <Tooltip
                    id="ehr-doc-tooltip"
                    message="This selection was populated from the EHR"
                    place="top"
                  >
                    <i className="calculator-input_observation-icon-mult icon-ehr-information" />
                  </Tooltip>
                </div>
              )}
            Yes
          </button>
          <button
            style={{ marginRight: "1rem" }}
            onClick={this.toggleInput({
              calcUsageId,
              inputId: input.id,
              selected: false,
              inputGroupId
            })}
            className={noButtonClasses}
          >
            No
          </button>
        </div>
      </div>
    );
  }
}

ShowMultipleInput.propTypes = {
  input: PropTypes.object.isRequired,
  setCalcInput: PropTypes.func,
  calcUsageId: PropTypes.string,
  inputState: PropTypes.object,
  inputGroupId: PropTypes.string,
  calculatorType: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  // activeMoreInfoId: state.protocol.activeMoreInfoId,
  inputState: dotProp.get(
    state,
    `session.entities.usages.${props.calcUsageId}.inputs.${props.input.id}`
  )
});
const mapDispatchToProps = {
  // setActiveMoreInfo,
  setCalcInput
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowMultipleInput);
