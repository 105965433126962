import Sockette from "sockette";
import { info } from "react-notification-system-redux";
import { logSentryError } from "helpers/logging/error";
import dotProp from "dot-prop";

import { setSocketConnectedState, addCommentToProtocol } from "store/comments";
class CommentSocketClass {
  dispatch = null;
  getState = null;
  ws = null;

  sendMessage = ({ action, data }) => {
    const connected =
      this.getState && this.getState().comments.socketIsConnected;
    if (connected) {
      this.ws.json({ action, data });
    } else {
      logSentryError(
        new Error("Attempted to connect to socket which is not connected")
      );
    }
  };
  updateProtocols = () => {
    const connected =
      this.getState && this.getState().comments.socketIsConnected;
    if (connected) {
      const protocols = this.getState().session.protocolsOrder;
      this.ws.json({ action: "updateProtocols", protocols: protocols || [] });
    }
  };
  init = ({ protocolId, dispatch, getState }) => {
    this.dispatch = dispatch;
    this.getState = getState;
    const connected = getState().comments.socketIsConnected;
    const ecToken = getState().user.token;
    if (!connected && ecToken) {
      this.ws = new Sockette(`${COMMENT_SOCKET}?t=${ecToken}`, {
        timeout: 5e3,
        maxAttempts: 10,
        onopen: e => {
          this.dispatch(setSocketConnectedState({ socketIsConnected: true }));
          const protocols = protocolId ? [protocolId] : [];
          this.ws.json({ action: "updateProtocols", protocols });
        },
        onmessage: e => {
          const data = JSON.parse(e.data);
          const { comment, action, replyId, suppressNotification } = data;
          // alias to check and see if it's our own comment
          const alias = dotProp.get(comment, "author.alias");
          if (
            (action === "NEW_COMMENT" || action === "NEW_REPLY") &&
            alias !== getState().user.alias
          ) {
            this.dispatch(addCommentToProtocol({ comment, replace: replyId }));
            if (!suppressNotification) {
              const author =
                action === "NEW_COMMENT"
                  ? comment.author
                  : comment.replies.find(reply => reply.id === replyId).author;
              const title = `New ${
                action === "NEW_COMMENT" ? "Comment" : "Reply"
              } from ${author.firstName} ${author.lastName} `;
              const message = comment.markdown;
              const notificationOpts = {
                uid: comment.id,
                title,
                message,
                position: "tr"
              };

              this.dispatch(info(notificationOpts));
            }
          }
        },
        onreconnect: e => console.log("Reconnecting..."),
        onmaximum: e => {
          console.log("Stop Attempting!", e);
        },
        onclose: e => {
          console.log("Closed!", e);
          this.dispatch(setSocketConnectedState({ socketIsConnected: false }));
        },
        onerror: e => console.log("Error:", e)
      });
    } else {
      console.log("could not init. hasEcToken?", !!ecToken);
    }
  };
}

const CommentSocket = new CommentSocketClass();
export default CommentSocket;
