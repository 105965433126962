import React, { Component } from "react";
import PropTypes from "prop-types";

const Embed = props => {
  var videoSrc = `https://player.vimeo.com/video/${props.video}?portrait=0`;
  return (
    <div className="video-container">
      <iframe
        src={videoSrc}
        className="video-player"
        width="640"
        height="360"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
      />
    </div>
  );
};
Embed.propTypes = {
  video: PropTypes.string
};
class Video extends Component {
  render() {
    let { content, title, video } = this.props;
    return (
      <div className="video-modal_container">
        <h5 className="video-modal_title">{title}</h5>
        <p>{content}</p>
        <Embed video={video} />
      </div>
    );
  }
}
Video.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.string
};

export default Video;
