import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import MoreInfo from "./moreInfo/MoreInfo";
import OutputTabs from "./outPutTabs/OutputTabs";
import classNames from "classnames";
import McgRightColumn from "integrations/mcg/mcgRightColumn/McgRightColumn";

export class ProtocolRightCol extends Component {
  static propTypes = {
    protocolState: PropTypes.object,
    activeMoreInfo: PropTypes.object,
    activeProtocolId: PropTypes.string,
    sideBarIsActive: PropTypes.bool,
    showMcgRightColumn: PropTypes.bool,
    activeOutputTab: PropTypes.string
  };

  render() {
    const {
      protocolState,
      activeProtocolId,
      activeMoreInfo,
      sideBarIsActive,
      showMcgRightColumn,
      activeOutputTab
    } = this.props;
    const sideBarClasses = classNames("protocol_side-bar", {
      "protocol_side-bar--active":
        sideBarIsActive || (activeProtocolId && activeMoreInfo),
      "protocol_side-bar--mcg": showMcgRightColumn
    });

    const activeTab = protocolState.activeOutputTab || activeOutputTab;
    return (
      <div className={sideBarClasses}>
        <div className="protocol_right-col-wrap" style={{ height: "100%" }}>
          {showMcgRightColumn ? (
            <McgRightColumn />
          ) : activeProtocolId && activeMoreInfo ? (
            <MoreInfo moreInfo={activeMoreInfo} />
          ) : (
            <OutputTabs activeOutputTab={activeTab} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeProtocolId: state.session.activeProtocolId,
  // comment TODO what to do about tabb state here?
  activeTab: state.session.activeOutputTab,
  protocolState:
    state.session.entities.protocols[state.session.activeProtocolId],
  activeMoreInfo: state.protocol.activeMoreInfo,
  sideBarIsActive: state.protocol.sideBarIsActive,
  showMcgRightColumn:
    state.mcg.activeMetaData.length > 0 || state.mcg.isFetchingMeta
});

export default connect(mapStateToProps)(ProtocolRightCol);
