import React from "react";
import PropTypes from "prop-types";
import MarkdownRender from "utility/markdownRender";
import ShowSingleInput from "./ShowSingleInput";
import MoreInfoIcon from "../moreInfo/MoreInfoIcon";

export const ShowInputGroup = ({ inputGroup, index, calcUsageId }) => {
  return (
    <div key={inputGroup.id} className="calculator-input-group row collapse">
      <div className="columns medium-6 small-12">
        <div className="calculator-input-group_title-container row collapse align-middle">
          <div className="columns">
            <h5 className="calculator-input-group_title">
              {`${index + 1}.`} {inputGroup.title}{" "}
              <span className="calculator-input-group_asterisk">*</span>
            </h5>
          </div>
          <div className="columns shrink calculator-input-group_more-info">
            {inputGroup.evidence && (
              <MoreInfoIcon evidence={inputGroup.evidence} />
            )}
          </div>
        </div>
        <h6 className="calculator-input-group_subtitle">
          {inputGroup.subtitle}
        </h6>
        <div className="calculator-input-group_body">
          <MarkdownRender
            className="calculator-item_markdown"
            source={
              inputGroup.description !== "(Click here to edit description text)"
                ? inputGroup.description
                : ""
            }
            escapeHtml
          />
        </div>
      </div>
      <div className="columns medium-6 small-12 calculator-input-group">
        {inputGroup.inputs &&
          inputGroup.inputs.map(input => (
            <ShowSingleInput
              key={input.id}
              input={input}
              calcUsageId={calcUsageId}
              inputGroupId={inputGroup.id}
            />
          ))}
      </div>
    </div>
  );
};

ShowInputGroup.propTypes = {
  inputGroup: PropTypes.object.isRequired,
  index: PropTypes.number,
  calcUsageId: PropTypes.string
};

export default ShowInputGroup;
