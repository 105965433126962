import "./rhlconfig.js";
import React from "react";
import ReactDOM from "react-dom";
import { history } from "myHistory";
import * as Sentry from "@sentry/browser";
import "styles/core.scss";
import { store, bootstrappingFinishedPromise } from "store/store";
import AppContainer from "./containers/AppContainer";
import { MOUNT_NODE } from "constants/react";
import "./devtools";
import "core-js/stable";
import "regenerator-runtime/runtime";

const baseEnv =
  (__ALPHA__ && "alpha") ||
  (__PROD__ && "prod") ||
  (__DEV__ && "dev") ||
  "none";
const fhirEnv = (__FHIR__ && (FHIR_ENVIRONMENT || "-fhir")) || "";

window.onerror = error => {
  console.warn("Error", error);
  return true;
};

Sentry.init({
  dsn: SENTRY_DSN,
  release: RELEASE_STRING,
  maxBreadcrumbs: 50,
  environment: baseEnv + fhirEnv,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (
      __DEV__ &&
      event.exception &&
      window.location.search.includes("SENTRY_MODAL")
    ) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  }
  // httpsProxy: 'sentry.evidence.care'
});

if (!__FHIR__) {
  const reactGa = require("react-ga");
  reactGa.default.initialize("UA-74164913-2");
}

// ========================================================
// Render Setup
// ========================================================

const renderAppContainerInDOM = () => {
  ReactDOM.render(
    <AppContainer
      store={store}
      bootstrappingFinishedPromise={bootstrappingFinishedPromise}
    />,
    MOUNT_NODE
  );
};

// not best practice to use the store like this, should convert to action
history.listen(location => {
  if (!__FHIR__) {
    import("react-ga").then(reactGa =>
      reactGa.pageview(window.location.pathname + window.location.search)
    );
  }
});

// ========================================================
// Go!
// ========================================================
renderAppContainerInDOM();
