import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Accreditation from "./Accreditation";
import MarkdownRender from "utility/markdownRender";
import { alphaSortArrayOfObjectsByProp } from "helpers/util";

export class Disclosure extends Component {
  state = {
    open: false
  };
  toggleSection = () => {
    this.setState({ open: !this.state.open });
  };
  render() {
    const { disclosure } = this.props;
    const expandClasses = classNames("cme-disclosure_header-expand-icon", {
      "icon-expand": !this.state.open,
      "icon-expanded": this.state.open
    });
    const headerClasses = classNames("cme-disclosure_header", {
      "cme-disclosure_text--opened": this.state.open
    });
    return (
      <div className="cme-disclosure_text">
        <div className={headerClasses} onClick={this.toggleSection}>
          <h5 className="cme-disclosure_title">
            {disclosure.accreditor.title}
          </h5>
          <div className={expandClasses} />
        </div>
        {this.state.open && (
          <div className="cme-disclosure_body">
            <MarkdownRender softBreak="br" source={disclosure.text} />
            {alphaSortArrayOfObjectsByProp({
              arrayToSort: disclosure.accreditor.accreditations,
              prop: "title"
            }).map((cred, index) => (
              <Accreditation
                medSociety={disclosure.accreditor}
                cred={cred}
                key={cred.id}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

Disclosure.propTypes = {
  disclosure: PropTypes.object
};

export default Disclosure;
