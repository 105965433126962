// React/Redux
import React, { Component } from "react";
// Helpful libs
import PropTypes from "prop-types";

import CitationList from "./CitationList";
import PatternFill from "components/ui/PatternFill";

class GraphSetItem extends Component {
  constructor() {
    super();

    this.state = {
      graphs: []
    };
  }

  componentDidMount = () => {
    this.setState({
      graphs:
        this.props.item.data.stats && Array.isArray(this.props.item.data.stats)
          ? this.props.item.data.stats
          : []
    });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.hasOwnProperty("stats")) {
      this.setState({
        graphs:
          nextProps.item.data.stats && Array.isArray(nextProps.item.data.stats)
            ? nextProps.item.data.stats
            : []
      });
    }
  };

  cleanData = (data, denom) => {
    const newData = data.reduce((formattedData, item) => {
      let sum = denom;
      if (!denom || denom <= 0) {
        sum += parseInt(item.value);
      }
      item.percentage = (item.value / sum) * 100;
      return formattedData.concat(item);
    }, []);
    return newData;
  };

  getColumnWidth = value => {
    if (value === "half") {
      return "columns";
    } else if (value === "full") {
      return "small-12 columns";
    }
  };

  tenByTenGraph = (title, denom, data) => {
    const { isInBlackAndWhiteMode } = this.props;
    let cleanedData = this.cleanData(data, denom);
    let items = [];
    for (let num = 0; num < cleanedData.length; num++) {
      const graphItemClass = isInBlackAndWhiteMode
        ? "ten-by-ten-graph-item"
        : `color ${cleanedData[num].color || "green"}`;
      for (let i = 0; i < cleanedData[num].percentage; i++) {
        items.push(
          <li key={`tenByTenGraph-item-${num}-${i}`} className={graphItemClass}>
            {isInBlackAndWhiteMode && (
              <PatternFill itemColor={cleanedData[num].color} />
            )}
          </li>
        );
      }
    }
    return <ul className="ten-by-ten-graph">{items}</ul>;
  };

  barGraph = (
    label,
    color,
    percentValue,
    value,
    index,
    graphIndex,
    graphsLength
  ) => {
    const { isInBlackAndWhiteMode } = this.props;
    const style = {
      width: percentValue + "%",
      height: isInBlackAndWhiteMode ? "10px" : "6px"
    };
    return (
      <div key={`barGrapItem-${index}`} className="row bar-graph">
        <div className="small-12 columns">
          <span className="bar-graph_title">{label}</span>
        </div>
        <div className="small-9 columns">
          <div
            className="bar-graph_progress bar-graph_progress--small"
            role="progressbar"
          >
            {!isInBlackAndWhiteMode ? (
              <div
                className={
                  "bar-graph_progress-meter bar-graph_progress-meter--" + color
                }
                style={style}
              />
            ) : (
              <div
                className={
                  "bar-graph_progress-meter bar-graph_progress-meter--black-and-white"
                }
                style={style}
              >
                <PatternFill itemColor={color} height={style.height} />
              </div>
            )}
          </div>
        </div>

        <div className="small-3 columns text-right">
          <span className="bar-graph_progress-value">{value}</span>
        </div>
      </div>
    );
  };

  barGraphs = (title, denom, data, graphIndex) => {
    let cleanedData = this.cleanData(data, denom);

    let graphs = cleanedData.map((item, index) => {
      return this.barGraph(
        item.label,
        item.color,
        item.percentage,
        item.value,
        index,
        graphIndex,
        cleanedData.length
      );
    });

    return (
      <div className="bar-graphs graph">
        <h6 className="graph_header">{title}</h6>

        {graphs}
      </div>
    );
  };

  render() {
    let graphs, bar, tenByTen;

    graphs = this.state.graphs.map((graph, graphIndex) => {
      if (graph.type === "barGraph") {
        let styles = this.getColumnWidth("full");
        if (graph.tenByTen) {
          styles = this.getColumnWidth("half");

          tenByTen = (
            <div className={styles}>
              {this.tenByTenGraph(graph.title, graph.denom, graph.items)}
            </div>
          );
        }

        bar = (
          <div className={styles}>
            {this.barGraphs(graph.title, graph.denom, graph.items, graphIndex)}
          </div>
        );

        return (
          <div key={graphIndex + graph.type}>
            <div className="row collapse bar-graph large-unstack">
              {graph.tenByTen && tenByTen}
              {bar}
            </div>
          </div>
        );
      } else if (graph.type === "pieGraph") {
        let pieCharts = graph.items.map((chart, chartIndex) => {
          let barStyles = {
            strokeDasharray: chart.value + " 100"
          };
          let coloredBarClasses = "pie-chart_bar pie-chart_bar--" + chart.color;
          return (
            <div
              className="pie-chart column xsmall-centered"
              key={`chart-${chart.color}-${chart.label}-${chart.value}`}
            >
              <svg className="pie-chart_svg" viewBox="0 0 32 32">
                <g>
                  <circle className="pie-chart_empty" r="16" cx="16" cy="16" />
                  <circle
                    className={coloredBarClasses}
                    transform="rotate(-90, 16, 16)"
                    style={barStyles}
                    r="16"
                    cx="16"
                    cy="16"
                  />
                  <text x="50%" y="50%" textAnchor="middle">
                    <tspan
                      className="pie-chart_percent"
                      x="50%"
                      y="18"
                      textAnchor="middle"
                    >
                      {chart.value + "%"}
                    </tspan>
                  </text>
                </g>
              </svg>
              <div className="pie-chart_label">{chart.label}</div>
            </div>
          );
        });
        return (
          <div key={graphIndex + graph.type}>
            <div className="graph">
              <h6 className="graph_header">{graph.title}</h6>
              <div className="row xsmall-up-2 large-up-3 collapse">
                {pieCharts && pieCharts}
              </div>
            </div>
          </div>
        );
      }
    });
    const { item } = this.props;
    return (
      <div className="stats-container">
        {graphs}
        {item && item.citations && <CitationList citations={item.citations} />}
      </div>
    );
  }
}
GraphSetItem.propTypes = {
  item: PropTypes.object.isRequired,
  isInBlackAndWhiteMode: PropTypes.bool
};

export default GraphSetItem;
