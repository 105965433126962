import { sendPipelineRequest } from "lib/ec/datapipeline";
// ------------------------------------
// Constants
// ------------------------------------
export const PIPELINE_SEND = "PIPELINE_SEND";
export const PIPELINE_SEND_SUCCESS = "PIPELINE_SEND_SUCCESS";
export const PIPELINE_SEND_FAILURE = "PIPELINE_SEND_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function pipelineRequest(payload) {
  return async (dispatch, getState) => {
    dispatch({
      type: PIPELINE_SEND
    });
    const state = getState();
    const user = {
      id: state.user.user_uid,
      alias: state.user.alias,
      name: `${state.user.first_name} ${state.user.last_name}`
    };
    if (state.user.email) {
      user["email"] = state.user.email;
    }

    const isInSession = state.session.id;
    const { message = {} } = payload;

    if (isInSession) {
      const {
        activeSectionId = "",
        latestVersion = {}
      } = state.session.entities.protocols[state.session.activeProtocolId];

      message["session_id"] = state.session.id || "";
      message["protocol_id"] = state.session.activeProtocolId || "";
      message["section_id"] = activeSectionId;
      message["protocolversion_id"] = latestVersion ? latestVersion.id : "";
      message["protocolversion_doc"] = latestVersion
        ? latestVersion.renderedDocument
        : "";
    }
    if (__FHIR__) {
      const facilityId =
        state.patient.encounter.location &&
        state.patient.encounter.location.length
          ? state.patient.encounter.location[0].location.reference
          : "";
      message["facility_id"] = facilityId;
    }
    try {
      sendPipelineRequest({ ...payload, message, user })
        .then(() => {
          dispatch({
            type: PIPELINE_SEND_SUCCESS
          });
        })
        .catch(() => {
          dispatch({
            type: PIPELINE_SEND_FAILURE
          });
        });
    } catch (err) {
      console.error(err, "data pipeline only accepts ec tokens");
    }
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PIPELINE_SEND]: (state, action) => ({ ...state, isSending: true }),
  [PIPELINE_SEND_SUCCESS]: (state, action) => ({ ...state, isSending: false }),
  [PIPELINE_SEND_FAILURE]: (state, action) => ({ ...state, isSending: false })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isSending: false
};
export default function pipelineReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
