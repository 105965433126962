import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideModal } from "store/modal/actions";
import { startTour } from "store/tour/actions";
import MarkdownRender from "utility/markdownRender";
import { history } from "myHistory";
import { editCloudinarySrc } from "helpers/urls/cloudinary";

import "./Sherpa.scss";

/**************************************************************
 * Sherpa Modal
 *
 * The Sherpa modal is a special type of modal as it is dynamically
 * configured by the Sherpa service. The Sherpa service might produce
 * many modal configurations and this component consumes those configurations
 * as props
 **************************************************************/

export default function SherpaModal(props) {
  const {
    message,
    leftButtonActive,
    leftButtonColor,
    leftButtonAction,
    leftButtonText,
    rightButtonActive,
    rightButtonText,
    rightButtonAction,
    rightButtonColor,
    imageURL
  } = props;

  return (
    <div className="sherpa-modal_content-container">
      <div>
        {imageURL && (
          <img
            className="sherpa-modal_image"
            alt="sherpa-image"
            src={`${editCloudinarySrc(imageURL, "q_60,w_1920,c_limit", "jpg")}`}
          />
        )}
      </div>
      {message && (
        <div className="sherpa-modal_message-container">
          <MarkdownRender source={message} />
        </div>
      )}
      <div className="sherpa-modal_spacer" />
      <div className="sherpa-modal_button-container">
        {leftButtonActive && (
          <ConnectedModalButton
            color={leftButtonColor.hexCode}
            text={leftButtonText}
            action={leftButtonAction}
          />
        )}
        {rightButtonActive && (
          <ConnectedModalButton
            color={rightButtonColor.hexCode}
            text={rightButtonText}
            action={rightButtonAction}
          />
        )}
      </div>
    </div>
  );
}

SherpaModal.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
  video: PropTypes.string,
  leftButtonActive: PropTypes.bool,
  leftButtonColor: PropTypes.object,
  leftButtonAction: PropTypes.object,
  leftButtonText: PropTypes.string,
  rightButtonActive: PropTypes.bool,
  rightButtonText: PropTypes.string,
  rightButtonAction: PropTypes.object,
  rightButtonColor: PropTypes.object,
  imageURL: PropTypes.string
};

/**************************************************************
 * Modal Button
 *
 * Takes one of the modal button configs and figures
 * out how to display and respond to user input
 **************************************************************/

const ModalButton = ({
  color = "#8dc63f",
  text,
  action = {},
  hideModal,
  startTour
}) => {
  return (
    <button
      style={{
        backgroundColor: color,
        color: idealTextColor(color)
      }}
      className="sherpa-modal_button"
      onClick={() => {
        const { action: actionType = "CLOSE_MODAL", actionInput } = action;
        switch (actionType) {
          case "START_FLOW": {
            hideModal();
            if (actionInput) {
              startTour(actionInput);
            }
            break;
          }

          case "GOTO_ROUTE": {
            hideModal();
            if (
              actionInput !== "" &&
              history.location.pathname !== actionInput
            ) {
              history.push(`${actionInput}`);
            }
            break;
          }

          case "CLOSE_MODAL": {
            hideModal();
            break;
          }
        }
      }}
    >
      {text}
    </button>
  );
};

const ConnectedModalButton = connect(
  null,
  { hideModal, startTour }
)(ModalButton);

ModalButton.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.object,
  hideModal: PropTypes.func,
  startTour: PropTypes.func
};

/**************************************************************
 * Utilties
 *
 * todo move to own util directory
 **************************************************************/

// taken from https://stackoverflow.com/questions/4726344/how-do-i-change-text-color-determined-by-the-background-color
const nThreshold = 85;
function idealTextColor(bgColor) {
  const bgDelta =
    parseInt(bgColor.substring(1, 3), 16) * 0.299 +
    parseInt(bgColor.substring(3, 5), 16) * 0.587 +
    parseInt(bgColor.substring(5, 7), 16) * 0.114;
  return 255 - bgDelta < nThreshold ? "#000000" : "#ffffff";
}
