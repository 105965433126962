import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import dotProp from "dot-prop";
import { setCustomOutputItemSelectedState } from "store/session";
import CommentBubble from "components/protocol/comments/CommentBubble";
import { pipelineRequest } from "store/pipeline";
import { EVENT_FEATURE_ORDERS_SELECT } from "../../constants/broadcastEventTypes";
import { buildTooltipMessage } from "helpers/ehr/fhir";

export class OrderString extends React.Component {
  handleChange = e => {
    e.stopPropagation();
    const {
      orderString,
      setCustomOutputItemSelectedState,
      customItemState,
      crosswalk,
      pipelineRequest
    } = this.props;
    const orderMap = __FHIR__ && crosswalk;
    const isMapped = __FHIR__ && orderMap[orderString.id];
    pipelineRequest({
      action: EVENT_FEATURE_ORDERS_SELECT,
      message: {
        orderstring_id: orderString.id
      }
    });
    const itemIsSelected = customItemState ? customItemState.isSelected : false;
    if (__FHIR__) {
      setCustomOutputItemSelectedState({
        outputId: "",
        outputItemId: orderString.id,
        isSelected: !itemIsSelected && !!isMapped,
        itemType: "order"
      });
    } else {
      setCustomOutputItemSelectedState({
        outputId: "",
        outputItemId: orderString.id,
        isSelected: !itemIsSelected,
        itemType: "order"
      });
    }
  };

  stopChange = e => {
    e.stopPropagation();
  };

  getPropName = prop => {
    const { orderString } = this.props;
    return dotProp.get(orderString, `${prop}.name`) || "";
  };

  render() {
    const {
      orderString,
      customItemState,
      sortBy,
      duplicateTitles,
      isMapped,
      isSent,
      sent_at,
      isDraft,
      isDraftReviewer
    } = this.props;
    const hasOnlyOneOrderstring = !duplicateTitles.includes(orderString.title);
    const isSelected = dotProp.get(customItemState, "isSelected");
    const selectClasses = classNames("orderstring_select-icon", {
      "icon-checkbox":
        (__FHIR__ &&
          ((!isSelected || (isMapped && isDraft && !isDraftReviewer)) &&
            sortBy !== "sent")) ||
        !isSelected,
      "orderstring_select-icon--selected icon-checkbox-checked":
        (__FHIR__ &&
          (isSelected &&
            ((isMapped && isDraft && isDraftReviewer) ||
              (isMapped && !isDraft)) &&
            sortBy !== "sent")) ||
        isSelected
    });
    const orderStringClasses = classNames("orderstring_strings", {
      "orderstring_strings--disabled":
        __FHIR__ && (!isMapped || (isMapped && isDraft && !isDraftReviewer)),
      "orderstring_strings--selected":
        sortBy === "selected" ||
        (isSelected &&
          ((isMapped && isDraft && isDraftReviewer) || (isMapped && !isDraft))),
      "orderstring_strings--single-order": hasOnlyOneOrderstring
    });
    const orderStringWrapperClasses = classNames(
      "orderstring",
      "collapse",
      "align-middle",
      {
        "orderstring--single-order": hasOnlyOneOrderstring
      }
    );
    const selectContainerClasses = classNames(
      "orderstring_select",
      "columns",
      "shrink",
      {
        "orderstring_select--single-order": hasOnlyOneOrderstring
      }
    );
    const orderStringTitleClasses = classNames(
      "orderstring_title",
      "orderstring_title--single-order",
      {
        "orderstring_title--disabled":
          __FHIR__ && (!isMapped || (isMapped && isDraft && !isDraftReviewer)),
        "orderstring_title--draft":
          __FHIR__ && (isMapped && isDraft && isDraftReviewer)
      }
    );
    const showOrderString =
      (customItemState &&
        customItemState.isSelected &&
        sortBy === "selected") ||
      (sortBy === "sent" && isSent) ||
      sortBy === "recommended";

    const order = (
      <div className="orderstring_content-container">
        <CommentBubble
          locationDescription="Order String"
          internalName={orderString.internalName}
          style={{ marginLeft: "-1rem", marginRight: "1rem" }}
          itemId={orderString.id}
          type="orderstring"
          condensed
        />
        {hasOnlyOneOrderstring && (
          <div
            title={
              __FHIR__
                ? buildTooltipMessage(isMapped, isDraft, isDraftReviewer)
                : orderString.title
            }
            className={orderStringTitleClasses}
          >
            {orderString.title}
          </div>
        )}
        <div className={orderStringClasses}>
          {this.getPropName("contrastDetail") ? (
            <span className="orderstring_detail-wrapper">
              {orderString.category === "imaging" &&
                `CONTRAST DETAIL: ${this.getPropName("contrastDetail")}`}
            </span>
          ) : null}
          {this.getPropName("source") && orderString.category === "lab" ? (
            <span className="orderstring_detail-wrapper">{`SOURCE: ${this.getPropName(
              "source"
            )}`}</span>
          ) : null}
          {orderString.laterality && orderString.category !== "lab" ? (
            <span className="orderstring_detail-wrapper">{`LATERALITY: ${orderString.laterality}  `}</span>
          ) : null}
          {this.getPropName("priority") ? (
            <span className="orderstring_detail-wrapper">{`PRIORITY: ${this.getPropName(
              "priority"
            )}`}</span>
          ) : null}
          {this.getPropName("frequency") ? (
            <span className="orderstring_detail-wrapper">{`FREQUENCY: ${this.getPropName(
              "frequency"
            )}`}</span>
          ) : null}
          {this.getPropName("duration") ? (
            <span className="orderstring_detail-wrapper">{`DURATION: ${this.getPropName(
              "duration"
            )}`}</span>
          ) : null}
          {orderString.specialInstructions ? (
            <span className="orderstring_detail-wrapper">{`SPECIAL INSTRUCTIONS: ${orderString.specialInstructions}`}</span>
          ) : null}
        </div>
        <div className="orderstring_title-sent-time">
          {isSent && "sent " + sent_at}
        </div>
      </div>
    );

    if (__FHIR__) {
      const orderMessage = buildTooltipMessage(
        isMapped,
        isDraft,
        isDraftReviewer
      );
      return showOrderString ? (
        <div
          title={orderMessage}
          className={orderStringWrapperClasses}
          onClick={sortBy !== "sent" ? this.handleChange : this.stopChange}
        >
          <div className={selectContainerClasses}>
            <div className={selectClasses} />
          </div>
          {order}
        </div>
      ) : null;
    } else {
      return showOrderString ? (
        <div className={orderStringWrapperClasses} onClick={this.handleChange}>
          <div className={selectContainerClasses}>
            <div className={selectClasses} />
          </div>
          {order}
        </div>
      ) : null;
    }
  }
}

OrderString.propTypes = {
  orderString: PropTypes.object,
  setCustomOutputItemSelectedState: PropTypes.func,
  customItemState: PropTypes.object,
  sortBy: PropTypes.string,
  duplicateTitles: PropTypes.array,
  isMapped: PropTypes.bool,
  isDraft: PropTypes.bool,
  isDraftReviewer: PropTypes.bool,
  isSent: PropTypes.bool,
  sent_at: PropTypes.string,
  crosswalk: PropTypes.object,
  pipelineRequest: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  customItemState: dotProp.get(
    state,
    `session.customOutputItems.${props.orderString.id}`
  ),
  crosswalk: state.FHIR_orders.crosswalk
});

const mapDispatchToProps = {
  setCustomOutputItemSelectedState,
  pipelineRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderString);
