import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AccountForm from "./AccountEditAvatar";
import AccountDetailsForm from "./AccountDetailsForm";
import { loadUserProfile } from "store/user/actions";

export class Profile extends Component {
  state = {
    profileLoaded: false
  };

  componentDidMount() {
    const { loadUserProfile } = this.props;

    // Refreshing the page would load empty values
    // as formik (as it's used in this form) is not a controlled component
    // and can only load initial values from props
    // Therefor we can postpone loading the form til after we have the initial values
    loadUserProfile().then(() => {
      this.setState({ profileLoaded: true });
    });
  }

  render() {
    const { profileLoaded } = this.state;

    return (
      <div className="account-col">
        <h2 className="account_title">My Profile</h2>
        {profileLoaded && (
          <div className="row">
            <div className="small-12 medium-4 columns">
              <AccountForm />
            </div>
            <div className="small-12 medium-8 columns">
              <AccountDetailsForm />
            </div>
          </div>
        )}
      </div>
    );
  }
}
Profile.propTypes = {
  loadUserProfile: PropTypes.func
};

const mapDispatchToProps = {
  loadUserProfile
};

export default connect(
  null,
  mapDispatchToProps
)(Profile);
