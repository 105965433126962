import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import unified from "unified";
import rehype2react from "rehype-react";
import classname from "classnames";
import { debounce } from "lodash";

import { scrollIntoView } from "helpers/dom/scroll";
import { loadHelpCenterArticle } from "store/helpCenter/actions";
import { selectArticleData } from "store/helpCenter/selectors";
import HelpCenterTabbedLayout from "layouts/HelpCenterTabbedLayout/HelpCenterTabbedLayout";
import { history } from "myHistory";

import "./HelpCenterArticle.scss";

export class HelpCenterArticle extends React.PureComponent {
  headerContainerRef = React.createRef();

  state = {
    activeHeaderIndex: 0,
    headerContainerFixed: false
  };

  componentDidMount() {
    this.props.loadData();
    document.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll = debounce(
    () => {
      // get the current active header
      // checks for the first header that is below the top of the client
      // window (with a small buffer) and the "active" header is the one
      // immediately before that
      const { content: { headers = [] } = {} } = this.props;
      const activeHeaderIndex = Math.max(
        headers.findIndex(({ link }) => {
          const headerEl = document.getElementById(link);
          return headerEl && headerEl.getBoundingClientRect().bottom > 100;
        }) - 1,
        0
      );

      // hack for IE11 since no `position: sticky`
      const headerContainerFixed =
        this.headerContainerRef.current.parentNode.getBoundingClientRect().top <
        20;

      this.setState({
        activeHeaderIndex,
        headerContainerFixed
      });
    },
    10,
    { maxWait: 100 }
  );

  renderSidebar() {
    const { content: { headers = [] } = {} } = this.props;

    const { activeHeaderIndex } = this.state;

    return headers.map(({ title, link }, i) => {
      return (
        <a
          className={classname("help-center-article_header-link", {
            "help-center-article_header-link--active": activeHeaderIndex === i
          })}
          onClick={async () => {
            // update the URL
            history.push(`#${link}`);

            // scroll the element into view
            await scrollIntoView(document.getElementById(link), {
              verticalOffset: 20
            });

            // update the active heading
            // have to defer this until the scroll handler is executed
            this.setState({ activeHeaderIndex: i });
          }}
          key={title}
        >
          {title}
        </a>
      );
    });
  }

  render() {
    const { content: { ast, headers = [] } = {}, title } = this.props;

    const { headerContainerFixed } = this.state;

    return (
      <HelpCenterTabbedLayout title={"Article"}>
        <h1 className="help-center-article_header">
          <b>{title}</b>
        </h1>
        <div className="help-center-article_main-content-container">
          <div className="help-center-article_content">
            {ast && rehypeToComponent(ast)}
          </div>
          {headers.length > 0 && (
            <div
              id="help-center-article_header-container"
              className="help-center-article_header-container"
              ref={this.headerContainerRef}
            >
              {headerContainerFixed ? (
                <div className="help-center-article_header-fixed-container">
                  {this.renderSidebar()}
                </div>
              ) : (
                this.renderSidebar()
              )}
            </div>
          )}
        </div>
      </HelpCenterTabbedLayout>
    );
  }
}

HelpCenterArticle.propTypes = {
  loadData: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return selectArticleData(state, { id: ownProps.match.params.id });
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: () => dispatch(loadHelpCenterArticle(ownProps.match.params.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpCenterArticle);

/******************************************
 * Content Rendering Logic
 ******************************************/

const rehypeToComponent = ast =>
  _rehypeToComponent.stringify(_rehypeToComponent.runSync(ast));
const _rehypeToComponent = unified().use(rehype2react, {
  createElement: React.createElement,

  // add custom help center components here
  components: {}
});
