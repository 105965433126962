import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveThread, showCommentSidebar } from "store/comments";
import { getThreadComments } from "selectors/commentSelectors";

import classNames from "classnames";
class CommentBubble extends Component {
  state = {
    comments: [],
    commentCount: null
  };

  setActiveThread = ({
    protocolId,
    sectionId,
    usageId,
    itemId,
    locationDescription,
    internalName
  }) => () => {
    this.props.setActiveThread({
      protocolId,
      sectionId,
      usageId,
      itemId,
      locationDescription,
      internalName
    });
    this.props.showCommentSidebar();
  };

  render() {
    const {
      protocolId,
      sectionId,
      usageId,
      itemId,
      threadComments,
      style,
      showComments,
      locationDescription,
      internalName
    } = this.props;
    if (!showComments) return null;

    const classes = classNames(
      "comment-bubble",
      {
        "comment-bubble--condensed": this.props.condensed,
        "comment-bubble--expanded": !this.props.condensed
      },
      {
        "comment-bubble--highlighted": this.props.isHighlighted
      }
    );

    return (
      <div
        style={style}
        className={classes}
        onClick={this.setActiveThread({
          protocolId,
          sectionId,
          usageId,
          itemId,
          locationDescription,
          internalName
        })}
      >
        <i className="icon-chat-bubble comment-bubble_icon" />
        <span className="comment-bubble_count">
          {" "}
          {(threadComments && threadComments.length) || 0}{" "}
        </span>{" "}
        {this.props.expanded ? (
          <span className="comment-bubble_label"> Comments </span>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  // important
  const threadComments =
    state.comments.showComments &&
    getThreadComments(state, state.session.activeProtocolId, props.itemId);
  const isHighlighted =
    threadComments &&
    threadComments.length > 0 &&
    threadComments.map(c => c._id).includes(state.comments.highlightedComment);
  if (state.comments.showComments) {
    return {
      showComments: state.comments.showComments,
      threadComments,
      protocolId: state.session.activeProtocolId,
      sectionId:
        state.session.entities.protocols[state.session.activeProtocolId]
          .activeSectionId,
      isHighlighted
    };
  } else {
    return {
      showComments: state.comments.showComments
    };
  }
};
const mapDispatchToProps = {
  setActiveThread,
  showCommentSidebar
};
CommentBubble.defaults = {
  condensed: true,
  style: {}
};
CommentBubble.propTypes = {
  setActiveThread: PropTypes.func,
  showCommentSidebar: PropTypes.func,
  expanded: PropTypes.bool,
  condensed: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  threadComments: PropTypes.array,
  protocolId: PropTypes.string,
  sectionId: PropTypes.string,
  usageId: PropTypes.string,
  itemId: PropTypes.string,
  style: PropTypes.object,
  showComments: PropTypes.bool,
  locationDescription: PropTypes.string,
  internalName: PropTypes.string
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentBubble);
