import PropTypes from "prop-types";
import React, { Component } from "react";
import MarkdownRender from "utility/markdownRender";
import MoreInfoIcon from "../moreInfo/MoreInfoIcon";
import CommentBubble from "components/protocol/comments/CommentBubble";
import { connect } from "react-redux";
import dotProp from "dot-prop";

class Recommendation extends Component {
  constructor(props) {
    super();
    this.state = {
      showCitations: false
    };
  }
  toggleCitationView = () => {
    return () => {
      this.setState({ showCitations: !this.state.showCitations });
    };
  };
  openCitation = citationUrl => {
    window.open(citationUrl, "_blank");
  };
  render() {
    const { evidenceUsage, showComments } = this.props;
    const recItem =
      evidenceUsage.evidence.recommendation &&
      evidenceUsage.evidence.recommendation.items[0];
    return (
      (
        <div className="rec-item">
          <div key={evidenceUsage.id} className="row">
            <div className="columns expand">
              <div className="rec-item_rec-markdown">
                <MarkdownRender source={recItem && recItem.text} />
              </div>
            </div>
            <div className="rec-item_more-info columns shrink">
              {evidenceUsage.evidence.blocks.length > 0 && (
                <MoreInfoIcon
                  evidence={evidenceUsage.evidence}
                  usageType={evidenceUsage.usageType}
                  usageId={evidenceUsage.id}
                />
              )}
            </div>
          </div>
          {recItem && recItem.citations.length > 0 && (
            <div className="rec-item_citation-header-wrapper">
              <span
                className="rec-item_citation-header"
                onClick={this.toggleCitationView()}
              >
                Citations ({this.state.showCitations ? " - " : " + "})
              </span>
            </div>
          )}
          {this.state.showCitations && (
            <ul className="rec-item_citation-container">
              {recItem &&
                recItem.citations.map(citation => {
                  return (
                    <li
                      key={citation.id}
                      onClick={() => this.openCitation(citation.url)}
                      className="rec-item_citation"
                    >
                      {citation.displayName}
                    </li>
                  );
                })}
            </ul>
          )}
          {showComments && (
            <CommentBubble
              itemId={evidenceUsage.id}
              usageId={evidenceUsage.id}
              internalName={dotProp.get(
                evidenceUsage,
                "evidence.internalName",
                ""
              )}
              locationDescription="Evidence"
              condensed
            />
          )}
        </div>
      ) || <p key={evidenceUsage.id}>loading</p>
    );
  }
}
Recommendation.propTypes = {
  evidenceUsage: PropTypes.object,
  showComments: PropTypes.bool
};
const mapStateToProps = state => ({
  showComments: state.comments.showComments
});
export default connect(mapStateToProps)(Recommendation);
