import React from "react";
import PropTypes from "prop-types";
import CareUsage from "./CareUsage";
import CommentBubble from "components/protocol/comments/CommentBubble";
import McgLaunchButton from "integrations/mcg/McgLaunchButton";
import dotProp from "dot-prop";
import ReasonForDecision from "./ReasonForDecision";

const CareFolder = ({
  folder,
  selectedSelectionIds,
  showComments,
  couldShowMcgLaunchButton,
  hideReasonForDecision
}) => {
  return (
    <div>
      <div className="care-category_title row collapse align-middle">
        <div className="columns shrink">
          {folder.category.title}
          {(folder.hasNextSection || folder.childUsageHasNextSection) && (
            <span style={{ color: "red" }}> *</span>
          )}
        </div>
        <div className="columns">
          <span className="care-category_select-text">
            {folder.selectMultiple ? "Select all that apply" : "Select ONE"}
          </span>
        </div>
      </div>
      {showComments && (
        <CommentBubble
          style={{
            top: "-.25rem",
            position: "relative",
            marginBottom: ".25rem"
          }}
          itemId={folder.id}
          locationDescription="Carefolder"
          internalName={dotProp.get(folder, "category.title", "")}
          // usageId={this.props.usageId}
          condensed
        />
      )}
      {couldShowMcgLaunchButton ? (
        <div className="care-category_usages row collapse align-middle">
          <div className="columns">
            {folder.usages.map(usage => (
              <CareUsage
                key={usage.id}
                usage={usage}
                folder={folder}
                selectedSelectionIds={selectedSelectionIds}
              />
            ))}
          </div>
          <div className="columns shrink">
            <McgLaunchButton />
          </div>
        </div>
      ) : (
        <div className="care-category_usages">
          {folder.usages.map(usage => (
            <CareUsage
              key={usage.id}
              usage={usage}
              folder={folder}
              selectedSelectionIds={selectedSelectionIds}
            />
          ))}
        </div>
      )}
      {!hideReasonForDecision && (
        <ReasonForDecision careUsages={folder.usages} />
      )}
    </div>
  );
};

CareFolder.propTypes = {
  selectedSelectionIds: PropTypes.array,
  folder: PropTypes.object,
  showComments: PropTypes.bool,
  couldShowMcgLaunchButton: PropTypes.bool,
  hideReasonForDecision: PropTypes.bool
};

export default CareFolder;
