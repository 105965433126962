import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import R from "ramda";
import Checkbox from "components/ui/Checkbox";
import { updateReason } from "store/session";

const EhrJustification = ({ reasonsForDeviation, updateReason }) => {
  return (
    <Fragment>
      <div className="ehr_treatment-course-container">
        <div className="ehr_category-title">Justification</div>
        <div className="ehr_category-subtitle">
          The following justification was used to make a decision in the care of
          this patient:
        </div>
        {R.values(reasonsForDeviation).map(ehr => {
          const indicatorClasses = classNames(
            "columns",
            "shrink",
            "select-output-item",
            {
              "select-output-item--selected": ehr.isSelected
            }
          );
          const itemTextClasses = classNames("columns", "output-item_text", {
            "output-item_text--selected": ehr.isSelected
          });
          return (
            <div
              className="output-item_container row collapse align-middle"
              key={ehr.id}
            >
              <div className={indicatorClasses}>
                <Checkbox
                  isSelected={ehr.isSelected}
                  onChange={() =>
                    updateReason({ ...ehr, isSelected: !ehr.isSelected })
                  }
                />
              </div>
              <span className={itemTextClasses}>{ehr.text}</span>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default connect(
  null,
  { updateReason }
)(EhrJustification);

EhrJustification.propTypes = {
  reasonsForDeviation: PropTypes.object.isRequired,
  updateReason: PropTypes.func
};
