import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectQuestionAnswer, launchNextSection } from "store/session";
import CommentBubble from "components/protocol/comments/CommentBubble";
import withWebMessage from "components/global/withWebMessage";
import { AUC_OTHER_INDICATION_SELECTION_IDS } from "constants/appropriateUseCriteria";
import { selectCDSMode } from "store/ui/selectors";

import { EVENT_QUESTIONUSAGE_CLICK } from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";

import { showProtocolLaunchButton } from "store/protocol";
import MoreInfoIcon from "components/protocol/sections/moreInfo/MoreInfoIcon";

import QuestionOption from "./QuestionOption";
export class Question extends Component {
  state = {
    changeNext: false,
    sections: []
  };

  selectAnswer = option => {
    return () => {
      const {
        usageId,
        activeProtocolId,
        relatedSections,
        setActiveGroup,
        pipelineRequest,
        cdsMode
      } = this.props;

      /*
       * DataPipeline event for selecting a question answer. - MB 20191015
       */
      pipelineRequest({
        action: EVENT_QUESTIONUSAGE_CLICK,
        message: {
          careusage: {
            id: usageId
          },
          selection: {
            id: option.id,
            displayName: option.displayName,
            internalName: option.selection.internalName
          }
        }
      });

      const nextSectionId = option.output.nextId;
      const nextObj = relatedSections[nextSectionId];
      if (nextSectionId) {
        if (nextObj.protocolId !== activeProtocolId) {
          /**
           * Because we passed in a section id, it will fetch that after the protocol
           */
          this.props.selectQuestionAnswer({
            usageId,
            outputId: option.output.id
          });
          this.props.showProtocolLaunchButton({
            sectionId: nextObj.id,
            protocol: nextObj.protocol
          });
          setActiveGroup();
        } else {
          this.props.selectQuestionAnswer({
            usageId,
            outputId: option.output.id
          });
          this.props.launchNextSection({
            currentSectionId: this.props.section.id,
            nextSectionId
          });
        }
      } else {
        this.props.selectQuestionAnswer({
          usageId,
          outputId: option.output.id
        });
      }
      if (
        __FHIR__ &&
        cdsMode &&
        AUC_OTHER_INDICATION_SELECTION_IDS.includes(option.output.selectionId)
      ) {
        this.props.sendWebMessage();
      }
    };
  };

  render() {
    const { question, usageId, questionUsage, showComments } = this.props;
    const optionsWithOutputs = this.props.outputs
      ? this.props.question.options.map(option => {
          const output =
            this.props.outputs.find(
              output =>
                output.selectionId === option.selection.id &&
                usageId === output.usageId
            ) || {};
          return { ...option, output };
        })
      : this.props.question.options;
    return (
      <div className="question">
        <div className="row row align-middle">
          <div className="columns">
            <div className="question_header">
              <h5 className="question_header-text">{question.title || ""}</h5>

              {questionUsage.evidence && (
                <MoreInfoIcon
                  evidence={questionUsage.evidence}
                  usageId={usageId}
                />
              )}
            </div>
            {showComments && (
              <CommentBubble
                style={{ top: "-.5rem", position: "relative" }}
                itemId={usageId}
                usageId={usageId}
                locationDescription="Question"
                internalName={question.internalName}
                condensed
              />
            )}
          </div>
        </div>
        <div className="question_options">
          {optionsWithOutputs.map(option => (
            <QuestionOption
              key={option.id}
              option={option}
              selectAnswer={this.selectAnswer}
              usageId={usageId}
            />
          ))}
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.object,
  outputs: PropTypes.array,
  usageId: PropTypes.string,
  questionUsage: PropTypes.object,
  selectQuestionAnswer: PropTypes.func,
  activeProtocolId: PropTypes.string,
  section: PropTypes.object,
  relatedSections: PropTypes.object,
  launchNextSection: PropTypes.func,
  showProtocolLaunchButton: PropTypes.func,
  setActiveGroup: PropTypes.func,
  sendWebMessage: PropTypes.func,
  showComments: PropTypes.bool,
  pipelineRequest: PropTypes.func,
  cdsMode: PropTypes.bool
};
const mapStateToProps = state => ({
  sessionId: state.session.sessionId,
  activeProtocolId: state.session.activeProtocolId,
  token: state.user.token,
  showComments: state.comments.showComments,
  cdsMode: selectCDSMode(state)
});

const mapDispatchToProps = {
  selectQuestionAnswer,
  launchNextSection,
  showProtocolLaunchButton,
  pipelineRequest
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withWebMessage
)(Question);
