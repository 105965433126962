import React from "react";
import PropTypes from "prop-types";
import PatientInfoWithBottomDrawer from "./PatientInfoWithBottomDrawer";

export default class PatientEdResources extends React.Component {
  state = {
    drawerIsOpen: false
  };
  toggleDrawer = () => {
    this.setState({ drawerIsOpen: !this.state.drawerIsOpen });
  };
  render() {
    return (
      <PatientInfoWithBottomDrawer
        faqs={this.props.faqs}
        resources={this.props.resources}
        drawerIsOpen={this.state.drawerIsOpen}
        patientEds={this.props.patientEd.filter(
          pe => pe.category && pe.category.categoryType === 2
        )}
        toggleDrawer={this.toggleDrawer}
        headerTitle="Resources"
      />
    );
  }
}
PatientEdResources.propTypes = {
  faqs: PropTypes.array,
  resources: PropTypes.array,
  patientEd: PropTypes.array
};
