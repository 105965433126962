import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getShouldShowMcgLaunchButton } from "./mcgSelectors";
import { showMcgGuidelines, hideMcgGuidelines } from "./mcgReducer";
import { updateEntityProp } from "store/session";
import { mcgLogo } from "./constants";
class McgButton extends React.Component {
  static propTypes = {
    shouldShowMcgButton: PropTypes.bool,
    showMcgGuidelines: PropTypes.func,
    hideMcgGuidelines: PropTypes.func,
    updateEntityProp: PropTypes.func,
    match: PropTypes.object
  };
  state = {
    selected: false
  };
  handleClick = () => {
    if (this.state.selected) {
      this.props.hideMcgGuidelines();
      this.setState({ selected: false });
    } else {
      this.props.showMcgGuidelines();
      this.setState({ selected: true });
      this.props.updateEntityProp({
        value: "",
        id: this.props.match.params.protocolId,
        entityName: "protocols",
        prop: "activeGroupId"
      });
    }
  };
  render() {
    const buttonClasses = classNames("button mcg-launch-button", {
      "button mcg-launch-button--selected": this.state.selected
    });
    return this.props.shouldShowMcgButton ? (
      <div className={buttonClasses} onClick={this.handleClick}>
        <h5 className="mcg-launch-button_header">Admission Advisor</h5>
        <span className="mcg-launch-button_subheader">Informed by</span>
        {mcgLogo(this.state.selected)}
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    shouldShowMcgButton: getShouldShowMcgLaunchButton(state)
  };
};

const mapDispatchToProps = {
  showMcgGuidelines,
  hideMcgGuidelines,
  updateEntityProp
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(McgButton)
);
