import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCitation } from "../mcgReducer";

export class Citation extends Component {
  renderCitationPopup = ({ e, citation }) => {
    e.stopPropagation();
    const citationType = citation.hasOwnProperty("AnnotationType")
      ? "annotation"
      : citation.Link.includes("footnote")
      ? "footnote"
      : "citation";
    this.props.fetchCitation({ citation, citationType });
  };
  findCitation = (text = "", item) => {
    const [type = "", id = ""] = text.split(":");
    if (type === "Foot") {
      return item.FootnoteLinks.find(footnote => footnote.Id === id);
    } else if (type === "Cit") {
      return item.CitationLinks.find(citation => {
        return citation.Id === id;
      });
    } else if (type === "Ano") {
      return item.AnnotationLinks.find(annotation => annotation.Id === id);
    }
  };
  render() {
    const { citationText, item } = this.props;
    const citation = this.findCitation(citationText, item);
    return citation ? (
      <Fragment>
        (
        <a
          className="mcg-citation"
          onClick={e => this.renderCitationPopup({ e, citation })}
        >
          {citation.AnnotationType || citation.DisplayValue}
        </a>
        )
      </Fragment>
    ) : null;
  }
}

Citation.propTypes = {
  citationText: PropTypes.string,
  item: PropTypes.object,
  fetchCitation: PropTypes.func
};

const mapDispatchToProps = {
  fetchCitation
};

export default connect(
  null,
  mapDispatchToProps
)(Citation);
