import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { startTour } from "store/tour/actions";
import { selectSelfHelpTours } from "store/tour/selectors";
import { hideModal } from "store/modal/actions";
import { selectUserHasFeature } from "store/user/selectors";
import { goToHelpCenterLandingPage } from "store/helpCenter/actions";

import "./SelfHelp.scss";

export function SelfHelp({
  tours,
  hideModal,
  startTour,
  showSherpaHelpCenter,
  goToHelpCenterLandingPage
}) {
  const onPageTours = tours.filter(({ onPage }) => onPage);
  const offPageTours = tours.filter(({ onPage }) => !onPage);
  return (
    <div>
      <h3 className="self-help_header-title">Self Help</h3>
      <div className="text-center">
        <h6 className="self-help_header-subtitle">
          Use our Self Help section to find tutorials and training on how to use
          our application.
        </h6>
      </div>
      <div className="self-help_tours-main-container">
        {tours.length > 0 ? (
          <>
            {onPageTours.length > 0 && (
              <div className="self-help_tours-section-container">
                <h4 className="self-help_tours-section-header"> Page Tours</h4>
                <ToursList
                  tours={onPageTours}
                  hideModal={hideModal}
                  startTour={startTour}
                />
              </div>
            )}
            {offPageTours.length > 0 && onPageTours.length > 0 && (
              <div className="self-help_tours-section-spacer" />
            )}
            {offPageTours.length > 0 && (
              <div className="self-help_tours-section-container">
                <h4 className="self-help_tours-section-header">
                  {" "}
                  Application Tours
                </h4>
                <h6>
                  <em> These tours may interrupt your current session. </em>
                </h6>
                <ToursList
                  tours={offPageTours}
                  hideModal={hideModal}
                  startTour={startTour}
                />
              </div>
            )}
          </>
        ) : (
          <NoTourMessage />
        )}
      </div>
      <div className="self-help_footer-container">
        Don&apos;t see what you are looking for?
        <a
          className="self-help_footer-button"
          onClick={() => {
            if (showSherpaHelpCenter) {
              goToHelpCenterLandingPage();
              hideModal();
            } else {
              //just using window.location here since these help centers are at a different site and not part of this app
              window.location =
                FHIR_ENVIRONMENT === "cerner"
                  ? "https://evidence.care/help-center-medstar/"
                  : "https://evidence.care/help-center-ec/";
            }
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit the Help Center
        </a>
      </div>
    </div>
  );
}

const NoTourMessage = () => (
  <em className="self-help_tours-not-found">
    No tours available. Check back soon!
  </em>
);

export const ToursList = ({ tours, hideModal, startTour }) =>
  tours.map(({ name, title, onPage, subtitle }, i) => {
    return (
      <React.Fragment key={name}>
        {i > 0 && <hr className="self-help_tours-divider" />}
        <a
          onClick={e => {
            e.preventDefault();
            hideModal();
            startTour(name);
          }}
          className="self-help_tours-item"
        >
          <span className="icon-metabolism-arrow self-help_tours-icon" />
          <div className="self-help_tours-item-content">
            <b className="self-help_tours-item-title">{title}</b>
            {subtitle && (
              <em className="self-help_tours-item-subtitle">{subtitle} </em>
            )}
          </div>
        </a>
      </React.Fragment>
    );
  });

SelfHelp.propTypes = {
  tours: PropTypes.array,
  hideModal: PropTypes.func,
  startTour: PropTypes.func,
  changeDraftStatus: PropTypes.func,
  goToHelpCenterLandingPage: PropTypes.func,
  showSherpaHelpCenter: PropTypes.bool
};

const mapStateToProps = state => ({
  tours: selectSelfHelpTours(state) || [],
  showSherpaHelpCenter: selectUserHasFeature(state, {
    flag: "SHERPA_HELP_CENTER"
  })
});

const mapDispatchToProps = {
  hideModal,
  startTour,
  goToHelpCenterLandingPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfHelp);
