export const providerTypes = {
  providerType: [
    { value: "physician", text: "Physician" },
    { value: "resident", text: "Resident" },
    { value: "medstudent", text: "Med Student" },
    { value: "midlevel", text: "Midlevel" },
    { value: "apn", text: "Advanced Practice Nurse" },
    { value: "pa", text: "Physician Assistant" },
    { value: "nurse", text: "Nurse" },
    { value: "ems", text: "EMS" },
    { value: "pharmacist", text: "Pharmacist" },
    { value: "administrator", text: "Administrator" }
  ]
};
