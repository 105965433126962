import React, { Fragment } from "react";
import PropTypes from "prop-types";
import OrderSetView from "./OrderSetView";
import { connect } from "react-redux";
import {
  getOrderstrings,
  makeGetOrderstringsGroupedByCategory,
  makeGetOrderstringsWithDuplicateTitles
} from "selectors/orderstringSelectors";

export const OrdersView = ({
  orderStrings,
  groupedOrderStringsByCategory,
  duplicateOrderstringTitles
}) => {
  return (
    <Fragment>
      {orderStrings && (
        <OrderSetView
          orderStrings={orderStrings}
          groupedOrderStrings={groupedOrderStringsByCategory}
          duplicateTitles={duplicateOrderstringTitles}
        />
      )}
    </Fragment>
  );
};

OrdersView.propTypes = {
  orderStrings: PropTypes.array,
  groupedOrderStringsByCategory: PropTypes.object,
  duplicateOrderstringTitles: PropTypes.array
};

const makeMapStateToProps = () => (state, props) => {
  const { orders } = props;
  return {
    orderStrings: getOrderstrings(orders),
    groupedOrderStringsByCategory: makeGetOrderstringsGroupedByCategory()(
      orders
    ),
    duplicateOrderstringTitles: makeGetOrderstringsWithDuplicateTitles()(orders)
  };
};

export default connect(makeMapStateToProps)(OrdersView);
