import React from "react";
import PropTypes from "prop-types";
import PatientEdAccordion from "./PatientEdAccordion";
import PatientEducationItems from "./PatientEducationItems";

const PatientEdCareSummary = ({
  patientEds,
  shouldShowMcg,
  fetchMcg,
  mcgIndications,
  mcgText
}) => {
  return (
    (patientEds.length > 0 || shouldShowMcg) && (
      <PatientEdAccordion
        title="Care Summary"
        render={() => (
          <div>
            <div className="patient-ed_treatment-summary-text">
              The following information highlights the treatment course your
              provider determined was best for you.
            </div>
            <PatientEducationItems
              patientEds={patientEds}
              shouldShowMcg={shouldShowMcg}
              fetchMcg={fetchMcg}
              mcgIndications={mcgIndications}
              mcgText={mcgText}
            />
          </div>
        )}
      />
    )
  );
};

export default PatientEdCareSummary;

PatientEdCareSummary.propTypes = {
  patientEds: PropTypes.array
};
