import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { renderToStaticMarkup } from "react-dom/server";
import { success, error } from "react-notification-system-redux";
import { Portal } from "react-portal";
import dotProp from "dot-prop";
import h2p from "html2plaintext";

import FollowUpApptForm from "./FollowUpApptForm";
import ProviderNoteForm from "./ProviderNoteForm";
import PatientEdCareSummary from "./PatientEdCareSummary";
import PatientEdDiagnoses from "./PatientEdDiagnoses";
import withPersonalNoteCouponAndFollowUp from "./withPersonalNoteCouponAndFollowUp";
import PatientEdPrintModal from "./PatientEdPrintModal";
import PatientEdPrintMarkup from "./PatientEdPrintMarkup";
import { Tooltip } from "components/ui/tooltip";
import { renderFollowUpIcon, renderEhrIcon } from "./patientEdHelpers";
import {
  FHIR_FLAGS_HAS_PE_POST,
  FHIR_WARN_ON_DUPLICATE_SUBMIT
} from "constants/fhir";
import { fhirFactory } from "lib/fhir/factory";
import { getFacilityLocation } from "store/patient";
import {
  EVENT_FEATURE_PATIENT_VIEW,
  EVENT_FEATURE_PATIENT_VIEW_PRINT,
  EVENT_FEATURE_PATIENT_SEND_TO_EHR
} from "constants/broadcastEventTypes";
import { getConfigByOrgSlug } from "helpers/config";
import { pipelineRequest } from "store/pipeline";
import {
  fetchAdmissionContext,
  fetchMcgGuidelineStatus
} from "integrations/mcg/mcgReducer";
import {
  getSelectedIndicationsEhr,
  getMcgEhrText,
  getSelectedGuideline
} from "integrations/mcg/mcgSelectors";
import { selectFormattedUserName } from "store/user/selectors";
import { setLastSentPatientEdDoc } from "store/session";
import { formattedDate } from "helpers/parsing/dates";
import createHash from "create-hash";
import SendToEhrButton from "components/ehr/SendToEhrButton";

const GOOD_RX_COUPON_EXAMPLE = `${CLOUDINARY_URL}/evidencecare/image/upload/v1508270625/tagalong-site-assets/ec-coupon-card2.png`;

class PatientEdView extends Component {
  state = {
    drawerIsOpen: false,
    printModalIsOpen: false,
    windowWidth: window.innerWidth,
    resizeId: ""
  };
  componentDidMount() {
    this.props.getFacilityLocation();
    this.props.pipelineRequest({
      action: EVENT_FEATURE_PATIENT_VIEW
    });
    // need this event listener in order to resolve print icon tooltip positioning
    // throttling the resize event so `handleResize` only fires once the resizing is done
    window.addEventListener("resize", this.throttleResizeEvent);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.throttleResizeEvent);
  }

  toggleDrawer = () =>
    this.setState({ drawerIsOpen: !this.state.drawerIsOpen });

  togglePrintModal = () => {
    if (!this.state.printModalIsOpen) {
      __FHIR__ && this.sendToEhr();
      this.props.pipelineRequest({
        action: EVENT_FEATURE_PATIENT_VIEW_PRINT
      });
    }
    this.setState({ printModalIsOpen: !this.state.printModalIsOpen });
  };

  ehrSubmitSuccess = data => {
    this.props.success({
      uid: "send-pe-success",
      message: "Sent to EHR",
      position: "tr"
    });
  };

  ehrSubmitError = (err, data) => {
    this.props.error({
      uid: "send-pe-error",
      message: "Error Sending to EHR",
      position: "tr"
    });
    const log = {
      err,
      data: {
        ...data,
        resource: {
          ...data.resource,
          content: ""
        },
        data: "",
        auth: ""
      }
    };
    console.log("log", JSON.stringify(log));
  };

  sendToEhr = () => {
    const fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);
    this.props.pipelineRequest({
      action: EVENT_FEATURE_PATIENT_SEND_TO_EHR
    });
    const config = getConfigByOrgSlug(this.props.user.organization.slug);
    const { userName, user } = this.props;

    const patientEdHtmlString = renderToStaticMarkup(
      <PatientEdPrintMarkup
        isEhrDocument
        organizationLogo={dotProp.get(
          this.props.user,
          "organization.cloudinary_id"
        )}
        facilityLocation={this.props.facilityLocation}
        activeSelectedOutputObjs={this.props.activeSelectedOutputObjs}
        rxSets={this.props.rxSets}
        providerNote={this.props.providerNote}
        togglePrintModal={null}
        userName={userName}
        shouldShowMcg={this.props.shouldShowMcg}
        mcgIndications={this.props.mcgIndications}
        mcgText={this.props.mcgText}
      />
    );

    const wrappedPatientEdHtmlString = fhirModule.wrapPatientEdHtmlString(
      patientEdHtmlString,
      config.pe_description
    );
    // eslint-disable-next-line no-useless-escape
    const plainText = h2p(wrappedPatientEdHtmlString)
      .replace(/[\n\r]/g, " ")
      .replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]/gi, "");
    const encodedHTML = btoa(
      unescape(encodeURIComponent(wrappedPatientEdHtmlString))
    );
    const hashedHTML = createHash("sha256")
      .update(encodedHTML, "utf-8")
      .digest("hex");
    const resource = fhirModule.buildDocumentReference({
      user: user,
      patient: this.props.patient,
      encodedHTML,
      plainText,
      description: config.pe_description,
      loinc: config.pe_loinc,
      patientIdentifiers: this.props.patient.identifiers
    });
    const entry = { resource };

    if (
      user.features.includes(FHIR_WARN_ON_DUPLICATE_SUBMIT) &&
      this.props.lastSentDoc === hashedHTML
    ) {
      if (
        confirm(
          "PatientEd documentation has not changed since the last submission on " +
            formattedDate(this.props.lastSentTime, "MM/DD/YYYY h:mm:ss A") +
            ". Send again?"
        )
      ) {
        this.props.setLastSentPatientEdDoc({
          doc: hashedHTML,
          lastSentTime: new Date()
        });
        fhirModule.postEHR(
          entry,
          data => this.ehrSubmitSuccess(data),
          (err, data) => this.ehrSubmitError(err, data),
          this.props.patient.identifiers,
          localStorage.getItem("id_token")
        );
      }
    } else {
      fhirModule.postEHR(
        entry,
        data => this.ehrSubmitSuccess(data),
        (err, data) => this.ehrSubmitError(err, data),
        this.props.patient.identifiers,
        localStorage.getItem("id_token")
      );

      if (user.features.includes(FHIR_WARN_ON_DUPLICATE_SUBMIT)) {
        this.props.setLastSentPatientEdDoc({
          doc: hashedHTML,
          lastSentTime: new Date()
        });
      }
    }
  };

  filterPatientEd = categoryType => {
    const { patientEd } = this.props;
    if (categoryType === "careSummary") {
      return patientEd.filter(
        pe => pe.category && pe.category.categoryType === 1
      );
    } else if (categoryType === "afterCare") {
      return patientEd.filter(
        pe => pe.category && pe.category.categoryType === 2
      );
    }
  };
  // print icon tooltip is funky when resizing screen
  // taking the window width when it changes, sets it to state, and adds that value to icon print `key`
  handleResize = () => {
    const currentWindowWidth = window.innerWidth;
    const previousWindowWidth = this.state.windowWidth;
    if (currentWindowWidth !== previousWindowWidth) {
      this.setState({ windowWidth: currentWindowWidth });
    }
  };
  throttleResizeEvent = () => {
    const { resizeId } = this.state;
    clearTimeout(resizeId);
    this.setState({ resizeId: setTimeout(this.handleResize, 250) });
  };
  fetchMcg = () => {
    const {
      mcgSelectedGuideline,
      mcgGuideline,
      fetchMcgGuidelineStatus,
      fetchAdmissionContext
    } = this.props;
    if (!mcgGuideline.length && mcgSelectedGuideline) {
      // fetch guidelines with session data
      fetchAdmissionContext();
      fetchMcgGuidelineStatus({
        hsim: mcgSelectedGuideline.hsim,
        indicationIds: mcgSelectedGuideline.selections
      });
    }
  };

  render() {
    const {
      diagnoses,
      togglePersonalNoteInput,
      // toggleFollowUpForm,
      toggleCoupon,
      appointment,
      onChange,
      handleFollowUpSubmit,
      handlePersonalNoteSubmit,
      providerNote,
      handleAppointmentChange,
      data,
      activeSelectedOutputObjs,
      rxSets,
      account,
      facilityLocation,
      user,
      userName,
      shouldShowMcg,
      mcgIndications,
      mcgText
    } = this.props;
    const { printModalIsOpen } = this.state;
    // const hasAppointment = R.values(data.appointment).some(x => x !== '')
    const hasProviderNote = data.providerNote !== "";
    return (
      <Fragment>
        {printModalIsOpen ? (
          <Portal node={document && document.getElementById("print")}>
            <PatientEdPrintModal
              facilityLocation={facilityLocation}
              organizationLogo={dotProp.get(user, "organization.cloudinary_id")}
              activeSelectedOutputObjs={activeSelectedOutputObjs}
              togglePrintModal={this.togglePrintModal}
              providerNote={providerNote}
              appointment={appointment}
              rxSets={rxSets}
              account={account}
              userName={userName}
              shouldShowMcg={shouldShowMcg}
              mcgIndications={mcgIndications}
              mcgText={mcgText}
            />
          </Portal>
        ) : (
          <Fragment>
            <div className="patient-ed_wrapper">
              <div className="patient-ed_container">
                <div className="row collapse align-middle">
                  <div className="patient-ed_header-text columns">
                    Select information to share with your patient
                  </div>
                  <div className="columns shrink">
                    <div className="row collapse align-middle">
                      {/* adding a `key` here to ensure React is keeping track of this component for tooltip purposes */}
                      <Tooltip
                        id="pe-print-icon"
                        place="left"
                        className="patient-ed-icon_tooltip"
                        key={this.state.windowWidth}
                        message="Click here to view or print the Care Summary."
                      >
                        <div
                          onClick={this.togglePrintModal}
                          className="patient-ed-icon icon-print-outline"
                        />
                      </Tooltip>
                      {__FHIR__ && FHIR_FLAGS_HAS_PE_POST && (
                        <SendToEhrButton sendToEhr={this.sendToEhr} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="patient-ed-provider_container">
                    <div className="patient-ed-provider_avatar-container">
                      <img
                        className="patient-ed-provider_avatar"
                        src={`${CLOUDINARY_URL}/evidencecare/image/upload/v1473439347/users/user-profile.png`}
                      />
                    </div>
                    <div className="patient-ed-provider_information-container">
                      <div className="patient-ed-provider_name">
                        Dr Inez Jordon
                      </div>
                      <div className="patient-ed-provider-checkbox">
                        <input id="provider" type="checkbox" />
                        <label
                          className="patient-ed-provider_info-text"
                          htmlFor="provider"
                        >
                          Provider information shared
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-middle collapse patient-ed_icon-container-row">
                  <Tooltip
                    id="pe-provider-note-tooltip"
                    className="patient-ed-icon_tooltip"
                    message="Provider Comment"
                  >
                    {renderFollowUpIcon({
                      togglePersonalNoteInput,
                      hasProviderNote
                    })}
                  </Tooltip>
                  <Tooltip
                    id="pe-coupon-tooltip"
                    className="patient-ed-icon_tooltip"
                    message="Coupon(s)"
                  >
                    {renderEhrIcon({ toggleCoupon })}
                  </Tooltip>
                </div>
                {data.isFollowUpFormOpen && (
                  <FollowUpApptForm
                    appointment={appointment}
                    onChange={handleAppointmentChange}
                    onSubmit={handleFollowUpSubmit}
                  />
                )}
                {data.isPersonalNoteInputOpen && (
                  <ProviderNoteForm
                    note={providerNote}
                    onChange={onChange}
                    onSubmit={handlePersonalNoteSubmit}
                  />
                )}
                {data.isCouponOpen && <img src={GOOD_RX_COUPON_EXAMPLE} />}
                <PatientEdDiagnoses diagnoses={diagnoses} />
                <div className="row align-middle collapse">
                  <PatientEdCareSummary
                    patientEds={this.filterPatientEd("careSummary")}
                    shouldShowMcg={shouldShowMcg}
                    fetchMcg={this.fetchMcg}
                    mcgIndications={mcgIndications}
                    mcgText={mcgText}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

PatientEdView.propTypes = {
  data: PropTypes.object,
  toggleCoupon: PropTypes.func,
  togglePersonalNoteInput: PropTypes.func,
  onChange: PropTypes.func,
  handleAppointmentChange: PropTypes.func,
  handleFollowUpSubmit: PropTypes.func,
  handlePersonalNoteSubmit: PropTypes.func,
  patientEd: PropTypes.array,
  diagnoses: PropTypes.array,
  appointment: PropTypes.object,
  providerNote: PropTypes.string,
  success: PropTypes.func,
  error: PropTypes.func,
  activeSelectedOutputObjs: PropTypes.object,
  account: PropTypes.object,
  rxSets: PropTypes.object,
  user: PropTypes.object,
  pipelineRequest: PropTypes.func,
  getFacilityLocation: PropTypes.func,
  facilityLocation: PropTypes.object,
  patient: PropTypes.object,
  userName: PropTypes.string,
  mcgGuideline: PropTypes.array,
  mcgSelectedGuideline: PropTypes.object,
  shouldShowMcg: PropTypes.bool,
  mcgIndications: PropTypes.object,
  mcgText: PropTypes.object,
  fetchAdmissionContext: PropTypes.func,
  fetchMcgGuidelineStatus: PropTypes.func,
  lastSentDoc: PropTypes.string,
  lastSentTime: PropTypes.string,
  setLastSentPatientEdDoc: PropTypes.func
};
PatientEdView.defaultProps = {
  shouldShowMcg: false,
  mcgSelectedGuideline: {},
  mcgIndications: {},
  mcgText: {}
};
const mapStateToProps = state => {
  const mcgSelectedGuideline = getSelectedGuideline(state);
  const shouldShowMcg = dotProp.get(mcgSelectedGuideline, "isSubmitted", false);
  return {
    shouldShowMcg,
    rxSets: state.session.rxSetItems,
    account: state.account,
    patient: state.patient,
    user: state.user,
    facilityLocation: dotProp.get(state, "patient.encounter.facilityLocation"),
    lastSentDoc: dotProp.get(
      state,
      "session.userText.lastSentPatientEdDoc.doc"
    ),
    lastSentTime: dotProp.get(
      state,
      "session.userText.lastSentPatientEdDoc.lastSentTime"
    ),
    userName: selectFormattedUserName(state),
    ...(shouldShowMcg && {
      mcgIndications: getSelectedIndicationsEhr(state),
      mcgSelectedGuideline,
      mcgGuideline: state.mcg.guidelineWithStatus,
      mcgText: getMcgEhrText(state)
    })
  };
};

const mapDispatchToProps = {
  getFacilityLocation,
  success,
  error,
  pipelineRequest,
  fetchMcgGuidelineStatus,
  fetchAdmissionContext,
  setLastSentPatientEdDoc
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPersonalNoteCouponAndFollowUp(PatientEdView));
