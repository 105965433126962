import { makeEcContentRequest as makeEcRequest, makeOmniRequest } from "./base";
import axios from "axios";

// const generateParamsFromArray = (paramArray, paramName) => {
//   return paramArray && Array.isArray(paramArray) ? paramArray.map((item, i) => `&${paramName}=${item}`).join('') : ''
// }

export const fetchProtocolApi = ({ protocolId }) => {
  return makeOmniRequest({
    method: "GET",
    location: `search?filters={"_id":"${protocolId}"}`
  });
};

export const fetchProtocolRenderedDocument = ({ url }) => {
  return axios({
    method: "GET",
    url
  });
};

export async function fetchProtocol({ protocolId }) {
  const {
    data: { results }
  } = await fetchProtocolApi({ protocolId });
  if (results.length) {
    const [
      {
        data: { latestVersion }
      }
    ] = results;
    const { data } = await fetchProtocolRenderedDocument({
      url: latestVersion.renderedDocument
    });
    return { data, latestVersion };
  } else {
    throw new Error("Protocol does not exist in OmniService");
  }
}

export const fetchContentBootstrap = () => {
  return makeEcRequest({
    api: "content-service",
    method: "GET",
    location: `bootstrap`
  });
};

export const fetchSectionVersionApi = ({ sectionId, approvalPoint, log }) => {
  const approvalPointString = approvalPoint
    ? `"createdBefore":"${approvalPoint}",`
    : "";
  const filters = encodeURIComponent(
    `{"sectionId":"${sectionId}",${approvalPointString}"uniquePerSection": "true"}`
  );
  const range = encodeURIComponent("[0,0]");
  return new Promise((resolve, reject) => {
    makeEcRequest({
      method: "GET",
      location: `protocols/sectionversion?filter=${filters}&range=${range}`
    })
      .then(({ data }) => {
        if (
          Array.isArray(data) &&
          data.length &&
          data.length > 0 &&
          data[0].sectionId === sectionId
        ) {
          resolve({ data: data[0] });
        } else {
          if (log) log("Rejecting protocols/sectionversion results");
          const err = new Error("No published version found");
          reject(err);
        }
      })
      .catch(err => {
        if (log) log("Error in GET from protocols/sectionversion");
        reject(err);
      });
  });
};

export const fetchAllSectionVersionsByProtocolApi = ({
  protocolId,
  createdBefore,
  log
}) => {
  const createdBeforeString = createdBefore
    ? `, "createdBefore":"${createdBefore}"`
    : "";
  const filters = encodeURIComponent(
    `{"protocolId":"${protocolId}", "uniquePerSection": "true" ${createdBeforeString}}`
  );
  const range = encodeURIComponent("[0,500]");
  return new Promise((resolve, reject) => {
    makeEcRequest({
      method: "GET",
      location: `protocols/sectionversion?filter=${filters}&range=${range}`
    })
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        if (log) log("Error in GET from protocols/sectionversion");
        reject(err);
      });
  });
};

export const fetchSectionVersionsApi = ({ sectionVersionIds }) => {
  const svParam = sectionVersionIds.map(svId => `"${svId}"`).join(",");
  const filters = encodeURIComponent(`{"ids":[${svParam}]}`);
  const range = encodeURIComponent("[0,100]");

  return makeEcRequest({
    method: "GET",
    location: `protocols/sectionversion?filter=${filters}&range=${range}`
  });
};

export const fetchProtocolsApi = () => {
  const range = encodeURIComponent("[0,2000]");
  return makeEcRequest({
    method: "GET",
    location: `protocols/protocol?range=${range}`
  });
};
export const makeOmniSearchQueryString = ({
  searchText,
  type,
  page,
  filter
}) => {
  const searchParam = searchText ? `&query=${searchText}` : "";
  const filterParam = filter && filter !== "{}" ? `&filters=${filter}` : "";
  const pageParam = page ? `&page=${page}` : "";
  return `page_size=100&types=${type}${searchParam}${filterParam}${pageParam}`;
};

export const fetchProtocolsOmni = ({ filter, page, searchText, type }) => {
  const queryString = makeOmniSearchQueryString({
    searchText,
    type,
    page,
    filter
  });
  return makeOmniRequest({
    method: "GET",
    location: `search?${queryString}`
  });
};

export const fetchProtocolOmniOptions = ({
  filter,
  type = "protocol",
  isOnTabChange
}) => {
  // const hasFilters = !isOnTabChange && filter && filter !== '{}'
  // const omniTypeWithOptions = type ? `/options?types=${type}` : '/options?types=protocol'
  // there are currently no 'options' for the admission type protocols
  // if this changes, we will need to update this
  // const options = type === 'admission' ? '?types=admission' : omniTypeWithOptions
  return makeOmniRequest({
    method: "GET",
    location:
      !isOnTabChange && filter && filter !== "{}"
        ? `search/options?types=${type}?filters=${filter}`
        : `search/options?types=${type}`,
    filter
  });
};
