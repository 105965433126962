import React from "react";

export const CareDecisionIconKey = () => {
  return (
    <div className="care-decision-icon-key_container">
      <div className="care-decision-icon-key">
        <div className="care-decision-icon-key icon-thumbs-up" />
        <div className="care-decision-icon-key_text">
          {" "}
          = Usually Appropriate
        </div>
      </div>
      <div className="care-decision-icon-key">
        <div className="care-decision-icon-key icon-neutral-circle" />
        <div className="care-decision-icon-key_text">
          {" "}
          = Sometimes Appropriate
        </div>
      </div>
      <div className="care-decision-icon-key">
        <div className="care-decision-icon-key icon-thumbs-down" />
        <div className="care-decision-icon-key_text"> = Rarely Appropriate</div>
      </div>
    </div>
  );
};
