import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { hasFeature } from "helpers/user";
import FeatureBlockOverlay from "./Overlay";

export class FeatureBlock extends Component {
  render() {
    const { render, overlay, children } = this.props;
    const hasAccess = hasFeature(this.props.features, this.props.feature);
    return (
      <div>
        {!hasAccess && overlay && (
          <FeatureBlockOverlay {...this.props}>{children}</FeatureBlockOverlay>
        )}
        {render(hasAccess)}
      </div>
    );
  }
}

FeatureBlock.propTypes = {
  render: PropTypes.func.isRequired,
  features: PropTypes.array,
  feature: PropTypes.string,
  overlay: PropTypes.bool,
  children: PropTypes.object
};

const mapStateToProps = state => ({
  features: state.user.features
});

export default connect(mapStateToProps)(FeatureBlock);
