import PropTypes from "prop-types";
import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
const muiTheme = getMuiTheme({
  palette: {}
});

const CoreLayout = ({ children }) => {
  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <div className="core-layout__viewport">{children}</div>
    </MuiThemeProvider>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default CoreLayout;
