import React, { Component } from "react";

const withPreventBackwardNavInCDS = WrappedComponent => {
  return class ManageNavigation extends Component {
    componentDidMount() {
      const { history, fhirCDS } = this.props;
      if (fhirCDS) {
        history.listen((newLocation, action) => {
          if (action === "PUSH") {
            if (
              newLocation.pathname !== this.currentPathname ||
              newLocation.search !== this.currentSearch
            ) {
              // Save new location
              this.currentPathname = newLocation.pathname;
              this.currentSearch = newLocation.search;

              // Clone location object and push it to history
              history.push({
                pathname: newLocation.pathname,
                search: newLocation.search
              });
            }
          } else {
            // Send user back if they try to navigate back
            history.go(1);
          }
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withPreventBackwardNavInCDS;
