//
// Helper functions for the user state object
//

import R from "ramda";

//
// Returns if a user has a particular feature or not
//

export const hasFeature = (userFeatures, feature) => {
  return (
    userFeatures &&
    Array.isArray(userFeatures) &&
    userFeatures.includes(feature)
  );
};
//
// Returns if a user is considered a "doctor"
//
export const isDoctor = type => {
  return R.contains(type, ["physician", "resident"]);
};
