import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SectionContainer from "./SectionContainer";
import McgContainer from "integrations/mcg/McgContainer";

export const Sections = props => (
  <div className="protocol_sections">
    {props.protocol &&
      props.sectionsOrder.map(sectionId => {
        const sectionVersion = props.protocol.sections[sectionId];
        return (
          (sectionVersion && (
            <SectionContainer
              key={sectionId}
              protocolId={props.protocol.id}
              sectionId={sectionId}
            />
          )) || <div>loading</div>
        );
      })}
    <McgContainer />
  </div>
);

Sections.propTypes = {
  protocol: PropTypes.object,
  sectionsOrder: PropTypes.array
};

Sections.propTypes = {
  protocol: PropTypes.object,
  sectionsOrder: PropTypes.array
};
const mapStateToProps = state => ({
  protocol: state.protocol[state.session.activeProtocolId],
  sectionsOrder:
    state.session.entities.protocols[state.session.activeProtocolId]
      .sectionsOrder
});

export default connect(mapStateToProps)(Sections);
