import React from "react";
// these are items that are restructured fully upon load
// low level items like graphs/citations are excluded for perormance sake
export const MAIN_ITEM_TYPES = [
  "approachgroup",
  "approach",
  "recommendation",
  "patienteducation",
  "patientfaq",
  "patientresource",
  "prescriptionset",
  "question",
  "calculator",
  "phrase",
  "order",
  "ehr",
  "recommendationgroup"
];

// item types allowed in sections in the evidence tab
export const evidenceItemTypes = [
  {
    value: "recommendationgroup",
    text: "Recommendation Container"
  },
  {
    value: "question",
    text: "Question"
  },
  {
    value: "approachgroup",
    text: "Approach Group"
  }
];

export const MEDICATIONS_HEADER_ID = "f2dc9cfa-206e-4579-86ff-26c364c7bf53";
export const CARE_TRANSITION_ID = "42cda5a2-1ad4-40eb-a180-712010035444";
export const FOLLOWUP_HEADER_ID = "0fa5a91c-ccc0-4cc7-9d18-ac0e87f48ff6";

// Section headers used in the eviden tab
// NEVER remove an object, only add or update names
export const EVIDENCE_SECTION_HEADERS = [
  {
    name: "Assessment",
    id: "bcdb9a2b-27f4-4436-973a-d7c5623c5bbb",
    types: evidenceItemTypes,
    legacySectionType: "ASSESSMENT"
  },
  {
    name: "Approach",
    id: "87ca4642-3dd8-444e-8544-4e2f7d6d1abb",
    types: evidenceItemTypes,
    legacySectionType: "APPROACH"
  },
  {
    name: "Treatment",
    id: "f61a520f-0bc2-4169-b4be-a0db7ca42674",
    types: evidenceItemTypes,
    legacySectionType: "TREATMENT"
  },
  {
    name: "Care Transition",
    id: "5fcd0d5b-78e7-43b7-a657-667532426ea0",
    types: evidenceItemTypes,
    legacySectionType: "DISPOSITION"
  },
  {
    name: "Initial Treatment",
    id: "7915a747-1765-4a47-8d82-d910af6da12b",
    types: evidenceItemTypes,
    legacySectionType: "INITIAL_TREATMENT"
  },
  {
    name: "Reassessment",
    id: "5b5792de-17b8-44e1-909e-2770ac138e96",
    types: evidenceItemTypes,
    legacySectionType: "REASSESSMENT"
  },
  {
    name: "Prevention",
    id: "1025e13d-29a2-434e-942c-bfefc370adfe",
    types: evidenceItemTypes,
    legacySectionType: "PREVENTION"
  },
  {
    name: "Work up",
    id: "d87fd649-a1cc-4c04-b0a6-7ec5124b8cdf",
    types: evidenceItemTypes,
    legacySectionType: "WORK_UP"
  },
  {
    name: "Subsequent Treatment",
    id: "cced8b63-8b01-4192-9473-1ceb21571cd7",
    types: evidenceItemTypes,
    legacySectionType: "SUBSEQUENT_TREATMENT"
  },
  {
    name: "Appropriate Use Criteria",
    id: "7df12782-b3ab-401c-b712-86cb1e001513",
    types: evidenceItemTypes,
    legacySectionType: "APPROPRIATE_USE_CRITERIA"
  },
  {
    name: "Risk Stratification",
    id: "0ed1e488-e2d3-4b06-bae5-8418d29fe4d2",
    types: evidenceItemTypes,
    legacySectionType: "RISK_STRATIFICATION"
  }
];

// Section headers used in the PE tab
// NEVER remove an object, only add or update names
export const PE_SECTION_HEADERS = [
  {
    name: "Care Transition",
    subheader: "The appropriate disposition for your patient",
    defaultElement:
      "Your provider has determined that the following next step is best for the care of your condition",
    ehrDefaultElement: "The appropriate disposition of the patient.",
    subheaderPatientView:
      "Your provider has determined that the following next step is best for the care of your condition.",
    id: CARE_TRANSITION_ID,
    types: [{ value: "patienteducation", text: "Patient Education Evidence" }]
  },
  {
    name: "Follow-Up",
    subheader:
      "If applicable, your patient should follow-up with the indicated provider within the appropriate time interval.",
    defaultElement:
      "Timely follow-up care with the provider chosen below will ensure that you are on the right path to getting better",
    ehrDefaultElement:
      "The patient was instructed to follow-up with the following provider.",
    subheaderPatientView:
      "Timely follow-up care with the provider chosen below will ensure that we have you on the right path to getting better.",
    id: FOLLOWUP_HEADER_ID,
    types: [{ value: "patienteducation", text: "Patient Education Evidence" }]
  },
  {
    name: "Summary of Care",
    subheader:
      "The evidence that supports the diagnostic and/or therapeutic approach that is best for the care of your patient",
    defaultElement:
      "The following information highlights the evidence that supports the treatment course that your provider has determined is best for you",
    subheaderPatientView:
      "The following information highlights the evidence that supports the treatment course that your provider has determined is best for you.",
    id: "cc81a289-7f9e-41a9-915e-43ede83badaa",
    types: [{ value: "patienteducation", text: "Patient Education Evidence" }]
  },
  {
    name: "Medications",
    subheader:
      "Medications that were given or will be prescribed to your patient",
    defaultElement:
      "The following medications were given during the course of your care or will be prescribed",
    subheaderPatientView:
      "The following medications were given during the course of your care or will be prescribed.",
    id: MEDICATIONS_HEADER_ID,
    types: []
  },
  {
    name: "FAQs",
    subheader: "Frequently Asked Questions that are relevant to your patient",
    defaultElement:
      "Here are some questions that patients with your condition commonly have",
    subheaderPatientView:
      "Here are some questions that patients with your condition commonly have.",
    id: "8823974e-09c7-435e-bd11-fecc3f7f00b4",
    types: [{ value: "patientfaq", text: "FAQ" }]
  },
  {
    name: "Resources",
    subheader: "Online resources that may be helpful for your patient",
    defaultElement:
      "The following resources may be helpful for you in learning more about your condition",
    subheaderPatientView:
      "The following resources may be helpful for you in learning more about your condition.",
    id: "c4e97df3-b605-4f77-b1d8-36bc4a0e6f72",
    types: [{ value: "patientresource", text: "Resource" }]
  }
];

// Section headers used in the EHR tab
// NEVER remove an object, only add or update names
export const EHR_SECTION_HEADERS = [
  {
    name: "Evidence for Care",
    id: "8d494c27-0e85-419e-b4bc-19f41b434601",
    types: [{ value: "ehr", text: "EHR Evidence" }],
    subheader:
      "The evidence that supports the diagnostic and/or therapeutic course that was taken",
    defaultElement:
      "The following information highlights the evidence that was incorporated into my medical decision making to determine the appropriate diagnostic and/or therapeutic course."
  },
  {
    name: "Supporting Statements",
    id: "18aa2125-f5c2-4589-beb3-84d2eaf86828",
    types: [{ value: "phrase", text: "Phrase" }],
    subheader:
      "Additional phrases that you would like included in your documentation",
    defaultElement:
      "The following information is important in the patient's care and was communicated with them and/or their family."
  }
];

// this determines which items allow the criteria edit tab as well as when to expect criteria
export const ITEM_TYPES_WITH_CRITERIA = [
  "recommendation",
  "patientresource",
  "patientfaq",
  "question",
  "patienteducation",
  "ehr",
  "phrase"
];

// these are the header ids that need to be added to ehr from the patient ed section headers
export const EXTRA_EHR_HEADER_IDS = [CARE_TRANSITION_ID, FOLLOWUP_HEADER_ID];

export const EDITABLE_ITEM_TYPES = ["fe_markdown"];

export const PE_CATEGORY_TITLES = {
  reasonsToReturn: {
    title: "Reasons to Return",
    subtitle:
      "Call your healthcare provider, return immediately, or call 911 if you experience any of the following.",
    id: "7535abf0-4f2d-4554-8e27-6d1e8ba2d026"
  },
  atHome: {
    title: "What To Do at Home",
    printTitle: "After Care Instructions",
    subtitle: "Please follow instructions below to begin your healing process.",
    id: "3aa5c289-3fe3-4dec-8473-96b69894c4da"
  },
  summaryOfCare: {
    title: "Summary of Care",
    subtitle:
      "The following information highlights the evidence that supports the treatment course that your provider has determined is best for you."
  },
  faqs: {
    title: "Frequently Asked Questions",
    subtitle:
      "Below are some questions that patients with your conditions commonly ask."
  },
  resources: {
    title: "Resources",
    subtitle:
      "The following resources may be helpful for you in learning more about your condition."
  },
  medications: {
    title: "Medications and Coupons",
    subtitle:
      "The following medications were prescribed by your provider for treatment of your condition."
  },
  diagnoses: {
    title: "Diagnosis",
    subtitle: "You are being treated for the following conditions."
  },
  followup: {
    title: "Follow-Up Appointment",
    subtitle:
      "Timely follow-up care with the provider below will ensure that we have you on the right path to better health."
  }
};
export const DEFAULT_RIGHT_COLUMN_TEXT = {
  orders: (
    <div>
      <p>No orders to submit</p>
      <p>
        As you progress through the protocol, you may select labs, meds, and
        procedures during the decision process. They will show up here for you
        to take further action.
      </p>
    </div>
  ),
  pe: (
    <div>
      <p>Nothing to submit</p>
      <p>
        As selections are made in the protocol, EvidenceCare will build a
        comprehensive Care Summary for you here that you may share with your
        patient.
      </p>
    </div>
  ),
  ehr: (
    <div>
      <p>Nothing to submit</p>
      <p>
        As selections are made in the protocol, EvidenceCare will build clinical
        documentation for you here, including care decisions and evidence
        citations, that may be placed into any EHR.{" "}
      </p>
    </div>
  ),
  cds: (
    <div>
      <p>These features are not currently available. </p>
    </div>
  )
};

export const EHR_CATEGORY_IDS = {
  treatmentCourse: 1,
  evidenceSummary: 2,
  supportingStatement: 3
};
