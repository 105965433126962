import { easeInOutQuad } from "helpers/math";

// attempts to put the target element into view while preserving the
// specified offsets from the top and left of the target element's
// scrollable container
export async function scrollIntoView(targetEl, options = {}) {
  const {
    verticalOffset = 200,
    horizontalOffset = 200,
    duration = 1000
  } = options;

  // see if any of the ancestors are scrollable in either direction
  const scrollParentVert = getScrollableParentVert(targetEl);
  const scrollParentHoriz = getScrollableParentHoriz(targetEl);
  let topScrollPromise, leftScrollPromise;

  // structure it this way to reduce calls to expensive `getBoundingClientRect`
  let parentHorizRect;

  const targetRect = targetEl.getBoundingClientRect();

  // try to scroll the nearest scrollable DOM parent
  if (scrollParentVert) {
    const parentVertRect = scrollParentVert.getBoundingClientRect();

    // get rid of the vertical offset except for the value specified to save
    const targetTop = calcNewScroll(
      scrollParentVert.scrollTop,
      targetRect.top,
      parentVertRect.top,
      verticalOffset
    );
    topScrollPromise = simulateScroll(scrollParentVert, {
      targetScrollPosition: targetTop,
      duration
    });

    // reuse the bounding rect to do the same horizontally
    if (scrollParentHoriz === scrollParentHoriz) {
      parentHorizRect = parentVertRect;
    }

    // try to scroll the window
  } else {
    const targetTop = calcNewScroll(
      window.scrollY,
      targetRect.top,
      0,
      verticalOffset
    );
    topScrollPromise = simulateScroll(window, {
      targetScrollPosition: targetTop
    });
  }

  // try to scroll the nearest scrollable DOM parent
  if (scrollParentHoriz) {
    // set if not the same parent has the vertical scrollbar
    if (!parentHorizRect)
      parentHorizRect = scrollParentHoriz.getBoundingClientRect();

    // get rid of the vertical offset except for the value specified to save
    const targetLeft = calcNewScroll(
      scrollParentHoriz.scrollLeft,
      targetRect.left,
      parentHorizRect.left,
      horizontalOffset
    );
    leftScrollPromise = simulateScroll(scrollParentHoriz, {
      targetScrollPosition: targetLeft,
      side: "left",
      duration
    });

    // try to scroll the window
  } else {
    const targetLeft = calcNewScroll(
      window.scrollX,
      targetRect.left,
      0,
      horizontalOffset
    );
    leftScrollPromise = simulateScroll(window, {
      targetScrollPosition: targetLeft,
      side: "left"
    });
  }
  return Promise.all([topScrollPromise, leftScrollPromise]);
}

// checks to determine if the element has a vertical scrollbar
export function getScrollableParentVert(targetEl) {
  let parent = targetEl.parentNode;

  // keep going up until finding an element with a vertical scrollbar
  // cheap checks come before expensive checks
  // we have to check the computed style because the initial checks might indicate there could be a scrollbar
  // but the css rules applied might disable the scrollbar
  while (
    parent &&
    (parent.scrollHeight <= parent.clientHeight ||
      NOT_SCROLLABLE_STYLES.has(getComputedStyle(parent)["overflow-y"]))
  ) {
    parent = parent.parentNode;

    // make sure we only recurse up to the last actual dom node (not the document, a document fragment, etc.)
    if (parent.nodeType !== Node.ELEMENT_NODE) parent = null;
  }
  return parent;
}

// same as above but for checking for horizontal scrollbars
export function getScrollableParentHoriz(targetEl) {
  let parent = targetEl.parentNode;
  while (
    parent &&
    (parent.scrollWidth <= parent.clientWidth ||
      NOT_SCROLLABLE_STYLES.has(getComputedStyle(parent)["overflow-x"]))
  ) {
    parent = parent.parentNode;
    if (parent.nodeType !== Node.ELEMENT_NODE) parent = null;
  }
  return parent;
}

// returns the new scroll (top or left) parameter based on
// (1) the current scroll
// (2) the target's absolute distance from the top or the left of the viewport
// (3) the scrollable container's absolute distance from the top or left of the viewport
// (4) and the desired offset of the target within the container
export function calcNewScroll(
  currentScroll,
  targetAbsoluteDistance,
  parentAbsoluteDistance,
  desiredOffset
) {
  const currentOffset = targetAbsoluteDistance - parentAbsoluteDistance;
  const desiredScrollDelta = currentOffset - desiredOffset;
  return currentScroll + desiredScrollDelta;
}

// shared constant for styles that disable scrollbar
const NOT_SCROLLABLE_STYLES = new Set(["visible", "hidden", "clip"]);

// simulates scrolling on an quadratically spaced series of steps over time
// returns promise when scrolling is completed (i.e., scrolling on the page has stopped)
// element can either be a DOM node OR the main window of the page
export async function simulateScroll(element, options = {}) {
  const {
    targetScrollPosition = 0,
    side = "top",
    steps = 100,
    duration = 1000
  } = options;
  return new Promise(res => {
    const stepTime = duration / steps;

    // logic for returning the promise early if scrolling has stalled
    // (checks for a series of steps when scrolling doesnt change)
    const didntMoveThreshold = steps / 20;
    let didntMove = 0;
    let previousScroll = null;
    let exited = false;
    function shouldEarlyExit(newScroll) {
      if (previousScroll === newScroll) didntMove++;
      else didntMove = 0;

      if (didntMove === didntMoveThreshold) {
        res();
        exited = true;
      }

      previousScroll = newScroll;
    }

    let scrollFn;
    if (element === window) {
      if (side === "top") {
        const start = window.scrollY;
        const delta = targetScrollPosition - start;

        scrollFn = currentTime => {
          window.scrollTo(
            window.scrollX,
            easeInOutQuad(start, delta, currentTime, duration)
          );
          shouldEarlyExit(window.scrollY);
        };
      } else {
        const start = window.scrollX;
        const delta = targetScrollPosition - start;
        scrollFn = currentTime => {
          window.scrollTo(
            easeInOutQuad(start, delta, currentTime, duration),
            window.scrollY
          );
          shouldEarlyExit(window.scrollX);
        };
      }
    } else {
      const property = side === "top" ? "scrollTop" : "scrollLeft";
      const start = element[property];
      const delta = targetScrollPosition - start;
      scrollFn = currentTime => {
        shouldEarlyExit(
          (element[property] = easeInOutQuad(
            start,
            delta,
            currentTime,
            duration
          ))
        );
      };
    }

    for (let i = 1; i <= steps; i++) {
      const currentTime = stepTime * i;
      setTimeout(() => {
        if (!exited) scrollFn(currentTime);
      }, currentTime);
    }
    setTimeout(res, duration);
  });
}
