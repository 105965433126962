import PropTypes from "prop-types";
import React from "react";
import MarkdownRender from "utility/markdownRender";
import CitationList from "./CitationList";

export const TextItem = props => {
  const { item } = props;
  return (
    <div className="evidence-item-preview">
      <MarkdownRender
        className="evidence-item_markdown"
        source={item.text}
        escapeHtml
      />
      {item && item.citations && <CitationList citations={item.citations} />}
    </div>
  );
};
TextItem.propTypes = {
  item: PropTypes.object
};

export default TextItem;
