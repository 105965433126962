import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import MarkdownRender from "utility/markdownRender";
import { onAuth0ManualSignup } from "store/auth/actions";

const GENERIC_AUTH_ERROR_MESSAGE =
  "Something went wrong signing up.\n Please ensure you're using a valid email account.";

class Welcome extends React.PureComponent {
  state = {
    errorMessage: "",
    signupErrorFromApi: null
  };

  signViaUpAuthService(credentials) {
    return this.props.signup({ credentials }).catch(({ code, policy }) => {
      this.setState({ signupErrorFromApi: { code, message: policy } });
    });
  }

  renderErrors = () => {
    const { errorMessage, signupErrorFromApi } = this.state;
    if (signupErrorFromApi && typeof errorMessage !== "string") {
      return (
        <>
          <p>{signupErrorFromApi.code}</p>
          <MarkdownRender source={signupErrorFromApi.message} />
        </>
      );
    } else if (typeof errorMessage === "string") {
      return <span>{errorMessage.toUpperCase()}</span>;
    }
  };

  isFromSempaConference = () => {
    const { partner } = this.props;
    if (partner === "sempalink") {
      const currentDate = Date.now();
      const sempaConferenceStartDate = new Date(2019, 3, 15).valueOf();
      const sempaConferenceEndDate = new Date(2019, 4, 1).valueOf();
      return (
        currentDate > sempaConferenceStartDate &&
        currentDate < sempaConferenceEndDate
      );
    }
    return false;
  };

  render() {
    const {
      partner = "",
      email,
      cloudinaryUrl,
      welcomeText,
      signupText
    } = this.props;
    const signupCode = this.isFromSempaConference() ? "sempadow19" : partner;

    return (
      <div className="welcome-bg">
        <div className="welcome_wrap">
          <img className="welcome_banner-img" src={cloudinaryUrl} />
          <div className="welcome_signup-error">{this.renderErrors()}</div>
          <div className="welcome_form-container">
            <h4 className="welcome_header">Welcome</h4>
            <p dangerouslySetInnerHTML={{ __html: welcomeText }} />
            {email ? (
              <p>
                Input a <strong>password</strong> to sign up for an EvidenceCare
                account with the email: {email}
              </p>
            ) : (
              <p>{signupText}</p>
            )}
            <div>
              <Formik
                initialValues={{
                  password: "",
                  agreedToTOS: false,
                  email: email || ""
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Your email must be a valid email")
                    .required("email is required"),
                  password: Yup.string()
                    .min(8, "Password must be at least 8 characters long")
                    .matches(
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{0,}$/,
                      "Password must contain one lower case character, one upper case character, and a number."
                    )
                    .required("Password is required."),
                  agreedToTOS: Yup.bool().required()
                })}
                onSubmit={(values, { setSubmitting }) =>
                  this.signViaUpAuthService({
                    email: values.email,
                    password: values.password,
                    connection: "Email-Password-Authentication-With-Migration",
                    userMetadata: { signupCode }
                  })
                    .catch(error => {
                      this.setState({
                        errorMessage:
                          error.description || GENERIC_AUTH_ERROR_MESSAGE
                      });
                    })
                    .finally(() => {
                      setSubmitting(false);
                    })
                }
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    {!email && (
                      <div>
                        <input
                          placeholder="your email"
                          required
                          type="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {touched.email && errors.email && (
                          <div className="welcome_error-text">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    )}
                    <input
                      placeholder="your password"
                      required
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    {touched.password && errors.password && (
                      <div className="welcome_error-text">
                        {errors.password}
                      </div>
                    )}
                    {email && errors.email && (
                      <div className="welcome_error-text">{errors.email}</div>
                    )}
                    <div className="welcome_tos-container">
                      <label onChange={handleChange}>
                        <input
                          required
                          type="checkbox"
                          name="agreedToTOS"
                          value={values.agreedToTOS}
                        />
                        <span>
                          {" "}
                          I am a healthcare professional and I agree to the{" "}
                          <a
                            href="https://evidence.care/terms-of-use/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Terms of Service
                          </a>
                          .
                        </span>
                      </label>
                    </div>
                    <input
                      disabled={isSubmitting}
                      className="welcome_submit"
                      type="submit"
                      value="SIGN UP"
                    />
                    <div className="welcome_login-link-container">
                      <a
                        href={`/login?${
                          values.email || email
                            ? `email=${values.email || email}&`
                            : ""
                        }partner=${partner.toLowerCase()}`}
                      >
                        Already have an account? Login.
                      </a>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
        <img
          className="login_logo show-for-medium"
          src="//res.cloudinary.com/evidencecare/image/upload/v1496332970/tagalong-site-assets/evidencecare-logo.svg"
        />
      </div>
    );
  }
}

Welcome.propTypes = {
  cloudinaryUrl: PropTypes.string.isRequired,
  welcomeText: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  email: PropTypes.string,
  signupText: PropTypes.string,
  signup: PropTypes.func
};

const mapDispatchToProps = {
  signup: onAuth0ManualSignup
};

export default connect(
  null,
  mapDispatchToProps
)(Welcome);
