import { fetchPluginProtocolConfigs } from "lib/ec/plugin";

// ------------------------------------
// Constants
// ------------------------------------
const SET_PLUGIN = "SET_PLUGIN";

// ------------------------------------
// Actions
// ------------------------------------
export const setPlugin = ({ protocolConfig, protocolId }) => {
  return {
    type: SET_PLUGIN,
    payload: {
      protocolConfig,
      protocolId
    }
  };
};

export const fetchProtocolConfigs = (dispatch, state, protocolId) => {
  return fetchPluginProtocolConfigs({
    protocolId,
    token: state.user.token
  }).then(({ data }) => {
    const doesCustomConfigExist = data.length > 0;
    if (doesCustomConfigExist) {
      // a protocol can have multiple protocol configs if the protocol has configs in multiple plugins
      // to account for this, we are mapping over all protocol configs associtated with the current protocol and returning each config
      const formattedProtocolConfigs = data.reduce((acc, customConfig) => {
        const organization = customConfig.global_config.slug;
        acc[organization] = {
          id: customConfig.id,
          plugin: customConfig.global_config,
          config: customConfig.config
        };
        return acc;
      }, {});
      dispatch(
        setPlugin({
          protocolConfig: formattedProtocolConfigs,
          protocolId
        })
      );
    }
  });
};

export const fetchProtocolPlugin = protocolId => {
  return (dispatch, getState) => {
    fetchProtocolConfigs(dispatch, getState(), protocolId);
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_PLUGIN]: (state, action) => {
    const { protocolId, protocolConfig } = action.payload;
    return {
      ...state,
      protocolConfigs: {
        ...state.protocolConfigs,
        [protocolId]: protocolConfig
      }
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  protocolConfigs: {}
};
export default function pluginReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
