import React, { Fragment } from "react";
import PropTypes from "prop-types";
import OutputItemEditable from "components/patientEd/OutputItemEditable";
import R from "ramda";
import EhrJustification from "./EhrJustification";

const isInputSelected = (selectedCalc, input) => {
  switch (selectedCalc.usage.inputs[input.id].selected) {
    case true:
      return "(Yes)";
    case false:
      return "(No)";
    case null:
      return "(Not Answered)";
  }
};
const makeItemTextEl = selectedCalc => {
  const calcTitle = selectedCalc.usage.calculator.title;
  const headerString = `${calcTitle} \n
  Result: ${selectedCalc.selection.displayName}\n`;
  const inputGroupStrings = selectedCalc.usage.calculator.inputGroups.map(
    (inputGroup, index) => {
      const inputStrings = inputGroup.inputs.map(input => {
        return `- ${isInputSelected(selectedCalc, input)} ${input.title} +${
          input.value
        } \n`;
      });
      return `${
        inputGroup.title ? `${index + 1}. ${inputGroup.title}\n\n` : ""
      } ${inputStrings.join(" ")}`;
    }
  );

  return headerString + `\n\n${inputGroupStrings.join("")} `;
};

const EhrTreatmentCourse = ({
  treatmentCourseEhrs,
  calcs,
  reasonsForDeviation
}) => {
  return (
    <Fragment>
      <div className="ehr_treatment-summary-text">
        Here is a summary of the assessment and care decisions that were made on
        this patient
      </div>
      {R.values(treatmentCourseEhrs).map(ehr => {
        return (
          <Fragment key={ehr.id}>
            <div className="ehr_treatment-course-container">
              <div className="ehr_category-title">{ehr.title}</div>
              <div className="ehr_category-subtitle">{ehr.subtitle}</div>
              {ehr.ehrItems.map(ehrItem => (
                <OutputItemEditable
                  key={ehrItem.id}
                  item={ehrItem}
                  outputItemType="EHR_TREATMENT_COURSE"
                />
              ))}
            </div>
          </Fragment>
        );
      })}
      {calcs &&
        calcs.length > 0 &&
        calcs.map(calcOutput => (
          <div key={calcOutput.id} className="ehr_treatment-course-container">
            <OutputItemEditable
              key={calcOutput.id}
              item={calcOutput.usage}
              itemText={makeItemTextEl(calcOutput)}
              outputItemType="EHR_TREATMENT_COURSE"
            />
          </div>
        ))}
      {reasonsForDeviation && (
        <div className="row align-middle collapse">
          <EhrJustification reasonsForDeviation={reasonsForDeviation} />
        </div>
      )}
    </Fragment>
  );
};

export default EhrTreatmentCourse;

EhrTreatmentCourse.propTypes = {
  treatmentCourseEhrs: PropTypes.object.isRequired,
  calcs: PropTypes.array.isRequired,
  reasonsForDeviation: PropTypes.object
};
