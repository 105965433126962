import { Cerner } from "lib/fhir/cerner-index";
import { TSystem } from "lib/fhir/tsystem-index";
import { Epic } from "lib/fhir/epic-index";

export class fhirFactory {
  constructor() {}

  static getFhirModule(ehrType) {
    var fhirModule;

    if (ehrType === "cerner") {
      fhirModule = new Cerner();
    } else if (ehrType === "epic") {
      fhirModule = new Epic();
    } else if (ehrType === "tsystem") {
      fhirModule = new TSystem();
    } else {
      throw "Unknown EHR";
    }

    fhirModule.instantiateSmartData = function(fetchPatient) {
      return new Promise((resolve, reject) => {
        window.FHIR.oauth2.ready(
          smart => {
            this.currentSpec =
              this.getCurrentSpec(smart.server.serviceUrl) || this.dstu2;
            resolve(smart);
          },
          err => {
            reject(err);
          }
        );
      });
    };

    // ToDo: Set some default functions if they're undefined after instantiating the concrete class
    // This should help enforce each of the concrete classes are kept up to date (pseudo-interface)

    return fhirModule;
  }
}
