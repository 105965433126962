import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";

import "./HelpCenterArticleLink.scss";
import { goToHelpCenterArticle } from "store/helpCenter/actions";

export function ArticleLink({ title, id, onClick }) {
  return (
    <>
      <span className="icon-recommended help-center-article-link_icon" />
      <a onClick={() => onClick(id)} className="help-center-article-link_link">
        {title}
      </a>
    </>
  );
}

ArticleLink.propTypes = {
  title: Proptypes.string.isRequired,
  id: Proptypes.string.isRequired,
  onClick: Proptypes.func.isRequired
};

export default connect(
  null,
  { onClick: goToHelpCenterArticle }
)(ArticleLink);
