import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { onAuth0CallbackHash } from "store/auth/actions";
import { selectIsAuthenticated } from "store/auth/selectors";
import { history } from "myHistory";

const HASH_RE = /access_token|id_token|error/;

class AuthCB extends React.PureComponent {
  componentDidMount() {
    this.handleCallbackHash();
  }

  componentDidUpdate() {
    this.handleCallbackHash();
  }

  handleCallbackHash = () => {
    const {
      location: { hash },
      onAuth0CallbackHash,
      isAuthenticated
    } = this.props;
    if (HASH_RE.test(hash)) {
      onAuth0CallbackHash(hash);
    } else if (isAuthenticated) {
      history.push("/");
    }
  };

  render() {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
}

AuthCB.propTypes = {
  location: PropTypes.object,
  onAuth0CallbackHash: PropTypes.func,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: selectIsAuthenticated(state)
});

const mapDispatchToProps = {
  onAuth0CallbackHash
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthCB);
