import React from "react";
import PropTypes from "prop-types";
import Input from "react-validation/build/input";
import classnames from "classnames";
import Form from "react-validation/build/form";

const LOWERCASE_RE = /[a-z]/;
const NUM_RE = /\d+/g;
function checkForCapitalLetter(value) {
  return value.toLowerCase() !== value;
}
function checkForLowercaseLetter(value) {
  return LOWERCASE_RE.test(value);
}
function checkForLength(value) {
  return value.length > 7;
}
function checkForNumber(value) {
  return Boolean(value.match(NUM_RE));
}

/***************************************
 * Renders a password field with
 * some password requirements and validation messages.
 * When the validation requirements are met,
 * allows the user to submit the password and triggers
 * and the `onSubmit` callback prop.
 *
 * This component is specifically designed for use only
 * in the invite route. Future work can probably
 * be done to consolidate all of these disparate
 * login forms.
 ***************************************/

export default class InviteInput extends React.PureComponent {
  state = {
    password: "",
    hasCapitalLetter: false,
    hasLowercaseLetter: false,
    hasMinLength: false,
    hasCharacter: false,
    hasNumber: false
  };

  handleInputChange = e => {
    const password = e.target.value;
    this.setState({
      password,
      hasCharacter: Boolean(password),
      hasCapitalLetter: checkForCapitalLetter(password),
      hasLowercaseLetter: checkForLowercaseLetter(password),
      hasMinLength: checkForLength(password),
      hasNumber: checkForNumber(password)
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { password } = this.state;
    if (this.hasValidInput()) {
      this.props.onSubmit(password);
    }
  };

  hasValidInput = () => {
    const {
      hasCapitalLetter,
      hasLowercaseLetter,
      hasMinLength,
      hasNumber,
      hasCharacter
    } = this.state;
    return (
      hasCapitalLetter &&
      hasLowercaseLetter &&
      hasMinLength &&
      hasNumber &&
      hasCharacter
    );
  };

  render() {
    const {
      hasCapitalLetter,
      hasLowercaseLetter,
      hasMinLength,
      hasNumber
    } = this.state;

    const numberValidationClasses = classnames("face-up-validation", {
      "face-up-validation--valid": hasNumber
    });
    const capLetterValidationClasses = classnames("face-up-validation", {
      "face-up-validation--valid": hasCapitalLetter
    });
    const lowercaseValidationClasses = classnames("face-up-validation", {
      "face-up-validation--valid": hasLowercaseLetter
    });
    const minLengthValidationClasses = classnames("face-up-validation", {
      "face-up-validation--valid": hasMinLength
    });

    return (
      <Form onSubmit={this.onSubmit}>
        <Input
          onChange={this.handleInputChange}
          className="accept-invite_password-input"
          name="password"
          type="password"
          placeholder="Create new password"
        />
        <div className="face-up-validation_container">
          <div className="face-up-validation_validation-wrapper">
            {hasLowercaseLetter && <span className="icon-solid-circle-check" />}
            <p className={lowercaseValidationClasses}>
              password must include a lowercase letter (a-z)
            </p>
          </div>
          <div className="face-up-validation_validation-wrapper">
            {hasCapitalLetter && <span className="icon-solid-circle-check" />}
            <p className={capLetterValidationClasses}>
              password must include a capital letter (A-Z)
            </p>
          </div>
          <div className="face-up-validation_validation-wrapper">
            {hasNumber && <span className="icon-solid-circle-check" />}
            <p className={numberValidationClasses}>
              password must include a number (0-9)
            </p>
          </div>
          <div className="face-up-validation_validation-wrapper">
            {hasMinLength && <span className="icon-solid-circle-check" />}
            <p className={minLengthValidationClasses}>
              password must be at least 8 characters in length
            </p>
          </div>
        </div>
        <div className="accept-invite_tos-container">
          <input required type="checkbox" />
          <span>
            {" "}
            I am a healthcare professional and I agree to the{" "}
            <a
              href="https://evidence.care/terms-of-use/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
            .
          </span>
        </div>
        <button
          disabled={!this.hasValidInput()}
          type="submit"
          className="accept-invite_submit-button"
        >
          <span className="accept-invite_submit-button-label">
            Accept Invite
          </span>
        </button>
      </Form>
    );
  }
}

InviteInput.propTypes = {
  onSubmit: PropTypes.func
};
