import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import uuid from "uuid";
import dotProp from "dot-prop";
import { logSentryError } from "helpers/logging/error";
import { showLoader, hideLoader } from "store/ui";
import { Comment, Form, TextArea } from "semantic-ui-react";
import axios from "axios";
import R from "ramda";
import EcComment from "./EcComment";
import {
  setProtocol,
  addCommentToProtocol,
  hideCommentSidebar
} from "store/comments";
class Comments extends Component {
  state = {
    showSuccess: false,
    data: null,
    newComment: "",
    replyTo: ""
  };
  componentDidMount() {
    const { commentsByProtocolId, protocolId, setProtocol } = this.props;
    // pulling all protocol comments
    if (!commentsByProtocolId[protocolId]) {
      protocolId &&
        axios({
          method: "GET",
          url: `${COMMENT_CONTENT_URL}/protocol/${protocolId}.json`,
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(resp => {
            setProtocol({
              protocolId,
              data: resp.data
            });
          })
          .catch(err => {
            logSentryError(err);
            setProtocol({
              protocolId,
              data: []
            });
          });
    }
  }

  submitComment = e => {
    e.preventDefault();
    this.props.showLoader();
    const {
      firstName,
      lastName,
      email,
      protocolId,
      sectionId,
      outputs,
      userId,
      alias,
      ecToken,
      cloudinaryId,
      usageId,
      itemId,
      session,
      activeThread
    } = this.props;
    const body = {
      markdown: this.state.newComment,
      firstName,
      lastName,
      ecToken,
      email,
      protocolId,
      sectionId,
      userId,
      cloudinaryId,
      alias,
      id: uuid.v4(),
      usageId,
      itemId,
      selectectedOutputs: R.values(outputs)
        .filter(op => op.selected)
        .map(op => op.id)
    };
    const localAuthor = {
      firstName,
      lastName,
      email,
      userId,
      alias,
      cloudinaryId
    };
    if (this.props.socketIsConnected) {
      const redactedSession = {
        id: session.id,
        protocolsOrder: session.protocolsOrder,
        customOutputItems: {},
        rxSetItems: session.rxSetItems,
        useProtocolVersion: session.useProtocolVersion,
        entities: session.entities,
        activeProtocolId: session.activeProtocolId,
        activeOutputTab: session.activeOutputTab,
        providerNoteText: ""
      };
      const comment = {
        ...body,
        author: localAuthor,
        createdAt: Date.now(),
        protocolName: this.props.activeProtocolTitle,
        locationDescription: activeThread.locationDescription,
        internalName: activeThread.internalName
      };
      axios({
        method: "POST",
        url: `${COMMENT_SERVICE}/comments`,
        headers: {
          Authorization: "Bearer " + this.props.ecToken,
          "Content-Type": "application/json"
        },
        data: {
          comment,
          session: redactedSession
        }
      })
        .then(resp => {
          this.setState({
            newComment: ""
          });
          this.props.addCommentToProtocol({
            comment
          });
          this.props.hideLoader();
        })
        .catch(err => {
          logSentryError(err);

          this.props.hideLoader();
        });
    }
  };
  handleChange = (e, { name, value }) =>
    this.setState({
      [name]: value
    });
  render() {
    const comments =
      (this.props.protocolComments &&
        this.props.protocolComments.filter(
          com => com.itemId === this.props.itemId
        )) ||
      [];
    return (
      <div className="comments">
        <i
          aria-hidden="true"
          className="icon icon-close comments-sidebar_close-button"
          onClick={this.props.hideCommentSidebar}
        />
        <Comment.Group>
          <Form>
            <TextArea
              rows={2}
              placeholder="Add a comment"
              onChange={this.handleChange}
              name="newComment"
              value={this.state.newComment}
            />
            <div className="text-right">
              <button
                className="button button_standard comments_reply-button"
                disabled={this.state.newComment.length === 0}
                onClick={this.submitComment}
              >
                Submit
              </button>
            </div>
          </Form>{" "}
          {comments &&
            comments.map((com, i) => {
              return (
                <EcComment
                  {...com}
                  comment={com}
                  parentIndex={i}
                  parentId={com._id}
                  key={com.id}
                />
              );
            })}
        </Comment.Group>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    protocolComments: dotProp.get(
      state,
      `comments.protocol.${state.session.activeProtocolId}`
    ),
    protocolId: state.session.activeProtocolId,
    sectionId: dotProp.get(
      state,
      `session.entities.protocols.${state.session.activeProtocolId}.activeSectionId`
    ),
    outputs: state.session.entities.outputs,
    email: state.user.email,
    firstName: state.user.first_name,
    lastName: state.user.last_name,
    alias: state.user.alias,
    userId: state.user.user_uid,
    ecToken: state.account.ecToken.token,
    cloudinaryId: state.user.cloudinary_id,
    commentsByProtocolId: state.comments.protocol,
    activeThread: state.comments.activeThread,
    usageId: state.comments.activeThread.usageId,
    itemId: state.comments.activeThread.itemId,
    socketIsConnected: state.comments.socketIsConnected,
    session: state.session,
    activeProtocolTitle: dotProp.get(
      state,
      `protocol.${state.session.activeProtocolId}.title`,
      "none"
    )
  };
};
const mapDispatchToProps = {
  setProtocol,
  addCommentToProtocol,
  showLoader,
  hideLoader,
  hideCommentSidebar
};
Comments.propTypes = {
  medProfileId: PropTypes.number,
  setProtocol: PropTypes.func,
  commentsByProtocolId: PropTypes.object,
  protocolId: PropTypes.string,
  comments: PropTypes.array,
  protocolComments: PropTypes.array,
  addCommentToProtocol: PropTypes.func,
  socketIsConnected: PropTypes.bool,
  ecToken: PropTypes.string,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  activeProtocolTitle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  sectionId: PropTypes.string,
  outputs: PropTypes.array,
  userId: PropTypes.string,
  alias: PropTypes.string,
  cloudinaryId: PropTypes.string,
  usageId: PropTypes.string,
  itemId: PropTypes.string,
  session: PropTypes.object,
  activeThread: PropTypes.string,
  hideCommentSidebar: PropTypes.bool
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);
