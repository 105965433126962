import axios from "axios";

import { logSentryError, logSentryServerError } from "helpers/logging/error";
import { store } from "store/store";
import {
  selectIsAuthTokenValid,
  selectAuthToken,
  selectECToken,
  selectFHIRTokenURI,
  selectIsECTokenValid
} from "store/auth/selectors";
import { launchLogin } from "store/auth/actions";

//
// EC API Calls & Helpers
//

export const makeEcRequest = async ({ method, location, body = {} }) => {
  const state = store.getState();

  if (!selectIsAuthTokenValid(state)) {
    logSentryError(new Error("Auth token is not set or is expired!"));
    store.dispatch(launchLogin());
    return null;
  }

  const url = prependServiceURLIfNotPresent(location, EC_SERVICE_API);
  const token = selectAuthToken(state);

  const request =
    FHIR_ENVIRONMENT === "epic"
      ? axios({
          method: method,
          url,
          headers: {
            Authorization:
              "Introspect " +
              token +
              "; url " +
              selectFHIRTokenURI(state).replace("/token", "/introspect"),
            "Content-Type": "application/json"
          },
          data: body
        })
      : axios({
          method: method,
          url,
          headers: {
            Authorization: "JWT " + token,
            "Content-Type": "application/json"
          },
          data: body
        });

  return request.catch(err => {
    logSentryServerError(err);
    return null;
  });
};

export const makeEcCdsRequest = async ({ method, location, body = {} }) => {
  const state = store.getState();

  if (!selectIsAuthTokenValid(state)) {
    logSentryError(new Error("Auth token is not set or is expired!"));
    store.dispatch(launchLogin());
    return null;
  }

  const url = prependServiceURLIfNotPresent(location, EC_CDS_SERVICE);
  const token = selectAuthToken(state);

  const request =
    FHIR_ENVIRONMENT === "epic"
      ? axios({
          method: method,
          url,
          headers: {
            Authorization:
              "Introspect " +
              token +
              "; url " +
              selectFHIRTokenURI(state).replace("/token", "/introspect"),
            "Content-Type": "application/json"
          },
          data: body
        })
      : axios({
          method: method,
          url,
          headers: {
            Authorization: "JWT " + token,
            "Content-Type": "application/json"
          },
          data: body
        });

  return request.catch(err => {
    logSentryServerError(err);
    return null;
  });
};

export const makeEcContentRequest = async ({ method, location, body = {} }) => {
  const state = store.getState();

  if (!selectIsECTokenValid(state)) {
    logSentryError(new Error("EC token is not set or is expired!"));
    store.dispatch(launchLogin());
    return null;
  }

  return axios({
    method: method,
    url: prependServiceURLIfNotPresent(location, EC_CONTENT_SERVICE),
    headers: {
      Authorization: "Bearer " + selectECToken(state),
      "Content-Type": "application/json"
    },
    data: body
  });
};

export const makeOmniRequest = async ({ method, location, body = {} }) => {
  const state = store.getState();

  if (!selectIsECTokenValid(state)) {
    logSentryError(new Error("EC token is not set or is expired!"));
    store.dispatch(launchLogin());
    return null;
  }

  return axios({
    method: method,
    url: prependServiceURLIfNotPresent(location, EC_OMNI_SERVICE),
    headers: {
      Authorization: "Bearer " + selectECToken(state),
      "Content-Type": "application/json"
    },
    data: body
  }).catch(err => {
    logSentryServerError(err);
    return null;
  });
};

export const makePdfRequest = async (type, body = {}) => {
  const state = store.getState();

  if (!selectIsAuthTokenValid(state)) {
    logSentryError(new Error("Auth token is not set or is expired!"));
    store.dispatch(launchLogin());
    return null;
  }

  return axios({
    method: "POST",
    url: prependServiceURLIfNotPresent(type, EC_PDF_API),
    headers: { Authorization: "JWT " + selectAuthToken(state) },
    data: body
  }).catch(err => {
    logSentryServerError(err);
    return null;
  });
};

export const makeNonAuthEcRequest = async ({ method, location, body = {} }) => {
  return axios({
    method: method,
    url: prependServiceURLIfNotPresent(location, EC_SERVICE_API),
    data: body
  }).catch(err => {
    logSentryServerError(err);
    return null;
  });
};

export const makeProxyEcRequest = async ({ method, location, body }) => {
  const state = store.getState();

  if (!selectIsAuthTokenValid(state)) {
    logSentryError(new Error("Auth token is not set or is expired!"));
    store.dispatch(launchLogin());
    return null;
  }

  return axios({
    method,
    url: prependServiceURLIfNotPresent(location, EC_SERVICE_PROXY),
    headers: {
      Authorization: "JWT " + selectAuthToken(state),
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    data: body
  }).catch(err => {
    logSentryServerError(err);
    return null;
  });
};

export const makePipelineRequest = async ({ method, body }) => {
  const state = store.getState();

  if (!selectIsECTokenValid(state)) {
    logSentryError(new Error("EC token is not set or is expired!"));
    console.log("pipeline request about to fire launchLogin");
    store.dispatch(launchLogin());
    return null;
  }

  return axios({
    method,
    url: `${DATA_PIPELINE}/ectoken`,
    headers: { Authorization: "Bearer " + selectECToken(state) },
    data: body
  }).catch(err => {
    logSentryServerError(err);
    return null;
  });
};

/*************************************************************
 * Utilities
 **********************************************************/
function prependServiceURLIfNotPresent(location, serviceURL) {
  return location.substring(0, 4) === "http"
    ? location
    : serviceURL[serviceURL.length - 1] === "/"
    ? serviceURL + location
    : serviceURL + "/" + location;
}
