import React from "react";
import PropTypes from "prop-types";

const FollowUpApptForm = ({ appointment, onChange, onSubmit }) => {
  return (
    <div className="follow-up-form_container">
      <span className="follow-up-form_header">Follow-up Appointment</span>
      <form className="follow-up-form_form">
        <div className="row">
          <label className="follow-up-form_label_left columns small-2">
            Provider:{" "}
          </label>
          <input
            label="provider"
            type="text"
            className="columns small-10 follow-up-form_input"
            name="provider"
            value={appointment.provider}
            onChange={onChange}
          />
        </div>
        <div className="row">
          <label className="follow-up-form_label_left columns small-2">
            Specialty:{" "}
          </label>
          <input
            label="followUpSpecialty"
            type="text"
            className="columns small-10 follow-up-form_input"
            name="followUpSpecialty"
            value={appointment.followUpSpecialty}
            onChange={onChange}
          />
        </div>
        <div className="row">
          <label className="follow-up-form_label_left columns small-2">
            Date:{" "}
          </label>
          <input
            label="followUpDate"
            type="date"
            className="columns small-4 follow-up-form_input"
            name="followUpDate"
            value={appointment.followUpDate}
            onChange={onChange}
          />
          <label className="columns small-2"> Time</label>
          <input
            label="followUpTime"
            type="time"
            className="columns small-4 follow-up-form_input"
            name="followUpTime"
            value={appointment.followUpTime}
            onChange={onChange}
          />
        </div>
        <input
          className="patient-ed_provider-note-save"
          onClick={onSubmit}
          type="submit"
          value="Save"
        />
      </form>
    </div>
  );
};

FollowUpApptForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FollowUpApptForm;
