import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import classNames from "classnames";
/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
/* eslint-disable max-len */
class EhrAccordion extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: props.defaultToOpen
    };
  }
  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { title, editButton, render } = this.props;
    const { isOpen } = this.state;
    const indicatorClasses = classNames("ehr-accordion_indicator", {
      "triangle-right": !isOpen,
      "triangle-down": isOpen
    });

    return (
      <div className="ehr-accordion_container">
        <div onClick={this.toggleAccordion} className="ehr-accordion_header">
          <div className={indicatorClasses} />
          <div className="ehr-accordion_title">{title}</div>
        </div>
        <div className="ehr-accordion-actions_container">
          <div
            className="ehr-accordion-actions_edit"
            onClick={this.toggleAccordion}
          >
            {editButton}
          </div>
        </div>
        {isOpen && (
          <Fragment>
            {render({ toggleAccordion: this.toggleAccordion })}
          </Fragment>
        )}
      </div>
    );
  }
}

EhrAccordion.propTypes = {
  editButton: PropTypes.node,
  title: PropTypes.node,
  actions: PropTypes.node,
  render: PropTypes.func,
  defaultToOpen: PropTypes.bool
};

export default EhrAccordion;
