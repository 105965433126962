import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCMETests, fetchCMECertificate } from "store/account";
import { EVENT_FEATURE_CME_PRINT } from "constants/broadcastEventTypes";
import { getCMECertificateApi, getCMETestApi } from "lib/ec/account";
import { LoaderLineSpin } from "components/loaders";
import { printSummary, transformAccreditations } from "./cme-pdf";
import Moment from "moment";
import { pagination } from "components/ui/nav";
import { pipelineRequest } from "store/pipeline";

const pageSettings = (() => {
  const today = new Date();
  const year = today.getFullYear();
  return {
    year,
    pageSize: 10
  };
})();

export class CMEHistory extends Component {
  state = {
    loading: true
  };
  fetchTests = (currentPage = 1) => {
    this.setState({ loading: true });
    this.props
      .fetchCMETests({
        year: pageSettings.year,
        currentPage,
        pageSize: pageSettings.pageSize
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };
  setPage = currentPage => {
    this.fetchTests(currentPage);
  };
  componentDidMount() {
    const { cmeTestHistory } = this.props;
    if (cmeTestHistory.count === 0) {
      this.fetchTests();
    } else {
      // we already have tests in the reducer so can hide loader
      this.setState({ loading: false });
    }
  }

  viewPdf = (e, id, year) => {
    e.preventDefault();
    this.props.pipelineRequest({
      action: EVENT_FEATURE_CME_PRINT,
      message: {
        test_id: id
      }
    });

    let pdfWindow = window.open("about:blank", "_blank");
    getCMECertificateApi(id).then(({ data }) => {
      if (data.url) {
        pdfWindow.location.href = data.url;
      } else {
        // failed, closing new window
        pdfWindow.close();
        getCMETestApi(id).then(({ data }) => {
          const { medSocieties, total } = transformAccreditations(
            data.accreditations
          );
          this.props.printSummary({ medSocieties, testId: id, total, year });
        });
        pdfWindow.close();
      }
    });
  };

  tableRows = tests => {
    return tests.map(test => (
      <tr key={test.id}>
        <td>{Moment(test.created_at).format("MMM Do, YYYY")}</td>
        <td>{test.score}</td>
        <td>
          <div
            onClick={e => this.viewPdf(e, test.id, test.year)}
            className="cme-history_download"
          >
            <span className="icon-download" />
          </div>
        </td>
      </tr>
    ));
  };

  render() {
    const { loading } = this.state;
    const { count, pageSize, currentPage, tests } = this.props.cmeTestHistory;
    return (
      tests &&
      tests.length > 0 && (
        <div className="cme-history">
          <h3 className="cme-history_title">CME History</h3>
          {count > pageSize &&
            pagination({ count, pageSize, currentPage, setPage: this.setPage })}
          {tests.length > 0 && !loading ? (
            <table className="cme-history_table">
              <thead>
                <tr>
                  <th column="date">Date</th>
                  <th column="score">Score</th>
                  <th column="certificate" className="cme-history_certificate">
                    Certificate
                  </th>
                </tr>
              </thead>
              <tbody>{this.tableRows(tests)}</tbody>
            </table>
          ) : (
            (loading && (
              <div className="cme-history_loader">
                <LoaderLineSpin />
              </div>
            )) || (
              <p>
                No history. Click Print Certificate to take a test which will
                generate a PDF of your CME certificate.
              </p>
            )
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  tests: state.account.cme.tests,
  cmeTestHistory: state.account.cmeTestHistory
});
const mapDispatchToProps = {
  fetchCMETests,
  fetchCMECertificate,
  printSummary,
  pipelineRequest
};

CMEHistory.propTypes = {
  cmeTestHistory: PropTypes.object,
  user: PropTypes.object,
  fetchCMETests: PropTypes.func,
  printSummary: PropTypes.func,
  pipelineRequest: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMEHistory);
