import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOrderData, getHandshakeResponse } from "store/FHIR_orders";
import { pipelineRequest } from "store/pipeline";
import {
  EVENT_FEATURE_ORDERS_SEND_TO_EHR,
  EVENT_ORDER_ERROR
} from "../../constants/broadcastEventTypes";
import { fhirFactory } from "lib/fhir/factory";
import { checkForMpages } from "lib/fhir/mpage";
import { setSentOrders } from "store/FHIR_orders";
import { clearRxSelections, clearOrdersSelections } from "store/session";
import { getSelectedOrderData } from "selectors/orderstringSelectors";
import { getSelectedMedData } from "selectors/medSelectors";

export function FHIR_SubmitOrdersButton({
  selectedOrders,
  selectedMeds,
  getOrderData,
  pipelineRequest,
  getHandshakeResponse,
  orderListener,
  orderSender,
  uiCleanup,
  setSentOrders,
  clearOrdersSelections,
  clearRxSelections,
  getSelectedMedData,
  getSelectedOrderData,
  getState
}) {
  const submitButton = React.useRef(null);
  const submitClasses =
    selectedMeds.length === 0 && selectedOrders.length === 0
      ? "button button-copy orders-send-button orders-send-button-disabled"
      : "button button-copy orders-send-button";

  orderListener = event => {
    console.log(`EC window received message from ${event.origin} `, event);
    const fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);

    //do some initial response checking before either processing the response or logging an error
    if (event.origin !== window.origin) {
      console.log("EC window received message from UNKNOWN ORIGIN");
      pipelineRequest({ action: EVENT_ORDER_ERROR, message: event.data });
    } else if (event.data && event.data.actionExecuted) {
      //actionExecuted is epic specific and needs to be in the repo
      console.log("EC window received order response");
      fhirModule.processOrderResponse(event);
      fhirModule.cleanupOrderResponse(orderListener);
    } else {
      console.log("EC window received message and was unable to process");
      pipelineRequest({ action: EVENT_ORDER_ERROR, message: event.data });
    }
    return;
  };

  orderSender = () => {
    console.log("order sender");

    const fhirModule = fhirFactory.getFhirModule(FHIR_ENVIRONMENT);
    const orderData = getOrderData();
    const orderRequest = fhirModule.buildOrder({
      token: orderData.token,
      patientId: orderData.patientId,
      encounterId: orderData.encounterId,
      selectedOrders: orderData.selectedOrders,
      selectedMeds: orderData.selectedMeds
    });

    const handshakeToken = getHandshakeResponse();
    if (handshakeToken) {
      orderRequest.data.token = handshakeToken.token;
    }

    fhirModule.initiateOrder(orderRequest.data, orderListener, submitButton);

    uiCleanup = () => {
      console.log("selectedMeds", selectedMeds);
      if (selectedOrders.length === 0 && selectedMeds.length === 0) {
        alert("At least one order must be selected");
        console.log("ORDERS: At least one order must be selected.");
      } else if (selectedOrders.length > 0 || selectedMeds.length > 0) {
        setSentOrders(selectedOrders, selectedMeds);
        clearRxSelections();
        clearOrdersSelections();
        console.log("ORDERS: SENT");
      } else {
        alert("An error occured. Please try again or contact support.");
      }
    };

    //update the store and pipeline
    setTimeout(() => uiCleanup(), 500);
    pipelineRequest({
      action: EVENT_FEATURE_ORDERS_SEND_TO_EHR,
      message: {
        selectedMedsIds: selectedMeds.map(med => ({
          prescriptionset_id: med.rxSetId,
          prescriptions_ids: med.prescriptions
        })),
        selectedOrdersOutputIds: selectedOrders.map(order => order.outputId)
      }
    });
  };

  return (
    <a
      className={submitClasses}
      disabled={selectedOrders.length === 0 && selectedMeds.length === 0}
      ref={submitButton}
      href={"#"}
      onClick={() => {
        orderSender();
      }}
    >
      <span className="icon-submit-arrow" /> Send to EHR
    </a>
  );
}

FHIR_SubmitOrdersButton.propTypes = {
  selectedOrders: PropTypes.array.isRequired,
  selectedMeds: PropTypes.array.isRequired,
  getOrderData: PropTypes.func,
  pipelineRequest: PropTypes.func,
  getHandshakeResponse: PropTypes.func,
  setSentOrders: PropTypes.func,
  clearOrdersSelections: PropTypes.func,
  clearRxSelections: PropTypes.func
};
const mapStateToProps = state => ({
  selectedOrders: getSelectedOrderData(state),
  selectedMeds: getSelectedMedData(state)
});
export default connect(
  mapStateToProps,
  {
    getOrderData,
    pipelineRequest,
    getHandshakeResponse,
    setSentOrders,
    clearOrdersSelections,
    clearRxSelections
  }
)(FHIR_SubmitOrdersButton);
