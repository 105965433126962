import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Loader extends Component {
  render() {
    return (
      (this.props.loader && (
        <div className="global-loader">
          <div className="global-loader_spinner-wrap">
            <div className="global-loader_spinner" />
          </div>
        </div>
      )) ||
      null
    );
  }
}

const mapStateToProps = state => {
  return {
    loader: state.ui.loader
  };
};
Loader.propTypes = {
  loader: PropTypes.bool
};
export default connect(
  mapStateToProps,
  null
)(Loader);
