// This implementation is taken directly from https://github.com/jquery/jquery/issues/3444
/* eslint-disable eqeqeq, space-before-function-paren, space-in-parens, quotes, one-var, padded-blocks */
; (function (global) {
  global = global || window
  if ('$' in global) return

  var getProto = function () { return Object.getPrototypeOf }
  // Extra "=" makes no sense when both sides are always the same type
  // TODO: Adjust lint rules so this is required only when it makes sense for clarity

  // jQuery.isPlainObject is deprecated
  var isPlainObject = function (obj) {
    var proto

    if (toString.call(obj) == "[object Object]") {
      proto = getProto(obj)

      // Objects foolish enough to have prototypes with their own - hasOwnProperty - method are not supported
      return !proto || !proto.hasOwnProperty || proto.hasOwnProperty("hasOwnProperty")
    }

    return false
  }

  var extend = function () {
    var source, name, sourceProperty, targetProperty, clonedTargetProperty,
      target = arguments[0],
      i = 1,
      length = arguments.length,
      deep = false

    // Handle a deep copy situation
    if (typeof target == "boolean") {
      deep = target

      // Skip the boolean and the target
      target = arguments[i]
      i++
    }

    // Extend jQuery itself if only one argument is passed
    if (i == length) {
      target = this
      i--
    }

    // Loop through n objects
    for (; i < length; i++) {
      source = arguments[i]

      // Only deal with non-null/undefined values
      // NOTE: How was the previous source != null getting away with loose comparison?
      // In any event, it is obfuscating to do loose comparison to null
      if (source !== null && source !== undefined) {

        // Extend the base object
        for (name in source) {
          targetProperty = target[name]
          sourceProperty = source[name]

          // Prevent infinite loop
          if (target !== sourceProperty) {
            if (deep && sourceProperty) {
              clonedTargetProperty = null

              if (Array.isArray(sourceProperty)) {
                clonedTargetProperty = targetProperty &&
                  Array.isArray(targetProperty)
                  ? targetProperty : []
              } else if (isPlainObject(sourceProperty)) {
                clonedTargetProperty = targetProperty &&
                  isPlainObject(targetProperty)
                  ? targetProperty : {}
              }

              target[name] = clonedTargetProperty
                ? extend(true, clonedTargetProperty, sourceProperty)
                : sourceProperty

              // Don't bring in undefined values
            } else if (sourceProperty !== undefined) {
              target[name] = sourceProperty
            }
          }
        }
      }
    }

    // Return the modified object
    return target
  }

  // polyfill the jquery $.extend method so that the fhirclient works properly.  This IS NOT ideal and I think
  // the fhirclient library should have a pull request submitted to it and have this modified.
  var $ = {}
  $.extend = extend
  if ('module' in global) module.exports = $
  global.$ = $
})()
