import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchMcgGuideline } from "./mcgReducer";
import McgSubmitBar from "./components/McgSubmitBar";
import { getSelectedGuideline } from "./mcgSelectors";
import McgHeader from "./components/McgHeader";
import McgIndicationsContainer from "./components/McgIndicationsContainer";
import dotProp from "dot-prop";
import { editionText } from "./helpers";

export class Mcg extends Component {
  year = new Date().getFullYear();

  handleGuidelineSelect = ({ guideline }) => {
    const { fetchMcgGuideline } = this.props;
    fetchMcgGuideline({
      hsim: guideline.hsim
    });
  };

  render() {
    const { note, edition, selectedGuideline } = this.props;

    return (
      <div className="section section--active">
        <McgSubmitBar
          closeComponent={this.props.closeComponent}
          guideline={selectedGuideline}
        />
        <McgHeader
          handleGuidelineSelect={this.handleGuidelineSelect}
          selectedGuideline={selectedGuideline}
        />
        {note && (
          <div className="mcg-guideline-note">
            <p>{note}</p>
          </div>
        )}
        <McgIndicationsContainer selectedGuideline={selectedGuideline} />
        <p className="mcg-copyright">
          MCG Health, {editionText(edition)} edition, Copyright &copy;{" "}
          {this.year} MCG Health, LLC All Rights Reserved
        </p>
      </div>
    );
  }
}

Mcg.propTypes = {
  selectedGuideline: PropTypes.object,
  fetchMcgGuideline: PropTypes.func,
  note: PropTypes.string,
  closeComponent: PropTypes.func,
  edition: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const selectedGuideline = getSelectedGuideline(state);
  return {
    note: state.mcg.note,
    selectedGuideline,
    edition: dotProp.get(
      state,
      `mcg.context.${selectedGuideline.hsim}.edition`,
      ""
    )
  };
};

const mapDispatchToProps = {
  fetchMcgGuideline
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mcg);
