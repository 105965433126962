import PropTypes from "prop-types";
import React from "react";
import { largeImageUrl } from "helpers/urls/cloudinary";
import ModalContainer from "components/protocol/containers/ModalContainer";
import MediaQuery from "react-responsive";

export const MediaItem = ({ item }) => {
  const renderImage = () =>
    (item.cdnId && <img src={largeImageUrl(item.cdnId)} />) || (
      <div>No media attached</div>
    );
  return (
    <div className="evidence-default-image">
      {item.title && (
        <h5 className="evidence-default-image_title">{item.title}</h5>
      )}
      <MediaQuery minDeviceWidth={600}>
        {matches => {
          if (matches) {
            return (
              <ModalContainer title={item.title || ""}>
                {renderImage()}
              </ModalContainer>
            );
          } else {
            return renderImage();
          }
        }}
      </MediaQuery>
    </div>
  );
};
MediaItem.propTypes = {
  item: PropTypes.object
};

export default MediaItem;
