export const generateOptionSetHeaderText = (optionSetIndex, optionSet) => {
  let choiceText = "";
  if (optionSet.header) {
    choiceText = optionSet.header;
  } else if (optionSetIndex === 0) {
    if (optionSet.prescriptionSets.length === 1) {
      choiceText = "Select the following";
    } else if (optionSet.choiceType === "MULTIPLE") {
      choiceText = "Select one (or more) of the following";
    } else {
      choiceText = "Select one of the following";
    }
  } else if (optionSet.prescriptionSets.length > 1) {
    const mode = optionSet.mode || "PLUS";
    const typeText =
      optionSet.choiceType === "MULTIPLE" ? "one (or more)" : "one";
    choiceText = `${mode} ${typeText} of the following`;
  } else if (optionSet.prescriptionSets.length === 1) {
    const mode = optionSet.mode || "PLUS";
    choiceText = `${mode} the following`;
  }
  return choiceText;
};
