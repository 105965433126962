import { combineReducers } from "redux";
import { enableBatching } from "redux-batched-actions";

import locationReducer from "./location";
import sessionReducer from "./session";
import uiReducer from "./ui";
import modalReducer from "./modal/reducer";
import protocolReducer from "./protocol";
import startPageReducer from "./startPage";
import tourReducer from "./tour/reducer";
import listsSmallReducer from "./listsSmall";
import patientReducer from "./patient";
import UserReducer from "./user/reducer";
import { routerReducer } from "react-router-redux";
import { reducer as notifications } from "react-notification-system-redux";
import accountReducer from "./account";
import ordersReducer from "./FHIR_orders";
import bootstrapContent from "store/bootstrap";
import mcgReducer from "integrations/mcg/mcgReducer";
import commentsReducer from "./comments";
import pluginReducer from "./plugin";
import reviewReducer from "./review";
import pipelineReducer from "./pipeline";
import helpCenterReducer from "./helpCenter/reducer";
import adminReducer from "./admin/reducer";
import associationsReducer from "./associations/reducer";
import authReducer from "store/auth/reducer";
import termsReducer from "store/terms/reducer";

export const makeRootReducer = asyncReducers => {
  return enableBatching(
    combineReducers({
      auth: authReducer,
      admin: adminReducer,
      location: locationReducer,
      session: sessionReducer,
      ui: uiReducer,
      modal: modalReducer,
      protocol: protocolReducer,
      startPage: startPageReducer,
      associations: associationsReducer,
      routing: routerReducer,
      terms: termsReducer,
      tour: tourReducer,
      helpCenter: helpCenterReducer,
      smallLists: listsSmallReducer,
      patient: patientReducer,
      user: UserReducer,
      account: accountReducer,
      FHIR_orders: ordersReducer,
      comments: commentsReducer,
      review: reviewReducer,
      bootstrapContent,
      notifications,
      mcg: mcgReducer,
      plugins: pluginReducer,
      pipeline: pipelineReducer,
      ...asyncReducers
    })
  );
};

export default makeRootReducer;
