import axios from "axios";
import { getConfigByOrgSlug } from "helpers/config";
import {
  FHIR_EPIC_PATIENT_IDENTIFIER_ENDPOINT_PATH,
  FHIR_EPIC_PROXY_URL
} from "constants/fhir";
import {} from "../../../public/static/fhir/fhir-client";
import { logSentryError } from "helpers/logging/error";
import nativeFhir from "fhir.js/src/adapters/native";
import isEmpty from "lodash/isEmpty";
import R from "ramda";
import {
  ehrStringObject,
  ehrStringMarkup,
  inputGroupStringsMarkup,
  diagnosesTitleArray,
  diagnosesString
} from "helpers/ehr/ehr";

export class Epic {
  // ToDo: Move this into the factory? It's redundant in each of the classes
  stu3 = "stu3";
  dstu2 = "dstu2" || "v/r2";
  specs = [this.stu3, this.dstu2];
  currentSpec;

  //*********************************************************
  //EMR Specific Routines
  //*********************************************************
  getCurrentSpec(url) {
    this.specs.filter(spec => url.toLowerCase().includes(spec))[0];
  }

  clearForLaunch = (requestedScopes, returnedScopes) => {
    return requestedScopes
      .split(" ")
      .every(elem => returnedScopes.split(" ").indexOf(elem) > -1);
  };

  //*********************************************************
  // Patient data access methods
  //*********************************************************
  getPatient = patient => {
    return {
      id: patient.id,
      name: `${this.getPatientFirstName(
        this.currentSpec,
        patient.name
      )} ${this.getPatientLastName(this.currentSpec, patient.name)}`,
      mrn: this.getPatientMRN(this.currentSpec, patient.identifier),
      gender: this.getPatientGender(this.currentSpec, patient),
      birthDate: this.getPatientBirthDate(this.currentSpec, patient)
    };
  };

  getPatientFirstName = (spec, name = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2:
        return name[0] && name[0].given ? name[0].given.join(" ") : "anonymous";
      default:
        return null;
    }
  };

  getPatientLastName = (spec, name = []) => {
    switch (spec) {
      case this.stu3: {
        const lastName = name.find(name => name.use === "official");
        return lastName ? lastName.family : null;
      }
      case this.dstu2: {
        return name[0] ? name[0].family.join(" ") : "anonymous";
      }
      default:
        return null;
    }
  };

  getPatientMRN = (spec, identifier = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2: {
        const hasMRN = identifier[0];
        const value = hasMRN && hasMRN.value;
        return value;
      }
      default:
        return null;
    }
  };

  getPatientGender = (spec, patient) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2: {
        return patient.gender;
      }
      default:
        return null;
    }
  };

  getPatientBirthDate = (spec, patient) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2:
        return patient.birthDate;
      default:
        return null;
    }
  };

  //*********************************************************
  // Practitioner data access methods
  //*********************************************************
  getPractitioner = practitioner => {
    const parsedPractitioner = JSON.parse(practitioner);
    return {
      id: parsedPractitioner.id,
      name: "parsedPractitioner.name[0].text",
      // name: `${this.getPractitionerFirstName(this.currentSpec, parsedPractitioner.name)} ${this.getPractitionerLastName(this.currentSpec, parsedPractitioner.name)}`,
      npi: parsedPractitioner.identifier[0].value
      // npi: this.getPractitionerNPI(this.currentSpec, parsedPractitioner.identifier)
    };
  };

  getPractitionerFirstName = (spec, name = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2:
      default:
        return null;
    }
  };

  getPractitionerLastName = (spec, name = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2:
      default:
        return null;
    }
  };

  getPractitionerNPI = (spec, identifier = []) => {
    switch (spec) {
      case this.stu3:
      case this.dstu2:
      default:
        return null;
    }
  };

  //*********************************************************
  // Observation data access
  //*********************************************************
  getObservations = ({ category, results }) => ({
    category,
    observations: results.map(result => {
      switch (category) {
        case "social-history":
          return {
            field: result.code.text,
            value:
              result.valueCodeableConcept && result.valueCodeableConcept.text
          };
        default:
          // vital-signs, laboratory, exam
          return {
            field: result.code.text,
            value: result.valueQuantity && result.valueQuantity.value,
            unit: result.valueQuantity && result.valueQuantity.unit,
            date: result.effectiveDateTime
          };
      }
    })
  });

  //*********************************************************
  // Fetch methods
  //*********************************************************
  fetchEncounterData(dispatch, ecToken, patientResponse, orgSlug) {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(async smart => {
          const identifierResponse = await this.fetchIdentifierData(
            smart,
            patientResponse,
            ecToken,
            orgSlug
          );
          const { data } = identifierResponse;
          console.log("encounter req payload: ", data);

          var STU3id = data.Identifiers.find(identifier => {
            return identifier.IDType === "FHIR STU3";
          });

          var fhir = nativeFhir({
            baseUrl: smart.server.serviceUrl.replace("DSTU2", "STU3"),
            credentials: "same-origin",
            auth: { bearer: ecToken }
          });

          // get all of the encounters for this patient
          fhir.search({ type: "Encounter", patient: STU3id.ID }).then(
            encounters => {
              // get a specific encounter
              fhir
                .read({
                  type: "Encounter",
                  id: encounters.data.entry[0].resource.id
                })
                .then(
                  encounter => {
                    // need to do something better here
                    resolve({
                      encounter: encounter.data,
                      identifiers: data.Identifiers
                    });
                  },
                  err => {
                    reject(err);
                    logSentryError(err);
                  }
                ); // looks like a 400 error code with a description comes back when an encounter is not found
            },
            err => {
              reject(err);
            }
          );
        })
        .catch(err => logSentryError(err));
    });
  }

  fetchPatientData() {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          smart.patient.read().then(
            patient => {
              resolve(patient);
            },
            err => {
              reject(err);
              logSentryError(err);
            }
          );
        })
        .catch(err => logSentryError(err));
    });
  }

  fetchIdentifierData(smart, patientResponse, ecToken, orgSlug) {
    return new Promise(
      (resolve, reject) => {
        const config = getConfigByOrgSlug(orgSlug);

        return axios({
          method: "POST",
          url: FHIR_EPIC_PROXY_URL + FHIR_EPIC_PATIENT_IDENTIFIER_ENDPOINT_PATH,
          headers: { Authorization: "Bearer " + ecToken },
          data: {
            PatientID: patientResponse.id,
            PatientIDType: "FHIR",
            UserID: config.external_userID,
            UserIDType: "External"
          }
        })
          .then(axiosResult => {
            resolve(axiosResult);
          })
          .catch(err => {
            logSentryError(err);
            reject(err);
          });
      },
      err => {
        reject(err);
        logSentryError(err);
      }
    );
  }

  fetchUserFhirData(ecToken) {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData().then(
        smart => {
          return axios({
            method: "POST",
            url: smart.state.provider.oauth2.token_uri.replace(
              "/token",
              "/introspect"
            ),
            headers: { Authorization: "Bearer " + ecToken },
            data: {
              token: ecToken
            }
          })
            .then(introspectResult => {
              const practitionerUrl = introspectResult.data.sub;
              const practitionerId = practitionerUrl.slice(
                practitionerUrl.indexOf("Practitioner") + 13
              );
              let fhir = nativeFhir({
                baseUrl: practitionerUrl.slice(
                  0,
                  practitionerUrl.indexOf("Practitioner") - 1
                ),
                credentials: "same-origin",
                auth: { bearer: ecToken }
              });
              fhir
                .read({ type: "Practitioner", id: practitionerId })
                .then(practitionerResponse => {
                  return resolve(practitionerResponse);
                });
            })
            .catch(err => {
              logSentryError(err);
              reject(err);
            });
        },
        err => {
          reject(err);
          logSentryError(err);
        }
      );
    });
  }

  fetchPatientObservations(categoryType) {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          smart.patient.api
            .fetchAll({ type: `Observation?category=${categoryType}` })
            .then(
              results => {
                resolve(results);
              },
              err => {
                reject(err);
                logSentryError(err);
              }
            );
        })
        .catch(err => logSentryError(err));
    });
  }

  //*********************************************************
  // Document posting methods
  //*********************************************************
  makeEhrHtmlString = ({ ehrs, diagnoses, calcs, providerNote, reasons }) => {
    if (
      ehrs.length > 0 ||
      diagnoses.length > 0 ||
      calcs.length > 0 ||
      !isEmpty(reasons)
    ) {
      const finalEhrMarkup = Object.keys(ehrStringObject(ehrs)).map(
        ehrCategory => {
          return `<div><h3>${ehrCategory}</h3>
          ${ehrStringMarkup(ehrs)[ehrCategory].join("")}
          </div>`;
        }
      );
      const calcInputStrings = calcs =>
        calcs.length > 0 &&
        calcs.map(selectedCalc =>
          inputGroupStringsMarkup(selectedCalc).join("")
        );
      const calcStringMarkup =
        calcInputStrings(calcs).length > 0
          ? calcInputStrings(calcs).join("<br />")
          : "";
      const providerNoteMarkup = `${
        providerNote
          ? `<div><h2>Provider Comment: </h2><p>${providerNote}</p></div>`
          : ""
      } `;
      const diagnosisMarkup =
        diagnosesTitleArray(diagnoses).length > 0
          ? `<div><h2>${diagnosesString}</h2> <ul>${diagnosesTitleArray(
              diagnoses
            )
              .map(title => `<li>${title}</li>`)
              .join("")}</ul></div>`
          : "";

      const reasonsMarkup = !isEmpty(reasons)
        ? `<div><h3>Justification</h3><ul>
      ${R.values(reasons)
        .map(reason => `<li>${reason.text}</li>`)
        .join("")}</ul></div>`
        : "";

      return `${providerNoteMarkup} ${diagnosisMarkup} ${finalEhrMarkup.join(
        ""
      )} ${calcStringMarkup} ${reasonsMarkup}`;
    } else {
      return " ";
    }
  };

  wrapEhrHtmlString = ehrString => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"
        "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
     <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
     <head>
       <title>CDS Summary</title>
       <meta http-equiv="Content-Type" content="application/xhtml+xml; charset=UTF-8" />
       <meta http-equiv="Content-Style-Type" content="text/css" />
     </head>
     <body>
      <div>
        <h1>Evidence Care -- CDS Summary</h1>
        ${ehrString}
      </div>
     </body>
    </html>`;
  };

  makeEhrString = ({
    ehrs,
    diagnoses,
    calcs,
    reasons,
    providerNote,
    mcgItems
  }) => {
    if (
      ehrs.length > 0 ||
      diagnoses.length > 0 ||
      calcs.length > 0 ||
      !isEmpty(reasons)
    ) {
      // generate text from ehr items
      const finalEhrString = Object.keys(ehrStringObject(ehrs)).map(
        ehrCategory =>
          `${decode(ehrCategory)}\n${decode(
            ehrStringObject(ehrs)[ehrCategory].join("")
          )}`
      );

      // generate text calc strings
      const calcInputStrings = (calcs = []) =>
        calcs.map(selectedCalc => {
          return inputGroupStrings(selectedCalc);
        });
      const formattedCalcInputStrings = R.flatten(calcInputStrings(calcs)).join(
        ""
      );

      // generate reasons for deviation to ehr string
      const reasonsForDeviation = !isEmpty(reasons)
        ? `Justification\n\nThe following justification was used to make a decision in the care of this patient:\n${R.values(
            reasons
          )
            .map(reason => reason.text)
            .join("")}`
        : "";

      const providerNoteString = `${
        providerNote ? `Provider Comment: ${providerNote}\n\n ` : ""
      }`;

      const diagnosisString =
        diagnosesTitleArray(diagnoses).length > 0
          ? `${diagnosesString} \n -${diagnosesTitleArray(diagnoses).join(
              "\n-"
            )}\n\n `
          : "";

      const mcgItemString = mcgItems.items
        ? `Admission Criteria \n\n${mcgItems.summaryText} \n\n${
            mcgItems.subtitle
          } \n\n${mcgItems.indication} \n\n${mcgItems.items
            .map(item =>
              item.text.startsWith("-") ? item.text : `- ${item.text}`
            )
            .join("\n")} \n\n${mcgItems.copyright}`
        : "";

      return `${decode(providerNoteString)}${decode(diagnosisString)}${decode(
        summaryString
      )}\n\n${decode(finalEhrString.join("\n"))}${
        formattedCalcInputStrings ? decode(formattedCalcInputStrings) : ""
      }\n\n${decode(reasonsForDeviation)}\n\n${decode(mcgItemString)}`;
    } else {
      return "";
    }
  };

  wrapPatientEdHtmlString = (patientEdHtmlString, pe_description) => {
    const markup = `<?xml version="1.0" encoding="utf-8"?>
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"
          "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
        <head>
          <title>${pe_description}</title>
          <meta http-equiv="Content-Type" content="application/xhtml+xml; charset=UTF-8" />
          <meta http-equiv="Content-Style-Type" content="text/css" />
        </head>
        <body>
          ${patientEdHtmlString}
        </body>
      </html>`;

    return markup;
  };

  postEHR = (entry, callback, errback, patientIdentifiers, ecToken) => {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData()
        .then(smart => {
          var fhir = nativeFhir({
            baseUrl: smart.server.serviceUrl.replace("DSTU2", "STU3"),
            credentials: "same-origin",
            auth: { bearer: ecToken }
          });

          fhir.create(entry).then(
            // success or error never fires this first param.
            // (data) => console.log({ data }),
            data => {
              // success and error both fire this second param function, and since it is a create, I'm expecting a 201. everything else could be an error
              if (data.status === 201) {
                callback();
              } else {
                errback(
                  {
                    status: data.status,
                    statusText: data.statusText,
                    responseText: data.responseText,
                    headers: data.getAllResponseHeaders(),
                    body: data
                  },
                  entry
                );
              }
            },
            err => {
              errback({}, entry);
              logSentryError(err);
            }
          );
        })
        .catch(err => {
          console.error(err);
        });
    });
  };

  buildDocumentReference = ({
    user,
    patient,
    loinc,
    description,
    encodedHTML,
    plainText,
    patientIdentifiers
  }) => {
    // const now = new Date().toISOString()
    var STU3id = patientIdentifiers.find(identifier => {
      return identifier.IDType === "FHIR STU3";
    });

    const patientId = STU3id ? STU3id.ID : patient.id;

    return {
      resourceType: "DocumentReference",
      subject: {
        reference: `${patientId}`,
        display: `${patient.name}`
      },
      type: {
        coding: [
          {
            system: "http://loinc.org",
            code: `${loinc}`
          }
        ]
      },
      status: "current",
      description: `${description}`,
      content: [
        {
          attachment: {
            contentType: "text/plain",
            data: `${"ttt"}`
          }
        }
      ],
      context: {
        encounter: {
          reference: `${patient.encounter.id}`
        }
      }
    };
  };

  //*********************************************************
  // CDS methods
  //*********************************************************
  getCDSAppContext = () => {
    return new Promise((resolve, reject) => {
      this.instantiateSmartData().then(smart => {
        resolve();
      });
    });
  };

  sendWebMessage = message => {
    window.parent.postMessage(message, "*");
    return null;
  };

  buildWebMessage = (
    context,
    messageId,
    messageType,
    consultationCode,
    AUCMet,
    AUCApplicable,
    AUCCriteria,
    practitionerName,
    practitionerNPI
  ) => {
    return null;
  };

  translateAUCCriteria = AUCCriteria => {
    return "";
  };

  //*********************************************************
  // Orders methods
  //*********************************************************
  ordersFromOrderMap = (orders, meds) => {
    const orderStrings =
      orders && orders.length > 0
        ? orders.map(order => {
            // order format { OrderKey: "LAB0129" }
            return order.data && order.data.order_key
              ? { OrderKey: `${order.data.order_key}` }
              : {};
          })
        : [];
    const medStrings =
      meds && meds.length > 0
        ? meds.map(med => {
            // order format { OrderKey: "LAB0129" }
            return med.data && med.data.order_key
              ? { OrderKey: `${med.data.order_key}` }
              : {};
          })
        : [];

    return medStrings.concat(orderStrings);
  };

  buildTabList = (orders, meds) => {
    return null;
  };

  buildOrder = ({
    token,
    patientId,
    encounterId,
    selectedOrders,
    selectedMeds
  }) => {
    const orderString = this.ordersFromOrderMap(selectedOrders, selectedMeds);
    const message = {
      token: token,
      action: "Epic.Clinical.Informatics.Web.PostOrder",
      args: orderString[0]
    };

    console.log(message);
    return { type: "webMessage", data: message };
  };

  initiateHandshake = handshakeListener => {
    console.log("handshake sent...");
    window.addEventListener("message", handshakeListener, false);
    window.parent.postMessage(
      {
        action: "Epic.Clinical.Informatics.Web.InitiateHandshake"
      },
      "*"
    );

    //testing
    //window.postMessage(
    //  {
    //    token: "Test1234"
    //  },
    //  "*"
    //);
  };

  cleanupHandshake = handshakeListener => {
    window.removeEventListener("message", handshakeListener);
  };

  processHandshake = event => {
    console.log("handshakeListener invoked : ", event);
    let epicToken = null;
    for (var type in event.data) {
      var payload = event.data[type];
      switch (type) {
        case "token":
          // this is the token passed down by Epic
          // which every post message needs to include
          // return to??
          epicToken = payload;
          break;
        default:
          // if new properties get implemented which are not
          // handled above, you end up here
          break;
      }
    }
    return epicToken;
  };

  initiateOrder = (orderObject, orderListener) => {
    //send the order to epic here
    console.log("order sent...", orderObject);
    window.addEventListener("message", orderListener, false);

    //testing
    //window.postMessage({ actionExecuted: true }, "*");

    //epic
    window.parent.postMessage(orderObject, "*");
  };

  processOrderResponse = event => {
    console.log("order response received: ", event);

    let epicToken = null;
    for (var type in event.data) {
      var payload = event.data[type];
      switch (type) {
        case "token":
          // this is the token passed down by Epic
          // which every post message needs to include
          epicToken = payload;
          break;
        case "error":
          // payload is an error string
          break;
        case "features":
          // payload is a list of features (or actions)
          for (var feature in payload) {
            // feature is a string representing a single feature
          }
          break;
        case "state":
          // payload is some state which you have saved
          // before AGL hibernated
          break;
        case "actionExecuted":
          // payload is a boolean set to "true" if the action
          // completed, "false" otherwise
          break;
        case "errorCodes":
          // payload is an array of all errors which might have been // encountered
          break;
        default:
          // if new properties get implemented which are not
          // handled above, you end up here
          break;
      }
    }
  };

  cleanupOrderResponse = orderListener => {
    window.removeEventListener("message", orderListener);
  };
}
