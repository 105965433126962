import { ADD_ASSOCIATIONS } from "./constants";

// ------------------------------------
// Reducer
// ------------------------------------

const ACTION_HANDLERS = {
  [ADD_ASSOCIATIONS]: (state, action) => ({
    ...state,
    ...action.payload.reduce((acc, cur) => {
      acc[cur.slug] = cur;
      return acc;
    }, {})
  })
};

// all visible user associations indexed by slug
const initialState = {};

export default function associationsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
