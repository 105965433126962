import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import LoginComponent from "components/auth/login/Login";
import Welcome from "components/auth/signup/Welcome";
import { selectPartnerTemplate } from "constants/partners";
import { showLockSignup } from "store/auth/actions";
import AuthLayout from "layouts/AuthLayout/AuthLayout";

export class Signup extends React.PureComponent {
  componentDidMount() {
    const { code, showLockSignup, email } = this.props;
    const { welcomeText, cloudinaryUrl } = selectPartnerTemplate(code) || {};
    if (!welcomeText || !cloudinaryUrl) {
      showLockSignup({ code, email });
    }
  }

  render() {
    const { email, code } = this.props;
    const { welcomeText, cloudinaryUrl, signupText } =
      selectPartnerTemplate(code) || {};

    return (
      <AuthLayout>
        {welcomeText && cloudinaryUrl ? (
          <Welcome
            email={email}
            partner={code}
            welcomeText={welcomeText}
            cloudinaryUrl={cloudinaryUrl}
            signupText={signupText}
          />
        ) : (
          <LoginComponent />
        )}
      </AuthLayout>
    );
  }
}

Signup.propTypes = {
  email: PropTypes.string,
  code: PropTypes.string,
  showLockSignup: PropTypes.func
};

const mapDispatchToProps = {
  showLockSignup
};

export default connect(
  null,
  mapDispatchToProps
)(Signup);
