import axios from "axios";
import { success, error } from "react-notification-system-redux";

import { patchUser } from "store/user/actions";
import { loadBootstrapData } from "store/bootstrap";
import { updateProfileApi } from "lib/ec/profile";
import {
  passwordResetApi,
  logoutApi,
  updateAccountApi,
  fetchCMEAccreditationApi,
  fetchCMEDisclosureApi,
  fetchCMENewDisclosureApi,
  createCMEAgreementApi,
  createCMETestApi,
  updateCMETestApi,
  fetchCMETotalApi,
  createMembershipApi,
  getCMETestsApi,
  fetchSubscriptionsApi,
  fetchSubscriptionApi,
  updateSubscriptionApi,
  deleteMembershipApi,
  fetchProductsApi,
  generateEmailVerificationApi
} from "lib/ec/account";
import {
  EVENT_PWD_RESET_REQUEST,
  EVENT_PWD_RESET_REQUEST_FAILURE,
  EVENT_FEATURE_CME_DISCLOSURE_AGREE,
  EVENT_FEATURE_CME_TEST_SUBMIT
} from "constants/broadcastEventTypes";
import { showLoader, hideLoader } from "store/ui";
import { showModal, hideModal } from "store/modal/actions";
import { history as browserHistory } from "myHistory";
import {
  printSummary,
  transformAccreditations
} from "components/account/CME/cme-pdf";
import { extendProducts } from "helpers/subscriptions";
import { pipelineRequest } from "store/pipeline";

export const SET_CME = "SET_CME";
export const CLEAR_CME_TEST = "CLEAR_CME_TEST";
export const ACCOUNT_SET_CME_TEST_HISTORY = "ACCOUNT_SET_CME_TEST_HISTORY";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const CLEAR_SUBSCRIPTIONS = "CLEAR_SUBSCRIPTIONS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const ACCOUNT_SET_PROP = "ACCOUNT_SET_PROP";
export const ACCOUNT_FETCH_ECTOKEN = "ACCOUNT_FETCH_ECTOKEN";
export const ACCOUNT_SET_ECTOKEN = "ACCOUNT_SET_ECTOKEN";
export const ACCOUNT_CLEAR_ECTOKEN = "ACCOUNT_CLEAR_ECTOKEN";

export const setCME = payload => {
  return {
    type: SET_CME,
    payload
  };
};
export const accountClearEcToken = () => {
  return {
    type: ACCOUNT_CLEAR_ECTOKEN
  };
};
export const handleCMEData = (cmeData = []) => {
  const currentYear = new Date().getFullYear().toString();
  const currentYearCME = cmeData.filter(c => c.year === currentYear);
  return currentYearCME;
};

export const createCMEAgreement = () => (dispatch, getState) => {
  const user = getState().user;
  return createCMEAgreementApi(user.token).then(data => {
    dispatch(fetchCMESummary());
    dispatch(hideModal());
    dispatch(
      pipelineRequest({
        action: EVENT_FEATURE_CME_DISCLOSURE_AGREE
      })
    );
  });
};

export const fetchCMESummary = () => (dispatch, getState) => {
  const today = new Date();
  const year = today.getFullYear();
  return fetchCMEAccreditationApi(year).then(data => {
    dispatch(setCME({ summary: data.data.results }));
    dispatch(hideLoader());
  });
};

export const fetchCMEDisclosure = () => (dispatch, getState) => {
  return fetchCMEDisclosureApi(getState().user.token).then(data => {
    dispatch(setCME({ disclosure: data.data }));
  });
};

export const fetchCMENewDisclosure = () => (dispatch, getState) => {
  dispatch(showLoader());
  return fetchCMENewDisclosureApi(getState().user.token).then(data => {
    if (data.status === 204) {
      dispatch(fetchCMESummary());
    } else if (data.status === 200) {
      dispatch(hideLoader());
      dispatch(setCME({ disclosure: data.data }));
      dispatch(showModal("DISCLOSURE"));
    }
  });
};

export const createCMETest = () => dispatch => {
  const today = new Date();
  const year = today.getFullYear();
  return createCMETestApi(year).then(data => {
    dispatch(setCME({ test: data.data }));
  });
};

export const clearCMETest = () => {
  return {
    type: CLEAR_CME_TEST
  };
};

export const submitCMETest = (id, results, degree = "MD") => dispatch => {
  return updateCMETestApi(id, results).then(({ data }) => {
    if (data.passed === true) {
      const { medSocieties, total } = transformAccreditations(
        data.accreditations
      );
      dispatch(
        printSummary({
          medSocieties,
          testId: id,
          total,
          year: data.year,
          degree
        })
      );
    }
    dispatch(hideModal());
    dispatch(
      pipelineRequest({
        action: EVENT_FEATURE_CME_TEST_SUBMIT,
        message: {
          test_id: id
        }
      })
    );
  });
};

export const fetchCMETests = ({ year, currentPage, pageSize = 10 }) => (
  dispatch,
  getState
) => {
  return getCMETestsApi({
    token: getState().user.token,
    year,
    currentPage,
    pageSize
  }).then(({ data }) => {
    dispatch({
      type: ACCOUNT_SET_CME_TEST_HISTORY,
      payload: {
        tests: data.results,
        count: data.count,
        year,
        currentPage,
        pageSize
      }
    });
  });
};

export const updateCMETotal = () => (dispatch, getState) => {
  return fetchCMETotalApi(getState().user.token).then(data => {
    dispatch(setCME({ total: data.data.total }));
  });
};

const cloudinaryUploadCB = (error, result, dispatch) => {
  if (error) {
    console.log(error);
  } else if (result) {
    const imageId = result[0].public_id;
    dispatch(showLoader());
    if (imageId) {
      // saving new image to backend
      updateProfileApi({ cloudinary_id: imageId }).then(({ data }) => {
        dispatch(patchUser(data));
        dispatch(hideLoader());
      });
    }
  }
};

export const openCloudinaryWidget = evt => {
  evt.preventDefault();
  return (dispatch, store) => {
    window.cloudinary.openUploadWidget(
      {
        cloud_name: "evidencecare",
        upload_preset: "djxgk5sj",
        tags: [CLOUDINARY_ENV]
      },
      (error, result) => cloudinaryUploadCB(error, result, dispatch, store)
    );
  };
};

export const openCloudinary = evt => {
  return dispatch => {
    dispatch(openCloudinaryWidget(evt));
  };
};

export const passwordResetSuccess = user => {
  return (dispatch, getState) => {
    dispatch(
      success({
        message: "A password reset has been emailed to you",
        autoDismiss: 3
      })
    );
    dispatch(
      pipelineRequest({
        action: EVENT_PWD_RESET_REQUEST,
        message: {
          reason: "Change requested from profile"
        }
      })
    );
  };
};

export const sendPasswordChange = user => {
  return (dispatch, getState) => {
    passwordResetApi({ email: user.email })
      .then(() => {
        dispatch(passwordResetSuccess(user));
      })
      .catch(err => {
        dispatch(
          pipelineRequest({
            action: EVENT_PWD_RESET_REQUEST_FAILURE,
            message: {
              error: JSON.stringify({
                status: err.status,
                text: err.responseText
              })
            }
          })
        );
      });
  };
};

export const passwordReset = evt => {
  evt.preventDefault();
  return (dispatch, getState) => {
    dispatch(sendPasswordChange(getState().user.user));
  };
};

export const logout = e => {
  e.preventDefault();
  return (dispatch, getState) => {
    logoutApi(true).then(() => {
      browserHistory.push("/logout");
    });
  };
};

export const saveAccountDetails = (userData, profileData) => {
  return () => {
    return axios.all([
      updateAccountApi(userData),
      updateProfileApi(profileData)
    ]);
  };
};

export const createMembership = ({ assId, code }) => {
  return dispatch => {
    const body = { association: assId };
    if (code) {
      body.value = code;
    }
    return (
      createMembershipApi(body)
        // need to refresh the bootstrap data as new feature flags could be added
        .then(() => {
          dispatch(loadBootstrapData());
        })
        .catch(err => {
          const data = err && err.response && err.response.data;
          const notificationOpts = {
            uid: "member-join-fail",
            title: "Oops!",
            position: "tr",
            message:
              "An error occured while trying to join. We have been notified and are looking in to this issue."
          };
          if (
            err.response.status === 400 &&
            data.non_field_errors &&
            data.non_field_errors.length
          ) {
            if (data.non_field_errors.includes("verification failed")) {
              notificationOpts.message =
                "You have entered an invalid join code. Please try again";
            } else {
              notificationOpts.message = data.non_field_errors.join(", ");
            }
          }
          dispatch(error(notificationOpts));
        })
    );
  };
};

export const deleteMembership = ({ membUid, assName }) => {
  return dispatch => {
    return deleteMembershipApi(membUid)
      .then(() => {
        dispatch(loadBootstrapData());
      })
      .catch(err => {
        const deleteError = { err };
        const notificationOpts = {
          uid: "membership-delete-fail",
          title: `We're sorry`,
          postion: "tr",
          message: `We are unable to delete your ${assName} membership at this time. We have been notified and are looking into this issue.`
        };
        dispatch(error(notificationOpts));
        return deleteError;
      });
  };
};

export const setSubscription = subscription => {
  return {
    type: SET_SUBSCRIPTIONS,
    payload: subscription
  };
};

export const clearSubscription = subscription => {
  return {
    type: CLEAR_SUBSCRIPTIONS
  };
};

export const fetchSubscription = id => {
  return dispatch => {
    return fetchSubscriptionApi(id).then(({ data }) => {
      dispatch(setSubscription(data));
    });
  };
};

export const fetchSubscriptions = () => {
  return (dispatch, getState) => {
    dispatch(showLoader());
    const token = getState().user.token;
    fetchSubscriptionsApi(token).then(({ data }) => {
      if (data.length) {
        dispatch(setSubscription(data[0]));
      } else {
        dispatch(
          setSubscription({
            product: "core"
          })
        );
      }

      dispatch(hideLoader());
    });
  };
};

export const updateSubscription = ({ handle, id, action }) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SUBSCRIPTION
    });
    const token = getState().user.token;
    const body = {
      product_handle: handle,
      action
    };
    updateSubscriptionApi({ token, id, body }).then(() => {
      // the only options currently are delayed so the data doesn't need to be refreshed
      const notificationOpts = {
        uid: "subscription-upgrade-success",
        title: "Success!",
        message: "Your subscription will update at the next billing date"
      };
      dispatch(success(notificationOpts));
    });
  };
};

const prepProducts = products => {
  const productsWithFeatures = extendProducts(products);
  return productsWithFeatures;
};

export const fetchProducts = () => {
  return (dispatch, getState) => {
    dispatch(showLoader());
    fetchProductsApi(getState().user.token)
      .then(({ data }) => {
        dispatch({
          type: SET_PRODUCTS,
          payload: prepProducts(data)
        });
        dispatch(hideLoader());
      })
      .catch(e => {
        dispatch(hideLoader());
      });
  };
};

export const generateEmailVerification = () => {
  return (dispatch, getState) => {
    return generateEmailVerificationApi(getState().user.token);
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_CME]: (state, action) => {
    const cme = Object.assign({}, state.cme, action.payload);
    return Object.assign({}, state, { cme });
  },
  [CLEAR_CME_TEST]: (state, action) => {
    const cme = Object.assign({}, state.cme, { test: {} });
    return Object.assign({}, state, { cme });
  },
  [ACCOUNT_SET_CME_TEST_HISTORY]: (state, action) => {
    return Object.assign({}, state, { cmeTestHistory: action.payload });
  },
  [SET_SUBSCRIPTIONS]: (state, action) => {
    return Object.assign({}, state, { subscription: action.payload });
  },
  [CLEAR_SUBSCRIPTIONS]: (state, action) => {
    return Object.assign({}, state, { subscription: undefined });
  },
  [SET_PRODUCTS]: (state, action) => {
    return Object.assign({}, state, { products: action.payload });
  },
  [ACCOUNT_SET_PROP]: (state, action) => {
    const { val, prop } = action.payload;
    return Object.assign({}, state, { [prop]: val });
  },
  [ACCOUNT_FETCH_ECTOKEN]: (state, action) => {
    return Object.assign({}, state, {
      ecToken: { ...state.ecToken, isFetching: true }
    });
  },
  [ACCOUNT_SET_ECTOKEN]: (state, action) => {
    const { ecToken, values } = action.payload;
    return Object.assign({}, state, {
      ecToken: {
        token: ecToken,
        ...values,
        isFetching: false
      }
    });
  },
  [ACCOUNT_CLEAR_ECTOKEN]: (state, action) => {
    return Object.assign({}, state, { ecToken: ecTokenInitialState });
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const cmeTestHistoryInitialState = {
  tests: [],
  count: 0,
  currentPage: 1,
  year: new Date().getFullYear(),
  pageSize: 10
};
const ecTokenInitialState = {
  token: null,
  isFetching: false,
  values: {}
};

const initialState = {
  cmeTestHistory: cmeTestHistoryInitialState,
  cme: {},
  ecToken: ecTokenInitialState
};

export default function accountReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// ------------------------------------
// Selectors
// ------------------------------------

export function selectAccount(state) {
  return state.account;
}
