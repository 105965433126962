import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchAdmissionContext, fetchMcgGuidelineStatus } from "./mcgReducer";
import {
  getSelectedIndicationsEhr,
  getMcgEhrText,
  getSelectedGuideline
} from "./mcgSelectors";
import OutputItemSelectable from "./McgEhrOutputSelectable";
import { getSelectionDisplayNameById } from "selectors/outputSelectors";
import { mcgEhrText } from "./helpers";

class McgEhrDocumentation extends React.Component {
  componentDidMount() {
    const {
      selectedGuideline,
      guidelineWithStatus,
      fetchMcgGuidelineStatus,
      fetchAdmissionContext
    } = this.props;
    if (!guidelineWithStatus.length && selectedGuideline) {
      // fetch guidelines with session data
      fetchAdmissionContext();
      fetchMcgGuidelineStatus({
        hsim: selectedGuideline.hsim,
        indicationIds: selectedGuideline.selections
      });
    }
  }
  render() {
    const { ehrIndications, ehrText } = this.props;

    const {
      summaryText = "",
      title = "",
      subtitle = "",
      copyright = ""
    } = mcgEhrText(ehrText);
    return this.props.shouldShowMcgEhr ? (
      <React.Fragment>
        <div className="ehr_treatment-summary-text">{summaryText}</div>
        <div className="ehr_treatment-course-container">
          <div className="ehr_category-title">{title}</div>
          <div className="ehr_category-subtitle">{subtitle}</div>
          <p>{ehrIndications.text}</p>
          {ehrIndications.indications.map(indication =>
            indication.text ? (
              <OutputItemSelectable
                key={indication.id}
                item={indication}
                itemText={indication.text}
              />
            ) : null
          )}
          <p className="mcg-copyright">{copyright}</p>
        </div>
      </React.Fragment>
    ) : null;
  }
}

McgEhrDocumentation.propTypes = {
  shouldShowMcgEhr: PropTypes.bool,
  selectedGuideline: PropTypes.object,
  ehrIndications: PropTypes.object,
  ehrText: PropTypes.object,
  guidelineWithStatus: PropTypes.array,
  fetchMcgGuidelineStatus: PropTypes.func,
  fetchAdmissionContext: PropTypes.func
};

const mapStateToProps = state => {
  const selectedGuideline = getSelectedGuideline(state);
  return {
    shouldShowMcgEhr: selectedGuideline.isSubmitted,
    guidelineWithStatus: state.mcg.guidelineWithStatus,
    selectedGuideline,
    ehrIndications: getSelectedIndicationsEhr(state),
    selectionName:
      selectedGuideline.selectedSelection &&
      getSelectionDisplayNameById(state, selectedGuideline.selectedSelection),
    ehrText: getMcgEhrText(state)
  };
};

const mapDispatchToProps = {
  fetchMcgGuidelineStatus,
  fetchAdmissionContext
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McgEhrDocumentation);
