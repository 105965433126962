import R from "ramda";
import { mapKeys } from "lodash";

export const alphaSortArrayOfObjectsByProp = ({ arrayToSort, prop }) => {
  return R.sort((a, b) => {
    return a[prop] ? a[prop].localeCompare(b[prop]) : false;
  }, arrayToSort || []);
};

/**
 * formats 11-digit phone number
 * eg +19176576578 => (917) 657-6578
 */
export const formatPhoneNumber = ({ phoneNumberString }) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return null;
};

export const makeObjKeysLowerCase = obj => {
  const lowercaseKey = (val, key) => key.toLowerCase();
  return mapKeys(obj, lowercaseKey);
};

export const removeSnakeCaseAndCapitalize = str => {
  return str
    ? str
        .split("-")
        .map(st => st.charAt(0).toUpperCase() + st.substring(1))
        .join(" ")
    : "";
};
