import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Comment } from "semantic-ui-react";

import { profileImageNoRadiusUrl } from "helpers/urls/cloudinary";
import { openCloudinary } from "store/account";
import { showLoader, hideLoader } from "store/ui";
import { loadCloudinaryWidgetScript } from "lib/externalscripts";
import { selectAvatarCloudinaryID } from "store/user/selectors";

const defaultColors = [
  "#d73d32",
  "#7e3794",
  "#4285f4",
  "#67ae3f",
  "#d61a7f",
  "#ff4080"
];
const colors = [
  "#bd4030",
  "#D1534F",
  "#F08264",
  "#FEE0A1",
  "#6cb77e",
  "#377A71",
  "#519C90",
  "#4181A1",
  "#1F274D",
  "#554475",
  "#847364",
  "#62636B"
];

function _stringAsciiPRNG(value, m) {
  const charCodes = [...value].map(letter => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  for (let i = 0; i < len; i++) {
    random = (a * random + c) % m;
  }

  return random;
}
function defaultInitials(name, maxInitials) {
  return (
    name &&
    name
      .split(/\s/)
      .map(part => part.substring(0, 1).toUpperCase())
      .filter(v => !!v)
      .slice(0, maxInitials)
      .join("")
  );
}
function getRandomColor(value, colors = defaultColors) {
  // if no value is passed, always return transparent color otherwise
  // a rerender would show a new color which would will
  // give strange effects when an interface is loading
  // and gets rerendered a few consequent times
  if (!value) {
    return "transparent";
  }

  // value based random color index
  // the reason we don't just use a random number is to make sure that
  // a certain value will always get the same color assigned given
  // a fixed set of colors
  const colorIndex = _stringAsciiPRNG(value, colors.length);
  return colors[colorIndex];
}

class Avatar extends Component {
  state = {
    showSuccess: false,
    data: null,
    comments: [],
    newComment: ""
  };

  getColor = displayName => {
    return getRandomColor(displayName, colors);
  };
  editProfileImage = e => {
    console.log("showing loader then running load cloudinary");
    this.props.showLoader();
    loadCloudinaryWidgetScript().then(resp => {
      this.props.openCloudinary(e);
      this.props.hideLoader();
    });
  };
  render() {
    const { author, ownAlias, ownCloudinaryId } = this.props;
    const displayName = !author
      ? "User"
      : author.firstName || author.lastName
      ? `${author.firstName} ${author.lastName}`
      : author.email;
    const profileIamge =
      author && author.alias === ownAlias
        ? ownCloudinaryId
        : author && author.cloudinaryId;
    return profileIamge ? (
      <Comment.Avatar
        onClick={this.editProfileImage}
        src={profileImageNoRadiusUrl(profileIamge)}
      />
    ) : (
      <div
        className="comment_avatar"
        onClick={this.editProfileImage}
        style={{ backgroundColor: this.getColor(displayName) }}
      >
        <span className="comment_avatar-text">
          {defaultInitials(displayName, 2)}
        </span>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    protocolId: state.session.activeProtocolId,
    sectionId:
      state.session.entities.protocols[state.session.activeProtocolId]
        .activeSectionId,
    ownCloudinaryId: selectAvatarCloudinaryID(state),
    ownAlias: state.user.alias
  };
};
const mapDispatchToProps = {
  openCloudinary,
  showLoader,
  hideLoader
};
Avatar.propTypes = {
  medProfileId: PropTypes.number,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  author: PropTypes.object,
  ownAlias: PropTypes.string,
  ownCloudinaryId: PropTypes.string,
  openCloudinary: PropTypes.func
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Avatar);
