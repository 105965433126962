import { mergeDeepRight } from "ramda";

import {
  CLEAR_HC_DATA,
  SET_HC_ACTIVE_ARTICLE,
  SET_HC_ACTIVE_SECTION,
  UPDATE_HC_ARTICLES,
  UPDATE_HC_LANDINGPAGE,
  UPDATE_HC_SECTIONS,
  UPDATE_HC_SUBSECTIONS
} from "./constants";

const ACTION_HANDLERS = {
  [UPDATE_HC_ARTICLES]: (state, { payload }) => ({
    ...state,
    articles: mergeDeepRight(state.articles, payload)
  }),
  [UPDATE_HC_SECTIONS]: (state, { payload }) => ({
    ...state,
    sections: mergeDeepRight(state.sections, payload)
  }),
  [UPDATE_HC_SUBSECTIONS]: (state, { payload }) => ({
    ...state,
    subsections: mergeDeepRight(state.subsections, payload)
  }),
  [UPDATE_HC_LANDINGPAGE]: (state, { payload }) => ({
    ...state,
    landingPage: payload
  }),
  [SET_HC_ACTIVE_SECTION]: (state, { payload }) => ({
    ...state,
    activeSectionId: payload
  }),
  [SET_HC_ACTIVE_ARTICLE]: (state, { payload }) => ({
    ...state,
    activeArticleId: payload
  }),
  [CLEAR_HC_DATA]: state => ({
    ...state,
    articles: {},
    sections: {},
    subsections: {},
    landingPage: null
  })
};

const initialState = {
  // article data indexed by article ID
  articles: {},

  // section data indexed by section ID
  sections: {},

  // subsection data indexed by subsection ID
  subsections: {},

  // landing page data
  landingPage: null,

  // the currently active section (for breadcrumb use)
  activeSectionId: null,

  // the currently active article (for breadcrumb use)
  activeArticleId: null
};

export default function helpCenterReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
