import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { submitCalc, setSelected, launchNextSection } from "store/session";
import { showProtocolLaunchButton } from "store/protocol";
import {
  makeCheckIfAllSelectedInputs,
  makeGetTotal
} from "selectors/calcInputSelectors";
import classNames from "classnames";
import { logSentryError } from "helpers/logging/error";
import { EVENT_FEATURE_CALC_SUBMIT } from "constants/broadcastEventTypes";
import { pipelineRequest } from "store/pipeline";
import { selectCDSMode } from "store/ui/selectors";
import withWebMessage from "components/global/withWebMessage";

export class SubmitBar extends Component {
  calculateValueRange = ({ total }) => {
    const { valueRanges } = this.props;

    const selectedValueRanges = valueRanges.filter(
      vr => vr.minValue <= total && vr.maxValue >= total
    );
    return selectedValueRanges && selectedValueRanges[0];
  };
  submit = ({ selectedValueRange }) => {
    const {
      relatedSections,
      showProtocolLaunchButton,
      setActiveGroup,
      pipelineRequest,
      calcUsage,
      cdsMode
    } = this.props;
    return e => {
      e.preventDefault();
      pipelineRequest({
        action: EVENT_FEATURE_CALC_SUBMIT,
        message: {
          calculator_id: calcUsage.calculator.id
        }
      });
      selectedValueRange.output &&
        this.props.setSelected({ outputId: selectedValueRange.output.id });
      if (selectedValueRange.output.nextId) {
        /**
         * SCHEMA
         * supporting both schemas for by looking at props available
         * after all section versions are published selectedValueRange.output.next will not exist
         */
        const nextObj = selectedValueRange.output.next
          ? selectedValueRange.output.next
          : relatedSections[selectedValueRange.output.nextId];
        if (nextObj && nextObj.protocolId !== this.props.activeProtocolId) {
          setActiveGroup();
          showProtocolLaunchButton({
            sectionId: nextObj.id,
            protocol: nextObj.protocol
          });
        } else {
          this.props.launchNextSection({
            currentSectionId: this.props.currentSectionId,
            nextSectionId: selectedValueRange.output.nextId
          });
        }
      } else {
        const error = new Error(
          "Output in selected value range does not have nextId."
        );
        logSentryError(error);
      }
      if (__FHIR__ && cdsMode) {
        this.props.sendWebMessage();
      }
    };
  };
  render() {
    const {
      calcTotal,
      allInputsAreSelected,
      relatedSections,
      cdsMode,
      inputs
    } = this.props;
    const total = inputs ? calcTotal : 0;
    const selectedValueRange =
      total > -100 && this.calculateValueRange({ total });
    const isDisabled = !selectedValueRange || !allInputsAreSelected;
    const labelAndDisplayNameAreIdentical =
      selectedValueRange &&
      selectedValueRange.selection.label ===
        selectedValueRange.selection.displayName;
    const nextSection =
      selectedValueRange &&
      selectedValueRange.output &&
      relatedSections[selectedValueRange.output.nextId];
    const submitButtonClasses = classNames(
      "button_standard calculator_submit-section-button",
      {
        "calculator_submit-section-button--with-border": !isDisabled
      }
    );

    return (
      <div
        className={`calculator_submit-section calculator_submit-section${
          isDisabled ? "" : `--${selectedValueRange.severity}`
        }`}
      >
        <div className="calculator_submit-section-content">
          <div className="columns medium-6">
            {!isDisabled && (
              <h6 className="calculator_submit-section-selection-label">
                {selectedValueRange.selection.label}
              </h6>
            )}
            {(isDisabled && (
              <div className="calculator_required">
                Further Selections Required
              </div>
            )) ||
              (!labelAndDisplayNameAreIdentical && (
                <h5 className="calculator_submit-section-severity">
                  {selectedValueRange.selection.displayName}
                </h5>
              ))}
          </div>
          <div className="columns text-right align-middle">
            <div className="row">
              <div className="calculator_submit-section-right-column">
                {isDisabled && (
                  <p className="calculator_required--red">*Answer required</p>
                )}
                {!cdsMode && !isDisabled && nextSection && (
                  <div className="calculator_next-section-container">
                    <span className="calculator_next-section-label">
                      Next Section:
                    </span>
                    <p className="calculator_next-section-title">
                      {nextSection.group.title}
                    </p>
                  </div>
                )}
                {
                  <button
                    onClick={this.submit({ selectedValueRange })}
                    disabled={isDisabled}
                    className={submitButtonClasses}
                  >
                    {__FHIR__ && cdsMode ? "Send to EHR" : "Submit"}
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const checkIfAllInputsAreSelected = makeCheckIfAllSelectedInputs();
    const getCalcTotal = makeGetTotal();
    return {
      inputs:
        state.session.entities.usages[props.calcUsage.id] &&
        state.session.entities.usages[props.calcUsage.id].inputs,
      outputs: state.session.entities.outputs,
      activeProtocolId: state.session.activeProtocolId,
      allInputsAreSelected:
        state.session.entities.usages[props.calcUsage.id] &&
        checkIfAllInputsAreSelected(state, props),
      calcTotal:
        state.session.entities.usages[props.calcUsage.id] &&
        getCalcTotal(state, props),
      cdsMode: selectCDSMode(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  submitCalc,
  setSelected,
  launchNextSection,
  showProtocolLaunchButton,
  pipelineRequest
};

export default compose(
  connect(
    makeMapStateToProps,
    mapDispatchToProps
  ),
  withWebMessage
)(SubmitBar);

SubmitBar.propTypes = {
  inputs: PropTypes.object,
  calcUsage: PropTypes.object,
  valueRanges: PropTypes.array,
  setSelected: PropTypes.func,
  activeProtocolId: PropTypes.string,
  outputs: PropTypes.object,
  calcTotal: PropTypes.number,
  allInputsAreSelected: PropTypes.bool,
  cdsMode: PropTypes.bool,
  currentSectionId: PropTypes.string,
  relatedSections: PropTypes.object,
  launchNextSection: PropTypes.func,
  showProtocolLaunchButton: PropTypes.func,
  setActiveGroup: PropTypes.func,
  pipelineRequest: PropTypes.func,
  sendWebMessage: PropTypes.func
};
