import { alphaSortArrayOfObjectsByProp } from "helpers/util";

// sorting associations alphabetically with private associations preceeding public
export const sortAssociations = associations => {
  return alphaSortArrayOfObjectsByProp({
    arrayToSort: associations,
    prop: "name"
  }).reduce(
    (associationsObj, ass) => {
      ass.public === true
        ? associationsObj.pub.push(ass)
        : associationsObj.priv.push(ass);
      return associationsObj;
    },
    {
      pub: [],
      priv: []
    }
  );
};
