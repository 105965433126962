import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "./Avatar";
import Moment from "moment";

import { Comment } from "semantic-ui-react";

class Comments extends Component {
  render() {
    const { author, createdAt, markdown } = this.props;
    const displayName = !author
      ? "User"
      : author.firstName || author.lastName
      ? `${author.firstName} ${author.lastName}`
      : author.email;

    return (
      <Comment className="comment reply">
        <Avatar author={author} />
        <Comment.Content>
          <Comment.Author as="a">{displayName}</Comment.Author>
          <Comment.Metadata>
            <div>{Moment(createdAt).fromNow()}</div>
          </Comment.Metadata>
          <Comment.Text>{markdown}</Comment.Text>
          <Comment.Actions></Comment.Actions>
        </Comment.Content>
      </Comment>
    );
  }
}
const mapStateToProps = state => {
  return {
    protocolId: state.session.activeProtocolId,
    sectionId:
      state.session.entities.protocols[state.session.activeProtocolId]
        .activeSectionId,
    outputs: state.session.entities.outputs,
    email: state.user.email,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    alias: state.user.alias,
    userId: state.user.user_uid,
    ecToken: state.account.ecToken.token,
    cloudinaryId: state.user.cloudinary_id
  };
};

Comments.propTypes = {
  createdAt: PropTypes.number,
  author: PropTypes.object,
  markdown: PropTypes.string
};
export default connect(mapStateToProps)(Comments);
