import { pick } from "ramda";

import { makeEcRequest, makeNonAuthEcRequest } from "./base";

//
// Token Fetching and Refreshing
//

export const logoutApi = (allDevices = false) => {
  return makeEcRequest({
    method: "POST",
    location: "account/logout",
    body: { all_devices: allDevices }
  });
};

//
// User account
//

export const getFacilityList = () => {
  return makeEcRequest({
    method: "GET",
    location: "account/facility"
  });
};

export const passwordResetApi = data => {
  return makeNonAuthEcRequest({
    method: "POST",
    location: "account/password/reset",
    body: data
  });
};

export const updateAccountApi = data => {
  return makeEcRequest({
    method: "PATCH",
    location: "account/me",
    body: extractPatchAccountFields(data)
  });
};

export const fetchUserAccountApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "account/me"
  });
};

export const fetchCMEAccreditationApi = year => {
  return makeEcRequest({
    method: "GET",
    location: `cme/accreditation?hours_for_year=${year}&year=${year}&has_usage&page_size=100`
  });
};

export const fetchCMEDisclosureApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "cme/disclosure/current"
  });
};

export const fetchCMENewDisclosureApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "cme/disclosure/current?new"
  });
};

export const createCMEAgreementApi = () => {
  return makeEcRequest({
    method: "POST",
    location: "cme/agreement"
  });
};

export const createCMETestApi = year => {
  return makeEcRequest({
    method: "POST",
    location: `cme/test`,
    body: { year }
  });
};

export const updateCMETestApi = (id, body) => {
  return makeEcRequest({
    method: "PATCH",
    location: `cme/test/${id}`,
    body
  });
};

export const getCMETestsApi = ({ year, currentPage = 1, pageSize = 10 }) => {
  return makeEcRequest({
    method: "GET",
    location: `cme/test?completed=True&year=${year}&page_size=${pageSize}&page=${currentPage}`
  });
};

export const getCMETestApi = testId => {
  return makeEcRequest({
    method: "GET",
    location: `cme/test/${testId}`
  });
};

export const getCMECertificateApi = id => {
  return makeEcRequest({
    method: "GET",
    location: `cme/test/${id}/certificate`
  });
};

export const fetchCMETotalApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "cme/total"
  });
};

export const fetchAssociationsApi = ({ pageSize = 100 } = {}) => {
  return makeEcRequest({
    method: "GET",
    location: `account/association?page_size=${pageSize}`
  });
};

export const createMembershipApi = data => {
  return makeEcRequest({
    method: "POST",
    location: "account/membership",
    body: data
  });
};

export const deleteMembershipApi = membUid => {
  return makeEcRequest({
    method: "DELETE",
    location: `account/membership/${membUid}`
  });
};

export const acceptInviteApi = ({ singleUseToken, inviteId, password }) => {
  return makeNonAuthEcRequest({
    method: "POST",
    location: "account/invite/accept",
    body: {
      uid: inviteId,
      password,
      token: singleUseToken
    }
  });
};

export const unsubscribeApi = ({ uid }) => {
  return makeNonAuthEcRequest({
    method: "POST",
    location: `account/invitation/${uid}/unsubscribe`
  });
};

export const fetchInviteApi = (token, uid) => {
  return makeNonAuthEcRequest({
    method: "GET",
    location: `account/invitation/${uid}?token=${token}`
  });
};

export const fetchSubscriptionApi = id => {
  return makeEcRequest({
    method: "GET",
    location: `account/subscription/${id}`
  });
};

export const fetchSubscriptionsApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "account/subscriptions"
  });
};

export const updateSubscriptionApi = ({ id, body }) => {
  return makeEcRequest({
    method: "PATCH",
    location: `account/subscription/${id}`,
    body
  });
};

export const fetchProductsApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "account/products"
  });
};

export const generateEmailVerificationApi = () => {
  return makeEcRequest({
    method: "POST",
    location: "account/me/verification-email"
  });
};

export const fetchEcTokenApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "account/token"
  });
};

/*************************************************
 * Utilities
 ***********************************************/

const PATCH_ACCOUNT_FIELDS = ["first_name", "last_name"];
const extractPatchAccountFields = data => pick(PATCH_ACCOUNT_FIELDS, data);
