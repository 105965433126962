import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCustomOutputItemSelectedState } from "store/session";
import dotProp from "dot-prop";
import classNames from "classnames";
import Checkbox from "components/ui/Checkbox";
import MarkdownRender from "utility/markdownRender";

class PatientEdFaq extends Component {
  state = {
    isOpen: false
  };
  handleSelect = ({ target }) => {
    const {
      faq,
      setCustomOutputItemSelectedState,
      customItemState
    } = this.props;
    const itemIsSelected = customItemState
      ? customItemState["isSelected"]
      : true;
    setCustomOutputItemSelectedState({
      outputId: "",
      outputItemId: faq.id,
      isSelected: !itemIsSelected
    });
  };
  setIsOpen = ({ isOpen }) => {
    return () => {
      this.setState({ isOpen });
    };
  };
  render() {
    const { faq, customItemState } = this.props;
    const { isOpen } = this.state;
    const isSelected = customItemState ? customItemState["isSelected"] : true;
    const indicatorClasses = classNames(
      "patient-ed-faqs_container",
      "select-output-item",
      {
        "select-output-item--selected": isSelected,
        "": !isSelected
      }
    );
    const faqQuestionClasses = classNames("patient-ed-faqs_question", {
      "patient-ed-faqs_question--isOpen": this.state.isOpen
    });
    return (
      <div className="">
        <div className={indicatorClasses}>
          <div className="patient-ed-faqs_qa-container">
            {this.state.isOpen && (
              <Checkbox
                checkboxClassName={"patient-ed-faqs_checkbox"}
                isSelected={isSelected}
                onChange={this.handleSelect}
              />
            )}
            <div
              onClick={this.setIsOpen({ isOpen: !isOpen })}
              className={faqQuestionClasses}
            >
              {faq.question}
            </div>
            {this.state.isOpen && (
              <div className="patient-ed-faqs_answer">
                <MarkdownRender
                  className="item-fe-markdown_markdown"
                  source={faq.answer}
                  escapeHtml
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PatientEdFaq.propTypes = {
  faq: PropTypes.object.isRequired,
  setCustomOutputItemSelectedState: PropTypes.func.isRequired,
  customItemState: PropTypes.object
};

const mapStateToProps = (state, { faq }) => ({
  customItemState: dotProp.get(state, `session.customOutputItems.${faq.id}`)
});
export default connect(
  mapStateToProps,
  { setCustomOutputItemSelectedState }
)(PatientEdFaq);
