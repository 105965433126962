import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setActiveMoreInfo, clearActiveMoreInfo } from "store/protocol";
import classNames from "classnames";
import { Tooltip } from "components/ui/tooltip";

export class MoreInfoIcon extends Component {
  constructor(props) {
    super();
    this.state = {
      moreInfoIsOpen: false
    };
  }
  showMoreInfo = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ moreInfoIsOpen: true });
    const { evidence, setActiveMoreInfo, usageType, usageId } = this.props;
    const moreInfoEvidence = { ...evidence, usageType, usageId };
    setActiveMoreInfo({ evidence: moreInfoEvidence });
  };
  closeMoreInfo = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ moreInfoIsOpen: false });
    this.props.clearActiveMoreInfo();
  };
  handleMoreInfoClick = e => {
    const { moreInfoIsOpen } = this.state;
    if (moreInfoIsOpen) {
      this.closeMoreInfo(e);
    } else {
      this.showMoreInfo(e);
    }
  };
  render() {
    const { activeMoreInfoId, evidence } = this.props;
    const moreInfoClasses = classNames("info-icon", {
      "icon-solid-circle-info": activeMoreInfoId === evidence.id,
      "icon-info": activeMoreInfoId !== evidence.id
    });
    return (
      <Tooltip
        id="more-info-icon-tooltip"
        message="Click here to dig deeper into the evidence."
        className="more-info-tooltip"
      >
        <div onClick={this.handleMoreInfoClick} className={moreInfoClasses} />
      </Tooltip>
    );
  }
}

MoreInfoIcon.propTypes = {
  evidence: PropTypes.object,
  setActiveMoreInfo: PropTypes.func,
  activeMoreInfoId: PropTypes.string,
  usageType: PropTypes.string,
  clearActiveMoreInfo: PropTypes.func,
  usageId: PropTypes.string
};
const mapStateToProps = state => ({
  activeMoreInfoId: state.protocol.activeMoreInfoId
});
const mapDispatchToProps = {
  setActiveMoreInfo,
  clearActiveMoreInfo
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreInfoIcon);
