//
// Constants for broadcast events
//
export const EVENT_AUTH_FAILURE = "auth_failure";
export const EVENT_AUTH_SUCCESS = "auth_success";
export const EVENT_AUTH_LOGOUT = "auth_logout";
export const EVENT_TOKEN_RENEWAL_FAILURE = "token_renewal_failure";

export const EVENT_FRIENDLY_LAUNCH_PROTOCOL = "friendly_launch_protocol";
export const EVENT_FRIENDLY_LAUNCH_PROTOCOL_NOTFOUND =
  "error_friendly_launch_protocol_slug_not_found";
export const EVENT_FRIENDLY_LAUNCH_PROTOCOL_ERROR =
  "error_friendly_launch_protocol";
export const EVENT_FRIENDLY_LAUNCH_PARTNER = "friendly_launch_partner";

export const EVENT_PROFILE_LOAD_FAILURE = "profile_load_failure";

export const EVENT_PWD_RESET_REQUEST = "password_reset_request";
export const EVENT_PWD_RESET_REQUEST_FAILURE = "password_reset_request_failure";
export const EVENT_PWD_RESET = "password_reset";
export const EVENT_PWD_RESET_FAILURE = "password_reset_failure";

export const EVENT_EMAIL_VERIFY = "email_verify";
export const EVENT_EMAIL_VERIFY_FAILURE = "email_verify_failure";

export const EVENT_FEATURE_PATIENT_VIEW = "view.patient_ed";
export const EVENT_FEATURE_PATIENT_VIEW_PRINT = "print.patient_ed";
export const EVENT_FEATURE_PATIENT_SEND_TO_EHR =
  "button_click.patient_ed-to-ehr";

export const EVENT_FEATURE_EHR_VIEW = "view.ehr_doc";
export const EVENT_FEATURE_EHR_QUESTIONS = "feature_ehr_questions";
export const EVENT_FEATURE_EHR_COPY_TO_CLIPBOARD =
  "button_click.ehr_doc-to-clipboard";
export const EVENT_FEATURE_EHR_SEND_TO_EHR = "button_click.ehr_doc-to-ehr";

export const EVENT_FEATURE_ORDERS_VIEW = "view.orders";
export const EVENT_FEATURE_ORDERS_SELECT = "select.orders";
export const EVENT_FEATURE_ORDERS_SEND_TO_EHR = "button_click.orders-to-ehr";

export const EVENT_FEATURE_GOODRX_VIEW = "view.goodrx";
export const EVENT_FEATURE_GOODRX_PRINT = "print.goodrx";

export const EVENT_FEATURE_EMR_VIEW = "feature_emr_view";

export const EVENT_FEATURE_CME_VIEW = "view.cme";
export const EVENT_FEATURE_CME_PRINT = "print.cme";
export const EVENT_FEATURE_CME_DISCLOSURE_VIEW = "feature_cme_disclosure_view";
export const EVENT_FEATURE_CME_DISCLOSURE_AGREE =
  "feature_cme_disclosure_agree";
export const EVENT_FEATURE_CME_TEST_VIEW = "feature_cme_test_view";
export const EVENT_FEATURE_CME_TEST_SUBMIT = "feature_cme_test_submit";
export const EVENT_FEATURE_MEMBERSHIPS_VIEW = "feature_memberships_view";
export const EVENT_FEATURE_MEMBERSHIPS_JOIN = "feature_memberships_join";
export const EVENT_FEATURE_SUBSCRIPTIONS_VIEW = "view.subscriptions";
export const EVENT_FEATURE_SUBSCRIPTIONS_CHANGE_VIEW =
  "view.subscriptions_change";

export const EVENT_FEATURE_ANTIBIOTIC_ADVISOR_LIST_VIEW =
  "view.antibiotic_advisor_list";
export const EVENT_FEATURE_CALCS_LIST_VIEW = "view.calculators_list";
export const EVENT_FEATURE_MEDS_LIST_VIEW = "view.medications_list";
export const EVENT_FEATURE_ADMISION_ADVISOR_LIST_VIEW =
  "view.admission_advisor_list";
export const EVENT_FEATURE_FILTERS_CLICK = "select.list_filter";
export const EVENT_FEATURE_PROTOCOLS_LIST_VIEW = "view.protocols_list";
export const EVENT_FEATURE_IMAGING_ADVISOR_LIST_VIEW =
  "view.imaging_advisor_list";

export const EVENT_FEATURE_FOLLOW_UP_SELECTED = "feature_follow_up_selected";
export const EVENT_FEATURE_FOLLOW_UP_UPDATE_ITEM =
  "feature_follow_up_update_item";
export const EVENT_FEATURE_SECTION_EDIT = "feature_section_edit";
export const EVENT_FEATURE_SECTION_UPDATE = "feature_section_update";
export const EVENT_FEATURE_ITEM_EDIT = "feature_item_edit";
export const EVENT_FEATURE_ITEM_SELECT = "feature_item_select";
export const EVENT_FEATURE_ITEM_UPDATE = "feature_item_update";

export const EVENT_FEATURE_CALC_SUBMIT = "submit_click.calculator";

export const EVENT_FEATURE_REFER_A_FRIEND_VIEW = "feature_refer_a_friend_view";
export const EVENT_FEATURE_REFER_A_FRIEND_COPY = "feature_refer_a_friend_copy";

export const EVENT_SIGNUP_CREATE = "signup_account_created";
export const EVENT_SIGNUP_CREATE_FAILED = "signup_account_create_failed";
export const EVENT_SIGNUP_PROFILE = "signup_profile_created";
export const EVENT_SIGNUP_PROFILE_FAILED = "signup_profile_create_failed";
export const EVENT_EMAIL_VERIFICATION_REQUEST = "email_verification_request";

export const EVENT_CHARGIFY_CALL = "chargify_call";
export const EVENT_CHARGIFY_CALL_FAILED = "chargify_call_failed";

export const EVENT_ERROR_JS = "error_js";
export const EVENT_ERROR_CHARGIFY_PREVIEW = "error_chargify_preview";

export const EVENT_SHOWED_SENTRY_ERROR_MODAL = "error_showed_sentry_modal";

export const EVENT_UNSUBSCRIBE_ERROR = "error_unsubscribe";
export const EVENT_EMAIL_UNSUBSCRIBE_SUCCESS = "email_unsubscribe_success";
export const EVENT_INVITE_ACCEPT_FAILURE = "invite_accept_failure";
export const EVENT_INVITE_ACCEPT_SUCCESS = "invite_accept_success";

export const EVENT_UE_FAILED_TO_INITIALIZE = "ue_failed_to_initialize";

export const EVENT_MED_COUPON_VIEW = "med_coupon_viewed";
export const EVENT_MED_COUPON_CLICK = "med_coupon_clicked";

export const EVENT_PROTOCOL_LAUNCHED = "launch.protocol";
export const EVENT_PROTOCOL_SEARCHED = "launch.search";
export const EVENT_SECTION_LAUNCHED = "launch.section";

export const EVENT_FEATURE_HELP_ICON_CLICK = "click.help_icon";

export const EVENT_CAREUSAGE_CLICK = "click.careusage";
export const EVENT_QUESTIONUSAGE_CLICK = "click.questionusage";

export const EVENT_FEATURE_DEVIATION_SUBMIT = "button_click.deviation_submit";

export const EVENT_FEATURE_AUTO_PROTOCOL_SEARCH = "auto_protocol_search";

export const EVENT_IA_ERROR = "ia.error";
export const EVENT_IA_LAUNCH_PROTOCOL = "ia.launch_protocol";
export const EVENT_IA_GENERATE_CONSULTATION_CODE =
  "ia.generate_consultation_code";
export const EVENT_IA_SEND_CONSULTATION_CODE = "ia.send_consultation_code";
export const EVENT_IA_CONFIRMED_CONSULTATION_CODE =
  "ia.confirmed_consultation_code";

export const EVENT_HASHED_ENCOUNTER = "hashed.encounter";

export const EVENT_ORDER_ERROR = "order.error";
