// import dotProp from 'dot-prop'
import { REHYDRATE } from "redux-persist";

// ------------------------------------
// Constants
// ------------------------------------
// export const REVIEW_SET_PROTOCOL = 'REVIEW_SET_PROTOCOL'
export const REVIEW_SET_ENABLED = "REVIEW_SET_ENABLED";
export const REVIEW_SET_VISABLE = "REVIEW_SET_VISABLE";
export const REVIEW_SET_SECTION = "REVIEW_SET_SECTION";
export const REVIEW_RESET = "REVIEW_RESET";
// ------------------------------------
// Actions
// ------------------------------------
const initialState = {
  section: {},
  enabled: false,
  visable: false
};

const initialSection = {
  visited: false,
  id: null
};

export const setSectionVisited = ({ sectionId }) => {
  return {
    type: REVIEW_SET_SECTION,
    payload: { sectionId, data: { visited: true } }
  };
};
// export const clearProtocol = ({ protocolId }) => {
//   return {
//     type: REVIEW_SET_PROTOCOL,
//     payload: { protocolId, data: initialProtocol }
//   }
// }
// export const setProtocol = ({ protocolId, data }) => {
//   console.log('hitting setprotocol', protocolId, data)
//   return {
//     type: REVIEW_SET_PROTOCOL,
//     payload: { protocolId, data }
//   }
// }

export const showReview = () => {
  return {
    type: REVIEW_SET_VISABLE,
    payload: { visable: true }
  };
};
export const hideReview = () => {
  return {
    type: REVIEW_SET_VISABLE,
    payload: { visable: false }
  };
};
export const enableReviewMode = () => {
  return {
    type: REVIEW_SET_ENABLED,
    payload: { enabled: true }
  };
};
export const disableReviewMode = () => {
  return {
    type: REVIEW_SET_ENABLED,
    payload: { enabled: false }
  };
};

export const resetAll = () => {
  return {
    type: REVIEW_RESET,
    payload: { initialState: { ...initialState, enabled: true, visable: true } }
  };
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [REHYDRATE]: (state, action) => {
    var incoming = action.payload && action.payload.review;
    if (incoming) return { ...state, ...incoming };
    return state;
  },
  [REVIEW_SET_ENABLED]: (state, action) => ({
    ...state,
    enabled: action.payload.enabled
  }),
  [REVIEW_SET_VISABLE]: (state, action) => ({
    ...state,
    visable: action.payload.visable
  }),
  [REVIEW_SET_SECTION]: (state, action) => {
    const { sectionId, data } = action.payload;
    const updatedSection = {
      ...initialSection,
      ...state.section[sectionId],
      id: sectionId,
      ...data
    };
    return {
      ...state,
      section: { ...state.section, [sectionId]: updatedSection }
    };
  },
  [REVIEW_RESET]: (state, action) => action.payload.initialState || initialState
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reviewReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
