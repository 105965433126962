// ------------------------------------
// Selectors
// ------------------------------------

export function selectAssociations(state) {
  return state.associations;
}

export function selectAssociation(state, { slug }) {
  return selectAssociations(state)[slug];
}
