export const partnersWithTemplates = {
  acg: {
    name: "ACG",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/c_scale,h_420/tagalong-site-assets/acg_evidencecare_logos.png`,
    welcomeText:
      "EvidenceCare and ACG now partner to develop and provide current, quality, and evidence-based protocols for gastroenterological clinicians everywhere."
  },
  "acep-equal": {
    name: "ACEP E-QUAL",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/c_scale,h_420/tagalong-site-assets/ACEP_EC_logos.png`,
    welcomeText: `ACEP and E-QUAL partner EvidenceCare have developed a suite of Opioid protocols to support the E-QUAL Opioid Initiative. As an E-QUAL participating hospital, your hospital has pre-registered you for EvidenceCare. We encourage you to incorporate these protocols into your daily workflow.
    <br />
    <br />
    Please complete the registration process by setting up a password below. There is no cost to you to participate.`
  },
  sempa: {
    name: "SEMPA  ",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/c_scale,h_420/tagalong-site-assets/sempa_evidencecare_logos.png`,
    welcomeText:
      "EvidenceCare and SEMPA now partner to develop and provide current, quality, and evidence-based protocols for physician assistants everywhere."
  },
  sempalink: {
    name: "SEMPA  ",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/c_scale,h_420/tagalong-site-assets/sempa_evidencecare_logos.png`,
    welcomeText:
      "EvidenceCare and SEMPA now partner to develop and provide current, quality, and evidence-based protocols for physician assistants everywhere."
  },
  ebmedicine: {
    name: "EB Medicine",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/v1520616281/email-assets/ec-ebm-header.png`,
    welcomeText:
      "EvidenceCare is a proud content delivery partner of EB Medicine. We incorporate EB Medicine's unsurpassed content to deliver you point-of-care, evidence-based protocols when you need them the most. EvidenceCare is FREE to EB Medicine customers. Just establish a password, and you are good to go!"
  },
  envision: {
    name: "Envision",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/c_scale,h_420/v1544072191/tagalong-site-assets/Envision-EC-logo.png`,
    welcomeText:
      "EvidenceCare is a proud partner of Envision Healthcare. As an Envision provider, you have been pre-registered for EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  },
  svep: {
    name: "SVEP",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/c_scale,h_420/v1530134755/svep_evidencecare_logos.png`,
    welcomeText:
      "EvidenceCare is a proud partner of St Vincent Emergency Physicians. As an SVEP provider, you have been pre-registered for EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  },
  epcf: {
    name: "EPCF",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/v1548280480/tagalong-site-assets/ec_epcf_logo.png`,
    welcomeText:
      "EvidenceCare is a proud partner of Emergency Physicians of Central Florida. As an EPCF provider, you have been pre-registered for EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  },
  lcmc: {
    name: "LCMC",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/v1548280480/tagalong-site-assets/ec_lcmc_logo.png`,
    welcomeText:
      "EvidenceCare is a proud partner of LCMC Health. As an LCMC provider, you have been pre-registered for EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  },
  // UCA can be three different codes
  uca: {
    name: "UCA",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/v1551719991/tagalong-site-assets/EC_UCA_logo.png`,
    welcomeText:
      "EvidenceCare is a proud partner of the Urgent Care Association. As a UCA provider, you have been invited to subscribe to EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  },
  ucalink: {
    name: "UCA",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/v1551719991/tagalong-site-assets/EC_UCA_logo.png`,
    welcomeText:
      "EvidenceCare is a proud partner of the Urgent Care Association. As a UCA provider, you have been invited to subscribe to EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  },
  ucadow19: {
    name: "UCA",
    cloudinaryUrl: `${CLOUDINARY_URL}/evidencecare/image/upload/v1551719991/tagalong-site-assets/EC_UCA_logo.png`,
    welcomeText:
      "EvidenceCare is a proud partner of the Urgent Care Association. As a UCA provider, you have been invited to subscribe to EvidenceCare so that you have FREE access to current, quality, and evidence-based protocols when you need them the most."
  }
};

export function selectPartnerTemplate(partnerCode) {
  return partnersWithTemplates[`${partnerCode}`.toLowerCase()];
}
