import Auth0 from "auth0-js";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import qs from "querystring";

import {
  CLEAR_AUTH,
  CLEAR_REDIRECT_LOCATION,
  REFRESH_FHIR,
  SET_AUTH0,
  SET_EC_TOKEN,
  SET_FHIR,
  SET_IS_FETCHING_EC_TOKEN,
  SET_REDIRECT_LOCATION,
  SET_SIGNUP_CODE
} from "store/auth/constants";
import { loadBootstrapData, loadBootstrapFHIRData } from "store/bootstrap";
import { AUTH_SCOPE } from "constants/auth";
import {
  selectAuth0Token,
  selectAuth0TokenTimeToRenewal,
  selectECTokenTimeToRenewal,
  selectFHIRRefreshToken,
  selectFHIRTokenTimeToRenewal,
  selectFHIRTokenURI,
  selectIsAuth0TokenExpired,
  selectIsAuthenticated,
  selectIsECTokenValid,
  selectRedirectLocation
} from "store/auth/selectors";
import { history as browserHistory } from "myHistory";
import { logSentryError } from "helpers/logging/error";
import { pipelineRequest } from "store/pipeline";
import {
  EVENT_AUTH_SUCCESS,
  EVENT_TOKEN_RENEWAL_FAILURE
} from "constants/broadcastEventTypes";
import { makeAuth0Lock } from "store/auth/auth0Lock";
import { store } from "store/store";
import { fetchEcTokenApi } from "lib/ec/account";
import { clearUser, showNewUserModal } from "store/user/actions";
import { hideLoader, showLoader } from "store/ui";
import {
  selectHasViewedNewUserModal,
  selectUserCreatedAtDate
} from "store/user/selectors";
import { loadUserTerms } from "store/terms/actions";

// ------------------------------------
// Master Login Flow Entry Point
// ------------------------------------

// Clears all Auth0 information and redirects the user
// to the login page; ALWAYS use this to initiate a new
// login flow programmatically (don't just redirect to /login)
//
// If logoutBefore is set to true, it will clear all
// the user data before attempting the login; usually
// you should only do this if the user EXPLICITLY
// indicates that they want to logout
export function launchLogin(logoutBefore = false) {
  return dispatch => {
    // clean up all the existing auth data b/c we are
    // about to re login
    if (auth0TokenRenewalTimeout) clearTimeout(auth0TokenRenewalTimeout);
    if (fhirTokenRenewalTimeout) clearTimeout(fhirTokenRenewalTimeout);
    if (ecTokenRenewalTimeout) clearTimeout(ecTokenRenewalTimeout);
    dispatch(clearAuth());

    if (logoutBefore) {
      if (auth0Lock) {
        auth0Lock.logout();
      }
      dispatch(clearUser());
    }

    // remember where we currently are so we can return
    // upon successful login
    const path = window.location.pathname;
    dispatch(setRedirectLocation(path));

    // send us to the login page
    if (path !== "/login") {
      browserHistory.push("/login");
    }
  };
}

// ------------------------------------
// Unified Logic that should fire after
// launching any type of user session
// ------------------------------------

// Should always be called at the end of bootstrapping
// a new user session (of any type); may be expanded to include logic beyond
// redirecting in the future

export function postSessionLaunchHandler() {
  return (dispatch, getState) => {
    const state = getState();

    // handle the redirect
    browserHistory.push(selectRedirectLocation(state));
    dispatch(clearRedirectLocation());

    // hide the loader if any of the session launch
    // paths activated it
    dispatch(hideLoader());

    // new user logic (considered a new user if account was created in the last 30 seconds)
    const userCreatedAtDate = selectUserCreatedAtDate(state);
    if (
      userCreatedAtDate &&
      userCreatedAtDate.valueOf() >= Date.now() - 30000
    ) {
      document.body.classList.add("new-user");
      // need this check in case the session was restored
      // without an explicit logout
      // if (!selectHasViewedNewUserModal(state)) {
      //   dispatch(showNewUserModal());
      // }
    } else {
      document.body.classList.remove("new-user");
    }
  };
}

// ------------------------------------
// Authentication Handlers
// ------------------------------------

// Handler for initiating login flows when reaching the login page
// Currently support both deferring to the Auth0 lock as well as handling
// the FHIR connections
export function onLoginPage() {
  return async (dispatch, getState) => {
    // if already authenticated; skip launching a session
    // but still execute the post session launch handler
    // for all the side-effects (like redirects)
    if (selectIsAuthenticated(getState())) {
      dispatch(postSessionLaunchHandler());

      // if in FHIR env, launch a FHIR session
      // based on the SMART response we collected when the app originally
      // loaded; (there is currently no way to "re-login" in FHIR; that's likely
      // not an issue since we re-request new credentials every time our app opens, but
      // if we ever get to the point of caching FHIR credentials for faster loading, we will
      // need to re-examine this limitation)
    } else if (__FHIR__) {
      const smartResponse = await SMART_PROMISE;
      dispatch(launchFHIRSession(smartResponse));

      // otherwise, we will fallback on using Auth0 to login; specifically,
      // relying on the Auth0 lock library
    } else {
      dispatch(showLockLogin());
    }
  };
}

// Handler for initiating login flows when receiving an Auth0
// callback hash
export function onAuth0CallbackHash(hash) {
  return dispatch => {
    if (auth0WebAuth) {
      auth0WebAuth.parseHash({ hash }, function(err, authResult) {
        if (err) {
          logSentryError(
            err,
            "parse hash failed in handleAuthentication method in auth0"
          );
        } else {
          dispatch(
            launchAuth0Session({ authResult, source: "Auth0 Callback" })
          );
        }
      });
    }
  };
}

// Handler for initiating login flows when manually receiving and inputting
// Auth0 credentials
export function onAuth0Credentials(credentials) {
  return dispatch => {
    auth0WebAuth.login(credentials, (err, authResult) => {
      if (err) {
        logSentryError(err);
      } else {
        dispatch(launchAuth0Session({ authResult, source: "Auth0 WebAuth" }));
      }
    });
  };
}

export function onAuth0ManualSignup({ credentials }) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      auth0WebAuth.signup(credentials, err => {
        if (err) {
          logSentryError(err);
          reject(err);
        } else {
          dispatch(onAuth0SignupSubmit(credentials.userMetadata.signupCode));
          dispatch(
            onAuth0Credentials({
              email: credentials.email,
              password: credentials.password,
              realm: "Email-Password-Authentication-With-Migration"
            })
          ).then(resolve);
        }
      });
    });
  };
}

export function onAuth0SignupSubmit(code) {
  return dispatch => {
    // there is no easy programmatic way to extract the signup code
    // from the Auth0 lock, so if we don't input it manually into
    // this callback, try to crawl the DOM for it
    if (!code) {
      const codeInputFromLock = document.getElementById("1-signupCode");
      if (codeInputFromLock) {
        code = codeInputFromLock.value;
      }
    }

    // dynamically importing 'react-ga'
    import("react-ga").then(reactGa =>
      reactGa.event({
        category: "event",
        action: "Registration",
        label: "Sign Up Submitted",
        value: code || ""
      })
    );

    dispatch(setSignupCode(code));
  };
}

// Function to be called immediately after rehydrating the store
// to see if it is possible to restore an authentication session
export function onRehydrate() {
  return async (dispatch, getState) => {
    // if we are already authenticated, begin an auth session
    // otherwise, just let the normal routing handlers kick
    // in and begin an authentication flow only if necessary
    if (selectIsAuthenticated(getState())) {
      if (__FHIR__) {
        await dispatch(launchFHIRSession());
      } else {
        await dispatch(launchAuth0Session({ source: "Session Resume" }));
      }
    }
  };
}

// ------------------------------------
// Auth0
//
// Handlers and configuration for working with Auth0
// ------------------------------------

let auth0TokenRenewalTimeout = null;
let auth0WebAuth = null;
let auth0Lock = null;
let auth0Callbacks = {};
if (!__FHIR__) {
  auth0WebAuth = new Auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_ID,
    responseType: "id_token",
    redirectUri: `${APP_DOMAIN}/authcb`,
    AUTH_SCOPE
  });

  auth0Callbacks = {
    onAuthenticated: authResult => {
      auth0Lock.hide();
      store.dispatch(launchAuth0Session({ authResult, source: "Auth0 Lock" }));
    },
    onAuthenticationError: error => logSentryError(error),
    onSignup: () => store.dispatch(onAuth0SignupSubmit())
  };
}

// LOCK MANIPULATION
//
// Note that we have to make new lock's every time because
// there are soooo many issues with setting the prefills
// programmatically

export function showLockLogin() {
  return () => {
    auth0Lock = makeAuth0Lock(AUTH0_ID, AUTH0_DOMAIN, {
      callbacks: auth0Callbacks
    });
    auth0Lock.show();
  };
}

export function showLockSignup({ code, email }) {
  return () => {
    auth0Lock = makeAuth0Lock(AUTH0_ID, AUTH0_DOMAIN, {
      callbacks: auth0Callbacks,
      prefills: { code, email }
    });
    auth0Lock.show({ initialScreen: "signUp" });
  };
}

// SESSION LOGIC -- Logic for maintaining and updating the authentication sessions

// Takes the credentials returned from a successful Auth0 authentication (`authResult`)
// and launches a new authentication session; `source` specifies where the `authResult`
// came from
// Note: It is ok to omit `authResult` if valid credentials are already in the store
export function launchAuth0Session({ authResult, source }) {
  return async dispatch => {
    dispatch(showLoader());

    // allows us to restore the Auth0 session
    // if the valid token data is already in the store
    // (e.g., on rehydration)
    if (authResult) {
      dispatch(setAuth0(authResult));
    }

    dispatch(scheduleAuth0Renewal());
    await dispatch(loadECToken());
    dispatch(
      pipelineRequest({
        action: EVENT_AUTH_SUCCESS,
        message: { system: source || "" }
      })
    );
    await dispatch(loadBootstrapData());
    dispatch(postSessionLaunchHandler());
  };
}

// Checks to see if the renewal time has passed, and if so, initiates
// the renewal flow
export function scheduleAuth0Renewal() {
  return (dispatch, getState) => {
    clearTimeout(auth0TokenRenewalTimeout);
    const delay = selectAuth0TokenTimeToRenewal(getState());

    // renewal time is in the future, so defer renewal
    if (delay > 0) {
      auth0TokenRenewalTimeout = setTimeout(() => {
        this.renewAuth();
      }, delay);

      // the renewal time has passed, so need to immediately renew
    } else {
      dispatch(renewAuth0());
    }
  };
}

// Tries to renew the the Auth0 session silently
// If that fails (for example if the existing token is missing or expired)
// launches a new user login
export function renewAuth0() {
  return (dispatch, getState) => {
    const state = getState();

    if (!selectAuth0Token(state) || selectIsAuth0TokenExpired(state)) {
      dispatch(launchLogin());
    } else if (auth0WebAuth) {
      auth0WebAuth.checkSession(
        {
          responseType: "id_token",
          scope: AUTH_SCOPE
        },
        (err, authResult) => {
          if (err || authResult.error) {
            Sentry.captureMessage(
              "error on renew auth" + JSON.stringify(err || authResult.error)
            );
            logSentryError(err);
            dispatch(pipelineRequest({ action: EVENT_TOKEN_RENEWAL_FAILURE }));
            dispatch(launchLogin());
          } else {
            if (authResult && authResult.idToken) {
              dispatch(setAuth0(authResult));
              dispatch(scheduleAuth0Renewal());
            } else {
              Sentry.captureException(
                new Error(
                  "had a renewAuth success without an idToken, this should never happen"
                )
              );
              dispatch(launchLogin());
            }
          }
        }
      );
    }
  };
}

// ------------------------------------
// FHIR
//
// Handlers and configuration for working with SMART on FHIR
// sessions
// ------------------------------------

// this has to be initialized immediately on file load
// due to the way our routing works
const SMART_PROMISE = __FHIR__ ? new Promise(window.FHIR.oauth2.ready) : {};

let fhirTokenRenewalTimeout = null;

// Launches (or restore) a new user session
export function launchFHIRSession(smartResponse) {
  return async dispatch => {
    dispatch(showLoader());

    // allows us to reuse this function
    // if we already have valid FHIR data in the store
    // (e.g., rehydration)
    if (smartResponse) {
      dispatch(setFHIR(smartResponse));
    }

    dispatch(scheduleFHIRRenewal());
    await dispatch(loadECToken());
    await dispatch(loadBootstrapData());
    await dispatch(loadBootstrapFHIRData());
    await dispatch(loadUserTerms());
    dispatch(postSessionLaunchHandler());
  };
}

export function scheduleFHIRRenewal() {
  return (dispatch, getState) => {
    clearTimeout(fhirTokenRenewalTimeout);
    const delay = selectFHIRTokenTimeToRenewal(getState());
    if (delay > 0) {
      fhirTokenRenewalTimeout = setTimeout(() => {
        dispatch(renewFHIR());
      }, delay);
    } else {
      dispatch(renewFHIR());
    }
  };
}

export function renewFHIR() {
  return (dispatch, getState) => {
    const state = getState();
    const tokenURI = selectFHIRTokenURI(state);
    const refreshToken = selectFHIRRefreshToken(state);
    axios
      .post(
        tokenURI,
        qs.stringify({
          grant_type: "refresh_token",
          refresh_token: refreshToken
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
          }
        }
      )
      .then(({ data }) => {
        dispatch(refreshFHIR(data));
        dispatch(scheduleFHIRRenewal());
      })
      .catch(err => {
        Sentry.captureException(err);
        dispatch(launchLogin());
      });
  };
}

// ------------------------------------
// EC Token
//
// Note that there is no such thing as an EC token session
// as this token is a component of all other sessions
// and so we defer to the parent session logic to handle
// the management of this token
// ------------------------------------

let ecTokenRenewalTimeout = null;

// Loads a new EC token, schedules the renewal, (re)bootstraps the content
export function loadECToken() {
  return async (dispatch, getState) => {
    if (!selectIsECTokenValid(getState())) {
      try {
        await dispatch(fetchECToken());
        dispatch(scheduleECTokenRenewal());
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };
}

// Fetches the EC token and sets the updated state
export function fetchECToken() {
  return dispatch => {
    dispatch(setIsFetchingEcToken(true));
    return fetchEcTokenApi()
      .then(({ data: { id_token } }) => {
        if (id_token) {
          dispatch(setECToken(id_token));
        } else {
          throw new Error(
            "No token returned when trying to fetch new EC token!"
          );
        }
      })
      .finally(() => {
        dispatch(setIsFetchingEcToken(false));
      });
  };
}

export function scheduleECTokenRenewal() {
  return (dispatch, getState) => {
    clearTimeout(ecTokenRenewalTimeout);
    const delay = selectECTokenTimeToRenewal(getState());
    if (delay > 0) {
      ecTokenRenewalTimeout = setTimeout(() => {
        dispatch(loadECToken());
      }, delay);
    } else {
      dispatch(loadECToken());
    }
  };
}

// ------------------------------------
// Actions
// ------------------------------------

export function setAuth0({ accessToken, access_token, id_token, idToken }) {
  return {
    type: SET_AUTH0,
    payload: {
      accessToken: accessToken || access_token,
      idToken: idToken || id_token
    }
  };
}

export function setIsFetchingEcToken(isFetching = false) {
  return {
    type: SET_IS_FETCHING_EC_TOKEN,
    payload: isFetching
  };
}

export function setECToken(token) {
  return {
    type: SET_EC_TOKEN,
    payload: token
  };
}

export function setFHIR(smartResponse) {
  return {
    type: SET_FHIR,
    payload: smartResponse
  };
}

export function refreshFHIR(newTokens) {
  return {
    type: REFRESH_FHIR,
    payload: newTokens
  };
}

export function setRedirectLocation(path = "/") {
  return {
    type: SET_REDIRECT_LOCATION,
    payload: path
  };
}

export function clearRedirectLocation() {
  return {
    type: CLEAR_REDIRECT_LOCATION
  };
}

export function clearAuth() {
  return {
    type: CLEAR_AUTH
  };
}

export function setSignupCode(code) {
  return {
    type: SET_SIGNUP_CODE,
    payload: code
  };
}
