import React, { Component } from "react";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte/lib/RichTextEditor";
import _ from "lodash";
const BLOCK_TYPES = {
  recommendation: [
    { label: "Normal", style: "unstyled" },
    {
      label: "List Header",
      style: "header-six",
      className: "rec-item_list-header"
    }
  ],
  default: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-four" },
    { label: "Heading Medium", style: "header-five" },
    { label: "Heading Small", style: "header-six" }
  ]
};

export default class RichTextMdEditor extends Component {
  constructor(props) {
    super();
    const value =
      (props.markdown &&
        RichTextEditor.createValueFromString(props.markdown, "markdown")) ||
      (props.defaultText &&
        RichTextEditor.createValueFromString(props.defaultText, "markdown")) ||
      RichTextEditor.createEmptyValue();
    this.state = {
      value
    };
  }

  setMarkdown = rteVal => {
    /*
     When you set an empty string as react-rte's value, it will turn it into 2
     characters, a zero-width space = '\u200B' and an LF (unicode = 10)
     regex does not consider a zero-width space whitespace (\s).

     We depend on empty strings set as values for fields to show/hide components,
     so we must strip all whitespace and zero-width spaces from the markdown when
     there are no other valid characters.
    */
    const rteMarkdown = rteVal.toString("markdown");
    const markdownToSave =
      rteMarkdown.replace(/\s|\u200B/g, "").length === 0 ? "" : rteMarkdown;
    this.props.setMarkdown(markdownToSave);
  };

  // only need to fire the setMarkdown cb periodically
  setMarkdownDebounced = _.debounce(rteVal => {
    this.setMarkdown(rteVal);
  }, 400);

  onChange = value => {
    this.setState({ value });
    if (this.props.setMarkdown) {
      this.setMarkdownDebounced(value);
    }
  };

  render() {
    const { type } = this.props;
    const toolbarConfig = {
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS"
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD" },
        { label: "Italic", style: "ITALIC" }
      ],
      BLOCK_TYPE_DROPDOWN: BLOCK_TYPES[type || "default"],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" }
      ]
    };

    return (
      (this.props.markdown !== undefined && (
        <div className="markdown_html">
          <RichTextEditor
            value={this.state.value}
            onChange={this.onChange}
            toolbarConfig={toolbarConfig}
            className="rich-text-editor"
          />
        </div>
      )) ||
      null
    );
  }
}

RichTextMdEditor.propTypes = {
  setMarkdown: PropTypes.func.isRequired,
  markdown: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultText: PropTypes.string
};
