import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { xsmallImageUrl } from "helpers/urls/cloudinary";
import MarkdownRender from "utility/markdownRender";
import { EVENT_FEATURE_MEMBERSHIPS_JOIN } from "constants/broadcastEventTypes";
import { createMembership, deleteMembership } from "store/account";
import classNames from "classnames";
import ButtonLoader from "components/ui/buttons/ButtonLoader";
import { pipelineRequest } from "store/pipeline";

export class Association extends Component {
  state = {
    verificationCode: "",
    canSubmit: false,
    isFetchingData: false
  };
  memberSignUp = e => {
    e.preventDefault();
    const { createMembership, ass, fetchAssociations } = this.props;
    const code =
      ass.verification_method === "code" && this.state.verificationCode;
    this.setState({ isFetchingData: true });
    createMembership({ assId: ass.id, code: code }).then(() => {
      fetchAssociations().then(() => {
        this.setState({ isFetchingData: false });
      });
    });
    this.props.pipelineRequest({
      action: EVENT_FEATURE_MEMBERSHIPS_JOIN
    });
  };
  memberRemove = ({ membUid, assName }) => {
    this.setState({ isFetchingData: true });
    const { fetchAssociations, deleteMembership } = this.props;
    deleteMembership({ membUid, assName }).then(data => {
      data && data.err && this.setState({ isFetchingData: false });
      fetchAssociations().then(() => {
        this.setState({ isFetchingData: false });
      });
    });
  };
  enableButton = () => {
    this.setState({ canSubmit: true });
  };
  disableButton = () => {
    this.setState({ canSubmit: false });
  };
  handleVerificationCode = value => {
    if (value && value.length > 0) {
      this.enableButton();
      this.setState({ verificationCode: value });
    } else {
      this.disableButton();
    }
  };
  render() {
    const { ass } = this.props;
    const removeMembershipClass = classNames("membership_unjoin", {
      "membership_unjoin--loading": this.state.isFetchingData
    });
    return (
      <div className="membership row">
        {ass.cloudinary_id && (
          <div className="membership_logo shrink columns">
            <img src={xsmallImageUrl(ass.cloudinary_id)} alt={ass.name} />
          </div>
        )}
        <div className="membership_body columns">
          <h6 className="membership_title">{ass.name}</h6>
          <MarkdownRender source={ass.benefits} />
        </div>
        <form
          className="membership_signup shrink columns"
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          onSubmit={this.memberSignUp}
        >
          {!ass.is_member && ass.verification_method !== "" && (
            <div>
              {ass.verification_method === "code" && (
                <input
                  placeholder="Verification Code"
                  onChange={e => this.handleVerificationCode(e.target.value)}
                  className="membership_signup-field text-input"
                  name="code"
                  required
                />
              )}
              <ButtonLoader
                text="Join"
                isLoading={this.state.isFetchingData}
                isDisabled={
                  ass.verification_method === "code" && !this.state.canSubmit
                }
              />
            </div>
          )}
          {ass.is_member && (
            <div>
              <div className="membership_signedup">
                <div className="icon-circle-check" />
                Joined
              </div>
              <div
                className={removeMembershipClass}
                onClick={() => {
                  this.memberRemove({
                    membUid: ass.membership.uid,
                    assName: ass.name
                  });
                }}
              >
                {(this.state.isFetchingData && "removing") || "remove"}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

Association.propTypes = {
  ass: PropTypes.object,
  createMembership: PropTypes.func,
  deleteMembership: PropTypes.func,
  fetchAssociations: PropTypes.func,
  pipelineRequest: PropTypes.func
};

const mapDispatchToProps = {
  createMembership,
  deleteMembership,
  pipelineRequest
};

export default connect(
  null,
  mapDispatchToProps
)(Association);
