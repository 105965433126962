import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dotProp from "dot-prop";
import { connect } from "react-redux";
import {
  selectConsultationCode,
  selectAUCCriteria
} from "store/patient/selectors";
import { removeSnakeCaseAndCapitalize } from "helpers/util";
import CopyToClipboard from "components/global/CopyToClipboard";
import copy from "copy-to-clipboard";
import { success } from "react-notification-system-redux";

const notificationOpts = {
  uid: "copy-ehr-to-clipboard-success",
  message: "Copied to clipboard",
  position: "tr"
};

const SuccessIndicatorText = ({
  isMessageSuccessful,
  consultationCode,
  aucCriteria,
  success
}) => {
  const handleCopy = () => {
    copy(consultationCode);
    success(notificationOpts);
  };
  return isMessageSuccessful ? (
    <>
      <div className="web-message_success-text">
        Appropriate use determination has been sent back to your EHR. Please
        close this browser window to continue.
      </div>
    </>
  ) : (
    <div className="web-message_success-text">
      UH OH! We hit a snag sending your code back to the EHR. Don't worry, we've
      still got you covered!
      <div className="web-message_auc-data-text">
        <div>Just copy this code and then paste it into your EHR:</div>
        <div className="web-message_consultation-code-container">
          <div className="web-message_consultation-code">
            {consultationCode}
          </div>
          <CopyToClipboard
            handleCopy={handleCopy}
            copyButton={
              <button className="button button-secondary">
                Copy your code
              </button>
            }
          />
        </div>
        <div>{`Criteria Status: ${removeSnakeCaseAndCapitalize(
          aucCriteria
        )}`}</div>
      </div>
    </div>
  );
};

SuccessIndicatorText.propTypes = {
  isMessageSuccessful: PropTypes.bool
};

const ImagingAdvisorStatusScreen = props => {
  const { location, consultationCode, aucCriteria, success, match } = props;
  const messageStatus = dotProp.get(match, "params.status");
  const isMessageSuccessful = messageStatus ? JSON.parse(messageStatus) : false;
  const messageIconClasses = classNames("web-message_icon", {
    "web-message_icon--success icon-solid-circle-check": isMessageSuccessful,
    "web-message_icon--failure icon-solid-triangle-exclamation-point": !isMessageSuccessful
  });
  return (
    <div className="web-message_notification-container">
      <div className={messageIconClasses} />
      {SuccessIndicatorText({
        isMessageSuccessful,
        consultationCode,
        aucCriteria,
        success
      })}
    </div>
  );
};
ImagingAdvisorStatusScreen.propTypes = {
  location: PropTypes.object,
  consultationCode: PropTypes.string,
  aucCriteria: PropTypes.string
};

const mapStateToProps = state => ({
  consultationCode: selectConsultationCode(state),
  aucCriteria: selectAUCCriteria(state)
});

export default connect(
  mapStateToProps,
  { success }
)(ImagingAdvisorStatusScreen);
