import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "components/ui/tooltip";

const SendToEhrButton = ({ sendToEhr }) => (
  <Tooltip
    id="ehr-send-tooltip"
    className="ehr-icon_tooltip"
    message="Send to EHR"
    place="left"
  >
    <a className="button button-copy orders-send-button" onClick={sendToEhr}>
      <span className="icon-submit-arrow" /> Send to EHR
    </a>
  </Tooltip>
);

export default SendToEhrButton;

SendToEhrButton.propTypes = {
  sendToEhr: PropTypes.func.isRequired
};
