import React from "react";
import PropTypes from "prop-types";
import Block from "./Block";
import { connect } from "react-redux";
import { clearActiveMoreInfo } from "store/protocol";

const MoreInfo = ({ moreInfo, clearActiveMoreInfo }) => {
  return (
    <div className="more-info">
      <div className="more-info_header row align-middle">
        <div className="columns shrink">
          <div className="icon-info" />
        </div>
        <div className="columns">More Information</div>
        <div className="columns shrink">
          <div className="icon-close" onClick={clearActiveMoreInfo} />
        </div>
      </div>
      {moreInfo.recommendation && moreInfo.usageType !== "evidenceusage" && (
        <Block
          usageId={moreInfo.usageId}
          block={moreInfo.recommendation}
          startOpen
          isCollapsible
          isTitleHidden
        />
      )}
      {moreInfo.blocks &&
        moreInfo.blocks.map(block => {
          return (
            <Block
              block={block}
              usageId={moreInfo.usageId}
              key={block.id}
              startOpen={moreInfo.blocks.length === 1}
              evidenceInternalName={moreInfo.internalName}
              isCollapsible
            />
          );
        })}
    </div>
  );
};

MoreInfo.propTypes = {
  moreInfo: PropTypes.object,
  clearActiveMoreInfo: PropTypes.func
};
const mapDispatchToProps = {
  clearActiveMoreInfo
};
export default connect(
  null,
  mapDispatchToProps
)(MoreInfo);
