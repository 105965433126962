import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dotProp from "dot-prop";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { Tooltip } from "components/ui/tooltip";
import { launchNextSection } from "store/session";
import { getConfigByOrgSlug } from "helpers/config";

export const SectionLaunchNextSection = props => {
  const {
    section,
    activeSelectedOutputs,
    careUsagesWithOutputs,
    reasons,
    slug
  } = props;
  // TODO: add to re-reselect
  // Object that if exists determines whether to show button and which section id to use to launch next section
  const selectedCareUsageWithNextSection = careUsagesWithOutputs.find(cu => {
    // careUsage needs to be selected and have a nextId
    return cu.output && cu.output.nextId && activeSelectedOutputs[cu.output.id];
  });

  // TODO: add to re-reselect
  // Boolean to determine whether next button should be disabled
  // we disable the button if the user is in a FHIR env and
  // 1. they selected an inappropriate care usage
  // 2. we don't want them to proceed without submitting a reason for doing so

  const selectedInappropriateCareUsageWithoutReason = careUsagesWithOutputs.some(
    cu => {
      return (
        cu.appropriateness === -1 && // is inappropriate
        activeSelectedOutputs[cu.output.id] && // has been selected
        (!reasons[cu.id] || (reasons[cu.id] && !reasons[cu.id].submitted))
      ); // reason doesn't exist or it exists but hasn't been submitted.
    }
  );

  const ehrOptions = getConfigByOrgSlug(slug);

  return (
    (((props.sectionOutput && props.sectionOutput.nextId) ||
      selectedCareUsageWithNextSection) && (
      <div className="row align-right collapse section_next">
        <Tooltip
          id="next-section-tooltip"
          message={
            ehrOptions.disableNextOnDeviation &&
            selectedInappropriateCareUsageWithoutReason
              ? "Provide a reason for selecting a Rarely Appropriate decision to continue"
              : "Select NEXT to capture your decisions and move through the protocol."
          }
        >
          <div className="columns text-right">
            <button
              className="button button_standard"
              disabled={
                ehrOptions.disableNextOnDeviation &&
                selectedInappropriateCareUsageWithoutReason
              }
              onClick={() =>
                props.launchNextSection({
                  currentSectionId: section.id,
                  nextSectionId: selectedCareUsageWithNextSection
                    ? selectedCareUsageWithNextSection.output.nextId
                    : props.sectionOutput.nextId
                })
              }
            >
              Next
            </button>
          </div>
        </Tooltip>
      </div>
    )) ||
    null
  );
};

SectionLaunchNextSection.propTypes = {
  activeGroupId: PropTypes.string,
  children: PropTypes.array,
  sectionVersion: PropTypes.object,
  activeSelectedOutputs: PropTypes.array,
  launchNextSection: PropTypes.func
};
const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const getSelectedOutputs = makeSelectedOutputs();
    return {
      activeSelectedOutputs: getSelectedOutputs(state),
      reasons: state.session.userText.reasons,
      slug: dotProp.get(state, `user.organization.slug`, "")
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = {
  launchNextSection
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SectionLaunchNextSection);
