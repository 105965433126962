import axios from "axios";

/******************************************
 * API Calls
 ******************************************/

const FHIR_ENV = __FHIR__ ? FHIR_ENVIRONMENT : "none";
const TOUR_ENDPOINT = loadDrafts =>
  `${
    loadDrafts ? USER_ADOPTION_SERVICE : USER_ADOPTION_CONTENT_DOMAIN
  }/flows/${FHIR_ENV}`;
const MODAL_ENDPOINT = loadDrafts =>
  `${loadDrafts ? USER_ADOPTION_SERVICE : USER_ADOPTION_CONTENT_DOMAIN}/modals`;
const HC_LANDING_ENDPOINT = loadDrafts =>
  `${
    loadDrafts ? USER_ADOPTION_SERVICE : USER_ADOPTION_CONTENT_DOMAIN
  }/help_center_landing/${FHIR_ENV}`;
const HC_SECTION_ENDPOINT = (id, loadDrafts) =>
  `${
    loadDrafts ? USER_ADOPTION_SERVICE : USER_ADOPTION_CONTENT_DOMAIN
  }/help_center_sections/${FHIR_ENV}/${id}`;
const HC_ARTICLE_ENDPOINT = (id, loadDrafts) =>
  `${
    loadDrafts ? USER_ADOPTION_SERVICE : USER_ADOPTION_CONTENT_DOMAIN
  }/help_center_articles/${id}`;

export const fetchTours = ({
  userOrganizationName,
  accessibleContentBuckets,
  loadDrafts = false
}) => {
  return (
    axios
      .get(TOUR_ENDPOINT(loadDrafts))
      .then(response => response.data.flows || [])

      // filter to retain only the applicable tours
      .then(tours => {
        return tours.filter(tour =>
          hasPermissions(tour, {
            userOrganizationName,
            accessibleContentBuckets
          })
        );
      })
  );
};

export const fetchModals = loadDrafts => {
  return axios.get(MODAL_ENDPOINT(loadDrafts)).then(response => response.data);
};

export const fetchHelpCenterLanding = loadDrafts => {
  return axios
    .get(HC_LANDING_ENDPOINT(loadDrafts))
    .then(response => response.data);
};

export const fetchHelpCenterSection = (id, loadDrafts) => {
  return axios
    .get(HC_SECTION_ENDPOINT(id, loadDrafts))
    .then(response => response.data);
};

export const fetchHelpCenterArticle = (id, loadDrafts) => {
  return axios
    .get(HC_ARTICLE_ENDPOINT(id, loadDrafts))
    .then(response => response.data.article);
};

/******************************************
 * Utilities
 ******************************************/

// filters the content array on the the presumption it has the following arrays:
//
// - activeOrganizations: `userOrganizationName` MUST be in this array
// - requiredContent: `accesibleContentBuckets` MUST contain all items in this array
//
// Those items that don't meet the above requirements will not be in the returned array
export function hasPermissions(content, filters = {}) {
  if (!content) return false;

  const { userOrganizationName, accessibleContentBuckets = [] } = filters;

  // check if user's organization is in the allowed list
  if (
    Array.isArray(content.activeOrganizations) &&
    content.activeOrganizations.findIndex(
      name => userOrganizationName === name
    ) === -1
  ) {
    return false;
  }

  // check if user has access to all of the content
  const { requiredContent } = content;
  if (Array.isArray(requiredContent)) {
    for (let i = 0; i < requiredContent.length; i++) {
      if (!accessibleContentBuckets.includes(requiredContent[i])) {
        return false;
      }
    }
  }

  return true;
}
