import { makeEcRequest } from "./base";

export const termsApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "agreements/terms/me"
  });
};

export const acceptAgreementApi = termsId => {
  return makeEcRequest({
    method: "POST",
    location: "agreements/agreement/me",
    body: {
      terms_id: termsId
    }
  });
};
