import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TextWithCitation from "../components/TextWithCitation";

export class McgRightColumnAccordian extends Component {
  state = {
    open: true
  };
  getMetaType = () => {
    const { content } = this.props;
    if (content.CitationText) {
      return "citation";
    } else if (content.Type) {
      return "annotation";
    } else {
      return "footnote";
    }
  };
  render() {
    const { content } = this.props;
    const accordianIconClasses = classnames("mcg-right-column_accordian-icon", {
      "icon-expand": !this.state.open,
      "icon-expanded": this.state.open
    });
    const metaType = this.getMetaType();
    const item =
      metaType === "footnote"
        ? content.ContentItem
        : metaType === "citation"
        ? {
            Text: content.CitationText
          }
        : content.ContentItems[0];
    return (
      <div className="mcg-right-column_accordian">
        <div
          onClick={() => this.setState({ open: !this.state.open })}
          className="mcg-right-column_accordian-header"
        >
          <h6 className="mcg-right-column_accordian-title">{metaType}</h6>
          <span className={accordianIconClasses} />
        </div>
        {this.state.open && (
          <div className="mcg-right-column_accordian-content">
            <TextWithCitation item={item} />
          </div>
        )}
      </div>
    );
  }
}

McgRightColumnAccordian.propTypes = {
  content: PropTypes.object
};

export default McgRightColumnAccordian;
