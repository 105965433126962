import React, { Component } from "react";
import PropTypes from "prop-types";
import { providerTypes } from "constants/account";
import { Formik, Field } from "formik";

import {
  FieldRadio,
  FieldTextComponent,
  FieldTextAreaComponent,
  RadioGroup,
  FieldSelectComponent
} from "components/ui/form";
import * as Yup from "yup";

export class PostActivityQuestions extends Component {
  needsName = !this.props.firstName || this.props.lastName;
  needsProviderType = !this.props.providerType;

  saveAccountDetails = values => {
    const profileData = {
      provider_type: values.providerType
    };
    const userData = {
      first_name: values.firstName,
      last_name: values.lastName
    };
    this.props.saveAccountDetails(userData, profileData).then(() => {
      this.props.fetchBootstrapInfo();
    });
  };

  render() {
    const { firstName, lastName, degree, providerType } = this.props;
    return (
      <Formik
        initialValues={{
          firstName,
          lastName,
          degree: degree || "MD",
          providerType
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required("First Name is required"),
          lastName: Yup.string().required("Last Name is required"),
          degree: Yup.string(),
          hasPracticeChanged: Yup.string().required("Required"),
          answerText: Yup.string().when("hasPracticeChanged", {
            is: val => val === "TRUE",
            then: Yup.string().required("Required"),
            otherwise: Yup.string()
          }),
          providerType: Yup.string().required("Provider type is required")
        })}
        onSubmit={values => {
          this.props.handlePostActivitySubmit({
            degree: values.degree,
            hasPracticeChanged: values.hasPracticeChanged === "TRUE",
            practiceChangedDescription: values.answerText || ""
          });
          this.saveAccountDetails(values);
        }}
        render={({ values, handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <div className="cme-test_post-activity-questions">
              <h5 className="cme-test_post-activity-questions-header">
                CME Post-Activity Questions
              </h5>
              {this.needsName && (
                <div className="row collapse-outer-col-gutters-row cme-test_post-activity-question-row">
                  <div className="medium-6 column">
                    <label>
                      First Name<span className="required_asterisk">*</span>
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        component={FieldTextComponent}
                      />
                    </label>
                  </div>
                  <div className="medium-6 column">
                    <label>
                      Last Name<span className="required_asterisk">*</span>
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        component={FieldTextComponent}
                      />
                    </label>
                  </div>
                </div>
              )}
              <div className="row collapse-outer-col-gutters-row cme-test_post-activity-question-row">
                <div className="medium-6 column">
                  <Field
                    required
                    className="cme-question_select"
                    label="Degree"
                    component={FieldSelectComponent}
                    name="degree"
                  >
                    <option value="MD">MD</option>
                    <option value="DO">DO</option>
                    <option value="PA">PA</option>
                  </Field>
                </div>
                {this.needsProviderType && (
                  <div className="medium-6 column">
                    <div className="cme-question_prompt">
                      <label htmlFor="providerType">
                        Provider Type
                        <span className="required_asterisk">*</span>{" "}
                      </label>
                      <Field
                        className="cme-question_select"
                        component="select"
                        name="providerType"
                      >
                        <option value="">No provider type chosen</option>
                        {providerTypes.providerType.map(p => (
                          <option key={p.value} value={p.value}>
                            {p.text}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                )}
              </div>
              <div className="cme-test_post-activity-question-row">
                <RadioGroup
                  required
                  legend="Will your participation in the activity result in a change in your practice?"
                >
                  <FieldRadio
                    id="hasPracticeChangedYes"
                    name="hasPracticeChanged"
                    value="TRUE"
                    label="Yes"
                  />
                  <FieldRadio
                    id="hasPracticeChangedNo"
                    name="hasPracticeChanged"
                    value="FALSE"
                    label="No"
                  />
                </RadioGroup>
              </div>
              <div className="cme-test_post-activity-question-row">
                {values.hasPracticeChanged === "TRUE" && (
                  <Field
                    required
                    component={FieldTextAreaComponent}
                    name="answerText"
                    label="Tell us how your practice has changed:"
                  />
                )}
              </div>
              <div className="cme-test_post-activity-question-row">
                <p className="required_indicator">
                  <span className="required_asterisk">*</span>{" "}
                  <em>Indicates required field</em>
                </p>
              </div>

              <div className="text-right">
                <button
                  className="button button_standard"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

PostActivityQuestions.propTypes = {
  handlePostActivitySubmit: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  degree: PropTypes.string,
  providerType: PropTypes.string,
  saveAccountDetails: PropTypes.func,
  fetchBootstrapInfo: PropTypes.func
};

export default PostActivityQuestions;
