import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setProviderNoteText, setProviderFollowUpAppt } from "store/session";
import dotProp from "dot-prop";
import { compose } from "redux";
import { success } from "react-notification-system-redux";
const noteNotificationOpts = {
  uid: "patient-ed-provider-note-saved",
  message: "Note Saved",
  position: "tr"
};
const followUpNotificationOpts = {
  uid: "patient-ed-follow-up-saved",
  message: "Follow Up Appointment Saved",
  position: "tr"
};
const withPersonalNoteCouponAndFollowUp = WrappedComponent => {
  return class PersonalNote extends Component {
    propTypes = {
      providerFollowUp: PropTypes.func,
      providerNoteText: PropTypes.string,
      setProviderNoteText: PropTypes.func,
      success: PropTypes.func,
      setProviderFollowUpAppt: PropTypes.func
    };
    constructor(props) {
      super(props);
      const { providerFollowUp, providerNoteText } = props;
      this.state = {
        isPersonalNoteInputOpen: false,
        isFollowUpFormOpen: false,
        isCouponOpen: false,
        appointment: {
          provider: (providerFollowUp && providerFollowUp.provider) || "",
          followUpSpecialty:
            (providerFollowUp && providerFollowUp.followUpSpecialty) || "",
          followUpDate:
            (providerFollowUp && providerFollowUp.followUpDate) || "",
          followUpTime:
            (providerFollowUp && providerFollowUp.followUpTime) || ""
        },
        providerNote: providerNoteText || ""
      };
    }

    handlePersonalNoteSubmit = e => {
      e.preventDefault();
      const { providerNote } = this.state;
      this.props.setProviderNoteText({ providerNoteText: providerNote });
      this.props.success(noteNotificationOpts);
      this.setState({ isPersonalNoteInputOpen: false });
    };
    handleFollowUpSubmit = e => {
      e.preventDefault();
      this.props.setProviderFollowUpAppt({
        providerFollowUp: this.state.appointment
      });
      this.props.success(followUpNotificationOpts);
      this.setState({ isFollowUpFormOpen: false });
    };
    togglePersonalNoteInput = () => {
      this.setState({
        isPersonalNoteInputOpen: !this.state.isPersonalNoteInputOpen,
        isCouponOpen: false,
        isFollowUpFormOpen: false
      });
    };
    toggleFollowUpForm = () => {
      this.setState({
        isFollowUpFormOpen: !this.state.isFollowUpFormOpen,
        isCouponOpen: false,
        isPersonalNoteInputOpen: false
      });
    };
    toggleCoupon = () => {
      this.setState({
        isCouponOpen: !this.state.isCouponOpen,
        isPersonalNoteInputOpen: false,
        isFollowUpFormOpen: false
      });
    };
    handleChange = ({ target }) => {
      this.setState({ [target.name]: target.value });
    };
    handleAppointmentChange = ({ target }) => {
      this.setState({
        appointment: { ...this.state.appointment, [target.name]: target.value }
      });
    };
    render() {
      return (
        <WrappedComponent
          appointment={this.state.appointment}
          handleAppointmentChange={this.handleAppointmentChange}
          providerNote={this.state.providerNote}
          data={this.state}
          onChange={this.handleChange}
          handleFollowUpSubmit={this.handleFollowUpSubmit}
          handlePersonalNoteSubmit={this.handlePersonalNoteSubmit}
          toggleFollowUpForm={this.toggleFollowUpForm}
          togglePersonalNoteInput={this.togglePersonalNoteInput}
          toggleCoupon={this.toggleCoupon}
          {...this.props}
        />
      );
    }
  };
};

const mapStateToProps = state => ({
  account: state.account,
  providerNoteText: dotProp.get(state, "session.providerNoteText"),
  providerFollowUp: dotProp.get(state, "session.providerFollowUp")
});

export default compose(
  connect(
    mapStateToProps,
    { setProviderNoteText, setProviderFollowUpAppt, success }
  ),
  withPersonalNoteCouponAndFollowUp
);
