import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  loadHelpCenterSection,
  setHCActiveSection
} from "store/helpCenter/actions";
import { selectSectionData } from "store/helpCenter/selectors";
import HelpCenterTabbedLayout from "layouts/HelpCenterTabbedLayout/HelpCenterTabbedLayout";
import HelpCenterArticleGroup from "./HelpCenterArticleGroup";
import "./HelpCenterSection.scss";

export class HelpCenterSection extends React.PureComponent {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const { title, subsections = [], id } = this.props;
    if (!title) return null;

    return (
      <HelpCenterTabbedLayout title={title} activeSectionId={id}>
        {subsections.map(({ title, id, articles }, i) => (
          <React.Fragment key={id}>
            {i !== 0 && (
              <hr className="help-center-section_article-group-divider" />
            )}
            <div className="help-center-section_article-group-container">
              <HelpCenterArticleGroup title={title} articles={articles} />
            </div>
          </React.Fragment>
        ))}
      </HelpCenterTabbedLayout>
    );
  }
}

HelpCenterSection.propTypes = {
  id: PropTypes.string,
  loadData: PropTypes.func,
  title: PropTypes.string,
  subsections: PropTypes.array
};

const mapStateToProps = (state, ownProps) => {
  return selectSectionData(state, { id: ownProps.match.params.id });
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: () => {
    const { id } = ownProps.match.params;
    dispatch(loadHelpCenterSection(id));
    dispatch(setHCActiveSection(id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpCenterSection);
