import { batchActions } from "redux-batched-actions";
import unified from "unified";
import markdown from "remark-parse";
import remark2rehype from "remark-rehype";
import visit from "unist-util-visit";
import rehyperaw from "rehype-raw";

import { history } from "myHistory";
import {
  CLEAR_HC_DATA,
  SET_HC_ACTIVE_ARTICLE,
  SET_HC_ACTIVE_SECTION,
  UPDATE_HC_ARTICLES,
  UPDATE_HC_LANDINGPAGE,
  UPDATE_HC_SECTIONS,
  UPDATE_HC_SUBSECTIONS
} from "./constants";
import { editCloudinarySrc } from "helpers/urls/cloudinary";
import {
  fetchHelpCenterLanding,
  fetchHelpCenterSection,
  fetchHelpCenterArticle
} from "lib/ec/sherpa";
import {
  selectHelpCenterArticles,
  selectHelpCenterSections,
  selectIsArticleLoaded,
  selectIsLandingDataLoaded,
  selectIsSectionLoaded
} from "./selectors";
import { selectSherpaDraftMode } from "store/admin/selectors";
import * as Sentry from "@sentry/browser";

// ------------------------------------
// Thunks
// ------------------------------------

/******************* Data Loading Utilities **********************/

let loadLandingInFlight = false;
export function loadHelpCenterLanding() {
  return (dispatch, getState) => {
    const state = getState();
    const isDraftMode = selectSherpaDraftMode(state);
    if (!loadLandingInFlight && !selectIsLandingDataLoaded(state)) {
      loadLandingInFlight = true;
      return fetchHelpCenterLanding(isDraftMode)
        .then(({ main, articles, sections, subsections }) => {
          dispatch(
            batchActions([
              updateHCSections(sections),
              updateHCArticles(articles),
              updateHCSubsections(subsections),
              updateHCLandingPage(main)
            ])
          );
        })
        .catch(() => {
          const err = new Error(
            `Could not load help center landing. Draft status: ${isDraftMode}`
          );
          Sentry.captureException(err);
          console.log(err);
        })
        .finally(() => {
          loadLandingInFlight = false;
        });
    }
  };
}

const loadSectionInFlight = {};
export function loadHelpCenterSection(id, logError = true) {
  return (dispatch, getState) => {
    const state = getState();
    if (!loadSectionInFlight[id] && !selectIsSectionLoaded(state, { id })) {
      loadSectionInFlight[id] = true;
      const isDraftMode = selectSherpaDraftMode(state);
      return fetchHelpCenterSection(id, isDraftMode)
        .then(({ articles, subsections, section }) => {
          dispatch(
            batchActions([
              updateHCArticles(articles),
              updateHCSubsections(subsections),
              updateHCSections({
                [section.id]: { ...section, isFullyLoaded: true }
              })
            ])
          );
        })
        .catch(() => {
          if (logError) {
            const err = new Error(
              `Could not load help center section. Draft status: ${isDraftMode}. Section ID: ${id}`
            );
            Sentry.captureException(err);
            console.log(err);
          }
        })
        .finally(() => {
          delete loadSectionInFlight[id];
        });
    }
  };
}

const loadArticleInFlight = {};
export function loadHelpCenterArticle(id, logError = true) {
  return (dispatch, getState) => {
    const state = getState();
    if (!loadArticleInFlight[id] && !selectIsArticleLoaded(state, { id })) {
      loadArticleInFlight[id] = true;
      const isDraftMode = selectSherpaDraftMode(state);
      return fetchHelpCenterArticle(id, isDraftMode)
        .then(article => {
          dispatch(
            updateHCArticles({
              [article.id]: { ...article, isFullyLoaded: true }
            })
          );
        })
        .catch(() => {
          if (logError) {
            const err = new Error(
              `Could not load help center article. Draft status: ${isDraftMode}. Article ID: ${id}`
            );
            Sentry.captureException(err);
            console.log(err);
          }
        })
        .finally(() => {
          delete loadArticleInFlight[id];
        });
    }
  };
}

// Note: Should only be used by admins as this is an blunt force reload tool
// that disables error logging to deal with circumstances like unpublishing
// or switching between draft modes
export function reloadHelpCenterData() {
  return (dispatch, getState) => {
    const state = getState();
    const articleIds = Object.keys(selectHelpCenterArticles(state));
    const sectionIds = Object.keys(selectHelpCenterSections(state));

    dispatch(clearHCData());

    return Promise.all([
      dispatch(loadHelpCenterLanding()),
      ...articleIds.map(id => dispatch(loadHelpCenterArticle(id, false))),
      ...sectionIds.map(id => dispatch(loadHelpCenterSection(id, false)))
    ]);
  };
}

/******************* Navigation Utilities **********************/

export function goToHelpCenterArticle(id) {
  return dispatch => {
    const destination = `/help-center/article/${id}`;
    if (window.location.pathname !== destination) {
      history.push(destination);
      dispatch(setHCActiveArticle(id));
      loadHelpCenterArticle(id);
    }
  };
}

export function goToHelpCenterSection(id) {
  return dispatch => {
    const destination = `/help-center/section/${id}`;
    if (window.location.pathname !== destination) {
      history.push(destination);
      dispatch(batchActions([setHCActiveSection(id), setHCActiveArticle()]));
      dispatch(loadHelpCenterSection(id));
    }
  };
}

export function goToHelpCenterLandingPage() {
  return dispatch => {
    const destination = "/help-center";
    if (window.location.pathname !== destination) {
      history.push(destination);
      dispatch(batchActions([setHCActiveArticle(), setHCActiveSection()]));
      dispatch(loadHelpCenterLanding());
    }
  };
}

// ------------------------------------
// Actions
// ------------------------------------

export function updateHCSections(sections = {}) {
  return {
    type: UPDATE_HC_SECTIONS,
    payload: sections
  };
}

export function updateHCArticles(articles = {}) {
  Object.keys(articles).forEach(id => {
    const { content } = articles[id];
    if (content) {
      articles[id].content = articleContentProcessor.processSync(
        content
      ).contents;
    }
  });

  return {
    type: UPDATE_HC_ARTICLES,
    payload: articles
  };
}

export function updateHCSubsections(subsections = {}) {
  return {
    type: UPDATE_HC_SUBSECTIONS,
    payload: subsections
  };
}

export function updateHCLandingPage(landingPage = null) {
  return {
    type: UPDATE_HC_LANDINGPAGE,
    payload: landingPage
  };
}

export function setHCActiveArticle(id = null) {
  return {
    type: SET_HC_ACTIVE_ARTICLE,
    payload: id
  };
}

export function setHCActiveSection(id = null) {
  return {
    type: SET_HC_ACTIVE_SECTION,
    payload: id
  };
}

export function clearHCData() {
  return {
    type: CLEAR_HC_DATA
  };
}

// ------------------------------------
// Utilities
// ------------------------------------

// replaces image urls with the appropriate cloudinary url
function cloudinaryURLPlugin() {
  function transformer(tree) {
    visit(
      tree,
      node => node.tagName === "img",
      node => {
        const { src } = node.properties;
        if (src) {
          node.properties.src = editCloudinarySrc(
            src,
            "q_60,w_1920,c_limit",
            "jpg"
          );
        }
      }
    );
    return tree;
  }

  return transformer;
}

// extracts the content headers from the ast
function extractHeadersPlugin() {
  this.Compiler = compiler;

  let headers = [];
  function compiler(tree) {
    headers = [];

    // extracts the headers
    visit(
      tree,
      node => node.tagName === "h1",
      node => {
        const title = node.children[0].value;
        const link = encodeURI(title);
        headers.push({
          title,
          link
        });
        node.properties.id = link;
      }
    );

    return {
      headers,
      ast: tree
    };
  }
}

const articleContentProcessor = unified()
  .use(markdown)
  .use(remark2rehype, { allowDangerousHTML: true })
  .use(rehyperaw)
  .use(cloudinaryURLPlugin)
  .use(extractHeadersPlugin);
