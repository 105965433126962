import React from "react";
import PropTypes from "prop-types";
import { makeSelectedOutputs } from "selectors/outputSelectors";
import { connect } from "react-redux";
import { Transition, animated } from "react-spring";
import dotProp from "dot-prop";
import R from "ramda";
import CareFolder from "./CareFolder";
import { getMcgPlugin } from "integrations/mcg/mcgSelectors";
import { getConfigByOrgSlug } from "helpers/config";

class CareDecisionByCategory extends React.Component {
  render() {
    const {
      careFolders,
      careUsages,
      selectedSelectionIds,
      showComments,
      mcgSelectionTrigger,
      user
    } = this.props;
    const filteredCarefolders = careFolders.filter(
      cf => this.props.careFoldersStates[cf.id].shouldShow
    );
    const config = getConfigByOrgSlug(dotProp.get(user, "organization.slug"));
    const hideReasonForDecision = config.hideReasonForDeviation;

    // needs to be outside of the map so that the value persists across iterations
    let indexAtHasSelection;
    return (
      <Transition
        from={{ height: 0 }}
        keys={filteredCarefolders.map(cf => cf.id)}
        initial={{ height: "auto" }}
        enter={{ height: "auto" }}
        leave={{ height: 0 }}
        native
      >
        {filteredCarefolders.map((folder, folderIndex) => styles => {
          const hasSelection =
            folder.usages &&
            folder.usages.some(usage => {
              return (
                selectedSelectionIds.includes(usage.selectionId) &&
                usage.selectionId === mcgSelectionTrigger
              );
            });
          if (hasSelection) {
            indexAtHasSelection = folderIndex;
          }
          const couldShowMcgLaunchButton =
            folderIndex === indexAtHasSelection + 1;
          return (
            <animated.div
              className="care-category_folder"
              key={folder.id}
              style={styles}
            >
              <CareFolder
                hideReasonForDecision={hideReasonForDecision}
                careUsages={careUsages}
                folder={folder}
                folderIndex={folderIndex}
                key={folder.id}
                careFoldersStates={this.props.careFoldersStates}
                selectedSelectionIds={selectedSelectionIds}
                showComments={showComments}
                couldShowMcgLaunchButton={couldShowMcgLaunchButton}
              />
            </animated.div>
          );
        })}
      </Transition>
    );
  }
}

CareDecisionByCategory.propTypes = {
  careUsages: PropTypes.array,
  careFolders: PropTypes.array,
  selectedSelectionIds: PropTypes.array,
  careFoldersStates: PropTypes.object,
  user: PropTypes.object,
  showComments: PropTypes.bool,
  mcgSelectionTrigger: PropTypes.string
};
const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const getSelectedOutputs = makeSelectedOutputs();
    const activeSelectedOutputs = getSelectedOutputs(state);
    /**
     * TODO move these to selectors
     */
    const selectedSelectionIds = R.uniq(
      Object.keys(activeSelectedOutputs).map(
        opId => activeSelectedOutputs[opId].selectionId
      )
    );
    const careFoldersStates = props.careFolders.reduce((acc, cf) => {
      acc[cf.id] = {
        ...cf,
        shouldShow: cf.allUsagesHaveCriteria
          ? cf.parentSelectionIds.some(psId =>
              selectedSelectionIds.includes(psId)
            )
          : true
      };
      return acc;
    }, {});
    const mcgPlugin = getMcgPlugin(state);
    return {
      activeSelectedOutputs,
      selectedSelectionIds,
      careFoldersStates,
      user: state.user,
      showComments: state.comments.showComments,
      mcgSelectionTrigger: dotProp.get(
        mcgPlugin,
        "plugin.selectionIdTrigger",
        ""
      )
    };
  };
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(CareDecisionByCategory);
