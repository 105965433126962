import React from "react";
import PropTypes from "prop-types";

const ContextMenuItem = props => {
  return (
    <li className="context-menu_item" onClick={props.action}>
      {props.label}
    </li>
  );
};
ContextMenuItem.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string
};
export default ContextMenuItem;
