import { pick } from "ramda";

import { makeEcRequest } from "./base";
import { getConfigByOrgSlug } from "helpers/config";

//
// Profiles
//

export const fetchBootStrapInfoApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "bootstrap"
  });
};

export const updateProfileApi = data => {
  return makeEcRequest({
    method: "PATCH",
    location: "profile/me",
    body: extractPatchProfileData(data)
  });
};

export const createFhirProfileApi = (data, slug) => {
  try {
    const config = getConfigByOrgSlug(slug);
    return makeEcRequest({
      method: "POST",
      location: "profile/me/providerprofile",
      body: { data: data, source: config.profile_source }
    });
  } catch (err) {
    return null;
  }
};

export const fetchAccountProfileApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "profile/me"
  });
};

export const fetchCanonicalProfileApi = () => {
  return makeEcRequest({
    method: "GET",
    location: "profile/me/canonicalprofile"
  });
};

export const fetchSchoolsApi = ({ zipcode, radius = "100" }) => {
  return makeEcRequest({
    method: "GET",
    location: `profile/school?zipcode=${zipcode}&radius=${radius}`
  });
};

export const fetchSpecialtiesApi = () => {
  return makeEcRequest({
    method: "GET",
    location: `profile/specialty?page_size=100`
  });
};

export const fetchWorkplacesApi = ({ zipcode, radius = "100" }) => {
  return makeEcRequest({
    method: "GET",
    location: `profile/workplace?zipcode=${zipcode}&radius=${radius}`
  });
};

/*****************************************
 * Utilities
 *****************************************/

const PATCH_PROFILE_FIELDS = [
  "zipcode",
  "provider_type",
  "specialty",
  "school",
  "degree",
  "email",
  "workplaces",
  "preferences",
  "cloudinary_id",
  "npi"
];
const extractPatchProfileData = data => pick(PATCH_PROFILE_FIELDS, data);
