import {
  IMAGING_ADVISOR,
  ANTIBIOTIC_ADVISOR,
  CALCULATORS,
  ADMISSION
} from "constants/protocolCriteriaTypes";

// this maps a feature flag to a protocol type that the feature flag unlocks
export const contentProtocolMap = {
  [IMAGING_ADVISOR]: "imaging-advisor",
  [ADMISSION]: "mcg"
};

// todo this is definitely the right approach for customizing builds (in terms of the layer of abstraction)
// however, this should really be done at compile time rather than at runtime so we can have the lightest builds
// possible
export const getConfigByOrgSlug = (orgSlug = "default") => {
  // default config affects fhir and non fhir builds
  const defaultConfig = {
    ehr_description: "CDS Summary",
    pe_description: "Patient Care Summary",
    help_url: "https://evidence.care/help-center-EC/",
    hideProtocolCategories: [IMAGING_ADVISOR, ADMISSION],
    showHamburgerMenu: true,
    showProfileMenu: true,
    showProfileMenuItem: true,
    showHelpCenterMenuItem: true,
    showSentryTestMenuItem: false,
    showCmeTracker: true,
    disableNextOnDeviation: false,
    hideReasonForDeviation: false
  };

  // default fhir config only affects fhir builds
  const defaultFhirConfig = {
    ord_canSubmit: true,
    showHamburgerMenu: true,
    showProfileMenu: false,
    showProfileMenuItem: true,
    showHelpCenterMenuItem: false,
    showSentryTestMenuItem: false,
    showCmeTracker: false,
    disableNextOnDeviation: false,
    hideReasonForDeviation: false,
    help_url: "https://evidence.care/help-center-EC/",
    hideProtocolCategories: [
      ANTIBIOTIC_ADVISOR,
      CALCULATORS,
      IMAGING_ADVISOR,
      ADMISSION
    ]
  };

  const config =
    {
      medstar: {
        ...defaultConfig,
        disableNextOnDeviation: true,
        help_url:
          'javascript:APPLINK("100", "https://evidence.care/help-center-medstar/", "")',
        ...(__FHIR__ && {
          ...defaultFhirConfig,
          disableNextOnDeviation: true,
          external_userID: "1",
          ehr_loinc: "34133-9",
          pe_loinc: "18842-5",
          profile_source: "cerner-smart"
        })
      },
      "t-system": {
        ...defaultConfig,
        ...(__FHIR__ && {
          ...defaultFhirConfig,
          external_userID: "1",
          ehr_loinc: "34133-9",
          pe_loinc: "18842-5",
          profile_source: "tsystem-smart"
        })
      },
      "ec-tests": {
        ...defaultConfig,
        ...(__FHIR__ && {
          ...defaultFhirConfig,
          external_userID: "1",
          ehr_loinc: "11506-3",
          pe_loinc: "11506-3",
          profile_source: "ec-smart"
        })
      },
      default: {
        ...defaultConfig,
        ...(__FHIR__ && {
          ...defaultFhirConfig,
          ord_canSubmit: false,
          showHamburgerMenu: true,
          showProfileMenu: true
        })
      }
    }[orgSlug || "default"] || defaultConfig;

  // FHIR env overrides by env
  if (__FHIR__) {
    // if (__DEV__) {}
    // if (__ALPHA__) {}
    // if (__PROD__) {}
  } else {
    // web overrides by env
    if (__DEV__) {
      config.showSentryTestMenuItem = true;
    }
    if (__ALPHA__) {
      config.showSentryTestMenuItem = true;
    }
    // if (__PROD__) {}
  }

  return config;
};
