// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_TOGGLE = "MODAL_TOGGLE";
export const SNACKBAR_SET = "SNACKBAR_SET";
export const LOADER_SHOW = "LOADER_SHOW";
export const LOADER_HIDE = "LOADER_HIDE";
export const MESSAGE_SHOW = "MESSAGE_SHOW";
export const QUICK_SELECT_SHOW = "QUICK_SELECT_SHOW";
export const QUICK_SELECT_HIDE = "QUICK_SELECT_HIDE";
export const OVERLAY_SHOW = "OVERLAY_SHOW";
export const OVERLAY_HIDE = "OVERLAY_HIDE";
export const CDS_MODE = "CDS_MODE";

// ------------------------------------
// Actions
// ------------------------------------
export function toggleModal(payload) {
  return {
    type: MODAL_TOGGLE,
    payload: {
      modal: payload
    }
  };
}

export function showSnackbar(payload) {
  return {
    type: SNACKBAR_SET,
    payload: {
      snackbar: {
        isHidden: false,
        message: payload.message
      }
    }
  };
}

export function clearSnackbar(payload) {
  return {
    type: SNACKBAR_SET,
    payload: {
      snackbar: {
        isHidden: true,
        message: ""
      }
    }
  };
}

export function showLoader() {
  return {
    type: LOADER_SHOW
  };
}

export function hideLoader() {
  return {
    type: LOADER_HIDE
  };
}

export function showMessage() {
  return {
    type: MESSAGE_SHOW
  };
}

export function showQuickSelect() {
  return {
    type: QUICK_SELECT_SHOW
  };
}

export function hideQuickSelect() {
  return {
    type: QUICK_SELECT_HIDE
  };
}

export function showOverlay({ handleOverlayClick, className }) {
  return {
    type: OVERLAY_SHOW,
    payload: { visible: true, handleOverlayClick, className }
  };
}

export function hideOverlay() {
  return {
    type: OVERLAY_HIDE,
    payload: {
      visible: false
    }
  };
}

export function setCDSMode(appContext) {
  return {
    type: CDS_MODE,
    payload: appContext ? true : false
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [MODAL_TOGGLE]: (state, action) => Object.assign({}, state, action.payload),
  [SNACKBAR_SET]: (state, action) => Object.assign({}, state, action.payload),
  [LOADER_SHOW]: (state, action) => Object.assign({}, state, { loader: true }),
  [LOADER_HIDE]: (state, action) => Object.assign({}, state, { loader: false }),
  [MESSAGE_SHOW]: (state, action) =>
    Object.assign({}, state, { message: true }),
  [QUICK_SELECT_SHOW]: (state, action) =>
    Object.assign({}, state, { quickSelect: true }),
  [QUICK_SELECT_HIDE]: (state, action) =>
    Object.assign({}, state, { quickSelect: false }),
  [OVERLAY_SHOW]: (state, action) =>
    Object.assign({}, state, { overlay: action.payload }),
  [OVERLAY_HIDE]: (state, action) =>
    Object.assign({}, state, { overlay: action.payload }),
  [CDS_MODE]: (state, action) =>
    Object.assign({}, state, { cdsMode: action.payload })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  modal: {
    isHidden: true,
    message: null
  },
  snackbar: {
    isHidden: true,
    message: ""
  },
  loader: false,
  message: false,
  quickSelect: false,
  overlay: {
    visible: false
  },
  cdsMode: false
};
export default function uiReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
