import React, { Component } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import { connect } from "react-redux";
import dotProp from "dot-prop";
import classNames from "classnames";
import ManagePrescription from "./ManagePrescription";
import { showModal } from "store/modal/actions";
import ContextMenu from "components/ui/ContextMenu";
import ContextMenuItem from "components/ui/ContextMenu/ContextMenuItem";
import Measure from "react-measure";
import CouponComponent from "./CouponComponent";
import {
  setRxSetSelectedState,
  setRxSetMultipleSelectedState
} from "store/session";
import { Tooltip } from "components/ui/tooltip";
import CommentBubble from "components/protocol/comments/CommentBubble";
import { pipelineRequest } from "store/pipeline";
import { EVENT_FEATURE_ORDERS_SELECT } from "constants/broadcastEventTypes";
import { buildTooltipMessage } from "helpers/ehr/fhir";
import { FHIR_ORDERS_DRAFT_REVIEWER } from "constants/fhir";

export class RxSet extends Component {
  constructor(props) {
    super();
    this.isMultipleChoice = props.optionSet.choiceType === "MULTIPLE";
    const { prescriptions = [{}], singleMedMode } = this.setPrescriptions(
      props.prescriptionSet,
      props.prescriptionsWithProfiles
    );
    this.state = {
      prescriptions,
      selected: this.isMultipleChoice
        ? this.isCheckboxSelected(props)
        : this.isRadioSelected(props),
      singleMedMode,
      dimensions: {
        width: -1,
        height: -1
      },
      rxSetMeta: {}
    };
  }
  componentDidMount() {
    const rxSetMeta = R.find(
      R.propEq("id", this.state.prescriptions[0].medicationProfile.id),
      this.props.medicationProfileMeta
    );
    rxSetMeta && this.setState({ rxSetMeta });
  }
  isRadioSelected = props => {
    const orderMap = props.crosswalk;
    const isDraftReviewer =
      __FHIR__ && props.user.features.indexOf(FHIR_ORDERS_DRAFT_REVIEWER) > -1;
    const isInOptionSet = R.propEq("optionSetIndex", props.optionSetIndex);
    const hasOptionIndex = R.propEq("optionIndex", props.prescriptionSetIndex);
    const isInGroup = R.propEq("groupId", props.groupId);
    const optionInSession = R.find(
      R.allPass([isInOptionSet, hasOptionIndex, isInGroup])
    )(props.rxSetItemState);
    const optionWithMappedInfo = {
      ...optionInSession,
      isMapped:
        __FHIR__ &&
        optionInSession &&
        optionInSession.prescriptions.every(
          prescription =>
            orderMap[prescription] && orderMap[prescription].data.synonym_id
        ),
      isDraft:
        __FHIR__ &&
        optionInSession &&
        optionInSession.prescriptions.every(
          prescription =>
            !!orderMap[prescription] &&
            !!orderMap[prescription].data.draft_order &&
            orderMap[prescription].data.draft_order === "true"
        )
    };
    const isSelected = __FHIR__
      ? optionWithMappedInfo &&
        optionWithMappedInfo.isSelected &&
        ((optionWithMappedInfo.isMapped && !optionWithMappedInfo.isDraft) ||
          (optionWithMappedInfo.isMapped &&
            optionWithMappedInfo.isDraft &&
            isDraftReviewer))
      : optionWithMappedInfo && optionWithMappedInfo.isSelected;

    return isSelected;
  };
  isCheckboxSelected = props => {
    const isInOptionSet = R.propEq("optionSetIndex", props.optionSetIndex);
    const hasOptionIndex = R.propEq("optionIndex", props.prescriptionSetIndex);
    const optionObj = R.find(R.allPass([isInOptionSet, hasOptionIndex]))(
      props.rxSetItemState
    );
    return optionObj ? optionObj.selected : false;
  };
  componentWillReceiveProps(nextProps) {
    if (!R.equals(this.props.rxSetItemState, nextProps.rxSetItemState)) {
      this.setState({
        selected: this.isMultipleChoice
          ? this.isCheckboxSelected(nextProps)
          : this.isRadioSelected(nextProps)
      });
    }
  }

  updateRxSet = () => {
    const {
      groupId,
      rxSetId,
      optionSetIndex,
      prescriptionSetIndex,
      crosswalk,
      pipelineRequest,
      user
    } = this.props;
    const orderMap = __FHIR__ && crosswalk;
    const isMapped =
      __FHIR__ &&
      this.state.prescriptions.map(
        prescription =>
          orderMap[prescription.id] && orderMap[prescription.id].data.synonym_id
      );
    const isDraftReviewer =
      __FHIR__ && user.features.indexOf(FHIR_ORDERS_DRAFT_REVIEWER) > -1;
    const isDraft =
      __FHIR__ &&
      this.state.prescriptions.map(
        prescription =>
          !!orderMap[prescription.id] &&
          !!orderMap[prescription.id].data.draft_order &&
          orderMap[prescription.id].data.draft_order === "true"
      );

    pipelineRequest({
      action: EVENT_FEATURE_ORDERS_SELECT,
      message: {
        prescriptionset_id: rxSetId,
        prescriptions_ids: this.state.prescriptions.map(script => script.id)
      }
    });
    const optionSetObj = {
      rxSetId,
      groupId,
      prescriptions: this.state.prescriptions.map(script => script.id),
      optionSetIndex,
      optionIndex: prescriptionSetIndex,
      selected: __FHIR__
        ? ((isMapped && !isDraft) ||
            (isMapped && isDraft && isDraftReviewer)) &&
          this.state.selected
        : this.state.selected,
      isMapped:
        (isMapped && !isDraft) || (isMapped && isDraft && isDraftReviewer)
    };

    if (this.isMultipleChoice) {
      this.props.setRxSetMultipleSelectedState(optionSetObj);
    } else {
      this.props.setRxSetSelectedState(optionSetObj);
    }
  };
  setPrescriptions = (prescriptionSet, prescriptionsWithProfiles) => {
    let meds = [];
    const prescriptions = prescriptionSet.prescriptions.map(id => {
      const prescriptionObj = R.find(
        R.propEq("id", id),
        prescriptionsWithProfiles
      );
      meds.push(prescriptionObj.medicationProfile.id);
      return prescriptionObj;
    });
    const singleMedMode =
      meds.length > 1 ? R.all(R.equals(meds[0]), meds) : false;
    return { prescriptions, singleMedMode };
  };
  showTarasconModal = usbmisId => {
    return () => {
      this.props.showModal("TARASCON", { usbmisId });
    };
  };
  showGoodRxModal = (goodRxUrl, medProfileId) => {
    return () => {
      this.props.showModal("GOODRX", {
        goodRxUrl,
        medProfileId
      });
    };
  };
  eliquisCouponLink = url => {
    return () => {
      window.open(url);
    };
  };
  render() {
    const { prescriptionSet, user, sortBy } = this.props;
    const { selected, prescriptions, singleMedMode, rxSetMeta } = this.state;
    const { width } = this.state.dimensions;
    const prescriptionSetClasses = classNames(
      "rx-set",
      "row",
      "collapse",
      "align-middle",
      {
        "rx-set--selected": selected,
        "rx-set--small": width < 360,
        "rx-set--medium": width > 359
      }
    );
    const showEliquis =
      prescriptions[0].medicationProfile.genericName.toLowerCase() ===
      "apixaban";

    const findByObjectId = id => obj =>
      R.findLast(
        R.o(R.propEq("object_id", id), R.flip(R.prop)(obj)),
        R.keys(obj)
      );

    // putting this logic here for now until we can re-wire it a bit... i think we need to move the "shouldShow" check inside of the prescriptions.map function to avoid this redundant check
    const sentMeds = __FHIR__ && this.props.sentMeds;
    const isSent = __FHIR__ && !!findByObjectId(prescriptions[0].id)(sentMeds);

    // if sortBy === 'selected' show only the selected.
    // but if sortBy !== 'selected' show whether or not selected
    const shouldShow =
      (sortBy === "selected" && selected) ||
      (sortBy === "sent" && isSent) ||
      sortBy === "recommended";
    const shouldShowContextMenu =
      rxSetMeta && (rxSetMeta.goodrxComparePriceUrl || rxSetMeta.usbmisId);
    return shouldShow ? (
      <Measure
        // id={`${rxSetId}-${prescriptionSet.id}-${key}`}
        bounds
        onResize={contentRect => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => (
          <div
            className={prescriptionSetClasses}
            ref={measureRef}
            data-flow-rxset-id={prescriptionSet.prescriptions.join("-")}
          >
            <div
              className="columns small-12 rx-set-content"
              onClick={sortBy !== "sent" ? this.updateRxSet : null}
            >
              <div className="rx-set_wrap rx-set_prescriptions-col">
                {prescriptions.map((prescription, prescriptionIndex) => {
                  const orderMap = __FHIR__ && this.props.crosswalk;
                  const sentMeds = __FHIR__ && this.props.sentMeds;
                  const sentMed =
                    sentMeds && findByObjectId(prescription.id)(sentMeds);

                  const isDraftReviewer =
                    __FHIR__ &&
                    user.features.indexOf(FHIR_ORDERS_DRAFT_REVIEWER) > -1;
                  const isDraft =
                    __FHIR__ &&
                    !!orderMap[prescription.id] &&
                    !!orderMap[prescription.id].data.draft_order &&
                    orderMap[prescription.id].data.draft_order === "true";
                  const isMapped =
                    __FHIR__ &&
                    !!orderMap[prescription.id] &&
                    !!orderMap[prescription.id].data.synonym_id;
                  const isSent = __FHIR__ && !!sentMed;
                  const sentAt =
                    __FHIR__ && (isSent ? sentMeds[sentMed].sent_at : "");
                  const selectClasses = classNames({
                    "rx-set_checkbox icon-checkbox":
                      this.isMultipleChoice && sortBy !== "sent",
                    "rx-set_radio icon-radio":
                      !this.isMultipleChoice && sortBy !== "sent"
                  });
                  const wrapperClasses = classNames({
                    "rx-set_presciption-wrapper": __FHIR__,
                    "rx-set_presciption-wrapper--draft": __FHIR__ && isDraft
                  });
                  const orderMessage = buildTooltipMessage(
                    isMapped,
                    isDraft,
                    isDraftReviewer
                  );

                  return (
                    <div
                      className={wrapperClasses}
                      key={prescription.id + prescriptionIndex}
                    >
                      <div className={selectClasses} />
                      <ManagePrescription
                        prescription={prescription}
                        key={prescription.id}
                        singleMedMode={singleMedMode}
                        prescriptionIndex={prescriptionIndex}
                        prescriptionSetMode={prescriptionSet.mode}
                        isMapped={isMapped}
                        isSent={isSent}
                        isDraft={isDraft}
                        isDraftReviewer={isDraftReviewer}
                        sentAt={sentAt}
                        orderMessage={orderMessage}
                        prescriptionSetMessage={prescriptionSet.message}
                      />
                    </div>
                  );
                })}
                <CommentBubble
                  style={{ marginTop: "1rem" }}
                  itemId={prescriptionSet.id}
                  type="prescriptionset"
                  locationDescription="Prescription Set"
                  internalName={this.props.internalName}
                  condensed
                />
              </div>
              <div className="rx-set_context-menu-col align-self-middle">
                {!showEliquis && rxSetMeta && rxSetMeta.goodrxComparePriceUrl && (
                  <Tooltip
                    className="rx-set_good-rx-coupon-tooltip"
                    place="top"
                    id="goodrx-icon-tooltip"
                    message="Click for drug pricing powered by GoodRx."
                    hideOnFhir
                  >
                    <div
                      className="rx-set_good-rx-coupon-container"
                      onClick={this.showGoodRxModal(
                        rxSetMeta.goodrxComparePriceUrl,
                        prescriptions[0].medicationProfile.id
                      )}
                    >
                      <img
                        className="rx-set_good-rx-icon"
                        alt="good-rx-icon"
                        src="https://res.cloudinary.com/evidencecare/image/upload/v1530134578/tagalong-site-assets/goodrx_tag.png"
                      />
                      {rxSetMeta.outpatientPrice && (
                        <div className="rx-set_outpatient-price">
                          ${parseInt(rxSetMeta.outpatientPrice)}
                        </div>
                      )}
                    </div>
                  </Tooltip>
                )}
                {shouldShowContextMenu && (
                  <ContextMenu className="rx-set_context-menu">
                    {rxSetMeta && rxSetMeta.usbmisId && (
                      <ContextMenuItem
                        action={this.showTarasconModal(rxSetMeta.usbmisId)}
                        label="Tarascon"
                      />
                    )}
                    {rxSetMeta && rxSetMeta.goodrxComparePriceUrl && (
                      <ContextMenuItem
                        action={this.showGoodRxModal(
                          rxSetMeta.goodrxComparePriceUrl,
                          prescriptions[0].medicationProfile.id
                        )}
                        label="GoodRx"
                      />
                    )}
                  </ContextMenu>
                )}
              </div>
            </div>
            {showEliquis && (
              <CouponComponent
                dimensions={this.state.dimensions}
                session={this.props.session}
                updateRxSet={this.updateRxSet}
              />
            )}
          </div>
        )}
      </Measure>
    ) : null;
  }
}

RxSet.propTypes = {
  prescriptionSet: PropTypes.object,
  prescriptionsWithProfiles: PropTypes.array,
  optionSet: PropTypes.object,
  groupId: PropTypes.string,
  rxSetId: PropTypes.string,
  optionSetIndex: PropTypes.number,
  prescriptionSetIndex: PropTypes.number,
  showModal: PropTypes.func,
  session: PropTypes.object,
  setRxSetSelectedState: PropTypes.func,
  setRxSetMultipleSelectedState: PropTypes.func,
  sortBy: PropTypes.string,
  user: PropTypes.object,
  medicationProfileMeta: PropTypes.array,
  rxSetItemState: PropTypes.array,
  crosswalk: PropTypes.object,
  sentMeds: PropTypes.object,
  internalName: PropTypes.string,
  pipelineRequest: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  session: state.session,
  rxSetItemState:
    dotProp.get(state, `session.rxSetItems.${props.rxSetId}`) || [],
  medicationProfileMeta: state.smallLists.medProfileMeta.data,
  crosswalk: state.FHIR_orders.crosswalk,
  sentMeds: state.FHIR_orders.sentMeds
});
const mapDispatchToProps = {
  showModal,
  setRxSetSelectedState,
  setRxSetMultipleSelectedState,
  pipelineRequest
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RxSet);
