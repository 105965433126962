import { ADD_ASSOCIATIONS } from "./constants";
import { fetchAssociationsApi } from "lib/ec/account";

// ------------------------------------
// Thunks
// ------------------------------------

// load visible associations
export function loadAssociations() {
  return dispatch => {
    fetchAssociationsApi().then(({ data }) => {
      dispatch(addAssociations(data.results));
    });
  };
}

// ------------------------------------
// Actions
// ------------------------------------
export function addAssociations(associations = []) {
  return {
    type: ADD_ASSOCIATIONS,
    payload: associations
  };
}
