import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import dotProp from "dot-prop";
import MoreInfoIcon from "components/protocol/sections/moreInfo/MoreInfoIcon";
import { Tooltip } from "components/ui/tooltip";

export class QuestionOption extends Component {
  static propTypes = {
    option: PropTypes.object,
    selectAnswer: PropTypes.func,
    outputIsSelected: PropTypes.bool,
    selectedByObservation: PropTypes.bool,
    usageId: PropTypes.string
  };

  render() {
    const { option, selectAnswer, selectedByObservation } = this.props;
    const answerSelectTextClasses = classNames(
      "answer-select_text",
      `answer-select_text--${option.severity}`
    );
    const radioClasses = classNames("answer-select_icon", {
      "icon-radio": !this.props.outputIsSelected,
      "icon-radio-selected": this.props.outputIsSelected
    });
    return (
      <div
        key={option.id}
        className="row collapse question_option align-middle"
        onClick={selectAnswer(option)}
      >
        <div className="columns shrink">
          <div className={radioClasses} />
        </div>
        <div className="columns">
          <div className={answerSelectTextClasses}>
            <span>{option.displayName || option.selection.displayName}</span>
            {selectedByObservation && (
              <span className="answer-select_observation-icon-wrap">
                <Tooltip
                  id="ehr-doc-tooltip"
                  message="This selection was populated from the EHR"
                  place="top"
                >
                  <i className="icon-ehr-information answer-select_observation-icon" />
                </Tooltip>
              </span>
            )}
          </div>
        </div>
        {option.output.evidence && (
          <div className="columsn shrink">
            {option.output.evidence && (
              <MoreInfoIcon
                evidence={option.output.evidence}
                usageId={this.props.usageId}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  outputIsSelected: dotProp.get(
    state,
    `session.entities.outputs.${props.option.output.id}.selected`
  ),
  selectedByObservation: dotProp.get(
    state,
    `session.entities.selections.${props.option.output.selectionId}.selectedByObservation`
  )
});

export default connect(mapStateToProps)(QuestionOption);
