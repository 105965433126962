import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Review from "./Review";
// import dotProp from 'dot-prop'
// import R from 'ramda'
import { showReview, hideReview } from "store/review";
export class ReviewToggleVisableButton extends Component {
  static propTypes = {
    enabled: PropTypes.bool,
    visable: PropTypes.bool,
    showReview: PropTypes.func,
    hideReview: PropTypes.func
  };
  toggleVisable = () => {
    const { visable, showReview, hideReview } = this.props;
    visable ? hideReview() : showReview();
  };
  render() {
    const { visable } = this.props;

    return this.props.enabled ? (
      <React.Fragment>
        <button className="button button_standard" onClick={this.toggleVisable}>
          {visable ? "Hide Review" : "Show Review"}
        </button>
        <div>{visable && <Review />}</div>
      </React.Fragment>
    ) : null;
  }
}
const mapStateToProps = state => ({
  visable: state.review.visable,
  enabled: state.review.enabled
});
const mapDispatchToProps = {
  showReview,
  hideReview
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewToggleVisableButton);
