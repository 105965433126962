import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const withBottomDrawer = WrappedComponent => {
  return class WithDrawer extends Component {
    propTypes = {
      drawerIsOpen: PropTypes.bool,
      toggleDrawer: PropTypes.func,
      headerTitle: PropTypes.string
    };
    render() {
      const { drawerIsOpen, toggleDrawer, headerTitle } = this.props;
      const indicatorClasses = classNames("bottom-drawer_container", {
        "bottom-drawer_container--isOpen": drawerIsOpen,
        "bottom-drawer_container--fhir": drawerIsOpen && __FHIR__,
        "": !drawerIsOpen
      });
      const topDivIndicatorClasses = classNames("bottom-drawer_top-div", {
        "bottom-drawer_top-div--isOpen": drawerIsOpen,
        "": !drawerIsOpen
      });
      return (
        <Fragment>
          <div onClick={toggleDrawer} className={topDivIndicatorClasses} />
          <div className={indicatorClasses}>
            <div onClick={toggleDrawer} className="bottom-drawer_header">
              <div className="bottom-drawer_header-content">
                <div className="bottom-drawer_hamburger-container">
                  <div className="bottom-drawer_hamburger-icon-row" />
                  <div className="bottom-drawer_hamburger-icon-row" />
                  <div className="bottom-drawer_hamburger-icon-row" />
                </div>
                <span className="bottom-drawer_text">{headerTitle}</span>
              </div>
            </div>
            <div className="bottom-drawer_content">
              <WrappedComponent {...this.props} />
            </div>
          </div>
        </Fragment>
      );
    }
  };
};

export default withBottomDrawer;
