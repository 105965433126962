export const ecCouponSrc = `${CLOUDINARY_URL}/evidencecare/image/upload/v1508270625/tagalong-site-assets/ec-coupon-card2.png`;

export const visaSrc = `${CLOUDINARY_URL}/evidencecare/image/upload/v1508270625/tagalong-site-assets/visa.png`;
export const mastercardSrc = `${CLOUDINARY_URL}/evidencecare/image/upload/v1508270625/tagalong-site-assets/mastercard.png`;
export const paypalSrc =
  "https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png";
export const discoverSrc = `${CLOUDINARY_URL}/evidencecare/image/upload/v1508270625/tagalong-site-assets/dicover.png`;
export const amexSrc = `${CLOUDINARY_URL}/evidencecare/image/upload/v1508270625/tagalong-site-assets/amex.png`;
export const eliquisSrc = "tagalong-site-assets/eliquis";
export const goodRxSrc = `//${CLOUDINARY_HOST}/evidencecare/image/upload/v1528914863/tagalong-site-assets/goodrx.png`;
export const tarasconSrc = `//${CLOUDINARY_HOST}/evidencecare/image/upload/v1508270625/tagalong-site-assets/tarascon_logo.png`;
