import decode from "jwt-decode";

import {
  SET_AUTH0,
  CLEAR_AUTH,
  SET_IS_FETCHING_EC_TOKEN,
  SET_EC_TOKEN,
  SET_FHIR,
  REFRESH_FHIR,
  SET_REDIRECT_LOCATION,
  CLEAR_REDIRECT_LOCATION,
  SET_SIGNUP_CODE
} from "./constants";

// ------------------------------------
// Reducer
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_AUTH0]: (state, { payload = {} }) => {
    return {
      ...state,
      auth0: {
        ...payload,
        data: decode(payload.idToken)
      }
    };
  },
  [CLEAR_AUTH]: () => initialState,
  [SET_IS_FETCHING_EC_TOKEN]: (state, { payload }) => ({
    ...state,
    ecToken: { ...state.ecToken, isFetching: payload }
  }),
  [SET_EC_TOKEN]: (state, { payload }) => {
    return {
      ...state,
      ecToken: {
        token: payload,
        isFetching: false,
        data: decode(payload)
      }
    };
  },
  [SET_FHIR]: (state, { payload }) => {
    const {
      tokenResponse: { id_token, refresh_token, access_token, scope },
      state: {
        provider: {
          oauth2: { token_uri }
        },
        client: { client_id }
      },
      expires_in = 3600
    } = payload;

    return {
      ...state,
      fhir: {
        idToken: id_token,
        refreshToken: refresh_token,
        accessToken: access_token,
        tokenURI: token_uri,
        clientId: client_id,
        scope,
        // EPIC doesn't use JWTs - todo we should abstract out the decoding into a separate method that handles all the environments and scenarios
        accessTokenData:
          FHIR_ENVIRONMENT === "epic"
            ? { exp: Math.floor(Date.now() / 1000) + expires_in }
            : decode(access_token),
        idTokenData: id_token ? decode(id_token) : {}
      }
    };
  },
  [REFRESH_FHIR]: (state, { payload }) => {
    const { access_token, id_token, expires_in = 3600 } = payload;
    return {
      ...state,
      fhir: {
        ...state.fhir,
        idToken: id_token,
        accessToken: access_token,
        accessTokenData:
          FHIR_ENVIRONMENT === "epic"
            ? { exp: Math.floor(Date.now() / 1000) + expires_in }
            : decode(access_token),
        idTokenData: id_token ? decode(id_token) : {}
      }
    };
  },
  [SET_REDIRECT_LOCATION]: (state, { payload }) => ({
    ...state,
    redirect: {
      location: payload,
      expireTime: Date.now() + 5 * 60 * 1000
    }
  }),
  [CLEAR_REDIRECT_LOCATION]: state => ({
    ...state,
    redirect: initialState.redirect
  }),
  [SET_SIGNUP_CODE]: (state, { payload }) => ({ ...state, signupCode: payload })
};

const initialState = {
  auth0: {
    idToken: undefined,
    accessToken: undefined,
    data: {}
  },
  ecToken: {
    token: undefined,
    isFetching: false,
    data: {}
  },
  fhir: {
    idToken: undefined,
    refreshToken: undefined,
    clientId: undefined,
    tokenURI: undefined,
    scope: undefined,
    idTokenData: {},
    accessTokenData: {}
  },
  redirect: {
    location: null,
    expireTime: null
  },
  signupCode: undefined
};
export default function authReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
