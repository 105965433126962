import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import "./StepEmphasizer.scss";

export class StepEmphasizer extends React.Component {
  highlightRootEl = document.createElement("div");
  pollInterval = null;

  componentDidMount() {
    document.body.appendChild(this.highlightRootEl);
    this.pollInterval = setInterval(() => {
      this.forceUpdate();
    }, 50);
  }

  componentWillUnmount() {
    document.body.removeChild(this.highlightRootEl);
    clearInterval(this.pollInterval);
  }

  render() {
    const { target, borderColor, offset } = this.props;

    const targetEl = document.querySelector(target);

    if (!targetEl) return null;

    const { width, height, top, left } = targetEl.getBoundingClientRect();
    const sizeAdjustment = offset * 2;

    return ReactDOM.createPortal(
      <div
        className={"tour-emphasizer_box"}
        style={{
          border: `3px solid ${borderColor}`,
          width: `${width + sizeAdjustment}px`,
          height: `${height + sizeAdjustment}px`,
          top: `${top - offset}px`,
          left: `${left - offset}px`
        }}
      />,
      this.highlightRootEl
    );
  }
}

StepEmphasizer.propTypes = {
  target: PropTypes.string,
  borderColor: PropTypes.string,
  offset: PropTypes.number
};
