import { Prompt } from "react-router";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import classNames from "classnames";
import R from "ramda";
import { fetchMedicationProfileMeta } from "lib/ec/meds";
import { setMedicationsProfileMeta } from "store/listsSmall";
import { getFirstEndOfBranchSectionId } from "selectors/sectionSelectors";
import dotProp from "dot-prop";
import CommentSocket from "lib/commentSocket";
import CommentBubble from "components/protocol/comments/CommentBubble";
import { fetchProtocolPlugin } from "store/plugin";
import { selectCDSMode } from "store/ui/selectors.js";
import ReviewToggleVisableButton from "./review/ReviewToggleVisableButton";
import ProtocolContent from "./ProtocolContent";
import {
  hideCommentSidebar,
  setProtocol as setProtocolComments,
  connectSocketMessageHandler,
  turnOnComments,
  highlightComment
} from "store/comments";
import { showLoader, hideLoader } from "store/ui";
import {
  clearSession,
  updateSessionProp,
  createSession,
  rehydrateSession,
  setOutputTab
} from "store/session";
import {
  clearProtocol,
  setProtocolProp,
  protocolAddProtocol,
  setSections,
  addProtocolSection,
  fetchSectionsFromSession,
  setSectionVersions,
  protocolLaunchProtocol,
  setProtocolContent,
  clearActiveMoreInfo
} from "store/protocol";
import { clearOrders } from "store/FHIR_orders";

import { showModal } from "store/modal/actions";
import { updateCMETotal } from "store/account";
import { fetchProtocol, fetchProtocolRenderedDocument } from "lib/ec/protocol";
import { HIDE_ALL_RIGHT_COLUMNS } from "constants/fhir";
import { selectFeatureFlags } from "store/user/selectors";
import withPreventBackwardNavInCDS from "components/global/withPreventBackwardNavInCDS";
import Patient from "components/protocol/PatientDrawer/PatientDrawer";
import { FHIR_FLAGS_HAS_PATIENT_DRAWER } from "../../constants/fhir";

class Protocol extends Component {
  state = {
    expandDropDown: false
  };
  componentDidMount() {
    const {
      commentsByProtocolId,
      ecToken,
      showComments,
      connectSocketMessageHandler,
      setProtocolComments,
      match
    } = this.props;

    showComments &&
      connectSocketMessageHandler({
        protocolId: match.params.protocolId
      });
    const protocolId = match.params.protocolId;
    this.initiateProtocol();
    if (showComments && protocolId && !commentsByProtocolId[protocolId]) {
      protocolId &&
        axios({
          method: "GET",
          url: `${COMMENT_SERVICE}/protocol/${protocolId}`,
          headers: {
            Authorization: "Bearer " + ecToken,
            "Content-Type": "application/json"
          }
        }).then(resp => {
          setProtocolComments({ protocolId, data: resp.data });
        });
    }
  }
  componentDidUpdate(prevProps) {
    const commentsTurnedOn =
      this.props.showComments && prevProps.showComments === false;
    if (commentsTurnedOn) {
      this.props.showComments &&
        this.props.connectSocketMessageHandler({
          protocolId: this.props.match.params.protocolId
        });
    }
    if (
      commentsTurnedOn ||
      (this.props.showComments &&
        this.props.protocolsOrder !== prevProps.protocolsOrder)
    ) {
      CommentSocket.updateProtocols();
      const newProtocols = commentsTurnedOn
        ? this.props.protocolsOrder
        : R.difference(this.props.protocolsOrder, prevProps.protocolsOrder);

      if (newProtocols.length > 0) {
        const promises = newProtocols.map(protocolId => {
          return axios({
            method: "GET",
            url: `${COMMENT_SERVICE}/protocol/${protocolId}`,
            headers: {
              Authorization: "Bearer " + this.props.ecToken,
              "Content-Type": "application/json"
            }
          });
        });
        // console.log('newProtocols', newProtocols)
        Promise.all(promises).then(vals => {
          vals.forEach((resp, i) => {
            // console.log('newProtocols[i]', newProtocols[i])
            this.props.setProtocolComments({
              protocolId: newProtocols[i],
              data: resp.data
            });
          });
        });
      }
    }
  }
  openOutputTab = outputTab => {
    return () => {
      this.props.clearActiveMoreInfo();
      this.props.setOutputTab({ outputTabName: outputTab });
    };
  };
  componentWillUnmount() {
    this.props.hideCommentSidebar();
    this.clearProtocol();
    this.props.updateCMETotal();
  }
  checkForMedProfileData = () => {
    const { medProfileMetaTimestamp, setMedicationsProfileMeta } = this.props;

    if (
      !medProfileMetaTimestamp ||
      Date.now() - medProfileMetaTimestamp > 600000
    ) {
      fetchMedicationProfileMeta(this.props.token).then(({ data }) => {
        setMedicationsProfileMeta({ medProfileMeta: data });
      });
    }
  };
  initiateProtocol = async (props = this.props) => {
    const { protocolId, sessionId: existingSessionId } = props.match.params;
    const ecToken = localStorage.getItem("ecToken");

    const {
      protocol,
      rehydrateSession,
      protocolLaunchProtocol,
      useProtocolVersion,
      setProtocolContent,
      fetchProtocolPlugin
    } = this.props;
    const hasExistingSession = !!existingSessionId;
    let params = new URL(document.location).searchParams;
    let commentId = params && params.get("loadComment");

    setTimeout(this.checkForMedProfileData, 4000);
    if (commentId) {
      // hit comment endpoint to get session
      this.props.turnOnComments();
      this.props.highlightComment({ commentId });
      const { data: sessionData } = await axios({
        method: "GET",
        url: `${COMMENT_SERVICE}/comments/${commentId}/session`,
        headers: {
          Authorization: "Bearer " + ecToken,
          "Content-Type": "application/json"
        }
      });

      delete sessionData.id;
      const newSession = await this.props.createSession({
        protocolId,
        slug: "slug",
        intialSession: sessionData
      });
      rehydrateSession({ sessionId: newSession.id }).then(session => {
        const fetchProtocolsPromises = R.values(session.entities.protocols).map(
          protocolSession => {
            return fetchProtocolRenderedDocument({
              url: protocolSession.latestVersion.renderedDocument
            });
          }
        );

        Promise.all(fetchProtocolsPromises).then(values => {
          values.forEach(({ data }) => {
            const protocolContent = data.protocol;
            protocolContent.sections = R.indexBy(
              R.prop("sectionId"),
              data.sectionVersions
            );
            protocolContent.id = data.protocol.id;
            protocolContent.latestVersion = data.protocol.latestVersion;

            setProtocolContent({ protocolContent });
          });
        });
      });

      // 'turn on' comments for app
      // highlight comment bubble
      // open comment thread on left
    } else if (protocol[protocolId] && !hasExistingSession) {
      // this is coming from a launch card
      // const approvalPoint = dotProp.get(protocol, `${protocolId}.latestVersion.approvalPoint`)
      const { slug } = this.props.match.params;
      /**
       * don't need to pass useProtocolVersion into create Session
       * as it's value is derived from the initial session state
       */
      const latestVersion = protocol[protocolId].latestVersion;
      this.props.createSession({ protocolId, slug, latestVersion }).then(() => {
        protocolLaunchProtocol({
          sectionId: protocol[protocolId].rootId,
          protocolId,
          latestVersion,
          useProtocolVersion
        });
      });
      fetchProtocolPlugin(protocolId);
    } else if (hasExistingSession) {
      // this means it's coming from a page refresh or a session launch
      rehydrateSession({ sessionId: existingSessionId }).then(session => {
        const fetchProtocolsPromises = R.values(session.entities.protocols).map(
          protocolSession => {
            return axios({
              method: "GET",
              url: protocolSession.latestVersion.renderedDocument
            });
          }
        );

        Promise.all(fetchProtocolsPromises).then(values => {
          values.forEach(({ data }) => {
            const protocolContent = data.protocol;
            protocolContent.sections = R.indexBy(
              R.prop("sectionId"),
              data.sectionVersions
            );
            protocolContent.id = data.protocol.id;
            protocolContent.latestVersion = data.protocol.latestVersion;

            setProtocolContent({ protocolContent });
            fetchProtocolPlugin(data.protocol.id);
          });
        });
      });
    } else {
      /**
       * this is coming from a url without a session param
       * We need to fetch the protocol first, before we check to see if it's in
       * the cache because we do not know the latest approvalPoint (version)
       */

      const { slug } = this.props.match.params;
      fetchProtocol({ protocolId }).then(({ data, latestVersion }) => {
        const protocolContent = {
          ...data.protocol,
          sections: R.indexBy(R.prop("sectionId"), data.sectionVersions),
          latestVersion: data.protocol.latestVersion || latestVersion
        };

        setProtocolContent({ protocolContent });
        this.props.createSession({ protocolId, slug }).then(() => {
          protocolLaunchProtocol({
            protocol: protocolContent,
            sectionId: protocolContent.rootId,
            protocolId: protocolContent.id,
            latestVersion: protocolContent.latestVersion,
            useProtocolVersion
          });
        });
      });
      fetchProtocolPlugin(protocolId);
    }
  };
  clearProtocol(props = this.props) {
    props.clearProtocol();
    props.clearSession();
    props.clearOrders();
    props.hideLoader();
  }

  setActiveProtocol = ({ protocolId }) => {
    return () => {
      if (this.props.protocols.length > 0) {
        this.props.updateSessionProp("activeProtocolId", protocolId);
      }
      if (this.state.expandDropDown) {
        this.setState({ expandDropDown: !this.state.expandDropDown });
      }
    };
  };

  handleToggleDropDown = ({ protocolId }) => {
    return () => {
      if (
        this.props.activeProtocolId === protocolId &&
        this.props.protocols.length > 1 &&
        window.innerWidth < 640
      ) {
        this.setState({ expandDropDown: !this.state.expandDropDown });
      } else {
        return null;
      }
    };
  };
  handleSegmentRef = c => {
    this.segmentRef = c;
  };

  protocolEl = () => {
    const {
      activeProtocolId,
      protocols,
      patientDrawerIsVisible,
      fhirCDS,
      userFeatures
    } = this.props;
    const hideRightColumns =
      userFeatures.includes(HIDE_ALL_RIGHT_COLUMNS) || fhirCDS;
    const tabsToggleClasses = classNames("protocol-icons", {
      "icon-expand": !this.state.expandDropDown,
      "icon-expanded": this.state.expandDropDown
    });

    const homeIconClasses = fhirCDS
      ? "protocol-top-bar_home icon-home protocol-top-bar_home-disabled "
      : "protocol-top-bar_home icon-home";
    const shouldShowPatientDrawer =
      (__DEV__ && __FHIR__ && FHIR_FLAGS_HAS_PATIENT_DRAWER) ||
      patientDrawerIsVisible;
    return (
      <>
        <div className="row collapse expanded protocol-row">
          <div className="columns">
            {__FHIR__ && __DEV__ && shouldShowPatientDrawer && (
              <Patient user={this.props.user} />
            )}
            <div className="protocol-top-bar">
              <Link to={fhirCDS ? "#" : "/"} className={homeIconClasses} />
              <div className="protocol-top-bar_protocol-list-container">
                <ul className="protocol-top-bar_protocol-list">
                  {activeProtocolId &&
                    protocols.map(protocol => {
                      const protocolId = protocol && protocol.id;
                      const protocolTabClasses = classNames(
                        "protocol-top-bar_protocol-item",
                        {
                          "protocol-top-bar_protocol-item--active":
                            activeProtocolId === protocolId,
                          "protocol-top-bar_protocol-item--expanded": this.state
                            .expandDropDown
                        }
                      );
                      const showDropDownToggle =
                        activeProtocolId === protocolId && protocols.length > 1;
                      const protocolTitleContainerClasses = classNames(
                        "protocol-top-bar_protocol-title-container",
                        {
                          "small-11 columns": showDropDownToggle,
                          "small-12 columns": !showDropDownToggle
                        }
                      );
                      const isAdmissionTypeProtocol =
                        protocol && protocol.type === "admission";
                      const subtitle = isAdmissionTypeProtocol
                        ? "Admission Advisor"
                        : protocol && protocol.subtitle;
                      return (
                        protocol && (
                          <Fragment key={protocol.id}>
                            <li
                              onClick={this.setActiveProtocol({
                                protocolId: protocol.id
                              })}
                              key={protocol.id}
                              className={protocolTabClasses}
                            >
                              <div
                                className="protocol-top-bar_protocol-item-container row collapse align-middle"
                                onClick={this.handleToggleDropDown({
                                  protocolId
                                })}
                              >
                                <div className={protocolTitleContainerClasses}>
                                  <div className="protocol-top-bar_protocol-item-title">
                                    {protocol.title}
                                  </div>
                                  {subtitle && (
                                    <div className="protocol-top-bar_protocol-item-subtitle">
                                      {subtitle}
                                    </div>
                                  )}
                                </div>
                                {showDropDownToggle && (
                                  <div className="small-1 columns protocol-top-bar_icon-container">
                                    <i className={tabsToggleClasses} />
                                  </div>
                                )}
                              </div>
                              <CommentBubble
                                style={{
                                  top: "25px",
                                  position: "absolute",
                                  right: "6px",
                                  fontSize: "16px",
                                  padding: "4px 5px",
                                  zIndex: "99"
                                }}
                                itemId={protocol.id}
                                type="protocol"
                                locationDescription="Protocol"
                                internalName={protocol.title}
                                condensed
                              />
                            </li>
                          </Fragment>
                        )
                      );
                    })}
                </ul>
              </div>
              <ReviewToggleVisableButton />
            </div>
            <ProtocolContent
              openOutputTab={this.openOutputTab}
              hideRightColumns={hideRightColumns}
              hideCommentSidebar={this.props.hideCommentSidebar}
              {...this.props}
            />
          </div>
        </div>
      </>
    );
  };
  render() {
    return (
      <>
        {__FHIR__ && !this.props.fhirCDS && (
          <Prompt
            message={location =>
              location.pathname === "/"
                ? `If you leave this protocol and start a new one, you will reset your patient session.`
                : true
            }
          />
        )}

        {this.protocolEl()}
      </>
    );
  }
}

Protocol.propTypes = {
  params: PropTypes.object,
  protocol: PropTypes.object,
  protocolAddProtocol: PropTypes.func,
  updateSessionProp: PropTypes.func,
  protocols: PropTypes.array,
  user: PropTypes.object,
  slug: PropTypes.string,
  createSession: PropTypes.func,
  rehydrateSession: PropTypes.func,
  fetchSectionsFromSession: PropTypes.func,
  activeProtocolId: PropTypes.string,
  token: PropTypes.string,
  match: PropTypes.object,
  updateCMETotal: PropTypes.func,
  setProtocolContent: PropTypes.func,
  medProfileMetaTimestamp: PropTypes.number,
  protocolLaunchProtocol: PropTypes.func,
  useProtocolVersion: PropTypes.bool,
  patientDrawerIsVisible: PropTypes.bool,
  setOutputTab: PropTypes.func,
  clearActiveMoreInfo: PropTypes.func,
  endOfBranchSectionId: PropTypes.string,
  hideCommentSidebar: PropTypes.func,
  showSidebar: PropTypes.bool,
  setProtocolComments: PropTypes.func,
  connectSocketMessageHandler: PropTypes.func,
  protocolsOrder: PropTypes.array,
  showComments: PropTypes.bool,
  commentsByProtocolId: PropTypes.object,
  ecToken: PropTypes.string,
  fetchProtocolPlugin: PropTypes.func,
  turnOnComments: PropTypes.func,
  highlightComment: PropTypes.func,
  setMedicationsProfileMeta: PropTypes.func,
  fetchProtocol: PropTypes.func,
  fhirCDS: PropTypes.bool,
  userFeatures: PropTypes.array
};

const mapStateToProps = state => {
  return {
    showSidebar: state.comments.showSidebar,
    showComments: state.comments.showComments,
    commentsByProtocolId: state.comments.protocol,
    protocol: state.protocol,
    token: state.user.token,
    launchCard: state.protocol.launchCard,
    user: state.user,
    activeProtocolId: state.session.activeProtocolId,
    protocols: state.session.protocolsOrder.map(
      protocolId => state.protocol[protocolId]
    ),
    medProfileMetaTimestamp: dotProp.get(
      state,
      "smallLists.medProfileMeta.timestamp"
    ),
    useProtocolVersion: state.session.useProtocolVersion,
    scope: dotProp.get(state, "account.ecToken.scope"),
    ecToken: state.account.ecToken.token,
    patientDrawerIsVisible: state.patient.patientDrawer.visible,
    endOfBranchSectionId:
      (state.session.activeProtocolId &&
        getFirstEndOfBranchSectionId(state, state.session.activeProtocolId)) ||
      undefined,
    protocolsOrder: state.session.protocolsOrder,
    fhirCDS: selectCDSMode(state),
    userFeatures: selectFeatureFlags(state)
  };
};

const mapDispatchToProps = {
  setProtocolProp,
  clearProtocol,
  clearSession,
  clearOrders,
  showLoader,
  hideLoader,
  createSession,
  protocolAddProtocol,
  showModal,
  updateCMETotal,
  setSections,
  addProtocolSection,
  rehydrateSession,
  fetchSectionsFromSession,
  updateSessionProp,
  setSectionVersions,
  setMedicationsProfileMeta,
  protocolLaunchProtocol,
  setProtocolContent,
  setOutputTab,
  clearActiveMoreInfo,
  hideCommentSidebar,
  setProtocolComments,
  connectSocketMessageHandler,
  fetchProtocolPlugin,
  turnOnComments,
  highlightComment
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withPreventBackwardNavInCDS
)(Protocol);
