import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { protocolLaunchProtocol } from "store/protocol";
export class ProtocolLaunchButton extends React.Component {
  handleClick = () => {
    const { launchButton, protocolLaunchProtocol } = this.props;
    const protocolId = launchButton.protocol.id;
    protocolLaunchProtocol({
      protocolId: protocolId,
      sectionId: launchButton.sectionId
    });
  };
  render() {
    const { launchButton } = this.props;

    return launchButton ? (
      <div className="row collapse align-middle protocol-launch">
        <div className="columns text-right protocol-launch_text">
          Launch Protocol:
          <br />
          <span className="protocol-launch_title">
            {launchButton.protocol.title}
          </span>
        </div>
        <div className="columns shrink">
          <div className="columns text-right">
            <button
              className="button button_standard protocol-launch_button"
              onClick={this.handleClick}
            >
              Launch
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => ({
  launchButton: state.protocol.launchButton
});
const mapDispatchToProps = {
  protocolLaunchProtocol
};
ProtocolLaunchButton.propTypes = {
  protocolLaunchProtocol: PropTypes.func,
  launchButton: PropTypes.object
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtocolLaunchButton);
