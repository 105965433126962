import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import McgRightColumnAccordian from "./McgRightColumnAccordian";
import { clearMetaData } from "../mcgReducer";
import { getAllMetaData } from "../mcgSelectors";

export class McgRightColumn extends Component {
  handleClose = () => {
    const { clearMetaData } = this.props;
    clearMetaData();
  };
  render() {
    const { isFetchingMetaData, allMetaData } = this.props;
    const shouldRenderRightColumn =
      allMetaData.length > 0 || isFetchingMetaData;
    return (
      shouldRenderRightColumn && (
        <div className="mcg-right-column">
          <div className="mcg-right-column_header">
            <div className="mcg-right-column_header-left">
              <span className="mcg-right-column_more-info icon-info" />
              <p>Guideline Information</p>
            </div>
            <div onClick={() => this.handleClose()}>
              <span className="mcg-right-column_header-close-icon icon-close" />
            </div>
          </div>
          {allMetaData.map(metaData => (
            <McgRightColumnAccordian key={metaData.Id} content={metaData} />
          ))}
          {isFetchingMetaData && (
            <div className="mcg-right-column_status-loader-container">
              <span className="mcg-right-column_status-loader mcg-definition-status-loader" />
            </div>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  allMetaData: getAllMetaData(state),
  isFetchingMetaData: state.mcg.isFetchingMeta
});

const mapDispatchToProps = {
  clearMetaData
};

McgRightColumn.propTypes = {
  allMetaData: PropTypes.array,
  isFetchingMetaData: PropTypes.bool,
  clearMetaData: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(McgRightColumn);
