import React from "react";
import PropTypes from "prop-types";

export default class AuthLayout extends React.PureComponent {
  componentDidMount() {
    document.body.classList.add("full-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("full-bg");
  }
  render() {
    return this.props.children;
  }
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
