import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { fetchAssociationsApi } from "lib/ec/account";
import { EVENT_FEATURE_MEMBERSHIPS_VIEW } from "constants/broadcastEventTypes";
import { sortAssociations } from "helpers/associations";
import { pipelineRequest } from "store/pipeline";
import Association from "./Association";

export class Memberships extends React.PureComponent {
  state = {
    privateAssociations: [],
    publicAssociations: []
  };

  componentDidMount() {
    this.fetchAndSortAssociations();
    this.props.pipelineRequest({
      action: EVENT_FEATURE_MEMBERSHIPS_VIEW
    });
  }

  fetchAndSortAssociations = () => {
    return fetchAssociationsApi({ pageSize: 100 }).then(data => {
      const associations = data.data.results;
      const sortedAssociations = sortAssociations(associations);
      this.setState({
        privateAssociations: sortedAssociations.priv,
        publicAssociations: sortedAssociations.pub
      });
    });
  };

  render() {
    const { publicAssociations, privateAssociations } = this.state;
    return (
      <div className="account-col">
        <div className="account_header">
          <h2 className="account_title">Memberships</h2>
        </div>
        <div className="memberships">
          {privateAssociations &&
            privateAssociations.map(ass => (
              <Association
                fetchAssociations={this.fetchAndSortAssociations}
                ass={ass}
                key={ass.id}
              />
            ))}
          {publicAssociations &&
            publicAssociations.map(ass => (
              <Association
                fetchAssociations={this.fetchAndSortAssociations}
                ass={ass}
                key={ass.id}
              />
            ))}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  pipelineRequest
};

Memberships.propTypes = {
  pipelineRequest: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(Memberships);
