import * as immutable from "object-path-immutable";
import dotProp from "dot-prop";
import CommentSocket from "lib/commentSocket";

// ------------------------------------
// Constants
// ------------------------------------
export const COMMENTS_SET_PROTOCOL = "COMMENTS_SET_PROTOCOL";
export const COMMENTS_SET_ACTIVE_THREAD = "COMMENTS_SET_ACTIVE_THREAD";
export const COMMENTS_SET_LOADER = "COMMENTS_SET_LOADER";
export const COMMENTS_SET_SHOW_STATE = "COMMENTS_SET_SHOW_STATE";
export const COMMENTS_SET_SIDEBAR_SHOW_STATE =
  "COMMENTS_SET_SIDEBAR_SHOW_STATE";
export const COMMENTS_SET_SOCKET_CONNECTION_STATE =
  "COMMENTS_SET_SOCKET_CONNECTION_STATE";
export const COMMENTS_HIGHTLIGHT_COMMENT = "COMMENTS_HIGHTLIGHT_COMMENT";

// ------------------------------------
// Actions
// ------------------------------------

export const connectSocketMessageHandler = ({ protocolId }) => (
  dispatch,
  getState
) => {
  CommentSocket.init({ protocolId, dispatch, getState });
  return Promise.resolve();
};

export const setSocketConnectedState = ({ socketIsConnected }) => {
  return {
    type: COMMENTS_SET_SOCKET_CONNECTION_STATE,
    payload: { socketIsConnected }
  };
};
export const clearProtocol = ({ protocolId }) => {
  return {
    type: COMMENTS_SET_PROTOCOL,
    payload: { protocolId, data: {} }
  };
};
export const highlightComment = ({ commentId }) => {
  return {
    type: COMMENTS_HIGHTLIGHT_COMMENT,
    payload: commentId
  };
};
export const setProtocol = ({ protocolId, data }) => {
  return {
    type: COMMENTS_SET_PROTOCOL,
    payload: { protocolId, data }
  };
};
export const addCommentToProtocol = ({ comment, replace, session }) => {
  return (dispatch, getState) => {
    const protocolComments = dotProp.get(
      getState(),
      `comments.protocol.${comment.protocolId}`,
      []
    );
    const filteredComments = protocolComments.filter(
      com => com.id !== comment.id
    );
    const mergedComments = filteredComments
      ? [comment, ...filteredComments]
      : [comment];
    dispatch(
      setProtocol({ protocolId: comment.protocolId, data: mergedComments })
    );
    // /**
    //  * this means it's a reply
    //  */
    // if (comment.replies) {
    //   const filteredComments = protocolComments.filter(com => com.id !== comment.id)
    //   const mergedComments = filteredComments ? [comment, ...filteredComments] : [comment]
    //   dispatch(setProtocol({ protocolId: comment.protocolId, data: mergedComments }))
    //   /**
    //    * a new comment, but don't want to send to same connection
    //    */
    // } else {

    // }
  };
};

export const addCommentReplyToProtocol = ({
  comment,
  parentId,
  protocolId
}) => {
  return (dispatch, getState) => {
    const protocolComments = dotProp.get(
      getState(),
      `comments.protocol.${comment.protocolId}`
    );
    const index = protocolComments.findIndex(com => com._id === parentId);
    const commentToUpdate = protocolComments[index];
    const updatedComment = {
      ...commentToUpdate,
      replies: [...commentToUpdate.replies, comment]
    };
    const updatedComments = immutable.set(
      protocolComments,
      `${index}`,
      updatedComment
    );
    dispatch(
      setProtocol({ protocolId: comment.protocolId, data: updatedComments })
    );
  };
};

export const setActiveThread = ({
  protocolId,
  sectionId,
  usageId,
  itemId,
  locationDescription,
  internalName
}) => {
  return {
    type: COMMENTS_SET_ACTIVE_THREAD,
    payload: {
      protocolId,
      sectionId,
      usageId,
      itemId,
      locationDescription,
      internalName
    }
  };
};

export const showLoader = () => {
  return {
    type: COMMENTS_SET_LOADER,
    payload: { loaderState: true }
  };
};

export const hideLoader = () => {
  return {
    type: COMMENTS_SET_LOADER,
    payload: { loaderState: false }
  };
};

export const turnOnComments = () => {
  return {
    type: COMMENTS_SET_SHOW_STATE,
    payload: { commentsState: true }
  };
};
export const turnOffComments = () => {
  return {
    type: COMMENTS_SET_SHOW_STATE,
    payload: { commentsState: false }
  };
};
export const showCommentSidebar = () => {
  return {
    type: COMMENTS_SET_SIDEBAR_SHOW_STATE,
    payload: { sidebarState: true }
  };
};
export const hideCommentSidebar = () => {
  return {
    type: COMMENTS_SET_SIDEBAR_SHOW_STATE,
    payload: { sidebarState: false }
  };
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [COMMENTS_SET_PROTOCOL]: (state, action) => {
    const { protocolId, data } = action.payload;

    return immutable.set(state, `protocol.${protocolId}`, data);
  },
  [COMMENTS_HIGHTLIGHT_COMMENT]: (state, action) => ({
    ...state,
    highlightedComment: action.payload
  }),
  [COMMENTS_SET_ACTIVE_THREAD]: (state, action) => ({
    ...state,
    activeThread: { ...action.payload }
  }),
  [COMMENTS_SET_LOADER]: (state, action) => ({
    ...state,
    showLoader: action.payload.loaderState
  }),
  [COMMENTS_SET_SHOW_STATE]: (state, action) => ({
    ...state,
    showComments: action.payload.commentsState
  }),
  [COMMENTS_SET_SIDEBAR_SHOW_STATE]: (state, action) => ({
    ...state,
    showSidebar: action.payload.sidebarState
  }),
  [COMMENTS_SET_SOCKET_CONNECTION_STATE]: (state, action) => ({
    ...state,
    socketIsConnected: action.payload.socketIsConnected
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  protocol: {},
  activeThread: {
    protocolId: "",
    sectionId: "",
    usageId: "",
    itemId: "",
    locationDescription: "",
    internalName: ""
  },
  highlightedComment: null,
  showLoader: false,
  showComments: false,
  showSidebar: false,
  socketIsConnected: false
};

export default function commentsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
