import React from "react";
import PropTypes from "prop-types";
import { mcgPEText } from "./helpers";

const McgPatientEdPrint = ({ mcgText }) => {
  const {
    title = "",
    subtitle = "",
    summaryText = "",
    copyright = ""
  } = mcgPEText(mcgText);
  return (
    <>
      <p className="patient-ed-print_single-item">
        <strong>{title}: </strong>
        <span>{subtitle}</span>
      </p>
      <p>{summaryText}</p>
      <p>
        <small>{copyright}</small>
      </p>
    </>
  );
};

McgPatientEdPrint.propTypes = {
  mcgText: PropTypes.object
};

export default McgPatientEdPrint;
