import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, connect } from "formik";

/*

*/
export const FieldRadio = props => (
  <div className="fancy-radio_wrap">
    <Field
      className={classNames("fancy-radio", props.className || "")}
      {...props}
      type="radio"
    />
    <label htmlFor={props.id}>
      <i className="fancy-radio_icon icon-radio" /> {props.label}
      {props.required && <span className="required_asterisk">*</span>}
    </label>
  </div>
);
FieldRadio.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
};

export const FieldTextComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div className="field" {...props.inputWrapProps}>
    <label>
      {props.label}
      {props.required && <span className="required_asterisk">*</span>}
      <input
        type="text"
        {...field}
        maxLength={props.maxLength || ""}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    </label>
    {touched[field.name] && errors[field.name] && (
      <div className="field_error field_error--text">{errors[field.name]}</div>
    )}
  </div>
);

FieldTextComponent.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  inputWrapProps: PropTypes.object || {},
  form: PropTypes.object,
  field: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool
};

export const FieldTextAreaComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div className="field">
    <label>
      {props.label}
      {props.required && <span className="required_asterisk">*</span>}
      <textarea {...field} disabled={props.disabled} />
    </label>
    {touched[field.name] && errors[field.name] && (
      <div className="field_error field_error--textarea">
        {errors[field.name]}
      </div>
    )}
  </div>
);
FieldTextAreaComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

export const RadioGroup = connect(({ children, formik, legend, required }) => {
  // use name of first radio button
  const name = children[0].props.name;
  return (
    <fieldset className="field">
      {legend && (
        <legend>
          {legend}
          {required && <span className="required_asterisk">*</span>}
        </legend>
      )}
      {children}
      {formik.touched[name] && formik.errors[name] && (
        <div className="field_error field_error--textarea">
          {formik.errors[name]}
        </div>
      )}
    </fieldset>
  );
});
RadioGroup.propTypes = {
  children: PropTypes.array,
  legend: PropTypes.string,
  required: PropTypes.bool
};

export const FieldSelectComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { inputWrapProps } = props;
  inputWrapProps && delete props.inputWrapProps;
  return (
    <div className="field" {...inputWrapProps}>
      <label>
        {props.label}
        {props.required && <span className="required_asterisk">*</span>}
        <select {...field} {...props} />
      </label>
      {touched[field.name] && errors[field.name] && (
        <div className="field_error field_error--select">
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};
FieldSelectComponent.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  required: PropTypes.bool,
  inputWrapProps: PropTypes.object || {}
};
