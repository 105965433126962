import PropTypes from "prop-types";
import React from "react";

export function AccountInfo({ name, avatarURL }) {
  return (
    <div className="account-info_profile">
      <div className="account-info_avatar">
        <img className="account-info_profile-image" src={avatarURL} />
      </div>
      <div className="account-info_name">{name && <div>{name}</div>}</div>
    </div>
  );
}

AccountInfo.propTypes = {
  name: PropTypes.string,
  avatarURL: PropTypes.string
};

export default AccountInfo;
