import React, { Component } from "react";
import { fetchTarascon } from "lib/ec/meds";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { showLoader, hideLoader } from "store/ui";

class Tarascon extends Component {
  state = {
    data: {}
  };
  fetchData = usbmisId => {
    this.props.showLoader();
    fetchTarascon(this.props.token, usbmisId)
      .then(data => {
        this.setState({ data: data.data });
        this.props.hideLoader();
      })
      .catch(err => this.props.hideLoader());
  };
  componentDidMount() {
    const { usbmisId } = this.props;
    this.fetchData(usbmisId);
  }

  render() {
    let { data } = this.state;
    return (
      <div>
        <h5 className="tarascon-title">Tarascon Pharmacopoeia</h5>
        <div className="tarascon-summary">
          {data.genericNames && data.genericNames.length > 0 && (
            <h5 className="tarascon-summary_header">
              {data.genericNames.join(", ")}
            </h5>
          )}
          {data.tradeNames && data.tradeNames.length > 0 && (
            <h6 className="tarascon-summary_trade-names">
              ({data.tradeNames.join(", ")})
            </h6>
          )}
          <div className="row tarascon-summary_symbols collapse">
            <div className="xsmall-8 columns">
              <div>
                {data.tppMetabolism && (
                  <div className="tarascon-summary_symbol">
                    <span className="icon-metabolism-arrow" />{" "}
                    {data.tppMetabolism}
                  </div>
                )}
                {data.pregnancy && (
                  <div className="tarascon-summary_symbol">
                    <span className="icon-female" />
                    {data.pregnancy}
                  </div>
                )}
                {data.lactation && (
                  <div className="tarascon-summary_symbol">
                    <span className="icon-lactation" /> {data.lactation}
                  </div>
                )}
              </div>
            </div>
            <div className="xsmall-4 columns text-right">{data.price}</div>
          </div>
        </div>
        {data.moa && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">MOA</h4>
            <div className="tarascon-entry_content">
              <p>{data.moa}</p>
            </div>
          </div>
        )}
        {data.deluxeWarning && (
          <div className="tarascon-entry">
            <div className="tarascon-entry_content">
              <h5>Warning</h5>
              <p>{data.deluxeWarning}</p>
            </div>
          </div>
        )}
        <div className="tarascon-entry">
          <h4 className="tarascon-entry_header">Entries</h4>
          <div className="tarascon-entry_content tarascon-entry_content--tabs">
            <Tabs className="tarascon-tabs">
              <TabList>
                <Tab>Classic</Tab>
                <Tab>Deluxe</Tab>
              </TabList>
              <TabPanel>
                <p>{data.classicEntry || "None listed"}</p>
              </TabPanel>
              <TabPanel>
                <p>{data.deluxeNotes || "None listed"}</p>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <div className="tarascon-entry">
          <h4 className="tarascon-entry_header">FDA Approved</h4>
          <div className="tarascon-entry_content tarascon-entry_content--tabs">
            <Tabs className="tarascon-tabs">
              <TabList>
                <Tab>Adult</Tab>
                <Tab>Ped</Tab>
              </TabList>
              <TabPanel>
                <p>{data.adultFda}</p>
              </TabPanel>
              <TabPanel>
                <p>{data.pedsFda}</p>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        {data.adultUnapp && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Unapproved Usages</h4>
            <div className="tarascon-entry_content tarascon-entry_content--tabs">
              <Tabs className="tarascon-tabs">
                <TabList>
                  <Tab>Adult</Tab>
                  <Tab>Ped</Tab>
                </TabList>
                <TabPanel>
                  <p>{data.adultUnapp}</p>
                </TabPanel>
                <TabPanel>
                  <p>{data.pedsUnapp}</p>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}

        {data.adultUnapp && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Side Effects</h4>
            <div className="tarascon-entry_content tarascon-entry_content--tabs">
              <Tabs className="tarascon-tabs">
                <TabList>
                  <Tab>Adverse</Tab>
                  {data.sideEffects !== "" && <Tab>Other Side Effects</Tab>}
                </TabList>
                <TabPanel>
                  <p>{data.adverse || "none listed"}</p>
                </TabPanel>
                {data.sideEffects !== "" && (
                  <TabPanel>
                    <p>{data.sideEffects || "None listed"}</p>
                  </TabPanel>
                )}
              </Tabs>
            </div>
          </div>
        )}

        {data.forms && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Forms</h4>
            <div className="tarascon-entry_content">
              <p>{data.forms}</p>
            </div>
          </div>
        )}

        {data.nursingImplications && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Nursing Implications</h4>
            <div className="tarascon-entry_content">
              <p>{data.nursingImplications}</p>
            </div>
          </div>
        )}

        {data.diseaseSymptom && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Disease Symptom</h4>
            <div className="tarascon-entry_content">
              <p>{data.diseaseSymptom}</p>
            </div>
          </div>
        )}

        {data.peerReview && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Peer Review</h4>
            <div className="tarascon-entry_content">
              <p>{data.peerReview}</p>
            </div>
          </div>
        )}

        {data.pricingNotes && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Pricing Notes</h4>
            <div className="tarascon-entry_content">
              <p>{data.pricingNotes}</p>
            </div>
          </div>
        )}

        {data.dea && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">DEA</h4>
            <div className="tarascon-entry_content">
              <p>{data.dea}</p>
            </div>
          </div>
        )}

        {data.comboDrug && (
          <div className="tarascon-entry">
            <h4 className="tarascon-entry_header">Combo Drug</h4>
            <div className="tarascon-entry_content">
              <p>{data.comboDrug}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
Tarascon.propTypes = {
  token: PropTypes.string,
  usbmisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideLoader: PropTypes.func,
  showLoader: PropTypes.func
};

const mapStateToProps = state => ({
  token: state.user.token
});

const mapDispatchToProps = {
  showLoader,
  hideLoader
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tarascon);
