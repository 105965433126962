import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { success, error } from "react-notification-system-redux";

import { showLoader, hideLoader } from "store/ui";

export class SelectByZipCodeField extends React.PureComponent {
  state = {
    options: [],
    zip: this.props.initialZip || "",
    isInSelectMode: false
  };

  // to be used
  clear = e => {
    e.preventDefault();
    this.props.save();
  };

  // to be used when selecting a value after the zip code search
  handleSelect = event => {
    const { showLoader, save, success, error, hideLoader, label } = this.props;
    const value = event.target.value;

    showLoader();
    save(value)
      .then(() => {
        success({
          message: `Successfully Updated ${label}`,
          autoDismiss: 3
        });
      })
      .catch(() => {
        error({
          message: `Failed to update ${label}`
        });
      })
      .finally(() => {
        this.setState({ isInSelectMode: false });
        hideLoader();
      });
  };

  // to be used when the zip code changes
  onZipChange = e => {
    const { value } = e.target;
    if (value.length === 5) {
      this.zipSearch(value);
    }
    this.setState({
      zip: value
    });
  };

  // to be used when clicking on the button to enter select mode
  onSelectButtonClick = e => {
    e.preventDefault();
    const { zip = "" } = this.state;
    this.setState({ isInSelectMode: true });
    if (zip.length === 5) this.zipSearch(zip);
  };

  // to be used when initiating zip search
  onSearch = e => {
    e.preventDefault();
    this.zipSearch();
  };

  // does a zip code search and updates the component's options
  zipSearch = (zip = this.state.zip) => {
    const { showLoader, fetchOptions, hideLoader } = this.props;
    showLoader();
    fetchOptions(zip, 100)
      .then(options => {
        this.setState({ options });
      })
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    const { label, selectButtonText, selection } = this.props;
    const { zip, isInSelectMode, options } = this.state;

    return (
      <div className="zip-select">
        {!isInSelectMode ? (
          <div>
            {!selection ? (
              <button
                className="button button-primary zip-select_button"
                onClick={this.onSelectButtonClick}
              >
                {selectButtonText}
              </button>
            ) : (
              <div className="zip-select_selected">
                <div>
                  <p className="zip-select_name_label">{label}</p>
                  <span className="zip-select_name">
                    <strong>{selection.name}</strong>
                  </span>
                  <button
                    type="button"
                    className="icon-close"
                    onClick={this.clear}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="row zip-select_not-selected">
              <div className="columns small-12 zip-select_not-selected_zip-search">
                <p className="zip-select_not-selected_label">{label}</p>
                <input
                  type="text"
                  placeholder="Zip"
                  name="zip"
                  value={zip}
                  onChange={this.onZipChange}
                  className="zip-select_input"
                  maxLength="5"
                />
                <i className="icon-search" onClick={this.onSearch} />
              </div>
            </div>
            {options.length ? (
              <select
                value={(selection && selection.id) || ""}
                onChange={this.handleSelect}
              >
                <option value="">No {label} Chosen</option>
                {options.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : (
              zip && (
                <span className="zip-select_no-results">
                  Enter a zip for list of results
                </span>
              )
            )}
          </div>
        )}
      </div>
    );
  }
}

SelectByZipCodeField.propTypes = {
  fetchOptions: PropTypes.func,
  label: PropTypes.string,
  save: PropTypes.func,
  selectButtonText: PropTypes.string,
  initialZip: PropTypes.string,
  selection: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  success: PropTypes.func,
  error: PropTypes.func
};

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  success,
  error
};

export default connect(
  null,
  mapDispatchToProps
)(SelectByZipCodeField);
